import { PosUISettings } from ".";

export const deliverectUISettings: PosUISettings = {
    servicePosId: {
        visible: false,
    },
    surchargeSku: {
        visible: false,
    },
    priceListPosId: {
        visible: false,
    },
    taxPosId: {
        visible: true,
        title: "Tax ID (from POS)",
        subTitle: "",
    },
};
