import { opsApi } from "API";
import { GraphQLInputModel } from "common/scaffolding/types";
import { isNew } from "common/utility/modelUtils";
import { GraphQLFeaturedProduct } from "./editService";
import { serviceListFields, GraphQLServiceSummary } from "./listServices";

export async function saveService(
    location: string,
    serviceId: string | undefined,
    data: ServiceInputModel
): Promise<GraphQLServiceSummary> {
    const isCreate = isNew(serviceId);

    const res = isCreate
        ? (await opsApi.graphQLQuery<{ createService: GraphQLServiceSummary }>(createQuery, { location, data })).data
              .createService
        : (
              await opsApi.graphQLQuery<{ updateService: GraphQLServiceSummary }>(updateQuery, {
                  location,
                  serviceId,
                  data,
              })
          ).data.updateService;

    return res;
}

export interface ServiceInputModel extends GraphQLInputModel {
    startTime: number;
    endTime: number;
    dates: DateRangeInputModel[];
    setMenus: string[];
    categories: string[];
    menus: string[];
    courses: string[];
    defaultCourse: string | null;
    daysOfWeek: number;
    surcharge: number | null;
    surchargeSku: string | null;
    available: boolean;
    posId: string | null;
    sections: string[];
    menuPriority: string;
    featured: GraphQLFeaturedProduct;
    priceList: string | null;
    membershipPriceLists: MembershipPriceListInputModel[];
    applyAlcoholicDrinksRestriction: boolean | null;
}

export interface DateRangeInputModel {
    startDate: string;
    endDate: string;
}

export interface MembershipPriceListInputModel {
    priceList: string;
    membershipLevel: string;
}

const createQuery = `
    mutation($location: ID!, $data: ServiceUpdateInput!) {
        createService(locationId: $location, data: $data) {
            ${serviceListFields}
        }
    }
`;

const updateQuery = `
    mutation($location: ID!, $serviceId: ID!, $data: ServiceUpdateInput!) {
        updateService(locationId: $location, id: $serviceId, data: $data) {
            ${serviceListFields}
        }
    }
`;
