import React from "react";
import { Row, Select, DatePicker, Button } from "antd";
import { RangeValue } from "rc-picker/lib/interface";
import { SelectValue } from "antd/lib/select";
import { Moment } from "moment";

const { Option } = Select;

export interface Props {
    onRangeChanged: (startDate: string, endDate: string) => void;
    onDownloadCsv: (startDate: string, endDate: string) => void;
}

export interface State {
    relativeSelection: string;
    absoluteSelection?: RangeValue<Moment>;
    startDate: string;
    endDate: string;
    days: number;
}

export class ReportDateFilter extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const [startDate, endDate] = getDateRange("30");
        const days = (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24);

        this.state = {
            relativeSelection: "30",
            absoluteSelection: undefined,
            startDate: startDate,
            endDate: endDate,
            days: days,
        };

        this.props.onRangeChanged(startDate, endDate);
    }

    onSelectAbsoluteRange = (dates: RangeValue<Moment>, absoluteSelection: [string, string]) => {
        const [startDate, endDate] = absoluteSelection;
        const days = (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24);

        this.setState({
            absoluteSelection: dates,
            relativeSelection: "custom",
            startDate: startDate,
            endDate: endDate,
            days: days,
        });

        this.props.onRangeChanged(startDate, endDate);
    };

    onSelectRelativeRange = (value: SelectValue) => {
        if (!value) {
            return;
        }
        const [startDate, endDate] = getDateRange(value.toString());
        const days = (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24);

        this.setState({
            absoluteSelection: undefined,
            relativeSelection: value.toString(),
            startDate: startDate,
            endDate: endDate,
            days: days,
        });

        this.props.onRangeChanged(startDate, endDate);
    };

    render() {
        const { absoluteSelection, relativeSelection, startDate, endDate, days } = this.state;
        const { onDownloadCsv } = this.props;
        const exportEnabled = days < 32;

        return (
            <div className="report-date-filter__wrapper">
                <Row justify="start" className="report-date-filter">
                    <div className="filter-input">
                        <label>Select range</label>
                        <DatePicker.RangePicker value={absoluteSelection} onChange={this.onSelectAbsoluteRange} />
                    </div>

                    <div className="filter-input">
                        <label>Time period</label>
                        <Select onSelect={this.onSelectRelativeRange} value={relativeSelection}>
                            <Option value="30">Last 30 days</Option>
                            <Option value="60">Last 60 days</Option>
                            <Option value="90">Last 90 days</Option>
                            {absoluteSelection && <Option value="custom">Custom</Option>}
                        </Select>
                    </div>

                    <div className="filter-input">
                        <Button
                            disabled={!exportEnabled}
                            onClick={() => onDownloadCsv(startDate, endDate)}
                            title={exportEnabled ? `Download CSV` : `Downloads limited to 30 days of data`}
                        >
                            Download CSV
                        </Button>
                    </div>
                </Row>
            </div>
        );
    }
}

function getDateRange(relativeRange: string) {
    const days = parseInt(relativeRange, 10);

    const end = new Date();
    end.setHours(0, 0, 0);

    const start = new Date(end);
    start.setDate(end.getDate() - days);

    return [formatDate(start), formatDate(end)];
}

const formatDate = (date: Date) =>
    `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
        .getDate()
        .toString()
        .padStart(2, "0")}`;
