import { opsApi } from "API";
import { PosConfigurationSetting } from "../types/PosConfigurationSetting";
import { posConfigurationFields } from "./getPosConfiguration";
import { ValidationError } from "common/forms/ValidationError";

export const clearPosConfiguration = async (slug: string) => {
    try {
        const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId: slug });
        return res.data.updatePosConfiguration;
    } catch (error) {
        const validationError = ValidationError.fromGraphQLErrors(error.errors);

        if (validationError) {
            throw validationError;
        }

        throw error;
    }
};

type GraphQLPayload = { posConfiguration: PosConfigurationSetting[] };

const query = `
    mutation ($locationId: ID!) {
        clearPosConfiguration(locationId: $locationId) {
            ${posConfigurationFields}
        }
    }
`;
