import styles from "./MenuButton.module.scss";
import { useTimer } from "common/hooks/useTimer";
import { BurgerMenu } from "common/icons";
import { Icon } from "core/components/icon/Icon";
import { ReactElement, useContext, useEffect, useState } from "react";
import { MenuContext } from "./MenuContainer";
import { useMedia } from "common/hooks";

interface Props {}

const TRANSITION_DURATION = 200;

export const MenuButton = ({}: Props): ReactElement => {
    const [enabled, setEnabled] = useState(true);
    const { menuActive, setMenuActive } = useContext(MenuContext);
    const { setTimer, cancelTimer } = useTimer();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => () => cancelTimer(), []);

    const large = useMedia("(min-width: 896px)");

    useEffect(() => {
        if (large) {
            setMenuActive(false);
        }
    }, [large, setMenuActive]);

    const toggle = () => {
        setMenuActive(!menuActive);
        setEnabled(false);
        setTimer(() => {
            setEnabled(true);
        }, TRANSITION_DURATION);
    };

    const handleClick = () => {
        if (!enabled) {
            return;
        }
        toggle();
    };

    const handleFocus = () => {
        if (menuActive) {
            toggle();
        }
    };

    return (
        <button
            className={styles.button}
            onClick={handleClick}
            onFocus={handleFocus}
            aria-expanded={menuActive}
            aria-haspopup={true}
            aria-label={menuActive ? "close main menu" : "open main menu"}
        >
            <Icon className={styles.icon}>
                <BurgerMenu />
            </Icon>
        </button>
    );
};
