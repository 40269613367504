import { FastField } from "formik";
import { Group } from "../types/Group";
import { SearchInput, SearchInputContainerProps } from "components/forms/SearchInput";
import { useMemo } from "react";

export interface GroupSelectorProps {
    groups?: Group[];
    enableNew: boolean;
}

export const NEW_GROUP: Group = {
    id: "new",
    displayName: "Create new group",
    authInfo: {
        permissions: [],
    },
};

// Superseded by GroupSelectOrCreate. TODO remove when EditCompanyDetails is refactored
export const GroupSelector = ({
    placeholder,
    disabled,
    name,
    groups,
    enableNew,
}: SearchInputContainerProps & GroupSelectorProps) => {
    const groupsWithNew = useMemo(() => {
        if (!groups || !enableNew) {
            return groups;
        }

        return [NEW_GROUP].concat(groups);
    }, [groups, enableNew]);

    return (
        <FastField
            name={name}
            component={SearchInput}
            disabled={disabled}
            placeholder={placeholder}
            options={groupsWithNew}
            disableSearch={true}
            allowClear={true}
        />
    );
};
