import styles from "./Card.module.scss";

interface Props {
    title: string;
    titleAs?: React.ElementType;
}

export const Title = ({ title, titleAs: TitleComponent = "h2", ...rest }: Props & Record<string, any>) => {
    return (
        <TitleComponent className={styles.title} {...rest}>
            {title}
        </TitleComponent>
    );
};
