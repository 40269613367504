interface Props {
    name: string;
    value: string;
}

export const QuickSellPerformance = ({ name, value }: Props) => (
    <div className="quick-sell-page__performance__box">
        <div className="quick-sell-page__performance__title">{name}</div>
        <div className="quick-sell-page__performance__value">{value}</div>
    </div>
);
