import { Spin } from "antd";
import { CSSTransition } from "react-transition-group";

export interface Props {
    loading: boolean;
}

export const UploadLoader = ({ loading }: Props) => (
    <>
        <CSSTransition classNames="fade" timeout={200} unmountOnExit in={loading}>
            <div className="loader__container loader__container--overlay">
                <Spin />
            </div>
        </CSSTransition>
    </>
);
