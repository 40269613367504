import { Option } from "core/components/form/select";

export enum OrderFlowType {
    PAYANDORDER = "PAYANDORDER",
    PAYONLY = "PAYONLY",
    OPENTABLE = "OPENTABLE",
}

export const OrderFlowLabel = {
    [OrderFlowType.PAYANDORDER]: "Order & Pay",
    [OrderFlowType.PAYONLY]: "Pay Only",
    [OrderFlowType.OPENTABLE]: "Flex",
};

export const orderFlowOptions: Option[] = Object.keys(OrderFlowType).map((key) => {
    return { label: OrderFlowLabel[key], value: key };
});
