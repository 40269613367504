import { withSvg } from "core/components/svg/Svg";

export const Edit = withSvg(
    16,
    16
)(() => (
    <>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.1427 -0.0638693C13.8633 0.0150106 14.5346 0.390815 15.0713 0.927053C15.6082 1.46352 15.984 2.13469 16.0627 2.85537C16.1437 3.59622 15.9023 4.33259 15.3068 4.92824L15.0715 5.16356C14.8111 5.42391 14.389 5.42391 14.1287 5.16356C13.8683 4.90321 13.8683 4.4811 14.1287 4.22075L14.3638 3.98556C14.3639 3.98554 14.3638 3.98558 14.3638 3.98556C14.6776 3.67173 14.7737 3.33369 14.7373 3.00018C14.6986 2.64649 14.5022 2.24328 14.1289 1.87025C13.7553 1.49699 13.3516 1.30029 12.9976 1.26155C12.664 1.22503 12.3265 1.32105 12.0134 1.63467L11.7777 1.87031C11.5174 2.13066 11.0953 2.13066 10.8349 1.87031C10.5746 1.60996 10.5746 1.18785 10.8349 0.927496L11.0698 0.692636C11.0698 0.692568 11.0697 0.692704 11.0698 0.692636C11.6651 0.0964011 12.4018 -0.144965 13.1427 -0.0638693Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.8358 0.927684C11.0962 0.667348 11.5183 0.667332 11.7786 0.927648L15.0726 4.22115C15.1977 4.34618 15.2679 4.51576 15.2679 4.69259C15.2679 4.86942 15.1977 5.03901 15.0726 5.16403L13.6311 6.60528C13.3707 6.86561 12.9486 6.86558 12.6883 6.6052C12.428 6.34483 12.428 5.92272 12.6884 5.66239L13.6584 4.69258L11.3073 2.34186L10.3377 3.31149C10.0773 3.57184 9.6552 3.57184 9.39485 3.31149C9.1345 3.05114 9.1345 2.62903 9.39485 2.36868L10.8358 0.927684Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.86621 2.17285C10.043 2.17285 10.2126 2.2431 10.3376 2.36813L13.6311 5.66188C13.8915 5.92224 13.8914 6.34435 13.6311 6.60469L5.36723 14.868C5.3671 14.8682 5.36696 14.8683 5.36683 14.8684C5.27318 14.9621 5.1586 15.022 5.03815 15.0483L0.740812 16.0607C0.516041 16.1136 0.279875 16.0465 0.116575 15.8832C-0.0467249 15.7199 -0.1139 15.4838 -0.0609819 15.259L0.950874 10.9609C0.977136 10.8404 1.03711 10.7258 1.13079 10.6321C1.13083 10.6321 1.13089 10.632 1.13093 10.632L9.39479 2.36811C9.51982 2.24309 9.68939 2.17285 9.86621 2.17285ZM1.9769 12.4211L1.48376 14.5158L3.57812 14.0224L1.9769 12.4211ZM4.89548 13.4542L2.54499 11.1035L9.86618 3.78235L12.2169 6.13324L4.89548 13.4542Z"
        />
    </>
));
