import { FastField } from "formik";
import { SelectItem, SearchInput, SearchInputContainerProps } from "components/forms/SearchInput";

const options: SelectItem[] = [
    { displayName: "Fixed amount", id: "FIXEDAMOUNT" },
    { displayName: "Percentage", id: "PERCENTAGE" },
];

export const AdjustmentTypeSelector = ({ placeholder, disabled, name }: SearchInputContainerProps) => (
    <FastField
        name={name}
        component={SearchInput}
        disableSearch={true}
        allowClear={true}
        disabled={disabled}
        placeholder={placeholder}
        options={options}
    />
);
