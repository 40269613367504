import styles from "./PageLoading.module.scss";

import { Spinner } from "../spinner";

interface Props {
    message?: string;
}

export const PageLoading = ({ message }: Props) => (
    <div className={styles.container}>
        <Spinner size="large" />
        {message && <p className={styles.message}>{message}</p>}
    </div>
);
