// tslint:disable:jsx-no-lambda

import * as React from "react";

import { RouteComponentProps } from "react-router";
import { DataListingPage } from "common/scaffolding/components/DataListingPage";
import { CategorySummary } from "features/category/types";
import { Tag } from "antd";
import { CrudPermissions } from "features/location/types/createCrudPermissions";
import { FEATURE_CONFIG } from "common/feature-variables";

export interface Props {
    items: CategorySummary[];
    permissions: CrudPermissions;
}

export class ListCategoriesPage extends React.Component<Props & RouteComponentProps<any>> {
    render() {
        const { items, match, permissions } = this.props;

        return (
            <DataListingPage
                title="Categories"
                description="Categories are groupings of similar dishes or drinks"
                newItemLabel="Add new category..."
                newItemRoute={`${match.url}/new`}
                editItemRoute={`${match.url}/:id`}
                items={items}
                renderItemInfo={this.renderItem}
                disableAdd={!permissions.canCreate}
                displayImages={FEATURE_CONFIG.enableListingImages === "1"}
            />
        );
    }

    renderItem = ({ menus, services }: CategorySummary) =>
        (services.length ? services : menus).map((item) => <Tag key={item.id}>{item.displayName}</Tag>);
}
