import { createAction } from "../reducers/edit";
import { createAction as lookupActions } from "../reducers/lookups";
import { AppState, AppDispatch } from "features/state";
import { clearPosConfiguration } from "../api/clearPosConfiguration";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { fetchActive } from "features/location/actions";

export const clear = () => async (dispatch: AppDispatch, getState: () => AppState) => {
    const state = getState();
    const location = getActiveLocation(state)!;

    try {
        dispatch(createAction.saving(location.id));

        const posConfiguration = await clearPosConfiguration(location.id);

        await dispatch(fetchActive(location.id, true));

        dispatch(createAction.saved(location.id, posConfiguration));
        dispatch(lookupActions.clear());
    } catch (e) {
        dispatch(createAction.saveFailed(location.id, e));

        throw e;
    }
};
