import styles from "./PreviewControlsFooter.module.scss";

import { PropsWithChildren, ReactElement } from "react";

export const PreviewControlsFooter = ({ children }: PropsWithChildren<{}>): ReactElement => {
    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>{children}</div>
        </div>
    );
};
