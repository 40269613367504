import { useIsNewRoute } from "common/hooks";
import { PageContainer } from "core/components/pageContainer/PageContainer";
import { PageHeader } from "core/components/pageHeader";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Props as EditServicePageProps } from "../EditServicePage/EditServicePage";
import { EditServicePage } from "./EditServicePage";

interface OwnProps {
    onClose: () => void;
}

export const EditServicePageWrapper = (props: OwnProps & EditServicePageProps & RouteComponentProps<any>) => {
    const activeLocation = useSelector(getActiveLocation);

    const isNewRoute = useIsNewRoute();

    if (!activeLocation?.enableServiceSchedule) {
        // Legacy services page without services schedule
        return <EditServicePage {...props} useLegacyLayout={true} />;
    }

    return (
        <PageContainer>
            <PageHeader title={`${isNewRoute ? "Create" : "Edit"} Service`} backHandler={props.onClose} />
            <EditServicePage {...props} useLegacyLayout={false} />
        </PageContainer>
    );
};
