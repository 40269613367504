import { EditableCmsItem } from "common/scaffolding/types";

export interface EditableSection extends EditableCmsItem {
    services: string[];
    posId: string | null;
    posMenuId: string | null;
    sectionType: "outdoor" | "indoor" | null;
    enableTips: boolean;
    locationTipsEnabled?: boolean;
    applySurcharges: boolean;
    applyTrayCharge: boolean;
    demoTableNumber?: string;
    demoStartTime?: string;
    demoQr?: SectionDemoQr;
}

export interface SectionDemoQr {
    isEnabled: boolean;
    dateUpdated?: number;
    timeToLiveSeconds?: number;
    selectedTimeToLive: number | null;
    url: string;
}
export enum DisablePeriodOption {
    TimeBased,
    EndOfDay,
}
export interface SectionDemoQrInput {
    isDemo?: boolean;
    timeToLiveSeconds?: number;
    dateUpdated?: Date;
}
