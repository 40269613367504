import { NotificationMessage } from "common/notificationMessage";
import { scaffoldArchiveAction } from "common/scaffolding/actions/scaffoldArchiveAction";
import { getArchiveServiceTrackingEventData } from "features/service/selectors";
import { EditableService } from "features/service/types";
import { AppDispatch, AppState } from "features/state";
import { archiveService } from "../api/archiveService";
import { createAction } from "../reducers/edit";
import { createAction as calendarActions } from "../reducers/calendar";
import { History } from "history";

export const archive = (location: string, service: EditableService, history: History) =>
    scaffoldArchiveAction(
        (state) => state.services.edit,
        createAction,
        (id: string) => archiveService(location, id),
        (dispatch: AppDispatch) => {
            dispatch(calendarActions.reset());
            return true;
        },
        NotificationMessage.ARCHIVE_SUCCESS,
        (state: AppState) => getArchiveServiceTrackingEventData(state, service)
    );
