import "./DayOfWeekList.scss";
import classNames from "classnames";

export interface Props {
    daysOfWeek: number;
    currentDayOfWeek: string;
    disabled: boolean;
}

const days = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

export const DayOfWeekList = ({ daysOfWeek, currentDayOfWeek, disabled }: Props) => (
    <>
        {days.map((day, index) => (
            <span
                key={index}
                className={classNames({
                    "day__is-active": (daysOfWeek & (1 << index)) !== 0,
                    "day__is-current": day === currentDayOfWeek,
                    "day__is-disabled": disabled,
                })}
            >
                {day.charAt(0)}
            </span>
        ))}
    </>
);
