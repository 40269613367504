import { TrackEventData } from "common/appInsights/types/TrackEventData";
import { isNew } from "common/utility/modelUtils";
import { getTrackingData } from "common/utility/trackingDataUtils";
import { EditableSurcharge, SurchargeType } from "../types";

const getSurchargeTrackingData =
    (name: string) =>
    (surcharge: EditableSurcharge): TrackEventData =>
        getTrackingData(name, getSurchargeTrackingProperties(surcharge));

const getDeleteTrackingData = getSurchargeTrackingData("SURCHARGE/DELETION_SAVED");

const getAddTrackingData = getSurchargeTrackingData("SURCHARGE/ADDITION_SAVED");

const getModifyTrackingData = getSurchargeTrackingData("SURCHARGE/MODIFICATION_SAVED");

const isNewSurcharge = (surcharge: EditableSurcharge) => surcharge.newRow || isNew(surcharge.id);

export const getSaveSurchargesTrackingEventData = (updates: EditableSurcharge[]) => {
    return updates.map((surcharge) => {
        if (surcharge.markedForDelete) {
            return getDeleteTrackingData(surcharge);
        } else if (isNewSurcharge(surcharge)) {
            return getAddTrackingData(surcharge);
        } else {
            return getModifyTrackingData(surcharge);
        }
    });
};

function getSurchargeTrackingProperties(surcharge: EditableSurcharge): { [key: string]: any } {
    const additionalProperties: { [key: string]: any } = {};

    if (surcharge.surchargeType === SurchargeType.OneTime && surcharge.date) {
        // date can be either Date or string - handle both
        additionalProperties.date = surcharge.date instanceof Date ? surcharge.date.toISOString() : surcharge.date;
    }

    if (surcharge.surchargeType === SurchargeType.Recurring) {
        additionalProperties.day = surcharge.dayLabel?.substr(0, 3);
    }

    // cant use isNew here because deletions can also get newRow = true
    if (!isNew(surcharge.id)) {
        additionalProperties.surcharge_id = surcharge.id;
    }

    if (!surcharge.markedForDelete) {
        additionalProperties.time_from = surcharge?.startTime?.toString().padStart(4, "0") || undefined;
        additionalProperties.time_to = surcharge?.endTime?.toString().padStart(4, "0") || undefined;
        additionalProperties.percent = `${surcharge.percentage}`;
    }

    return {
        ...additionalProperties,
        applied_to_food: surcharge.applyToFood,
        applied_to_drink: surcharge.applyToDrink,
    };
}
