import { createAction } from "../reducers/lookups";
import { AppState, AppDispatch } from "features/state";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { getPosLookup } from "../api/getPosLookup";

export const fetchLookup = (key: string, scope?: string) => async (dispatch: AppDispatch, getState: () => AppState) => {
    const location = getActiveLocation(getState())!;

    const compoundKey = `${key}!!${scope}`;

    const lookupState = getState().posLookups[compoundKey];

    if (lookupState && lookupState.status !== "unloaded") {
        return;
    }

    try {
        dispatch(createAction.loading(compoundKey));

        const posLookup = await getPosLookup(location.id, key, scope);

        dispatch(createAction.loaded(compoundKey, posLookup));
    } catch (e) {
        dispatch(createAction.loadFailed(compoundKey, e));
    }
};
