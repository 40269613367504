import { createSelector } from "reselect";
import { getActiveLocation } from "./getLocationPermissions";
import { getGroupList } from "features/group/selectors";
import { getLocationsList } from "./getLocationsList";
import { ActiveLocation, LocationSummary } from "features/location";
import { sortByDisplayName } from "foundation/dataConventions/sortByDisplayName";
import { LocationsMenuItem, LocationsMenuNode, MaybeLocationsMenuNode } from "../types/LocationsMenu";
import { Group } from "features/group";

type LocationsByGroup = {
    [groupId: string]: LocationSummary[];
};

type SortFn = (a: LocationsMenuNode, b: LocationsMenuNode) => number;

export const getLocationsMenu = createSelector(
    getActiveLocation,
    getLocationsList,
    getGroupList,
    (activeLocation, locations, groups): LocationsMenuNode[] => {
        const menu: LocationsMenuNode[] = [];

        if (!activeLocation) {
            return menu;
        }

        const enableGroups = activeLocation.authInfo.permissions.indexOf("location:nav:grouping") > -1;

        const sortFn = getSortFn(activeLocation);

        if (enableGroups) {
            const { locationsByGroup, locationsWithoutGroup } = getLocationsByGroup(locations, groups);

            if (groups.length) {
                menu.push(
                    ...(groups
                        .map((group) => {
                            return getGroupMenuNode(group, locationsByGroup[group.id] || [], sortFn);
                        })
                        .filter(Boolean) as LocationsMenuNode[])
                );
            }

            if (locationsWithoutGroup.length) {
                menu.push(...getParentLocationMenuNodes(locationsWithoutGroup, sortFn));
            }
        } else {
            menu.push(...getParentLocationMenuNodes(locations, sortFn));
        }

        return menu.sort(sortFn);
    }
);

function getGroupMenuNode(group: Group, groupLocations: LocationSummary[], sortFn: SortFn): MaybeLocationsMenuNode {
    // abort group creation if it has no locations
    if (!groupLocations.length) {
        return null;
    }

    return {
        id: group.id,
        data: group,
        children: getParentLocationMenuNodes(groupLocations, sortFn),
    };
}

function getParentLocationMenuNodes(locations: LocationSummary[], sortFn: SortFn): LocationsMenuNode[] {
    if (!locations.length) return [];

    const parentLocations = locations.filter((location) => location.locationType === "BRAND");

    const childLocations = locations.filter((location) => location.locationType !== "BRAND");

    const parentIds = parentLocations.map((location) => location.id);

    const parentNodes: LocationsMenuNode[] = parentLocations.map((parentLocation) => ({
        id: parentLocation.id,
        text: parentLocation.displayName,
        data: parentLocation,
        children: childLocations
            .filter((childLocation) => childLocation.parentLocationId === parentLocation.id)
            .map(getLocationNode)
            .sort(sortFn),
    }));

    const orphanNodes: LocationsMenuNode[] = childLocations
        .filter(
            (childLocation) => !childLocation.parentLocationId || !parentIds.includes(childLocation.parentLocationId)
        )
        .map(getLocationNode);

    return parentNodes.concat(orphanNodes).sort(sortFn);
}

function getLocationNode(location: LocationSummary): LocationsMenuItem {
    return {
        id: location.id,
        data: location,
    };
}

// build fast look up for locations by group id and list of locations without group
function getLocationsByGroup(locations: LocationSummary[], groups: Group[]) {
    const locationsByGroup: LocationsByGroup = {};

    const locationsWithoutGroup: LocationSummary[] = [];

    const groupIds = groups.map((group) => group.id);

    locations.forEach((location) => {
        if (location.groupId && groupIds.includes(location.groupId)) {
            if (!locationsByGroup[location.groupId]) {
                locationsByGroup[location.groupId] = [location];
            } else {
                locationsByGroup[location.groupId].push(location);
            }
        } else {
            locationsWithoutGroup.push(location);
        }
    });

    return { locationsByGroup, locationsWithoutGroup };
}

export function isGroup(data: Group | LocationSummary): data is Group {
    return !isLocation(data);
}

export function isParentLocation(data: Group | LocationSummary): data is LocationSummary {
    const parentLocation = isLocation(data) ? data : null;
    return parentLocation?.locationType === "BRAND";
}

export function isLocation(data: Group | LocationSummary): data is LocationSummary {
    if (!data) {
        return false;
    }
    return "slug" in data;
}

function getSortFn(activeLocation: ActiveLocation): SortFn {
    const getNodePriority = getNodePriorityFn(activeLocation);

    return (a: LocationsMenuNode, b: LocationsMenuNode) => {
        const priorityA = getNodePriority(a);
        const priorityB = getNodePriority(b);

        if (priorityA === priorityB) {
            return sortByDisplayName(a.data, b.data);
        } else {
            return priorityA - priorityB;
        }
    };
}

// get a priority fn that ranks nodes (lower number comes first)
function getNodePriorityFn(activeLocation: ActiveLocation) {
    return (node: LocationsMenuNode): number => {
        if (isGroup(node.data)) {
            return 1;
        }

        if (isParentLocation(node.data)) {
            return 2;
        }

        return 3;
    };
}
