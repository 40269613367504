import { CardWidth, Confirm, Modal, ModalRenderer } from "core/components/modal";
import { EditNewLocationFields } from "./EditNewLocationFields";
import { EditNewLocationForm } from "./EditNewLocationForm";

interface Props {
    onClose: () => void;
    visible: boolean;
}

export const EditNewLocationModal = ({ onClose, visible }: Props) => {
    return (
        <ModalRenderer target="#modal">
            {/* use key to force reset when visible changes */}
            <EditNewLocationForm key={`${visible}`}>
                <Modal
                    footer={
                        <Confirm
                            confirmLabel="Ok"
                            confirmProps={{ type: "submit" }}
                            // prevent modal close since submit reloads the page
                            onConfirm={() => false}
                        />
                    }
                    onClose={onClose}
                    title="Create a new location"
                    visible={visible}
                    width={CardWidth.NARROW}
                >
                    <EditNewLocationFields />
                </Modal>
            </EditNewLocationForm>
        </ModalRenderer>
    );
};
