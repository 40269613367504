import styles from "./Input.module.scss";
import {
    ChangeEvent,
    FocusEvent,
    forwardRef,
    MouseEvent,
    ReactNode,
    Ref,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import { FieldProps, FormikProps, getIn } from "formik";
import classNames from "classnames";
import isFunction from "lodash/isFunction";
import { Icon } from "core/components/icon/Icon";
import { SolidCircleDelete } from "common/icons/SolidCircleDelete";
import { Label } from "./Label";
import { useKeyboard } from "common/hooks";

export type InputProps = Omit<React.HTMLProps<HTMLInputElement>, "onChange" | "form">;

type ResetConfig = { value: boolean; touched: boolean; error: boolean };
export interface Props {
    after?: ReactNode;
    afterClassName?: string;
    autoReset?: boolean | ResetConfig;
    before?: ReactNode;
    beforeClassName?: string;
    blockEnter?: boolean;
    className?: string;
    disabled?: boolean;
    form?: FormikProps<any>;
    formatOnChange?: (value: string) => string;
    inputTest?: RegExp;
    label?: string;
    labelAfter?: ReactNode;
    markRequired?: boolean;
    name?: string;
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
    onChange?: (value: string, event: ChangeEvent<HTMLInputElement>) => boolean | void;
    onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
    onClear?: (event: MouseEvent<HTMLButtonElement>) => void;
    parseValue?: (value: string) => any;
    trim?: boolean;
    value?: string;
    masked?: {
        mask: (val?: string) => string | undefined;
        unmask: (val: string) => string;
    };
}

export const Input = forwardRef<HTMLInputElement, InputProps & Props & Partial<FieldProps<any>>>(
    (
        {
            after,
            afterClassName,
            autoReset = true,
            before,
            beforeClassName,
            blockEnter,
            className,
            disabled = false,
            field,
            form,
            formatOnChange,
            inputTest,
            label,
            labelAfter,
            markRequired = false,
            meta,
            name,
            onBlur,
            onClear,
            onChange,
            onFocus,
            parseValue = (x) => x,
            trim = true,
            value,
            masked,
            ...rest
        },
        ref: Ref<HTMLInputElement | null>
    ) => {
        const [focused, setFocused] = useState<boolean>(false);
        const { setFieldValue, setFieldTouched, setFieldError, touched, errors, initialValues } = form || {};
        const { onBlur: fieldOnBlur } = field || {};
        const inputRef = useRef<HTMLInputElement | null>(null);

        useImperativeHandle(ref, () => inputRef.current);

        name = field ? field.name : name;
        value = field ? field.value : value;

        if (masked) {
            value = masked.mask(value);
        }

        const hasError = name && getIn(errors, name) && getIn(touched, name);

        // reset form state if field unmounted?
        useEffect(
            () => () => {
                const { value, error, touched } =
                    typeof autoReset === "object"
                        ? autoReset
                        : {
                              value: autoReset,
                              error: autoReset,
                              touched: autoReset,
                          };

                value && field?.name && setFieldValue?.(name!, undefined);
                touched && field?.name && setFieldTouched?.(name!, false);
                error && field?.name && setFieldError?.(name!, undefined);
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            []
        );

        const inputContainerClasses = classNames({
            [styles.inputContainer]: !disabled && !focused,
            [styles.inputContainerFocused]: focused,
            [styles.inputContainerDisabled]: disabled || rest.readOnly,
            [styles.inputContainerError]: hasError,
            "input--error": hasError,
        });

        if (value && onClear && !after && !disabled && !rest.readOnly) {
            after = (
                <button type="button" className={styles.clearButton} onClick={onClear}>
                    <Icon size="tiny" verticalAlign="middle" className={styles.clearIcon}>
                        <SolidCircleDelete />
                    </Icon>
                </button>
            );
        }

        // catch clicks that don't land on the input
        const handleInputContainerClick = (event: MouseEvent<HTMLDivElement>) => {
            const input = inputRef?.current;
            event.target !== input && input?.focus();
        };

        const onBlockEnter = useCallback((e: KeyboardEvent) => {
            if (document.activeElement === inputRef.current) {
                e.preventDefault();
            }
        }, []);

        useKeyboard("Enter", onBlockEnter, !!blockEnter);

        return (
            <div className={hasError ? styles.containerError : styles.container}>
                {(label || markRequired) && (
                    <Label label={label} after={labelAfter} markRequired={markRequired} name={name} />
                )}
                <div className={inputContainerClasses} onClick={handleInputContainerClick}>
                    {before && (
                        <div className={classNames(styles.beforeContainer, beforeClassName)}>
                            {isFunction(before) ? before() : before}
                        </div>
                    )}
                    <input
                        className={classNames(styles.input, disabled && styles.disabled, className)}
                        {...(name ? { "data-testid": `input-${name}` } : null)}
                        disabled={disabled}
                        name={name}
                        onFocus={(e) => {
                            onFocus?.(e);
                            setFocused(true);
                        }}
                        onChange={(e) => {
                            const val = masked ? masked.unmask(e.target.value) : e.target.value;
                            if (inputTest && !inputTest.test(val)) return;

                            const value = formatOnChange ? formatOnChange(val) : val;

                            if (onChange) {
                                const proceed = onChange(value, e);
                                if (proceed === false) {
                                    return;
                                }
                            }

                            field?.name && setFieldValue?.(name!, value);
                        }}
                        onBlur={(e) => {
                            let value = trim ? e.target.value.trim() : e.target.value;
                            value = masked ? masked.unmask(value) : value;
                            value = restoreValue(value, initialValues, name);
                            setFocused(false);
                            field?.name && setFieldValue?.(name!, parseValue(value));
                            fieldOnBlur?.(e);
                            onBlur?.(e);
                        }}
                        ref={inputRef}
                        value={value || ""}
                        {...rest}
                    />
                    {after && (
                        <div className={classNames(styles.afterContainer, afterClassName)}>
                            {isFunction(after) ? after() : after}
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

// preserve type of empty initial values
function restoreValue(value: string, initialValues: any, name: string | undefined) {
    if (value === "" && name) {
        const initialValue = getIn(initialValues, name);
        if (initialValue === undefined || initialValue === null) {
            return initialValue;
        }
    }
    return value;
}
