import * as React from "react";
import memoizeOne from "memoize-one";
import Transfer, { TransferItem } from "antd/lib/transfer";

import "./SortableTransfer.scss";
import { FieldProps } from "formik";
import { escapeRegex } from "common/utility/StringUtils";
import { DragDropContext, Draggable, Droppable, DropResult, DroppableId } from "react-beautiful-dnd";
import { SortableTransferItem } from "./SortableTransferItem";

export interface Option {
    id: string;
    displayName: string;
    internalName?: string;
    iconClass?: string;
}

export interface Props {
    options: Option[];
    disabled?: boolean;
}

export interface State {
    targetKeys: string[];
}

export class SortableTransfer extends React.Component<Props & FieldProps, State> {
    dataSource = memoizeOne((options: Option[]) =>
        options.map((option) => ({
            key: option.id,
            title: option.displayName,
            description: option.internalName,
            iconClass: option.iconClass,
        }))
    );
    filterOption = memoizeOne((inputValue: string, option: TransferItem) => {
        const inputRegex = RegExp(escapeRegex(inputValue), "i");
        return (
            (option.title && inputRegex.test(option.title)) ||
            (!!option.description && inputRegex.test(option.description))
        );
    });
    state = {
        targetKeys: this.props.field.value || [],
    };

    resetTargetKeys = () => {
        this.setState({ targetKeys: [] });
    };

    handleChange = (targetKeys: string[]) => {
        const {
            form,
            field: { name },
        } = this.props;

        form.setFieldValue(name, targetKeys);
        this.setState({ targetKeys });
    };

    renderItem = (item: TransferItem) => {
        const { targetKeys } = this.state;
        const index = targetKeys.indexOf(item.key);

        if (index === -1) return this.formatItemDisplay(item);

        return (
            <Draggable key={item.key} draggableId={item.key as DroppableId} index={index}>
                {(provided, snapshot) => (
                    <SortableTransferItem provided={provided} snapshot={snapshot}>
                        {this.formatItemDisplay(item)}
                    </SortableTransferItem>
                )}
            </Draggable>
        );
    };

    onDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const targetKeys = this.state.targetKeys.slice();
        const value = targetKeys[result.source.index];
        targetKeys.splice(result.source.index, 1);
        targetKeys.splice(result.destination.index, 0, value);

        this.handleChange(targetKeys);
    };

    formatItemDisplay = (item: TransferItem) => (
        <>
            {item.iconClass && <i className={`icon ${item.iconClass}`}></i>} {item.title}{" "}
            {item.description && <i>({item.description})</i>}
        </>
    );

    render() {
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            <Transfer
                                className={`sortable-transfer${snapshot.isDraggingOver ? " dragging" : ""}`}
                                dataSource={this.dataSource(this.props.options)}
                                showSearch
                                listStyle={{
                                    width: "45%",
                                    height: 300,
                                }}
                                filterOption={this.filterOption}
                                targetKeys={this.state.targetKeys}
                                onChange={this.handleChange}
                                render={this.renderItem}
                                disabled={this.props.disabled}
                            />
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}
