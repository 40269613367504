import styles from "./Card.module.scss";

interface Props {
    subtitle: string;
    subtitleAs?: React.ElementType;
}

export const Subtitle = ({ subtitle, subtitleAs: SubtitleComponent = "div", ...rest }: Props & Record<string, any>) => {
    return (
        <SubtitleComponent className={styles.subtitle} {...rest}>
            {subtitle}
        </SubtitleComponent>
    );
};
