import { connect } from "react-redux";
import { Props, ListWaitersPage } from "features/waiter/components/ListWaitersPage";
import { State } from "features/waiter/reducers";
import * as actions from "features/waiter/actions";
import { RouteComponentProps } from "react-router";
import { PageLoadingProps, withLoader } from "common/loader/withLoader";
import { DataProps, FunctionProps } from "types/utils";
import { LocationRouteParams } from "common/types";

const mapStateToProps = ({ waiters: { list } }: State): DataProps<PageLoadingProps<Props>> => {
    if (list.status === "loaded") {
        return {
            loadStatus: list.status,
            items: list.data,
        };
    }

    return {
        loadStatus: list.status,
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { match: { params } }: RouteComponentProps<LocationRouteParams>
): FunctionProps<PageLoadingProps<Props>> => ({
    fetch: () => dispatch(actions.list(params.location)),
});

export const ListWaitersPageContainer = connect(mapStateToProps, mapDispatchToProps)(withLoader(ListWaitersPage));
