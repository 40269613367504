import { PublishStatus, Status } from "core/components/pubishStatus";
import { getIsParentLocation } from "features/location/selectors/getIsParentLocation";
import { useSelector } from "react-redux";
import { CatalogueItem, Status as ItemStatus } from "../types";

interface Props {
    item: CatalogueItem;
}

export const ListItemPublishStatus = ({ item }: Props) => {
    const status = getPublishStatus(item.status);
    const isParentLocation = useSelector(getIsParentLocation);

    // only relevant status for parent locations is 'missing content'
    if (!status || (isParentLocation && item.status !== ItemStatus.MissingContent)) {
        return null;
    }

    return <PublishStatus status={status}>{getPublishStatusLabel(item.status)}</PublishStatus>;
};

export const getPublishStatusLabel = (itemStatus?: number) => {
    switch (itemStatus) {
        case ItemStatus.Published:
            return "Published";
        case ItemStatus.MissingContent:
            return "Missing content";
        case ItemStatus.MissingSKU:
            return "Missing SKU";
        case ItemStatus.Staged:
            return "Staged changes";
        case ItemStatus.NotPublished:
            return "Not published";
    }
    return null;
};

const getPublishStatus = (itemStatus?: number): Status | undefined => {
    switch (itemStatus) {
        case ItemStatus.Published:
            return "published";
        case ItemStatus.MissingContent:
        case ItemStatus.MissingSKU:
            return "warning";
        case ItemStatus.Staged:
            return "staged";
        case ItemStatus.NotPublished:
            return "not-published";
    }

    return undefined;
};
