import { useState, useCallback, useEffect } from "react";
import { Props } from "./ViewBrandingPage";
import { preloadImage } from "../utils";
import { ImageLoader } from "./ImageLoader";

let fetchTimer = 0;

export const ListPage = ({ heroImage, displayName }: Props) => {
    const [imageReady, setImageReady] = useState(false);
    const fetchBackgroundImage = useCallback(() => {
        window.clearTimeout(fetchTimer);
        if (heroImage) {
            preloadImage(heroImage)
                .then(() => {
                    setImageReady(true);
                })
                .catch(() => {
                    setImageReady(false);
                    fetchTimer = window.setTimeout(fetchBackgroundImage, 1000);
                });
        }
    }, [heroImage]);

    useEffect(() => {
        fetchBackgroundImage();
        return () => window.clearTimeout(fetchTimer);
    }, [heroImage, fetchBackgroundImage]);

    return (
        <div className="branding-preview__list">
            Order History
            <br />
            <br />
            <div className="branding-preview__list__item">
                <div
                    className="branding-preview__list__item__image"
                    style={
                        imageReady
                            ? {
                                  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${heroImage})`,
                              }
                            : undefined
                    }
                >
                    {!heroImage ? (
                        <div className="empty smaller">No image set</div>
                    ) : !imageReady ? (
                        <ImageLoader hideText />
                    ) : (
                        displayName
                    )}
                </div>
                Order Details
            </div>
            <div className="branding-preview__list__item">
                <div
                    className="branding-preview__list__item__image"
                    style={
                        imageReady
                            ? {
                                  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${heroImage})`,
                              }
                            : undefined
                    }
                >
                    {!heroImage ? (
                        <div className="empty smaller">No image set</div>
                    ) : !imageReady ? (
                        <ImageLoader hideText />
                    ) : (
                        displayName
                    )}
                </div>
                Order Details
            </div>
            <div className="branding-preview__list__item">
                <div
                    className="branding-preview__list__item__image"
                    style={
                        imageReady
                            ? {
                                  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${heroImage})`,
                              }
                            : undefined
                    }
                >
                    {!heroImage ? (
                        <div className="empty smaller">No image set</div>
                    ) : !imageReady ? (
                        <ImageLoader hideText />
                    ) : (
                        displayName
                    )}
                </div>
                Order Details
            </div>
        </div>
    );
};
