import { SaveStatus } from "common/loader";
import {
    CloseOutlined,
    CloudSyncOutlined,
    DeleteOutlined,
    DeleteFilled,
    EditOutlined,
    ExclamationCircleOutlined,
    MinusOutlined,
} from "@ant-design/icons";
import { Alert, Button as AntButton, Col, message } from "antd";
import { ColumnProps, TablePaginationConfig } from "antd/lib/table";
import { RouteComponentProps } from "react-router-dom";
import { TableListing } from "../../../../common/scaffolding/components/TableListing/TableListing";
import { LocationLocale } from "features/location/types/LocationLocale";
import { EditablePriceLevels, EditablePriceListSettings, PriceListItem } from "../../types";
import { CrudPermissions } from "features/location/types/createCrudPermissions";
import { useEffect, useRef, useState } from "react";
import { EditPriceInline } from "./EditPriceInline";
import Search from "antd/lib/input/Search";
import memoizeOne from "memoize-one";
import classNames from "classnames";
import { filterSearchable } from "common/utility/filterSearchable";
import { ActionFooter, StatusMessage } from "core/components/actionFooter";
import { Button } from "core/components/button";

export interface Props extends RouteComponentProps<RouteParams> {
    priceLevels: EditablePriceLevels;
    bulkSaveStatus: SaveStatus;
    permissions: CrudPermissions;
    onBulkSave: (data: PriceListItem[], deleteDate: PriceListItem[]) => void;
    onManageClick: () => void;
    locale: LocationLocale;
    priceList: EditablePriceListSettings;
    onChange: (preventNavigation: boolean) => void;
}

interface RouteParams {
    id: string;
    location: string;
}

const paginationSettings: TablePaginationConfig = {
    size: "default",
    position: ["bottomCenter"],
    pageSize: 10,
    showSizeChanger: false,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
};

const renderDisplayName = (record: PriceListItem) => (
    <>
        {record.parentId && <MinusOutlined className="child-icon" />}
        {!record.canEdit && (!record.children || record.children.length === 0) && (
            <ExclamationCircleOutlined
                className="error-icon"
                title={`Add ${record.childType}s or delete ${record.displayType}`}
            />
        )}
        {record.displayName}
        {record.internalName && <i> ({record.internalName})</i>}
    </>
);

export const EditPriceLevelsTab = ({
    priceLevels,
    bulkSaveStatus,
    permissions,
    onBulkSave,
    onManageClick,
    locale,
    priceList,
    onChange,
}: Props) => {
    const [editMode, setEditMode] = useState(false);
    const [itemsToDelete, setItemsToDelete] = useState<PriceListItem[]>([]);
    const [datasource, setDatasource] = useState<PriceListItem[]>(priceLevels.selectedItems);
    const expandedKeys = memoizeOne(() => priceLevels.selectedItems.map((i) => i.id!));
    const [canCancel, setCanCancel] = useState(false);
    const [deleteAll, setDeleteAll] = useState(false);

    const touchedItems = useRef<PriceListItem[]>([]); //tracking this way to prevent rerendering the entire table to track touched items
    const invalidKeys = useRef<Set<string>>(new Set());

    const bindColumns = memoizeOne((locale: LocationLocale) => {
        let columns: ColumnProps<any>[] = [
            {
                title: "Type",
                dataIndex: "displayType",
                key: "displayType",
                className: "col-type",
            },
            {
                title: "Name",
                key: "displayName",
                render: (text, record) => renderDisplayName(record),
            },
            {
                title: "Base price",
                dataIndex: "price",
                key: "price",
                className: "col-right num-field col-price",
                render: (text, record) => (
                    <>
                        {text != undefined && (
                            <>
                                {locale.currencySymbol}
                                {text.toFixed(2)}
                            </>
                        )}
                    </>
                ),
            },
            {
                title: "List price",
                dataIndex: "priceLevelPrice",
                key: "priceLevelPrice",
                className: "col-right num-field col-price",
                render: (text, record) => (
                    <>
                        {!editMode && text != undefined ? (
                            <>
                                {locale.currencySymbol}
                                {text.toFixed(2)}
                            </>
                        ) : (
                            <></>
                        )}
                        {record.canEdit && editMode && (
                            <EditPriceInline
                                priceLevel={record}
                                handleUpdate={handlePriceChange}
                                canUpdate={permissions.canUpdate}
                            />
                        )}
                    </>
                ),
            },
            {
                title: "SKU",
                dataIndex: "sku",
                key: "sku",
                className: "col-right num-field col-sku",
            },
        ];

        if (permissions.canUpdate) {
            columns = columns.concat({
                title: editMode && (
                    <AntButton
                        type="link"
                        size="small"
                        onClick={onSelectAll}
                        className={classNames("btn-delete-all", deleteAll && "selected")}
                        title="Toggle delete all"
                    >
                        {deleteAll ? <DeleteFilled /> : <DeleteOutlined />}
                    </AntButton>
                ),
                key: "action",
                render: (text, record) =>
                    editMode && (
                        <AntButton
                            type="link"
                            icon={<DeleteOutlined />}
                            size="small"
                            onClick={() => handleRemove(record)}
                        />
                    ),
                className: "col-center col-actions",
            });
        }

        return columns;
    });

    const handleRemove = (item: PriceListItem) => {
        const childKeys = item.children ? item.children.map((c) => c.key) : [];

        let newItems = [];
        if (itemsToDelete.includes(item)) {
            newItems = itemsToDelete.filter(
                (existingItem) => item.key !== existingItem.key && existingItem.id !== item.parentId
            );
        } else {
            newItems = itemsToDelete
                .filter((existingItem) => item.key !== existingItem.key && !childKeys.includes(existingItem.key))
                .concat(item);
            newItems = item.children ? newItems.concat(item.children) : newItems;
            checkParentDeletion(item, newItems);
        }

        setItemsToDelete(newItems);
        onChange(!!touchedItems.current.length || !!invalidKeys.current.size || !!newItems.length);
        if (deleteAll) {
            setDeleteAll(false);
        }
    };

    const checkParentDeletion = (item: PriceListItem, items: PriceListItem[]): void => {
        if (item.parentId) {
            const parent = priceLevels.selectedItems.find((pl) => pl.id === item.parentId);
            if (parent) {
                const remainingChildren = parent.children && parent.children.filter((c) => !items.includes(c)).length;
                if (remainingChildren === 0) {
                    items.push(parent);
                }
            }
        }
    };

    useEffect(() => {
        if (!editMode) {
            touchedItems.current = [];
            invalidKeys.current.clear();
            setItemsToDelete([]);
        }
    }, [editMode]);

    useEffect(() => {
        if (priceList.useGlobalAdjustment) {
            return setEditMode(false);
        }

        const invalidItems = priceLevels.selectedItems.filter(
            (pl) =>
                (pl.canEdit && pl.priceLevelPrice === undefined) ||
                pl.children?.some((plc) => plc.canEdit && plc.priceLevelPrice === undefined)
        );
        // force edit mode if invalid items exists to begin with
        if (invalidItems.length > 0) {
            setEditMode(true);
            //cancel only allowed if previous state is valid
            setCanCancel(false);
            return;
        }

        setCanCancel(true);
        const hasValidChildren = priceLevels.selectedItems
            .filter((pl) => !pl.canEdit)
            .every((item) => {
                if (item.children && item.children.find((ch) => ch.priceLevelPrice !== undefined)) {
                    return false;
                }
                return true;
            });

        if (!hasValidChildren) {
            setEditMode(true);
            setCanCancel(false);
        }
    }, [priceLevels.selectedItems, priceList.useGlobalAdjustment]);

    const handleSubmit = () => {
        var invalidItems = Array.from(invalidKeys.current).filter((item) => !itemsToDelete.find((i) => i.key === item)); //filter out any ids that are being deleted
        if (invalidItems.length) {
            const items = document.querySelectorAll("tr:not(.ant-table-row-selected) .invalid");
            items.length > 0 && items[0].scrollIntoView({ behavior: "smooth", block: "center" });

            message.error("Failed to save. Level prices cannot be empty, set a price or remove the item.");
            //logging in case intermittent validation bug has not been resolved to help gather more info
            console.log(invalidItems, invalidKeys.current, itemsToDelete);
            return;
        }

        if (touchedItems.current.length === 0 && itemsToDelete.length === 0) {
            setEditMode(false);
            return;
        }
        onBulkSave(touchedItems.current, itemsToDelete);
    };

    const handlePriceChange = (item: PriceListItem, isValid: boolean, price?: number) => {
        const newItems = touchedItems.current.filter((existingItem) => item.key !== existingItem.key);
        item.priceLevelPrice !== price && newItems.push({ ...item, priceLevelPrice: price });
        touchedItems.current = newItems;
        isValid ? invalidKeys.current.delete(item.key) : invalidKeys.current.add(item.key);
        onChange(!!touchedItems.current.length || !!invalidKeys.current.size || !!itemsToDelete.length);
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = e.target.value.trim();

        if (!searchTerm) {
            setDatasource(priceLevels.selectedItems);
            return;
        }
        const searchResults = filterSearchable(priceLevels.selectedItems, searchTerm);
        setDatasource(searchResults);
    };

    const onSelectAll = () => {
        if (!deleteAll) {
            setItemsToDelete(
                datasource.reduce((all: PriceListItem[], item) => {
                    all.push(item);
                    return item.children ? all.concat(item.children) : all;
                }, [])
            );
            setDeleteAll(true);
            onChange(true);
        } else {
            setItemsToDelete([]);
            setDeleteAll(false);
            onChange(!!touchedItems.current.length || !!invalidKeys.current.size);
        }
    };

    const isDirty = !!touchedItems.current.length;

    return (
        <>
            <Col lg={22} md={24}>
                <div className="header-row">
                    <Search
                        placeholder="Search products"
                        allowClear
                        onChange={handleSearch}
                        className="pricelevel-search"
                    />
                    {editMode ? (
                        canCancel && (
                            <AntButton
                                onClick={() => {
                                    onChange(!editMode);
                                    setEditMode(!editMode);
                                }}
                                icon={<CloseOutlined />}
                            >
                                Cancel editing
                            </AntButton>
                        )
                    ) : (
                        <>
                            <AntButton
                                onClick={() => setEditMode(!editMode)}
                                icon={<EditOutlined />}
                                disabled={!priceLevels.selectedItems.length || priceList.useGlobalAdjustment}
                            >
                                Edit price levels
                            </AntButton>
                            <AntButton onClick={onManageClick} type="primary">
                                Manage list
                            </AntButton>
                        </>
                    )}
                </div>
                {priceList.useGlobalAdjustment && (
                    <Alert
                        className="price-levels-alert"
                        message={`${priceList.displayName} uses a global adjustment`}
                        description="Individual prices cannot be edited with global adjustment price lists."
                        type="info"
                    />
                )}
            </Col>
            <TableListing
                columns={bindColumns(locale)}
                dataSource={datasource}
                keyField="key"
                col={22}
                className={classNames("price-levels-table", editMode && "editing")}
                pagination={editMode ? false : paginationSettings}
                expandedKeys={expandedKeys()}
                selectedRowKeys={itemsToDelete.map((i) => i.key)}
                loading={bulkSaveStatus === "saving"}
            />

            {editMode && (
                <ActionFooter align="left" forceDirty={isDirty} position="fixed">
                    <Button
                        onClick={handleSubmit}
                        loading={bulkSaveStatus === "saving"}
                        // FIXME this page missing correct form semantics
                        type="button"
                    >
                        {!(bulkSaveStatus === "saving") && <CloudSyncOutlined />}
                        Save changes
                    </Button>
                    <StatusMessage />
                </ActionFooter>
            )}
        </>
    );
};
