import styles from "./TakeawayPage.module.scss";

import { Card, Row, Title } from "core/components/card";
import { Subtitle } from "core/components/card/Subtitle";
import { FieldErrors } from "core/components/form/fieldErrors";
import { Select } from "core/components/form/select";
import { Switch } from "core/components/form/switch";
import { EditableTakeawayOptions } from "features/takeaway/types";
import { Field, useFormikContext } from "formik";

interface Props {
    disabled: boolean;
}

const windowMinutesOptions = [
    { value: 15, label: "15 min" },
    { value: 20, label: "20 min" },
    { value: 30, label: "30 min" },
    { value: 60, label: "60 min" },
];

export const TakeawayPageScheduleCard = ({ disabled }: Props) => {
    const { values } = useFormikContext<EditableTakeawayOptions>();
    return (
        <Card>
            <Row collapse="down">
                <Title title="Scheduling orders" />
                <Field name="scheduleOptions.enabled" type="checkbox" component={Switch} disabled={disabled} />
            </Row>
            <Row collapse="up">
                <div className="mt-2">
                    <Subtitle subtitle="Allow customers to schedule orders within the same dining day." />
                </div>
            </Row>
            <Row border="top" hide={!values.scheduleOptions.enabled}>
                <div>
                    <h3 className="text-subheading">Collection increments</h3>
                    <div>Set the time between each collection slot as displayed to your customers in the me&u app.</div>
                </div>
            </Row>
            <Row collapse="up" hide={!values.scheduleOptions.enabled}>
                <FieldErrors fieldNames={["scheduleOptions.scheduleWindowMinutes"]}>
                    <Field
                        name="scheduleOptions.scheduleWindowMinutes"
                        label="Collection time increments"
                        markRequired={values.scheduleOptions.enabled}
                        component={Select}
                        className={styles.selectNarrow}
                        options={windowMinutesOptions}
                        search={false}
                        disabled={disabled}
                    />
                </FieldErrors>
            </Row>
        </Card>
    );
};
