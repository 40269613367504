import { MutableRefObject, useLayoutEffect, useState } from "react";

// note: only vertical scrollbars supported at this time
export function useScrollbarWidth(ref: MutableRefObject<HTMLElement | null>) {
    const [scrollbarWidth, setScrollbarWidth] = useState(0);

    useLayoutEffect(() => {
        if (!ref.current) {
            return;
        }

        setScrollbarWidth(ref.current.offsetWidth - ref.current.clientWidth);
    }, [ref]);

    return scrollbarWidth;
}
