import { createAction } from "../reducers/edit";
import { scaffoldArchiveAction } from "common/scaffolding/actions/scaffoldArchiveAction";
import { archivePriceList } from "../api/archivePriceList";
import { History } from "history";
import { AppDispatch } from "features";
import { list } from "./list";
import { LocationSummary } from "features/location";

export const archive = (location: LocationSummary, region: string, history: History) =>
    scaffoldArchiveAction(
        (state) => state.priceLists.edit,
        createAction,
        (id) => archivePriceList(location.id, id),
        (dispatch: AppDispatch) => {
            dispatch(list(location.id));
            return history.replace(`/${region}/${location.slug}/menu/pricelists`);
        }
    );
