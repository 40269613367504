import styles from "./Select.module.scss";
import { FieldInputProps, FieldMetaProps, FormikProps, getIn } from "formik";

import ReactSelect, { ActionMeta, GroupBase, OptionsOrGroups, Props as SelectProps, PropsValue } from "react-select";
import CreatableSelect from "react-select/creatable";

import { getComponents, getStyles } from "./SelectStyles";
import classNames from "classnames";
import { useMemo, useRef } from "react";
import { Label } from "../input/Label";
import { normaliseText } from "common/utility/StringUtils";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";

export type Option = {
    label: string;
    value: any;
};

export type OptionWithSecondaryLabel = Option & {
    secondaryLabel: string;
};

export type OptionSelectedTest = <T extends Option>(value: any, option: T) => boolean;

// Omit react-select's 'form' prop as it is incompatible with Formik's
export interface Props<T> extends Omit<SelectProps<T>, "form"> {
    allowCreate?: boolean;
    disabled?: boolean;
    disableError?: boolean;
    forceHasError?: boolean;
    label?: string;
    markRequired?: boolean;
    search?: boolean;
    onChange?: (value: PropsValue<T>, meta: ActionMeta<T>) => boolean | void;
    optionSelectedTest?: OptionSelectedTest;
    field?: FieldInputProps<any>;
    meta?: FieldMetaProps<any>;
    form?: FormikProps<any>;
    setTouchedOnBlur?: boolean;
    validateOnChange?: boolean;
    validateOnTouched?: boolean;
}

const defaultOptionSelectedTest: OptionSelectedTest = <T extends Option>(value: any, option: T) => {
    return value === option.value;
};

const findOption = <T extends Option>(
    value: any,
    options: OptionsOrGroups<T, GroupBase<T>>,
    optionSelectedTest: OptionSelectedTest
): PropsValue<T> => {
    for (const optionOrGroup of options) {
        if ("value" in optionOrGroup) {
            if (optionSelectedTest(value, optionOrGroup)) return optionOrGroup;
            continue;
        }
        for (const option of optionOrGroup.options) {
            if (optionSelectedTest(value, option)) return option;
        }
    }
    return null;
};

export const findOptions = <T extends Option>(
    value: any,
    options: OptionsOrGroups<T, GroupBase<T>>,
    optionSelectedTest: OptionSelectedTest = defaultOptionSelectedTest
): PropsValue<T> => {
    return Array.isArray(value)
        ? (value.map((value) => findOption(value, options, optionSelectedTest)).filter(Boolean) as PropsValue<T>)
        : findOption(value, options, optionSelectedTest);
};

export function Select<T extends Option>(props: Props<T>) {
    const {
        allowCreate = false,
        blurInputOnSelect = true,
        className,
        disabled = false,
        disableError = false,
        field,
        form,
        forceHasError = false,
        hideSelectedOptions = false,
        isClearable = false,
        isMulti,
        label,
        markRequired = false,
        meta,
        options,
        optionSelectedTest = defaultOptionSelectedTest,
        onChange,
        search = false,
        setTouchedOnBlur = true,
        validateOnChange = true,
        validateOnTouched = false,
        value,
        ...rest
    } = props;

    const createdOptions = useRef<T[] | T>([]);
    const { setFieldValue, setFieldTouched, values, touched, errors } = form || {};
    const { name } = field || {};

    let selectedValue: PropsValue<T> | undefined;

    // if value supplied it takes precedence over formik value
    if (value) {
        selectedValue = value;
    } else {
        const formikValue = name && getIn(values, name);
        const allOptions = (options ?? []).concat(createdOptions.current);
        selectedValue = findOptions(formikValue, allOptions, optionSelectedTest);
    }

    const hasError = forceHasError || (name && getIn(errors, name) && getIn(touched, name) && !disableError);

    const handleChange = (value: PropsValue<T>, meta: ActionMeta<T>) => {
        if (allowCreate) {
            if (Array.isArray(value)) {
                createdOptions.current = value.filter((option) => "__isNew__" in option);
            } else if (value && "__isNew__" in value) {
                createdOptions.current = value as T;
            }
        }

        if (onChange) {
            const proceed = onChange(value, meta);
            if (proceed === false) {
                return;
            }
        }

        if (name) {
            if (!value) {
                setFieldValue?.(name, "", validateOnChange);
                return;
            }
            if (Array.isArray(value)) {
                setFieldValue?.(
                    name,
                    value.map((option) => option.value),
                    validateOnChange
                );
            } else {
                setFieldValue?.(name, (value as T).value, validateOnChange);
            }
        }
    };

    // Creating custom component definitions every render is not only not performant,
    // but causes an issue with React Select where it doesn't handle clicks outside properly
    // with certain custom components e.g. Input and blurInputOnSelect=false and
    // closeMenuOnSelect=false.

    // These props are unlikely to change during typical lifecycle, so dependencies can
    // be ignored for now.

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const components = useMemo(() => getComponents<T>({ search, field, name }), []);

    const InternalSelect = allowCreate ? CreatableSelect : ReactSelect;

    return (
        <div className={hasError ? styles.containerError : styles.container}>
            {(label || markRequired) && <Label label={label} markRequired={markRequired} name={name} />}
            <InternalSelect
                blurInputOnSelect={blurInputOnSelect}
                className={classNames(className)}
                components={components}
                hideSelectedOptions={hideSelectedOptions}
                isClearable={isClearable}
                isDisabled={disabled}
                isMulti={isMulti}
                name={name}
                onBlur={() => {
                    if (name && setTouchedOnBlur) {
                        setFieldTouched?.(name, true, validateOnTouched);
                    }
                }}
                onChange={handleChange}
                options={options}
                styles={getStyles<T>(props, hasError)}
                value={selectedValue}
                // defaultMenuIsOpen={true} // helpful for inspecting styles
                {...rest}
            />
        </div>
    );
}

export function filterOptionByLabel<T extends OptionWithSecondaryLabel>(option: FilterOptionOption<T>, input: string) {
    const searchTerm = normaliseText(input.trim());

    if (!searchTerm) {
        return true;
    }

    const label = normaliseText(option.data.label || "");
    const secondaryLabel = normaliseText(option.data.secondaryLabel || "");

    return label.includes(searchTerm) || secondaryLabel.includes(searchTerm);
}

export function formatOptionWithSecondaryLabel(option: OptionWithSecondaryLabel) {
    return (
        <>
            <span>{option.label}</span>{" "}
            {option.secondaryLabel && <span className={styles.labelSecondary}>({option.secondaryLabel})</span>}
        </>
    );
}

export const sortOptions = <T extends Option>(a: T, b: T): number => a.label.localeCompare(b.label);
