import { opsApi } from "../../../API";

export const archiveCategory = async (locationId: string, categoryId: string) => {
    await opsApi.graphQLQuery(query, { locationId, categoryId });
};

const query = `
    mutation($locationId: ID!, $categoryId: ID!) {
        deleteCategory(locationId: $locationId, id: $categoryId) {
            id
        }
    }
`;
