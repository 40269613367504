import { withSvg } from "core/components/svg/Svg";

export const Grip = withSvg(
    16,
    16
)(() => (
    <>
        <path d="M6.75 2.75C6.75 3.44036 7.30964 4 8 4C8.69036 4 9.25 3.44036 9.25 2.75C9.25 2.05964 8.69036 1.5 8 1.5C7.30964 1.5 6.75 2.05964 6.75 2.75Z" />
        <path d="M6.75 9.75C6.75 10.4404 7.30964 11 8 11C8.69036 11 9.25 10.4404 9.25 9.75C9.25 9.05964 8.69036 8.5 8 8.5C7.30964 8.5 6.75 9.05964 6.75 9.75Z" />
        <path d="M6.75 13.25C6.75 13.9404 7.30964 14.5 8 14.5C8.69036 14.5 9.25 13.9404 9.25 13.25C9.25 12.5596 8.69036 12 8 12C7.30964 12 6.75 12.5596 6.75 13.25Z" />
        <path d="M6.75 6.25C6.75 6.94036 7.30964 7.5 8 7.5C8.69036 7.5 9.25 6.94036 9.25 6.25C9.25 5.55964 8.69036 5 8 5C7.30964 5 6.75 5.55964 6.75 6.25Z" />
    </>
));
