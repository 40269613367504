import styles from "./ListWrapper.module.scss";

import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { ListWrapperProps } from "../ModalCrudRoute";
import { PropsWithChildren } from "react";
import classNames from "classnames";

export const ListWrapper = ({
    children,
    contentWidth = ContentWidth.STANDARD,
}: PropsWithChildren<ListWrapperProps>) => {
    return (
        <div className={styles.container}>
            <div className={classNames(styles.content, `content--${contentWidth}`)}>{children}</div>
        </div>
    );
};
