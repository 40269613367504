import styles from "./Card.module.scss";
import classNames from "classnames";
import { forwardRef, PropsWithChildren, ReactElement } from "react";

type CardStyle = "default" | "popup";

interface Props {
    as?: React.ElementType;
    elevation?: string;
    className?: string;
    cardStyle?: CardStyle;
}

export const Card = forwardRef<ReactElement | HTMLElement, PropsWithChildren<Props & Record<string, any>>>(
    ({ as: Component = "div", cardStyle = "default", children, className, elevation = "small" }, ref) => {
        // prevent error if as is null or empty string
        if (!Component) {
            Component = "div";
        }

        return (
            <Component
                className={classNames(
                    styles.container,
                    elevation && styles[`elevation-${elevation}`],
                    cardStyle === "popup" && styles.popup,
                    className
                )}
                ref={ref}
            >
                {children}
            </Component>
        );
    }
);
