import { createAction } from "../reducers/list";
import { sortByDisplayName } from "foundation/dataConventions/sortByDisplayName";
import { PriceListSummary } from "..";
import { scaffoldListAction } from "../../../common/scaffolding/actions/scaffoldListAction";
import { listPriceLists } from "../api/listPriceLists";

export const list = (location: string) =>
    scaffoldListAction<PriceListSummary>(
        (state) => state.priceLists.list,
        createAction,
        async () => {
            const priceLists = await listPriceLists(location);

            return priceLists.sort(sortByDisplayName);
        }
    );
