import {
    RouteComponentProps,
    Route,
    StaticContext,
    withRouter,
    generatePath,
    matchPath,
    useRouteMatch,
} from "react-router";
import { Col, Drawer, DrawerProps } from "antd";
import { useMedia } from "common/hooks";
import { isNew } from "../../utility/modelUtils";
import { ContentWidth } from "../types/ContentWidth";
import { PropsWithChildren } from "react";

export interface EditWrapperProps {
    onClose?: (e: React.MouseEvent | React.KeyboardEvent) => void;
    className?: string;
    contentWidth?: ContentWidth;
    visible?: boolean;
}

export interface EditComponent {
    route: string;
    component: React.ComponentType<RouteComponentProps<any> | any> | React.ComponentType<any>;
    editTitle?: string;
    createTitle?: string;
    contentWidth?: ContentWidth;
    wrapper?: React.ComponentType<EditWrapperProps>;
}

export interface ListWrapperProps {
    name: string;
    contentWidth?: ContentWidth;
}

export interface ListComponent {
    route?: string;
    component: React.ComponentType<RouteComponentProps<any> | any> | React.ComponentType<any>;
    contentWidth?: ContentWidth;
    wrapper?: React.ComponentType<ListWrapperProps>;
}

export interface Props {
    name: string;
    section?: string | null;
    listComponent: ListComponent;
    editComponents?: EditComponent[];
    listWidth?: number;
    editWidth?: number;
    showBackLink?: boolean;
}

export const ModalCrudRoute = ({
    name,
    section,
    listComponent,
    editComponents,
    history,
    location,
}: Props & RouteComponentProps<any, StaticContext, any>) => {
    const route = section !== null ? `${section || "menu"}/${name}` : name;
    const ListComponent = listComponent.component;
    const ListWrapper = listComponent.wrapper || LegacyListWrapper;

    const onCancel = (props: RouteComponentProps<any, StaticContext, any>) => {
        history.replace(
            generatePath(
                `/:region/:location/${route}${listComponent.route ? "/" + listComponent.route : ""}`,
                matchPath(location.pathname, {
                    path: `/:region/:location/${route}${listComponent.route ? "/" + listComponent.route : ""}`,
                })!.params
            )
        );
    };

    return (
        <>
            <Route
                path={`/:region/:location/${route}${listComponent.route ? "/" + listComponent.route : ""}`}
                render={(props) => (
                    <>
                        <ListWrapper name={name} contentWidth={listComponent.contentWidth}>
                            <ListComponent {...props} />
                        </ListWrapper>
                        {editComponents &&
                            editComponents.map((edit, i) => (
                                <EditRoute
                                    name={name}
                                    key={`${listComponent.route}-${edit.route}`}
                                    edit={edit}
                                    route={route}
                                    onCancel={onCancel}
                                    props={props}
                                />
                            ))}
                    </>
                )}
            />
        </>
    );
};

export const ModalCrudRouteContainer = withRouter(ModalCrudRoute);

interface EditRouteProps {
    name: string;
    edit: EditComponent;
    route: string;
    onCancel: (props: RouteComponentProps<any, StaticContext, any>) => void;
    props: RouteComponentProps<any, StaticContext, any>;
}

export const EditRoute = ({ name, edit, route, onCancel, props }: EditRouteProps) => {
    const Component = edit.component;
    const Wrapper = edit.wrapper || Drawer;
    const match = useRouteMatch<{ id: string }>(`/:region/:location/${route}/${edit.route}`);
    const isActive = !!match;
    const large = useMedia("(min-width: 896px)");

    const dynamicProps: DrawerProps | EditWrapperProps = edit.wrapper
        ? {
              contentWidth: edit.contentWidth,
          }
        : {
              title: isNew(match?.params.id) ? edit.createTitle : edit.editTitle,
              placement: "right",
              width: large ? 680 : "100%",
          };

    return (
        <Wrapper
            onClose={() => onCancel(props)}
            className={`edit-drawer edit-drawer--${name}`}
            visible={isActive}
            {...dynamicProps}
        >
            <Route
                exact
                path={`/:region/:location/${route}/${edit.route}`}
                render={(props) => <Component key={props.match.params.id} onClose={() => onCancel(props)} {...props} />}
            />
        </Wrapper>
    );
};

interface LegacyListWrapperProps {
    name: string;
}

export const LegacyListWrapper = ({ children, name }: PropsWithChildren<LegacyListWrapperProps>) => (
    <Col span={24} md={24} className={`content__col content__col--${name} content__col--white`}>
        <div className="content__col-list-white">{children}</div>
    </Col>
);
