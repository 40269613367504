import styles from "./DatePickerInput.module.scss";
import reactDayPickerClasses from "./ReactDayPicker.module.scss";
import inputStyles from "../input/Input.module.scss";

import {
    ChangeEvent,
    FocusEvent,
    forwardRef,
    MouseEvent,
    MutableRefObject,
    Ref,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";

import { Calendar } from "common/icons";
import { DayModifiers, DayPickerProps, ModifiersUtils } from "react-day-picker";
import { DayPickerContainer } from "./DayPickerContainer";
import { FieldInputProps, FieldMetaProps, FormikProps, getIn } from "formik";
import { getShortDateFormatter, getShortDatePlaceholder } from "common/utility/dateUtils";
import { Icon } from "core/components/icon/Icon";
import { Input, Props as InputProps } from "../input";
import { Placement } from "@popperjs/core";
import { StyledDayPicker } from "./DatePicker";
import { useOnClickOutside, useKeyboard, usePrevious, useOnFocusOutside } from "common/hooks";
import { usePopper } from "react-popper";
import moment from "moment";
import MomentLocaleUtils from "react-day-picker/moment";

export type SelectionSource = "picker" | "input";
export interface Props extends Omit<InputProps, "value"> {
    dayPickerProps?: DayPickerProps;
    field?: FieldInputProps<any>;
    form?: FormikProps<any>;
    format?: string;
    formatDate?: (date: Date) => string;
    initialMonth?: Date;
    innerRef?: MutableRefObject<HTMLInputElement>;
    locale: string;
    meta?: FieldMetaProps<any>;
    onSelect?: (
        day: Date | undefined,
        source: SelectionSource,
        event: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLButtonElement> | MouseEvent<HTMLDivElement>
    ) => void;
    onTodayClick?: (day: Date, modifiers: DayModifiers, e: MouseEvent<HTMLButtonElement>) => boolean | void;
    parseDate?: (value: string, format?: string, locale?: string) => Date | undefined;
    pickerPlacement?: Placement;
    placeholder?: string;
    today?: Date;
    todayUpdatesSelection?: boolean;
    storageFormat?: string;
    value?: Date | undefined;
    closeOnDayClick?: boolean;
    canInputDisabled?: boolean;
    canClearInput?: boolean;
}

export const DatePickerInput = forwardRef<HTMLInputElement, Props>(
    (
        {
            initialMonth,
            canClearInput = true,
            canInputDisabled = true,
            closeOnDayClick,
            disabled,
            dayPickerProps,
            field,
            form,
            innerRef,
            locale,
            formatDate,
            meta,
            parseDate = MomentLocaleUtils.parseDate,
            pickerPlacement = "bottom-start",
            placeholder,
            onChange,
            onSelect,
            onTodayClick,
            storageFormat = "YYYY-MM-DD",
            todayUpdatesSelection = false,
            today = new Date(),
            value,
            ...rest
        },
        ref: Ref<HTMLInputElement | null>
    ) => {
        const containerRef = useRef<HTMLDivElement | null>(null);
        const inputRef = useRef<HTMLInputElement | null>(null);
        const [pickerOpen, setPickerOpen] = useState<boolean>(false);
        const [pickerElement, setPickerElement] = useState<HTMLDivElement | null>(null);
        const { setFieldValue, setFieldTouched, touched } = form || {};
        const { name, value: fieldValue, onBlur: fieldOnBlur } = field || {};
        const [inputValue, setInputValue] = useState<string | undefined>(value || fieldValue || "");
        const prevPickerOpen = usePrevious(pickerOpen);
        const isMulti = Array.isArray(value || fieldValue);
        const defaultPlaceholder = useMemo(() => getShortDatePlaceholder(locale), [locale]);
        const defaultFormatDate = useMemo(() => getShortDateFormatter(locale).format, [locale]);

        placeholder = placeholder || defaultPlaceholder;
        formatDate = formatDate || defaultFormatDate;

        const selectedDays = useMemo(() => {
            if (isMulti) {
                return (value || fieldValue)
                    .map((val: string) => {
                        const m = moment(val, storageFormat);
                        return m.isValid() ? m.toDate() : null;
                    })
                    .filter(Boolean);
            } else {
                const m = moment(value || fieldValue, storageFormat);
                const isValid = m.isValid();
                return isValid ? [m.toDate()] : [];
            }
        }, [value, fieldValue, storageFormat, isMulti]);

        useImperativeHandle(ref || innerRef, () => inputRef.current);

        // sync input with selected value
        useEffect(() => {
            const m = moment(value || fieldValue, storageFormat);
            const isValid = m.isValid();
            setInputValue(isValid && !isMulti ? formatDate!(m.toDate()) : "");
        }, [value, fieldValue, storageFormat, formatDate, isMulti]);

        useEffect(() => {
            if (!pickerOpen && prevPickerOpen && name && !getIn(touched, name)) {
                setFieldTouched?.(name, true);
            }
        }, [pickerOpen, prevPickerOpen, name, setFieldTouched, touched]);

        const popper = usePopper(containerRef.current, pickerElement, {
            placement: pickerPlacement,
            modifiers: [
                {
                    name: "offset",
                    options: {
                        offset: [0, 4],
                    },
                },
            ],
        });

        const setValue = useCallback(
            (value: Date | undefined, toggle: boolean = false, clear: boolean = false) => {
                const formattedValue = value ? moment(value).format(storageFormat) : "";

                if (isMulti) {
                    if (!formattedValue) {
                        if (clear) {
                            name && setFieldValue?.(name, []);
                        }
                        return;
                    }

                    const included = fieldValue.includes(formattedValue);

                    if (included) {
                        if (toggle) {
                            name &&
                                setFieldValue?.(
                                    name,
                                    fieldValue.filter((date: string) => date !== formattedValue).sort()
                                );
                        }
                    } else {
                        name && setFieldValue?.(name, [...fieldValue, formattedValue].sort());
                    }
                } else {
                    name && setFieldValue?.(name, formattedValue);
                }
            },
            [fieldValue, isMulti, name, setFieldValue, storageFormat]
        );

        const handleInputClick = () => {
            // if picker is closed and input has focus, click reopens picker
            if (document.activeElement === inputRef.current && !pickerOpen) {
                setPickerOpen(true);
            }
        };

        const handleDaySelect = (value: Date, modifiers: DayModifiers, event: MouseEvent<HTMLDivElement>) => {
            if (modifiers[reactDayPickerClasses.disabled]) {
                return;
            }

            setValue(value, true);

            if (!isMulti) setInputValue(value ? formatDate!(value) : "");
            onSelect?.(value, "picker", event);

            if (closeOnDayClick) {
                setPickerOpen(false);
            }
        };

        const handleTodayClick = (_: Date, modifiers: DayModifiers, event: MouseEvent<HTMLButtonElement>) => {
            const proceed = onTodayClick?.(today, modifiers, event);

            if (proceed !== false) {
                if (todayUpdatesSelection) {
                    setValue(today, false);
                }
            }
        };

        const handleClearInput = useCallback(
            (event: MouseEvent<HTMLButtonElement>) => {
                setInputValue("");
                onSelect?.(undefined, "input", event);
                setValue(undefined, false, true);
            },
            [onSelect, setValue]
        );

        const closePicker = useCallback(() => {
            if (pickerOpen) {
                setPickerOpen(false);
            }
        }, [pickerOpen]);

        useOnClickOutside(containerRef, closePicker, pickerOpen);

        useOnFocusOutside(containerRef, closePicker, pickerOpen && !disabled);

        useKeyboard("Escape", closePicker, pickerOpen);

        return (
            <DayPickerContainer initialMonth={initialMonth} locale={locale}>
                {({ locale, month, setMonth }) => {
                    return (
                        <div className={styles.outerContainer} ref={containerRef}>
                            <div className={styles.inputContainer}>
                                <Input
                                    autoComplete="off"
                                    name={name}
                                    inputTest={/^[\d\/]*$/}
                                    afterClassName={styles.afterContainer}
                                    beforeClassName={styles.beforeContainer}
                                    before={
                                        <Icon className={inputStyles.inputIcon} verticalAlign="middle">
                                            <Calendar />
                                        </Icon>
                                    }
                                    form={form}
                                    disabled={disabled}
                                    onBlur={(e: FocusEvent<HTMLInputElement>) => {
                                        if (pickerElement?.contains(e.relatedTarget as Node | null)) {
                                            // dont process value yet if user is interacting with date picker
                                            return;
                                        }
                                        const date =
                                            (inputValue && parseDate(inputValue, placeholder, locale)) || undefined;

                                        if (date) {
                                            const isDisabled = ModifiersUtils.dayMatchesModifier(
                                                date,
                                                dayPickerProps?.disabledDays
                                            );

                                            if (isDisabled && !canInputDisabled) {
                                                setInputValue("");
                                                return;
                                            }
                                            setValue(date, false);
                                        } else {
                                            setInputValue("");
                                        }

                                        name && fieldOnBlur?.(e);
                                    }}
                                    onFocus={() => setPickerOpen(true)}
                                    onClick={handleInputClick}
                                    onClear={canClearInput ? handleClearInput : undefined}
                                    placeholder={placeholder}
                                    ref={inputRef}
                                    value={inputValue}
                                    {...rest}
                                    onChange={(value: string, event: ChangeEvent<HTMLInputElement>) => {
                                        setInputValue(value);
                                        const date = parseDate(value, placeholder, locale);
                                        if (date) {
                                            if (
                                                dayPickerProps?.fromMonth &&
                                                date.getTime() >= dayPickerProps.fromMonth.getTime()
                                            ) {
                                                setMonth(date);
                                            }

                                            const isDisabled = ModifiersUtils.dayMatchesModifier(
                                                date,
                                                dayPickerProps?.disabledDays
                                            );

                                            if (isDisabled && !canInputDisabled) {
                                                return;
                                            }

                                            onSelect?.(date, "input", event);
                                            setValue(date, false);
                                        }
                                    }}
                                    maxLength={placeholder?.length}
                                />
                            </div>
                            {pickerOpen && (
                                <div
                                    className={styles.datePickerContainer}
                                    style={popper.styles.popper}
                                    {...popper.attributes.popper}
                                    ref={setPickerElement}
                                >
                                    <StyledDayPicker
                                        {...dayPickerProps}
                                        month={month}
                                        onDayClick={(
                                            day: Date,
                                            modifiers: DayModifiers,
                                            event: MouseEvent<HTMLDivElement>
                                        ) => {
                                            handleDaySelect(day, modifiers, event);
                                        }}
                                        today={today}
                                        onTodayButtonClick={handleTodayClick}
                                        selectedDays={selectedDays}
                                    />
                                </div>
                            )}
                        </div>
                    );
                }}
            </DayPickerContainer>
        );
    }
);
