import { opsApi } from "API";
import { GraphQLInputModel } from "common/scaffolding/types";
import { isNew } from "common/utility/modelUtils";
import { categoryListFields, GraphQLCategorySummary } from "./listCategories";

export const saveCategory = async (
    locationId: string,
    categoryId: string | undefined,
    category: CategoryInputModel
) => {
    const isCreate = isNew(categoryId);

    const res = isCreate
        ? (await opsApi.graphQLQuery<{ createCategory: GraphQLCategorySummary }>(createQuery, { locationId, category }))
              .data.createCategory
        : (
              await opsApi.graphQLQuery<{ updateCategory: GraphQLCategorySummary }>(updateQuery, {
                  locationId,
                  categoryId,
                  category,
              })
          ).data.updateCategory;

    return res;
};

export interface CategoryInputModel extends GraphQLInputModel {
    type: string;
    services: string[];
    menus: string[];
    items: string[];
    image: string;
    special: boolean;
    showFromPrice: boolean;
}

const createQuery = `
    mutation($locationId: ID!, $category: CategoryUpdateInput!) {
        createCategory(locationId: $locationId, data: $category) {
            ${categoryListFields}
        }
    }
`;

const updateQuery = `
    mutation($locationId: ID!, $categoryId: ID!, $category: CategoryUpdateInput!) {
        updateCategory(locationId: $locationId, id: $categoryId, data: $category) {
            ${categoryListFields}
        }
    }
`;
