import { message } from "antd";
import { PageTitle } from "common/scaffolding/components/DataListingPage/PageTitle";
import { useEffect, useMemo, useState } from "react";
import { CatalogueItem } from "features/catalogue";
import { TreeItem } from "common/types/TreeItem";
import { AdjustmentType, EditablePriceLevel, EditablePriceListSettings, PriceListItem } from "..";
import { ProductTree } from "components/forms/ProductTree/ProductTree";
import { visit } from "common/data/treeUtils";
import { ActionFooter, StatusMessage } from "core/components/actionFooter";
import { Button } from "core/components/button";
import isEqual from "lodash/isEqual";

export interface Props {
    listItems: TreeItem[];
    onSave: (products: EditablePriceLevel[], modifiers: EditablePriceLevel[]) => void;
    products: CatalogueItem[];
    modifiers: CatalogueItem[];
    initialSelectedItems: PriceListItem[];
    onClose: () => void;
    priceList: EditablePriceListSettings;
}

export const PriceLevelItemSelector = ({
    listItems,
    onSave,
    products,
    initialSelectedItems,
    modifiers,
    priceList,
}: Props) => {
    const selectedTreeItems: TreeItem[] = useMemo(() => {
        const selectedChildren = initialSelectedItems
            .map((i) => i.children || [])
            .reduce((all, children) => {
                return all.concat(children);
            }, []);

        const selectedParents = initialSelectedItems.filter((i) => !i.children || !i.children.length);
        const items = mapPriceListItemToTreeItem(selectedChildren.concat(selectedParents));
        return items;
    }, [initialSelectedItems]);

    const defaultExpandedKeys = useMemo(() => {
        let expandedKeys: string[] = [];
        if (!listItems) {
            return expandedKeys;
        }
        visit(
            listItems,
            (node) => {
                if (node.type === "root") {
                    expandedKeys.push(node.key);
                }
            },
            false
        );
        return expandedKeys;
    }, [listItems]);

    const handleSave = (items: TreeItem[]) => {
        const saveItems = items.filter((i) => i.type !== "root");
        if (
            priceList.useGlobalAdjustment &&
            priceList.adjustmentType === AdjustmentType.FixedAmount &&
            priceList.globalAdjustment &&
            containsItemWithIncorrectPrice(saveItems, priceList.globalAdjustment)
        ) {
            message.error("Failed to save. There are some items that have invalid prices for this adjustment.");
        } else {
            onSave(mapSelectedProducts(saveItems, products), mapSelectedModifiers(saveItems));
        }
    };

    const containsItemWithIncorrectPrice = (items: TreeItem[], adjustmentAmount: number) => {
        const selectedItems = items.filter((i) => !i.children).reverse();

        for (let i = 0; i < selectedItems.length; i++) {
            let currentItem = selectedItems[i];

            switch (currentItem.type) {
                case "product":
                    if (products.some((p) => p.id === currentItem.id && (p?.price || 0) + adjustmentAmount < 0)) {
                        return true;
                    }
                    break;
                case "variant":
                    if (anyItemWithIncorrectPrice(products, currentItem, adjustmentAmount)) {
                        return true;
                    }
                    break;
                case "option":
                    if (anyItemWithIncorrectPrice(modifiers, currentItem, adjustmentAmount)) {
                        return true;
                    }
                    break;
            }
        }
        return false;
    };

    const anyItemWithIncorrectPrice = (
        catalogueItems: CatalogueItem[],
        currentItem: TreeItem,
        adjustmentAmount: number
    ) => {
        return catalogueItems.some(
            (item) =>
                item.id === currentItem.parent &&
                item.children &&
                item.children.some((c) => c.id === currentItem.id && (c?.price || 0) + adjustmentAmount < 0)
        );
    };

    const [selectedItems, setSelectedItems] = useState<TreeItem[]>(selectedTreeItems);

    // initialSelections as determined by the *tree component* after it has applied the initialSelectedItems
    const [initialTreeSelections, setInitialTreeSelections] = useState<TreeItem[] | null>(null);

    const [dirty, setDirty] = useState(false);

    useEffect(() => {
        if (!initialTreeSelections) {
            return;
        }

        // for accurate comparison we only compare sorted leaf node keys
        const initSelectedItemkeys = initialTreeSelections
            .filter((item) => !item.children)
            .map(({ key }) => key)
            .sort();
        const selectedItemKeys = selectedItems
            .filter((item) => !item.children)
            .map(({ key }) => key)
            .sort();

        setDirty(!isEqual(selectedItemKeys, initSelectedItemkeys));
    }, [selectedItems, initialTreeSelections]);

    return (
        <>
            <PageTitle
                title="Select items to add to your price list"
                description="Selected items will be added to your price list, you can modify this at any time."
            />
            <ProductTree
                defaultExpandedKeys={defaultExpandedKeys}
                initialSelectedItems={selectedTreeItems}
                datasource={listItems}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                onTreeInitialised={setInitialTreeSelections}
            />
            <ActionFooter forceDirty={dirty}>
                <StatusMessage forceDirty={dirty} />
                {/* Pricelist dirty check is more complex then on most forms; keep save button enabled */}
                <Button onClick={() => handleSave(selectedItems)}>Save</Button>
            </ActionFooter>
        </>
    );
};

const mapSelectedProducts = (items: TreeItem[], products: CatalogueItem[]) => {
    const selectedCategories = items.filter((n) => n.type === "category").map((n) => n.id);
    const categoryProducts: EditablePriceLevel[] = products
        .filter((p) => p.categories && p.categories.some((c) => selectedCategories.includes(c.id)))
        .map((p) => {
            return {
                id: p.id!,
            };
        });
    const categoryProductsIds = categoryProducts.map((p) => p.id);

    const selectedProducts: EditablePriceLevel[] = items
        .filter((n) => n.type === "product" && !categoryProductsIds.includes(n.id))
        .map((p) => {
            return {
                id: p.id,
            };
        });

    const selectedVariants = items.filter((n) => n.type === "variant");
    const variants: EditablePriceLevel[] = selectedVariants
        .filter(
            (v) =>
                v.parent &&
                !categoryProductsIds.includes(v.parent) &&
                !selectedProducts.map((x) => x.id).includes(v.parent)
        )
        .map((v) => {
            return {
                id: v.parent!,
                childId: v.id,
            };
        });

    return [...categoryProducts, ...selectedProducts, ...variants];
};

const mapSelectedModifiers = (items: TreeItem[]) => {
    const selectedModifiers = items
        .filter((n) => n.type === "modifier")
        .map((p) => {
            return {
                id: p.id,
            };
        });

    const selectedOptions = items.filter((n) => n.type === "option");

    const options: EditablePriceLevel[] = selectedOptions
        .filter((o) => o.parent && !selectedModifiers.map((m) => m.id).includes(o.parent))
        .map((o) => {
            return {
                id: o.parent!,
                childId: o.id,
            };
        });

    return [...selectedModifiers, ...options];
};

const mapPriceListItemToTreeItem: any = (items?: PriceListItem[]) =>
    items &&
    items.map((item) => {
        return {
            displayName: item.displayName,
            id: item.id,
            internalName: item.internalName,
            children: item.children && mapPriceListItemToTreeItem(item.children),
            type: item.itemType.toLocaleLowerCase(),
            parent: item.parentId,
        };
    });
