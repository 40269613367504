import { PosUISettings } from ".";

export const lightspeedLUISettings: PosUISettings = {
    servicePosId: {
        visible: false,
    },
    surchargeSku: {
        visible: true,
        title: "Surcharge Product ID",
        subTitle: "Enter the Product ID for the Surcharge item or leave blank to use the default",
    },
    priceListPosId: {
        visible: false,
    },
    taxPosId: {
        visible: false,
    },
};
