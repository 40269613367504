import { opsApi } from "API";
import { Promotion } from "../types";

export const listPromotions = async (locationId: string) => {
    try {
        const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId });

        const sortedPromotions = res.data.promotions;

        return sortedPromotions;
    } catch (e) {
        throw e;
    }
};

type GraphQLPayload = { promotions: Promotion[] };

export const promotionObject = `
    id,
    displayName,
    internalName,
    posId,
    code,
    generatedCodeCount,
    promotionType,
    status,
    dateUpdated,
    discount {
        type,
        value
    },
    priceListId,
    usageCount
`;

const query = `
    query ($locationId: ID!) {
        promotions(locationId: $locationId) {
            ${promotionObject},
            restrictions {
                minSpend,
                maxMemberUsage,
                maxCodeUsage,
                schedule {
                    startDate,
                    endDate
                }
            }
        }
    }
`;
