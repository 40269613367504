import { FeatureConfiguration } from "features";
import { withPermissions } from "features/location/components/withPermission";
import { GroupTabsPageContainer } from "./containers/GroupTabsPageContainer";
import { GroupTabsEditPackageContainer } from "./containers/GroupTabsEditPackageContainer";
import { reducer } from "./reducers";
import * as actions from "./actions";
import { EditComponent, ModalCrudRouteContainer, ListComponent } from "common/scaffolding/components/ModalCrudRoute";

export * from "features/groupTabs/types";
export { actions };

const packageList: ListComponent = {
    route: "",
    component: withPermissions(GroupTabsPageContainer, "grouptabs:management:read", "grouptabs:read"),
};
const packageEdit: EditComponent = {
    route: ":id",
    editTitle: "",
    createTitle: "",
    component: withPermissions(GroupTabsEditPackageContainer, "grouptabs:management:read", "grouptabs:read"),
};

export default function (config: FeatureConfiguration) {
    config.reducers.groupTabs = reducer;

    config.addRoutes(config.routes.locationRoutes, [
        <ModalCrudRouteContainer
            name="grouptabs"
            key="grouptabs"
            listComponent={packageList}
            editComponents={[packageEdit]}
            listWidth={22}
        />,
    ]);
}
