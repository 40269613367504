import { Select } from "antd";

import "./DataListingFilter.scss";
export interface Option {
    id: string;
    displayName: string;
}

export interface Props {
    name: string;
    options?: Option[];
    selectedItem?: string;
    onChange?: (id: string, option: any) => void;
    disabled: boolean;
    placeholder?: string;
    displaySelectAll?: boolean;
    selectAllText?: string;
}

export const DataListingFilter = (props: Props) => {
    const { selectedItem, options, onChange, disabled, displaySelectAll, selectAllText, placeholder } = props;

    const dropDown = (menu: React.ReactNode) => <div>{menu}</div>;

    return (
        <Select
            value={selectedItem ? selectedItem : displaySelectAll ? "0" : undefined}
            placeholder={placeholder && `Select ...`}
            onChange={onChange}
            dropdownRender={dropDown}
            className="select-datalisting-filter"
            disabled={disabled}
        >
            {displaySelectAll && (
                <Select.Option key="0" value="0">
                    {selectAllText || "Select All"}
                </Select.Option>
            )}
            {options &&
                options.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                        {item.displayName}
                    </Select.Option>
                ))}
        </Select>
    );
};
