import { opsApi } from "API";
import { EditableTaxClass } from "../types";

export const editTax = async (locationId: string, taxClassId: string): Promise<EditableTaxClass> => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId, taxClassId });
    return res.data.taxClass;
};

type GraphQLPayload = { taxClass: EditableTaxClass };

const query = `
    query ($locationId: ID!, $taxClassId: ID!) {
        taxClass(locationId: $locationId, id: $taxClassId) {
            id
            displayName
            internalName
            taxType
            taxRate
            posTaxId
            applyToAll
            exclusive
            taxAssignments {
                targetType
                targetId
            }
        }
    }
`;
