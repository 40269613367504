import { opsApi } from "API";
import { GraphQLVenueMembership } from "../types";
import { membershipQueryRet } from "./listMemberships";

export const editMembership = async (locationId: string, membershipId: string): Promise<GraphQLVenueMembership> => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId, membershipId });
    return res.data.locationMembershipProgram;
};

type GraphQLPayload = { locationMembershipProgram: GraphQLVenueMembership };

const query = `
    query ($locationId: ID!, $membershipId: ID!) {
        locationMembershipProgram(locationId: $locationId, membershipId: $membershipId) {
            ${membershipQueryRet}
        }
    }
`;
