import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { EditSectionPage, Props } from "../components/EditSectionPage";
import * as actions from "../actions";
import { EditableSection } from "../types";
import { mergeStatus } from "common/loader";
import { actions as serviceActions } from "features/service";
import { withLoader, PageLoadingProps } from "common/loader/withLoader";
import { isLoaded } from "common/loader/isLoaded";
import { AppState } from "features";
import { getCrudPermissions } from "features/location/selectors/getCrudPermissions";
import { DataProps, FunctionProps } from "types/utils";
import { LocationComponentProps, withLocation } from "features/location";
import { getLocationPermissions } from "features/location/selectors/getLocationPermissions";

const mapStateToProps = (
    state: AppState,
    { match: { params }, restaurantLocation }: LocationComponentProps & RouteComponentProps<RouteParams>
): DataProps<PageLoadingProps<Props>> => {
    const {
        sections: { edit },
        services: { list: serviceList },
    } = state;

    const overallFetchStatus = mergeStatus(edit.status, serviceList.status);

    if (isLoaded(edit) && isLoaded(serviceList)) {
        edit.data.locationTipsEnabled = restaurantLocation.allowTips;
        const locationTrayChargeEnabled = restaurantLocation.trayCharge?.enabled || false;
        const locationPermissions = getLocationPermissions(state);
        const takeawayOptions = restaurantLocation.takeawayOptions;
        const verifyTakeawaySectionDeleteAction = takeawayOptions.enabled && takeawayOptions.sectionId === edit.data.id;

        return {
            loadStatus: "loaded",
            saveStatus: edit.saveStatus === "saved" ? "saving" : edit.saveStatus,
            initialValues: edit.data,
            services: serviceList.data,
            permissions: getCrudPermissions(state).menudata,
            hasSectionSurchargesUpdatePermission: locationPermissions.hasAny("section:surcharges:update"),
            locationTrayChargeEnabled,
            restaurantLocation,
            verifyTakeawaySectionDeleteAction,
        };
    }

    return {
        loadStatus: overallFetchStatus,
    };
};

interface RouteParams {
    location: string;
    id: string;
}

const mapDispatchToProps = (
    dispatch: any,
    { match: { params } }: RouteComponentProps<RouteParams>
): FunctionProps<PageLoadingProps<Props>> => ({
    fetch: () => {
        dispatch(actions.edit(params.location, params.id));
        dispatch(serviceActions.list(params.location));
    },
    onSubmit: (data: EditableSection) => dispatch(actions.save(params.location, data)),
    onClone: (data: EditableSection) => dispatch(actions.save(params.location, data, true)),
    onArchive: () => dispatch(actions.archive(params.location)),
});

export const EditSectionPageContainer = withLocation(
    connect(mapStateToProps, mapDispatchToProps)(withLoader(EditSectionPage))
);
