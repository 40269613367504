import "./GroupTabsEditPackage.scss";
import { useState } from "react";
import { Form } from "antd";
import { isNew } from "common/utility/modelUtils";
import { Category } from "../types";
import { CheckInfo } from "common/types/CheckInfo";
import { ConfirmModal } from "common/scaffolding/components/ConfirmModal/ConfirmModal";
import { CrudPermissions } from "features/location/types/createCrudPermissions";
import { EditablePackage, PackageUpdateInput } from "..";
import { editablePackageSchema } from "../schema/EditablePackageSchema";
import { EditRow } from "common/scaffolding/components/EditRow";
import { Field, FieldProps, Formik, validateYupSchema, yupToFormErrors } from "formik";
import { FieldErrors, TextArea, TextInput } from "components/forms";
import { FilterCategory } from "features/catalogue/types/FilterCategory";
import { PageTitle } from "common/scaffolding/components/DataListingPage/PageTitle";
import { RouteComponentProps } from "react-router";
import { SaveStatus } from "common/loader";
import { TreeItem } from "common/types/TreeItem";
import { TreeItemSelector } from "./TreeItemSelector";
import { ActionFooter, StatusMessage } from "core/components/actionFooter";
import { Button } from "core/components/button";
import { WarningSolid } from "common/icons";
import { ScrollToFormikError } from "components/forms/ScrollToFormikError";

export interface Props {
    categories: FilterCategory[];
    groupTabsEnabled: boolean;
    hasLocationUpdatePermission: boolean;
    initialValues: PackageUpdateInput;
    onClose: () => void;
    onSubmit: (data: PackageUpdateInput, keepEditing: boolean) => void;
    package?: EditablePackage[];
    permissions: CrudPermissions;
    productTree: TreeItem[];
    saveStatus: SaveStatus;
}

const validate = (value: PackageUpdateInput) => {
    const context = {
        validatePos: document.getElementsByName("posId").length > 0,
    };

    return validateYupSchema(value, editablePackageSchema, undefined, context).then(
        () => ({}),
        (err: any) => yupToFormErrors(err)
    );
};

type CategoryValue = {
    id: string;
    items: string[];
};

export const GroupTabsEditPackage = ({
    initialValues,
    onSubmit,
    productTree,
    saveStatus,
}: Props & RouteComponentProps<any>) => {
    const [submitMode, setSubmitMode] = useState<string>("loaded");

    const handleSubmit = (data: PackageUpdateInput) => {
        setSubmitMode("submit");
        onSubmit(data, false);
    };

    const handleEnable = (data: PackageUpdateInput) => {
        setSubmitMode("enable");
        onSubmit(
            {
                ...data,
                enabled: true,
            },
            true
        );
    };

    const handleDisable = (data: PackageUpdateInput) => {
        setSubmitMode("disable");
        onSubmit(
            {
                ...data,
                enabled: false,
            },
            true
        );
    };

    const isCreate = isNew(initialValues.id);

    // normalise description so form.dirty works properly
    initialValues.description = initialValues.description || "";

    return (
        <>
            <Formik
                validate={validate}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validateOnBlur={true}
                validateOnChange={false}
            >
                {(form) => {
                    return (
                        <>
                            <ScrollToFormikError />
                            <PageTitle
                                title={isCreate ? "Create a package" : "Edit package"}
                                description="Create menus with limited products. Customers will be able to start a Group Tab using your preset package."
                            />
                            <Form onFinish={form.handleSubmit} autoComplete="off">
                                <EditRow labelCol={12} col={10} title="Package name" subTitle="23 character limit">
                                    <FieldErrors name="displayName" form={form}>
                                        <Field
                                            name="displayName"
                                            component={TextInput}
                                            placeholder="Package name"
                                            disabled={!form.values.enabled}
                                        />
                                    </FieldErrors>
                                </EditRow>
                                <EditRow
                                    labelCol={12}
                                    col={10}
                                    title="Description"
                                    titleInfo="(optional)"
                                    subTitle="80 character limit"
                                >
                                    <FieldErrors name="description" form={form}>
                                        <Field
                                            name="description"
                                            component={TextArea}
                                            placeholder="The description sits below the package name, use this space to describe your menu."
                                            disabled={!form.values.enabled}
                                        />
                                    </FieldErrors>
                                </EditRow>

                                <FieldErrors name="categories" form={form}>
                                    <Field name="categories">
                                        {({ form: { setFieldValue } }: FieldProps<Category>) => {
                                            return productTree ? (
                                                <TreeItemSelector
                                                    datasource={productTree}
                                                    selectedItems={form.values.categories}
                                                    initialSelectedItems={initialValues.categories}
                                                    enabled={form.values.enabled}
                                                    onCheck={(_, info: CheckInfo) => {
                                                        let checkedProductKeys =
                                                            (info.checkedNodes &&
                                                                (info.checkedNodes as TreeItem[])
                                                                    .filter((node: TreeItem) => node.type === "product")
                                                                    .map((node: TreeItem) => node.key)) ||
                                                            [];

                                                        let checkedCategoryIds = [
                                                            ...new Set(
                                                                checkedProductKeys.map(
                                                                    (key: string) => key.split(".")[0]
                                                                )
                                                            ),
                                                        ];

                                                        // flatten categories and filter out those without selections
                                                        const categoryNodes: TreeItem[] = productTree
                                                            .map((root) => root?.children || [])
                                                            .flat()
                                                            .filter((category) => {
                                                                return (
                                                                    category && checkedCategoryIds.includes(category.id)
                                                                );
                                                            });

                                                        const categories: CategoryValue[] = categoryNodes.map(
                                                            (category) => {
                                                                const items: string[] =
                                                                    category.children
                                                                        ?.filter((product) =>
                                                                            checkedProductKeys.includes(product.key)
                                                                        )
                                                                        .map(({ id }) => id) || [];
                                                                return {
                                                                    id: category.id,
                                                                    items,
                                                                };
                                                            }
                                                        );

                                                        setFieldValue("categories", categories);
                                                    }}
                                                />
                                            ) : null;
                                        }}
                                    </Field>
                                </FieldErrors>
                                <ActionFooter>
                                    {form.values.enabled && !isCreate && (
                                        <Button
                                            colorScheme="critical"
                                            role="secondary"
                                            type="button"
                                            loading={submitMode === "disable" && saveStatus === "saving"}
                                            onClick={() => {
                                                handleDisable(form.values);
                                            }}
                                        >
                                            {!(submitMode === "disable" && saveStatus === "saving") && <WarningSolid />}
                                            Disable package
                                        </Button>
                                    )}
                                    {(!form.values.enabled || (submitMode === "enable" && saveStatus === "saving")) && (
                                        <Button
                                            role="secondary"
                                            type="button"
                                            loading={submitMode === "enable" && saveStatus === "saving"}
                                            onClick={() => {
                                                handleEnable(form.values);
                                            }}
                                        >
                                            Enable package
                                        </Button>
                                    )}

                                    <StatusMessage />

                                    {form.values.enabled && (
                                        <Button
                                            type="submit"
                                            loading={submitMode === "submit" && saveStatus === "saving"}
                                            disabled={!form.dirty}
                                        >
                                            Save & close
                                        </Button>
                                    )}
                                </ActionFooter>
                            </Form>
                            <ConfirmModal
                                title={"You have unsaved changes."}
                                subTitle="Are you sure you want to leave this page without saving?"
                                isPrompt={!form.isSubmitting && form.dirty}
                                cancelText="Discard changes"
                                confirmText="Keep editing"
                            />
                        </>
                    );
                }}
            </Formik>
        </>
    );
};
