import { OpenInWindow } from "common/icons/OpenInWindow";
import { Button } from "core/components/button";
import { EmptyMessage } from "core/components/card/EmptyMessage";
import { Icon } from "core/components/icon/Icon";
import { Link, useLocation } from "react-router-dom";
import styles from "./EmptyPromotions.module.scss";

export const EmptyPromotions = () => {
    const location = useLocation();
    return (
        <div className={styles.container}>
            <EmptyMessage message="" />
            <h1 className={styles.heading}>Promotions</h1>
            <p className={styles.body}>Configure and run promotions through your venue.</p>
            <div className={styles.buttonsContainer}>
                <Button as="a" href="https://meandu.helpjuice.com/en_US/promotions" target="_blank" role="secondary">
                    <Icon className={styles.icon} size="small">
                        <OpenInWindow />
                    </Icon>
                    Learn more
                </Button>
                <Button as={Link} to={`${location.pathname}/new`}>
                    Add promotion
                </Button>
            </div>
        </div>
    );
};
