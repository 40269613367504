import "./EditServicePage.scss";

import * as React from "react";
import { FastField, FieldArray, Formik, FormikProps, validateYupSchema, yupToFormErrors } from "formik";
import { CheckboxInput, FieldErrors, RadioButtonGroup, TextInput } from "components/forms";
import { editableServiceSchema } from "features/service/schema";
import { MenuSummary } from "features/menu";
import { Effect } from "common/FormikEdffect";
import { EditDateRanges } from "./EditDateRanges";
import { EditDaysOfWeek } from "./EditDaysOfWeek/";
import { CourseSummary } from "features/course";
import { DaysOfWeek, EditableService } from "features/service/types";
import { Divider, Form, Input, Select } from "antd";
import { EditTimeRange } from "./EditTimeRange";
import { SortableTransfer } from "components/forms/SortableTransfer";
import { SaveStatus } from "common/loader";
import { EditRow } from "common/scaffolding/components/EditRow";
import { InputNumberAddon } from "components/forms/InputNumberAddon";
import { CrudPermissions } from "features/location/types/createCrudPermissions";
import { CategorySummary } from "features/category";
import { PosEditRow } from "components/forms/posUI/PosEditRow";
import { SectionSummary } from "features/section";
import { SearchInput } from "components/forms/SearchInput";
import { CmsItemSummary } from "common/scaffolding/types";
import { PriceListSummary } from "features/priceList";
import { MembershipLevelSummary } from "features/membershipLevel";
import classNames from "classnames";
import { LocationLocale } from "features/location/types/LocationLocale";
import { SurchargeAlert } from "./SurchargeAlert";
import { match } from "react-router-dom";
import { ActionFooter, DeleteButton, DuplicateButton, StatusMessage } from "core/components/actionFooter";
import { Button } from "core/components/button";
import { ScrollToFormikError } from "components/forms/ScrollToFormikError";
import { CardsContainer } from "core/components/card/CardsContainer";
import { Card, Row } from "core/components/card";

export interface Props {
    initialValues: EditableService;
    daysOfWeek: DaysOfWeek[];
    surcharge?: number;
    surchargeSku?: string;
    categories: CategorySummary[];
    menus: MenuSummary[];
    courses: CourseSummary[];
    onSubmit: (values: EditableService) => void;
    onClone: (values: EditableService) => void;
    onArchive: (values: EditableService) => void;
    saveStatus?: SaveStatus;
    permissions: CrudPermissions;
    previewService: (serviceId: string, membershipLevelId?: string) => void;
    sections: SectionSummary[];
    menuItems: CmsItemSummary[];
    priceLists: PriceListSummary[];
    membershipLevels: MembershipLevelSummary[];
    locationLocale: LocationLocale;
    surchargesEnabled: boolean;
    alcoholicDrinksRestrictionEnabled: boolean;
    match: match<RouteParams>;

    // TODO remove legacy Services support https://meanduapp.atlassian.net/browse/ROCK-745
    useLegacyLayout?: boolean;
}

type SubmitMode = "save" | "clone" | "archive";

interface RouteParams {
    region: string;
    location: string;
}

export interface State {
    submitMode: SubmitMode;
    previewMembership?: string;
    confirmDelete: boolean;
}

const availableOptions = [
    { text: "Enabled", value: true },
    { text: "Disabled", value: false },
];

const menuPriorityOptions = [
    { text: "Food", value: "food" },
    { text: "Drinks", value: "drink" },
];

export class EditServicePage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { submitMode: "save", confirmDelete: false };
    }

    handleDuplicate = (data: EditableService) => {
        this.setState({ submitMode: "clone" });
        this.props.onClone(data);
    };

    handleDelete = (data: EditableService) => {
        this.setState({ submitMode: "archive" });
        this.props.onArchive(data);
    };

    handleSubmit = (data: EditableService) => {
        this.setState({ submitMode: "save" });
        this.props.onSubmit(data);
    };

    render() {
        const {
            saveStatus,
            initialValues,
            categories,
            menus,
            permissions,
            previewService,
            sections,
            menuItems,
            priceLists,
            membershipLevels,
            surchargesEnabled,
            alcoholicDrinksRestrictionEnabled,
            match,
            // TODO remove legacy Services support https://meanduapp.atlassian.net/browse/ROCK-745
            useLegacyLayout = false,
        } = this.props;

        const isUpdate = !!initialValues.id;
        const disableFields = isUpdate && !permissions.canUpdate;
        const { submitMode } = this.state;
        const saving = saveStatus === "saving";

        return (
            <Formik
                validate={this.validate}
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={this.handleSubmit}
                key={this.props.initialValues.id}
            >
                {(form: FormikProps<EditableService>) => (
                    <Wrapper useLegacyLayout={useLegacyLayout}>
                        <Form
                            onFinish={form.handleSubmit}
                            layout="vertical"
                            autoComplete="off"
                            className={useLegacyLayout ? undefined : "modal-edit-service-form"}
                        >
                            <ScrollToFormikError />
                            <Effect
                                onChange={({ values: currentValues }, { values: nextValues }) => {
                                    if (nextValues.courses !== currentValues.courses) {
                                        if (nextValues.courses.indexOf(nextValues.defaultCourse) === -1) {
                                            nextValues.defaultCourse = "";
                                        }
                                    }
                                }}
                            />
                            <EditRow
                                title="Service name"
                                subTitle="Enter the display name of your service. This will appear to consumers."
                            >
                                <FieldErrors name="displayName" form={form}>
                                    <FastField
                                        name="displayName"
                                        component={TextInput}
                                        placeholder="Service Name"
                                        disabled={disableFields}
                                    />
                                </FieldErrors>
                            </EditRow>
                            <EditRow
                                title="Internal name"
                                subTitle="Enter the display name of your service. This will only appear in this portal."
                            >
                                <FieldErrors name="internalName" form={form}>
                                    <FastField
                                        name="internalName"
                                        component={TextInput}
                                        placeholder="Internal Name (optional)"
                                        disabled={disableFields}
                                    />
                                </FieldErrors>
                            </EditRow>
                            <Divider />

                            <EditRow
                                title="Days active"
                                subTitle="Control when your service is visible in the me&amp;u app."
                                className="active-days"
                            >
                                <FastField name="daysOfWeek" component={EditDaysOfWeek} disabled={disableFields} />
                            </EditRow>
                            <Divider />

                            <EditRow title="Duration" subTitle="Select what time your service is available.">
                                <EditTimeRange disabled={disableFields} />
                            </EditRow>
                            <Divider />

                            <EditRow
                                title="Date range (Optional)"
                                subTitle="Specify whether you would like to apply a date range where this service is available."
                            >
                                <FieldErrors name="times" form={form}>
                                    <FieldArray
                                        name="dates"
                                        render={(props) => (
                                            <EditDateRanges
                                                {...props}
                                                dateFormat="YYYY-MM-DD"
                                                disabled={disableFields}
                                            />
                                        )}
                                    />
                                </FieldErrors>
                            </EditRow>
                            <Divider />

                            <PosEditRow fieldName="servicePosId" col={12}>
                                <FastField
                                    name="posId"
                                    component={TextInput}
                                    displayName="POS ID"
                                    disabled={disableFields}
                                />
                            </PosEditRow>
                            <Divider />

                            {menus.length === 0 && (
                                <>
                                    <EditRow title="Menu order" subTitle="Which items should appear first in the menu?">
                                        <FastField
                                            name="menuPriority"
                                            component={RadioButtonGroup}
                                            options={menuPriorityOptions}
                                            disabled={disableFields}
                                        />
                                    </EditRow>
                                    <Divider />
                                    {alcoholicDrinksRestrictionEnabled && (
                                        <>
                                            <EditRow
                                                title="Apply alcoholic drinks restriction"
                                                subTitle="Limit alcoholic drinks per order."
                                            >
                                                <FastField
                                                    name="applyAlcoholicDrinksRestriction"
                                                    component={CheckboxInput}
                                                    disabled={disableFields}
                                                />
                                            </EditRow>
                                            <Divider />
                                        </>
                                    )}
                                    <EditRow
                                        title="Categories"
                                        subTitle="Select and add categories to your service"
                                        col={24}
                                    >
                                        <FastField
                                            name="categories"
                                            component={SortableTransfer}
                                            options={categories}
                                            disabled={disableFields}
                                        />
                                    </EditRow>
                                    <Divider />
                                </>
                            )}

                            {menus.length > 0 && (
                                <>
                                    <EditRow title="Menus" subTitle="Select and add menus to your service" col={24}>
                                        <FastField
                                            name="menus"
                                            component={SortableTransfer}
                                            options={menus}
                                            disabled={disableFields}
                                        />
                                    </EditRow>
                                    <Divider />
                                </>
                            )}

                            <EditRow title="Featured product" subTitle="Select a featured product for this service.">
                                <FieldErrors name="featured.item" form={form}>
                                    <FastField
                                        name="featured.item"
                                        component={SearchInput}
                                        placeholder="Search products"
                                        allowClear
                                        searchIcon
                                        disabled={disableFields}
                                        options={menuItems}
                                    />
                                </FieldErrors>
                            </EditRow>
                            <EditRow
                                title="Customise ‘Featured product’ title"
                                subTitle="Optional. Add your own title, such as ‘Happy Hour’ or ‘Local fave’. If left blank, we’ll use ‘Featured product’."
                            >
                                <FieldErrors name="featured.title" form={form}>
                                    <FastField
                                        name="featured.title"
                                        component={TextInput}
                                        placeholder="Featured product"
                                        disabled={disableFields}
                                    />
                                    <label className="featured-title-label">Optional. 20 character limit</label>
                                </FieldErrors>
                            </EditRow>
                            <Divider />

                            {!!priceLists.length && (
                                <>
                                    <EditRow
                                        title="Price list"
                                        subTitle="Select the price list that should apply to this service."
                                    >
                                        <FastField
                                            name="priceList"
                                            component={SearchInput}
                                            allowClear
                                            placeholder="No price list"
                                            options={priceLists}
                                            disabled={disableFields}
                                        />
                                    </EditRow>

                                    {!!priceLists.length && !!membershipLevels.length && (
                                        <div className="membership-prices">
                                            <h2>Member prices</h2>
                                            {membershipLevels.map((ml) => (
                                                <EditRow title={ml.displayName} key={ml.id}>
                                                    <FastField
                                                        name={`membershipPriceLists.${ml.id}`}
                                                        component={SearchInput}
                                                        allowClear
                                                        placeholder="No price list"
                                                        options={priceLists}
                                                        disabled={disableFields}
                                                    />
                                                </EditRow>
                                            ))}
                                        </div>
                                    )}

                                    <Divider />
                                </>
                            )}

                            <EditRow
                                title="Sections"
                                subTitle="Select the sections that should operate within your service."
                                col={24}
                            >
                                <FastField
                                    name="sections"
                                    component={SortableTransfer}
                                    options={sections}
                                    disabled={disableFields}
                                />
                            </EditRow>
                            <Divider />

                            <EditRow title="Surcharge" subTitle="Enter the surcharge applied to this service.">
                                {!surchargesEnabled ? (
                                    <FastField
                                        name="surcharge"
                                        component={InputNumberAddon}
                                        addonAfter="%"
                                        displayName="Surcharge"
                                        disabled={disableFields}
                                    />
                                ) : (
                                    <Input addonAfter="%" disabled={true} />
                                )}
                            </EditRow>

                            {surchargesEnabled && (
                                <SurchargeAlert region={match.params.region} location={match.params.location} />
                            )}

                            <PosEditRow fieldName="surchargeSku" col={12}>
                                {!surchargesEnabled ? (
                                    <FastField
                                        name="surchargeSku"
                                        component={TextInput}
                                        displayName="Surcharge SKU"
                                        disabled={disableFields}
                                    />
                                ) : (
                                    <Input disabled={true} />
                                )}
                            </PosEditRow>
                            <Divider />

                            <EditRow
                                title="Availability"
                                subTitle="Enable or disable this service temporarily, controlling the ability for guests to order this product."
                            >
                                <FastField
                                    name="available"
                                    component={RadioButtonGroup}
                                    options={availableOptions}
                                    disabled={disableFields}
                                />
                            </EditRow>

                            {/* TODO remove legacy Services support https://meanduapp.atlassian.net/browse/ROCK-745 */}
                            <ActionFooter position={useLegacyLayout ? "relative" : "fixed"}>
                                {permissions.canCreate && isUpdate && (
                                    <DuplicateButton
                                        buttonProps={{
                                            onClick: () => {
                                                if (form.isValid) {
                                                    this.handleDuplicate(form.values);
                                                } else {
                                                    // force ScrollToFormikError to run (wont submit because form is invalid)
                                                    form.submitForm();
                                                }
                                            },
                                        }}
                                        saving={saving}
                                        submitMode={submitMode}
                                    />
                                )}
                                {permissions.canDelete && isUpdate && (
                                    <DeleteButton
                                        buttonProps={{
                                            onClick: () => this.handleDelete(form.values),
                                        }}
                                        saving={saving}
                                        submitMode={submitMode}
                                        confirmDelete={{
                                            title: "Are you sure you want to delete this service?",
                                        }}
                                    />
                                )}
                                {isUpdate && (
                                    <div className="preview-wrapper">
                                        {isUpdate && (
                                            <Button
                                                role="secondary"
                                                type="button"
                                                className={classNames(
                                                    "preview",
                                                    !!membershipLevels.length && "with-membership"
                                                )}
                                                onClick={() =>
                                                    previewService(form.values.id!, this.state.previewMembership)
                                                }
                                            >
                                                Preview
                                            </Button>
                                        )}
                                        {/* TODO redesign legacy preview / membership controls */}
                                        {!!membershipLevels.length && (
                                            <Select
                                                key="preview-membership"
                                                className="preview-membership"
                                                popupClassName="preview-membership-options-popup"
                                                placement={"topLeft"}
                                                defaultValue={"Default"}
                                                onSelect={(value?: string) => {
                                                    this.setState({ previewMembership: value });
                                                    previewService(form.values.id!, value);
                                                }}
                                            >
                                                <Select.OptGroup label="Member prices">
                                                    <Select.Option
                                                        value="Default"
                                                        key={"previewMembeshipLevelOption-base"}
                                                        title="Base level"
                                                    >
                                                        Default
                                                    </Select.Option>
                                                    {membershipLevels.map((ml) => (
                                                        <Select.Option
                                                            key={
                                                                "previewMembeshipLevelOption-" + ml.id + ml.displayName
                                                            }
                                                            value={ml.id}
                                                            title={ml.displayName}
                                                        >
                                                            {ml.displayName}
                                                        </Select.Option>
                                                    ))}
                                                </Select.OptGroup>
                                            </Select>
                                        )}
                                    </div>
                                )}

                                <StatusMessage />
                                {!disableFields && (
                                    <Button
                                        loading={submitMode === "save" && saving}
                                        type="submit"
                                        disabled={!form.dirty && isUpdate}
                                    >
                                        Save
                                    </Button>
                                )}
                            </ActionFooter>
                        </Form>
                    </Wrapper>
                )}
            </Formik>
        );
    }

    validate = (value: EditableService) => {
        const { courses } = this.props;

        const context = { courses };

        return validateYupSchema(value, editableServiceSchema, undefined, context).then(
            () => ({}),
            (err: any) => yupToFormErrors(err)
        );
    };
}

// TODO remove legacy Services support https://meanduapp.atlassian.net/browse/ROCK-745
interface WrapperProps {
    useLegacyLayout: boolean;
}

function Wrapper({ children, useLegacyLayout }: React.PropsWithChildren<WrapperProps>) {
    if (useLegacyLayout) {
        return <>{children}</>;
    }

    return (
        <CardsContainer>
            <Card className="modal-edit-service-form">
                <Row>{children}</Row>
            </Card>
        </CardsContainer>
    );
}
