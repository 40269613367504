export interface AuditLogEntry {
    id: string;
    timestamp: string;

    scope: AuditLogScope;
    principal: AuditLogPrincipal;
    event: AuditLogEvent;
    telemetry: AuditLogTelemetry;
}

export interface AuditLogScope {
    type: AuditLogScopeType;
    id: string;
}

export enum AuditLogScopeType {
    Location = 0,
    Group = 1,
    Global = 2,
    Member = 3,
    External = 4
}

export interface AuditLogPrincipal {
    type: AuditLogPrincipalType;
    id: string | null;
    name: string;
    ipAddress: string | null;

    parent: AuditLogPrincipal | null;
}

export enum AuditLogPrincipalType {
    Anonymous = 0,
    FunctionKey = 1,
    User = 2,
    Staff = 3,
}

export interface AuditLogEvent {
    eventName: string;
    targetType: string | null;
    targetId: string | null;
    targetName: string | null;
    data: string | null;
}

export interface AuditLogTelemetry {
    operationId: string | null;
    operationName: string | null;
}