import styles from "./PopupButton.module.scss";

import { forwardRef } from "react";
import { Chevron } from "core/components/icon/Chevron";
import { Icon } from "core/components/icon/Icon";
import { ButtonProps } from "core/components/popup";

export const PopupButton = forwardRef<HTMLButtonElement, ButtonProps>(({ expanded, label, onClick }, ref) => {
    return (
        <button className={styles.button} ref={ref} onClick={onClick} aria-expanded={expanded} aria-haspopup={true}>
            <span className={styles.label}>{label}</span>
            <Icon className={expanded ? styles.iconExpanded : styles.iconCollapsed}>
                <Chevron />
            </Icon>
        </button>
    );
});
