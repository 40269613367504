import { opsApi } from "API";
import { GraphQLItem } from "../../../common/scaffolding/types";

export const listStaffAccessLevels = async (locationId: string) => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query);
    return res.data.staffAccessLevels;
};

type GraphQLPayload = { accessLevels: GraphQLItem[] };

export interface GraphQLAccessLevel extends GraphQLItem {
    description: string;
    isDefault: boolean;
    displayOrder: number;
}
const query = `
    query { 
        staffAccessLevels { 
            id, 
            displayName,
            description,
            isDefault,
            displayOrder
        }
    }
`;
