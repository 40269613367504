import { opsApi } from "../../../API";

export const archiveModifier = async (locationId: string, modifierId: string) => {
    await opsApi.graphQLQuery(query, { locationId, modifierId });
};

const query = `
    mutation($locationId: ID!, $modifierId: ID!) {
        deleteModifier(locationId: $locationId, id: $modifierId) {
            id
        }
    }
`;
