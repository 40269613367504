import { createAction } from "../reducers/list";
import { MenuSummary } from "../types";
import { normalizeSummaryItems } from "foundation/dataConventions/normalizeSummaryItems";
import { applySummaryDisplayName } from "foundation/dataConventions/applySummaryDisplayName";
import { scaffoldListAction } from "../../../common/scaffolding/actions/scaffoldListAction";
import { GraphQLMenuSummary, listMenus } from "../api/listMenus";

export const list = (location: string) =>
    scaffoldListAction<MenuSummary>(
        (state) => state.menus.list,
        createAction,
        async () => {
            const menus = await listMenus(location);

            return normalizeSummaryItems<GraphQLMenuSummary, MenuSummary>(menus, (menu) => ({
                ...menu,
                services: menu.services.map(applySummaryDisplayName),
            }));
        }
    );
