import { isNew } from "common/utility/modelUtils";
import { createAction } from "../reducers/edit";
import { editSection, GraphQLSection } from "../api/editSection";
import { scaffoldEditAction } from "common/scaffolding/actions/scaffoldEditAction";
import { EditableSection } from "../types";
import { config } from "common/config";
import { region } from "features/region";

export const edit = (location: string, sectionId: string) =>
    scaffoldEditAction<EditableSection>(
        sectionId,
        (state) => state.sections.edit,
        createAction,
        async (): Promise<EditableSection> => {
            if (isNew(sectionId)) {
                return newEditableSection();
            }

            const section = await editSection(location, sectionId);
            return mapEditableSection(section);
        }
    );

const mapEditableSection = (input: GraphQLSection): EditableSection => ({
    id: input.id,
    displayName: input.displayName,
    services: input.services.map((m) => m.id),
    posId: input.posId,
    posMenuId: input.posMenuId,
    sectionType: input.sectionType,
    enableTips: !!input.enableTips,
    applySurcharges: !!input.applySurcharges,
    applyTrayCharge: !!input.applyTrayCharge,
    demoTableNumber: input.demoTableNumber || "demo",
    demoQr: {
        isEnabled: !!input.demoQr?.isDemo,
        url: `${config.REACT_APP_ORDER_APP_URL}/qr?t=${input.demoQrToken}&r=${region.getActiveRegion().id}`,
        timeToLiveSeconds: input.demoQr?.timeToLiveSeconds,
        dateUpdated: input.demoQr?.dateUpdated ? Date.parse(input.demoQr.dateUpdated.toString()) : undefined,
        selectedTimeToLive: null,
    },
});

const newEditableSection = (): EditableSection => ({
    displayName: "",
    services: [],
    posId: null,
    posMenuId: null,
    sectionType: null,
    enableTips: true,
    applySurcharges: true,
    applyTrayCharge: false,
});
