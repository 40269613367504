import { AppDispatch, AppState } from "features";
import { createAction } from "../reducers/list";
import { listPromotions } from "../api/listPromotions";
import { mapGraphQLPromotionToPromotion } from "../types";

export const list = (location: string) => async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
        dispatch(createAction.loading());
        const promotions = await listPromotions(location);
        const list = promotions.map(mapGraphQLPromotionToPromotion);
        dispatch(createAction.loaded(list));
    } catch (e) {
        dispatch(createAction.loadFailed(e));
    }
};
