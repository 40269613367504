import { CmsItemSummary, GraphQLItem } from "common/scaffolding/types";

export enum PromotionDiscountType {
    PERCENTAGE = "PERCENTAGE",
    FIXEDAMOUNT = "FIXEDAMOUNT",
    PRICELIST = "PRICELIST",
}

export interface PromotionDiscount {
    type: PromotionDiscountType;
    value?: number | string;
}

export interface PromotionItems {
    productIds: string[];
    variantIds: string[];
}

export interface PromotionSchedule {
    startDate: string | null;
    endDate: string | null;
}

export interface PromotionRestrictions {
    minSpend: number | string;
    maxMemberUsage: number | string;
    maxCodeUsage: number | string;
    requiredItems?: null | PromotionItems;
    schedule: PromotionSchedule;
}

export enum PromotionScheduleState {
    STAGED = 1,
    ACTIVE = 2,
    SCHEDULED = 3,
    EXPIRED = 4,
}

export type PromotionScheduleStateKeys = keyof PromotionScheduleState;

export interface Promotion extends GraphQLItem, CmsItemSummary {
    posId?: string;
    code: string;
    generatedCodeCount?: number;
    promotionType: PromotionType;
    status?: PromotionScheduleStateKeys | null;
    discount: PromotionDiscount;
    restrictions: PromotionRestrictions;
    usageCount: number;
    priceListId: string;
    dateUpdated?: string;
}

export enum PromotionType {
    SINGLECODE = "SINGLECODE",
    MULTIPLECODE = "MULTIPLECODE",
}

export interface SortablePromotion extends Promotion {
    status: PromotionScheduleStateKeys;
    restrictions: Pick<PromotionRestrictions, "minSpend" | "maxMemberUsage" | "maxCodeUsage" | "requiredItems"> & {
        schedule: {
            startDate: string;
            endDate: string | null;
        };
    };
}

export const promotionItemTypes = ["product", "variant"];
