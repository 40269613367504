import { opsApi } from "API";
import { GraphQLItemId, GraphQLItem } from "../../../common/scaffolding/types";

export const editCourse = async (locationId: string, courseId: string): Promise<GraphQLCourse> => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId, courseId });
    return res.data.course;
};

type GraphQLPayload = { course: GraphQLCourse };

export interface GraphQLCourse extends GraphQLItem {
    sku: string;
    categories: Array<GraphQLItemId>;
}

const query = `
    query($locationId: ID!, $courseId: ID!) { 
        course(locationId: $locationId, id: $courseId) {
            id,
            internalName,
            displayName,
            sku,
            categories{
                id
            }
        }
    }
`;
