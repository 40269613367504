import { AppState } from "features";
import { EditCompanyDetailsPage, EditLocationProps } from "../components/EditCompanyDetails";
import { LocationComponentProps, withLocation } from "./withLocation";
import * as actions from "../actions";
import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { PageLoadingProps, withLoader } from "common/loader/withLoader";
import { EditableLocation } from "../types/EditableLocation";
import { generatePath, RouteComponentProps, withRouter } from "react-router";
import { isLoaded } from "common/loader/isLoaded";
import { mergeStatus } from "common/loader/mergeStatus";
import { actions as groupActions } from "features/group";
import { getLocationPermissions } from "../selectors/getLocationPermissions";
import { region } from "features/region";

function mapStateToProps(
    state: AppState,
    { restaurantLocation, match: { params } }: LocationComponentProps & RouteComponentProps<RouteParams>
): DataProps<PageLoadingProps<EditLocationProps>> {
    const {
        locations: { edit },
        groups: { list: groupList },
    } = state;

    const overallFetchStatus = mergeStatus(edit.status, groupList.status);

    if (isLoaded(edit) && isLoaded(groupList)) {
        // using Gdpr to detect UK for now as more reliable than other fields, may need to revisit
        const { isGdpr } = region.getActiveRegion();
        const hasGroupBillingSystemId = !!restaurantLocation.group?.billingSystemId;

        return {
            initialValue: edit.data,
            saveStatus: edit.saveStatus,
            loadStatus: "loaded",
            groups: groupList.data,
            permissions: getLocationPermissions(state),
            requiresBillingSystemId: !(isGdpr || hasGroupBillingSystemId),
            requiresStripeCommission: restaurantLocation.enableStripeOnboarding,
        };
    }

    return {
        loadStatus: overallFetchStatus,
    };
}

interface RouteParams {
    location: string;
}

interface OwnProps {
    id: string;
}

const onSave = () => {
    //do nothing for now, just prevent nav away from page
};

const mapDispatchToProps = (
    dispatch: any,
    { restaurantLocation, match: { params } }: OwnProps & LocationComponentProps & RouteComponentProps<RouteParams>
): FunctionProps<PageLoadingProps<EditLocationProps>> => ({
    fetch: () => {
        dispatch(actions.edit(restaurantLocation.id));
        dispatch(groupActions.list());
    },
    onSubmit: (data: EditableLocation) => dispatch(actions.save(data.slug, data, onSave)),
    onDelete: async () => {
        await dispatch(
            actions.archive(restaurantLocation.id, () => {
                window.location.href = generatePath("/:region", params as any);
            })
        );
    },
});

export const EditCompanyDetailsPageContainer = withRouter(
    withLocation(connect(mapStateToProps, mapDispatchToProps)(withLoader(EditCompanyDetailsPage)))
);
