import { EditableCmsItem } from "common/scaffolding/types";
import { normaliseText } from "./StringUtils";

interface SearchableType<T extends SearchableType<T>> extends EditableCmsItem {
    children?: T[];
}

export type MatchText = (text?: string | null) => boolean;

export type MatchCallback<T> = (value: T, matchText: MatchText) => boolean;

function defaultMatch<T extends SearchableType<T>>(value: T, matchText: MatchText) {
    return matchText(value.displayName) || (value.internalName && matchText(value.internalName));
}

export const filterSearchable = <T extends SearchableType<T>>(
    items: T[],
    searchTerm: string,
    ignoreCase: boolean = true,
    trimNonMatchingChildren: boolean = false,
    customMatch: MatchCallback<T> | undefined = undefined
) => {
    const normaliseSearchedTerm = normaliseText(searchTerm, ignoreCase);

    const matchText: MatchText = (text?: string | null) =>
        !!text && normaliseText(text, ignoreCase).includes(normaliseSearchedTerm);

    const matchItem = (value: T) => defaultMatch(value, matchText) || (!!customMatch && customMatch(value, matchText));

    const newItems = items.reduce((result: T[], item) => {
        const matchedItem = { ...item };

        if (matchItem(item)) {
            matchedItem.children = trimNonMatchingChildren ? getChildMatches(item, matchItem) : item.children;
            result.push(matchedItem);
            return result;
        }

        const childMatches = getChildMatches(item, matchItem);

        if (childMatches && childMatches.length) {
            matchedItem.children = childMatches;
            result.push(matchedItem);
        }
        return result;
    }, []);

    return [...newItems];
};

const getChildMatches = <T extends SearchableType<T>>(item: T, match: (value: T) => boolean) => {
    return item.children && item.children.filter(match);
};
