import { connect } from "react-redux";
import { Props, PosCataloguePage } from "features/posCatalogue/components/PosCataloguePage";
import { RouteComponentProps, withRouter, generatePath } from "react-router";
import { AppState } from "features";
import { getCrudPermissions } from "features/location/selectors/getCrudPermissions";
import { DataProps, FunctionProps } from "types/utils";
import { LocationComponentProps, withLocation } from "features/location";
import { LocationRouteParams } from "common/types";
import { withLoader, PageLoadingProps } from "common/loader/withLoader";
import { fetchPosCatalogue } from "../actions/fetchPosCatalogue";
import { isLoaded } from "common/loader/isLoaded";

interface CataloguePageRouteParams extends LocationRouteParams {
    tab?: string;
}

const mapStateToProps = (
    state: AppState,
    { location, match: { params } }: LocationComponentProps & RouteComponentProps<CataloguePageRouteParams>
): DataProps<PageLoadingProps<Props>> => {
    const list = state.posCatalogue.list;

    if (isLoaded(list)) {
        return {
            loadStatus: list.status,
            permissions: getCrudPermissions(state).catalogue,
            urlTab: params.tab,
            query: location.search,
        };
    }

    return {
        loadStatus: list.status,
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { history, match: { params, path } }: RouteComponentProps<CataloguePageRouteParams>
): FunctionProps<PageLoadingProps<Props>> => {
    return {
        changeTab: (tab: string) => {
            history.replace(
                generatePath(path, {
                    ...params,
                    tab,
                })
            );
        },
        fetch: () => dispatch(fetchPosCatalogue(params.location)),
    };
};

export const PosCataloguePageContainer = withRouter(
    withLocation(connect(mapStateToProps, mapDispatchToProps)(withLoader(PosCataloguePage)))
);
