export interface MenuItem<T> {
    id: string;
    data: T;
}

export interface MenuItemParent<T> extends MenuItem<T> {
    children: MenuNode<T>[];
}

export type MenuNode<T> = MenuItem<T> | MenuItemParent<T>;

export function isParent<T>(node: MenuItem<T> | null): node is MenuItemParent<T> {
    if (!node) {
        return false;
    }
    return "children" in node;
}

export function isChild<T>(node: MenuItem<T> | null): node is MenuItem<T> {
    return !isParent(node);
}
