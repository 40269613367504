import styles from "./EditNewLocationModal.module.scss";

import { actions as groupActions } from "features/group";
import { currencySelectOptions } from "../data/CurrencySelectOptions";
import { FastField } from "formik";
import { FieldErrors } from "core/components/form/fieldErrors";
import { getActiveLocation, getLocationPermissions } from "../selectors/getLocationPermissions";
import { getGroupListState, getGroupList, getGroupOptions } from "features/group/selectors";
import { GroupSelectOrCreate } from "features/group/components/GroupSelectOrCreate";
import { Input } from "core/components/form/input";
import { isLoaded } from "common/loader/isLoaded";
import { PageLoading } from "core/components/pageLoading";
import { Row } from "core/components/card";
import { Select } from "core/components/form/select";
import { Switch } from "core/components/form/switch";
import { timeZoneSelectOptions } from "../data/TimeZoneSelectOptions";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLoadStatus } from "common/hooks";
import { Tooltip } from "core/components/tooltip/Tooltip";
import { Icon } from "core/components/icon";
import { InfoSolid } from "common/icons";
import { orderFlowOptions } from "features/locationSettings/types/OrderFlow";

export const EditNewLocationFields = () => {
    const dispatch = useDispatch();

    const activeLocation = useSelector(getActiveLocation);
    const activeLocationName = activeLocation?.displayName;

    const groupList = useSelector(getGroupListState);
    const groups = useSelector(getGroupList);
    const groupOptions = useSelector(getGroupOptions);

    const permissions = useSelector(getLocationPermissions);
    const canCreateGroup = permissions.has("group:create");
    const canCreateParentLocation = permissions.has("location:parent:create");

    const fetch = useCallback(() => {
        !isLoaded(groupList) && dispatch(groupActions.list());
    }, [dispatch, groupList]);

    const fetchStatus = useLoadStatus([groupList.status], fetch);

    if (fetchStatus === "loading" || fetchStatus === "unloaded") {
        return (
            <Row>
                <PageLoading />
            </Row>
        );
    }

    return (
        <>
            {canCreateParentLocation && (
                <Row collapse="down">
                    <div>Create this location as a brand</div>
                    <FastField name="createAsBrand" type="checkbox" component={Switch} />
                </Row>
            )}
            {activeLocation && (
                <Row collapse="down">
                    <div>
                        Copy settings and menu from{" "}
                        <span className={styles.truncateContainer}>
                            <span className={styles.truncate}>{activeLocationName}</span>
                        </span>
                    </div>
                    <FastField name="clone" type="checkbox" component={Switch} />
                </Row>
            )}
            <Row collapse="down">
                <FieldErrors fieldNames={["displayName"]}>
                    <FastField
                        autoComplete="off"
                        name="displayName"
                        component={Input}
                        label="Display name"
                        placeholder="Display name"
                        markRequired
                    />
                </FieldErrors>
            </Row>
            <Row collapse="down">
                <FieldErrors fieldNames={["slug"]}>
                    <FastField
                        autoComplete="off"
                        name="slug"
                        component={Input}
                        label="Slug"
                        placeholder="Slug"
                        markRequired
                        labelAfter={
                            <Tooltip
                                content="Enter a short name that will appear in the CMS URL. This value must be unique."
                                offset={[-12, 12]}
                                placement="bottom-start"
                                portal="#modal"
                            >
                                <Icon
                                    className="ml-2"
                                    verticalAlign="middle"
                                    size="small"
                                    style={{ color: "var(--color-icon-primary-subtle)" }}
                                >
                                    <InfoSolid />
                                </Icon>
                            </Tooltip>
                        }
                    />
                </FieldErrors>
            </Row>
            {(!!groups.length || canCreateGroup) && (
                <Row collapse="down">
                    <FieldErrors fieldNames={["groupId", "newGroupName"]}>
                        <GroupSelectOrCreate
                            canCreateGroup={canCreateGroup}
                            name="groupId"
                            newGroupFieldName="newGroupName"
                            options={groupOptions}
                        />
                    </FieldErrors>
                </Row>
            )}
            <Row collapse="down">
                <FieldErrors fieldNames={["timeZone"]}>
                    <FastField
                        name="timeZone"
                        component={Select}
                        label="Time Zone"
                        markRequired
                        placeholder="Select"
                        options={timeZoneSelectOptions}
                        menuPlacement="auto"
                        menuPosition="fixed"
                        blurInputOnSelect={false}
                    />
                </FieldErrors>
            </Row>
            <Row collapse="down">
                <FieldErrors fieldNames={["locale"]}>
                    <FastField
                        name="locale"
                        component={Select}
                        label="Currency"
                        markRequired
                        placeholder="Select"
                        options={currencySelectOptions}
                        menuPlacement="auto"
                        menuPosition="fixed"
                        blurInputOnSelect={false}
                    />
                </FieldErrors>
            </Row>
            <Row>
                <FieldErrors fieldNames={["orderFlow"]}>
                    <FastField
                        name="orderFlow"
                        component={Select}
                        label="Order Flow"
                        markRequired
                        placeholder="Select"
                        options={orderFlowOptions}
                        menuPlacement="auto"
                        menuPosition="fixed"
                        blurInputOnSelect={false}
                    />
                </FieldErrors>
            </Row>
        </>
    );
};
