import { opsApi } from "API";
import { VenueMembershipProgram } from "../types";

export const listMemberships = async (locationId: string) => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId });
    return res.data.locationMembershipPrograms;
};

type GraphQLPayload = { locationMembershipPrograms: VenueMembershipProgram[] };

const membershipLevels = `
    membershipLevels {
        id,
        displayName,
        priceList {
            id,
            displayName
        }
        dateCreated,
        dateUpdated
    }
`;

export const membershipQueryRet = `   
    id,
    displayName,
    type,
    authentication {
        method
    },
    links { key, value },
    ${membershipLevels}
    `;

const query = `
    query ($locationId: ID!) {
        locationMembershipPrograms(locationId: $locationId) {
          ${membershipQueryRet}
        }
    }
`;
