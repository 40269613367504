import { Card, Row, Title } from "core/components/card";
import { EditableProduct } from "features/catalogue/types";
import { EditTagGroup } from "./EditTagGroup";
import { FastField, useFormikContext } from "formik";
import { FieldErrors } from "core/components/form/fieldErrors";
import { Input } from "core/components/form/input";
import { MenuItemTemplate } from "features/menuitemtemplate";
import { TagGroup } from "features/tags";
import { useCallback, useMemo } from "react";

interface Props {
    tagList: TagGroup[];
    templateList: MenuItemTemplate[];
    disableFields: boolean;
}

export const ProductTags = ({ disableFields, tagList, templateList }: Props) => {
    const {
        setFieldValue,
        values: { template: selectedTemplateId },
    } = useFormikContext<EditableProduct>();

    const selectedTemplate = templateList.find((template) => template.id === selectedTemplateId);

    const tagGroups = useMemo(() => {
        if (!selectedTemplate) {
            return [];
        }

        const tg = tagList.filter((tag: TagGroup) => selectedTemplate.tagGroups.includes(tag.id));

        const sorted: TagGroup[] = [];

        // try and order tag groups by parents followed by their children
        tg.forEach((tagGroup) => {
            const sortedParentIndex = sorted.findIndex((tg) => tg.id === tagGroup.parentTagGroup);
            const sortedChildIndex = sorted.findIndex((tg) => tg.parentTagGroup === tagGroup.id);

            if (sortedParentIndex !== -1) {
                sorted.splice(sortedParentIndex + 1, 0, tagGroup);
            }

            if (sortedChildIndex !== -1) {
                sorted.splice(sortedChildIndex, 0, tagGroup);
            }

            if (sortedParentIndex === -1 && sortedChildIndex === -1) {
                sorted.push(tagGroup);
            }
        });

        return sorted;
    }, [selectedTemplate, tagList]);

    const descriptors = selectedTemplate?.descriptors || [];

    const handleTagGroupChange = useCallback(
        (name: string) => {
            const [, tagGroupId] = name.split(".");

            if (tagGroupId) {
                const [{ id: childTagGroupId = "" } = {}] = tagGroups.filter(
                    (tagGroup) => tagGroup.hasOwnProperty("parentTagGroup") && tagGroup.parentTagGroup === tagGroupId
                );

                if (childTagGroupId) {
                    setFieldValue(`tags.${childTagGroupId}`, []);
                }
            }
        },
        [setFieldValue, tagGroups]
    );

    if (!tagGroups.length) {
        return null;
    }

    return (
        <Card>
            <Row>
                <Title title="Tags" />
            </Row>
            {tagGroups.map((tagGroup) => (
                <Row collapse="up" key={tagGroup.id}>
                    <FieldErrors fieldNames={["tags"]}>
                        <EditTagGroup
                            disableFields={disableFields}
                            tagGroup={tagGroup}
                            key={tagGroup.id}
                            onChange={handleTagGroupChange}
                        />
                    </FieldErrors>
                </Row>
            ))}
            {descriptors.map((descriptor) => (
                <Row collapse="up" key={descriptor.key}>
                    <FieldErrors fieldNames={[`descriptors.${descriptor.key}`]} key={`descriptors.${descriptor.key}`}>
                        <FastField
                            component={Input}
                            disabled={disableFields}
                            label={descriptor.displayName}
                            name={`descriptors.${descriptor.key}`}
                            placeholder={descriptor.displayName}
                        />
                    </FieldErrors>
                </Row>
            ))}
        </Card>
    );
};
