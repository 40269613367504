import { scaffoldEditAction } from "common/scaffolding/actions/scaffoldEditAction";
import { isNew } from "common/utility/modelUtils";
import { createAction } from "../reducers/edit";
import {
    DayOfWeekDictionary,
    EditableSurcharge,
    SurchargesSummary,
    SurchargeType,
    templateOneTimeSurcharges,
    templateSurcharges,
} from "../types";
import { editSurcharges, GraphQLSurcharge, GraphQLSurcharges } from "../api/editSurcharges";
import { displayPercentage } from "../surchargeHelper";

export const edit = (locationId: string) =>
    scaffoldEditAction(
        locationId,
        (state) => state.surcharges.edit,
        createAction,
        async () => {
            if (isNew(locationId)) {
                return { locationId: "new", surcharges: [] };
            }
            const surchargeData = await editSurcharges(locationId);
            return mapSurcharges(locationId, surchargeData);
        }
    );

export const mapSurcharges = (locationId: string, surcharges: GraphQLSurcharges): SurchargesSummary => {
    const templateRows = templateSurcharges.filter((t) => !surcharges.current.find((s) => s.dayOfWeek === t.day));
    const surchargeRows: EditableSurcharge[] = surcharges.current
        .filter((s) => s.surchargeType === SurchargeType.Recurring)
        .map(mapSurcharge);
    const oneTimeSurcharges: EditableSurcharge[] = surcharges.current
        .filter((s) => s.surchargeType === SurchargeType.OneTime)
        .map(mapSurcharge);
    const previousSurcharges: EditableSurcharge[] = surcharges.previous.map(mapSurcharge);

    return {
        locationId,
        recurringSurcharges: [...templateRows, ...surchargeRows].sort((a, b) => (a.day! > b.day! ? 1 : -1)),
        oneTimeSurcharges: oneTimeSurcharges
            .sort((a, b) => (a.date! > b.date! ? 1 : -1))
            .concat(templateOneTimeSurcharges),
        previousSurcharges: previousSurcharges,
    };
};

export const mapSurcharge = (s: GraphQLSurcharge): EditableSurcharge => {
    return {
        day: s.dayOfWeek,
        dayLabel: s.dayOfWeek && DayOfWeekDictionary[s.dayOfWeek],
        date: s.date,
        id: s.id,
        startTime: s.startTime,
        endTime: s.endTime,
        percentage: s.percentage ? displayPercentage(s.percentage) : s.percentage,
        applyToFood: s.applyToFood,
        applyToDrink: s.applyToDrink,
        surchargeId: s.surchargeId,
        rowKey: (s.dayOfWeek && s.dayOfWeek.toString()) || (s.date && s.date.toString()) || "new",
        newRow: false,
        surchargeType: s.surchargeType,
    };
};
