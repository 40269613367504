import * as React from "react";
import { FieldProps } from "formik";
import { Checkbox } from "antd";
import { DaysOfWeek } from "features/service";
import "./EditDaysOfWeek.scss";
import { CheckboxValueType } from "antd/lib/checkbox/Group";

export interface Props {
    disabled?: boolean;
}

export const EditDaysOfWeek = React.memo((props: Props & FieldProps) => {
    const {
        field: { value, name },
        form,
        disabled,
    } = props;

    const optionsCheckBox = [
        { value: DaysOfWeek.MONDAY, label: "Mo" },
        { value: DaysOfWeek.TUESDAY, label: "Tu" },
        { value: DaysOfWeek.WEDNESDAY, label: "We" },
        { value: DaysOfWeek.THURSDAY, label: "Th" },
        { value: DaysOfWeek.FRIDAY, label: "Fr" },
        { value: DaysOfWeek.SATURDAY, label: "Sa" },
        { value: DaysOfWeek.SUNDAY, label: "Su" },
    ];

    const handleChange = (selectedDays: CheckboxValueType[]) => {
        form.setFieldValue(name, selectedDays);
    };

    return (
        <Checkbox.Group
            className="round-checkbox"
            options={optionsCheckBox}
            value={value}
            onChange={handleChange}
            disabled={disabled}
        />
    );
});
