import {
    BaseTelemetryPlugin,
    IAppInsightsCore,
    IConfiguration,
    IPlugin,
    IProcessTelemetryContext,
    ITelemetryItem,
    ITelemetryPluginChain,
} from "@microsoft/applicationinsights-core-js";
import { AnyAction } from "redux";
import { AppState } from "features";
import { ReduxActionPlugin } from "../types/ReduxActionPlugin";
import { trackEvent } from "..";
import { TrackEventData } from "../types/TrackEventData";

export class ReduxTrackEventPlugin extends BaseTelemetryPlugin implements ReduxActionPlugin {
    public identifier = "MeanduReduxTrackEventPlugin";

    constructor() {
        super();
    }

    processTelemetry(event: ITelemetryItem, itemCtx?: IProcessTelemetryContext) {
        this.processNext(event, itemCtx!);
    }

    initialize(
        configuration: IConfiguration,
        core: IAppInsightsCore,
        extensions: IPlugin[],
        pluginChain?: ITelemetryPluginChain
    ) {
        super.initialize(configuration, core, extensions, pluginChain);
    }

    handleReduxAction(action: AnyAction, _: () => AppState) {
        if (!action.type || action.type.startsWith("@")) {
            return;
        }

        // automatically track any redux event with tracking data attached
        if (action.trackingData) {
            // accept array / single argument of TrackingEventData
            let trackingEvents: TrackEventData[] = Array.isArray(action.trackingData)
                ? action.trackingData
                : [action.trackingData];

            trackingEvents.forEach((trackingEvent) => {
                try {
                    const { event, customProperties } = trackingEvent;
                    if (!event || !event.name) {
                        throw new Error("invalid event");
                    }
                    trackEvent(event, customProperties);
                } catch (err) {
                    console.warn(`trackEvent failed for ${action.type}`, err);
                }
            });
        }
    }
}
