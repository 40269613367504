import { PosUISettings } from ".";

export const kountaUISettings: PosUISettings = {
    servicePosId: {
        visible: false,
    },
    surchargeSku: {
        visible: false,
    },
    priceListPosId: {
        visible: false,
    },
    taxPosId: {
        visible: false,
    },
};
