import styles from "./EditCategoryPage.module.scss";

import * as React from "react";
import { FieldErrors, ImageUpload, RadioButtonGroup, TextInput } from "components/forms";
import { Formik, FormikProps, FieldProps, FastField, Field, validateYupSchema, yupToFormErrors } from "formik";
import { EditableCategory } from "features/category/types";
import { editableCategorySchema } from "features/category/schema/EditableCategory";
import { ProductSummary } from "features/catalogue/types";
import { MenuSummary } from "features/menu";
import { Effect } from "common/FormikEdffect";

import { EditRow } from "common/scaffolding/components/EditRow";
import { Form, Divider } from "antd";
import { SortableTransfer } from "components/forms/SortableTransfer";
import { DataSelectItem } from "common/scaffolding/components/DataSelectItem";

import { SaveStatus } from "common/loader";
import memoizeOne from "memoize-one";
import { CrudPermissions } from "features/location/types/createCrudPermissions";
import { ServiceSummary } from "features/service";
import { ActiveLocation } from "features/location";
import { CrudActionFooter, SubmitMode } from "core/components/actionFooter/CrudActionFooter";
import { ScrollToFormikError } from "components/forms/ScrollToFormikError";
import { equalsIgnoreOrder } from "common/utility/arrayUtils";

export interface Props {
    restaurantLocation: ActiveLocation;
    initialValues: EditableCategory;
    services: ServiceSummary[];
    menus: MenuSummary[];
    menuItems: ProductSummary[];
    saveStatus?: SaveStatus;
    onSubmit: (values: EditableCategory, refreshMenuItems: boolean) => void;
    onClone: (values: EditableCategory, refreshMenuItems: boolean) => void;
    onArchive: () => void;

    permissions: CrudPermissions;
}

export interface State {
    menuItems: ProductSummary[];
    submitMode: SubmitMode;
}
const foodTypeOptions = [
    { text: "Food", value: "food" },
    { text: "Drink", value: "drink" },
];
const booleanOptions = [
    { text: "Yes", value: true },
    { text: "No", value: false },
];

export class EditCategoryPage extends React.Component<Props, State> {
    private getMenuItems = memoizeOne((menuItems, menuItemType) =>
        menuItems.filter((item: ProductSummary) => item.type === menuItemType)
    );

    private getMenuItemsChanged = ({ menuItems }: EditableCategory) => {
        return !equalsIgnoreOrder(this.props.initialValues.menuItems, menuItems);
    };

    state = {
        menuItems: this.getMenuItems(this.props.menuItems, this.props.initialValues.type),
        submitMode: "save",
    } as State;

    sortableTransferRef = React.createRef<SortableTransfer>();

    handleChange = (
        { values: currentValues }: FormikProps<EditableCategory>,
        { values: nextValues }: FormikProps<EditableCategory>
    ) => {
        const { menuItems } = this.props;

        if (nextValues.type !== currentValues.type) {
            nextValues.menuItems = [];

            this.sortableTransferRef.current!.resetTargetKeys();
            this.setState({
                menuItems: this.getMenuItems(menuItems, nextValues.type),
            });
        }
    };

    handleDuplicate = (data: EditableCategory) => {
        this.setState({ submitMode: "clone" });
        this.props.onClone(data, this.getMenuItemsChanged(data));
    };

    handleDelete = () => {
        this.setState({ submitMode: "archive" });
        this.props.onArchive();
    };

    handleSubmit = (data: EditableCategory) => {
        this.setState({ submitMode: "save" });
        this.props.onSubmit(data, this.getMenuItemsChanged(data));
    };

    render() {
        const { saveStatus, initialValues, services, menus, permissions } = this.props;
        const { menuItems } = this.state;

        const isUpdate = !!initialValues.id;
        const disableFields = isUpdate && !permissions.canUpdate;

        const imageSubtitle = (
            <span>
                800px x 560px
                <br />
                Must be .jpg, .png
                <br />
                Maximum size: 4MB
            </span>
        );

        return (
            <Formik
                validate={this.validate}
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={this.handleSubmit}
                key={initialValues.id}
            >
                {(form) => (
                    <Form className={styles.form} onFinish={form.handleSubmit}>
                        <ScrollToFormikError />
                        <Effect onChange={this.handleChange} />

                        <EditRow
                            title="Category name"
                            subTitle="Enter the display name of your category. This will appear to consumers."
                        >
                            <FieldErrors name="displayName" form={form}>
                                <FastField
                                    name="displayName"
                                    component={TextInput}
                                    placeholder="Category Name"
                                    disabled={disableFields}
                                />
                            </FieldErrors>
                        </EditRow>
                        <EditRow
                            title="Internal name"
                            subTitle="Enter the display name of your category. This will only appear in this portal."
                        >
                            <FieldErrors name="internalName" form={form}>
                                <FastField
                                    name="internalName"
                                    component={TextInput}
                                    placeholder="Internal Name (optional)"
                                    disabled={disableFields}
                                />
                            </FieldErrors>
                        </EditRow>
                        <Divider />

                        <EditRow title="Image" subTitle={imageSubtitle}>
                            <FieldErrors name="image" form={form}>
                                <FastField
                                    name="image"
                                    component={ImageUpload}
                                    label="Click to upload image"
                                    disabled={disableFields}
                                />
                            </FieldErrors>
                        </EditRow>
                        <Divider />

                        <EditRow
                            title="Category type"
                            subTitle={
                                <p>
                                    Select the type of products that will exist within this category.
                                    <br />
                                    Note: Only food products can be added to food categories, and vice versa.
                                </p>
                            }
                        >
                            <FastField
                                name="type"
                                component={RadioButtonGroup}
                                options={foodTypeOptions}
                                disabled={disableFields}
                            />
                        </EditRow>
                        <Divider />

                        <EditRow
                            title="Make this a special?"
                            subTitle="Would you like to display this category as a special?"
                        >
                            <FastField
                                name="special"
                                component={RadioButtonGroup}
                                options={booleanOptions}
                                disabled={disableFields}
                            />
                        </EditRow>
                        <Divider />

                        <EditRow
                            title="Show a from price?"
                            subTitle="Display a from price instead of multiple prices for products in this category? (i.e. from $6.00)"
                        >
                            <FastField
                                name="showFromPrice"
                                component={RadioButtonGroup}
                                options={booleanOptions}
                                disabled={disableFields}
                            />
                        </EditRow>
                        <Divider />

                        {menus.length === 0 && (
                            <>
                                <EditRow
                                    title="Services"
                                    subTitle="Select the services that your categories will appear within."
                                >
                                    <FastField
                                        name="services"
                                        component={DataSelectItem}
                                        options={services}
                                        disabled={disableFields}
                                    />
                                </EditRow>
                                <Divider />
                            </>
                        )}

                        {menus.length > 0 && (
                            <>
                                <EditRow
                                    title="Menus"
                                    subTitle="Select the menus that your categories will appear within."
                                >
                                    <FastField
                                        name="menus"
                                        component={DataSelectItem}
                                        options={menus}
                                        disabled={disableFields}
                                    />
                                </EditRow>
                                <Divider />
                            </>
                        )}

                        <EditRow
                            title="Products"
                            subTitle="Select and add products that should appear in this category."
                            col={24}
                        >
                            <Field
                                name="menuItems"
                                render={(props: FieldProps) => (
                                    <SortableTransfer
                                        {...props}
                                        options={menuItems}
                                        ref={this.sortableTransferRef}
                                        disabled={disableFields}
                                    />
                                )}
                            />
                        </EditRow>
                        <CrudActionFooter
                            saving={saveStatus === "saving"}
                            submitMode={this.state.submitMode}
                            showDuplicate={permissions.canCreate && isUpdate}
                            showDelete={permissions.canDelete && isUpdate}
                            showSave={!disableFields}
                            duplicateProps={{
                                onClick: () => {
                                    if (form.isValid) {
                                        this.handleDuplicate(form.values);
                                    } else {
                                        // force ScrollToFormikError to run (wont submit because form is invalid)
                                        form.submitForm();
                                    }
                                },
                            }}
                            confirmDelete={{
                                title: "Are you sure you want to delete this category?",
                                children: "This category will be removed. This action cannot be undone.",
                            }}
                            deleteProps={{
                                onClick: this.handleDelete,
                            }}
                            saveProps={{
                                disabled: !form.dirty && isUpdate,
                            }}
                        />
                    </Form>
                )}
            </Formik>
        );
    }

    validate = (value: EditableCategory) => {
        const context = {
            requireImage: !this.props.restaurantLocation.defaultCatalogueImage,
        };

        return validateYupSchema(value, editableCategorySchema, undefined, context).then(
            () => ({}),
            (err: any) => yupToFormErrors(err)
        );
    };
}
