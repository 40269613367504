import { createSelector } from "reselect";
import { getModifierCatalogueItems } from "./getModifierCatalogueItems";
import { CatalogueModifierOption } from "..";
import { getEditModifier } from "./getEditModifier";

// returns true if the modifier group currently being edited has been nested by other modifiers
export const getIsEditingNestedModifier = createSelector(
    getModifierCatalogueItems,
    getEditModifier,
    (modifiers, editModifier) => {
        const { id } = editModifier || {};

        if (!id) {
            return false;
        }

        return !!modifiers.find((modifier) => {
            return modifier?.children?.some((option: CatalogueModifierOption) => {
                return option.modifiers?.includes(id);
            });
        });
    }
);
