import { Col, Row } from "antd";
import { ColumnProps, TablePaginationConfig } from "antd/lib/table";
import { CrudPermissions } from "features/location/types/createCrudPermissions";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { LocaleComponentProps, LocationComponentProps } from "features/location";
import { NewDataButton } from "common/scaffolding/components/DataListingPage/NewDataButton";
import { PageTitle } from "common/scaffolding/components/DataListingPage/PageTitle";
import { RouteComponentProps } from "react-router";
import { TableListing } from "common/scaffolding/components/TableListing/TableListing";

import { TaxClassSummary, TaxTypeLabel } from "../types";
export interface Props {
    taxes: TaxClassSummary[];
    permissions: CrudPermissions;
    showIdColumn: boolean;
}

const paginationSettings: TablePaginationConfig = {
    size: "default",
    position: ["bottomCenter"],
    pageSize: 10,
    showSizeChanger: false,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
};

export const ListTaxesPage = ({
    locale,
    taxes,
    match,
    permissions,
    history,
    showIdColumn,
}: Props & LocationComponentProps & LocaleComponentProps & RouteComponentProps<any>) => {
    const { canCreate, canUpdate } = permissions || {};
    const bindColumns = () => {
        let columns: ColumnProps<TaxClassSummary>[] = [
            {
                title: "Name",
                dataIndex: "displayName",
                key: "displayName",
                render: (title, record) => (record.internalName ? `${title} (${record.internalName})` : title),
            },
            {
                title: "Type",
                dataIndex: "taxType",
                key: "type",
                render: (type) => TaxTypeLabel[type] || "",
            },
            {
                title: "Tax applied",
                dataIndex: "taxRate",
                key: "taxRate",
                render: (taxRate) => `${locale?.formatPercent(taxRate) || ""}`,
            },
        ];

        if (showIdColumn) {
            columns.push({
                title: "ID",
                dataIndex: "posTaxId",
                key: "posTaxId",
            });
        }

        if (canUpdate) {
            columns = columns.concat({
                title: "",
                key: "action",
                className: "col-actions col-center",
                render: (text, record) => (
                    <Link to={`${match.url}/${record.id}`}>
                        <EditOutlined />
                    </Link>
                ),
            });
        }

        return columns;
    };

    const handleRowClick = (record: TaxClassSummary) => {
        history.push({
            pathname: `${match.url}/${record.id}`,
        });
    };

    return (
        <>
            <Col span={18}>
                <PageTitle
                    title="Taxes"
                    description="Setup all taxes that apply to your venue. These can be applied to products, modifiers and variants. Taxes must be configured in order for customers to be charged correctly and appear on order receipts. "
                />
                {canCreate && (
                    <div className="mt-4">
                        <NewDataButton text="Add tax" newItemRoute={`${match.url}/new`} />
                    </div>
                )}
            </Col>
            <Row>
                <TableListing
                    columns={bindColumns()}
                    dataSource={taxes}
                    keyField="id"
                    pagination={paginationSettings}
                    onRowClick={handleRowClick}
                />
            </Row>
        </>
    );
};
