import * as React from "react";
import { FieldProps } from "formik";

import "./PercentageInput.scss";
import { Input } from "antd";

export interface PercentageInputProps {
    displayName?: string;
    disabled?: boolean;
}

export interface PercentageInputState {
    value: string;
}

export class PercentageInput extends React.Component<FieldProps & PercentageInputProps, PercentageInputState> {
    constructor(props: FieldProps & PercentageInputProps) {
        super(props);

        const {
            field: { value },
        } = props;

        this.state = {
            value,
        };
    }

    render() {
        const {
            displayName,
            disabled,
            field: { name },
        } = this.props;

        const { value } = this.state;

        return (
            <>
                <h6>{displayName}</h6>

                <Input
                    name={name}
                    value={value}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    placeholder={displayName}
                    disabled={disabled}
                    maxLength={5}
                    addonAfter="%"
                    style={{ width: 120 }}
                />
            </>
        );
    }

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const {
            field: { onChange },
        } = this.props;
        const reg = /^(0|[1-9][0-9]*)(\.[0-9]{0,2})?$/;
        if ((!Number.isNaN(parseFloat(value)) && reg.test(value)) || value === "") {
            this.setState({ value });
            onChange(e);
        }
    };

    // '.' at the end or only '-' in the input box.
    onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const {
            field: { name, onBlur },
        } = this.props;
        let { value } = this.state;
        if (value?.toString().charAt(value.length - 1) === ".") {
            value = value.slice(0, -1);
            this.setState({ value });
        }

        this.props.form.setFieldValue(name, value === null ? null : value);
        onBlur(e);
    };
}
