import { FeatureConfiguration } from "features";
import { reducer } from "features/quickSell/reducers";
import * as actions from "features/quickSell/actions";
import { withPermissions } from "features/location/components/withPermission";
import { Route } from "react-router";
import { Col } from "antd";
import { QuickSellPageContainer } from "./containers/QuickSellPageContainer";

export { actions };
export * from "features/quickSell/types";

export default function (config: FeatureConfiguration) {
    config.reducers.quickSell = reducer;

    config.addRoutes(config.routes.locationRoutes, [
        <Route
            path={`/:region/:location/quicksell`}
            key="quicksell"
            exact
            render={() => (
                <Col span={24} md={24} className="content__col content__col--white no-top-header">
                    <div className="content__col-list-white">
                        <Route component={withPermissions(QuickSellPageContainer, "quicksell:read")} />
                    </div>
                </Col>
            )}
        />,
    ]);
}
