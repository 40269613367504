import * as React from "react";
import { getIn, FastFieldProps } from "formik";

import { Input } from "antd";

export interface TextInputProps {
    placeholder?: string;
    type?: string;
    className?: string;
    displayName?: string;
    error?: string | undefined;
    disabled: boolean;
    trim?: boolean;
    id?: string;
    autoFocus?: boolean;
    regex?: RegExp;
    allowClear?: boolean;
    maxLength?: number;
    delayUpdates?: boolean;
    addonBefore?: string;
    autoComplete?: string;
}

interface State {
    value?: string;
}

export class TextInput extends React.Component<FastFieldProps & TextInputProps, State> {
    constructor(props: FastFieldProps & TextInputProps) {
        super(props);

        this.state = {};
    }

    onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {
            form,
            field: { name, onBlur },
            trim,
        } = this.props;
        let value: string | number = "";

        if (this.props.type === "number") value = parseInt(e.target.value);
        else value = trim === false ? e.target.value : e.target.value.trim();

        form.setFieldValue(name, value);
        form.setFieldTouched(name, true);
        this.setState({ value: undefined });

        onBlur(e);
    };

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.delayUpdates) {
            this.setState({ value: e.target.value });
            return;
        }

        const {
            form,
            field: { name },
            regex,
        } = this.props;
        if (regex && !regex.test(e.target.value)) return;
        let value: string | number = e.target.value;

        if (this.props.type === "number") value = parseInt(e.target.value);

        const touched = getIn(form.touched, name) as boolean;
        const shouldValidate = touched || false;

        form.setFieldValue(name, value, shouldValidate);
    };

    handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
    };

    render() {
        const {
            placeholder,
            className,
            type,
            field: { name, value },
            disabled,
            id,
            autoFocus,
            allowClear,
            maxLength,
            addonBefore,
            autoComplete,
        } = this.props;

        const { value: tempValue } = this.state;

        return (
            <>
                <Input
                    type={type || "text"}
                    name={name}
                    id={id}
                    value={tempValue || value || ""}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    onClick={this.handleClick}
                    className={className}
                    placeholder={placeholder}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    allowClear={allowClear}
                    maxLength={maxLength}
                    addonBefore={addonBefore}
                    autoComplete={autoComplete}
                />
            </>
        );
    }
}
