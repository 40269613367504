import { opsApi } from "../../../API";

export const archiveWaiter = async (locationId: string, staffId: string) => {
    await opsApi.graphQLQuery(query, { locationId, staffId });
};

const query = `
    mutation($locationId: ID!, $staffId: ID!) {
        deleteStaffMember(locationId: $locationId, id: $staffId) {
            id
        }
    }
`;
