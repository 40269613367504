import styles from "./Status.module.scss";

import { PropsWithChildren, ReactElement } from "react";
import classNames from "classnames";

export type ColorScheme = "active" | "disabled" | "error" | "ongoing" | "warning" | "success";

type Shape = "dot" | "rect";

interface Props {
    colorScheme?: ColorScheme;
    nowrap?: boolean;
    shape?: Shape;
}

export const Status = ({
    children,
    colorScheme = "active",
    nowrap = true,
    shape = "rect",
}: PropsWithChildren<Props>): ReactElement => {
    return (
        <span className={classNames(styles.default, styles[colorScheme], nowrap && styles.nowrap, styles[shape])}>
            {children}
        </span>
    );
};
