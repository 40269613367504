import { isNew } from "common/utility/modelUtils";
import {
    PrintAsProductTypes,
    ProductSubtypes,
    EditableModifier,
    ModifySelectionOptions,
    EditableModifierProducts,
} from "../types";
import { createAction } from "../reducers/edit";
import { editModifier, GraphQLModifier, GraphQLModifierProduct } from "../api/editModifier";
import { scaffoldEditAction } from "common/scaffolding/actions/scaffoldEditAction";
import { GraphQLItemId } from "common/scaffolding/types";

export const edit = (locationId: string, modifierId: string) =>
    scaffoldEditAction(
        modifierId,
        (state) => state.modifiers.edit,
        createAction,
        async () => {
            if (isNew(modifierId)) {
                return newEditableModifier();
            }

            const modifier = await editModifier(locationId, modifierId);

            // We need to show a nicer not found page for brand catalogue, without messing with the plumbing in withLoader
            // which responds to Failed/Not found before we can chose what to display in EditModifierPage
            if (!modifier) throw new Error("Modifier Not Found");

            return mapEditableModifier(modifier);
        }
    );

export const mapEditableModifier = (input: GraphQLModifier): EditableModifier => {
    return {
        id: input.id,
        internalName: input.internalName,
        displayName: input.displayName,
        minMaxSelect: ModifySelectionOptions.Unlimited, // Not saved in db
        minSelection: input.minSelection,
        maxSelection: input.maxSelection,
        required: input.required,
        sku: input.sku,
        options: input.options.map((option) => ({ ...option, energyContent: option?.energyContent?.toString() })),
        items: input.items ? input.items.map((i) => i.id) : undefined, // TODO @deprecated remove when all data migrated to 'products'
        products: mapEditableModifierProducts(input.products, input.items),
        upsell: input.upsell || false,
        priceLists: input.priceLists || [],
        status: input.status,
        smartSorting: input.smartSorting,
        maxSelectionPerOption: input.maxSelectionPerOption,
        type: mapModifierUpsellPropertiesToType(),
        isLinked: input.isLinked,
    };

    function mapModifierUpsellPropertiesToType(): PrintAsProductTypes | null {
        if (
            input.type === "drink" &&
            input.subtypes !== null &&
            ProductSubtypes.ALCOHOLIC === (input.subtypes & ProductSubtypes.ALCOHOLIC)
        ) {
            return "alcoholic-drink";
        }
        return input.type;
    }
};

const newEditableModifier = (): EditableModifier => ({
    id: "new",
    displayName: "",
    options: [],
    minMaxSelect: ModifySelectionOptions.Unlimited,
    required: false,
    items: [], // @deprecated
    products: {
        productIds: [],
        variantIds: [],
    },
    upsell: false,
    priceLists: [],
    status: 0,
    smartSorting: true,
    type: null,
    isLinked: false,
});

const mapEditableModifierProducts = (
    products: GraphQLModifierProduct[] | undefined,
    items: GraphQLItemId[] | undefined
): EditableModifierProducts => {
    if (products) {
        const values = products.reduce<EditableModifierProducts>(
            (acc, product) => {
                if (product.variantIds?.length) {
                    acc.variantIds.push(...product.variantIds);
                } else {
                    acc.productIds.push(product.productId);
                }
                return acc;
            },
            {
                productIds: [],
                variantIds: [],
            }
        );

        values.productIds?.sort();
        values.variantIds?.sort();

        return values;
    }

    return {
        productIds: [],
        variantIds: [],
    };
};
