import { ComponentType } from "react";
import { CatalogueItem } from "../types";
import { product, modifier } from ".";
import { useRouteMatch } from "react-router";

interface Props {
    collectionName: "modifiers" | "products";
    item: CatalogueItem;
    formatCurrency: (x: number) => string;
    canBulkSelect: boolean;
    component: ComponentType<product.ListItemProps> | ComponentType<modifier.ListItemProps>;
}

// Renders catalogue list item e.g. product | modifier group and children as a flattened list fragment
export const ListItemGroup = ({ canBulkSelect, component: Component, item, formatCurrency }: Props) => {
    const disableSelection = !canBulkSelect;

    // TODO move to container
    const { url } = useRouteMatch<any>() || {};

    const editLink = `${url}/${item.parentId || item.id}`;

    return (
        <>
            <Component
                canBulkSelect={canBulkSelect}
                disableSelection={disableSelection}
                parentItem={null}
                item={item}
                formatCurrency={formatCurrency}
                editLink={editLink}
            />

            {item.children?.map((childItem) => (
                <Component
                    canBulkSelect={canBulkSelect}
                    key={childItem.key}
                    disableSelection={disableSelection}
                    item={childItem}
                    parentItem={item}
                    formatCurrency={formatCurrency}
                    editLink={editLink}
                />
            ))}
        </>
    );
};
