import * as React from "react";
import { EditOutlined } from "@ant-design/icons";
import { Col, List, Row } from "antd";

import "./DataListing.scss";
import { Link } from "react-router-dom";
import { generatePath, RouteComponentProps, withRouter, matchPath } from "react-router";
import classNames from "classnames";
import { Image } from "../../Image";
import { ResolvedDisplayName } from "components/ResolvedDisplayName";

export type DataItem = { id: string; displayName: string; image?: string };

export type AvailableDataItem = DataItem & { available: false };

export interface Props<T extends DataItem | AvailableDataItem> {
    items: T[];
    renderItemInfo?: (item: T, index?: number) => React.ReactNode;
    renderHeader?: (item: T, index?: number) => React.ReactNode;
    renderFooter?: (item: T, index?: number) => React.ReactNode;
    className?: string;
    editItemRoute: string;
    emptyText?: string;
    displayImages?: boolean;
}

const DataListing = <T extends DataItem | AvailableDataItem>({
    items,
    editItemRoute,
    history,
    location,
    renderItemInfo,
    renderHeader,
    renderFooter,
    className,
    emptyText,
    displayImages,
}: Props<T> & RouteComponentProps) => {
    const match = matchPath<any>(history.location.pathname, editItemRoute);

    const activeId: string | null = match !== null ? match.params.id : null;

    return (
        <List
            className={classNames("list-container", className)}
            bordered={true}
            loading={false}
            itemLayout="horizontal"
            dataSource={items}
            locale={{ emptyText: emptyText || "No Data" }}
            renderItem={(item: T, index: number) => {
                const href = generatePath(editItemRoute, { id: item.id });
                const active = item.id === activeId;

                const onNavigate = !!activeId ? history.replace : history.push;

                return (
                    <DataListItem
                        renderHeader={renderHeader}
                        renderFooter={renderFooter}
                        renderItemInfo={renderItemInfo}
                        key={item.id}
                        item={item}
                        index={index}
                        active={active}
                        onNavigate={onNavigate}
                        href={href}
                        displayImages={displayImages}
                    />
                );
            }}
        />
    );
};

export interface DataListItemProps<T extends DataItem | AvailableDataItem> {
    item: T;
    index: number;
    active: boolean;
    href: string;
    renderItemInfo?: (item: T, index?: number) => React.ReactNode;
    renderHeader?: (item: T, index?: number) => React.ReactNode;
    renderFooter?: (item: T, index?: number) => React.ReactNode;
    onNavigate: (href: string) => void;
    displayImages?: boolean;
}

class DataListItem<T extends DataItem | AvailableDataItem> extends React.PureComponent<DataListItemProps<T>> {
    render() {
        const { item, active, href, renderItemInfo, renderHeader, renderFooter, onNavigate, displayImages } =
            this.props;

        return (
            <List.Item
                className={classNames("list-item", displayImages && "has-image", {
                    "list-item__active": active,
                    "list-item__disabled": !(item as AvailableDataItem).available,
                })}
                actions={[
                    <Link to={href}>
                        <EditOutlined />
                    </Link>,
                ]}
                onClick={() => onNavigate(href)}
            >
                <div className="item-wrapper-outer" key={item.id}>
                    {renderHeader && renderHeader(item)}
                    <Row justify="start" className="item-wrapper" gutter={12}>
                        <Col
                            span={renderItemInfo ? 10 : 24}
                            className="item-wrapper__display-name"
                            title={item.displayName}
                        >
                            {displayImages && <Image image={item.image || ""} type="inline" alt={item.displayName} />}
                            <ResolvedDisplayName item={item} />
                        </Col>
                        {renderItemInfo && <Col span={14}>{renderItemInfo(item)}</Col>}
                    </Row>
                    {renderFooter && renderFooter(item)}
                </div>
            </List.Item>
        );
    }
}

export const DataListingWithRoute = withRouter(DataListing);
