import { opsApi } from "../../../API";

export const archivePromotion = async (locationId: string, promotionId: string) => {
    await opsApi.graphQLQuery(query, { locationId, promotionId });
};

const query = `
    mutation($locationId: ID!, $promotionId: ID!) {
        deletePromotion(locationId: $locationId, id: $promotionId) {
            id
        }
    }
`;
