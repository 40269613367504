import isEqual from "lodash/isEqual";
import { createSelector } from "reselect";
import { getTrackingData } from "common/utility/trackingDataUtils";
import { isNew } from "common/utility/modelUtils";
import { AppState } from "../../state";
import { getTakeawayOptions } from "../../takeaway/selectors";
import { EditableTakeawayOptions } from "../../takeaway/types";
import { EditableSection } from "../types";

const getExistingSection = ({ sections: { edit } }: AppState) => {
    return edit.status === "loaded" ? edit.data : ({} as EditableSection);
};

export const getArchiveSectionTrackingEventData = createSelector(
    getExistingSection,
    getTakeawayOptions,
    (section: EditableSection, takeawayOptions?: EditableTakeawayOptions) => {
        const eventName = "SECTION/DELETION_SAVED";
        const trackingData = {
            section_id: section.id,
            takeaway_enabled: takeawayOptions?.enabled && takeawayOptions?.sectionId === section.id,
        };
        return getTrackingData(eventName, trackingData);
    }
);

export const getSaveSectionTrackingEventData = createSelector(
    getExistingSection,
    (_: AppState, formData: EditableSection) => formData,
    (_: AppState, __: EditableSection, id?: string) => id,
    (existing, formData, savedId) => {
        const sectionTrackingData = getSectionChanges(existing, formData, savedId);
        const eventName = isNew(existing.id) ? "SECTION/ADDITION_SAVED" : "SECTION/MODIFICATION_SAVED";
        return getTrackingData(eventName, sectionTrackingData);
    }
);

export const getSectionChanges = (existing: EditableSection, formData: EditableSection, savedId?: string) => {
    const isNewModel = isNew(formData.id);
    const sectionId = !isNewModel ? formData.id : savedId;
    const changes: any = {};

    changes.timestamp = new Date().toISOString();
    if (sectionId) {
        changes.section_id = sectionId;
    }

    if (isNewModel || existing.displayName !== formData.displayName) {
        changes.section_name = formData.displayName;
    }

    if (isNewModel || existing.sectionType !== formData.sectionType) {
        changes.section_type = formData.sectionType;
    }

    if (isNewModel || existing.enableTips !== formData.enableTips) {
        changes.allow_tips = formData.enableTips;
    }

    if (isNewModel || existing.applySurcharges !== formData.applySurcharges) {
        changes.apply_surcharges = formData.applySurcharges;
    }

    if (isNewModel || existing.applyTrayCharge !== formData.applyTrayCharge) {
        changes.apply_tray_charge = formData.applyTrayCharge;
    }

    if (isNewModel || (existing && !isEqual(existing.services, formData.services))) {
        changes.service_ids = formData.services;
    }

    let trackDemoConfiguration = false;
    const demoModeEnabled = !!formData.demoQr?.isEnabled;
    if (isNewModel || (existing && !isEqual(existing.demoQr?.isEnabled, formData.demoQr?.isEnabled))) {
        trackDemoConfiguration = true;
    }

    if (isNewModel || demoModeEnabled || (existing && !isEqual(existing.demoTableNumber, formData.demoTableNumber))) {
        trackDemoConfiguration = true;
        changes.demo_table_number = formData.demoTableNumber;
    }

    if (
        isNewModel ||
        (existing && !isEqual(existing.demoQr?.selectedTimeToLive, formData.demoQr?.selectedTimeToLive))
    ) {
        trackDemoConfiguration = true;
        changes.demo_expiration_hours = formData.demoQr?.selectedTimeToLive;
    } else if (demoModeEnabled) {
        trackDemoConfiguration = true;
        changes.demo_expiration_hours = Math.round(((formData.demoQr?.timeToLiveSeconds || 0) / (60 * 60)) * 100) / 100;
    }

    if (trackDemoConfiguration) {
        changes.demo_enabled = demoModeEnabled;
    }

    return changes;
};
