import styles from "./ButtonGroup.module.scss";
import buttonStyles from "./Button.module.scss";

import { Container, Props as ContainerProps } from "../container/Container";

import { Props as ButtonProps, Role } from "./Button";
import { Children, cloneElement, isValidElement, MouseEvent, useCallback } from "react";
import classNames from "classnames";

type GroupButtonProps = Pick<ButtonProps, "padding" | "size">;

type ButtonStyle = "solid" | "outline";

interface Props {
    buttonClassName?: string;
    buttonNoWrap?: boolean;
    buttonStyle?: ButtonStyle;
    className?: string;
    disabled?: boolean;
    nowrap?: boolean;
    onClick?: (index: number, event: MouseEvent<HTMLElement>) => void;
    onDisabledClick?: (index: number, event: MouseEvent<HTMLElement>) => void;
    selectedIndex: number;
}

export const ButtonGroup = (props: Props & GroupButtonProps & Omit<ContainerProps, "defaultStyle">) => {
    const {
        as = "nav",
        buttonClassName,
        buttonNoWrap,
        buttonStyle,
        children,
        disabled,
        nowrap = true,
        onClick,
        onDisabledClick,
        padding,
        selectedIndex,
        size,
        ...rest
    } = props;

    const handleClick = useCallback(
        (index: number, event: MouseEvent<HTMLElement>) => {
            onClick?.(index, event);
        },
        [onClick]
    );

    const handleDisabledClick = useCallback(
        (index: number, event: MouseEvent<HTMLElement>) => {
            onDisabledClick?.(index, event);
        },
        [onDisabledClick]
    );

    return (
        <Container as={as} {...rest} defaultStyle={nowrap ? styles.containerNowrap : styles.container}>
            {Children.map(children, (child, index) => {
                if (!isValidElement<ButtonProps>(child)) {
                    return null;
                }

                const role: Role = buttonStyle === "outline" ? "secondary" : "primary";

                const childProps = {
                    className: classNames(buttonStyles.inGroup, buttonClassName),
                    role,
                    "aria-selected": index === selectedIndex,
                    ...(buttonNoWrap ? { nowrap: buttonNoWrap } : null),
                    ...(disabled ? { disabled } : null),
                    ...(size ? { size } : null),
                    ...(padding ? { padding } : null),
                    ...(onClick ? { onClick: (event: MouseEvent<HTMLElement>) => handleClick(index, event) } : null),
                    ...(onDisabledClick
                        ? { onDisabledClick: (event: MouseEvent<HTMLElement>) => handleDisabledClick(index, event) }
                        : null),
                    ...child.props,
                };
                return cloneElement(child, childProps);
            })}
        </Container>
    );
};
