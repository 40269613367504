import { Col, Table } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { ColumnProps, TablePaginationConfig } from "antd/lib/table";
import classNames from "classnames";
import { useState, useEffect } from "react";
import "./TableListing.scss";

export interface Props<T> {
    dataSource: any[];
    columns: ColumnProps<any>[];
    keyField: string;
    col?: number;
    size?: SizeType;
    className?: string;
    pagination?: TablePaginationConfig | false;
    expandedKeys?: string[];
    selectedRowKeys?: string[];
    loading?: boolean;
    defaultExpandAllRows?: boolean;
    emptyText?: string;
    onRowClick?: (record: T) => void;
    rowClassName?: (record: any, index: number) => string;
    onPageChange?: (page: number) => void;
    resetPagination?: boolean;
    renderExpanded?: (record: T) => React.ReactNode;
}

export const TableListing = <T extends any>({
    dataSource,
    columns,
    keyField,
    col,
    size,
    className,
    pagination,
    expandedKeys,
    selectedRowKeys,
    loading,
    defaultExpandAllRows,
    emptyText,
    rowClassName,
    onRowClick,
    onPageChange,
    resetPagination = true,
    renderExpanded
}: Props<T>) => {
    const [currentPage, setCurrentPage] = useState(1);

    const rowSelection = {
        selectedRowKeys,
    };

    const onChange = (page: number) => {
        setCurrentPage(page);
        const alertItem = document.querySelector(".catalogue-alert");
        if (alertItem) {
            alertItem && alertItem.scrollIntoView({ behavior: "auto", block: "start" });
            onPageChange && onPageChange(page);
            return;
        }

        const item = document.querySelector(".header-row");
        item && item.scrollIntoView({ behavior: "auto", block: "start" });
        onPageChange && onPageChange(page);
    };

    useEffect(() => {
        if (!!resetPagination) {
            setCurrentPage(1);
        } else {
            onChange(currentPage);
        }
        //Other dependencies are ignored because we want pagination to only be reset if resetPagination == true.
        //We will trigger onChange(currentPage) if the datasource changes (update/delete/create), so that the user remains on the same page.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSource]);
    return (
        <Col lg={col ? col : 18} className="col-content" md={24}>
            <Table
                columns={columns}
                dataSource={dataSource}
                bordered
                key={keyField}
                rowKey={keyField}
                expandedRowKeys={expandedKeys || []}
                expandIconColumnIndex={-1}
                size={size || "middle"}
                className={classNames(onRowClick && "actionable-row-table", className)}
                pagination={
                    pagination
                        ? { ...pagination, current: currentPage, hideOnSinglePage: true, onChange: onChange }
                        : false
                }
                expandable={{
                    expandedRowRender: renderExpanded as any
                }}
                rowSelection={selectedRowKeys && rowSelection}
                loading={loading}
                defaultExpandAllRows={defaultExpandAllRows}
                locale={{ emptyText: emptyText || "No Data" }}
                rowClassName={rowClassName}
                onRow={
                    onRowClick
                        ? (record) => {
                              return {
                                  onClick: (event) => onRowClick(record), // click row
                              };
                          }
                        : undefined
                }
            />
        </Col>
    );
};
