import { FastField } from "formik";
import { SelectItem, SearchInput, SearchInputContainerProps } from "components/forms/SearchInput";

const options: SelectItem[] = [
    { displayName: "Australian Dollar (AUD)", id: "en-AU" },
    { displayName: "British Pound Sterling (GBP)", id: "en-GB" },
    { displayName: "New Zealand Dollar (NZD)", id: "en-NZ" },
    { displayName: "American Dollar (USD)", id: "en-US" },
];

export const LocaleCurrencySelector = ({ placeholder, disabled, name }: SearchInputContainerProps) => (
    <FastField name={name} component={SearchInput} disabled={disabled} placeholder={placeholder} options={options} />
);
