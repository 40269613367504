import { LocationRouteParams } from "common/types";
import { useParams } from "react-router-dom";

export function useLocationBasePath(): string | undefined {
    const { location, region } = useParams<LocationRouteParams>();

    if (!location || !region) {
        return undefined;
    }

    return `/${region}/${location}`;
}
