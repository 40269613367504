import { isNew } from "common/utility/modelUtils";
import { createAction } from "../reducers/edit";
import { EditablePriceList, EditablePriceListSettings, PriceListItem } from "../types";
import { scaffoldEditAction } from "common/scaffolding/actions/scaffoldEditAction";
import {
    editPriceList,
    GraphQLPriceList,
    GraphQLPriceListProduct,
    GraphQLPriceListModifier,
} from "../api/editPriceList";
import { TabKeys } from "../components/EditPriceListPage";
import { sortByDisplayName } from "foundation/dataConventions/sortByDisplayName";

export const edit = (locationId: string, priceListId: string, activeTab: string) =>
    scaffoldEditAction(
        priceListId,
        (state) => state.priceLists.edit,
        createAction,
        async () => {
            if (isNew(priceListId)) {
                return newEditablePriceList();
            }

            const priceList = await editPriceList(locationId, priceListId);
            return mapEditablePriceList(priceList, activeTab);
        }
    );

export const mapEditablePriceList = (input: GraphQLPriceList, activeTab: string): EditablePriceList => {
    const priceListItems = mapPriceListItems(input.id, input.products, input.modifiers);
    return {
        settings: mapSettings(input),
        priceLevels: { selectedItems: priceListItems },
        activeTab: activeTab,
    };
};

const newEditablePriceList = (): EditablePriceList => ({
    settings: {
        id: "new",
        displayName: "",
        posId: "",
        useGlobalAdjustment: false,
        globalAdjustment: undefined,
        adjustmentType: null,
    },
    priceLevels: { selectedItems: [] },
    activeTab: TabKeys.Settings,
});

const mapSettings = (input: GraphQLPriceList): EditablePriceListSettings => {
    return {
        id: input.id,
        displayName: input.displayName,
        posId: input.posId ?? null,
        useGlobalAdjustment: !!input.globalAdjustment,
        globalAdjustment: input.globalAdjustment,
        adjustmentType: input.adjustmentType || null,
    };
};

const mapPriceListItems = (
    priceListId: string,
    products: GraphQLPriceListProduct[],
    modifiers: GraphQLPriceListModifier[]
): PriceListItem[] => {
    const items: PriceListItem[] = products.map((product) => {
        const priceLevel = product.priceLevels && product.priceLevels.find((i) => i.priceList === priceListId);
        return {
            itemType: "Product",
            displayType: "Product",
            basePrice: product.price,
            children: mapProductChildren(priceListId, product),
            priceLevelPrice: priceLevel?.price ?? undefined,
            canEdit: product.variants.length < 1,
            childType: "variant",
            ...product,
            categories: product.categories.map((i) => i.id),
            internalName: product.internalName || "",
            key: product.id,
        };
    });

    const mods: PriceListItem[] = modifiers.map((modifier) => {
        return {
            itemType: "Modifier",
            displayType: "Modifier",
            children: mapModifierChildren(priceListId, modifier),
            canEdit: modifier.options ? modifier.options.length < 1 : false,
            childType: "option",
            ...modifier,
            internalName: modifier.internalName || "",
            key: modifier.id,
        };
    });

    return sortPriceLevels([...items, ...mods]);
};

const sortPriceLevels = (priceListItems: PriceListItem[]): PriceListItem[] => {
    var invalidItems = findAndSortInvalidItems(priceListItems);
    var remaining = priceListItems.filter((pl) => invalidItems.indexOf(pl) < 0).sort(sortByDisplayName);

    return invalidItems.concat(remaining);
};

const findAndSortInvalidItems = (priceListItems: PriceListItem[]): PriceListItem[] => {
    //find items with no price set or has a child with no price set
    return priceListItems
        .filter(
            (pl) =>
                (pl.children!.length === 0 && (pl.priceLevelPrice === undefined || pl.priceLevelPrice < 0)) ||
                pl.children!.some((ch) => ch.priceLevelPrice === undefined || ch.priceLevelPrice < 0)
        )
        .sort(sortByDisplayName);
};

const mapModifierChildren = (priceListId: string, modifier: GraphQLPriceListModifier): PriceListItem[] => {
    return modifier.options
        ? modifier.options
              .filter((i) => i.priceLevels && i.priceLevels.find((p) => p.priceList === priceListId))
              .map((o) => {
                  const priceLevel = o.priceLevels && o.priceLevels.find((i) => i.priceList === priceListId);
                  return {
                      parentId: modifier.id,
                      itemType: "Option",
                      displayType: "",
                      priceLevelPrice: priceLevel?.price ?? undefined,
                      canEdit: true,
                      ...o,
                      internalName: o.internalName || "",
                      key: `${modifier.id}-${o.id}`,
                  };
              })
        : [];
};

const mapProductChildren = (priceListId: string, product: GraphQLPriceListProduct): PriceListItem[] => {
    return product.variants
        ? product.variants
              .filter((v) => v.priceLevels && v.priceLevels.find((p) => p.priceList === priceListId))
              .map((v) => {
                  const priceLevel = v.priceLevels && v.priceLevels.find((i) => i.priceList === priceListId);
                  return {
                      parentId: product.id,
                      itemType: "Variant",
                      displayType: "",
                      priceLevelPrice: priceLevel?.price ?? undefined,
                      canEdit: true,
                      ...v,
                      price: v.price ?? undefined,
                      sku: v.sku || undefined,
                      internalName: v.internalName || "",
                      key: `${product.id}-${v.id}`,
                  };
              })
        : [];
};
