import { connect } from "react-redux";

import { withLocation, LocationComponentProps } from "features/location";

import { AppState } from "features";
import { publish } from "features/location/actions/publish";
import { Props, PublishButton } from "../components/PublishButton";
import { DataProps, FunctionProps } from "types/utils";
import { RouteComponentProps, withRouter } from "react-router";
import { getLocationPermissions } from "features/location/selectors/getLocationPermissions";
import { getIsPublishDisabled } from "features/location/selectors/getPublishDisabled";

interface LocationProps {
    location: string;
}

const mapStateToProps = (
    state: AppState,
    {
        restaurantLocation: { lastPublished, lastPublishedUrl },
        match: { params },
    }: LocationComponentProps & RouteComponentProps<LocationProps>
): DataProps<Props> => {
    const {
        locations: {
            publish: { publishing },
        },
    } = state;

    const permissions = getLocationPermissions(state);

    return {
        lastPublished,
        publishing,
        visible: permissions.hasAny("menudata:publish"),
        canViewMenuData: permissions.hasAll("menudata:raw:read"),
        lastPublishedUrl,
        disabled: getIsPublishDisabled(state),
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { restaurantLocation: { slug } }: LocationComponentProps
): FunctionProps<Props> => ({
    onClick: () => dispatch(publish(slug)),
});

export default withRouter(withLocation(connect(mapStateToProps, mapDispatchToProps)(PublishButton)));
