import { opsApi } from "API";
import { GraphQLItem, GraphQLItemId } from "../../../common/scaffolding/types";

export const editCategory = async (locationId: string, categoryId: string): Promise<GraphQLCategory> => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId, categoryId });
    return res.data.category;
};

type GraphQLPayload = { category: GraphQLCategory };

export interface GraphQLCategory extends GraphQLItem {
    type: string;
    services: Array<GraphQLItemId>;
    menus: Array<GraphQLItemId>;
    items: Array<GraphQLItemId>;
    special: boolean;
    image: string;
    showFromPrice: boolean;
}

const query = `
    query($locationId: ID!, $categoryId: ID!) { 
        category(locationId: $locationId, id: $categoryId) {
            id,
            internalName,
            displayName,
            type,
            services {
                id
            },
            menus{
                id
            },
            items{
               id
            },
            image,
            special,
            showFromPrice
        } 
    }
`;
