import { connect } from "react-redux";
import * as actions from "../actions";
import { RouteComponentProps } from "react-router";
import { Props, DailySalesReportPage } from "../components/DailySalesReportPage";
import { FunctionProps, DataProps } from "types/utils";
import { PageLoadingProps } from "common/loader/withLoader";
import { getLocationPermissions } from "features/location/selectors/getLocationPermissions";
import { AppState } from "features/state";
import { isLoaded } from "../../../common/loader/isLoaded";
import { getIsGroupTabGratuitySupported } from "../../location/selectors/getGroupTabsGratuitySupported";

const mapStateToProps = (
    state: AppState,
    { match: { params } }: RouteComponentProps<RouteParams>
): DataProps<PageLoadingProps<Props>> => {
    const {
        dailySalesReport: { list },
        locations: { active: activeLocation },
    } = state;

    if (list.status === "loaded" && isLoaded(activeLocation)) {
        const canViewRemittance = getLocationPermissions(state).has("reports:remittance");

        return {
            loadStatus: "loaded",
            report: list.data,
            canViewRemittance,
            groupTabGratuitySupported: getIsGroupTabGratuitySupported(activeLocation.data),
        };
    }

    return {
        loadStatus: list.status,
    };
};

interface RouteParams {
    location: string;
}

const mapDispatchToProps = (
    dispatch: any,
    { match: { params } }: RouteComponentProps<RouteParams>
): FunctionProps<Props> => ({
    fetch: (startDate: string, endDate: string) => dispatch(actions.list(params.location, startDate, endDate)),
    export: (startDate: string, endDate: string) => dispatch(actions.exportCsv(params.location, startDate, endDate)),
});

export const DailySalesReportPageContainer = connect(mapStateToProps, mapDispatchToProps)(DailySalesReportPage as any);
