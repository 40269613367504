import { createAction } from "../reducers/edit";
import { EditablePriceListSettings, PriceListItem } from "../types";
import { PriceListInputModel, savePriceList } from "../api/savePriceList";
import { scaffoldSaveAction } from "common/scaffolding/actions/scaffoldSaveAction";
import { History } from "history";
import { mapModifiersInputModel, mapProductsInputModel } from "./bulkSave";
import { LocationSummary } from "features/location";

export const clone = (
    location: LocationSummary,
    region: string,
    editablePriceList: EditablePriceListSettings,
    priceLevels: PriceListItem[],
    history: History
) =>
    scaffoldSaveAction(
        (state) => state.priceLists.edit,
        createAction,
        async (priceListId: string | undefined) => {
            const priceListInputModel = mapInputModel(editablePriceList, priceLevels);
            return (await savePriceList(location.id, priceListId, priceListInputModel)).id;
        },
        undefined,
        true,
        (id: string) => {
            return history.replace(`/${region}/${location.slug}/menu/pricelists/${id}`);
        }
    );

const mapInputModel = (
    { id, displayName, posId, globalAdjustment, adjustmentType }: EditablePriceListSettings,
    priceLevels: PriceListItem[]
): PriceListInputModel => ({
    displayName,
    posId,
    globalAdjustment: globalAdjustment || null,
    products: mapProductsInputModel(priceLevels),
    modifiers: mapModifiersInputModel(priceLevels),
    isClone: true,
    adjustmentType: adjustmentType || null,
});
