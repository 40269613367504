import { Field } from "formik";
import { Row, Col } from "antd";
import { MeuTimePicker } from "components/forms/TimePicker";

import "./EditTimeRange.scss";

export interface Props {
    disabled?: boolean;
}

export const EditTimeRange = ({ disabled }: Props) => (
    <Row justify="space-between">
        <Col span={12}>
            <Field name={`times.startTime`} component={MeuTimePicker} disabled={disabled} />
        </Col>
        <Col span={12}>
            <Field name={`times.endTime`} component={MeuTimePicker} disabled={disabled} />
        </Col>
    </Row>
);
