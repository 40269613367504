import * as Yup from "yup";
import { Category, PackageUpdateInput } from "../types";

export const editablePackageSchema = Yup.object<PackageUpdateInput>().shape({
    id: Yup.string(),
    displayName: Yup.string()
        .max(23, "Package name must be less than 23 characters")
        .required("Package name is required"),
    description: Yup.string().max(80, "Package name must be less than 80 characters").nullable(true),
    enabled: Yup.boolean(),
    categories: Yup.array<Category>().required("No items selected"),
});
