import styles from "./PromotionDetails.module.scss";

import { useDebounceCallback } from "@react-hook/debounce";
import { ActiveLocation, LocationLocaleContext } from "features/location";
import { Card, Row, Title } from "core/components/card";
import { EditablePromotion, PromotionType } from "features/promotions/types";
import { FastField, Field, FormikProps, useFormikContext } from "formik";
import { FieldErrors } from "core/components/form/fieldErrors";
import { Input } from "core/components/form/input";
import { PosField } from "components/forms/posField/PosField";
import { PosFieldDescription } from "components/forms/posField/PosFieldDescription";
import { queryPromoCodeAvailable } from "features/promotions/api/queryPromoCodeAvailable";
import { toUpper } from "common/data/stringUtil";
import { useCallback, useContext, useEffect, useState } from "react";
import { CommonEditProps } from "../EditPromotionPage";
import { usePosField } from "components/forms/posUI/usePosField";
import { useIsNewRoute } from "common/hooks";
import { Container } from "core/components/grid/Grid";
import { RadioGroup } from "core/components/form/radio";

interface Props extends CommonEditProps {
    id: string;
    restaurantLocation: ActiveLocation;
    setValidatingCode: (validating: boolean) => void;
}

const promotionTypeOptions = [
    { text: "Create a single promo code", value: PromotionType.SINGLECODE },
    { text: "Generate multiple promo codes", value: PromotionType.MULTIPLECODE },
];

export const PromotionDetails = ({ disableFields, id, restaurantLocation, setValidatingCode }: Props) => {
    const form = useFormikContext<EditablePromotion>();
    const isNew = useIsNewRoute();
    const locationLocale = useContext(LocationLocaleContext);
    const [isMultiPromotionCodes, setIsMultiPromotionCodes] = useState(
        form.values.promotionType === PromotionType.MULTIPLECODE
    );

    useEffect(() => {
        // need to use state so the validation will happen
        setIsMultiPromotionCodes(form.values.promotionType === PromotionType.MULTIPLECODE);
    }, [form.values.promotionType]);

    const handleCodeChange = useCallback(
        async (value: string, form: FormikProps<EditablePromotion>) => {
            if (!value) {
                // treat empty value as ok for uniqueness - other rules will handle it
                form.setFieldValue("hasUniqueCode", true);
                return;
            }

            setValidatingCode(true);

            try {
                const result = await queryPromoCodeAvailable(
                    form.values.promotionType,
                    value,
                    restaurantLocation.id,
                    id
                );
                form.setFieldValue("hasUniqueCode", result);
                form.setFieldTouched("code", true);
            } catch (err) {
                // if we cant get response, assume worst and let user try again
                form.setFieldValue("hasUniqueCode", false);
            }

            setValidatingCode(false);
        },
        [id, restaurantLocation.id, setValidatingCode]
    );

    const debouncedHandleCodeChange = useDebounceCallback(handleCodeChange, 500);

    const discountPosField = usePosField({
        restaurantLocation,
        operationName: "OrderCreate",
        fieldName: "discountPosId",
    });

    const disableCodeField = disableFields || form.values.promotionFileName || (!isNew && isMultiPromotionCodes);

    return (
        <>
            <Card>
                <Row collapse="down">
                    <Title title="Details" />
                </Row>
                <Row collapse="down">
                    <FieldErrors fieldNames={["displayName"]}>
                        <Field
                            name="displayName"
                            component={Input}
                            label="Promotion name"
                            placeholder="Example: staff discount"
                            disabled={disableFields}
                            markRequired
                        />
                    </FieldErrors>
                </Row>
                <Row collapse={discountPosField ? "down" : undefined}>
                    <FieldErrors fieldNames={["internalName"]}>
                        <Field
                            name="internalName"
                            component={Input}
                            label="Internal name"
                            placeholder="Only you can see this"
                            disabled={disableFields}
                        />
                    </FieldErrors>
                </Row>
                {discountPosField && (
                    <Row>
                        <FieldErrors fieldNames={["posId"]}>
                            <FastField
                                name={"posId"}
                                component={PosField}
                                definition={discountPosField}
                                disabled={disableFields}
                                label={discountPosField.title || "POS ID"}
                            />
                            <PosFieldDescription definition={discountPosField} />
                        </FieldErrors>
                    </Row>
                )}
            </Card>
            <Card>
                <Row collapse="down">
                    <Title title="Promo code" />
                </Row>
                {isNew && !disableCodeField && (
                    <Row collapse={"down"}>
                        <FieldErrors fieldNames={["promotionType"]}>
                            <Field
                                name="promotionType"
                                component={RadioGroup}
                                direction="column"
                                options={promotionTypeOptions}
                                disabled={disableFields}
                            />
                        </FieldErrors>
                    </Row>
                )}
                <Row className={styles.codeRow}>
                    {isMultiPromotionCodes && (
                        <Container className={styles.generatedCodeCount} as="span">
                            <FieldErrors fieldNames={["generatedCodeCount"]}>
                                <Field
                                    name="generatedCodeCount"
                                    component={Input}
                                    label="How many codes do you need"
                                    placeholder=""
                                    disabled={disableCodeField}
                                    inputTest={/\d*/g}
                                    masked={{
                                        mask: (val?: string) => {
                                            return val ? locationLocale.formatNumber(Number(val)) : val;
                                        },
                                        unmask: (val?: string) => val?.replace(/\D/g, ""),
                                    }}
                                    markRequired
                                    autoReset={{
                                        value: false,
                                        error: true,
                                        touched: true,
                                    }}
                                    maxLength={6}
                                />
                            </FieldErrors>
                        </Container>
                    )}
                    <FieldErrors fieldNames={["code"]}>
                        <Field
                            name="code"
                            component={Input}
                            label={isMultiPromotionCodes ? "Code prefix" : "Promo code"}
                            placeholder="Example: STAFF5"
                            disabled={disableCodeField}
                            markRequired
                            inputTest={/^[a-zA-Z0-9]*$/}
                            formatOnChange={toUpper}
                            onChange={(value: string) => debouncedHandleCodeChange(value, form)}
                            after={() => {
                                const length = form.values.code?.length || 0;
                                const maxLength = isMultiPromotionCodes ? 7 : 12;
                                if (length > maxLength && !form.touched.code) {
                                    form.setFieldTouched("code", true);
                                }
                                return (
                                    <span>
                                        {length}/{maxLength}
                                    </span>
                                );
                            }}
                        />
                    </FieldErrors>
                </Row>
            </Card>
        </>
    );
};
