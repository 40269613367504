import { createSelector } from "reselect";
import moment from "moment-timezone";
import { ServiceSummary } from "../types";
import { getNow } from "./getVenueTime";
import { AppState } from "../../state";
import { isWithinTimes, toServiceTime } from "common/utility/liveTimes";

export const getServices = ({ services: { list } }: AppState) => (list.status === "loaded" ? list.data : null);

export const getSections = ({ sections: { list } }: AppState) => (list.status === "loaded" ? list.data : null);

const DATE_FORMAT = "YYYY-MM-DD";
const LEGACY_DATE_FORMAT = "DD/MM/YYYY";
const LEGACY_DATE_PATTERN = /\d{2}\/\d{2}\/\d{4}/;

const parseDate = (date: string) =>
    LEGACY_DATE_PATTERN.test(date) ? moment(date, LEGACY_DATE_FORMAT) : moment(date, DATE_FORMAT);

const byDate = (a: moment.Moment, b: moment.Moment) => (a.isBefore(b) ? -1 : b.isBefore(a) ? 1 : 0);

const byActiveMinutes = (a: ServiceSummary, b: ServiceSummary) => a.activeMinutes - b.activeMinutes;

const getActiveDaysOfWeek = (daysOfWeek: number) => {
    let activeDaysOfWeek = new Array(7).fill(true);
    if (!daysOfWeek) return activeDaysOfWeek;

    activeDaysOfWeek = activeDaysOfWeek.map((_, index) => (daysOfWeek & (1 << index)) !== 0);
    // Move Sunday to element 0 as Moment days are Sun (0) - Sat (6)
    activeDaysOfWeek.unshift(activeDaysOfWeek.pop());
    return activeDaysOfWeek;
};

export const getEffectiveStartDate = ({ daysOfWeek, startTime, dates }: ServiceSummary, now: moment.Moment) => {
    let startDate = parseDate(now.format(LEGACY_DATE_FORMAT));

    if (dates && dates.length) {
        const startDates = dates.map((d) => parseDate(d.startDate)).sort(byDate);
        startDate = startDates.filter((d) => d.isSameOrAfter(startDate))[0] || startDates[startDates.length - 1];
    }

    const activeDaysOfWeek = getActiveDaysOfWeek(daysOfWeek);

    while (!activeDaysOfWeek[startDate.day()]) {
        startDate.add(1, "d");
    }

    startDate.hours(Math.floor(startTime / 100));
    startDate.minutes(startTime % 100);

    return startDate;
};

const isActiveDayOfWeek = ({ daysOfWeek }: ServiceSummary, now: moment.Moment) =>
    getActiveDaysOfWeek(daysOfWeek)[now.day()];

const isActiveDateAndTime = (service: ServiceSummary, now: moment.Moment) => {
    const { startTime, endTime, dates } = service;

    const timeNow = toServiceTime(now);
    const isWithin = isWithinTimes(startTime, endTime, now);

    if (isWithin && timeNow < startTime) {
        now = now.clone().add(-1, "day");
    }

    if (!isActiveDayOfWeek(service, now)) {
        return false;
    }

    if (!isWithin || !dates || !dates.length) return isWithin;

    for (const date of dates) {
        const startDate = parseDate(date.startDate);
        const endDate = parseDate(date.endDate).add(1, "days");
        if (now.isSameOrAfter(startDate) && now.isBefore(endDate)) return true;
    }

    return false;
};

export const isActive = (service: ServiceSummary, now: moment.Moment) =>
    service.available && isActiveDateAndTime(service, now);

const getSortedActiveServices = (services: ServiceSummary[], now: moment.Moment) =>
    services.filter((s) => isActive(s, now)).sort(byActiveMinutes);

export const getActiveServices = createSelector(getServices, getSections, getNow, (services, sections, now) => {
    if (!services) return [];

    if (!sections || !sections.length) {
        return getSortedActiveServices(services, now);
    }

    const activeServices: ServiceSummary[] = [];
    for (const section of sections) {
        const servicesForSection = services.filter(
            (service) => service.sections.some((s) => s.id === section.id) && service.available
        );
        const activeService = getSortedActiveServices(servicesForSection, now)[0];
        if (activeService) {
            const activeSave = activeServices.find((ser) => ser.id === activeService.id);
            if (!!activeSave) {
                activeSave.sections.push(section);
            } else {
                activeServices.push({
                    ...activeService,
                    sections: [section],
                });
            }
        }
    }
    return activeServices.sort(byActiveMinutes);
});

export const getInactiveSections = createSelector(getSections, getActiveServices, (sections, activeServices) => {
    if (!sections) return [];
    return sections.filter(
        (section) => !activeServices.some((service) => service.sections.some((s) => s.id === section.id))
    );
});

export const getInactiveServices = createSelector(
    getServices,
    getActiveServices,
    getNow,
    (services, activeServices, now) => {
        if (!services) return [];

        return services
            .filter((s) => activeServices.every((ser) => s.id !== ser.id))
            .map((s) => ({
                service: s,
                effectiveStartDate: getEffectiveStartDate(s, now),
            }))
            .sort((a, b) => {
                if (a.effectiveStartDate.isSame(b.effectiveStartDate)) {
                    return byActiveMinutes(a.service, b.service);
                }
                return byDate(a.effectiveStartDate, b.effectiveStartDate);
            })
            .map((s) => s.service);
    }
);
