import styles from "./AlertsList.module.scss";

import { ReactNode } from "react";
import { Alert, AlertStatus } from "./Alert";

interface Props {
    alerts: ReactNode[];
    status: AlertStatus;
}

export const AlertsList = ({ alerts = [], status }: Props) => {
    if (!alerts.length) {
        return null;
    }

    return (
        <div className={styles.root}>
            <Alert status={status}>
                {alerts.length === 1 ? (
                    <div>{alerts[0]}</div>
                ) : (
                    <div>
                        <ul className={styles.list}>
                            {alerts.map((warning, index) => (
                                <li key={index}>{warning}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </Alert>
        </div>
    );
};
