import { CatalogueWarnings } from "../CatalogueWarnings";
import { getModifierCatalogueItems } from "features/catalogue/selectors/getModifierCatalogueItems";
import { getModifierGroupsWithSyncFailed } from "features/catalogue/selectors/getCatalogueItemsWithSyncFailed";
import { getModifierGroupsWithSyncSuccess } from "features/catalogue/selectors/getCatalogueItemsWithSyncSuccess";
import { useSelector } from "react-redux";

const WarningsContainer = () => {
    const items = useSelector(getModifierCatalogueItems);
    const itemsWithSyncFailed = useSelector(getModifierGroupsWithSyncFailed);
    const itemsWithSyncSuccess = useSelector(getModifierGroupsWithSyncSuccess);

    return (
        <CatalogueWarnings
            collectionName="modifiers"
            items={items}
            itemsWithSyncFailed={itemsWithSyncFailed}
            itemsWithSyncSuccess={itemsWithSyncSuccess}
        />
    );
};

export const Warnings = WarningsContainer;
