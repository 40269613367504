import styles from "./TimePicker.module.scss";

import { FieldProps, getIn } from "formik";

import moment, { Moment } from "moment";

import { TimePicker as AntTimePicker, TimePickerProps } from "antd";

import { ReactElement, useEffect, useState } from "react";

interface Props extends TimePickerProps {
    hideFooter?: boolean;
}

export const TimePicker = ({
    disabled,
    form,
    field: { name, value },
    meta,
    hideFooter = false,
    onOpenChange,
    ...rest
}: Props & FieldProps): ReactElement => {
    const [momentValue, setMomentValue] = useState<Moment | null | undefined>(
        typeof value === "number" ? moment(String(value).padStart(3, "0"), "Hmm") : undefined
    );

    const handleChange = (time: Moment | null) => {
        form.setFieldValue(name, time ? parseInt(time.format("Hmm"), 10) : null);
    };

    useEffect(() => {
        setMomentValue(typeof value === "number" ? moment(String(value).padStart(3, "0"), "Hmm") : undefined);
    }, [value]);

    const { touched, errors } = form || {};

    const hasError = name && getIn(errors, name) && getIn(touched, name);

    return (
        <AntTimePicker
            use12Hours
            format="h:mm a"
            minuteStep={15}
            allowClear={false}
            value={momentValue}
            onChange={handleChange}
            onSelect={handleChange}
            disabled={disabled}
            showNow={false}
            className={hasError ? `${styles.rootError} input--error` : styles.root}
            popupClassName={hideFooter ? styles.popupFooterHidden : styles.popup}
            name={name}
            onOpenChange={(open: boolean) => {
                form?.setFieldTouched(name, true);
                onOpenChange?.(open);
            }}
            {...rest}
        />
    );
};
