import { AppState } from "features";
import { TagGroup } from "features/tags";
import { createSelector } from "reselect";

const getEditProduct = ({ menuItems: { edit } }: AppState) => (edit.status === "loaded" ? edit.data : null);

const getTemplatesList = ({ menuItemTemplates: { list } }: AppState) => (list.status === "loaded" ? list.data : null);

const getTagsList = ({ tags: { list } }: AppState) => (list.status === "loaded" ? list.data : null);

export const getEditProductTemplateId = createSelector(getEditProduct, (editProduct) =>
    editProduct ? editProduct.template : null
);

export const getEditProductTemplate = createSelector(
    getTemplatesList,
    getEditProductTemplateId,
    (templates, templateId) => (templates && templateId ? templates.find((t) => t.id === templateId) || null : null)
);

export const getEditProductTagGroups = createSelector(
    getEditProductTemplate,
    getTagsList,
    (template, tagsList): TagGroup[] | null => {
        if (template === null || tagsList === null) {
            return null;
        }

        return template.tagGroups
            .map((tagGroupId) => tagsList.find((tagGroup) => tagGroup.id === tagGroupId))
            .filter(notUndefined);
    }
);

export const getEditProductWithTags = createSelector(getEditProduct, getEditProductTagGroups, (item, groups) => {
    if (item && groups) {
        const tags = groups.reduce((map, group) => {
            map[group.id] = group.tags.filter((tag) => item.tagList.indexOf(tag.id) !== -1).map((tag) => tag.id);
            return map;
        }, {});

        return {
            ...item,
            tags,
        };
    }

    return item;
});

const getDefaultTemplate = createSelector(getTemplatesList, (templates) => {
    if (templates) {
        return templates.find((t) => t.type === "food");
    }
    return null;
});

export const getEditProductWithDefaultTemplate = createSelector(
    getEditProduct,
    getDefaultTemplate,
    (item, template) => {
        if (item && template) {
            return {
                ...item,
                template: template.id,
            };
        }
        return item;
    }
);

export const getEditProductTemplateType = createSelector(getEditProductTemplate, (template) => {
    if (!template) {
        return null;
    }
    return template.type;
});

function notUndefined<T>(x: T | undefined): x is T {
    return x !== undefined;
}
