// tslint:disable:jsx-no-lambda

import * as React from "react";

import { RouteComponentProps } from "react-router";
import { DataListingPage } from "common/scaffolding/components/DataListingPage";
import { WaiterSummary } from "features/waiter/types";

export interface Props {
    items: WaiterSummary[];
}

export class ListWaitersPage extends React.Component<Props & RouteComponentProps<any>> {
    render() {
        const { items, match } = this.props;

        return (
            <DataListingPage
                title="Staff"
                description="Staff can access the Venue Management system using a PIN"
                newItemLabel="Add new staff member..."
                newItemRoute={`${match.url}/new`}
                editItemRoute={`${match.url}/:id`}
                items={items}
            />
        );
    }
}
