import { CmsItemSummary, GraphQLItem } from "common/scaffolding/types";
import { EditablePriceLevels } from "./EditablePriceLevels";

export interface EditablePriceList {
    settings: EditablePriceListSettings;
    activeTab: string;
    priceLevels: EditablePriceLevels;
}

export interface EditablePriceListSettings extends CmsItemSummary {
    posId: string | null;
    useGlobalAdjustment: boolean;
    globalAdjustment?: number;
    adjustmentType: AdjustmentType | null;
}

export interface EditablePriceListItem extends GraphQLItem {
    itemType: string;
    price?: number;
    parentId: string;
}

export interface EditablePriceLevel {
    id?: string;
    price?: number;
    childId?: string;
}

export enum AdjustmentType {
    Percentage = "PERCENTAGE",
    FixedAmount = "FIXEDAMOUNT",
}
