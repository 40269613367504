import { createAction } from "../reducers/list";
import { WaiterSummary } from "../types";
import { sortByDisplayName } from "foundation/dataConventions/sortByDisplayName";
import { scaffoldListAction } from "../../../common/scaffolding/actions/scaffoldListAction";
import { listWaiters } from "../api/listWaiters";

export const list = (location: string) =>
    scaffoldListAction<WaiterSummary>(
        (state) => state.waiters.list,
        createAction,
        async () => {
            const waiters = await listWaiters(location);

            return waiters.sort(sortByDisplayName);
        }
    );
