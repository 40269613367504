import { createSelector } from "reselect";
import { getLocationPermissions } from "../../location/selectors/getLocationPermissions";
import { PageSectionAccess } from "../components/EditLocationSettingsPage";
import { posOperationBehaviorFilter } from "../../posConfiguration/selectors/posOperationFilter";
import { getLocationSettings } from ".";
import { OrderFlowType } from "../types/OrderFlow";

export const getVenueServiceFeeSettingsAccess = createSelector(
    getLocationSettings,
    getLocationPermissions,
    posOperationBehaviorFilter("OrderCreate", "VenueServiceFee"),
    (settings, permissions, posSupportsVenueServiceFee): PageSectionAccess => {
        const venueServiceFee = settings?.venueServiceFee;
        const hasUpdatePermission = permissions.hasAny("settings:update");

        const hasReadVenueServiceFeePermission = permissions.hasAny("venueservicefee:read");
        const hasUpdateVenueServiceFeePermission = permissions.hasAny("venueservicefee:update");
        const venueServiceFeeIsActive = !!venueServiceFee?.enabled;

        if (settings?.orderFlow !== OrderFlowType.PAYONLY || !posSupportsVenueServiceFee) return "off";
        if (hasUpdatePermission && hasUpdateVenueServiceFeePermission) return "editable";
        if (venueServiceFeeIsActive && hasReadVenueServiceFeePermission) return "read-only";

        return "off";
    }
);
