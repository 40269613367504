import { toTitleCase } from "common/utility/StringUtils";
import { Card, Row, Title } from "core/components/card";
import { FieldErrors } from "core/components/form/fieldErrors";
import { Input } from "core/components/form/input";
import { RadioGroup } from "core/components/form/radio";
import { Select } from "core/components/form/select";
import { TextArea } from "core/components/form/textarea";
import { ItemValidationStatus } from "features/catalogue/types";
import { EditableProduct } from "features/catalogue/types";
import { MenuItemTemplate } from "features/menuitemtemplate";
import { FastField, useFormikContext } from "formik";
import { useCallback, useEffect, useMemo } from "react";

interface Props {
    allowTemplateTypeChange: boolean;
    disableFields: boolean;
    disableLinkedFields: boolean;
    templateList: MenuItemTemplate[];
    syncStatus: ItemValidationStatus | undefined;
    posSyncEnabled: boolean;
}

const typeOptions = [
    { text: "Food", value: "food" },
    { text: "Drink", value: "drink" },
];

export const ProductDetails = ({
    allowTemplateTypeChange,
    disableFields,
    disableLinkedFields,
    posSyncEnabled,
    syncStatus,
    templateList,
}: Props) => {
    const { values, touched, initialValues, setFieldValue, setFieldTouched, validateField } =
        useFormikContext<EditableProduct>();

    const { itemType, template: selectedTemplateId } = values;

    const selectedTemplate = useMemo(() => {
        return templateList.find((template) => template.id === selectedTemplateId);
    }, [templateList, selectedTemplateId]);

    const defaultFoodTemplate = useMemo(() => templateList.find((t) => t.type === "food"), [templateList]);

    const defaultDrinkTemplate = useMemo(() => templateList.find((t) => t.type === "drink"), [templateList]);

    // ensure that pseudo-field `itemType` always matches the type of the selected template
    useEffect(() => {
        if (!selectedTemplate) {
            return;
        }

        setFieldValue("itemType", selectedTemplate.type);
    }, [initialValues, selectedTemplate, setFieldValue]);

    // call out missing item type immediately
    useEffect(() => {
        if (!itemType && !touched.itemType) {
            setFieldTouched("itemType", true);
            validateField("itemType");
        }
    }, [itemType, setFieldTouched, touched.itemType, validateField]);

    const handleTypeChange = useCallback(
        (value: "food" | "drink") => {
            if (value === "food") {
                setFieldValue("template", defaultFoodTemplate?.id);
            }

            if (value === "drink") {
                setFieldValue("template", defaultDrinkTemplate?.id);
            }
            setFieldValue("tags", {});
        },
        [defaultDrinkTemplate?.id, defaultFoodTemplate?.id, setFieldValue]
    );

    const handleTemplateChange = useCallback(() => {
        setFieldValue("tags", {});
    }, [setFieldValue]);

    const templateOptions = useMemo(() => {
        if (itemType) {
            const available = templateList.filter((t) => t.type === itemType);

            return available.map((t) => ({
                label: t.displayName,
                value: t.id,
            }));
        }

        return [];
    }, [itemType, templateList]);

    return (
        <Card>
            <Row collapse="down">
                <Title title="General Information" />
            </Row>
            <Row collapse="down">
                <FieldErrors fieldNames={["displayName"]}>
                    <FastField
                        autoComplete="off"
                        name="displayName"
                        component={Input}
                        label="Product name"
                        placeholder="Product name"
                        disabled={disableFields || disableLinkedFields}
                        markRequired
                    />
                </FieldErrors>
            </Row>
            <Row collapse="down">
                <FieldErrors fieldNames={["internalName"]}>
                    <FastField
                        autoComplete="off"
                        name="internalName"
                        component={Input}
                        label="Internal name"
                        placeholder="Internal name (optional)"
                        disabled={disableFields}
                    />
                </FieldErrors>
            </Row>
            <Row collapse={"down"} id="input-item-type">
                <FieldErrors fieldNames={["itemType"]}>
                    <FastField
                        name="itemType"
                        legend="Item Type"
                        markRequired={true}
                        component={RadioGroup}
                        options={typeOptions}
                        onChange={handleTypeChange}
                        disabled={disableFields || disableLinkedFields || !allowTemplateTypeChange}
                    />
                </FieldErrors>
            </Row>
            {selectedTemplate && templateOptions?.length > 1 && (
                <Row collapse={"down"}>
                    <FastField
                        name="template"
                        label={`${toTitleCase(selectedTemplate.type)} Type`}
                        component={Select}
                        options={templateOptions}
                        disabled={disableFields || disableLinkedFields}
                        onChange={handleTemplateChange}
                    />
                </Row>
            )}
            <Row>
                <FastField
                    name="description"
                    label="Description"
                    component={TextArea}
                    disabled={disableFields || disableLinkedFields}
                    placeholder="Describe this item…"
                />
            </Row>
        </Card>
    );
};
