// tslint:disable:jsx-no-lambda

import * as React from "react";

import { Divider, Modal, Table, Tooltip } from "antd";
import { DailySales, DailySalesReport } from "../types";
import { ColumnProps } from "antd/lib/table";
import { PageTitle } from "common/scaffolding/components/DataListingPage/PageTitle";
import { ReportDateFilter } from "./ReportDateFilter";
import AutoSizer from "react-virtualized-auto-sizer";
import "./DailySalesReportPage.scss";
import { LoadStatus } from "common/loader";
import { LocationLocaleContext } from "features/location";
import { LocationLocale } from "features/location/types/LocationLocale";
import { DailySalesDetailsModalContainer } from "../containers/DailySalesDetailsModalContainer";

export interface Props {
    report: DailySalesReport;
    loadStatus: LoadStatus;

    fetch: (startDate: string, endDate: string) => void;
    export: (startDate: string, endDate: string) => void;

    canViewRemittance: boolean;
    groupTabGratuitySupported: boolean;
}

export interface State {
    selectedRowKeys: string[] | number[];
    detailsDate: Date | null;
}

const formatCurrency = (input: number) => (
    <LocationLocaleContext.Consumer>
        {(locale: LocationLocale) => locale.formatCurrency(input)}
    </LocationLocaleContext.Consumer>
);

const formatDate = (input: Date) => (
    <LocationLocaleContext.Consumer>
        {(locale: LocationLocale) => locale.formatDate(input)}
    </LocationLocaleContext.Consumer>
);

const getDailySalesReportColumns: (groupTabGratuitySupported: boolean) => ColumnProps<any>[] = (
    groupTabGratuitySupported: boolean
) => [
    {
        title: () => tooltipLabel(fieldLabels.diningDate),
        dataIndex: "diningDate",
        width: "25%",
        render: formatDate,
    },
    {
        title: () => tooltipLabel(fieldLabels.sales),
        dataIndex: "sales",
        width: "15%",
        render: formatCurrency,
        align: "right",
    },
    {
        title: () => tooltipLabel(fieldLabels.surcharges),
        dataIndex: "surcharges",
        width: "15%",
        render: formatCurrency,
        align: "right",
    },
    {
        title: () => tooltipLabel(fieldLabels.venueServiceFees),
        dataIndex: "venueServiceFees",
        width: "15%",
        render: formatCurrency,
        align: "right",
    },
    {
        title: () => {
            if (groupTabGratuitySupported) {
                return tooltipLabel(fieldLabels.tipsAndGratuity);
            }
            return tooltipLabel(fieldLabels.tips);
        },
        dataIndex: "tips",
        width: "15%",
        render: formatCurrency,
        align: "right",
    },
    {
        title: () => tooltipLabel(fieldLabels.refunds),
        dataIndex: "refunds",
        width: "15%",
        render(text, record) {
            return {
                props: {
                    style: { color: parseFloat(text) > 0.0 ? "red" : "" },
                },
                children: formatCurrency(0 - parseFloat(text)),
            };
        },
        align: "right",
    },
    {
        title: () => tooltipLabel(fieldLabels.total),
        dataIndex: "total",
        width: "15%",
        render: formatCurrency,
        align: "right",
    },
];

export class DailySalesReportPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedRowKeys: [],
            detailsDate: null,
        };
    }
    onSelectedRowKeysChange = (selectedRowKeys: string[] | number[]) => {
        this.setState({ selectedRowKeys });
    };

    onFilterRangeChanged = (startDate: string, endDate: string) => {
        this.props.fetch(startDate, endDate);
    };

    onExport = (startDate: string, endDate: string) => {
        this.props.export(startDate, endDate);
    };

    render() {
        const { loadStatus, report, groupTabGratuitySupported } = this.props;
        const { detailsDate } = this.state;

        return (
            <div className="data-listing-page daily-sales-report-page">
                <PageTitle title="Daily Sales Report" description="" />

                <ReportDateFilter onRangeChanged={this.onFilterRangeChanged} onDownloadCsv={this.onExport} />

                <Divider />

                <div className="table-container">
                    <AutoSizer defaultHeight={400} disableWidth={true}>
                        {({ height }) => (
                            <Table
                                loading={loadStatus === "loading"}
                                pagination={false}
                                onRow={this.getRowEventHandlers}
                                columns={getDailySalesReportColumns(groupTabGratuitySupported)}
                                dataSource={(report || {}).items}
                                rowKey={(row) => row.diningDate.toString()}
                                // useFixedHeader={true}
                                scroll={{ y: height - 100 }}
                                footer={() => this.renderFooter()}
                            />
                        )}
                    </AutoSizer>
                    {detailsDate && (
                        <LocationLocaleContext.Consumer>
                            {(locale: LocationLocale) => (
                                <Modal
                                    visible={true}
                                    onCancel={this.onCloseDetails}
                                    footer={null}
                                    title={"Dining Date: " + locale.formatLongDate(new Date(detailsDate))}
                                    className="dailysales__details__modal"
                                    width={760}
                                >
                                    <DailySalesDetailsModalContainer diningDate={detailsDate} />
                                </Modal>
                            )}
                        </LocationLocaleContext.Consumer>
                    )}
                </div>
            </div>
        );
    }

    getRowEventHandlers = (row: DailySales) => {
        return {
            onClick: () => {
                return this.onRowClick(row);
            },
        };
    };

    onRowClick = (row: DailySales) => {
        if (!this.props.canViewRemittance) {
            return;
        }

        this.setState({
            detailsDate: row.diningDate,
        });
    };

    onCloseDetails = () => {
        this.setState({
            detailsDate: null,
        });
    };

    renderFooter = () => {
        if (!this.props.report) {
            return null;
        }

        const {
            totalSales = 0,
            totalTips = 0,
            totalRefunds = 0,
            grandTotal = 0,
            totalSurcharges = 0,
            totalVenueServiceFee = 0,
        } = this.props.report || {};

        return (
            <table className="">
                <colgroup>
                    <col style={{ width: "25%", minWidth: "" }} />
                    <col style={{ width: "15%", minWidth: "15%" }} />
                    <col style={{ width: "15%", minWidth: "15%" }} />
                    <col style={{ width: "15%", minWidth: "15%" }} />
                    <col style={{ width: "15%", minWidth: "15%" }} />
                    <col style={{ width: "15%", minWidth: "15%" }} />
                </colgroup>
                <tfoot className="ant-table-tfoot">
                    <tr>
                        <td className="ant-table-align-right"></td>
                        <td className="ant-table-align-right" style={{ textAlign: "right" }}>
                            {formatCurrency(totalSales)}
                        </td>
                        <td className="ant-table-align-right" style={{ textAlign: "right" }}>
                            {formatCurrency(totalSurcharges)}
                        </td>
                        <td className="ant-table-align-right" style={{ textAlign: "right" }}>
                            {formatCurrency(totalVenueServiceFee)}
                        </td>
                        <td className="ant-table-align-right" style={{ textAlign: "right" }}>
                            {formatCurrency(totalTips)}
                        </td>
                        <td
                            className="ant-table-align-right"
                            style={totalRefunds > 0 ? { textAlign: "right", color: "#F00" } : { textAlign: "right" }}
                        >
                            {formatCurrency(0 - totalRefunds)}
                        </td>
                        <td className="ant-table-align-right" style={{ textAlign: "right" }}>
                            {formatCurrency(grandTotal)}
                        </td>
                    </tr>
                </tfoot>
            </table>
        );
    };
}

type TooltipLabelProps = typeof fieldLabels.diningDate;

const tooltipLabel = ({ label, tooltip, extra }: TooltipLabelProps & { extra?: string }) => (
    <Tooltip placement="bottomLeft" title={tooltip}>
        <span className={"daily-sales-report-page__header-label"}>{label}</span>
        {extra && <span className={"daily-sales-report-page__header-label--small"}>{extra}</span>}
    </Tooltip>
);

const fieldLabels = {
    diningDate: {
        label: "Dining Date",
        tooltip: "Date when customer dined",
    },
    sales: {
        label: "Sales",
        tooltip: "Total me&u sales on this dining date",
    },
    tips: {
        label: "Tips",
        tooltip: "Total me&u tips on this dining date",
    },
    tipsAndGratuity: {
        label: "Tips + Gratuity",
        tooltip: "Total me&u tips and gratuity on this dining date",
    },
    refunds: {
        label: "Refunds",
        tooltip: "Total refunds issued on this dining date",
    },
    total: {
        label: "Total",
        tooltip: "Sales plus tips",
    },
    surcharges: {
        label: "Surcharges",
        tooltip: "Total surcharges",
        extra: "(inc. in sales)",
    },
    venueServiceFees: {
        label: "Venue Service Fees",
        tooltip: "Total venue service fees",
        extra: "(inc. in sales)",
    },
};
