import { useState, useCallback, useEffect } from "react";
import { Props } from "./ViewBrandingPage";
import SVG from "react-inlinesvg";
import { preloadImage } from "../utils";
import { Divider } from "antd";
import { ImageLoader } from "./ImageLoader";

let fetchTimer = 0;

export const MenuPage = ({ heroImage, logoUrl, displayName, menuBackgroundImage }: Props) => {
    const [imageReady, setImageReady] = useState(false);
    const menuPageImage = menuBackgroundImage || heroImage;

    const fetchBackgroundImage = useCallback(() => {
        window.clearTimeout(fetchTimer);
        if (menuPageImage) {
            preloadImage(menuPageImage)
                .then(() => {
                    setImageReady(true);
                })
                .catch(() => {
                    setImageReady(false);
                    fetchTimer = window.setTimeout(fetchBackgroundImage, 1000);
                });
        }
    }, [menuPageImage]);

    useEffect(() => {
        fetchBackgroundImage();
        return () => window.clearTimeout(fetchTimer);
    }, [menuPageImage, fetchBackgroundImage]);

    const style = {
        "--menu-background-image": `
        linear-gradient(
            360deg, #FFFFFF 0%,
            rgba(255, 255, 255, 0.991615) 4.59%,
            rgba(255, 255, 255, 0.967585) 8.33%,
            rgba(255, 255, 255, 0.9296) 11.34%,
            rgba(255, 255, 255, 0.879348) 13.76%,
            rgba(255, 255, 255, 0.818519) 15.72%,
            rgba(255, 255, 255, 0.7488) 17.35%,
            rgba(255, 255, 255, 0.671881) 18.79%,
            rgba(255, 255, 255, 0.589452) 20.15%,
            rgba(255, 255, 255, 0.5032) 21.59%,
            rgba(255, 255, 255, 0.414815) 23.22%,
            rgba(255, 255, 255, 0.325985) 25.18%,
            rgba(255, 255, 255, 0.2384) 27.6%,
            rgba(255, 255, 255, 0.153748) 30.61%,
            rgba(255, 255, 255, 0.0737185) 34.35%,
            rgba(255, 255, 255, 0) 38.94%
        ),
        url("${menuBackgroundImage}")`,
    } as React.CSSProperties;

    return (
        <>
            <div className="branding-preview__menu__title">
                {!!logoUrl && logoUrl.endsWith(".svg") ? <SVG src={logoUrl} color="black" /> : displayName}
            </div>
            <div className="branding-preview__menu_background" style={menuBackgroundImage ? style : undefined}>
                {menuBackgroundImage ? (
                    <div className="branding-preview__menu">
                        {!!logoUrl && logoUrl.endsWith(".svg") ? <SVG src={logoUrl} color="white" /> : displayName}
                    </div>
                ) : (
                    <div
                        className="branding-preview__menu"
                        style={
                            imageReady
                                ? {
                                      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgb(0, 0, 0, 0.75)) center center / cover, url(${heroImage})`,
                                  }
                                : undefined
                        }
                    >
                        {!heroImage ? (
                            <div className="empty small">No image set</div>
                        ) : !imageReady ? (
                            <ImageLoader />
                        ) : !!logoUrl && logoUrl.endsWith(".svg") ? (
                            <SVG src={logoUrl} color="white" />
                        ) : (
                            displayName
                        )}
                    </div>
                )}
                <div className="branding-preview__menu__category">
                    <div className="branding-preview__menu__category__title">Food</div>
                    <div className="branding-preview__menu__category__row">
                        <span>
                            <div className="empty tile" />
                            Category title
                        </span>
                        <span>
                            <div className="empty tile" />
                            Category title
                        </span>
                    </div>
                    <div className="branding-preview__menu__category__row">
                        <span>
                            <div className="empty tile" />
                            Category title
                        </span>
                        <span>
                            <div className="empty tile" />
                            Category title
                        </span>
                    </div>
                </div>
                <Divider />
                <div className="branding-preview__menu__category">
                    <div className="branding-preview__menu__category__title">Drinks</div>
                </div>
            </div>
        </>
    );
};
