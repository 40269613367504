import { createAction } from "../reducers/edit";
import { scaffoldArchiveAction } from "common/scaffolding/actions/scaffoldArchiveAction";
import { AppState } from "../../state";
import { NotificationMessage } from "../../../common/notificationMessage";
import { EditableVenueMembershipProgram } from "../types";
import { archiveMembership } from "../api";
import { getArchiveMembershipTrackingEventData } from "../selectors";

export const archive = (locationId: string, memberships: EditableVenueMembershipProgram) =>
    scaffoldArchiveAction(
        (state) => state.memberships.edit,
        createAction,
        (id) => archiveMembership(locationId, id),
        null,
        NotificationMessage.ARCHIVE_SUCCESS,
        (appState: AppState) => getArchiveMembershipTrackingEventData(appState, memberships)
    );
