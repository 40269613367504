import styles from "./ModalContent.module.scss";

import { Button } from "core/components/button";
import { CardWidth, Confirm, Modal } from "core/components/modal";
import {
    SkuFilter,
    CatalogueFilters,
    CollectionName,
    Status as PublishStatus,
    StatusFilter,
    SyncStatusFilter,
    MissingContentFilter,
    IsLinkedFilter,
} from "../types";
import { createAction } from "../reducers/filters";
import { getActiveFilters } from "../selectors/getFilters";
import { getCategoryFilterOptions } from "../selectors/getCategoryFilterOptions";
import { getInitialState } from "../reducers/filters";
import { getPosSyncEnabled } from "../selectors/getPosSyncEnabled";
import { getPublishStatusLabel } from "./ListItemPublishStatus";
import { Select, sortOptions } from "core/components/form/select";
import { Row } from "core/components/card";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEqual from "lodash/isEqual";
import { trackEventFilterCatalogue } from "../actions/tracking";
import { getIsParentLocation } from "features/location/selectors/getIsParentLocation";
import { getIsChildLocation } from "features/location/selectors/getIsChildLocation";

interface Props {
    collectionName: CollectionName;
    enableCategoryFilters: boolean;
    onClose: () => void;
    onConfirm: () => boolean | void;
    visible: boolean;
}

export const statusOptions: StatusFilter[] = [
    { label: getPublishStatusLabel(PublishStatus.Published)!, value: PublishStatus.Published },
    { label: getPublishStatusLabel(PublishStatus.NotPublished)!, value: PublishStatus.NotPublished },
    { label: getPublishStatusLabel(PublishStatus.MissingContent)!, value: PublishStatus.MissingContent },
    { label: getPublishStatusLabel(PublishStatus.Staged)!, value: PublishStatus.Staged },
];

export const syncStatusOptions: SyncStatusFilter[] = [
    { label: "Synced", value: "synced" },
    { label: "Skipped", value: "skipped" },
    { label: "Failed to sync", value: "failed" },
];

const skuOptions: SkuFilter[] = [
    { label: "All", value: undefined },
    { label: "Without SKU", value: false },
    { label: "Has SKU", value: true },
];

// Note: although missingContentOptions and isLinkedOptions are the same, we dont share
// them because Select uses referential equality to determine if an option is selected

const missingContentOptions: MissingContentFilter[] = [
    { label: "All", value: undefined },
    { label: "Yes", value: true },
    { label: "No", value: false },
];

const isLinkedOptions: IsLinkedFilter[] = [
    { label: "All", value: undefined },
    { label: "Yes", value: true },
    { label: "No", value: false },
];

export const missingContentFilterOption = missingContentOptions.find((option) => option.value === true)!;

export const FiltersModal = ({ collectionName, enableCategoryFilters, onClose, onConfirm, visible }: Props) => {
    const dispatch = useDispatch();

    const activeFilters = useSelector(getActiveFilters);

    const [selectedFilters, setSelectedFilters] = useState<CatalogueFilters>(activeFilters);

    const categories = useSelector(getCategoryFilterOptions);

    const posSyncEnabled = useSelector(getPosSyncEnabled);

    const isParentLocation = useSelector(getIsParentLocation);

    const isChildLocation = useSelector(getIsChildLocation);

    const handleOpen = useCallback(() => {
        setSelectedFilters(activeFilters);
    }, [activeFilters]);

    const handleCategoryChange = useCallback(
        (selections) => {
            setSelectedFilters({
                ...selectedFilters,
                categories: selections.sort(sortOptions),
            });
        },
        [selectedFilters]
    );

    const handleSkuChange = useCallback(
        (selection) => {
            setSelectedFilters({
                ...selectedFilters,
                sku: selection,
            });
        },
        [selectedFilters]
    );

    const handleStatusChange = useCallback(
        (selections) => {
            setSelectedFilters({
                ...selectedFilters,
                status: selections.sort(sortOptions),
            });
        },
        [selectedFilters]
    );

    const handleSyncStatusChange = useCallback(
        (selections) => {
            setSelectedFilters({
                ...selectedFilters,
                syncStatus: selections.sort(sortOptions),
            });
        },
        [selectedFilters]
    );

    const handleMissingContentChange = useCallback(
        (selection) => {
            setSelectedFilters({
                ...selectedFilters,
                missingContent: selection,
            });
        },
        [selectedFilters]
    );

    const handleIsLinkedChange = useCallback(
        (selection) => {
            setSelectedFilters({
                ...selectedFilters,
                isLinked: selection,
            });
        },
        [selectedFilters]
    );

    const handleClearAll = useCallback(() => {
        setSelectedFilters(getInitialState().active);
    }, []);

    const handleConfirm = useCallback(() => {
        trackEventFilterCatalogue(collectionName, selectedFilters);
        dispatch(createAction.setFilters(selectedFilters));
    }, [dispatch, collectionName, selectedFilters]);

    const isDirty = useMemo(() => {
        return !isEqual(selectedFilters, activeFilters);
    }, [activeFilters, selectedFilters]);

    return (
        <Modal
            cardClassName={styles.card}
            contentContainerClassName={styles.contentContainer}
            footer={
                <>
                    <Button nowrap role="secondary" type="button" onClick={handleClearAll}>
                        Clear all
                    </Button>
                    <Confirm confirmLabel="Ok" onConfirm={handleConfirm} confirmProps={{ disabled: !isDirty }} />
                </>
            }
            onOpen={handleOpen}
            onClose={onClose}
            title={`Filter ${collectionName}`}
            visible={visible}
            width={CardWidth.NARROW}
        >
            {enableCategoryFilters && (
                <Row collapse="down">
                    <Select
                        blurInputOnSelect={false}
                        closeMenuOnSelect={false}
                        isMulti
                        label="Category"
                        menuPlacement="auto"
                        onChange={handleCategoryChange}
                        options={categories as any}
                        value={selectedFilters?.categories}
                        maxMenuHeight={220}
                    />
                </Row>
            )}
            <Row collapse="down">
                <Select
                    label="SKU"
                    menuPlacement="auto"
                    onChange={handleSkuChange}
                    options={skuOptions}
                    value={selectedFilters?.sku}
                />
            </Row>
            {isChildLocation && (
                <Row collapse="down">
                    <Select
                        label="Brand item"
                        menuPlacement="auto"
                        onChange={handleIsLinkedChange}
                        options={isLinkedOptions}
                        value={selectedFilters?.isLinked}
                    />
                </Row>
            )}
            <Row collapse={posSyncEnabled ? "down" : undefined}>
                {isParentLocation ? (
                    <Select
                        label="Missing content"
                        menuPlacement="auto"
                        onChange={handleMissingContentChange}
                        options={missingContentOptions}
                        value={selectedFilters?.missingContent}
                    />
                ) : (
                    <Select
                        isMulti
                        label="Status"
                        menuPlacement="auto"
                        onChange={handleStatusChange}
                        options={statusOptions}
                        value={selectedFilters?.status}
                    />
                )}
            </Row>
            {posSyncEnabled && (
                <Row>
                    <Select
                        blurInputOnSelect={false}
                        closeMenuOnSelect={false}
                        isMulti
                        label="Sync status"
                        menuPlacement="auto"
                        onChange={handleSyncStatusChange}
                        options={syncStatusOptions}
                        value={selectedFilters?.syncStatus}
                    />
                </Row>
            )}
        </Modal>
    );
};
