import styles from "./PageHeader.module.scss";
import { ReactElement, ReactNode } from "react";
import { Button } from "../button";
import { ArrowLeft } from "common/icons/ArrowLeft";
import { Link } from "react-router-dom";

interface Props {
    title: string;
    titleAfter?: ReactNode;
    actions?: ReactNode;
    className?: string;
    backHandler?: () => void;
    backLink?: string;
    subtitle?: string;
    titleAs?: React.ElementType;
}

export const PageHeader = ({
    actions = null,
    className,
    backHandler,
    backLink,
    subtitle,
    title,
    titleAfter,
    titleAs: TitleComponent,
}: Props): ReactElement => {
    if (!TitleComponent) {
        TitleComponent = "h1";
    }

    return (
        <header className={className}>
            {backHandler || backLink ? (
                <>
                    <div className={styles.topRow}>
                        {(backHandler || backLink) && (
                            <div className={styles.backButton}>
                                <Button
                                    as={backLink ? Link : "button"}
                                    onClick={backHandler}
                                    role="secondary"
                                    padding="icon"
                                    to={backLink}
                                >
                                    <ArrowLeft />
                                </Button>
                            </div>
                        )}
                        <div className={styles.actionsContainer}>{actions}</div>
                    </div>
                    <div className={styles.row}>
                        <TitleComponent className={styles.title}>{title}</TitleComponent>
                        {titleAfter && <div className={styles.titleAfter}>{titleAfter}</div>}
                    </div>
                </>
            ) : (
                <div className={styles.topRow}>
                    <TitleComponent className={styles.title}>{title}</TitleComponent>
                    {titleAfter && <div className={styles.titleAfter}>{titleAfter}</div>}
                    <div className={styles.actionsContainer}>{actions}</div>
                </div>
            )}
            {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
        </header>
    );
};
