import { opsApi } from "API";
import { GraphQLPromotion } from "../types";
import { promotionObject } from "./listPromotions";

export const editPromotion = async (locationId: string, promotionId: string): Promise<GraphQLPromotion> => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId, promotionId });
    return res.data.promotion;
};

type GraphQLPayload = { promotion: GraphQLPromotion };

const query = `
    query ($locationId: ID!, $promotionId: ID!) {
        promotion(locationId: $locationId, id: $promotionId) {
            ${promotionObject}
            restrictions {
                minSpend,
                maxMemberUsage,
                maxCodeUsage,
                requiredItems {
                    productIds,
                    variantIds
                }
                schedule {
                    startDate
                    endDate
                    activeDaysOfWeek
                    dailyStartTime
                    dailyEndTime
                    specificInactiveDates
                }
            },
        }
    }
`;
