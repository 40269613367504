import { createAction } from "../reducers/edit";
import { AppState, AppDispatch } from "features/state";
import { scaffoldSaveAction } from "common/scaffolding/actions/scaffoldSaveAction";
import { EditableNewLocation } from "../types/EditableNewLocation";
import { createLocation, LocationUpdateModel } from "../api/createLocation";
import { getActiveLocation } from "../selectors/getLocationPermissions";
import { localesSupportAbsorbMerchantFee } from "../selectors/getAbsorbMerchantFeeSupported";
import { LocationType } from "../types/LocationType";

export const create =
    (editableLocation: EditableNewLocation, callback?: (id: string) => void) =>
    (dispatch: AppDispatch, getState: () => AppState) => {
        const location = getActiveLocation(getState());

        return dispatch(
            scaffoldSaveAction(
                undefined,
                createAction,
                async (locationId: string | undefined) => {
                    const data = mapCreateModel(editableLocation);
                    const id = await createLocation(data, editableLocation.clone ? location!.id : null);

                    callback && callback(id);

                    return id;
                },
                undefined,
                false,
                callback
            )
        );
    };

const mapCreateModel = ({
    slug,
    displayName,
    timeZone,
    locale,
    groupId,
    newGroupName,
    createAsBrand,
    orderFlow,
}: EditableNewLocation): LocationUpdateModel => ({
    slug,
    displayName,
    timeZone,
    locale,
    groupId: groupId === "new" ? undefined : groupId,
    newGroupName: groupId === "new" ? newGroupName : undefined,
    isLiveOrdersEnabled: true,
    enableAbsorbMerchantFee: localesSupportAbsorbMerchantFee.includes(locale),
    locationType: createAsBrand ? LocationType.BRAND : null,
    orderFlow,
});
