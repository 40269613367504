import { ModifySelectionOptions } from "./types";
export interface ModifierSelectionSettings {
    selectOption: ModifySelectionOptions;
    minSelection: number | undefined;
    maxSelection: number | undefined;
    maxSelectionPerOption: number | undefined;
    required: boolean;
}

export const initialiseModifierRules = (settings: ModifierSelectionSettings) => {
    let { minSelection, maxSelection, required, maxSelectionPerOption } = settings;
    let selectOption = getSelectedOption(minSelection, maxSelection, required);

    // Migrations from pre-extended modifiers
    if (required) {
        if (!minSelection) {
            minSelection = 1;
            if (maxSelection === 1) selectOption = ModifySelectionOptions.Exactly;
            else if (maxSelection && maxSelection > 1) selectOption = ModifySelectionOptions.Between;
            else {
                selectOption = ModifySelectionOptions.AtLeast;
            }
        }
    }
    if (!maxSelection) maxSelection = undefined;
    if (!maxSelectionPerOption) maxSelectionPerOption = 1;

    return {
        ...settings,
        minSelection,
        maxSelection,
        selectOption,
        maxSelectionPerOption,
    };
};

const getSelectedOption = (minSelection: number | undefined, maxSelection: number | undefined, required: boolean) => {
    if (minSelection && maxSelection && maxSelection > minSelection) return ModifySelectionOptions.Between;
    if (minSelection && maxSelection && maxSelection === minSelection) return ModifySelectionOptions.Exactly;
    if (minSelection && !maxSelection) return ModifySelectionOptions.AtLeast;
    if (!minSelection && maxSelection && !required) return ModifySelectionOptions.UpTo;

    return ModifySelectionOptions.Unlimited;
};
