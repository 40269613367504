import styles from "./ExcludedDates.module.scss";

import { getShortDateFormatter } from "common/utility/dateUtils";
import { FieldArrayRenderProps, getIn } from "formik";
import moment from "moment";
import { useMemo } from "react";
import { Icon } from "core/components/icon/Icon";
import { CloseSmall } from "common/icons";
import { CommonEditProps } from "../EditPromotionPage";

interface Props extends CommonEditProps {
    locale: string;
    storageFormat?: string;
}

interface DateInfo {
    key: string;
    label: string;
}

export const ExcludedDates = ({
    disableFields,
    form,
    name,
    remove,
    locale,
    storageFormat = "YYYY-MM-DD",
}: Props & FieldArrayRenderProps) => {
    const formatDate = useMemo(() => getShortDateFormatter(locale).format, [locale]);
    const { values } = form || {};
    const fieldValue = getIn(values, name);

    const dates = useMemo<DateInfo[]>(
        () =>
            fieldValue?.map((value: string) => {
                const m = moment(value, storageFormat);

                return {
                    key: value,
                    label: formatDate(m.toDate()),
                };
            }),
        [fieldValue, formatDate, storageFormat]
    );

    if (!fieldValue?.length) {
        return <></>;
    }

    return (
        <div className={styles.container}>
            {dates.map(({ key, label }, index) => (
                <div className={styles.value} key={key}>
                    <span>{label}</span>
                    <button
                        disabled={disableFields}
                        className={styles.button}
                        type="button"
                        aria-label="Remove "
                        onClick={() => remove(index)}
                    >
                        <Icon size="large" verticalAlign="middle">
                            <CloseSmall />
                        </Icon>
                    </button>
                </div>
            ))}
        </div>
    );
};
