import { AppState } from "features";
import { createSelector } from "reselect";
import { getLocationsList } from "features/location/selectors/getLocationsList";
import { LocationSummary } from "features/location/types/LocationSummary";
import { sortByDisplayName } from "foundation/dataConventions/sortByDisplayName";

export const getSortedSelections = createSelector(
    getLocationsList,
    (_: AppState, selectedIds: string[]) => selectedIds, // component prop
    (locations, selectedIds): string[] => {
        return sortSelectedIdsByDisplayName(selectedIds, locations);
    }
);

export const sortSelectedIdsByDisplayName = (selectedIds: string[], locations: LocationSummary[]) => {
    return locations
        .filter((location) => selectedIds.includes(location.id))
        .sort(sortByDisplayName)
        .map(({ id }) => id);
};
