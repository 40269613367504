import { PermissionSet } from "./PermissionSet";

export interface CrudPermissions {
    canDelete: boolean;
    canRead: boolean;
    canUpdate: boolean;
    canCreate: boolean;
    canBulkDelete: boolean;
}

export function createCrudPermissions<T extends CrudPermissions>(
    subject: string,
    permissions: PermissionSet,
    additional?: (permissions: PermissionSet) => Partial<T>
): T {
    const crud = {
        canCreate: permissions.has(`${subject}:create`),
        canRead: permissions.has(`${subject}:read`),
        canUpdate: permissions.has(`${subject}:update`),
        canDelete: permissions.has(`${subject}:delete`),
        canBulkDelete: permissions.has(`${subject}:bulkdelete`),
    };

    if (additional) {
        return { ...crud, ...additional(permissions) } as any;
    }

    return crud as any;
}
