export interface PosSyncResult {
    catalogueRetrieved: boolean;
    catalogueSynced: boolean;
    responseMessage: string;
    syncStats?: SyncStats;
    results?: ItemSyncResult[];
}

interface ItemSyncResult {
    sku: string;
    outputObjectType?: "product" | "variant" | "modifier" | "option";
    outputType?: "product" | "variant" | "modifier" | "option";
    result: ImportEntityResult;
}

interface SyncStats {
    productStats: ItemSyncStats;
    variantStats: ItemSyncStats;
    modifierGroupStats: ItemSyncStats;
    modifierOptionStats: ItemSyncStats;
    syncDurationMilliseconds: number;
    completedTimeUtc: string;
}

interface ItemSyncStats {
    syncedCount: number;
    ignoredCount: number;
    missingFromPosCount: number;
}

enum ImportEntityResult {
    CREATED = "CREATED",
    UPDATED = "UPDATED",
    DELETED = "DELETED",
    IGNORED = "IGNORED",
    ABORTED = "ABORTED",
}
