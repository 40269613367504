import { createContext, useState } from "react";
import warnOnce from "warn-once";

// manages state that would otherwise be only available to React Day Picker component
export interface DayPickerContextProps {
    locale: string;
    month: Date;
    setMonth: (date: Date) => void;
    storageFormat: string;
}

export const DayPickerContext = createContext<DayPickerContextProps>({
    locale: "",
    month: new Date(),
    setMonth: () => {},
    storageFormat: "",
});

export interface Props {
    children: (value: DayPickerContextProps) => any;
    initialMonth?: Date | undefined;
    locale: string;
    storageFormat?: string;
}

export function DayPickerContainer({
    children,
    initialMonth = new Date(),
    locale,
    storageFormat = "YYYY-MM-DD",
}: Props) {
    const [month, setMonth] = useState<Date>(initialMonth);

    warnOnce(
        !locale,
        "DayPickerContainer has no locale. Fix this by passing a locale to DatePicker, DatePickerInput or DatePickerInputRange"
    );
    locale = locale || "en-AU";

    return (
        <DayPickerContext.Provider
            value={{
                locale,
                month,
                setMonth,
                storageFormat,
            }}
        >
            {children({ locale, month, setMonth, storageFormat })}
        </DayPickerContext.Provider>
    );
}
