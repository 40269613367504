import * as Yup from "yup";
import { AdjustmentType, EditablePriceList, EditablePriceListSettings } from "../types";
import { editablePriceLevelsSchema } from "./EditablePriceLevelsSchema";

export const editablePriceListSettingsSchema = Yup.object<EditablePriceListSettings>().shape({
    id: Yup.string(),
    displayName: Yup.string().required("Price List Name required"),
    posId: Yup.string().when("$validatePos", (validatePos: boolean, schema: Yup.StringSchema) => {
        if (validatePos) {
            return schema.required("Price List POS Id Required");
        } else {
            return schema.nullable(true);
        }
    }),
    globalAdjustment: Yup.number().when(
        "adjustmentType",
        (adjustmentType: AdjustmentType | null, schema: Yup.MixedSchema) => {
            if (adjustmentType === AdjustmentType.Percentage) {
                return schema
                    .typeError("Value required")
                    .required("Value required")
                    .test({
                        name: "globalAdjustment",
                        exclusive: true,
                        message: "Must be a value between -100 and -1 OR greater than 1",
                        test: (value) => (value >= -100 && value <= -1) || value >= 1,
                    });
            } else if (adjustmentType === AdjustmentType.FixedAmount) {
                return schema
                    .typeError("Value required")
                    .required("Value required")
                    .test({
                        name: "globalAdjustment",
                        exclusive: true,
                        message: "Must be a value greater than 1 or less than 0",
                        test: (value) => value >= 1 || value < 0,
                    });
            } else {
                return schema.nullable(true).transform((value) => (value && !Number.isNaN(value) ? value : undefined));
            }
        }
    ),
    adjustmentType: Yup.mixed().when("useGlobalAdjustment", (useGlobalAdjustment: boolean, schema: Yup.MixedSchema) =>
        useGlobalAdjustment
            ? schema.nullable(true).typeError("Value required").required("Value required")
            : schema.nullable(true)
    ),
    useGlobalAdjustment: Yup.boolean(),
});

export const editablePriceListSchema = Yup.object<EditablePriceList>().shape({
    settings: editablePriceListSettingsSchema,
    priceLevels: editablePriceLevelsSchema,
    activeTab: Yup.string(),
});
