import { opsApi } from "../../../API";

export const archiveTax = async (locationId: string, taxClassId: string) => {
    await opsApi.graphQLQuery(query, { locationId, taxClassId });
};

const query = `
    mutation($locationId: ID!, $taxClassId: ID!) {
        deleteTaxClass(locationId: $locationId, id: $taxClassId) {
            id
        }
    }
`;
