import { OpenInWindow } from "common/icons/OpenInWindow";
import { Button } from "core/components/button";
import { EmptyMessage } from "core/components/card/EmptyMessage";
import { Icon } from "core/components/icon/Icon";
import { Link, useLocation } from "react-router-dom";
import styles from "./EmptyMemberships.module.scss";

export const EmptyMemberships = () => {
    const location = useLocation();
    return (
        <div className={styles.container}>
            <EmptyMessage message="" />
            <h1 className={styles.heading}>Loyalty</h1>
            <p className={styles.body}>
                Create an opt-in loyalty program to run special offers for your customers via me&u.
            </p>
            <div className={styles.buttonsContainer}>
                <Button as="a" href="https://meandu.helpjuice.com/en_US/membership" target="_blank" role="secondary">
                    <Icon className={styles.icon} size="small">
                        <OpenInWindow />
                    </Icon>
                    Learn more
                </Button>
                <Button as={Link} to={`${location.pathname}/new`}>
                    Create loyalty program
                </Button>
            </div>
        </div>
    );
};
