import { ITelemetryItem } from "@microsoft/applicationinsights-core-js";
import { AppState } from "features";
import { region } from "features/region";

export type RouteParams = {
    location?: string;
    region?: string;
};

// Add region/location data to all App Insights tracking events
export const getLocationTelemetryInitializer = (getState: () => AppState) => (item: ITelemetryItem) => {
    const state = getState();

    const activeLocation = state.locations.active;

    let location = activeLocation?.status === "loaded" ? activeLocation.data : null;

    item.data!.region = region.getActiveRegion()?.id;

    item.data!.location = location?.slug;

    item.data!.location_id = location?.id;

    if (location?.parentLocationId) {
        item.data!.parent_location_id = location?.parentLocationId;
    }

    if (location?.childLocationIds?.length) {
        item.data!.child_location_ids = JSON.stringify(location?.childLocationIds);
    }

    return true;
};
