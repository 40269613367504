import previewControlsStyles from "./PreviewControlsFooter.module.scss";

import * as React from "react";

import { RouteComponentProps } from "react-router";
import { ServiceSummary } from "features/service";

import { DayOfWeekList } from "./DayOfWeekList";
import { DataListingPage, ItemsGroup } from "common/scaffolding/components/DataListingPage";

import "./ListServicesPage.scss";
import { CrudPermissions } from "features/location/types/createCrudPermissions";
import { SectionSummary } from "../../../section/types";
import { DollarCircleFilled } from "@ant-design/icons";
import { Alert, DatePicker } from "antd";
import moment from "moment-timezone";
import { Moment } from "moment";
import classNames from "classnames";
import { LocationLocale } from "features/location/types/LocationLocale";
import { ArrowIcon, CalendarIcon, SectionIcon } from "common/legacyIcons";
import { Indicator } from "components/forms/Indicator/Indicator";
import { ResolvedDisplayName } from "components/ResolvedDisplayName";
import { PreviewControlsFooter } from "./PreviewControlsFooter";

export interface Props {
    locationLocale: LocationLocale;
    permissions: CrudPermissions;
    liveServices: ServiceSummary[];
    otherServices: ServiceSummary[];
    inactiveSections: SectionSummary[];
    venueTime: string;
    currentDayOfWeek: string;
    setPreviewDate: (previewDate: moment.Moment) => void;
    resetPreviewDate: () => void;
    previewVenueTime?: string;
    sections?: SectionSummary[];
    surchargesEnabled: boolean;

    // TODO remove legacy Services support https://meanduapp.atlassian.net/browse/ROCK-745
    useLegacyLayout?: boolean;
}

export interface State {
    previewDate?: moment.Moment;
    propsLiveServices: ServiceSummary[];
    propsOtherServices: ServiceSummary[];
    filterLiveServices: ServiceSummary[];
    filterOtherServices: ServiceSummary[];
    selectedSection: string;
}

export class ListServicesPage extends React.Component<Props & RouteComponentProps<any>, State> {
    constructor(props: Props & RouteComponentProps<any>) {
        super(props);

        this.state = {
            propsLiveServices: props.liveServices,
            propsOtherServices: props.otherServices,
            filterLiveServices: props.liveServices,
            filterOtherServices: props.otherServices,
            selectedSection: "",
        };
    }

    static getDerivedStateFromProps(props: Props, state: State): Partial<State> | null {
        if (props.liveServices === state.propsLiveServices && props.otherServices === state.propsOtherServices) {
            return null;
        }

        return getFilterState(props, state.selectedSection);
    }

    render() {
        const {
            match,
            permissions,
            inactiveSections,
            venueTime,
            currentDayOfWeek,
            previewVenueTime,
            sections,
            liveServices,
            useLegacyLayout = false,
        } = this.props;

        const itemsGroups: ItemsGroup<ServiceSummary>[] = [
            {
                title: previewVenueTime ? (
                    "Live at this time"
                ) : (
                    <>
                        Live <span className="service-list__venue-time">(as of {venueTime})</span>
                    </>
                ),
                className: classNames("live-services-list", { "is-preview": previewVenueTime }),
                items: this.state.filterLiveServices,
                renderHeader: (service) => this.renderServiceHeader(service, true, !!previewVenueTime),
                renderFooter: this.renderServiceFooter,
                children: (
                    <>
                        {!liveServices.length && (!this.state.selectedSection || this.state.selectedSection === "0") ? (
                            <Alert
                                className="no-active-service-section"
                                key="venue-offline"
                                message={
                                    <>
                                        <strong>Venue offline:</strong> There are no active services right now.
                                    </>
                                }
                                type="warning"
                                showIcon
                            />
                        ) : (
                            inactiveSections
                                .filter(
                                    (s) =>
                                        s.id === this.state.selectedSection ||
                                        !this.state.selectedSection ||
                                        this.state.selectedSection === "0"
                                )
                                .map((s) => (
                                    <Alert
                                        className="no-active-service-section"
                                        key={s.id}
                                        message={
                                            <>
                                                <strong>Section: {s.displayName}</strong> does not have an active
                                                service.
                                            </>
                                        }
                                        type="warning"
                                        showIcon
                                    />
                                ))
                        )}
                    </>
                ),
            },
            {
                title: "All other services",
                items: this.state.filterOtherServices,
                renderHeader: (service) => this.renderServiceHeader(service),
                renderFooter: this.renderServiceFooter,
            },
        ];

        // TODO remove legacy Services support https://meanduapp.atlassian.net/browse/ROCK-745
        const listClasses = classNames({
            "list-services-page__list": true,
            "list-services-page__list--legacy": useLegacyLayout,
            "list-services-page__list--new": !useLegacyLayout,
        });

        return (
            <div className={"list-services-page"}>
                <div className={listClasses}>
                    <DataListingPage
                        title={useLegacyLayout ? "Services" : ""}
                        description={useLegacyLayout ? "Services let you control when your menus are visible." : ""}
                        newItemLabel="Add new service..."
                        newItemRoute={`${match.url}/new`}
                        editItemRoute={`${match.url}/:id`}
                        renderItemInfo={(service) => this.renderItemInfo(service, currentDayOfWeek)}
                        disableAdd={!permissions.canCreate || !useLegacyLayout}
                        itemsGroups={itemsGroups}
                        showFilter={!!sections && !!sections.length}
                        filterOptions={sections}
                        onFilter={this.handleFilter}
                        selectedFilter={this.state.selectedSection}
                        emptyText="No services to display"
                    >
                        {previewVenueTime && (
                            <Alert
                                className="viewing-as"
                                message={`Viewing your services as ${previewVenueTime}.`}
                                type="warning"
                                showIcon
                            />
                        )}
                    </DataListingPage>
                </div>
                {
                    // TODO remove legacy Services support https://meanduapp.atlassian.net/browse/ROCK-745
                    useLegacyLayout ? (
                        <div className="list-services-page__footer">
                            <div className="list-services-page__footer-left">
                                Check your future services when you preview
                            </div>
                            <DatePicker
                                placeholder=""
                                showTime
                                suffixIcon={<CalendarIcon />}
                                allowClear={true}
                                onChange={this.onChange}
                                onOk={this.onOk}
                            />
                        </div>
                    ) : (
                        <PreviewControlsFooter>
                            <span className={previewControlsStyles.message}>
                                Check your future services when you preview
                            </span>
                            <DatePicker
                                placeholder=""
                                showTime
                                suffixIcon={<CalendarIcon />}
                                allowClear={true}
                                onChange={this.onChange}
                                onOk={this.onOk}
                                placement="topRight"
                                popupClassName={previewControlsStyles.popup}
                            />
                        </PreviewControlsFooter>
                    )
                }
            </div>
        );
    }

    renderItemInfo = ({ daysOfWeek, available }: ServiceSummary, currentDayOfWeek: string) => (
        <div className="weekdays-container">
            <DayOfWeekList daysOfWeek={daysOfWeek} currentDayOfWeek={currentDayOfWeek} disabled={!available} />
        </div>
    );

    renderServiceHeader = (
        { startTime, endTime, dates }: ServiceSummary,
        live: boolean = false,
        isPreview: boolean = false
    ) => (
        <div className="service-list-item__header">
            {live && (
                <Indicator className="service-list-item__live" label={`LIVE${isPreview ? " AT THIS TIME" : ""}`} />
            )}
            <div className="service-list-item__active-time">
                {Math.floor(startTime / 100)}:{("00" + (startTime % 100)).slice(-2)}
                <ArrowIcon />
                {Math.floor(endTime / 100)}:{("00" + (endTime % 100)).slice(-2)}
            </div>
            {dates &&
                dates.map((date) => (
                    <div key={`${date.startDate}-${date.endDate}`} className="service-list-item__active-date">
                        {this.formatDate(date.startDate)}
                        <ArrowIcon />
                        {this.formatDate(date.endDate)}
                    </div>
                ))}
        </div>
    );

    formatDate = (input: string) => this.props.locationLocale.formatDate(new Date(input));

    renderServiceFooter = ({ sections, surcharge, priceLists }: ServiceSummary) => (
        <div className="service-list-item__footer">
            <div className="service-list-item__sections">
                {sections.map((section) => (
                    <div key={section.id} className="service-list-item__section">
                        <SectionIcon />
                        <ResolvedDisplayName className="service-list-item__section-label" item={section} />
                    </div>
                ))}
            </div>
            {!!surcharge && !this.props.surchargesEnabled && (
                <div className="service-list-item__surcharge">{Number(surcharge * 100)}% SURCHARGE</div>
            )}
            {!!priceLists && (
                <>
                    <div className="break"></div>
                    <div className="service-list-item__priceLists">
                        {priceLists.map((pl) => (
                            <div key={pl.id} className="service-list-item__priceList">
                                <DollarCircleFilled />
                                {pl.displayName}
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );

    onChange = (date: Moment | null) => {
        const { resetPreviewDate } = this.props;

        if (!date) {
            resetPreviewDate();
        } else {
            this.setState({ previewDate: moment(date.format()) });
        }
    };

    onOk = () => {
        const { setPreviewDate } = this.props;
        const { previewDate } = this.state;

        if (previewDate) {
            setPreviewDate(previewDate);
        }
    };

    handleFilter = (id: string) => {
        this.setState(getFilterState(this.props, id));
    };

    componentDidUpdate(prevProps: Props) {
        if (prevProps.previewVenueTime !== this.props.previewVenueTime) {
            this.handleFilter(this.state.selectedSection);
        }
    }
}

function getFilterState(props: Props, sectionId: string) {
    if (!!sectionId && sectionId !== "0") {
        const filterLiveServices = props.liveServices.filter((x) => x.sections.find((s) => s.id === sectionId));
        const filterOtherServices = props.otherServices.filter((x) => x.sections.find((s) => s.id === sectionId));
        return { filterLiveServices, filterOtherServices, selectedSection: sectionId };
    }

    return {
        filterLiveServices: props.liveServices,
        filterOtherServices: props.otherServices,
        selectedSection: sectionId,
    };
}
