import { opsApi } from "API";
import { PosConfigurationSetting } from "../types/PosConfigurationSetting";

export const getPosConfiguration = async (slug: string): Promise<PosConfigurationSetting[]> => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId: slug });
    return res.data.posConfiguration;
};

type GraphQLPayload = { posConfiguration: PosConfigurationSetting[] };

export const posConfigurationFields = `
    key,
    value
`;

const query = `
    query ($locationId: ID!) {
        posConfiguration(locationId: $locationId) {
            ${posConfigurationFields}
        }
    }
`;
