import * as React from "react";
import { FieldProps } from "formik";

import moment, { Moment } from "moment";

import "./TimePicker.scss";
import { TimePicker } from "antd";

export interface TimePickerProps {
    disabled?: boolean;
}

export interface TimePickerState {
    momentValue: Moment | undefined | null;
}

// Deprecated
// use core/components/form/timePicker
export class MeuTimePicker extends React.Component<TimePickerProps & FieldProps, TimePickerState> {
    constructor(props: TimePickerProps & FieldProps) {
        super(props);

        const {
            field: { value },
        } = props;

        this.state = {
            momentValue: typeof value === "number" ? moment(String(value).padStart(3, "0"), "Hmm") : undefined,
        };
    }

    render() {
        const { disabled } = this.props;
        return (
            <TimePicker
                use12Hours
                format="h:mm a"
                minuteStep={15}
                allowClear={false}
                value={this.state.momentValue}
                onChange={this.handleChange}
                onSelect={this.handleChange}
                disabled={disabled}
                showNow={false}
            />
        );
    }

    handleChange = (time: Moment | null) => {
        const {
            form,
            field: { name },
        } = this.props;

        form.setFieldValue(name, time ? parseInt(time.format("Hmm"), 10) : null);

        this.setState({
            momentValue: time,
        });
    };
}
