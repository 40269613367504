import { create, newEditableLocation } from "../actions";
import { EditableNewLocation } from "../types/EditableNewLocation";
import { editableNewLocationSchema } from "../schema/EditableNewLocationSchema";
import { Form, Formik, validateYupSchema, yupToFormErrors } from "formik";
import { generatePath, useRouteMatch } from "react-router";
import { PropsWithChildren, useCallback } from "react";
import { useDispatch } from "react-redux";

const validate = (values: EditableNewLocation) => {
    return validateYupSchema(values, editableNewLocationSchema).then(
        () => ({}),
        (err: any) => yupToFormErrors(err)
    );
};

export const EditNewLocationForm = ({ children }: PropsWithChildren<{}>) => {
    const dispatch = useDispatch();

    const { path, params } = useRouteMatch()!;

    const handleSubmit = useCallback(
        (data: EditableNewLocation) => {
            dispatch(
                create(
                    {
                        ...data,
                        groupId: data.newGroupName ? "new" : data.groupId || undefined,
                    },
                    (location) => {
                        window.location.href = generatePath(path, {
                            ...params,
                            location,
                        });
                    }
                )
            );
        },
        [dispatch, params, path]
    );

    return (
        <Formik validate={validate} initialValues={newEditableLocation()} onSubmit={handleSubmit}>
            <Form>{children}</Form>
        </Formik>
    );
};
