import { connect } from "react-redux";
import { Props, DashboardPage } from "../components/DashboardPage";
import { PageLoadingProps } from "common/loader/withLoader";
import { withLocation, LocationComponentProps } from "../../location";
import { AppState } from "../..";
import { publish } from "../../location/actions/publish";
import { RouteComponentProps } from "react-router";
import { FunctionProps, DataProps } from "types/utils";
import { IsPosMenuSupported } from "features/location/selectors/getPosMenuSupported";

const mapStateToProps = (
    {
        locations: {
            publish: { publishing },
        },
    }: AppState,
    { match: { params }, history, restaurantLocation }: LocationComponentProps & RouteComponentProps<any>
): DataProps<Omit<Props, keyof RouteComponentProps<any>>> => {
    let redirect: string = IsPosMenuSupported(restaurantLocation.posMetadata.operations)
        ? `/${params.region}/${params.location}/sections`
        : `/${params.region}/${params.location}/menu/catalogue`;

    return {
        redirect,
        restaurantLocation,
        publishing,
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { restaurantLocation: { slug } }: LocationComponentProps
): FunctionProps<PageLoadingProps<Props>> => ({
    onPublish: () => dispatch(publish(slug)),
});

export const DashboardPageContainer = withLocation(
    connect(mapStateToProps, mapDispatchToProps)(DashboardPage as any) as any
);
