import { opsApi } from "API";
import { GraphQLItem } from "../../../common/scaffolding/types";

export async function listSections(location: string): Promise<GraphQlSectionSummary[]> {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { location });
    return res.data.sections;
}

type GraphQLPayload = { sections: GraphQlSectionSummary[] };

export interface GraphQlSectionSummary extends GraphQLItem {}

const query = `
    query($location: ID!) { 
        sections(locationId: $location) { 
            id, 
            displayName 
        }
    }
`;
