import styles from "./Nav.module.scss";

import { useAccordion, useLocationBasePath, useMedia } from "common/hooks";
import { Fragment, PropsWithChildren, ReactElement, useCallback, useContext, useEffect, useRef } from "react";
import { MenuContext } from "./MenuContainer";
import { NavLink } from "react-router-dom";
import { UserProfileLink } from "./UserProfileLink";
import { Permissions } from "features/location/components/Permissions";
import { PermissionSet } from "features/location/types/PermissionSet";
import {
    AbstractNavItem,
    isNavGroup,
    isNavItem,
    isNavItemGroup,
    NavGroup,
    NavItem,
    NavItemGroup,
    NavNode,
    NavNodeTextOptions,
} from "features/structure/types/NavMenu";
import { Icon } from "core/components/icon/Icon";
import { useLocation, useParams } from "react-router";
import { LocationRouteParams } from "common/types";
import { Chevron } from "core/components/icon/Chevron";
import classNames from "classnames";
import { OpenInWindow } from "common/icons/OpenInWindow";
import { useSelector } from "react-redux";
import { getIsParentLocation } from "features/location/selectors/getIsParentLocation";

export interface Props {
    navigation: NavNode[];
    posMenuSupported?: boolean;
}

export const Nav = ({ navigation }: Props): ReactElement => {
    const { setMenuActive } = useContext(MenuContext);
    const large = useMedia("(min-width: 896px)");

    useEffect(() => {
        if (large) {
            setMenuActive(false);
        }
    }, [large, setMenuActive]);

    return (
        <div className={styles.container}>
            <nav className={styles.nav}>
                <Permissions>
                    {(permissions: PermissionSet) => (
                        <div className={styles.innerContainer}>
                            <NavMenu navigation={navigation} />
                            <UserProfileLink />
                        </div>
                    )}
                </Permissions>
            </nav>
        </div>
    );
};

interface NavMenuProps {
    navigation: NavNode[];
}

export const NavMenu = ({ navigation }: NavMenuProps): ReactElement => {
    const menuRef = useRef<HTMLUListElement | null>(null);
    const { pathname } = useLocation();
    const params = useParams<LocationRouteParams>();
    const { region, location } = params || {};
    const isParentLocation = useSelector(getIsParentLocation) || false;

    const isActiveItem = useCallback(
        (item: NavItem) => (item?.route && pathname.startsWith(`/${region}/${location}/${item.route}`)) || false,
        [pathname, region, location]
    );

    const prominentItems: any[] = navigation.filter((node) => (node?.category === "prominent" ? node : null));

    const getSelectedNavItemGroupIndex = useCallback(
        (navGroup?: NavGroup) => {
            const groupIndex = navGroup
                ? navGroup.navItemGroups.findIndex((navItemGroup) => {
                      return !!navItemGroup.children.find(isActiveItem);
                  })
                : -1;

            return groupIndex;
        },
        [isActiveItem]
    );

    return (
        <>
            {!!prominentItems.length && (
                <div className={styles.prominentContainer}>
                    {prominentItems.map((item, index) => (
                        <ProminentItem key={item.route || item.externalUrl} item={item} />
                    ))}
                </div>
            )}

            <ul className={styles.menu} ref={menuRef}>
                {navigation.map((node, index) => {
                    if (node?.category !== "prominent") {
                        if (isNavGroup(node)) {
                            return (
                                <Fragment key={index}>
                                    <li className={styles.itemLevel0}>
                                        <span>{getText(node, { isParentLocation })}</span>
                                    </li>
                                    {node.navItemGroups.map((navItemGroup, navItemGroupIndex) => {
                                        return (
                                            <NavElement
                                                node={navItemGroup}
                                                key={navItemGroupIndex}
                                                index={navItemGroupIndex}
                                                selectedGroupIndex={getSelectedNavItemGroupIndex(node)}
                                            />
                                        );
                                    })}
                                </Fragment>
                            );
                        } else {
                            return <NavElement node={node} key={index} index={index} selectedGroupIndex={-1} />;
                        }
                    }
                    return null;
                })}
            </ul>
        </>
    );
};

interface NavElementProps {
    node: NavItem | NavItemGroup | null;
    index: number;
    selectedGroupIndex: number;
}

export const NavElement = ({ node, index, selectedGroupIndex }: NavElementProps) => {
    if (isNavItemGroup(node)) {
        return <SubMenu navItemGroup={node} key={index} startExpanded={index === selectedGroupIndex} />;
    } else if (isNavItem(node)) {
        return <Item key={node.route || node.externalUrl} item={node} level={1} expanded={true} />;
    }

    return null;
};

interface SubMenuProps {
    navItemGroup: NavItemGroup;
    startExpanded: boolean;
}

const SubMenu = ({ navItemGroup, startExpanded }: PropsWithChildren<SubMenuProps>): ReactElement => {
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const contentRef = useRef<HTMLUListElement | null>(null);
    const { icon: Svg } = navItemGroup;
    const { expanded, setExpanded, contentStyle } = useAccordion(contentRef, { startExpanded });
    const isParentLocation = useSelector(getIsParentLocation) || false;

    const onClick = () => {
        setExpanded(!expanded);
    };

    const basePath = useLocationBasePath();
    const { pathname } = useLocation();

    useEffect(() => {
        const match = navItemGroup.children.some(
            (child) => child.route && pathname.startsWith(`${basePath}/${child.route}`)
        );
        if (match) {
            setExpanded(true);
        }
    }, [basePath, navItemGroup.children, pathname, setExpanded]);

    return (
        <li className={styles.submenu}>
            <button
                className={styles.itemLevel1}
                ref={buttonRef}
                onClick={onClick}
                aria-expanded={expanded}
                aria-haspopup={true}
            >
                <span className={styles.labelLevel1}>
                    {Svg && (
                        <Icon className={styles.iconLevel1} size="small">
                            <Svg />
                        </Icon>
                    )}
                    <span className={styles.title}>{getText(navItemGroup, { isParentLocation })}</span>
                    <Icon className={expanded ? styles.submenuArrowExpanded : styles.submenuArrowCollapsed}>
                        <Chevron />
                    </Icon>
                </span>
            </button>

            <ul className={styles.submenuContent} ref={contentRef} style={contentStyle} aria-hidden={!expanded}>
                {navItemGroup.children.map((item) => (
                    <Item key={item.route || item.externalUrl} item={item} level={2} expanded={expanded} />
                ))}
            </ul>
        </li>
    );
};

interface ItemProps {
    item: NavItem;
    level?: number;
    expanded?: boolean;
}

const Item = ({ item, level, expanded }: ItemProps): ReactElement => {
    const { route, externalUrl } = item;
    const { region, location } = useParams<LocationRouteParams>() || {};

    const accessProps = expanded
        ? {}
        : {
              tabIndex: -1,
          };

    return (
        <li role="menuitem" className={styles.listItem}>
            {route && (
                <NavLink
                    className={styles[`itemLevel${level}`]}
                    activeClassName={styles.linkActive}
                    to={`/${region}/${location}/${route}`}
                    {...accessProps}
                >
                    <ItemLabel item={item} level={level} />
                </NavLink>
            )}
            {externalUrl && (
                <a
                    key={externalUrl}
                    className={styles[`itemLevel${level}`]}
                    href={externalUrl}
                    target="_blank"
                    {...accessProps}
                >
                    <ItemLabel item={item} level={level} />
                </a>
            )}
        </li>
    );
};

const ItemLabel = ({ item, level }: ItemProps): ReactElement => {
    const { legacy, externalUrl, icon: Svg, iconProperties } = item;
    const isParentLocation = useSelector(getIsParentLocation) || false;

    return (
        <span className={classNames(styles[`labelLevel${level}`], legacy && styles.legacyItemLabel)}>
            {Svg && (
                <Icon {...iconProperties} size="small" className={styles[`iconLevel${level}`]}>
                    <Svg />
                </Icon>
            )}
            <span className={styles.title}>{getText(item, { isParentLocation })}</span>
            {externalUrl && (
                <Icon className={styles.externalLinkIcon}>
                    <OpenInWindow />
                </Icon>
            )}
        </span>
    );
};

const ProminentItem = ({ item }: ItemProps): ReactElement => {
    const { externalUrl, icon: Svg, iconProperties } = item;
    const isParentLocation = useSelector(getIsParentLocation) || false;
    return (
        <a key={externalUrl} className={styles.prominentLink} href={externalUrl} target="_blank">
            <span className={styles.label}>
                <span>{getText(item, { isParentLocation })}</span>
                {Svg && (
                    <Icon {...iconProperties} className={styles.externalLinkIcon}>
                        <Svg />
                    </Icon>
                )}
            </span>
        </a>
    );
};

const getText = (node: AbstractNavItem, { isParentLocation }: NavNodeTextOptions) => {
    if (!node?.text) {
        return "";
    }

    return typeof node.text === "function" ? node.text({ isParentLocation }) : node.text;
};
