import { createSelector } from "reselect";
import { AppState } from "features/state";
import { StatusDictionary } from "../types/StatusDictionary";
import { CatalogueItem } from "../types";
import { ProductSummary } from "features/catalogue/types";
import { getIsChildLocation } from "features/location/selectors/getIsChildLocation";

const getProductsList = (state: AppState) => state.menuItems.list;

export const getProductCatalogueItems = createSelector(
    getProductsList,
    getIsChildLocation,
    (products, isChildLocation): CatalogueItem[] => {
        if (products.status !== "loaded") {
            return [];
        }

        return products.data.map((product) => {
            return {
                children: mapProductChildren(product, !!isChildLocation),
                id: product.id,
                type: "product",
                displayName: product.displayName,
                internalName: product.internalName,
                description: product.description,
                sku: product.sku,
                status: product.status,
                statusName: StatusDictionary[product.status] || StatusDictionary[0],
                price: product.price,
                categories: product.categories,
                validationStatus: product.validationStatus,
                validationStatusReason: product.validationStatusReason,
                image: product.thumbnailImage,
                key: product.id,
                energyContent: product.energyContent,
                templateType: product.type,
                isLinked: product.isLinked && !!isChildLocation,
                templateShortName: product.templateShortName
            };
        });
    }
);

const mapProductChildren = (product: ProductSummary, isChildLocation: boolean): CatalogueItem[] => {
    return product.variants
        ? product.variants.map((v) => {
              return {
                  id: v.id,
                  type: "variant",
                  displayName: v.displayName,
                  price: v.price || undefined,
                  sku: v.sku,
                  parentId: product.id,
                  validationStatus: v.validationStatus,
                  validationStatusReason: v.validationStatusReason,
                  key: `${product.id}-${v.id}`,
                  energyContent: v.energyContent,
                  isLinked: v.isLinked && isChildLocation,
              };
          })
        : [];
};
