import * as React from "react";
import { FieldProps } from "formik";

import "./TextArea.scss";
import { Input } from "antd";

export interface TextAreaProps {
    placeholder?: string;
    className?: string;
    rows?: number;
    disabled: boolean;
    trim?: boolean;
    maxLength?: number;
}

export class TextArea extends React.Component<FieldProps & TextAreaProps> {
    handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
        const {
            form,
            field: { name, onBlur },
            trim,
        } = this.props;

        const value = trim === false ? e.target.value : e.target.value.trim();

        form.setFieldValue(name, value);
        form.setFieldTouched(name, true);

        onBlur(e);
    };

    render() {
        const {
            placeholder,
            className,
            rows,
            field: { name, value, onChange },
            disabled,
            maxLength,
        } = this.props;

        return (
            <Input.TextArea
                rows={rows || 4}
                className={className}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={this.handleBlur}
                placeholder={placeholder}
                disabled={disabled}
                maxLength={maxLength}
            />
        );
    }
}
