import { CancelBig } from "common/icons/CancelBig";
import { Button, Props as ButtonProps } from "core/components/button";
import { Icon } from "core/components/icon/Icon";
import { ReactElement } from "react";

export type Props = Pick<ButtonProps, "className" | "disabled" | "onClick">;

export const RemoveButton = (props: Props): ReactElement => {
    return (
        <Button role="secondary" padding="icon" type="button" {...props}>
            <Icon size="tiny">
                <CancelBig />
            </Icon>
        </Button>
    );
};
