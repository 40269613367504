import * as React from "react";
import { LoadStatus, SaveStatus } from "common/loader/types";
import { Trigger } from "common/loader/Trigger";
import { Spin } from "antd";
import { Layout } from "features/structure/screen/Layout";
import "./PageLoading.scss";
import { useEffect } from "react";

export interface PageLoadingDataProps {
    loadStatus: LoadStatus;
    saveStatus?: SaveStatus;
}

export type PageLoadingProps<T> =
    | ({ loadStatus: "loaded"; saveStatus?: SaveStatus } & PageLoadingActionProps & T)
    | ({ loadStatus: LoadStatus; saveStatus?: SaveStatus } & PageLoadingActionProps & Partial<T>);

export interface PageLoadingActionProps {
    fetch?: () => void;
}

export const withLoader =
    <P extends {}>(Component: React.ComponentType<P>, isFullPage?: boolean): React.SFC<PageLoadingProps<P>> =>
    ({ loadStatus, saveStatus, fetch, ...props }: PageLoadingProps<P>) => {
        // FIXME isFullPage seemingly never passed
        const pageProps = isFullPage ? { empty: true } : undefined;
        const PageComp = isFullPage ? Layout : React.Fragment;

        useEffect(() => {
            if (loadStatus === "unloaded") {
                fetch!();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [loadStatus]);

        return (
            <Trigger action={fetch!}>
                {loadStatus === "loaded" && <Component {...(props as any)} saveStatus={saveStatus} />}
                {loadStatus === "failed" && (
                    <PageComp {...pageProps}>
                        <div onClick={fetch}>Failed</div>
                    </PageComp>
                )}
                {loadStatus === "loading" && (
                    <PageComp {...pageProps}>
                        <div className="loader__container loader__container--overlay">
                            <Spin />
                        </div>
                    </PageComp>
                )}
            </Trigger>
        );
    };
