import styles from "./PosSyncPageHeader.module.scss";

import { FeatureBadge } from "core/components/featureBadge";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { HeaderActions } from "./HeaderActions";
import { PageHeader } from "core/components/pageHeader";
import { PosSyncResult } from "../types";
import { useSelector } from "react-redux";
import timeago from "timeago.js";
import { getCatalogueEmpty } from "../selectors/getCatalogueEmpty";
import { getCataloguePageTitle } from "../selectors/getCatalogueTitle";

interface Props {
    posSyncResult?: PosSyncResult;
    lastCatalogueSyncTime?: string;
    emptyCollection: boolean;
    title: string;
}

const PosSyncPageHeaderContainer = () => {
    const restaurantLocation = useSelector(getActiveLocation);
    const emptyCollection = useSelector(getCatalogueEmpty);
    const title = useSelector(getCataloguePageTitle);

    return (
        <PosSyncPageHeaderComponent
            lastCatalogueSyncTime={restaurantLocation?.lastCatalogueSyncTime}
            emptyCollection={emptyCollection}
            title={title}
        />
    );
};

// @private
export const PosSyncPageHeaderComponent = ({ lastCatalogueSyncTime, emptyCollection, title }: Props) => {
    return (
        <PageHeader
            title={title}
            titleAfter={
                <div className="cx-3">
                    <FeatureBadge role="text">
                        Sync
                        <span className={styles.badgeAdditional}> enabled</span>
                    </FeatureBadge>
                </div>
            }
            actions={<HeaderActions />}
            subtitle={`Last sync from POS: ${getLastSyncStatus(emptyCollection, lastCatalogueSyncTime)}`}
        />
    );
};

function getLastSyncStatus(emptyCollection: boolean, lastCatalogueSyncTime: string | undefined) {
    if (emptyCollection) {
        return "Never";
    }
    return lastCatalogueSyncTime ? timeago().format(lastCatalogueSyncTime) : "No record of last sync";
}

export const PosSyncPageHeader = PosSyncPageHeaderContainer;
