import { opsApi } from "API";
import { GraphQLItemId } from "../../../common/scaffolding/types";
import { PriceLevelInputModel } from "./savePriceLevels";

export const savePriceListItems = async (locationId: string, priceListId: string, items: PriceListItemsInputModel) => {
    const res = await (
        await opsApi.graphQLQuery<{ updatePriceList: GraphQLItemId }>(updateQuery, { locationId, priceListId, items })
    ).data.updatePriceListItems;
    return res;
};

export interface PriceListItemsInputModel {
    products?: PriceLevelInputModel[];
    modifiers?: PriceLevelInputModel[];
}

const updateQuery = `
    mutation($locationId: ID!, $priceListId: ID!, $items: PriceListItemsUpdateInput!) {
        updatePriceListItems(locationId: $locationId, id: $priceListId, priceListItems: $items) {
            id
        }
    }
`;
