import styles from "./Tabs.module.scss";

import { Children, cloneElement, isValidElement, PropsWithChildren, useState } from "react";
import { TabPanelProps } from "./TabPanel";
import { Tab } from "./Tab";

interface Props {
    onChange?: (index: number) => void;
    initialIndex: number;
    className?: string;
}

export const Tabs = ({ children, className, initialIndex = 0, onChange }: PropsWithChildren<Props>) => {
    const [activeIndex, setActiveIndex] = useState(initialIndex);

    if (!children) {
        return null;
    }

    return (
        <div className={className}>
            <div role="tablist" className={styles.controls}>
                {Children.map(children, (child, index) => {
                    if (!isValidElement<TabPanelProps>(child)) {
                        return null;
                    }

                    return (
                        <Tab
                            key={child.props.id}
                            id={child.props.id}
                            active={index === activeIndex}
                            onClick={() =>
                                setActiveIndex((prevIndex) => {
                                    if (prevIndex !== index) {
                                        onChange?.(index);
                                    }
                                    return index;
                                })
                            }
                        >
                            {child.props.label}
                        </Tab>
                    );
                })}
            </div>

            {Children.map(children, (child, index) => {
                if (!isValidElement<TabPanelProps>(child)) {
                    return child;
                }
                return cloneElement(child, { ...child.props, active: index === activeIndex }, child.props.children);
            })}
        </div>
    );
};
