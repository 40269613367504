import { Card } from "core/components/card";
import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { CrudActionFooter } from "core/components/actionFooter";
import { EditNewLocationFields } from "./EditNewLocationFields";
import { EditNewLocationForm } from "./EditNewLocationForm";
import { EditWrapper } from "common/scaffolding/components/EditWrapper";
import { PageHeader } from "core/components/pageHeader";
import { PageContainer } from "core/components/pageContainer/PageContainer";
import { CardsContainer } from "core/components/card/CardsContainer";

export const EditNewLocationPage = () => {
    return (
        <EditWrapper contentWidth={ContentWidth.NARROW}>
            <EditNewLocationForm>
                <PageContainer>
                    <PageHeader title="New Location" subtitle="Enter some basic details to create a new venue" />
                    <CardsContainer>
                        <Card>
                            <EditNewLocationFields />
                        </Card>
                    </CardsContainer>
                </PageContainer>
                <CrudActionFooter
                    saving={false}
                    submitMode={"save"}
                    showDuplicate={false}
                    showDelete={false}
                    showSave={true}
                />
            </EditNewLocationForm>
        </EditWrapper>
    );
};
