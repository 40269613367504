import { AppState, AppDispatch } from "features/state";
import { getIsParentLocation } from "features/location/selectors/getIsParentLocation";
import { fetchActive, list } from "features/location/actions";

export const reload = () => async (dispatch: AppDispatch, getState: () => AppState) => {
    const state = getState();

    const {
        locations: { active },
    } = state;

    const isParent = getIsParentLocation(state);

    if (active.status !== "loaded" || !isParent || active.data.childLocationIds?.length === 0) {
        // Don't fetch if we don't have an active location yet, or if we don't have children
        return;
    }

    dispatch(fetchActive(active.data.slug, true));

    dispatch(list(true));
};
