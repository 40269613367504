import * as React from "react";
import { FieldProps } from "formik";

import "./InputNumberAddon.scss";
import { Input } from "antd";

export interface Props {
    addonBefore?: string;
    addonAfter?: string;
    displayName?: string;
    placeholder?: string;
    maxLength?: number;
    className?: string;
    decimalGroup?: number;
    error?: string | undefined;
    defaultValue?: number;
    onChange: (value?: number) => void;
    disabled: boolean;
    autoFocus?: boolean;
    allowNegative?: boolean;
    allowUndefined?: boolean;
    allowClear?: boolean;
    submitOnEnter?: boolean;
}

export interface State {
    value: string;
    defaultValue?: number;
}

type InputNumberAddonProps = Props & Partial<FieldProps>;

export class InputNumberAddon extends React.Component<InputNumberAddonProps, State> {
    private reg = new RegExp(
        `^${this.props.allowNegative ? "(-?)" : ""}(0|[1-9][0-9]*)(\\.[0-9]{0,${this.props.decimalGroup || 2}})?$`
    );

    constructor(props: InputNumberAddonProps) {
        super(props);

        if (props.field) {
            const {
                field: { value },
            } = props;

            this.state = {
                value: formatNumber(value, this.props.decimalGroup),
                defaultValue: value,
            };
        } else {
            this.state = {
                value:
                    props.defaultValue !== undefined ? formatNumber(props.defaultValue, this.props.decimalGroup) : "",
                defaultValue: props.defaultValue,
            };
        }
    }

    static getDerivedStateFromProps(props: InputNumberAddonProps, state: State): Partial<State> | null {
        const defaultValue = props.field ? props.field.value : props.defaultValue;

        if (defaultValue === state.defaultValue) {
            return null; // No change
        }

        return {
            defaultValue,
            value: formatNumber(defaultValue, props.decimalGroup, props.allowUndefined),
        };
    }

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const parsedValue = (!value || value === "-") && this.props.allowUndefined ? undefined : parseFloat(value);

        if (
            (parsedValue !== undefined && !Number.isNaN(parsedValue) && this.reg.test(parsedValue.toString())) ||
            value === "" ||
            (this.props.allowNegative && value === "-")
        ) {
            this.setState({ value });

            if (this.props.onChange) {
                this.props.onChange(parsedValue);
            }
        }
    };

    // '.' at the end or only '-' in the input box.
    parseValue = () => {
        let { value } = this.state;

        if (value && value.toString().charAt(value.length - 1) === ".") {
            value = value.slice(0, -1);
        }

        const parsedValue = !value || value === "-" ? (this.props.allowUndefined ? undefined : 0) : parseFloat(value);

        if (parsedValue && this.props.decimalGroup) {
            value = this.props.decimalGroup ? parsedValue.toFixed(this.props.decimalGroup) : value;
        }

        if (this.props.onChange) {
            this.props.onChange(parsedValue);
        }

        this.setState({ value });

        if (this.props.form && this.props.field) {
            const {
                field: { name },
            } = this.props;

            this.props.form.setFieldValue(name, value === null ? null : parsedValue);

            let form = this.props.form;

            // allow new value to settle before setting touched – prevents validation message flashing
            setTimeout(() => {
                form.setFieldTouched(name, true);
            }, 0);
        }
    };

    onBlur = () => {
        this.parseValue();
    };

    handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
    };

    handleEnter = (e: React.KeyboardEvent<HTMLElement>) => {
        const { form, submitOnEnter } = this.props;

        if (submitOnEnter && form) {
            e.preventDefault();
            this.parseValue();
            setTimeout(() => form.submitForm(), 0);
        }
    };

    render() {
        const { placeholder, addonBefore, addonAfter, maxLength, className, field, disabled, allowClear } = this.props;
        const { value } = this.state;

        const name = field ? field.name : undefined;

        return (
            <>
                <Input
                    name={name}
                    value={value}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => e.key === "Enter" && this.handleEnter(e)}
                    placeholder={placeholder}
                    maxLength={maxLength || 5}
                    addonBefore={addonBefore}
                    addonAfter={addonAfter}
                    className={className}
                    onClick={this.handleClick}
                    disabled={disabled}
                    allowClear={allowClear}
                />
            </>
        );
    }
}

function formatNumber(value: any, decimalGroup: number | undefined, allowUndefined: boolean = false) {
    if (allowUndefined && value === undefined) {
        return value;
    }

    return decimalGroup ? Number(value || 0).toFixed(decimalGroup) : String(value || 0);
}
