import { opsApi } from "API";
import { GraphQLInputModel, GraphQLItemId } from "common/scaffolding/types";
import { isNew } from "common/utility/modelUtils";

export const saveWaiter = async (locationId: string, staffId: string | undefined, staff: WaiterInputModel) => {
    const isCreate = isNew(staffId);

    const res = isCreate
        ? (await opsApi.graphQLQuery<{ createStaffMember: GraphQLItemId }>(createQuery, { locationId, staff })).data
              .createStaffMember
        : (await opsApi.graphQLQuery<{ updateStaffMember: GraphQLItemId }>(updateQuery, { locationId, staffId, staff }))
              .data.updateStaffMember;

    return res.id;
};

export interface WaiterInputModel extends GraphQLInputModel {
    pin: string;
    accessLevel: string;
}

const createQuery = `
    mutation($locationId: ID!, $staff: StaffUpdateInput!) {
        createStaffMember(locationId: $locationId, data: $staff) {
            id,
            displayName,
            pin,
           accessLevel
        }
    }
`;

const updateQuery = `
    mutation($locationId: ID!, $staffId: ID!, $staff: StaffUpdateInput!) {
        updateStaffMember(locationId: $locationId, id: $staffId, data: $staff) {
            id,
            displayName,
            pin,
            accessLevel
        }
    }
`;
