import { PosFieldDefinition } from "features/location";
import styles from "./PosFieldDescription.module.scss";

interface Props {
    definition: PosFieldDefinition;
}

export const PosFieldDescription = ({ definition }: Props) => {
    if (!definition?.subtitle) {
        return null;
    }

    return <p className={styles.root}>{definition.subtitle}</p>;
};
