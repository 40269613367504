import { isNew } from "common/utility/modelUtils";
import { EditableSurcharge } from "../types";
import { SurchargeInputModel, saveSurcharges } from "../api/saveSurcharges";
import { scaffoldSaveAction } from "common/scaffolding/actions/scaffoldSaveAction";
import { createAction } from "../reducers/edit";
import { edit } from "./edit";
import { AppState } from "features";
import { getSaveSurchargesTrackingEventData } from "../selectors/getSaveSurchargesTrackingEventData";

export const save = (locationId: string, updates: EditableSurcharge[], oneTimeSurcharges: EditableSurcharge[]) =>
    scaffoldSaveAction(
        (state) => state.surcharges.edit,
        createAction,
        async () => {
            const surchargesInputModel = mapUpdateModel(updates, oneTimeSurcharges);
            return await saveSurcharges(locationId, surchargesInputModel);
        },
        undefined,
        false,
        (id: string) => {
            return edit(locationId);
        },
        undefined,
        (appState: AppState) => {
            return getSaveSurchargesTrackingEventData([...updates, ...oneTimeSurcharges]);
        }
    );

const mapUpdateModel = (
    updates: EditableSurcharge[],
    oneTimeSurcharges: EditableSurcharge[]
): SurchargeInputModel[] => {
    const recurring = updates.map(mapSurcharge);
    const oneTime = oneTimeSurcharges.map(mapSurcharge);

    return [...recurring, ...oneTime];
};

const mapSurcharge = (s: EditableSurcharge): SurchargeInputModel => {
    return {
        dayOfWeek: s.day,
        date: s.date,
        id: isNew(s.id) ? undefined : s.id,
        startTime: s.startTime === undefined ? -1 : s.startTime,
        endTime: s.endTime === undefined ? -1 : s.endTime,
        percentage: s.percentage ? s.percentage / 100 : 0,
        applyToFood: s.applyToFood,
        applyToDrink: s.applyToDrink,
        surchargeType: s.surchargeType,
        surchargeId: s.surchargeId,
        isDeleting: s.markedForDelete || false,
    };
};
