import { createAction } from "../reducers/edit";
import { EditablePriceLevel } from "../types";

import { scaffoldSaveAction } from "common/scaffolding/actions/scaffoldSaveAction";
import { edit } from "./edit";
import { TabKeys } from "../components/EditPriceListPage";
import { PriceListItemsInputModel, savePriceListItems } from "../api/savePriceListItems";

export const saveItems = (
    locationId: string,
    region: string,
    priceListId: string,
    products: EditablePriceLevel[],
    modifiers: EditablePriceLevel[]
) =>
    scaffoldSaveAction(
        (state) => state.priceLists.edit,
        createAction,
        async () => {
            const priceListInputModel = mapInputModel(products, modifiers);
            return (await savePriceListItems(locationId, priceListId, priceListInputModel)).id;
        },
        undefined,
        false,
        (id: string) => {
            return edit(locationId, priceListId, TabKeys.Items);
        }
    );

const mapInputModel = (products: EditablePriceLevel[], modifiers: EditablePriceLevel[]): PriceListItemsInputModel => ({
    products,
    modifiers,
});
