import { PosFieldName, getPosFieldSettings } from "./posSettings";
import { withLocation, ActiveLocation } from "features/location";
import { EditRow } from "common/scaffolding/components/EditRow";
import React, { useMemo } from "react";
import { PosField } from "../posField/PosField";
import { FastField, useFormikContext } from "formik";
import { FieldErrors } from "..";
import { WellKnownPosField, WellKnownPosOperation } from "../posField/wellKnown";

// Use PosEditRow for Legacy Layouts - for redesigned layouts/components use PosRow
type CommonProps = {
    name?: string;
    restaurantLocation: ActiveLocation;
    children?: React.ReactNode;
    col?: number;
    defaultTitle?: string;
    defaultSubTitle?: string;
    labelCol?: number;
    disabled?: boolean;
};

type WellKnownProps<T extends WellKnownPosOperation = "OrderCreate" | "PosMenu"> = {
    operationName: T;
    fieldName: WellKnownPosField<T>;
};

type LegacyProps = {
    operationName?: undefined;
    fieldName: PosFieldName;
};

type Props = CommonProps & (LegacyProps | WellKnownProps);

const PosEditRowInner = (props: Props) => {
    const { restaurantLocation, operationName, fieldName, name, col, labelCol, disabled } = props;

    const posField = useMemo(() => {
        const operation = restaurantLocation.posMetadata.operations.find((o) => o.name === operationName);

        if (!operation?.supported) {
            return null;
        }

        const posField = operation.fields.find((o) => o.name === fieldName);

        if (posField === undefined) {
            return null;
        }

        return posField;
    }, [restaurantLocation, operationName, fieldName]);

    const isFullWidth = posField?.input === "lookup" && posField.multiValueDelimiter;

    const actualCol = col ?? (isFullWidth ? 24 : undefined);
    const actualLabelCol = labelCol ?? (isFullWidth ? 24 : undefined);

    const form = useFormikContext();

    if (props.operationName) {
        if (posField && name) {
            return (
                <EditRow title={posField.title} subTitle={posField.subtitle} col={actualCol} labelCol={actualLabelCol}>
                    <FieldErrors name={name} form={form}>
                        <FastField name={name} component={PosField} definition={posField} disabled={disabled} />
                    </FieldErrors>
                </EditRow>
            );
        }

        return null;
    }

    return renderLegacyPosEditRow(props);
};

// Legacy approach, with per-POS details known in manage-app
// Once all POS's support all the field definitions, we can drop this
function renderLegacyPosEditRow({
    restaurantLocation,
    fieldName,
    children,
    col,
    defaultTitle,
    defaultSubTitle,
    labelCol,
}: CommonProps & LegacyProps) {
    const fieldSettings = getPosFieldSettings(restaurantLocation.postype || "", fieldName);

    if (fieldSettings && !fieldSettings.visible) {
        return null;
    }

    if (!fieldSettings && !defaultTitle) {
        return null;
    }

    const title = fieldSettings && fieldSettings.title ? fieldSettings.title : defaultTitle;
    const subTitle = fieldSettings && fieldSettings.subTitle ? fieldSettings.subTitle : defaultSubTitle;

    return (
        <EditRow title={title} subTitle={subTitle} col={col} labelCol={labelCol}>
            {children}
        </EditRow>
    );
}

export const PosEditRow = withLocation(PosEditRowInner);
