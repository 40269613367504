import styles from "./StickyHeader.module.scss";

import { PropsWithChildren, useEffect, useRef } from "react";
import { useCalendarContext } from "features/service/context/CalendarContext";

interface Props {
    isExtended?: boolean;
}

export const StickyHeader = ({ children, isExtended = false }: PropsWithChildren<Props>) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { setHeaderPinned } = useCalendarContext();

    useEffect(() => {
        const el = containerRef.current;

        if (!el) {
            return;
        }

        // based on https://css-tricks.com/how-to-detect-when-a-sticky-element-gets-pinned/
        const observer = new IntersectionObserver(
            ([e]) => {
                const pin = e.intersectionRatio < 1;
                e.target.classList.toggle(styles.pinnedContainer, pin);
                setHeaderPinned(pin);
            },
            { threshold: [1] }
        );

        observer.observe(el);

        return () => el && observer.unobserve(el);
    }, [setHeaderPinned]);

    return (
        <div className={ isExtended ? styles.containerExtended : styles.container} ref={containerRef}>
            {children}
        </div>
    );
};
