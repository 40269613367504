import { KeyedLoaded } from "common/loader";
import { ActiveLocation, ParentLocationUpdateStatus } from "../types/ActiveLocation";
import { TrackEventData } from "common/appInsights/types/TrackEventData";

export type State = KeyedLoaded<ActiveLocation, string | undefined>;

export enum TypeKeys {
    BEGIN = "LOCATION/FETCH_ACTIVE_BEGIN",
    SUCCESS = "LOCATION/FETCH_ACTIVE_SUCCESS",
    FAILURE = "LOCATION/FETCH_ACTIVE_FAILURE",
    SET_LAST_ITEM_UPDATED = "LOCATION/SET_LAST_ITEM_UPDATED",
    UPDATE_STATUS_SUCCESS = "LOCATION/FETCH_UPDATE_STATUS_SUCCESS",
}

export const createAction = {
    begin: (slug: string | undefined) => ({ type: TypeKeys.BEGIN, slug }),
    success: (data: ActiveLocation, trackingData?: TrackEventData) => ({ type: TypeKeys.SUCCESS, data, trackingData }),
    failure: (slug: string | undefined, error: any) => ({ type: TypeKeys.FAILURE, error, slug }),
    setLastItemUpdated: (timeUtc: string) => ({ type: TypeKeys.SET_LAST_ITEM_UPDATED, timeUtc }),
    parentUpdateStatusSuccess: (data: ParentLocationUpdateStatus) => ({
        type: TypeKeys.UPDATE_STATUS_SUCCESS,
        data,
    }),
};

type FetchActiveBeginAction = { type: TypeKeys.BEGIN; slug: string | undefined };
type FetchActiveSuccessAction = { type: TypeKeys.SUCCESS; data: ActiveLocation };
type FetchActiveFailureAction = { type: TypeKeys.FAILURE; error: any; slug: string | undefined };

type FetchActiveAction = FetchActiveBeginAction | FetchActiveSuccessAction | FetchActiveFailureAction;

type SetLastItemUpdated = { type: TypeKeys.SET_LAST_ITEM_UPDATED; timeUtc: string };

type FetchParentUpdateStatusSuccessAction = {
    type: TypeKeys.UPDATE_STATUS_SUCCESS;
    data: ParentLocationUpdateStatus;
};

const initialState: State = {
    status: "unloaded",
};

export function reducer(
    state: State = initialState,
    action: FetchActiveAction | SetLastItemUpdated | FetchParentUpdateStatusSuccessAction
): State {
    if (action.type === TypeKeys.BEGIN) {
        return {
            status: "loading",
            key: action.slug,
        };
    }

    if (action.type === TypeKeys.SUCCESS && state.status !== "unloaded") {
        if (!state.key || state.key === action.data.slug || state.key === action.data.id) {
            return {
                status: "loaded",
                key: action.data?.slug || "*New*",
                data: action.data,
            };
        }
    }

    if (action.type === TypeKeys.UPDATE_STATUS_SUCCESS) {
        if (state.status === "loaded" && action.data) {
            return {
                ...state,
                data: {
                    ...state.data,
                    parentLocationUpdateStatus: action.data,
                },
            };
        }
    }

    if (action.type === TypeKeys.FAILURE && state.status !== "unloaded") {
        if (!state.key || state.key === action.slug) {
            return {
                ...state,
                status: "failed",
                error: action.error,
            };
        }
    }

    if (action.type === TypeKeys.SET_LAST_ITEM_UPDATED && state.status === "loaded") {
        if (state.data.lastItemUpdatedUtc !== action.timeUtc) {
            return {
                ...state,
                data: {
                    ...state.data,
                    lastItemUpdatedUtc: action.timeUtc,
                },
            };
        }
    }

    return state;
}
