import {
    DescriptorMap,
    EditableProduct,
    EditableVariant,
    EditMenuItemTagMap,
    ProductSummary,
} from "features/catalogue/types";
import {
    ItemModifierOptionInputModel,
    MenuItemInputModel,
    saveProduct as saveProductAPI,
} from "features/catalogue/api/saveProduct";
import { createAction } from "features/catalogue/reducers/product/edit";
import { LocationImageType, upload } from "API/upload";
import { MenuItemTemplate } from "../../menuitemtemplate";
import { EditableModifier, EditableModifierOption } from "features/modifier";
import { scaffoldSaveAction } from "common/scaffolding/actions/scaffoldSaveAction";
import { History } from "history";
import { mapListItem } from "features/catalogue/actions";
import { getSaveProductTrackingEventData } from "../selectors/getSaveProductTrackingEventData";
import { AppDispatch, AppState } from "features";
import { numberOrUndefined } from "common/utility/numberUtils";
import { mapEnergyContent } from "./saveModifier";
import { UploadFileType } from "components/forms";
import { setLastItemUpdated } from "features/location/actions/setLastItemUpdated";

export const saveProduct = (
    location: string,
    region: string,
    formData: EditableProduct,
    itemTemplate: MenuItemTemplate,
    clone: boolean = false,
    history: History<any>
) =>
    scaffoldSaveAction(
        (state) => state.menuItems.edit,
        createAction,
        async (itemId: string | undefined, dispatch: AppDispatch) => {
            const menuItemInputModel = await mapInputModel(formData, location, itemTemplate, clone);
            const result = await saveProductAPI(location, itemId, menuItemInputModel);

            dispatch(setLastItemUpdated());

            return mapListItem(result);
        },
        undefined,
        clone,
        (menuItem) => {
            const newUrl = clone
                ? `/${region}/${location}/menu/catalogue/products/${menuItem.id}`
                : `/${region}/${location}/menu/catalogue/products`;

            history.replace(`${newUrl}${window.location.search}`);
            return;
        },
        undefined,
        (appState: AppState, saveResult?: ProductSummary) =>
            getSaveProductTrackingEventData(appState, formData, saveResult)
    );

const mapInputModel = async (
    {
        internalName,
        displayName,
        description,
        image,
        video,
        prices,
        categories,
        descriptors,
        modifiers,
        popular,
        recommendedMenuItems,
        special,
        tags,
        template,
    }: EditableProduct,
    location: string,
    itemTemplate: MenuItemTemplate,
    clone: boolean
): Promise<MenuItemInputModel> => {
    const [imageUrl, videoUrl] = await Promise.all([
        getUploadUrl(image, location, itemTemplate.type, UploadFileType.Image),
        getUploadUrl(video, location, itemTemplate.type, UploadFileType.Video),
    ]);

    const mappedModel: MenuItemInputModel = {
        internalName,
        displayName,
        template: template || itemTemplate.id,
        description,
        descriptors: mapToGraphQLDescriptors(descriptors),
        image: imageUrl,
        video: videoUrl,
        sku: prices.length === 1 ? prices[0].sku : null,
        price: prices.length === 1 ? parseFloat(prices[0].price as any) : null,
        taxRate: prices.length === 1 ? prices[0].taxRate : null,
        variants: prices.length > 1 ? mapVariants(prices, modifiers, clone) : [],
        // Temporary fix -- with local modifiers being migrated out, we should just change the input to accept a list of IDs
        // (which would avoid any structural compatibility issues)
        modifiers: mapModifiers(modifiers),
        tags: flattenTags(tags).filter((t) => !!t),
        relatedItems: recommendedMenuItems,
        categories,
        popular,
        special,
        energyContent: prices.length === 1 ? mapEnergyContent(prices[0].energyContent) : null,
    };

    return mappedModel;
};

async function getUploadUrl(
    media: File | string,
    location: string,
    locationImageType: LocationImageType,
    type: UploadFileType
) {
    if (media && typeof media !== "string") {
        return upload(media as File, location, locationImageType, false, type);
    }
    return Promise.resolve(media);
}

function mapVariants(prices: EditableVariant[], allModifiers: EditableModifier[], clone: boolean): GraphQLVariant[] {
    return prices.map(({ id, displayName, sku, price, taxRate, modifiers, isNew, recommended, energyContent }) => ({
        id: clone || isNew ? undefined : id,
        displayName,
        sku,
        price: parseFloat(price as any),
        taxRate,
        modifiers: allModifiers.map((m) => m.id!).filter((mid) => modifiers.indexOf(mid) !== -1),
        recommended: prices.length > 1 ? recommended : undefined,
        energyContent: mapEnergyContent(energyContent),
    }));
}

function mapModifiers(modifiers: EditableModifier[]) {
    return modifiers.map((modifier) => {
        const {
            status,
            validationStatus,
            minSelection,
            maxSelection,
            maxSelectionPerOption,
            products,
            isLinked,
            ...other
        } = modifier;

        return {
            ...other,
            // TODO awaiting API to make global nullable so we can remove
            global: true,
            options: mapModifierOptions(modifier.options),
            minMaxSelect: undefined,
            minSelection: numberOrUndefined(minSelection),
            maxSelection: numberOrUndefined(maxSelection),
            maxSelectionPerOption: numberOrUndefined(maxSelectionPerOption),
        };
    });
}

function mapModifierOptions(options: EditableModifierOption[]): ItemModifierOptionInputModel[] {
    return options.map((option) => {
        const { validationStatus, validationStatusReason, price, isLinked, ...other } = option;

        return {
            ...other,
            price: Number(price),
        };
    });
}

function flattenTags(tags: EditMenuItemTagMap) {
    return Object.keys(tags)
        .map((tgid) => tags[tgid] || [])
        .reduce((all, tags) => {
            return all.concat(tags);
        }, []);
}

interface GraphQLDescriptor {
    key: string;
    value: string;
}

interface GraphQLVariant {
    id?: string;
    displayName: string;
    price: number | null;
    taxRate: number | null;
    sku: string | null;
    modifiers: string[];
    recommended?: boolean;
    energyContent?: number | string | null;
}

function mapToGraphQLDescriptors(input: DescriptorMap): GraphQLDescriptor[] {
    return Object.keys(input).map((key) => ({
        key,
        value: input[key],
    }));
}
