import { AppState } from "features";
import { CategoryFilter, GroupOrOption } from "../types";
import { CategorySummary } from "features/category";
import { createSelector } from "reselect";
import { sortByDisplayName } from "foundation/dataConventions/sortByDisplayName";
import { toTitleCase } from "common/utility/StringUtils";

const getCategoriesList = (state: AppState) => state.categories.list;

export const UNASSIGNED_CATEGORY_VALUE: string = "__unassigned__";

export const getSortedCategories = createSelector(getCategoriesList, (categoriesList) => {
    if (categoriesList.status !== "loaded") {
        return null;
    }

    const foodCategories = categoriesList.data.filter((category) => category.type === "food").sort(sortByDisplayName);

    const drinkCategories = categoriesList.data.filter((category) => category.type === "drink").sort(sortByDisplayName);

    const otherCategories = categoriesList.data
        .filter((category) => category.type !== "food" && category.type !== "drink")
        .sort(sortByDisplayName);

    return {
        drinks: drinkCategories,
        food: foodCategories,
        other: otherCategories,
    };
});

const mapCategoriesToFilterOptions = (categories: CategorySummary[]): CategoryFilter[] =>
    categories.map<CategoryFilter>(({ id: value, displayName: label }) => ({ value, label }));

export const getCategoryFilterOptions = createSelector(getSortedCategories, (categoriesList) => {
    if (!categoriesList) {
        return [];
    }

    const entries = Object.entries(categoriesList || []);

    const items: GroupOrOption[] = entries
        .map(([type, categories]) => {
            return {
                label: toTitleCase(type),
                options: mapCategoriesToFilterOptions(categories),
            };
        })
        .filter((entry) => entry.options?.length > 0);

    items.push({ label: "(No category)", value: UNASSIGNED_CATEGORY_VALUE, italicize: true });

    return items;
});

export const getFlattenedCategoryFilterOptions = createSelector(getCategoryFilterOptions, (options) => {
    if (!options) {
        return [];
    }

    return options.flatMap((optionOrGroup) => {
        if ("options" in optionOrGroup) {
            return optionOrGroup.options;
        }

        return optionOrGroup;
    });
});
