import styles from "./DatePickerNavBar.module.scss";

import { NavbarElementProps } from "react-day-picker";
import { Button } from "core/components/button";
import { AngleDown } from "common/icons";
import { Icon } from "core/components/icon/Icon";
import { useContext, useMemo, useState } from "react";
import { DayPickerContext } from "./DayPickerContainer";

const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export const DatePickerNavBar = (props: NavbarElementProps) => {
    const { month, onPreviousClick, onNextClick, className } = props;

    const year = month.getFullYear();

    const [tempYear, setTempYear] = useState(year);

    const { setMonth, locale } = useContext(DayPickerContext);

    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const handleMenuClick = () => {
        if (!menuOpen) {
            setTempYear(year);
        }
        setMenuOpen(!menuOpen);
    };

    const handlePrevious = () => {
        if (menuOpen) {
            setTempYear(tempYear - 1);
        } else {
            onPreviousClick();
        }
    };

    const handleNext = () => {
        if (menuOpen) {
            setTempYear(tempYear + 1);
        } else {
            onNextClick();
        }
    };

    const handleMonthClick = (index: number) => {
        setMonth(new Date(`${tempYear}/${index + 1}/1`));
        setMenuOpen(false);
    };

    const localeMonths = useMemo(() => {
        return months.map((month) => new Date(year, month, 1).toLocaleString(locale || "en-AU", { month: "short" }));
        // ignore year dependency, it doesn't change month names
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale]);

    const monthName = month.toLocaleString(locale || "en-AU", { month: "long" });

    return (
        <div className={className}>
            <Button role="secondary" type="button" padding="icon" noBackground noBorder onClick={handlePrevious}>
                <Icon size="small" className={styles.iconPrev}>
                    <AngleDown />
                </Icon>
            </Button>
            <Button
                className={styles.menuButton}
                type="button"
                role="secondary"
                noBackground
                noBorder
                onClick={handleMenuClick}
            >
                {menuOpen ? (
                    <span>{tempYear}</span>
                ) : (
                    <>
                        <span>
                            {monthName} {year}
                        </span>
                        <Icon className={styles.menuButtonIcon} size="small">
                            <AngleDown />
                        </Icon>
                    </>
                )}
            </Button>
            <Button role="secondary" type="button" padding="icon" noBackground noBorder onClick={handleNext}>
                <Icon size="small" className={styles.iconNext}>
                    <AngleDown />
                </Icon>
            </Button>
            {menuOpen && (
                <ul className={styles.monthsList}>
                    {localeMonths.map((monthName, index) => (
                        <li key={index}>
                            <MonthButton
                                onClick={handleMonthClick}
                                month={month}
                                tempYear={tempYear}
                                index={index}
                                monthName={monthName}
                            />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

interface MonthButtonProps {
    onClick: (index: number) => void;
    index: number;
    month: Date;
    monthName: string;
    tempYear: number;
}

export const MonthButton = ({ onClick, index, month, monthName, tempYear }: MonthButtonProps) => {
    const active = month.getFullYear() === tempYear && month.getMonth() === index;
    return (
        <Button
            role="secondary"
            type="button"
            noBorder
            noBackground
            className={active ? styles.monthButtonActive : styles.monthButton}
            onClick={() => onClick(index)}
        >
            {monthName}
        </Button>
    );
};
