import { opsApi } from "API";
import { GraphQLInputModel } from "common/scaffolding/types";
import { menuItemListFields, GraphQLMenuItemSummary } from "features/catalogue/api/listProducts";
import { isNew } from "common/utility/modelUtils";

export const saveProduct = async (locationId: string, itemId: string | undefined, item: MenuItemInputModel) => {
    const isCreate = isNew(itemId);

    const res = isCreate
        ? (await opsApi.graphQLQuery<{ createItem: GraphQLMenuItemSummary }>(createQuery, { locationId, item })).data
              .createItem
        : (await opsApi.graphQLQuery<{ updateItem: GraphQLMenuItemSummary }>(updateQuery, { locationId, itemId, item }))
              .data.updateItem;

    return res;
};

export interface MenuItemInputModel extends GraphQLInputModel {
    template: string;
    description: string;
    image: string;
    video: string;
    sku?: string | null;
    price?: number | null;
    taxRate: number | null;
    variants: ItemVariantInputModel[];
    descriptors: ItemDescriptorInputModel[];
    modifiers: ItemModifierInputModel[];
    tags: string[];
    relatedItems: string[];
    popular: boolean;
    special: boolean;
    categories: string[];
    energyContent?: number | string | null;
}

export interface ItemVariantInputModel {
    id?: string;
    displayName: string;
    sku: string | null;
    price: number | null;
    taxRate?: number | null;
    modifiers: string[];
    recommended?: boolean;
    energyContent?: number | string | null;
}

export interface ItemDescriptorInputModel {
    key: string;
    value: string;
}

export interface ItemModifierInputModel extends GraphQLInputModel {
    id?: string;
    global?: boolean;
    sku?: string;
    required: boolean;
    minSelection?: number;
    maxSelection?: number;
    options: ItemModifierOptionInputModel[];
    upsell: boolean;
    type?: string | null;
}

export interface ItemModifierOptionInputModel {
    displayName: string;
    sku?: string;
    price?: number;
    taxRate: number | null;
}

const createQuery = `
    mutation($locationId: ID!, $item: ProductUpdateInput!) {
        createItem(locationId: $locationId, data: $item) {
            ${menuItemListFields}
        }
    }
`;

const updateQuery = `
    mutation($locationId: ID!, $itemId: ID!, $item: ProductUpdateInput!) {
        updateItem(locationId: $locationId, id: $itemId, data: $item) {
           ${menuItemListFields}
       }
    }
`;
