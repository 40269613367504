import classNames from "classnames";
import { ElementType, ReactNode } from "react";
import styles from "./Label.module.scss";

export type LabelProps = React.HTMLProps<HTMLLabelElement>;
export interface Props {
    after?: ReactNode;
    as?: ElementType;
    label?: string;
    markRequired?: boolean;
    name?: string;
    className?: string;
}

export const Label = ({
    after,
    as: Component = "label",
    className,
    label,
    markRequired,
    name,
    ...rest
}: Props & LabelProps) => {
    // prevent error if as is null or empty string
    if (!Component) {
        Component = "label";
    }

    return (
        <Component
            className={classNames(styles.label, className)}
            {...(name ? { htmlFor: name, "data-testid": `label-${name}` } : null)}
            {...rest}
        >
            {label}
            {markRequired && <span className={styles.required}>&nbsp;*</span>}
            {after}
        </Component>
    );
};
