import { PageHeader } from "core/components/pageHeader";
import { getIsParentLocation } from "features/location/selectors/getIsParentLocation";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { useSelector } from "react-redux";
import { getCataloguePageTitle } from "../selectors/getCatalogueTitle";
import { HeaderActions } from "./HeaderActions";
import timeago from "timeago.js";

export const CataloguePageHeader = () => {
    const title = useSelector(getCataloguePageTitle);

    const activeLocation = useSelector(getActiveLocation);

    const isParentLocation = useSelector(getIsParentLocation);

    const { lastItemUpdatedUtc } = activeLocation || {};

    const lastUpdatedTimeAgo = lastItemUpdatedUtc ? timeago().format(lastItemUpdatedUtc) : "never";

    const subtitle = isParentLocation ? `Last updated: ${lastUpdatedTimeAgo}` : undefined;

    return <PageHeader title={title} actions={<HeaderActions />} subtitle={subtitle} />;
};
