import { GraphQLStripeAccount, GraphQLStripeAccountLink } from "../api/types";
import {
    PAYMENT_STATEMENT_DESCRIPTOR_MAX_LENGTH,
    PAYOUT_STATEMENT_DESCRIPTOR_MAX_LENGTH,
} from "../schema/StatementDescriptorsSchema";
import { StripeAccount, StripeAccountLink } from "../types/StripeAccount";

export const mapStripeAccount = (input: GraphQLStripeAccount): StripeAccount => ({
    accountId: input.accountId,
    chargesEnabled: input.chargesEnabled,
    payoutsEnabled: input.payoutsEnabled,
    detailsSubmitted: input.detailsSubmitted,
    accountCreationLink: input.accountCreationLink ? mapStripeAccountLink(input.accountCreationLink) : undefined,
    paymentStatementDescriptor: input.paymentStatementDescriptor?.substring(0, PAYMENT_STATEMENT_DESCRIPTOR_MAX_LENGTH),
    payoutStatementDescriptor: input.payoutStatementDescriptor?.substring(0, PAYOUT_STATEMENT_DESCRIPTOR_MAX_LENGTH),
});

export const mapStripeAccountLink = (input: GraphQLStripeAccountLink): StripeAccountLink => ({
    url: input.url,
    createdAt: input.createdAt,
    expiresAt: input.expiresAt,
});
