import styles from "./DisabledCheckboxIcon.module.scss";

export const DisabledCheckedIcon = () => {
    return (
        <div className={styles.container}>
            <span className="ant-tree-checkbox ant-tree-checkbox-checked ant-tree-checkbox-disabled">
                <span className="ant-tree-checkbox-inner"></span>
            </span>
        </div>
    );
};
