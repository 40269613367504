import { createAction } from "../reducers/list";
import { applySummaryDisplayName } from "foundation/dataConventions/applySummaryDisplayName";
import { CategorySummary } from "../types";
import { scaffoldListAction } from "../../../common/scaffolding/actions/scaffoldListAction";
import { listCategories, GraphQLCategorySummary } from "../api/listCategories";
import { sortByDisplayName } from "foundation/dataConventions/sortByDisplayName";
import { AppState } from "features";

export const list = (location: string) =>
    scaffoldListAction<CategorySummary>(
        (state) => state.categories.list,
        createAction,
        async (state: AppState) => {
            const categories = await listCategories(location, state);

            return categories.map(mapListItem).sort(sortByDisplayName);
        }
    );

export function mapListItem(category: GraphQLCategorySummary): CategorySummary {
    return {
        ...category,
        image: category.thumbnailImage,
        menus: category.menus.map(applySummaryDisplayName),
        services: category.services.map(applySummaryDisplayName),
    };
}
