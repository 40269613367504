import { createAction } from "../reducers/list";
import { MenuItemTemplate } from "../types";
import { scaffoldListAction } from "../../../common/scaffolding/actions/scaffoldListAction";
import { GraphQLMenuItemTemplate, listMenuItemTemplates } from "../api/listMenuItemTemplates";

export const list = () =>
    scaffoldListAction<MenuItemTemplate>(
        (state) => state.menuItemTemplates.list,
        createAction,
        async () => {
            const menuItemTemplates = await listMenuItemTemplates();

            return menuItemTemplates.map(map);
        }
    );

const map = ({
    id,
    displayName,
    type,
    descriptors,
    tagGroups,
    recommended,
    rating,
}: GraphQLMenuItemTemplate): MenuItemTemplate => ({
    id,
    displayName,
    type: type as "food" | "drink",
    descriptors: descriptors || [],
    tagGroups: (tagGroups || []).map((tg) => tg.id),
    recommended,
    rating,
});
