import styles from "./PromotionRestrictions.module.scss";
import commonStyles from "../EditPromotion.module.scss";

import { formatLeadingDecimal } from "common/utility/numberUtils";
import { Card, Row, Title } from "core/components/card";
import { FieldErrors } from "core/components/form/fieldErrors";
import { Input } from "core/components/form/input";
import { buildInputRegex } from "core/components/form/InputNumber";
import { Field, useFormikContext } from "formik";
import { useCurrencyParser } from "common/hooks";
import { useContext } from "react";
import { LocationLocaleContext } from "features/location";
import { RadioGroup, RadioGroupOption } from "core/components/form/radio";
import { EditablePromotion } from "features/promotions/types";
import { Label } from "core/components/form/input/Label";
import { CommonEditProps } from "../EditPromotionPage";

interface Props extends CommonEditProps {}

const usageOptions: RadioGroupOption[] = [
    {
        text: "Unlimited",
        value: "unlimited",
    },
    {
        text: "Limited",
        value: "limited",
    },
];

export const PromotionRestrictions = ({ disableFields }: Props) => {
    const { currencySymbol } = useContext(LocationLocaleContext);
    const currencyParser = useCurrencyParser();
    const form = useFormikContext<EditablePromotion>();

    return (
        <Card>
            <Row collapse="down">
                <Title title="Restrictions" />
            </Row>
            <Row collapse="down">
                <FieldErrors fieldNames={["restrictions.minSpend"]}>
                    <div className={styles.smallColumn}>
                        <Field
                            name="restrictions.minSpend"
                            component={Input}
                            label="Minimum spend"
                            disabled={disableFields}
                            markRequired
                            before={currencySymbol}
                            inputTest={buildInputRegex({ numDecimals: 2 })}
                            inputMode="decimal"
                            parseValue={currencyParser}
                            formatOnChange={formatLeadingDecimal}
                            maxLength={6}
                        />
                    </div>
                </FieldErrors>
            </Row>
            <Row collapse={"down"}>
                <FieldErrors fieldNames={["restrictions.maxMemberUsage"]}>
                    <Label
                        label="Number of times a specific customer can redeem this promotion"
                        markRequired={true}
                        name="restrictions.maxMemberUsage"
                    />
                    <div className={styles.radioContainer}>
                        <div>
                            <Field
                                name="memberUsage"
                                component={RadioGroup}
                                disabled={disableFields}
                                options={usageOptions}
                            />
                        </div>
                        <div className={commonStyles.mediumColumn}>
                            <Field
                                name="restrictions.maxMemberUsage"
                                component={Input}
                                placeholder=""
                                disabled={disableFields || form.values.memberUsage !== "limited"}
                                inputTest={buildInputRegex()}
                                pattern="\d*"
                                autoReset={{
                                    value: false,
                                    error: true,
                                    touched: true,
                                }}
                                maxLength={9}
                                after="time(s)"
                            />
                        </div>
                    </div>
                </FieldErrors>
            </Row>
            <Row>
                <FieldErrors fieldNames={["restrictions.maxCodeUsage"]}>
                    <Label
                        label="Number of times a specific promo code can be redeemed"
                        markRequired={true}
                        name="restrictions.maxCodeUsage"
                    />
                    <div className={styles.radioContainer}>
                        <div>
                            <Field
                                name="codeUsage"
                                component={RadioGroup}
                                disabled={disableFields}
                                options={usageOptions}
                            />
                        </div>
                        <div className={commonStyles.mediumColumn}>
                            <Field
                                name="restrictions.maxCodeUsage"
                                component={Input}
                                placeholder=""
                                disabled={disableFields || form.values.codeUsage !== "limited"}
                                inputTest={buildInputRegex()}
                                pattern="\d*"
                                autoReset={{
                                    value: false,
                                    error: true,
                                    touched: true,
                                }}
                                maxLength={9}
                                after="time(s)"
                            />
                        </div>
                    </div>
                </FieldErrors>
            </Row>
        </Card>
    );
};
