import { getIn, useFormikContext } from "formik";

interface Props {
    className?: string;
    fieldName: string;
    max: number;
    setTouchedOnMaxExceeded?: boolean; // show validation error while the user is typing
}

export const CharacterCount = ({ className, fieldName, max, setTouchedOnMaxExceeded = true }: Props) => {
    const { values, touched, setFieldTouched } = useFormikContext();
    const value: string = getIn(values, fieldName);
    const length = value?.length || 0;

    if (setTouchedOnMaxExceeded && length > max && !getIn(touched, fieldName)) {
        // Formik complains if we do this synchronously
        setTimeout(() => setFieldTouched(fieldName, true), 0);
    }

    return (
        <span className={className}>
            {length}/{max}
        </span>
    );
};
