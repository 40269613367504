import { useMemo, useState } from "react";
import { Category } from "../types";
import { CheckHandler } from "common/types";
import { filterSearchable } from "common/utility/filterSearchable";
import { Key } from "rc-tree/lib/interface";
import { normaliseText } from "common/utility/StringUtils";
import { PageTitle } from "common/scaffolding/components/DataListingPage/PageTitle";
import { Spin, Tree } from "antd";
import { Title } from "components/forms/ProductTree/Title";
import { TreeItem } from "common/types/TreeItem";
import { useCallback } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import Search from "antd/lib/input/Search";
import "./TreeItemSelector.scss";

export interface Props {
    datasource: TreeItem[];
    initialSelectedItems: Category[];
    selectedItems: Category[];
    onCheck: CheckHandler;
    enabled: boolean;
}

export const TreeItemSelector = ({ datasource, enabled, initialSelectedItems, onCheck, selectedItems }: Props) => {
    const [loaded, setLoaded] = useState(false);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [expandedKeys, setExpandedKeys] = useState<Key[] | undefined>(undefined);
    const timeoutRef = useRef(0);

    useEffect(() => {
        timeoutRef.current = window.setTimeout(() => setLoaded(true), 300);
        return () => clearTimeout(timeoutRef.current);
    }, []);

    useEffect(() => {
        setExpandedKeys(getExpandedKeys(initialSelectedItems, datasource));
        // ignore changes to datasource, only required for for init selections
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialSelectedItems]);

    const handleFilter = (searchTerm: string) => {
        setExpandedKeys(getKeysForSearch(datasource, searchTerm));
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAutoExpandParent(true);
        const searchTerm = normaliseText(e.target.value.trim());
        setSearchTerm(searchTerm);
        handleFilter(searchTerm);
    };

    const handleExpand = (expandedKeys: Key[]) => {
        setAutoExpandParent(false);
        setExpandedKeys(expandedKeys);
    };

    const selectedItemKeys = useMemo(() => getSelectedItemkeys(selectedItems, datasource), [selectedItems, datasource]);

    const titleRender = useCallback(
        ({ displayName, internalName }) => (
            <Title displayName={displayName} internalName={internalName} searchTerm={searchTerm} />
        ),
        [searchTerm]
    );

    return (
        <>
            <PageTitle
                title="Package contents"
                description="Add products to your package. When customers select this package, they will be restricted to ordering the products you select."
            />
            <div className="group-tabs__tree-wrapper">
                {!loaded && (
                    <div className="loader__container loader__container--overlay">
                        <Spin />
                    </div>
                )}
                <Search placeholder="Search products" allowClear onChange={handleSearch} />
                <Tree
                    checkable
                    onCheck={onCheck}
                    expandedKeys={expandedKeys}
                    checkedKeys={selectedItemKeys}
                    autoExpandParent={autoExpandParent}
                    selectable={false}
                    onExpand={handleExpand}
                    disabled={!enabled}
                    treeData={datasource}
                    titleRender={titleRender}
                    className="group-tabs__tree"
                />
            </div>
        </>
    );
};

function getSelectedItemkeys(selectedItems: Category[], listItems: TreeItem[]): string[] {
    let keys: string[] = [];

    listItems.forEach((root) => {
        const categories = root.children || [];
        const categoriesWithAllSelected = [];
        categories.forEach((category) => {
            const products = category.children || [];
            const selectedCategory = selectedItems.find(({ id }) => id === category.id);
            const selectedProducts = selectedCategory
                ? products.filter(({ id }) => selectedCategory.items.includes(id)).map((item) => (item as any).key)
                : [];
            keys = keys.concat(selectedProducts);
            if (products.length && products.length === selectedProducts.length) {
                categoriesWithAllSelected.push(category.id);
                keys.push(category.id);
            }
        });
        if (categoriesWithAllSelected.length && categoriesWithAllSelected.length === categories.length) {
            keys.push(root.id);
        }
    });

    return keys;
}

function getExpandedKeys(selectedItems: Category[], listItems: TreeItem[]): string[] {
    let keys: string[] = [];

    listItems.forEach((root) => {
        const categories = root.children || [];
        categories.forEach((category) => {
            const products = category.children || [];
            const selectedCategory = selectedItems.find(({ id }) => id === category.id);
            const selectedProducts = selectedCategory
                ? products.filter(({ id }) => selectedCategory.items.includes(id)).map((item) => (item as any).key)
                : [];
            keys = keys.concat(selectedProducts);
            // expand parents
            if (selectedProducts.length) {
                keys.push(category.id);
                keys.push(root.id);
            }
        });
    });

    return [...new Set<string>(keys)];
}

function getKeysForSearch(listItems: TreeItem[], searchTerm: string): string[] {
    // find matching roots
    let keys: string[] = filterSearchable<TreeItem>(listItems, searchTerm, true).map(({ id }) => id);

    // find matching categories and products
    listItems.forEach((root) => {
        const categories = root.children || [];
        const matches = filterSearchable<TreeItem>(categories, searchTerm, true, true);
        matches.forEach((category) => {
            const matchingChildKeys = (category.children && category.children.map(({ key }) => key)) || [];
            keys = [...keys, category.key, ...matchingChildKeys];
        });
    });

    return [...new Set<string>(keys)];
}
