import { useEffect } from "react";
import { Link } from "react-router-dom";
import { RouteComponentProps } from "react-router";

import "./DashboardPage.scss";
import { LocationSummary } from "../../location";

export interface Props extends RouteComponentProps<{}, {}> {
    redirect: string;
    restaurantLocation: LocationSummary;
    publishing: boolean;
    onPublish: () => void;
}

export const DashboardPage = ({ match, history, restaurantLocation, redirect, publishing, onPublish }: Props) => {
    useEffect(() => {
        if (redirect) {
            history.replace(redirect);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redirect]);

    if (redirect) {
        return null;
    }

    return (
        <div className="dashboard--container">
            <div className="row middle-xs">
                <div className="col-xs-12">
                    <h1>{restaurantLocation.displayName}</h1>
                </div>
            </div>
            <div className="dashboard--links">
                <div className="dashboard--link">
                    <Link to={`${match.url}/services`}>Services</Link>
                </div>
                <div className="dashboard--link">
                    <Link to={`${match.url}/menus`}>Menus</Link>
                </div>
                <div className="dashboard--link">
                    <Link to={`${match.url}/categories`}>Categories</Link>
                </div>
                <div className="dashboard--link">
                    <Link to={`${match.url}/menuitems/food`}>Menu Items - Food</Link>
                </div>
                <div className="dashboard--link">
                    <Link to={`${match.url}/menuitems/drink`}>Menu Items - Drinks</Link>
                </div>
                <div className="dashboard--link">
                    <Link to={`${match.url}/modifiers`}>Modifiers</Link>
                </div>
                <div className="dashboard--link">
                    <Link to={`${match.url}/waiters`}>Waiters</Link>
                </div>
                <div className="dashboard--link">
                    <Link to={`${match.url}/courses`}>Courses</Link>
                </div>
                <div className="dashboard--link">
                    <Link to={`${match.url}/nfc-import`}>NFC-Import</Link>
                </div>
            </div>
        </div>
    );
};
