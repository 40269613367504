import { LocationLocale } from "features/location/types/LocationLocale";

interface LastUpdatedProps {
    locale: LocationLocale;
    dateUpdated: Date;
}

export const LastUpdated = ({ locale, dateUpdated }: LastUpdatedProps) => {
    return <>{`Updated daily. Last update was ${locale.formatRelativeTime(new Date(dateUpdated))}.`}</>;
};
