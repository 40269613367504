import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import ReactModal from "react-modal";
import cuid from "cuid";
import { Prompt } from "react-router-dom";
import { Location } from "history";
import "./ConfirmModal.scss";

interface Props {
    showModal?: boolean;
    onCloseModal?: () => void;
    title?: string;
    subTitle?: string;
    onConfirm?: () => void;
    confirmText?: string;
    cancelText?: string;
    isPrompt?: boolean;
}

export const ConfirmModal = ({
    showModal,
    onCloseModal,
    children,
    title,
    subTitle,
    confirmText,
    cancelText,
    onConfirm,
    isPrompt,
}: PropsWithChildren<Props>) => {
    const [isOpen, setIsOpen] = useState(!!showModal);
    const allowTransitionCallback = useRef<(ok: boolean) => void | undefined>();

    const triggerPrompt = (cb: (ok: boolean) => void) => {
        setIsOpen(true);
        allowTransitionCallback.current = cb;
    };

    const trigger = useMemo(() => Symbol.for(`__PreventTransitionPrompt_${cuid()}`), []);
    useEffect(() => {
        if (isPrompt) {
            window[trigger] = triggerPrompt;
        }
        return () => {
            if (trigger) {
                delete window[trigger];
            }
        };
    }, [isPrompt, trigger]);

    useEffect(() => {
        if (!isPrompt) {
            setIsOpen(!!showModal);
        }
    }, [showModal, isPrompt]);

    const closeModal = () => {
        setIsOpen(false);
        allowTransitionCallback.current && allowTransitionCallback.current(true);
    };

    const saveAndClose = () => {
        onConfirm && onConfirm();
        allowTransitionCallback.current && allowTransitionCallback.current(false);
        setIsOpen(false);
    };
    const handleTransition = (location: Location) => {
        return trigger && isPrompt ? Symbol.keyFor(trigger) || true : true;
    };

    return (
        <>
            {isPrompt && <Prompt when={isPrompt} message={handleTransition} />}
            <ReactModal
                isOpen={isOpen}
                className={{
                    base: "confirm-modal",
                    afterOpen: "confirm-modal--after-open",
                    beforeClose: "confirm-modal--before-close",
                }}
                overlayClassName="confirm-modal-overlay"
                closeTimeoutMS={250}
                onAfterClose={onCloseModal}
                shouldCloseOnEsc={!isPrompt}
                shouldCloseOnOverlayClick={!isPrompt}
                onRequestClose={closeModal}
                ariaHideApp={false}
            >
                <div className="confirm-modal__body">
                    <div className="confirm-modal__header">
                        <QuestionCircleOutlined />
                        <div>
                            {title && <span className="title">{title}</span>}
                            {subTitle && <span className="description">{subTitle}</span>}
                        </div>
                    </div>
                    <div className="confirm-modal__content">{children}</div>
                    <div className="confirm-modal__actions">
                        <Button className="confirm-modal__actions__cancel" onClick={closeModal}>
                            {cancelText || "Cancel"}
                        </Button>
                        <Button
                            type="primary"
                            className="confirm-modal__actions__confirm"
                            disabled={!isPrompt && !onConfirm}
                            onClick={saveAndClose}
                        >
                            {confirmText || "Confirm"}
                        </Button>
                    </div>
                </div>
            </ReactModal>
        </>
    );
};
