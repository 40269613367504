import { useTrackEvent } from "common/appInsights";
import { toCamelCase } from "common/utility/StringUtils";
import { FileUpload } from "core/components/fileUpload";
import { Confirm, Modal } from "core/components/modal";
import { ActiveLocation } from "features/location";
import { importPromotionFile } from "features/promotions/actions/importPromotionFile";
import { EditablePromotion, mapImportedPromotionToEditablePromotion, PromotionType } from "features/promotions/types";
import { ImportedPromotion } from "features/promotions/types/ImportedPromotion";
import { Moment } from "moment";
import { useCallback, useState } from "react";

// To support 20K codes need 260KB
const MAX_FILE_SIZE = 1;

interface Props {
    visible: boolean;
    close: () => void;
    setEditablePromotion: (editablePromotion: EditablePromotion) => void;
    venueTime: Moment;
    venueTimeZone: string;
    restaurantLocation: ActiveLocation;
}

export const ImportPromotionModal = ({
    visible,
    venueTime,
    venueTimeZone,
    restaurantLocation,
    close,
    setEditablePromotion,
}: Props) => {
    const [editablePromotion, setPromotion] = useState<EditablePromotion | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [failedMessage, setFailedMessage] = useState<string | undefined>();
    const trackImportFailed = useTrackEvent("PROMOTION/IMPORT_PROMOTION_FAILED", {});
    const trackImport = useTrackEvent("PROMOTION/IMPORT_PROMOTION_SUCCESSFUL", {});

    const onClose = useCallback(() => {
        close();
        setPromotion(null);
        setFailedMessage(undefined);
    }, [close]);

    const handleImportFile = useCallback(
        async (files: File[]) => {
            if (!files.length) return;
            setLoading(true);
            try {
                // limit file parse size as we don't care for the code permutations
                const fileData = await files[0].slice(0, 800).text();
                if (!fileData) {
                    throw new Error("File is empty");
                }
                const fileLines = fileData.split(/\r\n|\n/g);

                if (fileLines.length < 2) {
                    throw new Error("File is missing content");
                }
                const reader = fileLines.slice(0, 2);
                const headerRow = reader[0].split(",");
                const dataRow = reader[1]
                    .split(/,(?![\s])/g)
                    .map((a) => (a.startsWith('"') ? a.replace(/["\s\\]/g, "").split(",") : a));

                const promotion = {};

                if (!/code(s)?/gi.test(headerRow[0])) {
                    throw new Error("First column must be Code");
                } else {
                    headerRow[0] = "Code";
                }

                for (let i = 0; i < headerRow.length; i++) {
                    var data = dataRow[i];
                    var header = headerRow[i];
                    if (header && data) {
                        promotion[toCamelCase(header)] = data;
                    }
                }

                const editable = mapImportedPromotionToEditablePromotion(
                    promotion as ImportedPromotion,
                    venueTime,
                    venueTimeZone,
                    fileLines.length > 2
                );

                if (fileLines.length > 2 && fileLines[2]) {
                    editable.promotionType = PromotionType.MULTIPLECODE;
                    editable.promotionFileName = await importPromotionFile(files[0], restaurantLocation.id);
                    if (!editable.promotionFileName) {
                        return;
                    }
                }

                setPromotion(editable);
                setFailedMessage(undefined);
            } catch (e: any) {
                trackImportFailed?.({ error_messages: e.message });
                setFailedMessage(e.message);
            }
            setLoading(false);
        },
        [venueTime, venueTimeZone, restaurantLocation, trackImportFailed]
    );

    return (
        <Modal
            title="Import promotion CSV"
            visible={visible}
            onClose={onClose}
            footer={
                <Confirm
                    confirmLabel="Continue"
                    confirmProps={{ disabled: !editablePromotion }}
                    onConfirm={() => {
                        if (editablePromotion) {
                            trackImport?.({
                                promotion_name: editablePromotion.displayName,
                                promotion_code: editablePromotion.code,
                                generated_code_count: editablePromotion.generatedCodeCount,
                                file_name: editablePromotion.promotionFileName,
                            });
                            setEditablePromotion(editablePromotion);
                        }
                        onClose();
                    }}
                    onCancel={onClose}
                />
            }
        >
            <FileUpload
                maxFiles={1}
                loading={loading}
                maxFileSizeMegabytes={MAX_FILE_SIZE}
                acceptedFileTypes={{ "text/csv": [] }}
                failedMessage={failedMessage}
                showFile
                resetFileInfo
                onResetFile={() => {
                    setPromotion(null);
                    setFailedMessage(undefined);
                }}
                filesAcceptedHandler={handleImportFile}
            />
        </Modal>
    );
};
