import { EditableNewLocation } from "../types/EditableNewLocation";
import { Saved } from "common/loader";

export enum TypeKeys {
    SAVE_BEGIN = "LOCATION/CREATE_BEGIN",
    SAVE_SUCCESS = "LOCATION/CREATE_SUCCESS",
    SAVE_FAILURE = "LOCATION/CREATE_FAILURE",
}

type SavingAction = { type: TypeKeys.SAVE_BEGIN; key: string | undefined };
type SavedAction = { type: TypeKeys.SAVE_SUCCESS; key: string };
type SaveFailedAction = { type: TypeKeys.SAVE_FAILURE; error: any };

type AllActions = SavingAction | SavedAction | SaveFailedAction;

export const createAction = {
    saving: (key: string | undefined): SavingAction => ({ type: TypeKeys.SAVE_BEGIN, key }),
    saved: (key: string): SavedAction => ({ type: TypeKeys.SAVE_SUCCESS, key }),
    saveFailed: () => ({ type: TypeKeys.SAVE_FAILURE }),
};

export type State = Saved<EditableNewLocation | undefined>;

const initialState: State = {};

function isAction<TAction extends AllActions>(action: AllActions, type: string): action is TAction {
    return action.type === type;
}

export function reducer(state: State = initialState, action: AllActions): State {
    if (isAction<SavingAction>(action, TypeKeys.SAVE_BEGIN)) {
        return {
            ...state,
            saveStatus: "saving",
        };
    }

    if (isAction<SavedAction>(action, TypeKeys.SAVE_SUCCESS)) {
        return {
            ...state,
            saveStatus: "saved",
        };
    }

    if (isAction<SaveFailedAction>(action, TypeKeys.SAVE_FAILURE)) {
        return {
            ...state,
            saveStatus: "savefailed",
        };
    }

    return state;
}
