import { useState, useCallback, useEffect } from "react";
import { Props } from "./ViewBrandingPage";
import { preloadImage } from "../utils";
import SVG from "react-inlinesvg";
import { ImageLoader } from "./ImageLoader";

let fetchTimer = 0;

export const HistoryItemPage = ({ heroImage, logoUrl, displayName }: Props) => {
    const [imageReady, setImageReady] = useState(false);
    const fetchBackgroundImage = useCallback(() => {
        window.clearTimeout(fetchTimer);
        if (heroImage) {
            preloadImage(heroImage)
                .then(() => {
                    setImageReady(true);
                })
                .catch(() => {
                    setImageReady(false);
                    fetchTimer = window.setTimeout(fetchBackgroundImage, 1000);
                });
        }
    }, [heroImage]);

    useEffect(() => {
        fetchBackgroundImage();
        return () => window.clearTimeout(fetchTimer);
    }, [heroImage, fetchBackgroundImage]);

    return (
        <div
            className="branding-preview__menu"
            style={
                imageReady
                    ? {
                          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgb(0, 0, 0, 0.75)) center center / cover, url(${heroImage})`,
                      }
                    : undefined
            }
        >
            {!heroImage ? (
                <div className="empty small">No image set</div>
            ) : !imageReady ? (
                <ImageLoader />
            ) : (
                <>
                    {!!logoUrl && logoUrl.endsWith(".svg") && <SVG src={logoUrl} color="white" />}
                    <span>{displayName}</span>
                    <span>Table information</span>
                </>
            )}
        </div>
    );
};
