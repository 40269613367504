import { SectionDemoQr } from "./types";

export const calculateTimeToLive = (demoQr?: SectionDemoQr, fromSeconds: number = 1) => {
    if (!demoQr?.isEnabled || !demoQr.timeToLiveSeconds || !demoQr.dateUpdated) {
        return null;
    }

    const passedSeconds = (Date.now() - demoQr.dateUpdated) / 1000;

    return passedSeconds > demoQr.timeToLiveSeconds
        ? null
        : Math.round((demoQr.timeToLiveSeconds - passedSeconds) / fromSeconds);
};
