import { simplePlural } from "common/utility/StringUtils";
import { createSelector } from "reselect";
import {
    CollectionInfo,
    CollectionName,
    CatalogueItem,
    CatalogueSelections,
    SelectionInfo,
    SelectionState,
} from "../types";

const getSelections = (state: SelectionState) => state.selections;

const getModifiers = (state: SelectionState) => state.modifiers;

const getProducts = (state: SelectionState) => state.products;

export const getCollectionParentItemName = (collectionName: CollectionName) => {
    return collectionName === "products" ? "product" : "modifier group";
};

export const getCollectionChildItemName = (collectionName: CollectionName) => {
    return collectionName === "products" ? "variant" : "modifier option";
};

export const getCollectionNameSingular = (collectionName: CollectionName) =>
    collectionName === "products" ? "product" : "modifier";

const getCollectionInfo = (
    values: CatalogueSelections,
    collectionName: CollectionName,
    items: CatalogueItem[]
): CollectionInfo => {
    const collectionSelections = values[collectionName];

    const collectionEntries = Object.entries(collectionSelections || {});

    const selectedParentIds = collectionEntries.filter(([_, value]) => value).map(([key]) => key);

    let numParentsSelected = 0;

    items
        .filter(({ id }) => id && selectedParentIds.includes(id))
        .forEach((item) => {
            if (item.id) {
                const selections = collectionSelections[item.id];
                const children = item.children?.filter((child) => !!child.id) || [];

                if (selections === true) {
                    numParentsSelected++;
                } else if (Array.isArray(selections)) {
                    // only select parent if all children are selected
                    if (!children.length || children.every(({ id }) => id && selections.includes(id))) {
                        numParentsSelected++;
                    }
                }
            }
        });

    const numChildrenSelected = collectionEntries.reduce<number>((prev, [key, value]) => {
        return prev + (Array.isArray(value) ? value.length : 0);
    }, 0);

    const parentItemName = getCollectionParentItemName(collectionName);
    const childItemName = getCollectionChildItemName(collectionName);

    return {
        collectionName,
        numParentsSelected,
        numChildrenSelected,
        parentItemName,
        childItemName,
        parentSummary: `${numParentsSelected} ${parentItemName}${simplePlural(numParentsSelected)}`,
        childSummary: `${numChildrenSelected} ${childItemName}${simplePlural(numChildrenSelected)}`,
    };
};

export const getSelectionInfo = createSelector(
    getSelections,
    getModifiers,
    getProducts,
    (selections, modifiers, products): SelectionInfo => {
        const modifiersInfo = getCollectionInfo(selections, "modifiers", modifiers);
        const productsInfo = getCollectionInfo(selections, "products", products);

        return {
            modifiers: modifiersInfo,
            products: productsInfo,
        };
    }
);
