import { useState, useEffect } from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { TextInput } from "../../TextInput";
import { PosFieldProps } from "../PosFieldProps";
import { PosTextFieldCommon } from "features/location";
import { PosSecretValueSymbol } from "features/posConfiguration/types/PosSecretValueSymbol";

import "./PosSecretField.scss";

export const PosSecretField = ({
    form,
    field,
    meta,
    definition: { placeholder },
    disabled,
}: PosFieldProps<PosTextFieldCommon>) => {
    const [hasInitialValue] = useState<boolean>(field.value === PosSecretValueSymbol);
    const [editingValue, setEditingValue] = useState<boolean>(!hasInitialValue);

    useEffect(() => {
        if (!editingValue && field.value !== PosSecretValueSymbol) {
            form.setFieldValue(field.name, PosSecretValueSymbol);
            form.setFieldTouched(field.name, true);
        }
    }, [editingValue, form, field.name, field.value]);

    function toggleEditingValue() {
        setEditingValue(!editingValue);
    }

    if (editingValue) {
        return (
            <div className="possecretfield--editing">
                <TextInput
                    autoFocus={hasInitialValue}
                    form={form}
                    field={field}
                    meta={meta}
                    type="password"
                    autoComplete="off"
                    placeholder={placeholder || undefined}
                    disabled={disabled === undefined ? false : disabled}
                />
                {hasInitialValue && <DeleteOutlined onClick={toggleEditingValue} title="Revert changes" />}
            </div>
        );
    }

    return (
        <div className="possecretfield--placeholder">
            &#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;
            {!disabled && <EditOutlined onClick={toggleEditingValue} title="Edit secret" />}
        </div>
    );
};
