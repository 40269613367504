import styles from "./Card.module.scss";

import { forwardRef, PropsWithChildren, ReactElement } from "react";
import classNames from "classnames";

export type Collapse = "up" | "down" | "both";
export type Border = "top" | "bottom" | "auto";
export type Background = "default" | "neutral" | undefined;

export interface Props {
    as?: React.ElementType;
    collapse?: Collapse;
    border?: Border;
    className?: string;
    background?: Background;
    hide?: boolean;
}

export const Row = forwardRef<ReactElement | HTMLElement, PropsWithChildren<Props & Record<string, any>>>(
    ({ as: Component = "div", background, children, collapse, border, className, direction, hide, ...rest }, ref) => {
        // prevent error if as is null or empty string
        if (!Component) {
            Component = "div";
        }

        const classes = classNames({
            [styles.row]: !collapse,
            [styles[`row--${background}`]]: background,
            [styles[`collapse--${collapse}`]]: collapse,
            [styles[`border--${border}`]]: border,
            [styles.hidden]: hide,
            [`${className}`]: className,
        });

        return (
            <Component className={classes} ref={ref} {...rest}>
                {children}
            </Component>
        );
    }
);
