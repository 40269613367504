import * as Yup from "yup";
import {
    EditablePromotion,
    EditablePromotionRestrictions,
    EditablePromotionSchedule,
    PromotionDiscount,
    PromotionDiscountType,
    PromotionItems,
    PromotionType,
} from "../types";
import { fromPercentage, withPrecision } from "common/utility/numberUtils";

import moment, { Moment } from "moment";
import { findPosFieldSchema } from "components/forms/posField/getPosFieldSchema";

type ValidationContext = {
    values: EditablePromotion;
};

export interface ValidationOptions {
    formatCurrency: (value: number) => string;
    locationId: string;
    initialStartDate: string | undefined;
    venueTime: Moment;
    venueTimeZone: string;
}

export const isNotPastDate = function (value: string, venueTime: Moment, venueTimeZone: string) {
    if (!value) {
        return true;
    }

    const date = moment(value, "YYYY-MM-DD")
        .tz(venueTimeZone, true)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    const today = venueTime.clone().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    return !date.isBefore(today);
};

export const getEditablePromotionSchema = (options: ValidationOptions) =>
    Yup.object<EditablePromotion>().shape({
        id: Yup.string(),
        displayName: Yup.string().max(64, "Name must be no more than 64 characters").required("Enter a Promotion name"),
        posId: findPosFieldSchema("OrderCreate", "discountPosId"),
        code: Yup.string()
            .when("promotionType", {
                is: PromotionType.MULTIPLECODE,
                then: Yup.string()
                    .max(
                        7,
                        "Code prefix must be no more than 7 characters and cannot contain special characters or spaces"
                    )
                    .required("Enter a Promo code prefix")
                    .test(
                        "promoCodeAvailable",
                        "This code prefix is already used by another Promotion",
                        function (value) {
                            if (!value) {
                                return true;
                            }
                            // hidden form value indicates uniqueness
                            const {
                                values: { hasUniqueCode },
                            } = this.options.context as ValidationContext;

                            return hasUniqueCode || false;
                        }
                    ),
            })
            .when("promotionType", {
                is: PromotionType.SINGLECODE,
                then: Yup.string()
                    .max(
                        12,
                        "Promo code must be no more than 12 characters and cannot contain special characters or spaces"
                    )
                    .required("Enter a Promo code")
                    .test(
                        "promoCodeAvailable",
                        "This Promo code is already used by another Promotion",
                        function (value) {
                            if (!value) {
                                return true;
                            }
                            const {
                                values: { hasUniqueCode },
                            } = this.options.context as ValidationContext;

                            return hasUniqueCode || false;
                        }
                    ),
            }),
        generatedCodeCount: Yup.string().test("validateRange", "Must be between 2 and 20k", function (value: string) {
            const {
                values: { promotionType },
            } = this.options.context as ValidationContext;

            if (promotionType !== PromotionType.MULTIPLECODE) {
                return true;
            }
            const parsedVal = value ? Number(value.replace(/,/g, "")) : 0;
            return parsedVal > 1 && parsedVal <= 20000;
        }),
        promotionType: Yup.mixed().oneOf(Object.values(PromotionType)),
        discount: Yup.object<PromotionDiscount>().shape({
            type: Yup.mixed().oneOf(Object.values(PromotionDiscountType)),
            value: Yup.number()
                .when("type", {
                    is: PromotionDiscountType.PERCENTAGE,
                    then: Yup.number()
                        .min(1, "Discount must be between 1% and 99%")
                        .max(99, "Discount must be between 1% and 99%")
                        .integer("Value cannot contain decimals")
                        .required("Please enter a value"),
                })
                .when("type", {
                    is: PromotionDiscountType.FIXEDAMOUNT,
                    then: Yup.number()
                        .min(0.5, `Must be at least ${options.formatCurrency(0.5)}`)
                        .max(99999, `Must be less than ${options.formatCurrency(100000)}`)
                        .required("Please enter a value"),
                }),
        }),
        priceListId: Yup.string()
            .nullable(true)
            .test("priceListSelected", "Please select a Price List", function (value) {
                const {
                    values: { discount },
                } = this.options.context as ValidationContext;
                return !!value || discount.type !== PromotionDiscountType.PRICELIST;
            }),
        restrictions: Yup.object<EditablePromotionRestrictions>().shape({
            minSpend: Yup.number()
                .required("Please enter a value")
                .when("type", {
                    is: PromotionDiscountType.FIXEDAMOUNT,
                    then: Yup.number()
                        .min(
                            0.5,
                            `Minimum spend must be greater than the discount by at least ${options.formatCurrency(0.5)}`
                        )
                        .max(999999, `Must be less than ${options.formatCurrency(1000000)}`)
                        .required("Please enter a value"),
                })
                .test(
                    "validPercentMinSpend",
                    `Minimum spend must be greater than the discount by at least ${options.formatCurrency(0.5)}`,
                    function (value) {
                        const {
                            values: {
                                discount: { type, value: discountValue },
                            },
                        } = this.options.context as ValidationContext;

                        // only apply rule if we have a percentage value and a minSpend value
                        if (
                            type !== PromotionDiscountType.PERCENTAGE ||
                            discountValue === "" ||
                            discountValue == null ||
                            value === "" ||
                            value == null
                        ) {
                            return true;
                        }

                        const discountValueFactor = fromPercentage(discountValue);

                        return withPrecision(value * (1 - discountValueFactor)) >= 0.5;
                    }
                )
                .test(
                    "validAmountMinSpend",
                    `Minimum spend must be greater than the discount by at least ${options.formatCurrency(0.5)}`,
                    function (value) {
                        const {
                            values: {
                                discount: { type, value: discountValue },
                            },
                        } = this.options.context as ValidationContext;

                        // only apply rule if we have an amount value and a minSpend value
                        if (
                            type !== PromotionDiscountType.FIXEDAMOUNT ||
                            discountValue === "" ||
                            discountValue == null ||
                            value === "" ||
                            value == null
                        ) {
                            return true;
                        }

                        return value - Number(discountValue) >= 0.5;
                    }
                ),
            maxMemberUsage: Yup.number().when("$memberUsage", {
                is: "limited",
                then: Yup.number().moreThan(0, "Usage must be greater than 0").required("Please enter a value"),
            }),
            maxCodeUsage: Yup.number().when("$codeUsage", {
                is: "limited",
                then: Yup.number().moreThan(0, "Usage must be greater than 0").required("Please enter a value"),
            }),
            requiredItems: Yup.object<PromotionItems>()
                .nullable(true)
                .test("validSelections", "No items selected", function (value: PromotionItems | null) {
                    const {
                        values: { applyToAll },
                    } = this.options.context as ValidationContext;
                    if (applyToAll) {
                        return true;
                    }

                    const numSelections = (value?.productIds.length || 0) + (value?.variantIds.length || 0);
                    return numSelections > 0;
                }),
            schedule: Yup.object<EditablePromotionSchedule>()
                .nullable(true)
                .shape({
                    startDate: Yup.string()
                        .required("Start date is required")
                        .test("pastStart", "Start date can not be in the past", function (value: string) {
                            const { initialStartDate, venueTime, venueTimeZone } = options;

                            if (!value || initialStartDate === value) {
                                return true;
                            }

                            return isNotPastDate(value, venueTime, venueTimeZone);
                        }),
                    endDate: Yup.string()
                        .nullable(true)
                        .test("pastEnd", "End date can not be in the past", (value: string) => {
                            const { venueTime, venueTimeZone } = options;
                            return isNotPastDate(value, venueTime, venueTimeZone);
                        })
                        .test("endBeforeStart", "End date can not be before start date", function (value: string) {
                            const { values: formData } = this.options.context as ValidationContext;
                            const { startDate } = formData?.restrictions?.schedule || {};

                            if (!startDate || !value) {
                                return true;
                            }

                            const toDate = moment(value, "YYYY-MM-DD").toDate();
                            const fromDate = moment(startDate, "YYYY-MM-DD").toDate();

                            return fromDate.getTime() <= toDate.getTime();
                        }),
                    activeDaysOfWeek: Yup.array().of(Yup.number()).min(1, "Active days cannot be empty"),
                    dailyStartTime: Yup.number(),
                    dailyEndTime: Yup.number().test(
                        "overnightPromo",
                        "End time should be greater than the start time",
                        function (value: string) {
                            const { values: formData } = this.options.context as ValidationContext;
                            const { dailyStartTime } = formData?.restrictions?.schedule || {};
                            const endTime = parseInt(value, 10);

                            if (endTime === 0) {
                                return true;
                            }

                            return endTime > dailyStartTime;
                        }
                    ),
                    specificInactiveDates: Yup.array().of(Yup.string()), //TODO
                }),
        }),
        usageCount: Yup.number(),
    });
