import styles from "./RegionList.module.scss";

import { Region } from "../types/region";
import { Popup } from "core/components/popup";
import { RegionPopupButton } from "./RegionPopupButton";
export interface Props {
    selectedRegion?: Region;
    availableRegions: Region[];
}

export const RegionSelector = ({ selectedRegion, availableRegions }: Props) => {
    if (availableRegions.length < 2) {
        return null;
    }

    return (
        <Popup
            buttonLabel={selectedRegion ? selectedRegion.id : "Select region..."}
            button={RegionPopupButton}
            offset={{
                x: 0,
                y: 4,
            }}
            align="bottom-right"
        >
            <RegionList activeRegion={selectedRegion} availableRegions={availableRegions} />
        </Popup>
    );
};

interface RegionListProps {
    activeRegion?: Region;
    availableRegions: Region[];
}

const RegionList = ({ activeRegion, availableRegions }: RegionListProps) => {
    return (
        <>
            <ul className={styles.list}>
                {availableRegions.map(({ id, displayName }: Region) => (
                    <li key={id} className={id === activeRegion?.id ? styles.listItemSelected : styles.listItem}>
                        {displayName.includes("[access denied]") ? (
                            <span className={styles.labelDisabled}>{displayName}</span>
                        ) : (
                            <a className={styles.link} href={`/${id}`}>
                                <span className={styles.label}>{displayName}</span>
                            </a>
                        )}
                    </li>
                ))}
            </ul>
        </>
    );
};
