import { AppState } from "features";
import { Dispatch } from "redux";
import { getAnalyticsDashboardUrl } from "../api/getAnalyticsDashboardUrl";
import { createAction } from "../reducers/analytics";

export const getDashboardUrl = (locationId: string, analyticsTool: string, tabId: string) => {
    return async (dispatch: Dispatch, getState: () => AppState) => {
        const state = getState().analytics.dashboardUrl;

        if (state.status === "loading") {
            return;
        }

        try {
            dispatch(createAction.loading());
            const data = await getAnalyticsDashboardUrl(locationId, analyticsTool, tabId);
            dispatch(createAction.loaded(data));
        } catch (e) {
            if (process.env.NODE_ENV === "development") {
                console.error(e);
            }
            dispatch(createAction.failed(e));
        }
    };
};
