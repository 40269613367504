import { opsApi } from "API";
import { PromotionType } from "features/promotions/types";

export const queryPromoCodeAvailable = async (
    promotionType: PromotionType,
    promoCode: string,
    location: string,
    currentPromotionId: string
): Promise<boolean> => {
    const res = await opsApi.graphQLQuery<{ promoCodeIsAvailable: boolean }>(query, {
        promotionType,
        promoCode,
        location,
        currentPromotionId,
    });
    return res.data.promoCodeIsAvailable;
};

const query = `query ($promotionType: PromotionType!, $promoCode: String!, $location: ID!, $currentPromotionId: ID) {
        promoCodeIsAvailable(promoCode: $promoCode, locationId: $location, currentPromotionId: $currentPromotionId, promotionType: $promotionType)
    } 
`;
