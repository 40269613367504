import "./EditLocationSettingsPage.scss";
import { ValidationOptions } from "features/locationSettings/schema/ValidationOptions";
import { ChangeEvent, useContext } from "react";
import { DataSelectItem } from "common/scaffolding/components/DataSelectItem";
import { EditableLocationSettings } from "../types";
import { getEditableLocationSettingsSchema } from "../schema/EditableLocationSettingsSchema";
import { EditRow } from "common/scaffolding/components/EditRow";
import { FieldErrors, CheckboxInput, TextInput, TextArea, PercentageInput } from "components/forms";
import { Form, Divider } from "antd";
import { Formik, FastField, validateYupSchema, yupToFormErrors, Field, FieldProps } from "formik";
import { PageTitle } from "common/scaffolding/components/DataListingPage/PageTitle";
import { PosEditRow } from "components/forms/posUI/PosEditRow";
import { PreSelectedTipInput } from "./PreSelectedTipInput";
import { SaveStatus } from "common/loader";
import { SectionSummary } from "features/section";
import { TipsInput } from "./TipsInput";
import { SelectAgeRestriction } from "./SelectAgeRestriction";
import { ActionFooter, StatusMessage } from "core/components/actionFooter";
import { Button } from "core/components/button";
import { ScrollToFormikError } from "components/forms/ScrollToFormikError";
import { PosField } from "components/forms/posField/PosField";
import { PosFieldDescription } from "components/forms/posField/PosFieldDescription";
import { usePosField } from "components/forms/posUI/usePosField";
import { ActiveLocation, LocationLocaleContext } from "features/location";
import { Input } from "core/components/form/input";
import { buildInputRegex } from "core/components/form/InputNumber";
import { formatLeadingDecimal } from "common/utility/numberUtils";
import { useCurrencyFormatter, useCurrencyParser } from "common/hooks";
import { OrderFlowSelector } from "./SelectOrderFlow";
import { splitPaymentOptions } from "../types/SplitPaymentOptions";

export type PageSectionAccess = "off" | "read-only" | "editable";

export interface Props {
    initialValues: EditableLocationSettings;
    sections: SectionSummary[];
    saveStatus: SaveStatus;
    onSubmit: (data: EditableLocationSettings) => void;
    location: ActiveLocation;
    hasUpdatePermission: boolean;
    hasGroupEditPermission: boolean;
    hideLiveOrdersCheckbox: boolean;
    hasLocationUpdatePermission: boolean;
    groupTabGratuitySupported: boolean;
    posSyncSupported: boolean;
    trayChargeSettingsAccess: PageSectionAccess;
    venueServiceFeeSettingsAccess: PageSectionAccess;
    alcoholicDrinksRestrictionAccess: PageSectionAccess;
    hasLocationFlagUpdatePermission: boolean;
    absorbMerchantFeeSupported: boolean;
    showSelectSplitPaymentOptions: boolean;
}

export const EditLocationSettingsPage = ({
    initialValues,
    saveStatus,
    sections,
    location,
    onSubmit,
    hasUpdatePermission,
    hasGroupEditPermission,
    hideLiveOrdersCheckbox,
    hasLocationUpdatePermission,
    groupTabGratuitySupported,
    posSyncSupported,
    trayChargeSettingsAccess,
    venueServiceFeeSettingsAccess,
    alcoholicDrinksRestrictionAccess,
    hasLocationFlagUpdatePermission,
    absorbMerchantFeeSupported,
    showSelectSplitPaymentOptions,
}: Props) => {
    const locationLocale = useContext(LocationLocaleContext);
    const currencyParser = useCurrencyParser();
    const currencyFormatter = useCurrencyFormatter(locationLocale);
    const trayChargePosField = usePosField({
        restaurantLocation: location,
        operationName: "OrderCreate",
        fieldName: "trayChargePosId",
    });

    return (
        <div className="edit-config">
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={onSubmit}
                key={initialValues.id}
                validate={getValidate({ formatCurrency: currencyFormatter.format }, location)}
            >
                {(form) => {
                    const trayChargeFieldsDisabled =
                        trayChargeSettingsAccess === "read-only" || !form.values.trayCharge?.enabled;
                    const venueServiceFeeFieldsDisabled =
                        venueServiceFeeSettingsAccess === "read-only" || !form.values.venueServiceFee?.enabled;
                    const alcoholicDrinkRestrictionFieldsDisabled =
                        alcoholicDrinksRestrictionAccess === "read-only" ||
                        !form.values.alcoholicDrinksRestriction?.enabled;

                    return (
                        <Form onFinish={form.handleSubmit} className="padding-bottom-60">
                            <ScrollToFormikError />
                            <PageTitle title="Venue Configuration Settings" description="" />
                            <Divider />
                            {hasLocationUpdatePermission && (
                                <PosEditRow
                                    fieldName="allowNotes"
                                    defaultTitle="Docket Notes"
                                    defaultSubTitle="Enable this feature to allow customers to add custom notes to their orders."
                                >
                                    <FieldErrors name="allowNotesToKitchen" form={form}>
                                        <FastField name="allowNotesToKitchen" component={CheckboxInput} />
                                    </FieldErrors>
                                </PosEditRow>
                            )}
                            <EditRow
                                title="Age Restriction Venue Alert"
                                subTitle="Enable this feature to alert your customers that this is an age restricted venue."
                            >
                                <FieldErrors name="confirmEighteenPlus" form={form}>
                                    <FastField
                                        name="confirmEighteenPlus"
                                        component={CheckboxInput}
                                        disabled={!hasUpdatePermission}
                                    />
                                </FieldErrors>
                                {form.values.confirmEighteenPlus && (
                                    <FieldErrors name="ageRestriction" form={form}>
                                        <Field
                                            name="ageRestriction"
                                            component={SelectAgeRestriction}
                                            disabled={!hasUpdatePermission}
                                        />
                                    </FieldErrors>
                                )}
                            </EditRow>
                            <EditRow
                                title="Easy Drink Reorders"
                                subTitle="Allow your guests to quickly reorder a previous round of drinks with a prompt when they start ordering."
                            >
                                <FieldErrors name="enableAnotherRound" form={form}>
                                    <FastField
                                        name="enableAnotherRound"
                                        component={CheckboxInput}
                                        disabled={!hasUpdatePermission}
                                    />
                                </FieldErrors>
                            </EditRow>
                            {hasLocationUpdatePermission && (
                                <EditRow
                                    title="Buzzer"
                                    subTitle="Enable this feature if you want your customers to collect their order from a collection point. Contact your Account Manager for more details about this feature."
                                >
                                    <FieldErrors name="isBuzzer" form={form}>
                                        <FastField name="isBuzzer" component={CheckboxInput} />
                                    </FieldErrors>
                                </EditRow>
                            )}
                            <EditRow
                                title="Order Holding - Drinks"
                                titleInfo="(seconds)"
                                subTitle="We'll send through orders made on the same table together. You can override the numbers of seconds that we'll wait for other orders."
                            >
                                <FieldErrors name="drinkOrderBatchTimeSeconds" form={form}>
                                    <FastField
                                        name="drinkOrderBatchTimeSeconds"
                                        component={TextInput}
                                        type="number"
                                        placeholder="Order Holding - Drinks (seconds)"
                                        disabled={!hasUpdatePermission}
                                    />
                                </FieldErrors>
                            </EditRow>
                            <EditRow
                                title="Order Holding - Food"
                                titleInfo="(seconds)"
                                subTitle="We'll send through orders made on the same table together. You can override the numbers of seconds that we'll wait for other orders."
                            >
                                <FieldErrors name="foodOrderBatchTimeSeconds" form={form}>
                                    <FastField
                                        name="foodOrderBatchTimeSeconds"
                                        component={TextInput}
                                        type="number"
                                        placeholder="Order Holding - Food (seconds)"
                                        disabled={!hasUpdatePermission}
                                    />
                                </FieldErrors>
                            </EditRow>
                            <EditRow
                                title="Docket Merging"
                                subTitle="We can merge held orders from the same table together to reduce the quantity of dockets. Select which sections should merge held orders"
                            >
                                <FieldErrors name="sectionsWithDocketMerging" form={form}>
                                    <FastField
                                        name="sectionsWithDocketMerging"
                                        component={DataSelectItem}
                                        options={sections}
                                        placeholder="Select sections for docket merging"
                                        disabled={!hasUpdatePermission}
                                    />
                                </FieldErrors>
                            </EditRow>
                            {hasLocationUpdatePermission && (
                                <>
                                    <EditRow
                                        title="Table Time Out"
                                        titleInfo="(minutes)"
                                        subTitle="If an order has not been placed before the nominated amount of time, the order will be cleared."
                                    >
                                        <FieldErrors name="unusedPartyTimeoutMinutes" form={form}>
                                            <FastField
                                                name="unusedPartyTimeoutMinutes"
                                                component={TextInput}
                                                type="number"
                                                placeholder="Table Time Out (minutes)"
                                            />
                                        </FieldErrors>
                                    </EditRow>

                                    <EditRow
                                        title="POS Time Out"
                                        titleInfo="(minutes)"
                                        subTitle="The amount of time to wait for a response from the POS after submitting an order."
                                    >
                                        <FieldErrors name="idleTimeoutMinutes" form={form}>
                                            <FastField
                                                name="idleTimeoutMinutes"
                                                component={TextInput}
                                                type="number"
                                                placeholder="POS Time Out (minutes)"
                                            />
                                        </FieldErrors>
                                    </EditRow>
                                    {!hideLiveOrdersCheckbox && (
                                        <EditRow
                                            title="Live Orders"
                                            subTitle="Enables the live orders functionality for this venue."
                                        >
                                            <FieldErrors name="isLiveOrdersEnabled" form={form}>
                                                <FastField name="isLiveOrdersEnabled" component={CheckboxInput} />
                                            </FieldErrors>
                                        </EditRow>
                                    )}
                                </>
                            )}

                            <Divider />
                            <EditRow
                                title="Tips"
                                subTitle="Enabling tips will allow customers to send a tip to your venue when ordering."
                            >
                                <FieldErrors name="allowTips" form={form}>
                                    <Field name="allowTips">
                                        {({ form, field: { name, onChange, onBlur }, meta }: FieldProps<boolean>) => (
                                            <CheckboxInput
                                                form={form}
                                                field={{
                                                    name,
                                                    value: form.values.allowTips,
                                                    onChange: (e: ChangeEvent<HTMLInputElement>) => {
                                                        // prevent changed, possibly invalid tip values from being
                                                        // sent when allowTips becomes false
                                                        if (!e.target.checked) {
                                                            form.setValues({
                                                                ...form.values,
                                                                tipOptions: {
                                                                    ...form.initialValues.tipOptions,
                                                                },
                                                            });
                                                        }
                                                        onChange(e);
                                                    },
                                                    onBlur,
                                                }}
                                                meta={meta}
                                                disabled={!hasUpdatePermission}
                                            />
                                        )}
                                    </Field>
                                </FieldErrors>
                            </EditRow>
                            {form.values.allowTips && (
                                <>
                                    {form.values.enableNoTip && (
                                        <EditRow
                                            title="Display ‘No tip’ option"
                                            subTitle="When enabled, customers will see a quick ‘No tip’ option. Disable this to hide this option."
                                        >
                                            <FieldErrors name="tipOptions.showNoTip" form={form}>
                                                <FastField
                                                    name="tipOptions.showNoTip"
                                                    component={CheckboxInput}
                                                    disabled={!hasUpdatePermission}
                                                />
                                            </FieldErrors>
                                        </EditRow>
                                    )}
                                    <EditRow
                                        title="Suggested tip amounts"
                                        subTitle="Nominate 3 suggested tip values that customers can choose from. Values can be between 1–50, and must be whole numbers or .5 format."
                                    >
                                        <TipsInput form={form} disabled={!hasUpdatePermission} />
                                    </EditRow>
                                    <EditRow
                                        title="Preselected tip"
                                        subTitle="Optional. Nominate one of the tip amounts to be automatically selected on checkout."
                                    >
                                        <PreSelectedTipInput
                                            form={form}
                                            name="tipOptions.defaultIndex"
                                            disabled={!hasUpdatePermission}
                                        />
                                    </EditRow>
                                </>
                            )}
                            <Divider />
                            {trayChargeSettingsAccess !== "off" && (
                                <>
                                    <EditRow
                                        title="Tray Charge"
                                        titleInfo="(Requires publish)"
                                        subTitle="Set a fixed amount per transaction."
                                    >
                                        <Field
                                            name="trayCharge.enabled"
                                            component={CheckboxInput}
                                            disabled={trayChargeSettingsAccess === "read-only"}
                                        />
                                    </EditRow>
                                    <EditRow title="Tray Charge Amount" subTitle="Fee added to each order.">
                                        <FieldErrors name="trayCharge.fixedAmount" form={form}>
                                            <Field
                                                name="trayCharge.fixedAmount"
                                                component={Input}
                                                disabled={trayChargeFieldsDisabled}
                                                markRequired={false}
                                                before={locationLocale.currencySymbol}
                                                inputTest={buildInputRegex({ numDecimals: 2 })}
                                                inputMode="decimal"
                                                parseValue={currencyParser}
                                                formatOnChange={formatLeadingDecimal}
                                                maxLength={6}
                                            />
                                        </FieldErrors>
                                    </EditRow>
                                    <EditRow
                                        title="Tray Charge Name"
                                        subTitle="Name displayed to user on Review Order screen. If left blank, we’ll use 'Venue tray charge'."
                                    >
                                        <FieldErrors name="trayCharge.name" form={form}>
                                            <Field
                                                name="trayCharge.name"
                                                component={TextInput}
                                                placeholder="Venue tray charge"
                                                minLength={5}
                                                maxLength={28}
                                                allowClear
                                                disabled={trayChargeFieldsDisabled}
                                            />
                                        </FieldErrors>
                                    </EditRow>
                                    {trayChargePosField != null && (
                                        <EditRow
                                            title={trayChargePosField.title || "Tray Charge POS SKU"}
                                            subTitle="Enter the corresponding value that has been configured in POS."
                                        >
                                            <FieldErrors name="trayCharge.posId" form={form}>
                                                <Field
                                                    name="trayCharge.posId"
                                                    component={PosField}
                                                    definition={trayChargePosField}
                                                    disabled={trayChargeFieldsDisabled}
                                                    markRequired={false}
                                                />
                                                <PosFieldDescription definition={trayChargePosField} />
                                            </FieldErrors>
                                        </EditRow>
                                    )}
                                    <Divider />
                                </>
                            )}
                            {alcoholicDrinksRestrictionAccess !== "off" && (
                                <>
                                    <EditRow
                                        title="Restriction On Alcoholic Drinks"
                                        titleInfo="(Requires publish)"
                                        subTitle="Limit the number of alcoholic drinks that customers can add to their order."
                                    >
                                        <Field
                                            name="alcoholicDrinksRestriction.enabled"
                                            component={CheckboxInput}
                                            disabled={alcoholicDrinksRestrictionAccess === "read-only"}
                                        />
                                    </EditRow>
                                    <EditRow title="Apply Across All Services">
                                        <Field
                                            name="alcoholicDrinksRestriction.applyAcrossAllServices"
                                            component={CheckboxInput}
                                            disabled={alcoholicDrinkRestrictionFieldsDisabled}
                                        />
                                    </EditRow>
                                    <EditRow title="Alcoholic Drinks Per Order " titleInfo="(Drink count)">
                                        <FieldErrors name="alcoholicDrinksRestriction.maxDrinksPerOrder" form={form}>
                                            <Field
                                                name="alcoholicDrinksRestriction.maxDrinksPerOrder"
                                                component={TextInput}
                                                type="number"
                                                disabled={alcoholicDrinkRestrictionFieldsDisabled}
                                            />
                                        </FieldErrors>
                                    </EditRow>
                                    <Divider />
                                </>
                            )}
                            {groupTabGratuitySupported && (
                                <>
                                    <EditRow
                                        title="Group Tabs gratuity"
                                        subTitle="Optional. Add a gratuity when customers order on a Group Tab. Gratuity can be between 1% and 20%."
                                    >
                                        <FieldErrors name="groupTabGratuityFactor" form={form}>
                                            <FastField name="groupTabGratuityFactor" component={PercentageInput} />
                                        </FieldErrors>
                                    </EditRow>
                                    <Divider />
                                </>
                            )}
                            <EditRow
                                title="Enable Checkout Message"
                                titleInfo="(Requires publish)"
                                subTitle="Enable this feature to add a message customers will see at checkout."
                            >
                                <FastField
                                    name="enableDietaryNotifier"
                                    component={CheckboxInput}
                                    disabled={!hasUpdatePermission}
                                />
                            </EditRow>
                            <EditRow
                                title="Checkout Message Acknowledgement"
                                subTitle="Optional: Require customers to acknowledge the message before being able to order."
                            >
                                <FieldErrors name="mustAcceptAllergenNotice" form={form}>
                                    {form.values.enableDietaryNotifier ? (
                                        <FastField
                                            name="mustAcceptAllergenNotice"
                                            component={CheckboxInput}
                                            disabled={!hasUpdatePermission}
                                        />
                                    ) : (
                                        <FastField
                                            key="mustAcceptAllergenNoticeDisabled"
                                            name="mustAcceptAllergenNotice"
                                            component={CheckboxInput}
                                            disabled
                                        />
                                    )}
                                </FieldErrors>
                            </EditRow>
                            <EditRow
                                title="Checkout Message Title"
                                titleInfo="(Requires publish)"
                                subTitle="Optional. If left blank, we’ll use ‘Dietary & allergen notice’."
                            >
                                <FieldErrors name="fromTheVenueTitle" form={form}>
                                    {form.values.enableDietaryNotifier ? (
                                        <FastField
                                            name="fromTheVenueTitle"
                                            component={TextInput}
                                            placeholder="Dietary & allergen notice"
                                            maxLength={28}
                                            allowClear
                                            disabled={!hasUpdatePermission}
                                        />
                                    ) : (
                                        <FastField
                                            name="fromTheVenueTitle"
                                            component={TextInput}
                                            placeholder="Dietary & allergen notice"
                                            disabled
                                        />
                                    )}
                                </FieldErrors>
                            </EditRow>
                            <EditRow
                                title="Checkout Message"
                                titleInfo="(Requires publish)"
                                subTitle="Message displayed to user on Review Order screen."
                            >
                                <FieldErrors name="fromTheVenue" form={form}>
                                    {form.values.enableDietaryNotifier ? (
                                        <FastField
                                            name="fromTheVenue"
                                            component={TextArea}
                                            placeholder="Dietary Message"
                                            maxLength="400"
                                            disabled={!hasUpdatePermission}
                                        />
                                    ) : (
                                        <FastField
                                            key="fromTheVenueDisabled"
                                            name="fromTheVenue"
                                            component={TextArea}
                                            placeholder="Dietary Message"
                                            maxLength="400"
                                            disabled
                                        />
                                    )}
                                </FieldErrors>
                            </EditRow>
                            {!form.values.group && hasLocationUpdatePermission && (
                                <>
                                    <Divider />
                                    <EditRow
                                        title="Promotions & Marketing"
                                        subTitle="Enable this feature to allow customers to opt-in to your venues mailing list."
                                    >
                                        <FieldErrors name="marketingOptions.enabled" form={form}>
                                            <FastField name="marketingOptions.enabled" component={CheckboxInput} />
                                        </FieldErrors>
                                    </EditRow>

                                    <EditRow title="Supernova Prompt">
                                        <FieldErrors name="marketingOptions.prompt" form={form}>
                                            {form.values.marketingOptions.enabled ? (
                                                <FastField name="marketingOptions.prompt" component={CheckboxInput} />
                                            ) : (
                                                <FastField
                                                    name="marketingOptions.prompt"
                                                    component={CheckboxInput}
                                                    disabled
                                                />
                                            )}
                                        </FieldErrors>
                                    </EditRow>
                                </>
                            )}
                            {form.values.group && hasGroupEditPermission && (
                                <>
                                    <Divider />
                                    <EditRow
                                        title="Promotions & Marketing"
                                        subTitle={`Enable this feature to allow customers to opt-in to your venues mailing list. NOTE: This will affect all locations in your group (${form.values.group.displayName})`}
                                    >
                                        <FieldErrors name="group.marketingOptions.enabled" form={form}>
                                            <FastField
                                                name="group.marketingOptions.enabled"
                                                component={CheckboxInput}
                                            />
                                        </FieldErrors>
                                    </EditRow>
                                    {hasLocationUpdatePermission && (
                                        <EditRow title="Supernova Prompt">
                                            <FieldErrors name="group.marketingOptions.prompt" form={form}>
                                                {form.values.group.marketingOptions &&
                                                form.values.group.marketingOptions.enabled ? (
                                                    <FastField
                                                        name="group.marketingOptions.prompt"
                                                        component={CheckboxInput}
                                                    />
                                                ) : (
                                                    <FastField
                                                        name="group.marketingOptions.prompt"
                                                        component={CheckboxInput}
                                                        disabled={true}
                                                    />
                                                )}
                                            </FieldErrors>
                                        </EditRow>
                                    )}
                                </>
                            )}

                            {hasLocationFlagUpdatePermission && (
                                <>
                                    <Divider />
                                    <EditRow
                                        title="Product Videos"
                                        subTitle="Enable this feature to add videos to products."
                                    >
                                        <FieldErrors name="enableProductVideos" form={form}>
                                            <FastField name="enableProductVideos" component={CheckboxInput} />
                                        </FieldErrors>
                                    </EditRow>
                                </>
                            )}
                            <Divider />
                            {hasLocationUpdatePermission && absorbMerchantFeeSupported && (
                                <>
                                    <EditRow
                                        title="Absorb Merchant Fees"
                                        subTitle="When enabled, merchant/credit card processing fees will be absorbed by the venue and not passed on to the customer"
                                    >
                                        <FieldErrors name="enableAbsorbMerchantFee" form={form}>
                                            <FastField name="enableAbsorbMerchantFee" component={CheckboxInput} />
                                        </FieldErrors>
                                    </EditRow>
                                    <Divider />
                                </>
                            )}
                            <EditRow
                                title="Facebook Pixel Id"
                                subTitle="Go to your Business facebook Events Manager to get it."
                            >
                                <FieldErrors name="facebookPixelId" form={form}>
                                    <FastField
                                        name="facebookPixelId"
                                        component={TextInput}
                                        placeholder="Facebook Pixel ID"
                                        maxLength={28}
                                        allowClear
                                        disabled={!hasUpdatePermission}
                                    />
                                </FieldErrors>
                            </EditRow>
                            {posSyncSupported && (
                                <EditRow title="POS sync" subTitle="Sync your menu from POS">
                                    <FieldErrors name="posSync" form={form}>
                                        <FastField
                                            name="enablePosSync"
                                            component={CheckboxInput}
                                            placeholder="POS sync enabled"
                                            allowClear
                                            disabled={!hasUpdatePermission}
                                        />
                                    </FieldErrors>
                                </EditRow>
                            )}
                            {venueServiceFeeSettingsAccess !== "off" && (
                                <>
                                    <Divider />
                                    <EditRow
                                        title="me&u Pay Venue Service Fee"
                                        titleInfo="(Requires publish)"
                                        subTitle="Set a fixed percentage per transaction."
                                    >
                                        <Field
                                            name="venueServiceFee.enabled"
                                            component={CheckboxInput}
                                            disabled={venueServiceFeeSettingsAccess === "read-only"}
                                        />
                                    </EditRow>
                                    <EditRow
                                        title="Venue Service Fee Percentage"
                                        subTitle="Fee added to each transaction."
                                    >
                                        <FieldErrors name="venueServiceFee.percentage" form={form}>
                                            <Field
                                                name="venueServiceFee.percentage"
                                                component={PercentageInput}
                                                disabled={venueServiceFeeFieldsDisabled}
                                            />
                                        </FieldErrors>
                                    </EditRow>
                                </>
                            )}
                            {hasLocationUpdatePermission && (
                                <>
                                    <Divider />
                                    <EditRow title="Venue Order Flow">
                                        <FieldErrors name="orderFlow" form={form}>
                                            <OrderFlowSelector
                                                name="orderFlow"
                                                disabled={!hasLocationFlagUpdatePermission}
                                                placeholder="Select order flow"
                                            />
                                        </FieldErrors>
                                    </EditRow>
                                </>
                            )}
                            {showSelectSplitPaymentOptions && (
                                <>
                                    <Divider />
                                    <EditRow title="Split Payment Options">
                                        <FieldErrors name="splitPaymentOptions" form={form}>
                                            <FastField
                                                name="splitPaymentOptions"
                                                component={DataSelectItem}
                                                options={splitPaymentOptions}
                                                placeholder="Select split payment options"
                                                disabled={!hasUpdatePermission}
                                            />
                                        </FieldErrors>
                                    </EditRow>
                                </>
                            )}
                            <ActionFooter align="left">
                                {hasUpdatePermission && (
                                    <>
                                        <Button disabled={!form.dirty} loading={saveStatus === "saving"} type="submit">
                                            Save
                                        </Button>
                                        <StatusMessage />
                                    </>
                                )}
                            </ActionFooter>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

const getValidate =
    (options: ValidationOptions, restaurantLocation: ActiveLocation) => (values: EditableLocationSettings) => {
        return validateYupSchema(values, getEditableLocationSettingsSchema(options), undefined, {
            ...values,
            restaurantLocation,
        }).then(
            () => ({}),
            (err: any) => yupToFormErrors(err)
        );
    };
