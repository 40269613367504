import { accessUISettings } from "./access";
import { bepozUISettings } from "./bepoz";
import { doshiiUISettings } from "./doshii";
import { eposnowUISettings } from "./eposnow";
import { focusUISettings } from "./focus";
import { handlUISettings } from "./handl";
import { handlcloudUISettings } from "./handlcloud";
import { kountaUISettings } from "./kounta";
import { lightspeedLUISettings } from "./lightspeedl";
import { mpowerconnectUISettings } from "./mpowerconnect";
import { omnivoreUISettings } from "./omnivore";
import { taskUISettings } from "./task";
import { tevalisUISettings } from "./tevalis";
import { tyroconnectUISettings } from "./tyroconnect";
import { zonalUISettings } from "./zonal";
import { deliverectUISettings } from "./deliverect";

export interface PosUISettings {
    servicePosId: FieldDisplaySettings;
    surchargeSku: FieldDisplaySettings;
    allowNotes?: FieldDisplaySettings;
    priceListPosId?: FieldDisplaySettings;
    taxPosId?: FieldDisplaySettings;
}

export type FieldDisplaySettings =
    | { visible: false }
    | {
          visible: boolean;
          title: string;
          subTitle: string;
      };

const lookup: {} = {
    access: accessUISettings,
    bepoz: bepozUISettings,
    deliverect: deliverectUISettings,
    doshii: doshiiUISettings,
    eposnow: eposnowUISettings,
    focus: focusUISettings,
    handl: handlUISettings,
    handlcloud: handlcloudUISettings,
    kounta: kountaUISettings,
    lightspeedl: lightspeedLUISettings,
    mpowerconnect: mpowerconnectUISettings,
    omnivore: omnivoreUISettings,
    task: taskUISettings,
    tevalis: tevalisUISettings,
    tyroconnect: tyroconnectUISettings,
    zonal: zonalUISettings,
};

export type PosFieldName = keyof PosUISettings;

export function getPosFieldSettings(postype: string, field: PosFieldName): FieldDisplaySettings | null {
    const posSettings = lookup[postype];

    if (!posSettings) {
        return null;
    }

    const fieldSettings = posSettings[field];

    if (!fieldSettings) {
        return null;
    }

    return fieldSettings;
}
