import { isNew } from "common/utility/modelUtils";
import { createAction } from "../reducers/edit";
import { EditableCourse } from "features/course/types";
import { editCourse, GraphQLCourse } from "../api/editCourse";
import { scaffoldEditAction } from "common/scaffolding/actions/scaffoldEditAction";

export const edit = (locationId: string, courseId: string) =>
    scaffoldEditAction(
        courseId,
        (state) => state.courses.edit,
        createAction,
        async () => {
            if (isNew(courseId)) {
                return newEditableCourse();
            }

            const course = await editCourse(locationId, courseId);
            return mapEditableCourse(course);
        }
    );

const mapEditableCourse = (input: GraphQLCourse): EditableCourse => ({
    id: input.id,
    internalName: input.internalName,
    displayName: input.displayName,
    sku: input.sku,
    categories: (input.categories || []).map((c) => c.id),
});

const newEditableCourse = (): EditableCourse => ({
    internalName: "",
    displayName: "",
    sku: "",
    categories: [],
});
