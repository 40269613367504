import { isLoaded } from "common/loader/isLoaded";
import { LocationRouteParams } from "common/types";
import { AppState } from "features";
import { LocationComponentProps, withLocation } from "features/location";
import { getCrudPermissions } from "features/location/selectors/getCrudPermissions";
import { AppDispatch } from "features/state";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { DataProps, FunctionProps } from "types/utils";
import { QuickSellPage, Props } from "../components/QuickSellPage";
import { PageLoadingProps, withLoader } from "common/loader/withLoader";
import * as locationSettingsActions from "features/locationSettings/actions";
import { saveQuickSellFlag, saveQuickSellDrinksOnlyFlag } from "../actions";
import { actions as serviceActions } from "features/service";
import { getLocationPermissions } from "features/location/selectors/getLocationPermissions";
import { getQuickSellData } from "../actions/getQuickSellData";
import { mergeStatus } from "common/loader";
import { generateDemoQuickSellData } from "../actions/quickSellDemo";

const mapStateToProps = (
    state: AppState,
    { match: { params } }: LocationComponentProps & RouteComponentProps<LocationRouteParams>
): DataProps<PageLoadingProps<Props>> => {
    const {
        LocationSettings: { edit },
        quickSell: { data },
        services: { list: servicesList },
    } = state;

    const overallFetchStatus = mergeStatus(edit.status, data.status, servicesList.status);

    if (isLoaded(edit) && isLoaded(data) && isLoaded(servicesList)) {
        const locationPermissions = getLocationPermissions(state);

        return {
            loadStatus: data.saveStatus === "saving" ? "loading" : "loaded",
            permissions: getCrudPermissions(state).quickSell,
            hasLocationUpdatePermission: locationPermissions.hasAny("location:update"),
            canCreateDemo: locationPermissions.hasAll("mockdata:create"),
            isQuickSellOn: edit.data.enableQuickSell !== false,
            isQuickSellDrinksOnly: !!edit.data.quickSellDrinksOnly,
            summary: data.data,
            services: servicesList.data,
        };
    }

    return {
        loadStatus: overallFetchStatus,
    };
};

const mapDispatchToProps = (
    dispatch: AppDispatch,
    { restaurantLocation, match: { params } }: LocationComponentProps & RouteComponentProps<LocationRouteParams>
): FunctionProps<PageLoadingProps<Props>> => ({
    fetch: () => {
        dispatch(locationSettingsActions.edit(restaurantLocation.id));
        dispatch(serviceActions.list(params.location));
        dispatch(getQuickSellData(restaurantLocation.id));
    },
    handleQuickSellToggle: (on: boolean) => dispatch(saveQuickSellFlag(restaurantLocation.id, on)),
    handleDrinksOnlyQuickSellToggle: (on: boolean) => dispatch(saveQuickSellDrinksOnlyFlag(restaurantLocation.id, on)),
    generateDemoQuickSellData: () => dispatch(generateDemoQuickSellData(restaurantLocation.id)),
});

export const QuickSellPageContainer = withRouter(
    withLocation(connect(mapStateToProps, mapDispatchToProps)(withLoader(QuickSellPage)))
);
