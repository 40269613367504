import styles from "./ListItem.module.scss";

import { Badge } from "core/components/badge";
import { Button } from "core/components/button";
import { createAction } from "../reducers/filters";
import { FiltersModal } from "./FiltersModal";
import { getLocationsFilterCount, getTextFilter } from "../selectors/getFilters";
import { Headers } from "./Headers";
import { Icon } from "core/components/icon";
import { Input } from "core/components/form/input";
import { LocationSummary } from "features/location";
import { ModalRenderer } from "core/components/modal";
import { Search } from "components/icons";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

interface Props {
    pageIndex: number;
    pageItems: LocationSummary[];
    pageSize: number;
}

export const ListHeaderRow = ({ pageIndex, pageItems, pageSize }: Props) => {
    const dispatch = useDispatch();

    const [showFiltersModal, setShowFiltersModal] = useState(false);

    const filterCount = useSelector(getLocationsFilterCount);

    const textFilter = useSelector(getTextFilter);

    const hasItems = pageItems.length > 0;

    const handleTextFilterChange = useCallback(
        (value: string) => {
            dispatch(createAction.setTextFilter(value));
        },
        [dispatch]
    );

    return (
        <>
            <div className={styles.containerHeaderTop}>
                <div className={styles.innerContainerHeaderTop}>
                    <div className={styles.cellGrow}>
                        <Input
                            blockEnter
                            onChange={handleTextFilterChange}
                            value={textFilter}
                            placeholder="Search venues"
                            before={
                                <Icon verticalAlign="middle">
                                    <Search />
                                </Icon>
                            }
                            onClear={() => {
                                handleTextFilterChange("");
                            }}
                        />
                    </div>
                    <div className={styles.cellStatic}>
                        <Button role="secondary" type="button" onClick={() => setShowFiltersModal(true)}>
                            <>
                                {!!filterCount && (
                                    <Badge
                                        textColorScheme="light"
                                        backgroundColorScheme="dark"
                                        shape="circle"
                                        className={styles.badge}
                                    >
                                        {filterCount}
                                    </Badge>
                                )}
                                <span>Filter</span>
                            </>
                        </Button>
                    </div>
                </div>
            </div>
            {hasItems && (
                <div role="rowGroup">
                    <div role="row" className={styles.containerHeaderBottom}>
                        <div className={styles.innerContainer}>
                            <Headers />
                        </div>
                    </div>
                </div>
            )}

            <ModalRenderer target="#modal">
                <FiltersModal
                    visible={showFiltersModal}
                    onClose={() => setShowFiltersModal(false)}
                    onConfirm={() => {}}
                />
            </ModalRenderer>
        </>
    );
};
