import styles from "./EditNestedModifiers.module.scss";
import listStyles from "core/components/list/ListItem.module.scss";

import { AppState } from "features";
import { Confirm, Modal, ModalRenderer } from "core/components/modal";
import { EditableModifier } from "features/modifier/types";
import { EnhancedCatalogueModifierItem } from "features/catalogue";
import { Formik, FormikProps, getIn } from "formik";
import { getEnhancedNonEditModifiers } from "features/catalogue/selectors/getEnhancedNonEditModifers";
import { List, ListItem } from "core/components/list";
import { Nested, Search, Unnested } from "components/icons";
import { useSelector } from "react-redux";
import { Warning } from "core/components/iconWithText";
import { Input } from "core/components/form/input";
import { useState } from "react";
import { EmptyMessage } from "core/components/card/EmptyMessage";
import { normaliseText } from "common/utility/StringUtils";
import { HighlightSearchTerm } from "core/components/highlightSearchTerm";
import { Icon } from "core/components/icon/Icon";
interface Props {
    pathPrefix: string;
    disabled?: boolean;
    form: FormikProps<EditableModifier>;
    visible: boolean;
    onClose: () => void;
}

export const EditNestedModifiers = ({ pathPrefix, disabled, form, visible, onClose }: Props) => {
    const { values, setFieldValue } = form;

    const [filterText, setFilterText] = useState<string>("");

    const modifiers = useSelector<AppState, EnhancedCatalogueModifierItem[]>(getEnhancedNonEditModifiers).filter(
        (modifier) => {
            if (!filterText) {
                return true;
            }

            const searchTerm = normaliseText(filterText.trim());
            const displayName = normaliseText(modifier.displayName || "");
            const internalName = normaliseText(modifier.internalName || "");

            return displayName.includes(searchTerm) || internalName.includes(searchTerm);
        }
    );

    return (
        <ModalRenderer target="#modal">
            {/* Separate Formik instance (from main form) keeps changes in temporary state */}
            <Formik
                onSubmit={() => {}}
                initialValues={{
                    modifiers: getIn(values, `${pathPrefix}.modifiers`) || [],
                }}
                enableReinitialize
            >
                {(form) => (
                    <Modal
                        title={`Select Modifier Groups to nest to ‘${getIn(values, pathPrefix).displayName}’`}
                        visible={visible}
                        onClose={(e, meta) => {
                            if (meta.source === "confirm") {
                                // confirm changes
                                setFieldValue(`${pathPrefix}.modifiers`, form.values.modifiers);
                            } else {
                                // cancel changes
                                form.setValues(form.initialValues);
                            }

                            setFilterText("");
                            form.setTouched({});

                            onClose();
                        }}
                        header={
                            <Input
                                onChange={(value: string) => setFilterText(value)}
                                value={filterText}
                                placeholder="Search modifiers"
                                before={
                                    <Icon verticalAlign="middle">
                                        <Search />
                                    </Icon>
                                }
                            />
                        }
                        footer={<Confirm confirmLabel="Continue" confirmProps={{ disabled: !form.dirty }} />}
                        verticalAlign="top"
                        clickOutsideCloses={false}
                    >
                        <div className={modifiers.length ? styles.listContainer : styles.listContainerEmpty}>
                            {modifiers.length ? (
                                <List className={styles.list}>
                                    {modifiers.map(({ id, displayName, internalName, hasNested }) => (
                                        <ListItem
                                            key={id}
                                            thumb={hasNested ? <Nested /> : <Unnested />}
                                            heading={
                                                <span className={listStyles.heading}>
                                                    <HighlightSearchTerm source={displayName} searchTerm={filterText} />
                                                </span>
                                            }
                                            body={
                                                internalName ? (
                                                    <span>
                                                        <HighlightSearchTerm
                                                            source={internalName}
                                                            searchTerm={filterText}
                                                        />
                                                    </span>
                                                ) : undefined
                                            }
                                            checkable={true}
                                            checkboxFieldProps={{
                                                name: "modifiers",
                                                value: id,
                                                disabled: hasNested || disabled,
                                            }}
                                            details={
                                                hasNested ? (
                                                    <Warning>
                                                        Can’t add a modifier group that contains nested modifiers
                                                    </Warning>
                                                ) : undefined
                                            }
                                            disabled={hasNested}
                                        />
                                    ))}
                                </List>
                            ) : (
                                <EmptyMessage />
                            )}
                        </div>
                    </Modal>
                )}
            </Formik>
        </ModalRenderer>
    );
};
