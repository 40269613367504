import { FeatureConfiguration } from "features";
import { reducer } from "features/catalogue/reducers";
import * as actions from "features/catalogue/actions";
import { withPermissions } from "features/location/components/withPermission";
import { EditComponent, ModalCrudRouteContainer, ListComponent } from "common/scaffolding/components/ModalCrudRoute";
import { ModalEditWrapper } from "common/scaffolding/components/EditWrapper";
import { ListWrapper } from "common/scaffolding/components/ListWrapper";
import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { CataloguePage } from "./components";
import { EditProductPage } from "./components/product/edit/EditProductPage";
import { reducer as productsReducer } from "./reducers/product";
import { EditModifierPage } from "features/modifier/components/EditModifierPage";

export { actions };
export * from "features/catalogue/types";

const catalogueList: ListComponent = {
    route: ":tab(products|modifiers)?",
    component: withPermissions(CataloguePage, "catalogue:read"),
    contentWidth: ContentWidth.STANDARD,
    wrapper: ListWrapper,
};

const productEdit: EditComponent = {
    route: "products/:id",
    component: withPermissions(EditProductPage, "catalogue:read"),
    contentWidth: ContentWidth.NARROW,
    wrapper: ModalEditWrapper,
};

const modifierEdit: EditComponent = {
    route: "modifiers/:id",
    editTitle: "Edit Modifier",
    createTitle: "Create Modifier",
    component: withPermissions(EditModifierPage, "catalogue:read"),
    wrapper: ModalEditWrapper,
    contentWidth: ContentWidth.NARROW,
};

export default function (config: FeatureConfiguration) {
    config.reducers.catalogue = reducer;
    config.reducers.menuItems = productsReducer;

    config.addRoutes(config.routes.locationRoutes, [
        <ModalCrudRouteContainer
            key="catalogue"
            name="catalogue"
            listComponent={catalogueList}
            editComponents={[productEdit, modifierEdit]}
        />,
    ]);
}
