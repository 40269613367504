import styles from "./Modifiers.module.scss";

import { ArrayHelpers, useFormikContext } from "formik";
import { Button } from "core/components/button";
import { CancelBig } from "common/icons";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { EditableProduct } from "features/catalogue/types";
import { Grip } from "common/icons/Grip";
import { Icon } from "core/components/icon";
import { EditableModifier, ModifierSummary } from "features/modifier";
import { Edit } from "common/icons/Edit";
import { Link } from "react-router-dom";
import { Option, Select } from "core/components/form/select";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { simplePlural } from "common/utility/StringUtils";
import classNames from "classnames";
import { LinkedItemBadge } from "../../controlledItem/LinkedItemBadge";
import { FieldErrors } from "core/components/form/fieldErrors";
import { useSelector } from "react-redux";
import { getIsChildLocation } from "features/location/selectors/getIsChildLocation";

interface Props {
    disableFields: boolean;
    onModifierRemoved: (modifier: ModifierSummary | EditableModifier) => void;
}

export const Modifiers = ({ disableFields, move, remove, onModifierRemoved }: Props & ArrayHelpers) => {
    const {
        values: { modifiers, prices },
    } = useFormikContext<EditableProduct>();

    const onDragEnd = (result: DropResult) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        move(result.source.index, result.destination.index);
    };

    const onBeforeDragStart = () => {
        (document.activeElement as any)?.blur();
    };

    const variantOptions: Option[] = prices.map((price, index) => ({
        label: price.displayName || `<Unnamed Variant #${index + 1}>`,
        value: price.id,
    }));

    const handleRemove = useCallback(
        (index: number) => {
            const modifier = modifiers[index];
            remove(index);
            onModifierRemoved(modifier);
        },
        [modifiers, onModifierRemoved, remove]
    );

    const isDragDisabled = modifiers.length === 1;

    return (
        <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onBeforeDragStart}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} className={styles.modifiersList}>
                        {modifiers.map((modifier, index) => (
                            <Modifier
                                disableFields={disableFields}
                                index={index}
                                isDragDisabled={isDragDisabled}
                                key={`${index}`}
                                modifier={modifier}
                                onRemove={handleRemove}
                                variantOptions={variantOptions}
                            />
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

interface ModifierProps {
    disableFields?: boolean;
    index: number;
    isDragDisabled: boolean;
    onRemove: (index: number) => void;
    modifier: EditableModifier;
    variantOptions: Option[];
}

const Modifier = ({
    disableFields,
    index,
    isDragDisabled,
    onRemove,
    modifier: { displayName, id, internalName, required, isLinked },
    variantOptions,
}: ModifierProps) => {
    const {
        values: { prices },
        errors,
        setFieldValue,
        setFieldTouched,
    } = useFormikContext<EditableProduct>();

    const isChildLocation = useSelector(getIsChildLocation);

    const selectCellRef = useRef<HTMLDivElement | null>(null);

    const handleVariantsChange = useCallback(
        (selections) => {
            const selectionValues = selections.map((s: Option) => s.value);

            prices.forEach(({ id: variantId, modifiers }, variantIndex) => {
                const isSelected = selectionValues.includes(variantId);

                const newModifiers = isSelected ? [...modifiers, id] : modifiers.filter((m) => m !== id);

                setFieldValue(`prices.${variantIndex}.modifiers`, [...new Set(newModifiers)]);
                setFieldTouched(`modifiers.${index}`, true);
            });
        },
        [prices, id, setFieldValue, setFieldTouched, index]
    );

    const selectedValues = useMemo(
        () =>
            variantOptions.filter((option) => {
                const { value } = option;

                const variantModifiers = prices.find((price) => price.id === value)?.modifiers || [];

                return variantModifiers.includes(id!);
            }),
        [id, prices, variantOptions]
    );

    const numSelected = selectedValues.length;

    // this is a work around to get the select menu to open/close when clicked and drag & drop enabled
    // otherwise clicks ignored due to an bug in react-select when used within react beautiful dnd
    // @see https://github.com/JedWatson/react-select/issues/5176

    const handleSelectClick = useCallback((e) => {
        const input = e.target.querySelector("input");
        const hasFocus = input === document.activeElement;
        if (!hasFocus) {
            input?.focus();
        } else {
            input?.blur();
        }
    }, []);

    // handle clicks outside when input has focus

    useEffect(() => {
        const listener = (e: any) => {
            const selectCell = selectCellRef.current;
            const input = selectCell?.querySelector("input");
            const hasFocus = input === document.activeElement;

            if (selectCell && input && hasFocus && !selectCell?.contains(e.target)) {
                input.blur();
            }
        };

        document.addEventListener("mousedown", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
        };
    }, []);

    const hasError = !!errors.modifiers?.[index];

    const modifierContainerClasses = classNames({
        [styles.modifierContainer]: true,
        [styles.modifierContainerNoVariants]: !(variantOptions.length > 1),
        [styles.modifierContainerNoDrag]: isDragDisabled,
    });

    return (
        <Draggable draggableId={index.toString()} index={index} isDragDisabled={isDragDisabled || disableFields}>
            {(provided, snapshot) => (
                <div
                    className={snapshot.isDragging ? styles.containerDragging : styles.container}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                    aria-label={`Modifier ${index + 1}`}
                >
                    <div className={styles.modifierOuterContainer}>
                        <div className={modifierContainerClasses}>
                            {!isDragDisabled && (
                                <div className={styles.gripCell}>
                                    <Icon size="small" className={styles.gripIcon}>
                                        <Grip />
                                    </Icon>
                                </div>
                            )}

                            <div className={styles.cellContainer}>
                                <div className={styles.nameCell}>
                                    <div className={styles.nameCellPrimary}>
                                        {isLinked && isChildLocation && <LinkedItemBadge />}
                                        <span className={styles.nameCellPrimaryName}>{displayName}</span>
                                        {required && <span className={styles.required}>*</span>}
                                    </div>
                                    {internalName && <div className={styles.nameCellSecondary}>{internalName}</div>}
                                </div>
                                {variantOptions.length > 1 && (
                                    <div
                                        className={styles.selectCell}
                                        ref={selectCellRef}
                                        onClickCapture={isDragDisabled ? undefined : handleSelectClick}
                                    >
                                        <Select
                                            blurInputOnSelect={false}
                                            closeMenuOnSelect={false}
                                            controlShouldRenderValue={false}
                                            disabled={disableFields}
                                            forceHasError={hasError}
                                            isMulti
                                            menuPlacement="auto"
                                            options={variantOptions}
                                            onChange={handleVariantsChange}
                                            openMenuOnClick={isDragDisabled}
                                            openMenuOnFocus={!isDragDisabled}
                                            placeholder={
                                                numSelected === prices.length
                                                    ? "All variants"
                                                    : numSelected === 1
                                                    ? variantOptions.find(
                                                          (option) => option.value === selectedValues[0].value
                                                      )?.label
                                                    : `${numSelected} variant${simplePlural(numSelected)}`
                                            }
                                            value={selectedValues}
                                            aria-label={`Select variants for modifier ${displayName}`}
                                        />
                                    </div>
                                )}
                                <div className={styles.iconCell}>
                                    <Button
                                        as={Link}
                                        to={`../modifiers/${id}`}
                                        className={styles.iconButton}
                                        role="secondary"
                                        padding="icon"
                                        aria-label={`Edit modifier ${displayName}`}
                                        disabled={disableFields}
                                    >
                                        <Icon size="tiny">
                                            <Edit />
                                        </Icon>
                                    </Button>
                                </div>
                                <div className={styles.iconCell}>
                                    <Button
                                        className={styles.iconButton}
                                        role="secondary"
                                        padding="icon"
                                        disabled={disableFields}
                                        onClick={() => onRemove(index)}
                                        type="button"
                                        aria-label={`Remove modifier ${displayName}`}
                                    >
                                        <Icon size="tiny">
                                            <CancelBig />
                                        </Icon>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {hasError && (
                            <div className={styles.errorContainer}>
                                <FieldErrors fieldNames={[`modifiers.${index}`]} />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </Draggable>
    );
};
