import { CatalogueItem } from "../../catalogue/types/CatalogueProduct";
import { RouteComponentProps } from "react-router-dom";
import { useState } from "react";
import { ExtendedPermissions } from "features/location/types/ExtendedPermissions";
import { CatalogueHeader } from "./CatalogueHeader";
import { Divider } from "antd";
import { CatalogueListing } from "./CatalogueListing";

export interface Props {
    modifiers: CatalogueItem[];
    permissions: ExtendedPermissions;
    handleSetPagingAvailable: (hasPaging: boolean) => void;
    initialStatuses?: string[];
    exportData?: () => void;
}

const pageSize = 5;

export const ModifiersTab = ({
    modifiers,
    permissions,
    match,
    history,
    location,
    handleSetPagingAvailable,
    exportData,
}: Props & RouteComponentProps<any>) => {
    const [textFilter, setTextFilter] = useState<string>("");

    const handleSearch = (searchText: string) => {
        setTextFilter(searchText);
    };

    return (
        <>
            <CatalogueHeader
                parentRecordText="modifier"
                onTextFilter={handleSearch}
                permissions={permissions}
                exportData={exportData}
            />
            <CatalogueListing
                items={modifiers}
                permissions={permissions}
                showCategories={false}
                pageSize={pageSize}
                match={match}
                history={history}
                location={location}
                parentRecordText="modifier"
                textFilter={textFilter}
                setPagingAvailable={handleSetPagingAvailable}
            />
            <Divider style={{ visibility: "hidden" }} />
        </>
    );
};
