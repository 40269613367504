import styles from "./CmsHeader.module.scss";

import { Badge } from "core/components/badge";
import { Link } from "react-router-dom";
import { LocationContext } from "features/location";
import { LocationRouteParams } from "common/types";
import { LocationSelectorContainer } from "features/location/containers/LocationSelectorContainer";
import { MeanduLogo } from "common/icons";
import { MenuButton } from "../nav/MenuButton";
import { MenuContext } from "../nav/MenuContainer";
import { PublishButtonContainer, PublishErrorsContainer } from "./publishButton";
import { RegionSelectorContainer } from "features/region/containers/RegionSelectorContainer";
import { useContext, useRef } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { getIsParentLocation } from "features/location/selectors/getIsParentLocation";
import { getIsChildLocation } from "features/location/selectors/getIsChildLocation";
import { UpdateButton } from "./updateButton/UpdateButton";
import { getLocationPermissions } from "features/location/selectors/getLocationPermissions";
import { LocationListProvider } from "features/location/context/LocationListContext";
import { useMedia } from "common/hooks";

export interface Props {
    posMenuSupported?: boolean;
}

export const CmsHeader = ({ posMenuSupported }: Props) => {
    const restaurantLocation = useContext(LocationContext);
    const { location, region } = useParams<LocationRouteParams>();
    const { isDemo } = restaurantLocation || {};
    const isParentLocation = useSelector(getIsParentLocation);
    const isChildLocation = useSelector(getIsChildLocation);
    const redirectUrl = posMenuSupported && !isParentLocation ? "sections" : "menu/catalogue/products";

    const permissions = useSelector(getLocationPermissions);
    const canImportParent = permissions.has("location:child:menudata:import");

    const mobileContainerRef = useRef<HTMLDivElement | null>(null);
    const { setMenuActive } = useContext(MenuContext);

    const isLarge = useMedia("(min-width: 896px)");

    const handleMenuContainerClick = (e: React.MouseEvent<HTMLElement>) => {
        if (e.target === mobileContainerRef.current) {
            e.stopPropagation();
            setMenuActive(false);
        }
    };

    return (
        <LocationListProvider>
            <div className={styles.root}>
                <div className={styles.menu}>
                    <MenuButton />
                </div>
                <div className={styles.logoContainer}>
                    <Link to={`/${region}/${location}/${redirectUrl}`}>
                        <span className={styles.logo}>
                            <MeanduLogo />
                        </span>
                    </Link>
                </div>
                {isLarge && (
                    <div className={styles.locationLargeScreens}>
                        <LocationSelectorContainer />
                    </div>
                )}
                {(isDemo || isParentLocation) && (
                    <div className={styles.badges}>
                        {isParentLocation && <Badge backgroundColorScheme="mid">Brand</Badge>}
                        {isDemo && (
                            <Badge>
                                <span>
                                    <span>Demo</span>
                                    <span className="hide-until-medium"> mode</span>
                                </span>
                            </Badge>
                        )}
                    </div>
                )}
                <div className={styles.publish}>
                    {isLarge && (
                        <div className={styles.regionLargeScreens}>
                            <RegionSelectorContainer />
                        </div>
                    )}
                    {isChildLocation && canImportParent && <UpdateButton />}
                    {location && !isParentLocation && (
                        <>
                            <PublishButtonContainer />
                            <PublishErrorsContainer />
                        </>
                    )}
                </div>
            </div>
            {!isLarge && (
                <div
                    className={styles.menuContainerSmallScreens}
                    onClick={handleMenuContainerClick}
                    ref={mobileContainerRef}
                >
                    <div className={styles.menuSmallScreens}>
                        <div className={styles.locationSmallScreens}>
                            <LocationSelectorContainer />
                        </div>
                        <div>
                            <RegionSelectorContainer />
                        </div>
                    </div>
                </div>
            )}
        </LocationListProvider>
    );
};
