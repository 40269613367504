import styles from "./Tabs.module.scss";

import { PropsWithChildren } from "react";
import classNames from "classnames";

// export with children for typings in Tabs which clones element
export interface TabPanelProps extends PropsWithChildren<any> {
    id: string;
    active?: boolean;
    className?: string;
    label: string;
}

export const TabPanel = ({ active, children, className, id }: TabPanelProps) => {
    return (
        <div
            id={`${id}-tabpanel`}
            role="tabpanel"
            aria-labelledby={id}
            className={classNames(active && styles.tabPanel, !active && styles.tabPanelHidden, className)}
        >
            {children}
        </div>
    );
};
