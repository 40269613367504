import { isLoaded } from "common/loader/isLoaded";
import { createAction } from "features/location/reducers/publish";
import { LocationRouteParams } from "common/types";
import { AppState } from "features";
import { LocationComponentProps, withLocationFeature } from "features/location";
import { getCrudPermissions } from "features/location/selectors/getCrudPermissions";
import { AppDispatch } from "features/state";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { DataProps, FunctionProps } from "types/utils";
import { PageLoadingProps, withLoader } from "common/loader/withLoader";
import * as locationSettingsActions from "features/locationSettings/actions";
import { edit, saveSurchargesFlag, save } from "../actions";
import { mergeStatus } from "common/loader";
import { Props, SurchargesPage } from "../components/SurchargesPage";
import { getLocationPermissions } from "features/location/selectors/getLocationPermissions";
import { EditableSurcharge } from "../types";
import { getPosFieldSettings } from "components/forms/posUI/posSettings";
import { getNow } from "features/service/selectors/getVenueTime";
import { getOneTimeSurchargesWithLive } from "../selectors/getActiveSurcharges";

const mapStateToProps = (
    state: AppState,
    {
        restaurantLocation: { postype, timeZone, enableSurcharges },
        match: { params },
    }: LocationComponentProps & RouteComponentProps<LocationRouteParams>
): DataProps<PageLoadingProps<Props>> => {
    const {
        surcharges: { edit: surchargeEdit },
    } = state;

    const overallFetchStatus = mergeStatus(surchargeEdit.status);

    if (isLoaded(surchargeEdit)) {
        const locationPermissions = getLocationPermissions(state);
        const fieldSettings = getPosFieldSettings(postype || "", "surchargeSku");

        return {
            loadStatus: "loaded",
            saveStatus: surchargeEdit.saveStatus,
            permissions: getCrudPermissions(state).surcharges,
            hasUpdatePermission: locationPermissions.hasAny("surcharges:update"),
            surchargesEnabled: enableSurcharges,
            initialRecurringSurcharges: surchargeEdit.data.recurringSurcharges,
            initialOneTimeSurcharges: getOneTimeSurchargesWithLive(state),
            previousSurcharges: surchargeEdit.data.previousSurcharges,
            surchargeIdColumn: fieldSettings && fieldSettings.visible ? fieldSettings.title : undefined,
            venueTime: getNow(state),
        };
    }

    return {
        loadStatus: overallFetchStatus,
    };
};

const mapDispatchToProps = (
    dispatch: AppDispatch,
    { restaurantLocation, match: { params } }: LocationComponentProps & RouteComponentProps<LocationRouteParams>
): FunctionProps<PageLoadingProps<Props>> => ({
    fetch: () => {
        dispatch(locationSettingsActions.edit(restaurantLocation.id));
        dispatch(edit(restaurantLocation.id));
    },
    onToggleDisablePublish: (disabled: boolean) => dispatch(createAction.setDisabled(disabled)),
    onSurchargesToggle: (enabled: boolean) => {
        dispatch(saveSurchargesFlag(restaurantLocation.id, enabled));
        dispatch(createAction.setDisabled(false));
    },
    onSubmit: (updatedSurcharges: EditableSurcharge[], oneTimeSurcharges: EditableSurcharge[]) => {
        dispatch(save(restaurantLocation.id, updatedSurcharges, oneTimeSurcharges));
        dispatch(createAction.setDisabled(false));
    },
});

export const SurchargesPageContainer = withRouter(
    withLocationFeature(
        "enableSurchargesFeature",
        connect(mapStateToProps, mapDispatchToProps)(withLoader(SurchargesPage))
    )
);
