import { createSelector } from "reselect";
import { CatalogueItem } from "features/catalogue/types";
import { getProductCatalogueItems } from "features/catalogue/selectors/getProductCatalogueItems";
import { TreeItem } from "common/types/TreeItem";
import { FilterCategory } from "features/catalogue/types/FilterCategory";
import { getCategoryFilters } from "features/catalogue/selectors/getCategoryFilters";

const productsHaveCategory = (products: CatalogueItem[], category: FilterCategory): boolean => {
    return products.some(({ categories }) => categories?.some(({ id }) => id === category.id)) || false;
};

const mapDataToTree = (categories: FilterCategory[], products: CatalogueItem[]) => {
    const nodes: TreeItem[] = [
        root("drinks", "Drinks", "drink", categories, products),
        root("food", "Food", "food", categories, products),
    ];
    return nodes;
};

// create category root e.g food/drinks
const root = (
    id: string,
    displayName: string,
    type: string,
    categories: FilterCategory[],
    products: CatalogueItem[]
): TreeItem => {
    return {
        id,
        displayName,
        children: categories
            .filter((c) => c.type === type && productsHaveCategory(products, c))
            .map((c) => {
                return {
                    id: c.id,
                    displayName: c.displayName,
                    title: "",
                    type: "category",
                    internalName: c.internalName,
                    key: c.id,
                    children: mapProductsToTree(products, c.id),
                };
            }),
        title: "",
        type: "root",
        key: id,
    };
};

const mapProductsToTree = (items: CatalogueItem[], parentId: string): TreeItem[] =>
    items
        .filter((p) => p.categories && p.categories.some((c) => c.id === parentId))
        .map((p) => {
            return {
                displayName: p.displayName,
                id: p.id!,
                isLeaf: true,
                title: "",
                type: "product",
                internalName: p.internalName,
                key: `${parentId}.${p.id}`,
            };
        });

export const getProductTree = createSelector(
    getCategoryFilters,
    getProductCatalogueItems,
    (categories, products): TreeItem[] => {
        return mapDataToTree(categories, products);
    }
);
