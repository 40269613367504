import { useCallback, useState } from "react";
import { ConfirmModal } from "common/scaffolding/components/ConfirmModal/ConfirmModal";
import { CrudPermissions } from "../../../features/location/types/createCrudPermissions";
import { GroupTabsIntro } from "./GroupTabsIntro";
import { GroupTabsListPackages } from "./GroupTabsListPackages";
import { LastUpdated } from "components/lastUpdated/LastUpdated";
import { LocationLocaleContext } from "features/location";
import { PackageSummary, Stats } from "../types";
import { PageTitle } from "common/scaffolding/components/DataListingPage/PageTitle";
import { RouteComponentProps } from "react-router";
import { GroupTabsStats } from "./GroupTabsStats";
import { Divider } from "antd";
import "./GroupTabsIntro.scss";
import "./GroupTabsPage.scss";

export interface Props {
    permissions: CrudPermissions;
    hasLocationUpdatePermission: boolean;
    groupTabsEnabled: boolean;
    handleGroupTabsToggle: (enabled: boolean) => void;
    packages?: PackageSummary[];
    stats: Stats | undefined;
    supportsPackages: boolean;
}

export const GroupTabsPage = ({
    groupTabsEnabled,
    handleGroupTabsToggle,
    hasLocationUpdatePermission,
    packages,
    permissions,
    stats,
    supportsPackages,
    ...rest
}: Props & RouteComponentProps<any>) => {
    const [blockNavigation, setBlockNavigation] = useState(false);
    const [toggleValue, setToggleValue] = useState(groupTabsEnabled);

    const handleClick = () => {
        if (toggleValue) {
            setBlockNavigation(true);
        } else {
            handleToggleChange();
        }
    };

    const handleCloseModal = () => {
        setBlockNavigation(false);
    };

    const handleToggleChange = useCallback(() => {
        setBlockNavigation(false);
        handleGroupTabsToggle(!toggleValue);
        setToggleValue(!toggleValue);
    }, [handleGroupTabsToggle, toggleValue]);

    const showIntro = !groupTabsEnabled && (!packages || !packages.length) && !stats;

    return (
        <LocationLocaleContext.Consumer>
            {(locale) => (
                <div className="group-tabs-page">
                    <div className="group-tabs-page__content">
                        <PageTitle
                            title="Group Tabs"
                            description={
                                supportsPackages
                                    ? "Group Tabs are perfect for group ordering! Customers can create a Group Tab, invite friends to join, and select a specific Group Tab menu."
                                    : "Group Tabs are perfect for group ordering! Customers can create a Group Tab and invite friends to join."
                            }
                            disabled={!hasLocationUpdatePermission}
                            toggleState={toggleValue}
                            onToggleClick={handleClick}
                            additionalInfo={
                                !showIntro && stats ? (
                                    <LastUpdated dateUpdated={stats.dateUpdated} locale={locale} />
                                ) : undefined
                            }
                        />

                        {showIntro && <GroupTabsIntro />}

                        {!showIntro && (
                            <>
                                <GroupTabsStats stats={stats} groupTabsEnabled={groupTabsEnabled} />
                                {supportsPackages && (
                                    <>
                                        <Divider />
                                        <GroupTabsListPackages
                                            packages={packages}
                                            groupTabsEnabled={groupTabsEnabled}
                                            permissions={permissions}
                                            {...rest}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </div>

                    {blockNavigation && (
                        <ConfirmModal
                            showModal={blockNavigation}
                            title="Are you sure you want to disable Group Tabs?"
                            subTitle="Customers won’t be able to use Group Tabs until you enable this feature again."
                            isPrompt={blockNavigation}
                            cancelText="Cancel"
                            confirmText="Disable"
                            onConfirm={handleToggleChange}
                            onCloseModal={handleCloseModal}
                        />
                    )}
                </div>
            )}
        </LocationLocaleContext.Consumer>
    );
};
