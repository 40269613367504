import { FeatureConfiguration } from "..";
import { Route } from "react-router";
import { withPermissions } from "../location/components/withPermission";
import { StripeAccountPage } from "./components/StripeAccountPage";
import { withLocationFeature } from "features/location";
import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { EditWrapper } from "common/scaffolding/components/EditWrapper";
import { reducer } from "features/stripe/reducers";

const Component = withLocationFeature("enableStripeOnboarding", withPermissions(StripeAccountPage, "stripe:read"));

export default function (config: FeatureConfiguration) {
    config.reducers.stripe = reducer;

    config.addRoutes(config.routes.locationRoutes, [
        <Route
            path={"/:region/:location/stripe"}
            key="stripe"
            render={(props) => (
                <EditWrapper contentWidth={ContentWidth.NARROW}>
                    <Component {...props} />
                </EditWrapper>
            )}
        />,
    ]);
}
