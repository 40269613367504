import { createAction } from "../reducers/list";
import { fetchPosCatalogue as fetchPosCatalogueApi } from "../api/fetchPosCatalogue";
import { AppDispatch, AppState } from "features/state";

export const fetchPosCatalogue = (location: string) => async (dispatch: AppDispatch, getState: () => AppState) => {
    if (getState().posCatalogue.list.status === "loading") {
        return;
    }

    dispatch(createAction.loading());

    try {
        const result = await fetchPosCatalogueApi(location);

        dispatch(createAction.loaded(result));
    } catch (e) {
        dispatch(createAction.failed(e));
    }
};
