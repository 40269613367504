export const emptyStripeAccount = (): GraphQLStripeAccount => ({
    chargesEnabled: false,
    payoutsEnabled: false,
    detailsSubmitted: false,
});

export type GraphQLPayload = { stripeAccount: GraphQLStripeAccount };

export interface GraphQLStripeAccount {
    accountId?: string;
    chargesEnabled: boolean;
    payoutsEnabled: boolean;
    detailsSubmitted: boolean;
    accountCreationLink?: GraphQLStripeAccountLink;
    paymentStatementDescriptor?: string;
    payoutStatementDescriptor?: string;
}

export interface GraphQLStripeAccountLink {
    url?: string;
    createdAt: Date;
    expiresAt: Date;
}
