import { TipValue } from "./types";

export const toPercentage = (input: number) => parseFloat((input * 100).toFixed(1));

export const fromPercentage = (input: TipValue) => {
    if (input === null || input === undefined) {
        return 0;
    }

    input = typeof input === "string" ? parseFloat(input) : input;

    return input / 100;
};

export const hasDefinedValue = (values: TipValue[] | null) => {
    if (!values || !values.length) {
        return false;
    }

    for (let i = 0; i < values.length; i++) {
        if (values[i] !== null && values[i] !== undefined) {
            return true;
        }
    }

    return false;
};
