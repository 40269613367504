import { DownloadOutlined } from "@ant-design/icons";
import { Col, Button } from "antd";
import Search from "antd/lib/input/Search";
import { ExtendedPermissions } from "features/location/types/ExtendedPermissions";
import React, { useRef } from "react";
import { CheckboxFilter, Option } from "common/scaffolding/components/CheckboxFilter";
import { FilterCategory } from "../../catalogue/types/FilterCategory";

export interface Props {
    categories?: FilterCategory[];
    permissions: ExtendedPermissions;
    parentRecordText: string;
    onTextFilter: (text: string) => void;
    onCategoryFilter?: (categories: string[]) => void;
    exportData?: () => void;
}

export const CatalogueHeader = ({
    parentRecordText,
    categories,
    onTextFilter,
    onCategoryFilter,
    exportData,
}: Props) => {
    const filterCategoryOptions = useRef<Option[]>(
        categories ? categories.map((c) => ({ id: c.id, displayName: c.displayName, displayGroup: c.type })) : []
    );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = e.target.value.trim();
        onTextFilter(searchTerm);
    };

    const handleFilterSelect = (options: Set<Option>) => {
        const categories = Array.from(options).map((o) => o.id);
        onCategoryFilter && onCategoryFilter(categories);
    };

    return (
        <Col md={24}>
            <div className="header-row">
                <Search
                    placeholder={`Search ${parentRecordText}s`}
                    allowClear
                    onChange={handleSearch}
                    className="catalogue-search"
                />
                {filterCategoryOptions && !!filterCategoryOptions.current.length && (
                    <CheckboxFilter
                        options={filterCategoryOptions.current}
                        placeholder="Filter by categories"
                        onSelect={handleFilterSelect}
                        title="Category"
                    />
                )}
                {exportData && (
                    <Button type="default" onClick={exportData} className="export-csv-btn">
                        <DownloadOutlined />
                        Export CSV
                    </Button>
                )}
            </div>
        </Col>
    );
};
