import { AppState, AppDispatch } from "features/state";
import { createAction } from "../reducers/active";
import moment from "moment-timezone";

export const setLastItemUpdated = (timeUtc?: string) => (dispatch: AppDispatch, getState: () => AppState) => {
    const location = getState().locations.active;

    if (location.status !== "loaded") {
        return;
    }

    if (!timeUtc) {
        const now = moment().tz(location.data.timeZone || "Australia/Sydney");
        timeUtc = now.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    }

    dispatch(createAction.setLastItemUpdated(timeUtc));
};
