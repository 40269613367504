import * as React from "react";
import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { PageTitle } from "common/scaffolding/components/DataListingPage/PageTitle";
import "./PosCataloguePage.scss";
import { ModifiersContainer } from "../containers/ModifiersContainer";
import { ProductsContainer } from "../containers/ProductsContainer";
import classNames from "classnames";
import { ExtendedPermissions } from "features/location/types/ExtendedPermissions";

const { TabPane } = Tabs;

export interface Props {
    permissions: ExtendedPermissions;
    urlTab?: string;
    query: string;
    changeTab(tab: string): void;
}

export enum TabKeys {
    Products = "products",
    Modifiers = "modifiers",
}

export const PosCataloguePage = ({ permissions, urlTab, changeTab, query }: Props) => {
    const [tabChanged, setTabChanged] = useState(!urlTab);
    const [activeTab, setActiveTab] = useState(urlTab || TabKeys.Products);
    const [noPaging, setNoPaging] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const changeTabCallback = React.useCallback(changeTab, []);

    useEffect(
        function () {
            if (activeTab !== urlTab) {
                if (tabChanged) {
                    changeTabCallback(activeTab);
                } else {
                    setActiveTab(urlTab!);
                }
            }

            setTabChanged(false);
        },
        [activeTab, urlTab, changeTabCallback, tabChanged]
    );

    const handleChange = (activeKey: string) => {
        setActiveTab(activeKey);
        setTabChanged(true);
    };

    const handleSetPagingAvailable = (hasPaging: boolean) => {
        setNoPaging(!hasPaging);
    };

    return (
        <div className="poscatalogue-wrapper">
            <PageTitle title="POS Catalogue" description="" />
            <Tabs
                activeKey={activeTab}
                onChange={handleChange}
                destroyInactiveTabPane={true}
                className={classNames("catalogue-tab-content", noPaging && "no-paging")}
                animated={false}
            >
                <TabPane tab="Products" key={TabKeys.Products}>
                    <ProductsContainer permissions={permissions} handleSetPagingAvailable={handleSetPagingAvailable} />
                </TabPane>
                <TabPane tab="Modifiers" key={TabKeys.Modifiers}>
                    <ModifiersContainer permissions={permissions} handleSetPagingAvailable={handleSetPagingAvailable} />
                </TabPane>
            </Tabs>
        </div>
    );
};
