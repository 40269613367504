import { withSvg } from "core/components/svg/Svg";

export const DownloadSvg = withSvg(
    16,
    16
)(() => (
    <>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.01245 1.01685C7.15545 1.01685 6.3211 1.29209 5.63233 1.80203C4.94355 2.31198 4.43677 3.02966 4.18663 3.84934C3.93649 4.66903 3.95622 5.54738 4.24292 6.355C4.52963 7.16263 5.06813 7.85682 5.77912 8.33531C6.00821 8.48949 6.06894 8.8002 5.91476 9.02929C5.76058 9.25838 5.44988 9.31911 5.22078 9.16493C4.33205 8.56681 3.65892 7.69907 3.30054 6.68954C2.94217 5.68001 2.9175 4.58207 3.23017 3.55747C3.54284 2.53286 4.17633 1.63576 5.0373 0.998332C5.89827 0.360903 6.9412 0.0168457 8.01245 0.0168457C9.0837 0.0168457 10.1266 0.360903 10.9876 0.998332C11.8486 1.63576 12.4821 2.53286 12.7947 3.55747C13.1074 4.58207 13.0827 5.68001 12.7244 6.68954C12.366 7.69907 11.6928 8.56681 10.8041 9.16493C10.575 9.31911 10.2643 9.25838 10.1101 9.02929C9.95596 8.8002 10.0167 8.48949 10.2458 8.33531C10.9568 7.85682 11.4953 7.16263 11.782 6.355C12.0687 5.54738 12.0884 4.66903 11.8383 3.84934C11.5881 3.02966 11.0814 2.31198 10.3926 1.80203C9.7038 1.29209 8.86945 1.01685 8.01245 1.01685Z"
            fill="#4D5358"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00001 5.5C8.27615 5.5 8.50001 5.72386 8.50001 6V13.885L10.3683 11.677C10.5467 11.4662 10.8622 11.4399 11.073 11.6183C11.2838 11.7967 11.3101 12.1122 11.1317 12.323L8.38395 15.5703C8.36924 15.5879 8.35334 15.6045 8.33638 15.6199C8.24756 15.7007 8.12954 15.75 8.00001 15.75C7.87048 15.75 7.75246 15.7007 7.66364 15.6199C7.64668 15.6045 7.63078 15.5879 7.61607 15.5703L4.86832 12.323C4.68995 12.1122 4.71624 11.7967 4.92704 11.6183C5.13784 11.4399 5.45333 11.4662 5.6317 11.677L7.50001 13.885V6C7.50001 5.72386 7.72387 5.5 8.00001 5.5Z"
            fill="#4D5358"
        />
    </>
));
