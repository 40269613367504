import { CatalogueWarnings } from "../CatalogueWarnings";
import { getProductCatalogueItems } from "features/catalogue/selectors/getProductCatalogueItems";
import { getProductsWithSyncFailed } from "features/catalogue/selectors/getCatalogueItemsWithSyncFailed";
import { getProductsWithSyncSuccess } from "features/catalogue/selectors/getCatalogueItemsWithSyncSuccess";
import { useSelector } from "react-redux";

const WarningsContainer = () => {
    const items = useSelector(getProductCatalogueItems);
    const itemsWithSyncFailed = useSelector(getProductsWithSyncFailed);
    const itemsWithSyncSuccess = useSelector(getProductsWithSyncSuccess);

    return (
        <CatalogueWarnings
            collectionName="products"
            items={items}
            itemsWithSyncFailed={itemsWithSyncFailed}
            itemsWithSyncSuccess={itemsWithSyncSuccess}
        />
    );
};

export const Warnings = WarningsContainer;
