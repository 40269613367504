import { opsApi } from "API";
import { isNew } from "common/utility/modelUtils";
import { TaxClassSummary, EditableTaxClass } from "../types";

export const saveTaxClass = async (
    locationId: string,
    taxClassId: string | undefined,
    taxClassData: EditableTaxClass
) => {
    const isCreate = isNew(taxClassId);

    // remove id ('new') if we are creating a new tax
    const data = {
        ...taxClassData,
        ...(isCreate ? { id: "" } : null),
    };

    try {
        const res = isCreate
            ? (
                  await opsApi.graphQLQuery<{ createTaxClass: TaxClassSummary }>(createQuery, {
                      locationId,
                      taxClassUpdateInput: data,
                  })
              ).data.createTaxClass
            : (
                  await opsApi.graphQLQuery<{ updateTaxClass: TaxClassSummary }>(updateQuery, {
                      locationId,
                      taxClassId,
                      taxClassUpdateInput: data,
                  })
              ).data.updateTaxClass;

        return res;
    } catch (e) {
        throw e;
    }
};

const updateQuery = `
    mutation ($locationId: ID!, $taxClassId: ID!, $taxClassUpdateInput: TaxClassUpdateInput!) {
        updateTaxClass(locationId: $locationId,id: $taxClassId, data: $taxClassUpdateInput) {
            id
            displayName
            internalName
            taxType
            taxRate
            posTaxId
            applyToAll
        }
    }
`;

const createQuery = `
    mutation ($locationId: ID!, $taxClassUpdateInput: TaxClassUpdateInput!) {
        createTaxClass(locationId: $locationId, data: $taxClassUpdateInput) {
            id
            displayName
            internalName
            taxType
            taxRate
            posTaxId
            applyToAll
        }
    }
`;
