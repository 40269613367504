import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { EditCategoryPage, Props } from "features/category/components/EditCategoryPage";
import { actions as catalogueActions } from "features/catalogue";
import { actions as menuActions } from "features/menu";
import { actions as serviceActions } from "features/service";
import * as actions from "features/category/actions";
import { EditableCategory } from "features/category/types";
import { mergeStatus } from "common/loader";
import { withLoader, PageLoadingProps } from "common/loader/withLoader";
import { AppState } from "features";
import { getCrudPermissions } from "features/location/selectors/getCrudPermissions";
import { isLoaded } from "common/loader/isLoaded";
import { FunctionProps, DataProps } from "types/utils";
import { withLocation } from "features/location";

const mapStateToProps = (
    state: AppState,
    { match: { params } }: RouteComponentProps<RouteParams>
): DataProps<PageLoadingProps<Props>> => {
    const {
        categories: { edit },
        menuItems: { list: menuItemsList },
        services: { list: servicesList },
        menus: { list: menuList },
    } = state;

    const overallFetchStatus = mergeStatus(edit.status, menuList.status, menuItemsList.status, servicesList.status);

    if (isLoaded(edit) && isLoaded(menuItemsList) && isLoaded(menuList) && isLoaded(servicesList)) {
        return {
            loadStatus: overallFetchStatus,
            saveStatus: edit.saveStatus === "saved" ? "saving" : edit.saveStatus,
            initialValues: edit.data,
            services: servicesList.data,
            menus: menuList.data,
            menuItems: menuItemsList.data,
            permissions: getCrudPermissions(state).menudata,
        };
    }

    return {
        loadStatus: overallFetchStatus,
    };
};

interface RouteParams {
    location: string;
    type: string;
    id: string;
}

const mapDispatchToProps = (
    dispatch: any,
    { match: { params } }: RouteComponentProps<RouteParams>
): FunctionProps<PageLoadingProps<Props>> => ({
    fetch: () => {
        dispatch(actions.edit(params.location, params.id));
        dispatch(catalogueActions.listProducts(params.location));
        dispatch(menuActions.list(params.location));
        dispatch(serviceActions.list(params.location));
    },
    onSubmit: (data: EditableCategory, refreshMenuItems: boolean) =>
        dispatch(actions.save(params.location, data, false, refreshMenuItems)),
    onClone: (data: EditableCategory, refreshMenuItems: boolean) =>
        dispatch(actions.save(params.location, data, true, refreshMenuItems)),
    onArchive: () => dispatch(actions.archive(params.location)),
});

export const EditCategoryPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withLoader(withLocation(EditCategoryPage)));
