import { opsApi } from "API";
import { GraphQLItem } from "../../../common/scaffolding/types";

export const listWaiters = async (locationId: string) => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId });
    return res.data.staffMembers;
};

type GraphQLPayload = { staffMembers: GraphQLWaiterSummary[] };

export interface GraphQLWaiterSummary extends GraphQLItem {
    pin: string;
}

const query = `
    query($locationId: ID!) { 
        staffMembers(locationId: $locationId) { 
            id, 
            displayName, 
            pin 
        }
    }
`;
