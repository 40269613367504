import { opsApi } from "API";

export async function archiveService(location: string, serviceId: string): Promise<void> {
    await opsApi.graphQLQuery(query, { location, serviceId });
}

const query = `
    mutation($location: ID!, $serviceId: ID!) {
        deleteService(locationId: $location, id: $serviceId) {
            id
        }
    }
`;
