import styles from "./Confirm.module.scss";
import { Button, Props as ButtonProps } from "core/components/button";
import { ModalContext, CloseEvent, CloseMeta } from ".";
import { MouseEvent, PropsWithChildren, ReactNode, useCallback, useContext } from "react";

export interface ConfirmProps {
    cancelLabel?: ReactNode;
    confirmLabel?: ReactNode;
    cancelProps?: PropsWithChildren<ButtonProps & Record<string, any>>;
    confirmProps?: PropsWithChildren<ButtonProps & Record<string, any>>;
    onCancel?: (event: CloseEvent) => boolean | void;
    onConfirm?: (event: CloseEvent) => Promise<boolean | void> | boolean | void;
    showCancel?: boolean;
    showConfirm?: boolean;
}

type confirmFn = ((event: CloseEvent) => Promise<boolean | void> | boolean | void) | undefined;

const confirmAndOrClose = async (
    event: CloseEvent,
    meta: CloseMeta,
    fn: confirmFn,
    onClose: (event: CloseEvent, meta: CloseMeta) => void
) => {
    if (!fn) {
        onClose(event, meta);
        return;
    }

    const proceed = await Promise.resolve(fn(event));

    if (proceed !== false) {
        onClose(event, meta);
    }
};

export const Confirm = ({
    cancelLabel = "Cancel",
    confirmLabel = "Ok",
    cancelProps,
    confirmProps,
    onCancel,
    onConfirm,
    showCancel = true,
    showConfirm = true,
}: ConfirmProps) => {
    const { onClose } = useContext(ModalContext);

    const handleCancel = useCallback(
        (event: MouseEvent<HTMLElement>) => confirmAndOrClose(event, { source: "cancel" }, onCancel, onClose),
        [onCancel, onClose]
    );

    const handleConfirm = useCallback(
        (event: MouseEvent<HTMLElement>) => confirmAndOrClose(event, { source: "confirm" }, onConfirm, onClose),
        [onConfirm, onClose]
    );

    return (
        <div className={styles.container}>
            {showCancel && (
                <Button data-cancel role="secondary" onClick={handleCancel} type="button" {...cancelProps}>
                    {cancelLabel}
                </Button>
            )}
            {showConfirm && (
                <Button data-confirm onClick={handleConfirm} type="button" {...confirmProps}>
                    {confirmLabel}
                </Button>
            )}
        </div>
    );
};
