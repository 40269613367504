import { createAction } from "../reducers/edit";
import { saveTaxClass } from "../api/saveTax";
import { scaffoldSaveAction } from "common/scaffolding/actions/scaffoldSaveAction";
import { EditableTaxClass } from "../types";

export const save = (locationId: string, taxInput: EditableTaxClass) =>
    scaffoldSaveAction(
        (state) => state.taxes.edit,
        createAction,
        async (taxId: string | undefined) => {
            return await saveTaxClass(locationId, taxId, taxInput);
        },
        undefined,
        false
    );
