import styles from "./CataloguePage.module.scss";

import { BulkDeleteModal } from "./BulkDeleteModal";
import { CataloguePageHeader, PosSyncPageHeader } from ".";
import { CatalogueProvider } from "../context/CatalogueContext";
import { CatalogueRouteParams } from "../types/CatalogueRouteParams";
import { CatalogueSelections, PosSyncResult } from "../types";
import { CsvUploadProvider } from "../context/CsvUploadContext";
import { Form, Formik } from "formik";
import { getPosSync } from "../selectors/getPosSync";
import { getPosSyncEnabled } from "../selectors/getPosSyncEnabled";
import { ModalRenderer } from "core/components/modal";
import { ModalSyncSuccessful } from "./ModalSyncSuccessful";
import { ModalUpload } from ".";
import { modifier, product } from ".";
import { Redirect, useLocation, useParams } from "react-router";
import { RouteTabPanel, RouteTabs } from "core/components/tabs";
import { useSnackbarContext } from "core/components/snackbar/SnackbarContext";
import { Status } from "core/components/snackbar/types/Message";
import { useEffect, useState } from "react";
import { usePrevious } from "common/hooks";
import { useSelector } from "react-redux";

function useBasePath(): string {
    const location = useLocation();
    const { tab } = useParams<CatalogueRouteParams>();

    if (tab) {
        return location.pathname.slice(0, location.pathname.lastIndexOf(`/${tab}`));
    }

    return location.pathname;
}

export const CataloguePage = () => {
    const { addMessage } = useSnackbarContext();

    const { tab } = useParams<CatalogueRouteParams>();

    const basePath = useBasePath();

    const posSyncEnabled = useSelector(getPosSyncEnabled);
    const posSyncState = useSelector(getPosSync);
    const syncStatus = posSyncState?.status;
    const posSyncResult = posSyncState?.status === "loaded" ? posSyncState.data : ({} as PosSyncResult);
    const prevPosSyncStatus = usePrevious(syncStatus);

    const [showSyncModal, setShowSyncModal] = useState(false);

    const initialValues: CatalogueSelections = {
        products: {},
        modifiers: {},
    };

    useEffect(() => {
        if (prevPosSyncStatus && syncStatus !== prevPosSyncStatus) {
            if (syncStatus === "loaded") {
                setShowSyncModal(true);
            }

            if (syncStatus === "failed") {
                addMessage({ status: Status.ERROR, content: "Sync failed" });
            }
        }
    }, [syncStatus, prevPosSyncStatus, addMessage]);

    if (!tab && location.pathname !== `${basePath}/products`) {
        return <Redirect to={`${basePath}/products`} />;
    }

    return (
        <CatalogueProvider>
            <CsvUploadProvider>
                <Formik
                    initialValues={initialValues}
                    initialTouched={{ products: {}, modifiers: {} }}
                    onSubmit={() => {}}
                >
                    {() => (
                        <div className={styles.root}>
                            {posSyncEnabled ? <PosSyncPageHeader /> : <CataloguePageHeader />}
                            <Form id="catalogue-list">
                                <RouteTabs className={styles.tabsContainer}>
                                    <RouteTabPanel id="products" label="Products" route={`${basePath}/products`}>
                                        <product.Tab />
                                    </RouteTabPanel>
                                    <RouteTabPanel id="modifiers" label="Modifiers" route={`${basePath}/modifiers`}>
                                        <modifier.Tab />
                                    </RouteTabPanel>
                                </RouteTabs>
                            </Form>
                            <ModalUpload />
                            <ModalRenderer target="#modal">
                                <BulkDeleteModal />
                            </ModalRenderer>
                            <ModalRenderer target="#modal">
                                <ModalSyncSuccessful
                                    visible={showSyncModal}
                                    result={posSyncResult}
                                    onClose={() => setShowSyncModal(false)}
                                />
                            </ModalRenderer>
                        </div>
                    )}
                </Formik>
            </CsvUploadProvider>
        </CatalogueProvider>
    );
};
