import styles from "./StripeAccountPage.module.scss";

import { useCallback, useEffect, useState } from "react";
import { getStripeAccountLoginLink } from "../api/getStripeAccountLogin";
import { StripeAccount } from "../types/StripeAccount";
import { createStripeAccount } from "../api/createStripeAccount";
import { getStripeAccount } from "../api/getStripeAccount";
import { useSelector } from "react-redux";
import { getActiveLocationSelector } from "features/location/selectors/getActiveLocation";
import { RouteComponentProps } from "react-router";
import { PageHeader } from "core/components/pageHeader";
import { Button } from "core/components/button";
import { Card, Row, Title } from "core/components/card";
import { StripeSetupStep, StripeSetupStepStatus } from "./StripeSetupStep";
import { Alert, AlertStatus } from "core/components/alert";
import { StripeLogo } from "common/icons/Stripe";
import warnOnce from "warn-once";
import { Icon } from "core/components/icon";
import { OpenInWindow } from "common/icons/OpenInWindow";
import { normalizeError } from "common/error";
import { PageLoading } from "core/components/pageLoading";
import { getCrudPermissions } from "features/location/selectors/getCrudPermissions";
import { EditStatementDescriptors } from "./EditStatementDescriptors";
import { UpdateResult } from "../types";
import { CardsContainer } from "core/components/card/CardsContainer";

const getStatus = (
    type: "account" | "payouts" | "charges",
    status: boolean | undefined,
    detailsSubmitted: boolean | undefined
): StripeSetupStepStatus => {
    if (status) {
        return {
            label: "Complete",
            color: "success",
        };
    }

    if (type === "account" || !detailsSubmitted) {
        return {
            label: "Incomplete",
            color: "warning",
        };
    }

    return {
        label: "Verifying",
        color: "disabled",
    };
};

export const StripeAccountPage = ({}: RouteComponentProps<any>) => {
    const urlParams = new URLSearchParams(location.search);
    const locationId = urlParams.get("l");
    const restaurantId = useSelector(getActiveLocationSelector)?.id;
    const permissions = useSelector(getCrudPermissions).stripe;

    const [stripeAccount, setStripeAccount] = useState<StripeAccount | undefined>(undefined);
    const [redirecting, setRedirecting] = useState<boolean>(false);
    const [initialised, setInitialised] = useState<boolean>(false);
    const [fetchingAccount, setFetchingAccount] = useState(false);
    const [creatingAccount, setCreatingAccount] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const accountCreated = stripeAccount?.accountId !== undefined;
    const chargesEnabled = accountCreated && stripeAccount?.chargesEnabled;
    const payoutsEnabled = accountCreated && stripeAccount?.payoutsEnabled;
    const detailsSubmitted = accountCreated && stripeAccount?.detailsSubmitted;
    const accountReady = accountCreated && chargesEnabled && payoutsEnabled;

    useEffect(() => {
        onRefresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationId]);

    const onRefresh = useCallback(async () => {
        if (locationId && !redirecting) {
            setRedirecting(true);
            const account = await getStripeAccountLoginLink(locationId, true);
            if (account?.accountCreationLink?.url) {
                window.location.href = account.accountCreationLink.url as string;
            } else {
                setRedirecting(false);
                setStripeAccount(account);
            }
        } else if (!initialised) {
            setInitialised(true);
            await onFetchAccount();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationId, stripeAccount]);

    const onCreate = useCallback(async () => {
        if (restaurantId) {
            setCreatingAccount(true);
            try {
                var account = await createStripeAccount(restaurantId);
                setStripeAccount(account);
            } catch (err) {
                const error = normalizeError(err);
                warnOnce(true, `Could not create Stripe account: ${error.message}`);
                setError(error);
            }

            setCreatingAccount(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restaurantId, stripeAccount]);

    const onFetchAccount = useCallback(async () => {
        if (restaurantId) {
            setFetchingAccount(true);
            try {
                var account = await getStripeAccount(restaurantId);
                setStripeAccount(account);
            } catch (err) {
                const error = normalizeError(err);
                warnOnce(true, `Could not fetch Stripe account: ${error.message}`);
                setError(error);
            }
            setFetchingAccount(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restaurantId, stripeAccount]);

    const onFetchAccountLogin = useCallback(async () => {
        if (!redirecting && restaurantId) {
            setRedirecting(true);
            const account = await getStripeAccountLoginLink(restaurantId, false);
            if (account?.accountCreationLink?.url) {
                window.location.href = account.accountCreationLink.url as string;
            } else {
                setStripeAccount(account);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restaurantId, stripeAccount]);

    const onStatementDescriptorsUpdated = useCallback(
        (data: UpdateResult) => {
            if (stripeAccount) {
                setStripeAccount({
                    ...stripeAccount,
                    payoutStatementDescriptor: data.payoutStatementDescriptor,
                    paymentStatementDescriptor: data.paymentStatementDescriptor,
                });
            }
        },
        [stripeAccount]
    );

    const alertProperties = accountReady
        ? {
              title: "Your Stripe account is ready to use!",
              message:
                  "Once your venue has gone live on me&u, payouts and reporting information will be available in your Stripe account.",
              status: AlertStatus.SUCCESS,
          }
        : {
              title: "Your Stripe account is not set up",
              message:
                  "Stripe is a third party payment processor that allows your customers to transact securely on the me&u platform. You will need to setup a Stripe account in order to go live and receive payouts from me&u.",
              status: AlertStatus.WARNING,
          };

    let buttonHandler;
    let buttonLabel;

    if (!accountCreated) {
        buttonHandler = onCreate;
        buttonLabel = "Create Stripe account";
    } else {
        buttonHandler = onFetchAccountLogin;
        if (accountCreated) {
            buttonLabel = "Go to Stripe";
        } else {
            buttonLabel = "Continue Stripe setup";
        }
    }

    return (
        <div className={styles.container}>
            {(!initialised || fetchingAccount) && (
                <div className={styles.loadContainer}>
                    <PageLoading />
                </div>
            )}

            {initialised && !fetchingAccount && (
                <>
                    <PageHeader
                        title="Stripe"
                        actions={
                            permissions.canUpdate &&
                            !error && (
                                <Button onClick={buttonHandler} loading={creatingAccount || redirecting}>
                                    {buttonLabel}
                                </Button>
                            )
                        }
                    />
                    <div className={styles.logoContainer}>
                        <span className={styles.stripeLogo}>
                            <StripeLogo />
                        </span>
                    </div>
                    {error && (
                        <div className={styles.errorContainer}>
                            <h1 className={styles.errorHeading}>Oh no! There was a problem connecting to Stripe</h1>
                            <p className={styles.errorMessage}>
                                There was a technical problem that prevented me&u from connecting to Stripe. Try
                                reloading this page, connecting directly to Stripe or try again later.
                            </p>
                            <div className={styles.errorActions}>
                                <Button as="a" href="https://www.stripe.com" target="_blank" role="secondary">
                                    <Icon className={styles.icon} size="small">
                                        <OpenInWindow />
                                    </Icon>
                                    Go to Stripe
                                </Button>
                                <Button onClick={() => window.location.reload()}>Reload this page</Button>
                            </div>
                        </div>
                    )}
                    {!error && (
                        <>
                            <Alert title={alertProperties.title} status={alertProperties.status}>
                                {alertProperties.message}
                            </Alert>
                            <CardsContainer>
                                <Card>
                                    <Row collapse="down">
                                        <Title title="Stripe steps to go live" />
                                    </Row>
                                    <Row border="auto">
                                        <StripeSetupStep
                                            title="Account"
                                            description="Create a Stripe account"
                                            status={getStatus("account", accountCreated, detailsSubmitted)}
                                        />
                                    </Row>
                                    <Row border="auto">
                                        <StripeSetupStep
                                            title="Charge information"
                                            description="Supply business and owner details"
                                            status={getStatus("charges", chargesEnabled, detailsSubmitted)}
                                        />
                                    </Row>
                                    <Row border="auto">
                                        <StripeSetupStep
                                            title="Payout information"
                                            description="Supply business bank account details"
                                            status={getStatus("payouts", payoutsEnabled, detailsSubmitted)}
                                        />
                                    </Row>
                                </Card>
                                <EditStatementDescriptors
                                    enabled={chargesEnabled || false}
                                    onUpdated={onStatementDescriptorsUpdated}
                                    stripeAccount={stripeAccount}
                                />
                            </CardsContainer>
                        </>
                    )}
                </>
            )}
        </div>
    );
};
