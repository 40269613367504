import "./EditSectionPage.scss";

import { Divider, Form } from "antd";
import { SaveStatus } from "common/loader";
import { EditRow } from "common/scaffolding/components/EditRow";

import { FieldErrors, RadioButtonGroup, TextInput } from "components/forms";
import { PosEditRow } from "components/forms/posUI/PosEditRow";
import { ScrollToFormikError } from "components/forms/ScrollToFormikError";

import { SortableTransfer } from "components/forms/SortableTransfer";
import { SwitchInput } from "components/forms/SwitchInput";
import { CrudActionFooter } from "core/components/actionFooter/CrudActionFooter";
import { ActiveLocation } from "features/location";
import { CrudPermissions } from "features/location/types/createCrudPermissions";
import { MenuSummary } from "features/menu";
import { editableTableSectionSchema } from "features/section/schema";

import { EditableSection } from "features/section/types";
import { ServiceSummary } from "features/service";
import { FastField, Formik, FormikProps, validateYupSchema, yupToFormErrors } from "formik";
import { useState } from "react";
import { EditSectionDemo } from "./EditSectionDemo";
import { PosLookupScope } from "components/forms/posUI/PosFieldScope";

export interface Props {
    initialValues: EditableSection;
    restaurantLocation: ActiveLocation;
    services: ServiceSummary[];
    menus: MenuSummary[];
    onSubmit: (values: EditableSection) => void;
    onClone: (values: EditableSection) => void;
    onArchive: () => void;
    saveStatus?: SaveStatus;
    permissions: CrudPermissions;
    hasSectionSurchargesUpdatePermission: boolean;
    locationTrayChargeEnabled: boolean;
    verifyTakeawaySectionDeleteAction: boolean;
}

type SubmitMode = "save" | "clone" | "archive";

const sectionTypeOptions = [
    { text: "Indoor", value: "indoor" },
    { text: "Outdoor", value: "outdoor" },
];

export const EditSectionPage = (props: Props) => {
    const {
        restaurantLocation,
        saveStatus,
        initialValues,
        services,
        permissions,
        hasSectionSurchargesUpdatePermission,
        locationTrayChargeEnabled,
        verifyTakeawaySectionDeleteAction,
        onClone,
        onArchive,
        onSubmit,
    } = props;

    const isUpdate = !!initialValues.id;
    const disableFields = isUpdate && !permissions.canUpdate;

    const [submitMode, setSubmitMode] = useState<SubmitMode>("save");

    const handleDuplicate = (data: EditableSection) => {
        if (!onClone) {
            return;
        }
        setSubmitMode("clone");
        onClone(data);
    };

    const handleDelete = () => {
        setSubmitMode("archive");
        onArchive?.();
    };

    const handleSubmit = (data: EditableSection) => {
        setSubmitMode("save");
        onSubmit(data);
    };

    const validate = (value: EditableSection) => {
        const context = {
            restaurantLocation: props.restaurantLocation,
        };

        return validateYupSchema(value, editableTableSectionSchema, undefined, context).then(
            () => ({}),
            (err: any) => yupToFormErrors(err)
        );
    };

    return (
        <PosLookupScope>
            <Formik
                validate={validate}
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={handleSubmit}
                key={initialValues.id}
            >
                {(form: FormikProps<EditableSection>) => (
                    <Form onFinish={form.handleSubmit} layout="vertical">
                        <ScrollToFormikError />
                        <EditRow title="Section name">
                            <FieldErrors name="displayName" form={form}>
                                <FastField
                                    name="displayName"
                                    component={TextInput}
                                    placeholder="Section Name"
                                    disabled={disableFields}
                                />
                            </FieldErrors>
                        </EditRow>
                        <Divider />

                        <EditRow
                            labelCol={24}
                            col={24}
                            title="Services"
                            subTitle="Select the services your section should operate within. If no current service is available for this section, users won't be able to use me&amp;u"
                        >
                            <FastField
                                name="services"
                                component={SortableTransfer}
                                options={services}
                                disabled={disableFields}
                            />
                        </EditRow>

                        <Divider />

                        <PosEditRow
                            operationName="OrderCreate"
                            fieldName="sectionPosId"
                            name="posId"
                            disabled={disableFields}
                        />

                        <PosEditRow
                            operationName="PosMenu"
                            fieldName="posMenuId"
                            name="posMenuId"
                            disabled={disableFields}
                        />
                        <Divider />

                        <EditRow title="Section type" subTitle="">
                            <FieldErrors name="sectionType" form={form}>
                                <FastField
                                    name="sectionType"
                                    component={RadioButtonGroup}
                                    options={sectionTypeOptions}
                                    disabled={disableFields}
                                />
                            </FieldErrors>
                        </EditRow>

                        <EditRow title="Allow tips" subTitle="Allow tips in this section.">
                            <FieldErrors name="enableTips" form={form}>
                                <FastField
                                    name="enableTips"
                                    component={SwitchInput}
                                    disabled={disableFields || !initialValues.locationTipsEnabled}
                                />
                            </FieldErrors>
                        </EditRow>

                        <Divider />

                        {hasSectionSurchargesUpdatePermission && (
                            <>
                                <EditRow title="Apply surcharges" subTitle="Surcharges will be applied in this section.">
                                    <FieldErrors name="applySurcharges" form={form}>
                                        <FastField
                                            name="applySurcharges"
                                            component={SwitchInput}
                                            disabled={disableFields}
                                        />
                                    </FieldErrors>
                                </EditRow>
                                <Divider />
                            </>
                        )}
                        {locationTrayChargeEnabled && (
                            <>
                                <EditRow title="Tray charge" subTitle="Tray charge will be applied in this section.">
                                    <FieldErrors name="applyTrayCharge" form={form}>
                                        <FastField
                                            name="applyTrayCharge"
                                            component={SwitchInput}
                                            disabled={disableFields}
                                        />
                                    </FieldErrors>
                                </EditRow>
                                <Divider />
                            </>
                        )}
                        <EditSectionDemo
                            location={restaurantLocation}
                            disableFields={disableFields}
                            form={form}
                            initialValues={initialValues}
                        />
                        <CrudActionFooter
                            saving={saveStatus === "saving"}
                            submitMode={submitMode}
                            showDuplicate={permissions.canCreate && isUpdate}
                            showDelete={permissions.canDelete && isUpdate}
                            showSave={!disableFields}
                            duplicateProps={{
                                onClick: () => {
                                    if (form.isValid) {
                                        handleDuplicate(form.values);
                                    } else {
                                        // force ScrollToFormikError to run (wont submit because form is invalid)
                                        form.submitForm();
                                    }
                                },
                            }}
                            confirmDelete={{
                                title: "Are you sure you want to delete this section?",
                                children: verifyTakeawaySectionDeleteAction
                                    ? "This section is being used by the Takeaway menu. Customers won’t be able to order takeaway until you add a new takeaway section."
                                    : undefined,
                            }}
                            deleteProps={{
                                onClick: handleDelete,
                            }}
                            saveProps={{
                                disabled: !form.dirty,
                            }}
                        />
                    </Form>
                )}
            </Formik>
        </PosLookupScope>
    );
};
