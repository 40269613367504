import { FeatureConfiguration } from "..";
import { reducer } from "features/group/reducers";

import * as actions from "features/group/actions";

export * from "features/group/types/Group";

export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.groups = reducer;
}
