import styles from "./MediaUpload.module.scss";

import { Accept, FileRejection, useDropzone } from "react-dropzone";
import { ArrowUp, Bin } from "common/icons";
import { Button } from "../button";
import { FieldProps, getIn, useFormikContext } from "formik";
import { Icon } from "../icon";
import { useCallback, useMemo, useState } from "react";
import uniqueId from "lodash/uniqueId";
import { UploadFileType } from "components/forms";

export interface MediaUploadDragInfo {
    acceptedFileTypes: Accept;
    isDragActive: boolean;
    maxFileSizeMegabytes: number;
}

interface Props {
    acceptedFileTypes: Accept;
    defaultAltText?: string;
    disabled?: boolean;
    getDragMessage?: (info: MediaUploadDragInfo) => string;
    getThumbUrl: (thumb: string | undefined) => string | undefined;
    maxFileSizeMegabytes: number;
    showDelete?: boolean;
    showReset?: boolean;
    type?: UploadFileType;
    uploadButtonLabel?: string;
}

export const MediaUpload = ({
    acceptedFileTypes,
    defaultAltText = "",
    disabled = false,
    field,
    getDragMessage = defaultGetDragMessage,
    getThumbUrl = (thumb) => thumb,
    maxFileSizeMegabytes: maxFileSizeMegabytes,
    showDelete,
    showReset,
    type,
    uploadButtonLabel = "Upload file",
}: Props & FieldProps<any>) => {
    const { setFieldValue, setFieldTouched, initialValues } = useFormikContext<any>();
    const { value, name } = field;

    const [file, setFile] = useState<File | null>(null);
    const [thumb, setThumb] = useState<string | null>(value ? value : null);
    const fileInputId = useMemo(() => uniqueId("file-input-"), []);

    const handleSetFile = useCallback(
        (file: File | null) => {
            setFile((previousFile) => {
                previousFile && thumb && URL.revokeObjectURL(thumb);
                return file || null;
            });
        },
        [thumb]
    );

    const handleReset = useCallback(() => {
        const initialValue = getIn(initialValues, name);
        handleSetFile(null);
        setThumb(initialValue);
        setFieldValue?.(name, initialValue);
    }, [handleSetFile, initialValues, name, setFieldValue]);

    const handleDelete = useCallback(() => {
        handleSetFile(null);
        setThumb(null);
        setFieldValue?.(name, null);
    }, [handleSetFile, name, setFieldValue]);

    const handleDrop = useCallback(
        (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
            const acceptedFile = acceptedFiles[0] || null;
            const rejectedFile = rejectedFiles[0]?.file || null;

            if (acceptedFile) {
                setThumb(URL.createObjectURL(acceptedFile));
            } else {
                setThumb(null);
            }

            handleSetFile(acceptedFile || rejectedFile);

            // set value of field regardless of acceptance to run validation
            if (name && setFieldValue) {
                setFieldTouched(name, true);
                setFieldValue(name, acceptedFile || rejectedFile || undefined);
            }
        },
        [handleSetFile, name, setFieldTouched, setFieldValue]
    );

    // Before you make changes!
    // react-dropzone is currently brittle/unpredictable on Safari (desktop & iOS) and sensitive to DOM structure.
    // In particular, ensure changes don't prevent onDrop being triggered when clicking the file input button(s)
    // @see https://github.com/react-dropzone/react-dropzone/issues/1097

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        disabled,
        noClick: !!(thumb && type === UploadFileType.Video),
        multiple: false,
        onDrop: handleDrop,
        accept: acceptedFileTypes,
        maxSize: 1024 * 1024 * maxFileSizeMegabytes,
    });

    return (
        <>
            <div
                {...getRootProps({ className: "dropzone" })}
                className={isDragActive ? styles.containerDragActive : styles.container}
            >
                {thumb && (
                    <div className={styles.mediaContainer}>
                        {type === UploadFileType.Video ? (
                            <video className={styles.media} src={getThumbUrl(thumb)} muted controls />
                        ) : (
                            <img className={styles.media} src={getThumbUrl(thumb)} alt={file?.name || defaultAltText} />
                        )}
                    </div>
                )}

                {!thumb && (
                    <>
                        <input {...getInputProps()} disabled={disabled} />
                        <Icon size="largest" className={styles.icon}>
                            <ArrowUp />
                        </Icon>
                        <Button as="label" role="secondary" disabled={disabled}>
                            {uploadButtonLabel}
                        </Button>
                        <p className={styles.dragMessage}>
                            {getDragMessage({
                                isDragActive,
                                maxFileSizeMegabytes,
                                acceptedFileTypes,
                            })}
                        </p>
                    </>
                )}
            </div>
            {thumb && (
                <div className={styles.footer}>
                    <div className={styles.fileName}>{file?.name || null}</div>
                    {file && showReset && (
                        <Button role="secondary" onClick={handleReset} type="button" disabled={disabled}>
                            Reset
                        </Button>
                    )}
                    {showDelete && (
                        <Button
                            disabled={disabled}
                            type="button"
                            role="secondary"
                            onClick={handleDelete}
                            colorScheme="critical"
                            padding="iconUntilMedium"
                        >
                            <Bin />
                            <span className="hide-until-medium">Delete</span>
                        </Button>
                    )}
                    <Button as="label" role="secondary" htmlFor={`${fileInputId}-replace`} disabled={disabled}>
                        <span>
                            <input {...getInputProps()} id={`${fileInputId}-replace`} disabled={disabled} />
                            Replace
                        </span>
                    </Button>
                </div>
            )}
        </>
    );
};

function defaultGetDragMessage({ isDragActive }: MediaUploadDragInfo) {
    return isDragActive ? "Drop file" : "or drag to upload";
}
