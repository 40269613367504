import { opsApi } from "API";
import { mapStripeAccount } from ".";
import { StripeAccount } from "../types/StripeAccount";
import { emptyStripeAccount, GraphQLPayload } from "./types";

export const getStripeAccount = async (locationId: string): Promise<StripeAccount> => {
    try {
        const res = await opsApi.graphQLQuery<GraphQLPayload>(fetchQuery, { locationId });
        return mapStripeAccount(res.data.getStripeAccount || emptyStripeAccount());
    } catch (e) {
        console.log(e);
        throw e;
    }
};

const fetchQuery = `
    query($locationId: ID!) {
        getStripeAccount(locationId: $locationId) {
            accountId
            chargesEnabled
            payoutsEnabled
            detailsSubmitted
            accountCreationLink {
                url
                expiresAt
            }
            paymentStatementDescriptor
            payoutStatementDescriptor
        }
    }
`;
