import { createAction } from "../reducers/edit";
import { EditableLocation } from "../types/EditableLocation";
import { scaffoldSaveAction } from "common/scaffolding/actions/scaffoldSaveAction";
import { CompanyDetailsUpdateModel, saveLocation } from "../api/saveLocation";
import { createAction as activeLocationActions } from "../reducers/active";
import { list as listGroups } from "../../group/actions";
import { edit } from ".";
import { numberOrUndefined } from "common/utility/numberUtils";

export const save = (
    location: string,
    editableLocation: EditableLocation,
    callback?: () => void,
    clone: boolean = false
) =>
    scaffoldSaveAction(
        (state) => state.locations.edit,
        createAction,
        async (locationId: string | undefined, dispatch) => {
            const companyDetailsUpdate = mapUpdateModel(editableLocation);
            const updatedLocation = await saveLocation(location, locationId, companyDetailsUpdate);

            await dispatch(activeLocationActions.success(updatedLocation));

            dispatch(edit(updatedLocation.id));

            if (editableLocation.groupId === "new") {
                dispatch(listGroups(true));
            }

            return updatedLocation.id;
        },
        undefined,
        clone,
        callback
    );

const mapUpdateModel = ({
    companyDetails,
    billingSystemId,
    isDemo,
    isSalesMode,
    displayName,
    timeZone,
    locale,
    groupId,
    newGroupName,
    stripeCommission,
}: EditableLocation): CompanyDetailsUpdateModel => ({
    billingSystemId,
    ...companyDetails,
    isDemo,
    isSalesMode,
    displayName,
    timeZone,
    locale,
    groupId: groupId === "new" ? undefined : groupId,
    newGroupName: groupId === "new" ? newGroupName : undefined,
    stripeCommission: numberOrUndefined(stripeCommission),
});
