import { PosUISettings } from ".";

export const bepozUISettings: PosUISettings = {
    servicePosId: {
        visible: true,
        title: "Shift ID",
        subTitle: "Specify the Bepoz Shift that this service relates to. Leave blank to use the default.",
    },
    surchargeSku: {
        visible: false,
    },
    priceListPosId: {
        visible: false,
    },
    taxPosId: {
        visible: false,
    },
};
