import { AppDispatch } from "features";
import { createAction } from "../reducers/list";
import { listMemberships } from "../api/listMemberships";

export const list = (location: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(createAction.loading());
        const memberships = await listMemberships(location);
        dispatch(createAction.loaded(memberships));
    } catch (e) {
        dispatch(createAction.loadFailed(e));
    }
};
