import { scaffoldEditAction } from "common/scaffolding/actions/scaffoldEditAction";
import { isNew } from "common/utility/modelUtils";
import { createAction } from "../reducers/edit";
import { EditableTaxClass, TaxType } from "../types";
import { editTax } from "../api/editTax";
import { toPercentage } from "../taxesHelpers";

export const edit = (locationId: string, taxId: string) =>
    scaffoldEditAction(
        taxId,
        (state) => state.taxes.edit,
        createAction,
        async () => {
            if (isNew(taxId)) {
                return newEditableTax();
            }

            const tax = await editTax(locationId, taxId);
            return mapEditableTax(tax);
        }
    );

const newEditableTax = (): EditableTaxClass => {
    return {
        id: "new",
        displayName: "",
        internalName: "",
        taxType: TaxType.PERCENTAGE_PER_ITEM,
        taxRate: undefined,
        posTaxId: null,
        applyToAll: false,
        taxAssignments: [],
        exclusive: true,
    };
};

const mapEditableTax = (tax: EditableTaxClass): EditableTaxClass => {
    return {
        ...tax,
        taxAssignments: tax.taxAssignments || [],
        taxRate: toPercentage(tax.taxRate!),
    };
};
