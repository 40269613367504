import { AppDispatch, AppState } from "features";
import { createAction } from "../reducers/update";
import { StatementDescriptors } from "../types";
import { updateStatementDescriptors as updateStatementDescriptorsAPI } from "../api/updateStatementDescriptors";

export function updateStatementDescriptors(locationId: string, input: StatementDescriptors) {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();

        if (state.stripe.update.status === "loading") {
            return;
        }

        dispatch(createAction.loading());

        try {
            const result = await updateStatementDescriptorsAPI(locationId, input);

            dispatch(createAction.loaded(result));

            // TODO tracking
        } catch (e: any) {
            console.error(e);
            dispatch(createAction.failed(e));

            // TODO tracking
        }
    };
}
