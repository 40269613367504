import { opsApi } from "API";
import { OrderFlowType } from "features/locationSettings/types/OrderFlow";
import { GraphQLItemId } from "../../../common/scaffolding/types";
import { LocationType } from "../types/LocationType";

export const createLocation = async (
    data: LocationUpdateModel,
    cloneFromLocationId: string | null
): Promise<string> => {
    const res = await opsApi.graphQLQuery<{ createLocation: GraphQLItemId }>(updateQuery, {
        data,
        cloneFromLocationId,
    });
    return res.data.createLocation.slug;
};

export interface LocationUpdateModel {
    slug: string;
    displayName: string;
    timeZone: string;
    locale: string;
    groupId?: string;
    newGroupName?: string;
    isLiveOrdersEnabled: boolean;
    enableAbsorbMerchantFee: boolean;
    locationType: LocationType | null;
    orderFlow: OrderFlowType;
}

const updateQuery = `
    mutation($data: LocationUpdateInput!, $cloneFromLocationId: ID) {
        createLocation(data: $data, cloneFromLocationId: $cloneFromLocationId) {
            slug
        }
    }
`;
