import * as Yup from "yup";
import { HyperwalletUser } from "../types/HyperwalletUser";

export const hyperwalletUserSchema = Yup.object<HyperwalletUser>().shape({
    id: Yup.string(),
    businessName: Yup.string()
        .required("Required")
        .max(100, "Must be less than 100 characters")
        .matches(/^[aA-zZ0-9!\s&'()+,-./:;]+$/, "Invalid name, only letters, numbers, spaces or !&'()+,-./:; allowed"),
    firstName: Yup.string()
        .required("Required")
        .max(50, "Must be less than 50 characters")
        .matches(/^[aA-zZ\s',-.]+$/, "Invalid name, only letters, spaces or ',-. allowed"),
    lastName: Yup.string()
        .required("Required")
        .max(50, "Must be less than 50 characters")
        .matches(/^[aA-zZ\s',-.]+$/, "Invalid name, only letters, spaces or ',-. allowed"),
    email: Yup.string().email().required("Required"),
    phoneNumber: Yup.string()
        .nullable(true)
        .matches(/^\+?[0-9\s]{8,17}$/, "Invalid phone number"),
    isNew: Yup.bool(),
});
