import { region } from "features/region";
import { config } from "common/config";

export const menuDataUrl = (url: string) =>
    isAbsoluteUrl(url)
        ? url
        : `${region.getActiveRegion().locationDataUrl || config.REACT_APP_MENU_DATA_URI}/${/\/?(.+)/.exec(url)![1]}`;

function isAbsoluteUrl(src: string) {
    return src.indexOf("data:") === 0 || src.indexOf("://") !== -1;
}
