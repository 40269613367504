import { opsApi } from "API";

export async function archivePriceList(locationId: string, priceListId: string): Promise<void> {
    await opsApi.graphQLQuery(query, { locationId, priceListId });
}

const query = `
    mutation($locationId: ID!, $priceListId: ID!) {
        deletePriceList(locationId: $locationId, id: $priceListId) {
            id
        }
    }
`;
