import { Card, Row, Title } from "core/components/card";
import { EditableModifier } from "features/modifier/types";
import { Field, FieldProps, useFormikContext } from "formik";
import { FieldErrors } from "core/components/form/fieldErrors";
import {
    getModifierProductTree,
    getModifierProductTreeWithoutLinkedItems,
} from "features/modifier/selectors/getModifierProductTree";
import { ProductSelectionsAdapter } from "./ProductSelectionsAdapter";
import { ProductTree, Title as ProductTreeTitle } from "components/forms/ProductTree";
import { TreeItem } from "common/types/TreeItem";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { visit } from "common/data/treeUtils";
import { getIsChildLocation } from "features/location/selectors/getIsChildLocation";
import { LinkedItemBadge } from "features/catalogue/components/controlledItem/LinkedItemBadge";
import { Alert } from "core/components/alert";

export interface Props {
    disableFields?: boolean;
}

export const EditModifierProducts = ({ disableFields }: Props) => {
    const form = useFormikContext<EditableModifier>();

    const {
        initialValues,
        initialValues: { type, upsell },
    } = form;

    const isChildLocation = useSelector(getIsChildLocation);

    const productTreeAll = useSelector(getModifierProductTree);

    const productTreeWithoutLinkedItems = useSelector(getModifierProductTreeWithoutLinkedItems);

    const productTree = useMemo(() => {
        if (isChildLocation && !initialValues?.isLinked) {
            return productTreeWithoutLinkedItems;
        }

        return productTreeAll;
    }, [initialValues?.isLinked, isChildLocation, productTreeAll, productTreeWithoutLinkedItems]);

    const initialSelections = useMemo(() => {
        const selections: TreeItem[] = [];

        if (initialValues.products) {
            visit(productTree, (node) => {
                if (node.type === "product" && initialValues?.products?.productIds?.includes(node.id)) {
                    selections.push(node);
                }

                if (node.type === "variant" && initialValues?.products?.variantIds?.includes(node.id)) {
                    selections.push(node);
                }
            });
        }

        return selections;
    }, [initialValues, productTree]);

    const [selectedItems, setSelectedItems] = useState<TreeItem[]>(initialSelections);

    const defaultExpandedKeys = useMemo(() => {
        let expandedKeys: string[] = [];
        if (productTree && !initialSelections.length) {
            const expandFoodRoot = upsell && type === "food";

            const expandDrinksRoot = type && upsell && ["drink", "alcoholic-drink"].includes(type);

            visit(
                productTree,
                (node) => {
                    if (expandFoodRoot && node.type === "root" && node.id === "food") {
                        expandedKeys.push(node.id);
                    }

                    if (expandDrinksRoot && node.type === "root" && node.id === "drinks") {
                        expandedKeys.push(node.id);
                    }

                    if (!expandFoodRoot && !expandDrinksRoot && node.type === "root") {
                        expandedKeys.push(node.id);
                    }
                },
                false
            );
        }

        return expandedKeys;
    }, [initialSelections.length, type, upsell, productTree]);

    return (
        <Card>
            <Row collapse="down">
                <Title title="Products" />
            </Row>
            {isChildLocation && !initialValues.isLinked && (
                <Row collapse="down">
                    <Alert>You can not attach brand products/variants to a venue modifier</Alert>
                </Row>
            )}
            <Row>
                <FieldErrors fieldNames={["products"]}>
                    <Field name="products">
                        {({ field, form }: FieldProps<string[]>) => {
                            return productTree ? (
                                <>
                                    <ProductSelectionsAdapter field={field} form={form} selectedItems={selectedItems} />
                                    <ProductTree
                                        datasource={productTree}
                                        defaultExpandedKeys={defaultExpandedKeys}
                                        disabled={disableFields}
                                        initialSelectedItems={initialSelections}
                                        initialSelectionsExpansion={["parents"]}
                                        onCheck={() => form.setFieldTouched("items", true)}
                                        searchPlaceholder="Search for products and variants"
                                        selectedItems={selectedItems}
                                        setSelectedItems={setSelectedItems}
                                        showIcon={true}
                                        titleRenderer={titleRenderer}
                                    />
                                </>
                            ) : null;
                        }}
                    </Field>
                </FieldErrors>
            </Row>
        </Card>
    );
};

function titleRenderer({ displayName, internalName, isLinked, disabled }: TreeItem, searchTerm: string | undefined) {
    return (
        <>
            {isLinked && <LinkedItemBadge disabled={disabled} />}
            <ProductTreeTitle
                displayName={displayName}
                internalName={internalName || undefined}
                searchTerm={searchTerm}
            />
        </>
    );
}
