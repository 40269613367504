import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import * as actions from "../actions";
import { actions as categoryActions } from "features/category";
import { actions as catalogueActions } from "features/catalogue";
import { AppDispatch, AppState } from "../..";
import { LocationComponentProps, withLocation } from "../../location";
import { DataProps, FunctionProps } from "../../../types/utils";
import { PageLoadingProps, withLoader } from "../../../common/loader/withLoader";
import { mergeStatus } from "../../../common/loader";
import { GroupTabsEditPackage, Props } from "../components/GroupTabsEditPackage";
import { getCrudPermissions } from "../../location/selectors/getCrudPermissions";
import { isLoaded } from "../../../common/loader/isLoaded";
import { PackageUpdateInput } from "../types";
import { getProductTree } from "../selectors/getProductTree";

const mapStateToProps = (
    state: AppState,
    { match: { params } }: LocationComponentProps & RouteComponentProps<RouteParams>
): DataProps<PageLoadingProps<Props>> => {
    const {
        groupTabs: { edit },
        categories: { list: categoryList },
        menuItems: { list: menuItemList },
    } = state;

    const overallFetchStatus = mergeStatus(edit.status, categoryList.status, menuItemList.status);

    if (isLoaded(edit) && isLoaded(categoryList) && isLoaded(menuItemList)) {
        // strip unwanted properties
        const { itemCount, usedCount, ...values } = edit.data;

        return {
            loadStatus: "loaded",
            initialValues: values,
            categories: categoryList.data,
            saveStatus: edit.saveStatus,
            productTree: getProductTree(state),
            permissions: getCrudPermissions(state).packages,
        };
    }

    return {
        loadStatus: overallFetchStatus,
    };
};

const mapDispatchToProps = (
    dispatch: AppDispatch,
    {
        restaurantLocation,
        history,
        match: {
            params: { location, id, region },
        },
    }: LocationComponentProps & RouteComponentProps<RouteParams>
): FunctionProps<PageLoadingProps<Props>> => ({
    fetch: () => {
        dispatch(actions.edit(restaurantLocation.id, id));
        dispatch(categoryActions.list(location));
        dispatch(catalogueActions.listProducts(location));
    },
    onSubmit: (data: PackageUpdateInput, keepEditing: boolean) => {
        dispatch(actions.save(location, region, data, history, keepEditing));
    },
});
interface RouteParams {
    id: string;
    location: string;
    region: string;
}

export const GroupTabsEditPackageContainer = withRouter(
    withLocation(connect(mapStateToProps, mapDispatchToProps)(withLoader(GroupTabsEditPackage)))
);
