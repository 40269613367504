export const ArrowRightIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.8335 7.99967C2.8335 7.63148 3.13197 7.33301 3.50016 7.33301H12.5002C12.8684 7.33301 13.1668 7.63148 13.1668 7.99967C13.1668 8.36786 12.8684 8.66634 12.5002 8.66634H3.50016C3.13197 8.66634 2.8335 8.36786 2.8335 7.99967Z"
            fill="#262626"
        />
        <path
            d="M8.52876 4.02876C8.78911 3.76841 9.21122 3.76841 9.47157 4.02876L12.9716 7.52876C13.2319 7.78911 13.2319 8.21122 12.9716 8.47157L9.47157 11.9716C9.21122 12.2319 8.78911 12.2319 8.52876 11.9716C8.26841 11.7112 8.26841 11.2891 8.52876 11.0288L11.5574 8.00016L8.52876 4.97157C8.26841 4.71122 8.26841 4.28911 8.52876 4.02876Z"
            fill="#262626"
        />
    </svg>
);

export const CircleClose = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.3985 11.3981C8.85683 13.9397 8.85683 18.0605 11.3985 20.6022C13.9401 23.1438 18.061 23.1438 20.6026 20.6022C23.1443 18.0605 23.1443 13.9397 20.6026 11.3981C18.061 8.85641 13.9401 8.85641 11.3985 11.3981ZM10.5005 21.5002C7.46294 18.4626 7.46294 13.5377 10.5005 10.5001C13.5381 7.46251 18.463 7.46251 21.5006 10.5001C24.5382 13.5377 24.5382 18.4626 21.5006 21.5002C18.463 24.5377 13.5381 24.5377 10.5005 21.5002Z"
            fill="#8C8C8C"
        />
        <path
            d="M13.1717 13.1713C13.4003 12.9427 13.7709 12.9427 13.9995 13.1713L16.0001 15.1721L18.0007 13.1715C18.2293 12.9429 18.6 12.9429 18.8285 13.1715C19.0571 13.4001 19.0571 13.7708 18.8285 13.9993L16.8279 15.9999L18.8286 18.0007C19.0572 18.2293 19.0571 18.5999 18.8285 18.8285C18.5999 19.057 18.2293 19.057 18.0007 18.8284L16.0001 16.8277L13.9993 18.8284C13.7707 19.057 13.4 19.057 13.1714 18.8284C12.9429 18.5998 12.9429 18.2292 13.1714 18.0006L15.1723 15.9999L13.1717 13.9991C12.9431 13.7705 12.9431 13.3999 13.1717 13.1713Z"
            fill="#8C8C8C"
        />
    </svg>
);

export const ArrowIcon = () => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.74981 9L9.75 6L6.74981 3L5.95444 3.79556L7.59675 5.43769H2.25V6.56269H7.59637L5.95444 8.20463L6.74981 9Z"
            fill="#B3B3B3"
        />
    </svg>
);

export const CalendarIcon = () => (
    <svg
        className="calendar-icon"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8 0.5C7.86739 0.5 7.74021 0.552678 7.64645 0.646447C7.55268 0.740215 7.5 0.867392 7.5 1V4.5C7.5 4.63261 7.55268 4.75979 7.64645 4.85355C7.74021 4.94732 7.86739 5 8 5C8.13261 5 8.25979 4.94732 8.35355 4.85355C8.44732 4.75979 8.5 4.63261 8.5 4.5V1C8.5 0.867392 8.44732 0.740215 8.35355 0.646447C8.25979 0.552678 8.13261 0.5 8 0.5ZM4 0.5C3.86739 0.5 3.74021 0.552678 3.64645 0.646447C3.55268 0.740215 3.5 0.867392 3.5 1V4.5C3.5 4.63261 3.55268 4.75979 3.64645 4.85355C3.74021 4.94732 3.86739 5 4 5C4.13261 5 4.25979 4.94732 4.35355 4.85355C4.44732 4.75979 4.5 4.63261 4.5 4.5V1C4.5 0.867392 4.44732 0.740215 4.35355 0.646447C4.25979 0.552678 4.13261 0.5 4 0.5ZM12 0.5C11.8674 0.5 11.7402 0.552678 11.6464 0.646447C11.5527 0.740215 11.5 0.867392 11.5 1V4.5C11.5 4.63261 11.5527 4.75979 11.6464 4.85355C11.7402 4.94732 11.8674 5 12 5C12.1326 5 12.2598 4.94732 12.3536 4.85355C12.4473 4.75979 12.5 4.63261 12.5 4.5V1C12.5 0.867392 12.4473 0.740215 12.3536 0.646447C12.2598 0.552678 12.1326 0.5 12 0.5Z"
            fill="black"
        />
        <path
            d="M10.5 4.5C10.4986 4.79016 10.5815 5.07448 10.7385 5.3185C10.8954 5.56253 11.1198 5.75579 11.3844 5.87486C11.649 5.99393 11.9425 6.03371 12.2292 5.98936C12.516 5.94501 12.7837 5.81844 13 5.625V13H3V5.625C3.21627 5.81844 3.484 5.94501 3.77075 5.98936C4.0575 6.03371 4.35096 5.99393 4.61556 5.87486C4.88017 5.75579 5.10455 5.56253 5.26154 5.3185C5.41852 5.07448 5.50135 4.79016 5.5 4.5H6.5C6.5 4.89782 6.65804 5.27936 6.93934 5.56066C7.22064 5.84196 7.60218 6 8 6C8.39782 6 8.77936 5.84196 9.06066 5.56066C9.34196 5.27936 9.5 4.89782 9.5 4.5H10.5ZM5.5 2H6.5V3.5H5.5V2Z"
            fill="black"
        />
        <path d="M13.5 2V3.5H14V14H2V3.5H2.5V2H0.5V15.5H15.5V2H13.5Z" fill="black" />
        <path d="M9.5 2H10.5V3.5H9.5V2Z" fill="black" />
    </svg>
);

export const SectionIcon = () => (
    <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path
                d="M4 0.75C3.20435 0.75 2.44129 1.06607 1.87868 1.62868C1.31607 2.19129 1 2.95435 1 3.75C1 3.85 1 3.95 1.0125 4.0375V4.075C1.2625 6.45 4 8.25 4 8.25C4 8.25 6.7375 6.425 6.975 4.0875V4.0375C6.9875 3.9375 6.9875 3.85 6.9875 3.75C6.98751 2.95651 6.67315 2.19534 6.11324 1.63309C5.55334 1.07084 4.79348 0.753306 4 0.75ZM4 5.25C3.70333 5.25 3.41332 5.16203 3.16664 4.9972C2.91997 4.83238 2.72771 4.59811 2.61418 4.32403C2.50065 4.04994 2.47094 3.74834 2.52882 3.45736C2.5867 3.16639 2.72956 2.89912 2.93934 2.68934C3.14912 2.47956 3.41639 2.3367 3.70736 2.27882C3.99834 2.22094 4.29994 2.25065 4.57403 2.36418C4.84811 2.47771 5.08238 2.66997 5.2472 2.91664C5.41203 3.16332 5.5 3.45333 5.5 3.75C5.5 4.14782 5.34196 4.52936 5.06066 4.81066C4.77936 5.09196 4.39782 5.25 4 5.25Z"
                fill="#FAFAFA"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="8" height="8" fill="white" transform="translate(0 0.5)" />
            </clipPath>
        </defs>
    </svg>
);
