import { opsApi } from "API";
import { AuditLogEntry } from "../types";

export type BulkUploadType = "modifier" | "product";

export async function listAuditLogEntries(scopeType: string, scopeId: string) {
    const res = await opsApi.fetch(`/auditlog/${scopeType}/${scopeId}`);

    const entries: AuditLogEntry[] = await res.json();

    return entries;
}
