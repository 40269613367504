import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import * as actions from "../actions";
import { AppDispatch, AppState } from "../..";
import { LocationComponentProps, withLocation } from "../../location";
import { LocationRouteParams } from "../../../common/types";
import { DataProps, FunctionProps } from "../../../types/utils";
import { PageLoadingProps, withLoader } from "../../../common/loader/withLoader";
import { Props, GroupTabsPage } from "../components/GroupTabsPage";
import { mergeStatus } from "../../../common/loader";
import { getCrudPermissions } from "../../location/selectors/getCrudPermissions";
import { isLoaded } from "../../../common/loader/isLoaded";
import { getLocationPermissions } from "../../location/selectors/getLocationPermissions";
import * as locationSettingsActions from "../../locationSettings/actions";
import { getIsCatalogueDataSupported, getIsPosMenuSupported } from "features/location/selectors/getPosMenuSupported";

const mapStateToProps = (
    state: AppState,
    { match: { params }, restaurantLocation }: LocationComponentProps & RouteComponentProps<LocationRouteParams>
): DataProps<PageLoadingProps<Props>> => {
    const {
        LocationSettings: { edit },
        groupTabs: { list, stats },
    } = state;

    const overallFetchStatus = mergeStatus(edit.status, list.status, stats.status);

    if (isLoaded(edit) && isLoaded(list) && isLoaded(stats)) {
        const locationPermissions = getLocationPermissions(state);
        const packagePermissions = getCrudPermissions(state).packages;
        const posMenuSupported = getIsPosMenuSupported(state) || false;
        const catalogueDataSupported = getIsCatalogueDataSupported(state) || false;

        return {
            loadStatus: "loaded",
            packages: list.data,
            permissions: packagePermissions,
            hasLocationUpdatePermission: locationPermissions.hasAny("location:update"),
            groupTabsEnabled: edit.data.enableGroupTabs !== false,
            stats: stats.data || undefined,
            supportsPackages:
                (!posMenuSupported || (posMenuSupported && !catalogueDataSupported)) && packagePermissions.canCreate,
        };
    }

    return {
        loadStatus: overallFetchStatus,
    };
};

const mapDispatchToProps = (
    dispatch: AppDispatch,
    { restaurantLocation, match: { params } }: LocationComponentProps & RouteComponentProps<LocationRouteParams>
): FunctionProps<PageLoadingProps<Props>> => ({
    fetch: () => {
        dispatch(locationSettingsActions.edit(restaurantLocation.id));
        dispatch(actions.list(restaurantLocation.id));
        dispatch(actions.stats(restaurantLocation.id));
    },
    handleGroupTabsToggle: (enabled: boolean) => {
        dispatch(actions.saveGroupTabsFlag(restaurantLocation.id, enabled));
    },
});

export const GroupTabsPageContainer = withRouter(
    withLocation(connect(mapStateToProps, mapDispatchToProps)(withLoader(GroupTabsPage)))
);
