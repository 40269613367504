import { FormikContext } from "formik";
import { useCallback, useEffect } from "react";
import { createContext, ReactNode, useState, useContext } from "react";

type PosLookupScopeData = {[key: string]: string | undefined};

export type PosLookupScopeState = [PosLookupScopeData, (data: PosLookupScopeData) => void];

const PosLookupScopeContext = createContext<PosLookupScopeState>([
    {},
    () => {}
]);

export interface Props {
    children?: ReactNode | ReactNode[];
}

// Defines a context in which 'POS Lookup Scope' can be shared. Typically recommended for any form
// that houses more than one POS field.
//
// Within this scope:
// - PosLookupField will automatically link the selected value as the 'scope' for that field definition
// - PosLookupField will automatically reload its lookup if its definition defined a lookupScope and
//   that scope value changes
export const PosLookupScope = (props: Props) => {

    const state = useState<PosLookupScopeData>({});

    return (
        <PosLookupScopeContext.Provider value={state}>
            {props.children}
        </PosLookupScopeContext.Provider>
    );
};

export const usePosLookupScope = (scopeKey: string | null): [string | undefined, (newValue: string | undefined) => void] => {
    
    const [allScopes, setAllScopes] = useContext(PosLookupScopeContext);

    const scopeValue = scopeKey
        ? allScopes[scopeKey]
        : undefined;

    const setScope = useCallback((newValue: string | undefined) => {
        if (scopeKey && allScopes[scopeKey] !== newValue) {
            const newScopes = {
                ...allScopes,
                [scopeKey]: newValue
            };
            setAllScopes(newScopes);
        }
    }, [scopeKey, allScopes, setAllScopes]);


    return [scopeValue, setScope];
}

export interface PosFieldScopeProps {
    field: string;
    scopeKey?: string;
}

// Link a form field value to a POS Lookup Scope. If another PosLookupField uses that
// as its lookupScope, it will automatically reload when the scope value changes.
export const PosFieldScope = ({ field, scopeKey }: PosFieldScopeProps) => {
    const resolvedScopeKey = scopeKey ?? field;

    const [_, setScopeValue] = usePosLookupScope(resolvedScopeKey);
    const formik = useContext(FormikContext);
    const fieldValue = formik.values[field];

    useEffect(() => {
        setScopeValue(fieldValue);
    }, [setScopeValue, fieldValue]);

    return null;
}