import { EditableWaiter } from "features/waiter/types";
import { createAction } from "../reducers/edit";
import { scaffoldSaveAction } from "../../../common/scaffolding/actions/scaffoldSaveAction";
import { saveWaiter, WaiterInputModel } from "../api/saveWaiter";

export const save = (location: string, editableWaiter: EditableWaiter, clone: boolean = false) =>
    scaffoldSaveAction(
        (state) => state.waiters.edit,
        createAction,
        async (staffId: string | undefined) => {
            const waiterInputModel = mapInputModel(editableWaiter);
            return await saveWaiter(location, staffId, waiterInputModel);
        },
        undefined,
        clone
    );

const mapInputModel = ({ displayName, pin, accessLevel }: EditableWaiter): WaiterInputModel => ({
    displayName,
    pin,
    accessLevel,
});
