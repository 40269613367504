import { createSelector } from "reselect";
import { AppState } from "features/state";

const getProductsList = (state: AppState) => state.menuItems.list;
const getModifiersList = (state: AppState) => state.modifiers.list;

export const getCatalogueEmpty = createSelector(getProductsList, getModifiersList, (products, modifiers): boolean => {
    if (products.status !== "loaded" || modifiers.status !== "loaded") {
        return false;
    }
    return !products.data.length && !modifiers.data.length;
});
