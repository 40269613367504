import styles from "./ExportImportButton.module.scss";

import { useIsNewRoute } from "common/hooks";
import { DownloadSvg } from "common/icons/Download";
import { UploadSvg } from "common/icons/Upload";
import { Button } from "core/components/button";
import { ActiveLocation } from "features/location";
import { EditablePromotion } from "features/promotions/types";
import { getNow } from "features/service/selectors/getVenueTime";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { fetchDownloadPromotionConfiguration } from "../../api/exportPromotionCsv";
import { ImportPromotionModal } from "./ImportPromotionModal";
import { Icon } from "core/components/icon";
import { useTrackEvent } from "common/appInsights";
import { getPromotionFileName } from "features/promotions/actions/save";
import { ModalRenderer } from "core/components/modal";

interface Props {
    setEditablePromotion: (promotion: EditablePromotion) => void;
    restaurantLocation: ActiveLocation;
    promotionId: string;
    promotionName: string;
}

export const ExportImportButton = ({ setEditablePromotion, restaurantLocation, promotionId, promotionName }: Props) => {
    const venueTime = useSelector(getNow);
    const isNew = useIsNewRoute();

    const [downloading, setDownloading] = useState(false);
    const [showImportPromotion, setShowImportPromotion] = useState(false);

    const loadingTimerRef = useRef(0);

    useEffect(() => () => window.clearTimeout(loadingTimerRef.current));

    const trackImportClick = useTrackEvent("PROMOTION/IMPORT_PROMOTION_CLICKED", {});
    const trackExportClick = useTrackEvent("PROMOTION/EXPORT_PROMOTION", {});

    const venueTimeZone = restaurantLocation.timeZone || "Australia/Sydney";

    return (
        <>
            <Button
                loading={!isNew && downloading}
                role="secondary"
                onClick={() => {
                    setDownloading(true);
                    const startDownload = Date.now();
                    if (isNew) {
                        trackImportClick?.({});
                        setShowImportPromotion(true);
                    } else {
                        fetchDownloadPromotionConfiguration(
                            restaurantLocation.id,
                            promotionId,
                            getPromotionFileName(promotionName),
                            () => {
                                trackExportClick?.({ promo_id: promotionId });
                                const timeDiff = Date.now() - startDownload;
                                if (timeDiff > 1000) {
                                    setDownloading(false);
                                } else {
                                    loadingTimerRef.current = window.setTimeout(
                                        () => setDownloading(false),
                                        1000 - timeDiff
                                    );
                                }
                            }
                        );
                    }
                }}
            >
                <Icon size="small" title={isNew ? "Import promotion" : "Export promotion"}>
                    {isNew ? <UploadSvg /> : <DownloadSvg />}
                </Icon>
                <span className={styles.buttonText}>{isNew ? "Import promotion" : "Export promotion"}</span>
            </Button>

            <ModalRenderer target="#modal">
                <ImportPromotionModal
                    visible={showImportPromotion}
                    close={() => {
                        setShowImportPromotion(false);
                        setDownloading(false);
                    }}
                    restaurantLocation={restaurantLocation}
                    setEditablePromotion={setEditablePromotion}
                    venueTime={venueTime}
                    venueTimeZone={venueTimeZone}
                />
            </ModalRenderer>
        </>
    );
};
