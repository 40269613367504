import React, { Dispatch, ReactNode, SetStateAction, useCallback, useMemo, useState } from "react";
import { CheckHandler } from "common/types";
import { Spin, Tree } from "antd";
import { Key } from "rc-tree/lib/interface";
import { normaliseText } from "common/utility/StringUtils";
import { Title } from "components/forms/ProductTree/Title";
import { TreeItem, getDescendants } from "common/types/TreeItem";
import { useEffect } from "react";
import { useRef } from "react";
import Search from "antd/lib/input/Search";
import { visit, getKeysRecursive, selectedIdsByType, getSearchNodes, getKeysForSearch } from "common/data/treeUtils";

import "./ProductTree.scss";

export type InitialSelectionsExpansionRule = "parents" | "nodes";

export interface Props {
    datasource: TreeItem[];
    defaultExpandedKeys?: string[] | "all";
    initialSelectedItems: TreeItem[];
    initialSelectionsExpansion?: InitialSelectionsExpansionRule[];
    checkStrictly?: boolean;
    selectedItems: TreeItem[];
    setSelectedItems: Dispatch<SetStateAction<TreeItem[]>>;
    onCheck?: CheckHandler;
    disabled?: boolean;
    searchPlaceholder?: string;
    showIcon?: boolean;
    onTreeInitialised?: (initialSelections: TreeItem[]) => void;
    titleRenderer?: (item: TreeItem, searchTerm: string) => ReactNode;
}

export const ProductTree = ({
    datasource,
    initialSelectedItems,
    selectedItems,
    setSelectedItems,
    checkStrictly = false,
    defaultExpandedKeys = [],
    initialSelectionsExpansion = ["nodes", "parents"],
    onCheck,
    disabled = false,
    searchPlaceholder,
    showIcon = false,
    onTreeInitialised,
    titleRenderer,
}: Props) => {
    const [inited, setInited] = useState<boolean>(false);
    const [loaded, setLoaded] = useState(false);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [expandedKeys, setExpandedKeys] = useState<Key[] | undefined>(undefined);
    const timeoutRef = useRef(0);

    useEffect(() => {
        if (loaded && !inited) {
            // use initialSelectedItems (list of product / modifiers) to find
            // unique nodes in the priceListTree (datasource) Tree component MUST have nodes with unique keys
            const initSelectionNodes: TreeItem[] = [];
            const initSelectionIds = selectedIdsByType(initialSelectedItems);
            const expandedKeys: string[] = [];

            // if we have no initial selection apply default expansions
            if (initialSelectedItems.length === 0) {
                visit(
                    datasource,
                    (node, parentKeys) => {
                        if (defaultExpandedKeys.includes(node.key) || defaultExpandedKeys === "all") {
                            expandedKeys.push(node.key);
                            expandedKeys.push(...parentKeys);
                        }
                    },
                    true
                );
            } else {
                // add initially selected nodes, and apply expansion rules
                visit(
                    datasource,
                    (node, parentKeys) => {
                        if (initSelectionIds[node.type].includes(node.id)) {
                            initSelectionNodes.push(node);
                            if (initialSelectionsExpansion.includes("nodes")) {
                                expandedKeys.push(node.key);
                            }
                            if (initialSelectionsExpansion.includes("parents")) {
                                expandedKeys.push(...parentKeys);
                            }
                        }
                    },
                    true
                );
            }

            setSelectedItems(initSelectionNodes);
            setExpandedKeys([...new Set<string>(expandedKeys)]);
            setInited(true);
            onTreeInitialised?.(initSelectionNodes);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialSelectedItems, loaded, inited]);

    useEffect(() => {
        timeoutRef.current = window.setTimeout(() => setLoaded(true), 300);
        return () => clearTimeout(timeoutRef.current);
    }, []);

    const handleFilter = (searchTerm: string) => {
        setExpandedKeys(getKeysForSearch(searchNodes, searchTerm));
    };

    const handleCheck = (checkedKeys: Key[] | { checked: Key[]; halfChecked: Key[] }, info: any) => {
        const duplicates: { node: TreeItem; parentKeys: string[] }[] = [];
        const nodeIsChecked = info.checkedNodes.find((n: TreeItem) => n.key === info.node.key) !== undefined;

        // walk tree to find any 'duplicates' – nodes with matching ids (but different keys) to the
        // checked/unchecked node.
        // 'check strictly' affects how this should work:
        // - if checkStrictly = true, we only do this on the target node
        // - if checkStrictly = false, include all the target node's descendants as possible sources of duplicates

        const sources = checkStrictly ? [info.node] : getDescendants(info.node, true);
        const sourceIds = sources.map(({ id }) => id);
        const sourceKeys = sources.map(({ key }) => key);

        visit(
            datasource,
            (node, parentKeys) => {
                if (sourceIds.includes(node.id) && !sourceKeys.includes(node.key)) {
                    duplicates.push({
                        node,
                        parentKeys: [...parentKeys],
                    });
                }
            },
            false
        );

        let items: TreeItem[] = [];

        if (nodeIsChecked) {
            items = [...info.checkedNodes, ...duplicates.map((duplicate) => duplicate.node)];
        } else {
            // when unchecking node we need to uncheck any duplicates as well
            // behaviour of unchecked duplicate's parents:
            // if check strictly true - leave parents as they are
            // in check strictly false - uncheck parents as well (not all of their children are checked anymore)
            const duplicatesParentKeys = duplicates.map((duplicate) => duplicate.parentKeys).flat();
            const duplicatesKeys = duplicates.map((duplicate) => getKeysRecursive(duplicate.node)).flat();
            items = info.checkedNodes.filter(
                (n: TreeItem) =>
                    !duplicatesKeys.includes(n.key) && (checkStrictly || !duplicatesParentKeys.includes(n.key))
            );
        }

        setSelectedItems(items);
        onCheck && onCheck(checkedKeys, info);
    };

    const selectedItemKeys = useMemo(() => selectedItems.map((n) => n.key), [selectedItems]);

    // variant/option doesn't need to be added as children of products/modifiers are included by filterSearchable
    const searchNodes = useMemo(
        () => getSearchNodes(datasource, ["root", "category", "product", "modifier"]),
        [datasource]
    );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAutoExpandParent(true);
        const searchTerm = normaliseText(e.target.value.trim());
        setSearchTerm(searchTerm);
        handleFilter(searchTerm);
    };

    const handleExpand = (expandedKeys: Key[]) => {
        setAutoExpandParent(false);
        setExpandedKeys(expandedKeys);
    };

    const titleRender = useCallback(
        (node) => {
            return titleRenderer ? titleRenderer(node, searchTerm) : defaultTitleRenderer(node, searchTerm);
        },
        [searchTerm, titleRenderer]
    );

    return (
        <div className="tree-wrapper">
            {!loaded && (
                <div className="loader__container loader__container--overlay">
                    <Spin />
                </div>
            )}
            <Search
                placeholder={`${searchPlaceholder || "Search"} `}
                allowClear
                onChange={handleSearch}
                className="tree-search"
            />
            {inited && (
                <Tree
                    checkable
                    onCheck={handleCheck}
                    expandedKeys={expandedKeys}
                    checkedKeys={selectedItemKeys}
                    autoExpandParent={autoExpandParent}
                    selectable={false}
                    onExpand={handleExpand}
                    treeData={datasource}
                    titleRender={titleRender}
                    className="data-listing-page__tree"
                    checkStrictly={checkStrictly}
                    disabled={disabled}
                    showIcon={showIcon}
                />
            )}
        </div>
    );
};

function defaultTitleRenderer({ displayName, internalName }: TreeItem, searchTerm: string | undefined) {
    return <Title displayName={displayName} internalName={internalName || undefined} searchTerm={searchTerm} />;
}
