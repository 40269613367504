import { createAction } from "../reducers/edit";
import { scaffoldArchiveAction } from "../../../common/scaffolding/actions/scaffoldArchiveAction";
import { archiveTax } from "../api/archiveTax";

export const archive = (locationId: string) =>
    scaffoldArchiveAction(
        (state) => state.taxes.edit,
        createAction,
        (id) => archiveTax(locationId, id)
    );
