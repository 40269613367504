import { Status } from "core/components/status";
import { LocationSummary } from "features/location";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { useSelector } from "react-redux";
import { getLocationUpdateStatus } from "../selectors/getLocationUpdateStatus";
import { getUpdateStatusInfo } from "../selectors/getUpdateStatusInfo";

interface Props {
    item: LocationSummary;
}

export const ListItemStatus = ({ item }: Props) => {
    const activeLocation = useSelector(getActiveLocation);

    const status = getLocationUpdateStatus(item, activeLocation);

    const info = getUpdateStatusInfo(status);

    const { label, colorScheme } = info || {};

    return info ? <Status colorScheme={colorScheme}>{label}</Status> : null;
};
