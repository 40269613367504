import { AppState } from "features";
import { createSelector } from "reselect";

export const getPriceLists = (state: AppState) =>
    state.priceLists.list.status === "loaded" ? state.priceLists.list.data : undefined;

export const getPriceListsOptions = createSelector(getPriceLists, (priceLists) =>
    priceLists?.map((data) => ({
        label: data.displayName,
        value: data.id,
    }))
);
