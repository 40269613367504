import { PropsWithChildren, ReactElement } from "react";
import { Props as ButtonProps } from "core/components/button";
import { SubmitMode } from "./CrudActionFooter";
import { Duplicate } from "common/icons";
import { ActionButton } from ".";

interface Props {
    buttonProps?: PropsWithChildren<ButtonProps & Record<string, any>>;
    saving: boolean;
    submitMode: SubmitMode;
}

export const DuplicateButton = ({ buttonProps, saving, submitMode }: Props): ReactElement => {
    return (
        <ActionButton
            buttonProps={buttonProps}
            icon={<Duplicate />}
            iconProps={{ outline: true }}
            label="Duplicate"
            role="secondary"
            saving={saving}
            submitMode={submitMode}
            targetSubmitMode="clone"
        />
    );
};
