import * as React from "react";
import { FieldProps } from "formik";
import { ImagePreview } from "components/images/ImagePreview";

import "./ImageUpload.scss";
import { DownloadOutlined } from "@ant-design/icons";
import { Upload, Row, Col, Button } from "antd";
import { UploadChangeParam } from "antd/lib/upload";

export enum UploadFileType {
    Image,
    Video,
}

export interface ImageUploadProps {
    label?: string;
    className?: string;
    disabled?: boolean;
    type?: UploadFileType;
}

export class ImageUpload extends React.Component<FieldProps & ImageUploadProps> {
    render() {
        const {
            disabled,
            field: { value, name },
            className,
            type,
        } = this.props;

        return (
            <Upload.Dragger
                name={name}
                onChange={this.handleChange}
                beforeUpload={this.handleBeforeUpload}
                showUploadList={false}
                disabled={disabled}
                className={`imageupload--container ${className || ""}`}
            >
                {value ? (
                    <div className="imageupload--preview">
                        <ImagePreview previewsrc={value} alt="" type={type} />
                    </div>
                ) : (
                    <Row justify="center" align="middle" className="imageupload--input">
                        <Col span={value ? 12 : 8}>
                            <p className="ant-upload-drag-icon">
                                <DownloadOutlined />
                            </p>
                            <p className="ant-upload-text">Drag here to upload or click the upload button</p>
                        </Col>
                        <Col span={12}>
                            <Button disabled={disabled}>Upload {type === UploadFileType.Video ? "Video" : "Image"}</Button>
                        </Col>
                    </Row>
                )}
            </Upload.Dragger>
        );
    }

    handleChange = (info: UploadChangeParam) => {
        const {
            field: { name },
            form,
        } = this.props;

        form.setFieldValue(name, info.file);
        form.setFieldTouched(name, true);
    };

    handleBeforeUpload = () => {
        return false;
    };
}
