import { FeatureConfiguration } from "..";
import { reducer } from "features/priceList/reducers";
import * as actions from "features/priceList/actions";
//import { CrudRoute } from "common/scaffolding/components/CrudRoute";
import { withPermissions } from "features/location/components/withPermission";
import { ListPriceListsPageContainer } from "./containers/ListPriceListsPageContainer";
import { EditPriceListsPageContainer } from "./containers/EditPriceListsPageContainer";
import { Route } from "react-router";
import { Col } from "antd";

export * from "features/priceList/types";

export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.priceLists = reducer;

    config.addRoutes(config.routes.locationRoutes, [
        <Route
            path={`/:region/:location/menu/pricelists`}
            key="pricelists-list"
            exact
            render={() => (
                <>
                    <Col span={24} md={24} className={`content__col content__col--white content__col--pricelists`}>
                        <div className="content__col-list-white">
                            <Route component={withPermissions(ListPriceListsPageContainer, "pricelist:read")} />
                        </div>
                    </Col>
                </>
            )}
        />,
        <Route
            path={`/:region/:location/menu/pricelists/:id`}
            exact
            key="pricelists-edit"
            render={(props) => (
                <>
                    <Col span={24} md={24} className={`content__col content__col--pricelistsedit`}>
                        <div className="content__col-edit-slim">
                            <Route component={withPermissions(EditPriceListsPageContainer, "pricelist:read")} />
                        </div>
                    </Col>
                </>
            )}
        />,
    ]);
}
