import React, { ReactElement, useMemo } from "react";
import { EditableLocationSettings, TipValue } from "../types";
import { equals } from "common/utility/arrayUtils";
import { FieldErrors } from "components/forms";
import { FormikProps, Field, FieldProps } from "formik";
import { Col, Input, Row } from "antd";
import "./TipsInput.scss";

export interface Props {
    form: FormikProps<EditableLocationSettings>;
    disabled: boolean;
}

const defaultTipAt = (form: FormikProps<EditableLocationSettings>, n: number): number => {
    return form.initialValues.defaultTipLevels[n];
};

const validInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;

    // allow user to clear field
    if (rawValue === "") {
        return true;
    }

    if (!reg.test(rawValue) || isNaN(parseFloat(e.target.value))) {
        return false;
    }

    return true;
};

// for input allow decimals that won't actually pass
// pass validation e.g. 0.1 otherwise input would feel broken
const reg = new RegExp("^[0-9]{0,2}(\\.([0-9]{0,1})?)?$");

export const TipsInput = ({ form, disabled }: Props): ReactElement => {
    const levels = form.values.tipOptions.levels;
    const defaults = form.values.defaultTipLevels;

    const matchesDefaults: boolean = useMemo(() => {
        if (!levels) {
            return true;
        }
        const combined = defaults.map((level, index) => Number(levels[index] || level));
        return equals(combined, defaults);
    }, [defaults, levels]);

    return (
        <div className={"tips-input"}>
            {form.values.defaultTipLevels.map((_, index) => (
                <FieldErrors
                    className="tips-input-field__wrapper"
                    key={`tipOptions.level-${index}`}
                    name={`tipOptions.levels[${index}]`}
                    form={form}
                >
                    <Row>
                        <Col span={24} sm={8}>
                            <Field name={`tipOptions.levels[${index}]`} validateOnBlur>
                                {({
                                    form: { setFieldValue },
                                    field: { name, onChange, onBlur },
                                }: FieldProps<TipValue>) => (
                                    <Input
                                        name={name}
                                        value={(levels && levels[index]) || undefined}
                                        onChange={(e) => {
                                            if (validInputChange(e)) {
                                                // when setting a value, ensure all values are set
                                                form.values.defaultTipLevels.forEach((level, i) => {
                                                    if (i !== index && levels && !levels[i]) {
                                                        setFieldValue(`tipOptions.levels.${i}`, level);
                                                    }
                                                });

                                                onChange(e);
                                            }
                                            validInputChange(e) && onChange(e);
                                        }}
                                        onBlur={(e) => {
                                            // changes made are same as defaults... reset to null for consistency
                                            if (matchesDefaults) {
                                                setFieldValue(`tipOptions.levels`, [null, null, null]);
                                            } else {
                                                if (e.target.value === "" || e.target.value === undefined) {
                                                    setFieldValue(
                                                        `tipOptions.levels.${index}`,
                                                        form.values.defaultTipLevels[index]
                                                    );
                                                }
                                            }
                                            onBlur(e);
                                        }}
                                        placeholder={`${defaultTipAt(form, index)}`}
                                        addonAfter={"%"}
                                        type="text"
                                        inputMode="decimal"
                                        className="tips-input-field"
                                        autoComplete="off"
                                        disabled={disabled}
                                    />
                                )}
                            </Field>
                        </Col>
                    </Row>
                </FieldErrors>
            ))}
            {!matchesDefaults && (
                <button
                    className="tips-input-field__reset"
                    type="button"
                    disabled={disabled}
                    onClick={() => {
                        form.setFieldValue(`tipOptions.levels`, [null, null, null]);
                    }}
                >
                    Reset to defaults
                </button>
            )}
        </div>
    );
};
