import { FeatureConfiguration } from "features";
import * as actions from "./actions";
import { withPermissions } from "features/location/components/withPermission";
import { Route } from "react-router";
import { Col } from "antd";
import { SurchargesPageContainer } from "./containers/SurchargesPageContainer";
import { reducer } from "./reducers";

export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.surcharges = reducer;

    config.addRoutes(config.routes.locationRoutes, [
        <Route
            path={`/:region/:location/menu/surcharges`}
            key="surcharges"
            exact
            render={() => (
                <Col span={24} md={24} className="content__col content__col--white content__col--surcharges">
                    <div className="content__col-edit">
                        <Route component={withPermissions(SurchargesPageContainer, "surcharges:read")} />
                    </div>
                </Col>
            )}
        />,
    ]);
}
