import { EditWrapper } from "common/scaffolding/components/EditWrapper";
import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { FeatureConfiguration } from "features";
import { withPermissions } from "features/location/components/withPermission";
import { TakeawayPage } from "features/takeaway/components/edit/TakeawayPage";
import { Route } from "react-router";

const EditTakeawayPage = withPermissions(TakeawayPage, "settings:read");

export default function (config: FeatureConfiguration) {
    config.addRoutes(config.routes.locationRoutes, [
        <Route
            path={`/:region/:location/takeaway`}
            key="takeaway-settings"
            exact
            render={() => (
                <EditWrapper contentWidth={ContentWidth.NARROW}>
                    <EditTakeawayPage />
                </EditWrapper>
            )}
        />,
    ]);
}
