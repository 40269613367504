import { createAction } from "../reducers/list";
import { Dispatch } from "redux";

import { State } from "../reducers";

import { opsApi } from "API";
import { DailySales, DailySalesReport } from "../types";
import { fetchCsvExport } from "./fetchCsvExport";
import moment from "moment";

export const exportCsv = (location: string, startDate: string, endDate: string) => {
    return async () => await fetchCsvExport(location, moment(startDate), moment(endDate));
};

export const list = (location: string, startDate: string, endDate: string) => {
    const query: string = `query ($location: ID!, $startDate: String!, $endDate: String!) {
        dailySales(locationId: $location, startDate: $startDate, endDate: $endDate) {
            diningDate
            sales
            refunds
            tips
            total
            surcharges
            venueServiceFees
        }
      }`;

    return async (dispatch: Dispatch, getState: () => State) => {
        try {
            dispatch(createAction.loading());

            const items = (await opsApi.graphQLQuery<GraphQLResponse>(query, { location, startDate, endDate })).data
                .dailySales;

            const report = buildReport(items);

            dispatch(createAction.loaded(report));
        } catch (e) {
            dispatch(createAction.failed(e));
        }
    };
};

interface GraphQLResponse {
    dailySales: GraphQLDailySales[];
}

interface GraphQLDailySales {
    diningDate: string;
    sales: number;
    tips: number;
    refunds: number;
    total: number;
    surcharges: number;
    venueServiceFees: number;
}

function buildReport(items: GraphQLDailySales[]): DailySalesReport {
    let totalSales = 0;
    let totalTips = 0;
    let totalRefunds = 0;
    let grandTotal = 0;
    let totalSurcharges = 0;
    let totalVenueServiceFees = 0;

    for (let item of items) {
        totalSales += item.sales;
        totalTips += item.tips;
        totalRefunds += item.refunds;
        grandTotal += item.total;
        totalSurcharges += item.surcharges;
        totalVenueServiceFees += item.venueServiceFees;
    }

    return {
        items: items.map(mapDailySales),
        totalSales,
        totalTips,
        totalRefunds,
        grandTotal,
        totalSurcharges,
        totalVenueServiceFee: totalVenueServiceFees,
    };
}

function mapDailySales({
    diningDate,
    sales,
    tips,
    refunds,
    total,
    surcharges,
    venueServiceFees,
}: GraphQLDailySales): DailySales {
    return {
        diningDate: new Date(diningDate),
        sales,
        tips,
        refunds,
        total,
        surcharges,
        venueServiceFees,
    };
}
