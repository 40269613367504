import { opsApi } from "API";
import { GraphQLItemId } from "common/scaffolding/types";
import { PackageUpdateInput } from "..";
import { isNew } from "common/utility/modelUtils";

export const savePackage = async (
    locationId: string,
    packageId: string | undefined,
    packageData: PackageUpdateInput
) => {
    const isCreate = isNew(packageId);

    // remove id ('new') if we are creating a new package
    const data = isCreate
        ? {
              ...packageData,
              id: undefined,
          }
        : packageData;

    const res = isCreate
        ? (
              await opsApi.graphQLQuery<{ createPackage: GraphQLItemId }>(createQuery, {
                  locationId,
                  package: data,
              })
          ).data.createPackage
        : (
              await opsApi.graphQLQuery<{ updatePackage: GraphQLItemId }>(updateQuery, {
                  locationId,
                  packageId,
                  package: data,
              })
          ).data.updatePackage;

    return res;
};

const updateQuery = `
    mutation ($locationId: ID!, $packageId: ID!, $package: PackageUpdateInput!) {
        updatePackage(locationId: $locationId, id: $packageId, data: $package) {
            id
        }
    }
`;

const createQuery = `
    mutation ($locationId: ID!, $package: PackageUpdateInput!) {
        createPackage(locationId: $locationId, data: $package) {
            id
        }
    }
`;
