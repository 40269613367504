import styles from "./Modal.module.scss";
import classNames from "classnames";
import {
    createContext,
    MouseEvent,
    PropsWithChildren,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { Card, Row, Title } from "../card";
import { Content } from ".";
import FocusTrap from "focus-trap-react";
import { AnimatePresence, motion } from "framer-motion";
import uniqueId from "lodash/uniqueId";
import { useMedia, usePrevious } from "common/hooks";
import { createPortal } from "react-dom";
import { CloseButton } from "../closeButton";

export enum CardWidth {
    NARROW = "narrow",
    STANDARD = "standard",
    WIDE = "wide",
}

export type VerticalAlign = "top" | "bottom";

export type CloseSource = "close" | "click" | "clickOutside" | "escape" | "cancel" | "confirm" | undefined;

export type CloseEvent = MouseEvent<HTMLElement> | KeyboardEvent;

export interface CloseMeta {
    source: CloseSource;
}

interface ModalState {
    visible: boolean;
    onClose: (event: CloseEvent, meta: CloseMeta) => void;
}

export const ModalContext = createContext<ModalState>({
    visible: false,
    onClose: () => {},
});

interface Props {
    anyClickCloses?: boolean;
    cardClassName?: string;
    className?: string;
    clickOutsideCloses?: boolean;
    contentContainerClassName?: string;
    escapeCloses?: boolean;
    header?: ReactNode;
    footer?: ReactNode;
    initialFocus?: HTMLElement | SVGElement | string | false;
    onClose: (event: CloseEvent, meta: CloseMeta) => void;
    onOpen?: () => void;
    hideClose?: boolean;
    visible: boolean;
    width?: CardWidth;
    title?: string;
    verticalAlign?: VerticalAlign;
}

const variants = {
    visible: { opacity: 1, transition: { ease: "easeIn", duration: 0.2, when: "beforeChildren" } },
    hidden: { opacity: 0, transition: { ease: "easeOut", duration: 0.3, when: "afterChildren" } },
};

const cardVariantsSlide = {
    visible: {
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { type: "spring", stiffness: 300, damping: 25 },
    },
    hidden: {
        y: "100%",
        opacity: 1,
        scale: 1,
        transition: { ease: "backIn" },
    },
};

const cardVariantsScale = {
    visible: {
        scale: 1,
        opacity: 1,
        y: 0,
        transition: {
            scale: { type: "spring", stiffness: 300, damping: 20 },
            opacity: { ease: "linear", duration: 0.12 },
        },
    },
    hidden: {
        scale: 0.5,
        opacity: 0,
        y: 0,
        transition: {
            scale: { type: "ease", duration: 0.12 },
            opacity: { ease: "linear", duration: 0.12 },
        },
    },
};

export function Modal({
    anyClickCloses = false,
    cardClassName,
    children,
    className,
    clickOutsideCloses = true,
    contentContainerClassName,
    escapeCloses = true,
    header,
    footer,
    initialFocus,
    onClose,
    onOpen,
    visible = false,
    hideClose = false,
    width = CardWidth.STANDARD,
    title,
    verticalAlign,
}: PropsWithChildren<Props>) {
    const [modalId, _] = useState(uniqueId("modal-"));

    const buttonProps: React.ComponentPropsWithoutRef<"button"> = {
        type: "button",
    };
    const focusTrapOptions: any = {};

    // we must give something focus or FocusTrap will throw error
    if (!initialFocus) {
        if (hideClose) {
            // close button not present; fallback to Content container
            focusTrapOptions.initialFocus = false;
            focusTrapOptions.fallbackFocus = "[data-fallbackfocus]";
        } else {
            // give init focus to close button
            buttonProps["data-initfocus"] = true;
            focusTrapOptions.initialFocus = "[data-initfocus]";
        }
    } else {
        focusTrapOptions.initialFocus = initialFocus;
    }

    useEffect(() => {
        const onKeydown = (event: KeyboardEvent) => {
            visible && escapeCloses && event.key === "Escape" && onClose(event, { source: "escape" });
        };

        if (visible && escapeCloses) {
            document.addEventListener("keydown", onKeydown);
        }

        return () => {
            document.removeEventListener("keydown", onKeydown);
        };
    }, [visible, escapeCloses, onClose]);

    const previousVisible = usePrevious(visible);

    useEffect(() => {
        if (visible && !previousVisible) {
            onOpen?.();
        }
    }, [onOpen, previousVisible, visible]);

    const handleClick = useCallback(
        (event: MouseEvent<HTMLElement>) => {
            if (anyClickCloses || (clickOutsideCloses && event.currentTarget === event.target)) {
                onClose(event, { source: anyClickCloses ? "click" : "clickOutside" });
            }
        },
        [onClose, clickOutsideCloses, anyClickCloses]
    );

    const isMedium = useMedia("(min-width: 672px)");

    const containerClasses = classNames({
        [styles.container]: !verticalAlign,
        [styles[`container--${verticalAlign}`]]: verticalAlign,
    });

    return (
        <ModalContext.Provider
            value={{
                visible,
                onClose,
            }}
        >
            <AnimatePresence>
                {visible && (
                    <motion.div
                        key={modalId}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={variants}
                        className={classNames(styles.root, className)}
                    >
                        <FocusTrap focusTrapOptions={focusTrapOptions}>
                            <div className={containerClasses} onMouseDown={handleClick}>
                                <motion.div
                                    className={styles[`cardContainer--${width}`]}
                                    key={`{modalId}-card`}
                                    variants={isMedium ? cardVariantsScale : cardVariantsSlide}
                                    role="dialog"
                                    aria-labelledby={`${modalId}-title`}
                                    aria-modal
                                >
                                    <Card className={classNames(styles.card, cardClassName)} elevation="large">
                                        <Row border="bottom">
                                            {title ? <Title id={`${modalId}-title`} title={title} /> : <span />}
                                            {!hideClose && (
                                                <CloseButton
                                                    onClick={(event: MouseEvent<HTMLElement>) => {
                                                        onClose(event, { source: "close" });
                                                    }}
                                                    aria-label="close dialog"
                                                    {...buttonProps}
                                                />
                                            )}
                                        </Row>
                                        {header && <Row>{header}</Row>}
                                        <Content className={contentContainerClassName}>{children}</Content>
                                        {footer && <Row border="top">{footer}</Row>}
                                    </Card>
                                </motion.div>
                            </div>
                        </FocusTrap>
                    </motion.div>
                )}
            </AnimatePresence>
        </ModalContext.Provider>
    );
}

interface ModalRenderer {
    target: string | HTMLElement;
}

export const ModalRenderer = ({ children, target }: PropsWithChildren<ModalRenderer>) => {
    const containerNode = useRef<Element | null>(null);

    useEffect(() => {
        if (typeof target === "string") {
            const node = document.querySelector(target);
            if (node) {
                containerNode.current = node;
            }
        } else {
            containerNode.current = target;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (containerNode.current) {
        return createPortal(children, containerNode.current);
    }

    return <>{children}</>;
};
