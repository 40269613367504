import { AppState } from "features/state";
import * as React from "react";
import { Props as IconProperties } from "core/components/icon";

export interface AbstractNavItem {
    text: string | ((options: NavNodeTextOptions) => string);
    icon?: React.FC;
    iconProperties?: IconProperties;
    category?: string;
}

export interface NavItemGroup extends AbstractNavItem {
    children: NavItem[];
}

export interface NavGroup extends AbstractNavItem {
    navItemGroups: NavItemGroup[];
}

export type NavItemDisplayFilter = (state: AppState) => boolean;

export interface NavItem extends AbstractNavItem {
    route?: string;
    externalUrl?: string;
    permission: string;
    featureToggle?: string;
    configEnabled?: boolean;
    legacy?: boolean;
    includeForCurrencies?: string[];
    displayFilter?: NavItemDisplayFilter | NavItemDisplayFilter[];
    includeForParentLocation?: boolean;
    includeForParentLocationOnly?: boolean;
}

export type NavNode = NavItem | NavGroup | null;

export type NavNodeTextOptions = {
    isParentLocation: boolean;
};

export function isNavItem(node: any): node is NavItem {
    return node.route !== undefined || node.externalUrl !== undefined;
}

export function isNavItemGroup(node: any): node is NavItemGroup {
    return node.children !== undefined;
}

export function isNavGroup(node: any): node is NavGroup {
    return node.navItemGroups !== undefined;
}
