import { opsApi } from "API";
import { GraphQLLocation } from "features/location/api/editLocation";
import { LocationRelations } from "../types";

export const saveLocationRelations = async (
    locationId: string,
    locationRelationsUpdate: LocationRelations
): Promise<GraphQLLocation> => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId, locationRelationsUpdate });
    return res.data.update;
};

type GraphQLPayload = { update: LocationRelations };

const query = `
    mutation ($locationId: ID!, $locationRelationsUpdate: LocationRelationsUpdateInput!){
        updateLocationRelations(locationId: $locationId, locationRelationsUpdate: $locationRelationsUpdate) {
            childLocationIds
        }
    }
`;
