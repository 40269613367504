import { createSelector } from "reselect";
import * as Yup from "yup";
import { PosFieldDefinition } from "features/location";
import { getPosConfigurationFields } from "./getPosConfigurationFields";

export const getPosConfigurationSchema = createSelector(getPosConfigurationFields, (fields) => {
    const yupFields = fields.reduce((ag, field) => {
        ag[field.name] = getFieldSchema(field);

        return ag;
    }, {});

    return Yup.object<{}>().shape(yupFields);
});

function getFieldSchema(field: PosFieldDefinition) {
    let schema = Yup.string().nullable(true);

    if (field.validation) {
        const regex = new RegExp(field.validation);

        schema = schema.matches(regex);

        const required = !regex.test("");

        if (required) {
            schema = schema.required("Required");
        }
    }

    if (field.input === "secret") {
        return Yup.lazy<string | Symbol>((value) => {
            return typeof value === "symbol" ? Yup.mixed() : schema;
        });
    }

    return schema;
}
