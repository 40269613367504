import { ActionFooter, StatusMessage } from "core/components/actionFooter";
import { AppState } from "features";
import { Button } from "core/components/button";
import { Card, Row, Title } from "core/components/card";
import { CardsContainer } from "core/components/card/CardsContainer";
import { ChildLocationsTable } from "./ChildLocationsTable";
import { Form, Formik } from "formik";
import { getActiveLocation, getLocationPermissions } from "features/location/selectors/getLocationPermissions";
import { getGroupListState } from "features/group/selectors";
import { getIsParentLocation } from "features/location/selectors/getIsParentLocation";
import { getLocationsListState } from "features/location/selectors/getLocationsList";
import { getSortedSelections } from "../selectors/getSortedSelections";
import { isLoaded } from "common/loader/isLoaded";
import { LinkChildModal } from "./LinkChildModal";
import { list } from "features/location/actions";
import { list as groupList } from "features/group/actions";
import { LocationRelations } from "../types/LocationRelations";
import { ModalRenderer } from "core/components/modal";
import { PageContainer } from "core/components/pageContainer/PageContainer";
import { PageHeader } from "core/components/pageHeader";
import { PageLoading } from "core/components/pageLoading";
import { Prompt } from "core/components/modal/Prompt";
import { Redirect } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLoadStatus, useLocationBasePath, useOnApplicationFocus } from "common/hooks";
import * as actions from "../actions";

interface Props {}

export const ChildLocationsPage = ({}: Props) => {
    const dispatch = useDispatch();

    const activeLocation = useSelector(getActiveLocation);
    const isParentLocation = useSelector(getIsParentLocation);

    const locationListState = useSelector(getLocationsListState);
    const saveStatus = useSelector((state: AppState) => state.locationRelations.edit.saveStatus);

    const initialSelections = useSelector<AppState>((state) =>
        getSortedSelections(state, activeLocation?.childLocationIds || [])
    ) as string[];

    const groupListState = useSelector(getGroupListState);

    const locationBasePath = useLocationBasePath();

    const permissions = useSelector(getLocationPermissions);
    const disableFields = !permissions.has("location:parent:manage");

    const [linkChildModalVisible, setLinkChildModalVisible] = useState(false);

    const initialValues: LocationRelations = useMemo(
        () => ({
            childLocationIds: initialSelections,
        }),
        [initialSelections]
    );

    const fetch = useCallback(() => {
        !isLoaded(locationListState) && dispatch(list());
        !isLoaded(groupListState) && dispatch(groupList());
    }, [dispatch, groupListState, locationListState]);

    const fetchStatus = useLoadStatus([locationListState.status], fetch);

    const handleSubmit = useCallback(
        (values) => {
            activeLocation && dispatch(actions.save(activeLocation, values));
        },
        [activeLocation, dispatch]
    );

    const handleBlockTransition = useCallback(() => true, []);

    const handleLinkChildModalClose = useCallback(() => {
        setLinkChildModalVisible(false);
    }, []);

    const handleFocus = useCallback(() => {
        dispatch(actions.reload());
    }, [dispatch]);

    useOnApplicationFocus(handleFocus, 5000);

    if (!isParentLocation && locationBasePath) {
        return <Redirect to={locationBasePath} />;
    }

    if (fetchStatus === "loading" || fetchStatus === "unloaded") {
        return <PageLoading message="Loading venues" />;
    }

    return (
        <PageContainer>
            <PageHeader title="Linked venues" subtitle="Linked venues can recieve updates from this brand." />
            <Formik onSubmit={handleSubmit} initialValues={initialValues}>
                {(form) => (
                    <>
                        <CardsContainer as={Form} id="child-locations-list">
                            <Card>
                                <Row collapse="down">
                                    <Title title="Manage linked venues" />
                                    <Button
                                        disabled={disableFields}
                                        colorScheme="interactive"
                                        type="button"
                                        onClick={() => {
                                            setLinkChildModalVisible(true);
                                        }}
                                    >
                                        Link venues
                                    </Button>
                                </Row>
                                <ChildLocationsTable disableFields={disableFields} />
                            </Card>

                            <ActionFooter position="fixed">
                                <StatusMessage />
                                <Button role="secondary" type="button" disabled={!form.dirty} onClick={form.resetForm}>
                                    Cancel
                                </Button>
                                <Button loading={saveStatus === "saving"} type="submit" disabled={!form.dirty}>
                                    Save
                                </Button>
                            </ActionFooter>

                            <ModalRenderer target="#modal">
                                <Prompt
                                    title="Unsaved changes"
                                    when={form.dirty}
                                    blockTransition={handleBlockTransition}
                                    cancelLabel="Keep editing"
                                    confirmLabel="Discard changes"
                                >
                                    Change have not been saved. Are you sure you want to leave this page?
                                </Prompt>
                            </ModalRenderer>

                            <ModalRenderer target="#modal">
                                <LinkChildModal
                                    name="childLocationIds"
                                    visible={linkChildModalVisible}
                                    onClose={handleLinkChildModalClose}
                                />
                            </ModalRenderer>
                        </CardsContainer>
                    </>
                )}
            </Formik>
        </PageContainer>
    );
};
