import { Divider } from "antd";
import { EditRow } from "common/scaffolding/components/EditRow";
import { FieldErrors, TextInput } from "components/forms";
import { QuickCopyInput } from "components/forms/QuickCopyInput";
import { SwitchInput } from "components/forms/SwitchInput";
import { Button } from "core/components/button";
import { getCrudPermissions } from "features/location/selectors/getCrudPermissions";
import { FastField, Field, FormikProps } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAction as trackingActions } from "common/appInsights/actions/track";
import { downloadFile } from "common/utility/fileUtils";
import { EditableSection } from "features/section/types";
import { SelectDemoExpiry } from "./SelectDemoExpiry";
import { usePosField } from "components/forms/posUI/usePosField";
import { ActiveLocation } from "features/location";
import { PosField } from "components/forms/posField/PosField";

interface Props {
    location: ActiveLocation;
    initialValues: EditableSection;
    disableFields: boolean;
    form: FormikProps<any>;
}

export const EditSectionDemo = ({ location, initialValues, disableFields, form }: Props) => {
    const demoQrPermissions = useSelector(getCrudPermissions).demoQR;
    const disableDemoFields = disableFields || !demoQrPermissions.canUpdate;
    const [downloading, setDownloading] = useState(false);

    const track = useTracker();
    const downloadQrCode = async () => {
        const timeout = setTimeout(() => setDownloading(true), 250);
        try {
            if (!initialValues.demoQr) return;
            const qrCodeGeneratorUrl = `https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=${window.encodeURIComponent(
                initialValues.demoQr.url
            )}&choe=UTF-8`;
            await downloadFile(qrCodeGeneratorUrl, `demo-${initialValues.displayName}.png`);
            track.qrCodeDownloaded();
        } finally {
            clearTimeout(timeout);
            setDownloading(false);
        }
    };

    const tableNumberPosField = usePosField({
        restaurantLocation: location,
        operationName: "OrderCreate",
        fieldName: "tableNumber",
    });

    if (!demoQrPermissions.canRead || !initialValues.demoQr) return null;

    return (
        <>
            <EditRow title="Demo QR code" subTitle="Create a demo mode for this section.">
                <FieldErrors name="demoQr.isEnabled" form={form}>
                    <FastField name="demoQr.isEnabled" component={SwitchInput} disabled={disableDemoFields} />
                </FieldErrors>
            </EditRow>
            {form.values.demoQr.isEnabled && (
                <>
                    <EditRow
                        title="Expiry (required)"
                        subTitle="The URL/QR code will no longer be available once the expiry time is reached."
                    >
                        <FieldErrors name="demoQr.selectedTimeToLive" form={form}>
                            <FastField
                                name="demoQr.selectedTimeToLive"
                                component={SelectDemoExpiry}
                                initialValues={initialValues}
                                disabled={disableDemoFields}
                            />
                        </FieldErrors>
                    </EditRow>
                    <EditRow title="Table number (required)" subTitle="This shouldn’t be an existing table number.">
                        <FieldErrors name="demoTableNumber" form={form}>
                            {tableNumberPosField ? (
                                <Field
                                    name="demoTableNumber"
                                    component={PosField}
                                    definition={tableNumberPosField}
                                    hideMarkRequired
                                />
                            ) : (
                                <FastField name="demoTableNumber" component={TextInput} />
                            )}
                        </FieldErrors>
                    </EditRow>
                    <EditRow title="URL" subTitle="Unique URL assigned to this section.">
                        <FieldErrors name="demoQr.url" form={form}>
                            <FastField
                                name="demoQr.url"
                                component={QuickCopyInput}
                                readOnly
                                disabled={disableDemoFields}
                            />
                        </FieldErrors>
                    </EditRow>
                    <EditRow title="QR code" subTitle="Unique QR code assigned to this section.">
                        <Button type="button" role="secondary" onClick={downloadQrCode} loading={downloading}>
                            {downloading ? "Downloading" : "Download QR"}
                        </Button>
                    </EditRow>
                </>
            )}
            <Divider />
        </>
    );
};

const useTracker = () => {
    const dispatch = useDispatch();
    return {
        qrUrlCopied: () => dispatch(trackingActions.track({ event: { name: "SECTIONS/DEMO_QR_COPIED" } })),
        qrCodeDownloaded: () => dispatch(trackingActions.track({ event: { name: "SECTIONS/DEMO_QR_DOWNLOADED" } })),
    };
};
