import { disableRoots } from "common/data/treeUtils";
import { getCatalogueTreeSelector } from "common/selectors/getCatalogueTree";
import { CatalogueItem } from "features/catalogue";
import { createSelector } from "reselect";

const modifierProductTreeOptions = {
    showProducts: true,
    showModifiers: false,
    showCategories: true,
    showUntypedProducts: true,
    showProductsWithoutCategories: true,
    hideEmptyCategories: true,
};

const getModifierProductsTreeSelector = getCatalogueTreeSelector(modifierProductTreeOptions);

const getModifierProductTreeWithoutLinkedItemsSelector = getCatalogueTreeSelector({
    ...modifierProductTreeOptions,
    filterProducts: (item: CatalogueItem) => !item.isLinked,
});

export const getModifierProductTree = createSelector(getModifierProductsTreeSelector, (catalogueTree) => {
    const products = catalogueTree?.[0]?.children;

    if (!products?.length) {
        return [];
    }

    return disableRoots(products);
});

export const getModifierProductTreeWithoutLinkedItems = createSelector(
    getModifierProductTreeWithoutLinkedItemsSelector,
    (catalogueTree) => {
        const products = catalogueTree?.[0]?.children;

        if (!products?.length) {
            return [];
        }

        return disableRoots(products);
    }
);
