import { createSelector } from "reselect";
import { CatalogueItem, CatalogueModifierItem } from "features/catalogue/types";
import { TreeItem } from "common/types/TreeItem";
import { FilterCategory } from "features/catalogue/types/FilterCategory";
import { getCategoryFilters, UNASSIGNED_CATEGORY_FILTER } from "features/catalogue/selectors/getCategoryFilters";
import { getProductCatalogueItems } from "features/catalogue/selectors/getProductCatalogueItems";
import { getModifierCatalogueItems } from "features/catalogue/selectors/getModifierCatalogueItems";
import { showPriceListsItemSupport } from "features/priceList/selectors/showPriceListsFilter";

interface CatalogueTreeOptions {
    showCategories?: boolean;
    showProducts?: boolean;
    showModifiers?: boolean;
    showUntypedProducts?: boolean;
    showProductsWithoutCategories?: boolean;
    hideEmptyCategories?: boolean;
    filterModifiers?: (item: CatalogueModifierItem) => boolean;
    filterProducts?: (item: CatalogueItem) => boolean;
    productDisabledTest?: (item: CatalogueItem) => boolean;
}

const mapDataToTree = (
    categories: FilterCategory[],
    modifiers: CatalogueModifierItem[],
    products: CatalogueItem[],
    options: CatalogueTreeOptions
) => {
    const nodes: TreeItem[] = [];

    categories = categories.filter((c) => c.id !== UNASSIGNED_CATEGORY_FILTER.id);

    const { filterModifiers, filterProducts, showModifiers, showProducts, showUntypedProducts } = options;

    if (showProducts) {
        products = filterProducts ? products.filter(filterProducts) : products;

        const productsNode: TreeItem = {
            id: "products",
            displayName: "Products",
            title: "",
            type: "root",
            key: "products",
            children: [],
        };

        const drinksRoot = root("drinks", "Drinks", "drink", categories, products, options);
        const foodRoot = root("food", "Food", "food", categories, products, options);
        let untypedRoot;

        if (showUntypedProducts) {
            untypedRoot = root("untyped", "No type", "unknown", categories, products, options);
        }

        if (drinksRoot.children!.length) {
            productsNode.children!.push(drinksRoot);
        }

        if (foodRoot.children!.length) {
            productsNode.children!.push(foodRoot);
        }

        if (untypedRoot?.children!.length) {
            productsNode.children!.push(untypedRoot);
        }

        if (productsNode.children!.length) {
            nodes.push(productsNode);
        }
    }

    if (showModifiers) {
        const modifierNode: TreeItem = {
            id: "modifiers",
            displayName: "Modifiers",
            title: "",
            type: "root",
            key: "modifiers",
        };

        if (filterModifiers) {
            const filteredModifiers = modifiers.filter(filterModifiers);
            if (filteredModifiers.length) {
                modifierNode.children = mapModifiersToTree(filteredModifiers);
            }
        } else {
            modifierNode.children = mapModifiersToTree(modifiers);
        }

        if (modifierNode.children?.length) {
            nodes.push(modifierNode);
        }
    }

    return nodes;
};

// create category root e.g food/drinks
const root = (
    id: string,
    displayName: string,
    type: string | undefined,
    categories: FilterCategory[],
    products: CatalogueItem[],
    options: CatalogueTreeOptions = {}
): TreeItem => {
    const allProductsOfType = products.filter((p) => p.templateType === type);

    if (options.showCategories) {
        let children = categories
            .filter((c) => c.type === type)
            .map<TreeItem>((c) => {
                const categoryProducts = mapProductsToTree(products, options, c.id);
                const disabled = categoryProducts.length && categoryProducts.every((p) => p.disabled);

                return {
                    id: c.id,
                    displayName: c.displayName,
                    title: "",
                    type: "category",
                    internalName: c.internalName,
                    key: c.id,
                    children: categoryProducts,
                    ...(disabled && { disabled: true }),
                };
            });

        if (options.hideEmptyCategories) {
            children = children.filter((c) => c.children?.length);
        }

        if (options.showProductsWithoutCategories) {
            const uncategorized = mapProductsToTree(allProductsOfType, options, "uncategorized");

            if (uncategorized.length) {
                const disabled = uncategorized.every((p) => p.disabled);

                children.push({
                    id: `${type}-uncategorized`,
                    displayName: "No category",
                    internalName: undefined,
                    title: "",
                    type: "category",
                    key: `${type}-uncategorized`,
                    children: uncategorized,
                    ...(disabled && { disabled: true }),
                });
            }
        }

        const disabled = children.length && children.every((c) => c.disabled);

        return {
            id,
            displayName,
            children,
            title: "",
            type: "root",
            key: id,
            ...(disabled && { disabled: true }),
        };
    }

    return {
        id,
        displayName,
        children: mapProductsToTree(allProductsOfType, options),
        title: "",
        type: "root",
        key: id,
    };
};

const mapProductsToTree = (items: CatalogueItem[], options: CatalogueTreeOptions, parentId?: string): TreeItem[] => {
    let categoryFilter;

    if (!options.showCategories) {
        categoryFilter = () => true;
    } else {
        if (parentId === "uncategorized") {
            categoryFilter = (p: CatalogueItem) => !p.categories || !p.categories.length;
        } else {
            categoryFilter = (p: CatalogueItem) => p.categories && p.categories.some((c) => c.id === parentId);
        }
    }

    const parentKey = parentId ? `${parentId}-` : "";

    return items.filter(categoryFilter).map((p) => {
        const disabled = options.productDisabledTest ? options.productDisabledTest(p) : false;

        return {
            displayName: p.displayName,
            id: p.id!,
            type: "product",
            internalName: p.internalName,
            isLinked: p.isLinked,
            title: "",
            key: `${parentKey}${p.id}`,
            ...(disabled && { disabled: true }),
            ...(!p.children?.length && { isLeaf: true }),
            ...(p.children?.length && {
                children: p.children.map((child) => {
                    return {
                        displayName: child.displayName,
                        id: child.id!,
                        isLeaf: true,
                        title: "",
                        type: "variant",
                        internalName: child.internalName,
                        parent: p.id,
                        key: `${parentKey}${p.id!}-${child.id!}`,
                        ...(disabled && { disabled: true }),
                    };
                }),
            }),
        };
    });
};

const mapModifiersToTree = (items: CatalogueItem[]): TreeItem[] =>
    items.map((m) => {
        return {
            id: m.id!,
            displayName: m.displayName,
            title: "",
            type: "modifier",
            internalName: m.internalName,
            isLinked: m.isLinked,
            key: m.id!,
            children: m.children!.map((child) => {
                return {
                    displayName: child.displayName,
                    id: child.id!,
                    isLeaf: true,
                    title: "",
                    type: "option",
                    internalName: child.internalName,
                    parent: m.id,
                    key: `${m.id!}-${child.id!}`,
                };
            }),
        };
    });

export const getCatalogueTreeSelector = (options: CatalogueTreeOptions) =>
    createSelector(
        getCategoryFilters,
        getModifierCatalogueItems,
        getProductCatalogueItems,
        (categories, modifiers, products): TreeItem[] => {
            return mapDataToTree(categories, modifiers, products, options);
        }
    );

export const getCatalogueTree = getCatalogueTreeSelector({
    showCategories: true,
    showModifiers: true,
    showProducts: true,
    showUntypedProducts: false,
});

export const getPriceOverrideCatalogueTree = createSelector(
    getCategoryFilters,
    getModifierCatalogueItems,
    getProductCatalogueItems,
    showPriceListsItemSupport,
    (categories, modifiers, products, { acceptOverrideProductPrices, acceptOverrideModifierPrices }): TreeItem[] => {
        const filterModifiers = !acceptOverrideModifierPrices
            ? (item: CatalogueModifierItem) =>
                  !acceptOverrideModifierPrices && (item.type !== "modifier" || !!item.upsell)
            : undefined;

        const options: CatalogueTreeOptions = {
            showCategories: true,
            showModifiers: true,
            showProducts: acceptOverrideProductPrices,
            showUntypedProducts: false,
            filterModifiers,
        };

        return mapDataToTree(categories, modifiers, products, options);
    }
);
