import { ActiveLocation } from "features/location/types/ActiveLocation";
import { LocationSummary } from "features/location/types/LocationSummary";
import { UpdateStatus } from "../types";

export function getLocationUpdateStatus(location: LocationSummary, activeLocation: ActiveLocation | undefined) {
    const { lastItemUpdatedUtc } = activeLocation || {};

    const { parentLocationUpdateStatus } = location || {};

    const { lastParentImportUtc, hasBeenSynced } = parentLocationUpdateStatus || {};

    // last time any item was updated in the parent location
    const lastItemUpdated = lastItemUpdatedUtc ? new Date(lastItemUpdatedUtc) : null;

    // last time child location imported from parent
    const lastParentImport = lastParentImportUtc ? new Date(lastParentImportUtc) : null;

    if (!hasBeenSynced || lastItemUpdated === null || lastParentImport === null) {
        return UpdateStatus.NEVER_UPDATED;
    } else if (lastParentImport.getTime() < lastItemUpdated.getTime()) {
        return UpdateStatus.DUE_TO_UPDATE;
    }

    return UpdateStatus.UP_TO_DATE;
}
