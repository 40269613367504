import styles from "./ListAuditLogEntriesPage.module.scss";
import { ActiveLocation, LocationLocaleContext } from "features/location";
import { RouteComponentProps } from "react-router";
import { AuditLogEntry } from "../types";
import memoizeOne from "memoize-one";
import { LocationLocale } from "features/location/types/LocationLocale";
import { ColumnProps, TablePaginationConfig } from "antd/lib/table";
import { TableListing } from "common/scaffolding/components/TableListing/TableListing";
import { PageTitle } from "common/scaffolding/components/DataListingPage/PageTitle";
import { ReactNode, useState } from "react";
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    InfoCircleOutlined,
    PlusCircleOutlined,
    QuestionCircleOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";
import Search from "antd/lib/input/Search";
import { useEffect } from "react";

export interface Props {
    restaurantLocation: ActiveLocation;
    entries: AuditLogEntry[];
}

const pageSize = 15;

function filterEntry(filterParts: RegExp[], entry: AuditLogEntry) {
    return filterParts.every(
        (filter: RegExp) =>
            (entry.principal.id && filter.test(entry.principal.id)) ||
            (entry.principal.name && filter.test(entry.principal.name)) ||
            (entry.telemetry && entry.telemetry.operationName && filter.test(entry.telemetry.operationName)) ||
            filter.test(entry.event.eventName) ||
            (entry.event.targetId && filter.test(entry.event.targetId)) ||
            (entry.event.targetName && filter.test(entry.event.targetName)) ||
            (entry.event.targetType && filter.test(entry.event.targetType)) ||
            (entry.event.data && filter.test(entry.event.data))
    );
}

function escapeRegExp(input: string) {
    return input.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export const ListAuditLogEntriesPage = ({ restaurantLocation, entries }: Props & RouteComponentProps<any, any>) => {
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
    const [showVenueTimeZone, setShowVenueTimeZone] = useState(false);
    const [textFilter, setTextFilter] = useState<string>("");
    const [filteredEntries, setFilteredEntries] = useState<AuditLogEntry[]>(entries);

    useEffect(() => {
        setExpandedKeys([]);

        if (textFilter === "") {
            setFilteredEntries(entries);
            return;
        }

        var filterParts = textFilter
            .split(" ")
            .filter((word) => word.length > 0)
            .map((word) => new RegExp(escapeRegExp(word), "i"));

        if (filterParts.length === 0) {
            setFilteredEntries(entries);
            return;
        }

        setFilteredEntries(entries.filter((entry) => filterEntry(filterParts, entry)));
    }, [entries, textFilter]);

    const onRowClick = (entry: AuditLogEntry) => {
        if (expandedKeys.indexOf(entry.id) === -1) {
            setExpandedKeys([...expandedKeys, entry.id]);
        } else {
            setExpandedKeys(expandedKeys.filter((k) => k !== entry.id));
        }
    };

    const toggleTimeDisplay = () => {
        setShowVenueTimeZone(!showVenueTimeZone);
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = e.target.value.trim();
        setTextFilter(searchTerm);
    };

    const bindColumns = memoizeOne((locale: LocationLocale) => {
        const columns: ColumnProps<AuditLogEntry>[] = [
            {
                title: "Change",
                key: "eventName",
                align: "center",
                render: (text, record) => renderEventName(record.event.eventName),
            },
            {
                title: "Type",
                key: "type",
                render: (text, record) => record.event.targetType,
            },
            {
                title: "Target",
                key: "target",
                render: (text, record) => record.event.targetName,
            },
            {
                title: "Principal",
                key: "pricipal",
                render: (text, record) => record.principal.name ?? record.principal.id,
            },
            {
                title: (
                    <span onClick={toggleTimeDisplay}>
                        Time ({showVenueTimeZone ? "venue timezone" : "your timezone"})
                    </span>
                ),
                key: "time",
                render: (text, record) =>
                    new Date(record.timestamp).toLocaleString(undefined, {
                        timeZone: showVenueTimeZone ? restaurantLocation.timeZone : undefined,
                    }),
            },
        ];

        return columns;
    });

    const paginationSettings: TablePaginationConfig = {
        size: "default",
        position: ["bottomCenter"],
        pageSize: pageSize,
        showSizeChanger: false,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} entries`,
        itemRender: (
            page: number,
            type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
            originalElement: ReactNode
        ) => <div className="pagination-wrapper">{originalElement}</div>,
    };
    return (
        <>
            <PageTitle title="Audit Log" description="" />
            <div className="header-row">
                <Search placeholder="Search entries" allowClear onChange={handleSearch} className="autlog-search" />
            </div>
            <LocationLocaleContext.Consumer>
                {(locale) => (
                    <TableListing
                        columns={bindColumns(locale)}
                        dataSource={filteredEntries}
                        expandedKeys={expandedKeys}
                        onRowClick={onRowClick}
                        keyField="id"
                        col={24}
                        className="catalogue-table"
                        pagination={paginationSettings}
                        emptyText={`No audit log entries to display`}
                        resetPagination={false}
                        renderExpanded={renderExpandedRow}
                    />
                )}
            </LocationLocaleContext.Consumer>
        </>
    );
};

function renderExpandedRow(entry: AuditLogEntry) {
    return (
        <pre key={entry.id} className={styles.dataCell}>
            {entry.event.data}
        </pre>
    );
}

function renderEventName(eventName: string) {
    return <Tooltip overlay={eventName}>{renderEventNameIcon(eventName)}</Tooltip>;
}

function renderEventNameIcon(eventName: string) {
    switch (eventName) {
        case "created":
            return <PlusCircleOutlined />;
        case "updated":
            return <InfoCircleOutlined />;
        case "deleted":
            return <CloseCircleOutlined />;
        case "published":
            return <CheckCircleOutlined />;
        default:
            return <QuestionCircleOutlined />;
    }
}
