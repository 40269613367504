import { createAction } from "../reducers/edit";
import { scaffoldArchiveAction } from "../../../common/scaffolding/actions/scaffoldArchiveAction";
import { archiveCategory } from "../api/archiveCategory";

export const archive = (locationId: string) =>
    scaffoldArchiveAction(
        (state) => state.categories.edit,
        createAction,
        (id) => archiveCategory(locationId, id)
    );
