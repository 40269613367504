import { AppDispatch, AppState } from "../../state";
import { getSaveSectionTrackingEventData } from "../selectors";
import { EditableSection } from "../types";
import { createAction } from "../reducers/edit";
import { scaffoldSaveAction } from "common/scaffolding/actions/scaffoldSaveAction";
import { saveSection, SectionInputModel } from "../api/saveSection";
import { calculateTimeToLive } from "../helper";
import { createAction as calendarActions } from "../../service/reducers/calendar";

export const save = (location: string, formData: EditableSection, clone: boolean = false) =>
    scaffoldSaveAction(
        (state) => state.sections.edit,
        createAction,
        async (sectionId: string | undefined) => {
            const sectionInputModel = mapInputModel(formData);
            return await saveSection(location, clone ? undefined : sectionId, sectionInputModel);
        },
        undefined,
        clone,
        (_, dispatch: AppDispatch) => {
            dispatch(calendarActions.reset());
            return true;
        },
        false,
        (appState: AppState, saveResult?: string) => getSaveSectionTrackingEventData(appState, formData, saveResult)
    );

const mapInputModel = ({
    displayName,
    services,
    posId,
    posMenuId,
    sectionType,
    enableTips,
    locationTipsEnabled,
    applySurcharges,
    applyTrayCharge,
    demoTableNumber,
    demoQr,
}: EditableSection): SectionInputModel => ({
    displayName,
    services,
    posId: posId ? posId : null,
    posMenuId: posMenuId ? posMenuId : null,
    sectionType,
    enableTips,
    locationTipsEnabled: !!locationTipsEnabled,
    applySurcharges,
    applyTrayCharge,
    sectionDemoDetails: !demoQr
        ? undefined
        : {
              demoTableNumber,
              isDemoEnabled: demoQr.isEnabled,
              demoExpirySeconds: demoQr.selectedTimeToLive
                  ? demoQr.selectedTimeToLive * 60 * 60
                  : calculateTimeToLive(demoQr),
          },
});
