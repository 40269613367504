import { opsApi } from "API";
import { GraphQLInputModel } from "common/scaffolding/types";
import { UpsellModifierType, ProductSubtypes } from "features/modifier/types";
import { isNew } from "common/utility/modelUtils";
import { GraphQLModifierSummary, modifierListFields } from "./listModifiers";

export const saveModifier = async (
    locationId: string,
    itemModifierId: string | undefined,
    itemModifier: ItemModifierInputModel
) => {
    const isCreate = isNew(itemModifierId);

    itemModifier = mapItemModifier(itemModifier);

    const res = isCreate
        ? (
              await opsApi.graphQLQuery<{ createModifier: GraphQLModifierSummary }>(createQuery, {
                  locationId,
                  itemModifier,
              })
          ).data.createModifier
        : (
              await opsApi.graphQLQuery<{ updateModifier: GraphQLModifierSummary }>(updateQuery, {
                  locationId,
                  itemModifierId,
                  itemModifier,
              })
          ).data.updateModifier;

    return res;
};

/**
 * TODO when all locations have migrated and enableEnhancedModifierEdit has been flag removed
 * remove 'items'
 * make 'products' non-optional
 */
export interface ItemModifierInputModel extends GraphQLInputModel {
    minSelection?: number | null;
    maxSelection?: number | null;
    sku?: string;
    options: ItemModifierOptionInputModel[];
    items?: string[]; // @deprecated
    products?: ItemModifierInputProductModel[];
    required: boolean;
    upsell: boolean;
    smartSorting?: boolean;
    maxSelectionPerOption?: number | null;
    type: UpsellModifierType | null;
    subtypes: ProductSubtypes | null;
}

export interface ItemModifierInputProductModel {
    productId: string;
    variantIds: string[] | null;
}

export interface ItemModifierOptionInputModel {
    id?: string | undefined;
    isNew?: boolean | undefined;
    displayName: string;
    sku?: string;
    price?: number;
    taxRate: number | null;
    recommended?: boolean | undefined;
    modifiers?: string[] | null;
    energyContent?: number | string | null;
}

const createQuery = `
    mutation($locationId: ID!, $itemModifier: ModifierUpdateInput!) {
        createModifier(locationId: $locationId, data: $itemModifier) {
            ${modifierListFields}
        }
    }
`;

const updateQuery = `
    mutation($locationId: ID!, $itemModifierId: ID!, $itemModifier: ModifierUpdateInput!) {
        updateModifier(locationId: $locationId, id: $itemModifierId, data: $itemModifier) {
            ${modifierListFields}
        }
    }
`;

function mapItemModifier({ required, ...itemModifier }: ItemModifierInputModel): ItemModifierInputModel {
    return {
        ...itemModifier,
        required:
            itemModifier.minSelection !== undefined && itemModifier.minSelection !== null
                ? itemModifier.minSelection > 0
                : required,
        options: itemModifier.options.map(({ id, isNew, recommended, ...rest }: ItemModifierOptionInputModel) => ({
            ...rest,
            ...(isNew ? null : { id }),
            recommended: itemModifier.options.length > 1 ? recommended : undefined,
        })),
    };
}
