import { isNew } from "common/utility/modelUtils";
import { createAction } from "../reducers/edit";
import { savePromotion } from "../api";
import { scaffoldSaveAction } from "common/scaffolding/actions/scaffoldSaveAction";
import {
    EditablePromotion,
    mapEditablePromotionToGraphQLPromotion,
    mapGraphQLPromotionToPromotion,
    Promotion,
    PromotionType,
} from "../types";
import { getSavePromotionTrackingEventData } from "../selectors";
import { AppState } from "features";
import { fetchDownloadPromotionConfiguration } from "../api/exportPromotionCsv";
import { replaceInvalidFilenameCharacters } from "common/utility/StringUtils";

export const save = (locationId: string, formData: EditablePromotion) =>
    scaffoldSaveAction(
        (state) => state.promotions.edit,
        createAction,
        async (promotionId: string | undefined) => {
            const promotion = await savePromotion(
                locationId,
                promotionId,
                mapEditablePromotionToGraphQLPromotion(formData)
            );
            return mapGraphQLPromotionToPromotion(promotion);
        },
        undefined,
        false,
        (result: Promotion) => {
            if (isNew(formData.id) && formData.promotionType === PromotionType.MULTIPLECODE) {
                fetchDownloadPromotionConfiguration(locationId, result.id, getPromotionFileName(result.displayName));
            }
            return true;
        },
        undefined,
        (appState: AppState, saveResult?: Promotion) =>
            getSavePromotionTrackingEventData(appState, formData, saveResult ?? undefined)
    );

export const getPromotionFileName = (promotionName: string) =>
    `promotion-${replaceInvalidFilenameCharacters(promotionName).toLowerCase()}-${
        new Date().toISOString().split("T")[0]
    }.csv`;
