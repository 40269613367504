import styles from "./TakeawayPage.module.scss";

import { RouteParams } from "common/appInsights/plugins";
import { useLoadStatus } from "common/hooks";
import { isLoaded } from "common/loader/isLoaded";
import { ScrollToFormikError } from "components/forms/ScrollToFormikError";
import { goBack } from "connected-react-router";
import { CrudActionFooter } from "core/components/actionFooter";
import { Button } from "core/components/button";

import { PageError } from "core/components/pageError";
import { PageLoading } from "core/components/pageLoading";
import { actions as locationActions, LocationContext } from "features/location";
import { getLocationPermissions } from "features/location/selectors/getLocationPermissions";
import { posOperationBehaviorFilter } from "features/posConfiguration/selectors/posOperationFilter";
import { actions as sectionActions } from "features/section";
import { AppState } from "features/state";
import * as actions from "features/takeaway/actions";
import { getTakeawayOptionsSchema } from "features/takeaway/schema/EditableTakeawayOptionsSchema";
import { getTakeawayOptions } from "features/takeaway/selectors";
import { EditableTakeawayOptions } from "features/takeaway/types";
import { Form, Formik, validateYupSchema, yupToFormErrors } from "formik";
import { useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { TakeawayPageCollectionCard } from "./TakeawayPageCollectionCard";
import { TakeawayPageConfigurationCard } from "./TakeawayPageConfigurationCard";
import { TakeawayPageHeader } from "./TakeawayPageHeader";
import { TakeawayPageScheduleCard } from "./TakeawayPageScheduleCard";
import { TakeawayPageSharingCard } from "./TakeawayPageSharingCard";

export const TakeawayPage = () => {
    const dispatch = useDispatch();
    const { location: slug } = useParams<RouteParams>();
    const location = useContext(LocationContext);
    const { isLoading, hasErrored, retry } = useFetchStatus(location.id, slug);
    const isSaving = useIsSaving();

    const supportsScheduling = useSelector(posOperationBehaviorFilter("OrderCreate", "ScheduledOrder"));
    const formValues = useSelector(getTakeawayOptions);
    const permissions = useSelector(getLocationPermissions);
    const disableFields = !permissions.hasAny("settings:update");

    const handleSubmit = (data: EditableTakeawayOptions) => {
        dispatch(actions.save(data, location));
    };

    if (hasErrored) {
        return (
            <PageError
                heading="Oh no! Something went wrong."
                message="There was a technical problem that prevented me&u from loading this page. Try reloading this page or try again later."
                actions={
                    <>
                        <Button onClick={() => dispatch(goBack())} role="secondary">
                            Go back
                        </Button>
                        <Button onClick={retry}>Retry</Button>
                    </>
                }
            />
        );
    }

    if (isLoading || !formValues) {
        return <PageLoading message="Loading takeaway information" />;
    }

    return (
        <Formik initialValues={formValues} onSubmit={handleSubmit} validate={validate} enableReinitialize>
            {(form) => {
                return (
                    <>
                        <TakeawayPageHeader form={form} disabled={disableFields} />
                        <Form className={styles.container}>
                            <ScrollToFormikError />
                            <TakeawayPageConfigurationCard disabled={disableFields} />
                            {supportsScheduling && <TakeawayPageScheduleCard disabled={disableFields} />}
                            <TakeawayPageSharingCard />
                            <TakeawayPageCollectionCard disabled={disableFields} />
                            <CrudActionFooter
                                saving={isSaving}
                                submitMode="save"
                                showDuplicate={false}
                                showDelete={false}
                                showSave={!disableFields}
                                saveProps={{
                                    disabled: !form.dirty,
                                }}
                                position="fixed"
                            />
                        </Form>
                    </>
                );
            }}
        </Formik>
    );
};

const useFetchStatus = (locationId: string, slug: string) => {
    const dispatch = useDispatch();
    const location = useSelector((state: AppState) => state.locations.active);
    const sections = useSelector((state: AppState) => state.sections.list);

    const fetch = useCallback(() => {
        if (!isLoaded(location)) {
            dispatch(locationActions.fetchActive(slug));
        }
        if (!isLoaded(sections)) {
            dispatch(sectionActions.list(locationId));
        }
    }, [dispatch, slug, locationId, location, sections]);

    const fetchStatus = useLoadStatus([sections.status, location.status], fetch);

    return {
        isLoading: fetchStatus === "loading" || fetchStatus === "unloaded",
        hasErrored: fetchStatus === "failed",
        retry: fetch,
    };
};

const useIsSaving = () => {
    const status = useSelector((state: AppState) => state.locations.edit.saveStatus);
    return status === "saving";
};

const validate = (values: EditableTakeawayOptions) => {
    return validateYupSchema(values, getTakeawayOptionsSchema(), undefined).then(
        () => ({}),
        (err: any) => yupToFormErrors(err)
    );
};
