import { ListServicesPageContainer } from "features/service/containers/ListServicesPageContainer";
import { EditServicePageContainer } from "features/service/containers/EditServicePageContainer";
import { FeatureConfiguration } from "features";
import * as actions from "features/service/actions";
import { reducer } from "features/service/reducers";
import { EditComponent, ListComponent, ModalCrudRouteContainer } from "common/scaffolding/components/ModalCrudRoute";
import { withPermissions } from "features/location/components/withPermission";
import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { ListWrapper } from "common/scaffolding/components/ListWrapper";
import { ModalEditWrapper } from "common/scaffolding/components/EditWrapper";
import { useSelector } from "react-redux";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { CrudRoute } from "common/scaffolding/components/CrudRoute";

export * from "features/service/types";

export { actions };

export const servicesRoute = "/:region/:location/menu/services";

export const listRoute = ":tab(list|week|day)?";

const serviceList: ListComponent = {
    route: listRoute,
    component: withPermissions(ListServicesPageContainer, "menudata:read"),
    contentWidth: ContentWidth.WIDE,
    wrapper: ListWrapper,
};

const serviceEdit: EditComponent = {
    route: `${listRoute.replace("?", "")}/:id`,
    component: withPermissions(EditServicePageContainer, "menudata:read"),
    contentWidth: ContentWidth.NARROW,
    wrapper: ModalEditWrapper,
};

// TODO remove legacy Services support https://meanduapp.atlassian.net/browse/ROCK-745
const ContainerWrapper = () => {
    const activeLocation = useSelector(getActiveLocation);

    return activeLocation?.enableServiceSchedule ? (
        <ModalCrudRouteContainer name="services" listComponent={serviceList} editComponents={[serviceEdit]} />
    ) : (
        <CrudRoute
            name="services"
            listComponent={withPermissions(ListServicesPageContainer, "menudata:read")}
            editComponent={withPermissions(EditServicePageContainer, "menudata:read")}
        />
    );
};

export default function (config: FeatureConfiguration) {
    config.reducers.services = reducer;

    config.addRoutes(config.routes.locationRoutes, [<ContainerWrapper key="services" />]);
}
