import { opsApi } from "API";
import { TaxClassSummary } from "../types";

export const listTaxClasses = async (locationId: string) => {
    try {
        const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId });
        return res.data.taxClasses;
    } catch (e) {
        throw e;
    }
};

type GraphQLPayload = { taxClasses: TaxClassSummary[] };

const query = `
    query ($locationId: ID!) {
        taxClasses(locationId: $locationId) {
            id
            displayName
            internalName
            taxType
            taxRate
            posTaxId
        }
    }
`;
