import { SaveStatus } from "common/loader";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Drawer, message, Tabs } from "antd";
import { isNew } from "../../../common/utility/modelUtils";
import {
    AdjustmentType,
    EditablePriceLevel,
    EditablePriceList,
    EditablePriceListSettings,
    PriceListItem,
} from "../types";
import { useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { PageTitle } from "common/scaffolding/components/DataListingPage/PageTitle";
import "./EditPriceListPage.scss";
import { EditPriceLevelsTab } from "./tabs/EditPriceLevelsTab";
import { EditPriceListTab } from "./tabs/EditPriceListTab";
import { LocationLocaleContext } from "features/location";
import { PriceLevelItemSelector } from "./PriceLevelItemSelector";
import { CatalogueItem } from "features/catalogue";
import { TreeItem } from "common/types/TreeItem";
import { PriceListPermissions } from "../types/PriceListPermissions";
import { ConfirmModal } from "common/scaffolding/components/ConfirmModal/ConfirmModal";
import { FilterCategory } from "features/catalogue/types/FilterCategory";
import { useMedia } from "common/hooks";

const { TabPane } = Tabs;

export interface Props extends RouteComponentProps<RouteParams> {
    initialValues: EditablePriceList;
    categories: FilterCategory[];
    saveStatus: SaveStatus;
    bulkSaveStatus: SaveStatus;
    onSubmit: (data: EditablePriceListSettings) => void;
    permissions: PriceListPermissions;
    onRemove: (productId: string, type: string, itemId: string) => void;
    onBulkSave: (data: PriceListItem[], deleteData: PriceListItem[]) => void;
    onArchive: () => void;
    onClone: (data: EditablePriceListSettings, items: PriceListItem[]) => void;
    products: CatalogueItem[];
    modifiers: CatalogueItem[];
    priceListTree: TreeItem[];
    onSaveItems: (
        data: EditablePriceListSettings,
        products: EditablePriceLevel[],
        modifiers: EditablePriceLevel[]
    ) => void;
}

export enum TabKeys {
    Items = "1",
    Settings = "2",
}

interface RouteParams {
    id: string;
    location: string;
    region: string;
}

export const EditPriceListPage = (props: Props) => {
    const [activeTab, setActiveTab] = useState(props.initialValues.activeTab);
    const [itemSelectVisible, setItemSelectVisible] = useState(false);
    const [blockNavigation, setBlockNavigation] = useState(false);
    const [blockTabs, setBlockTabs] = useState(false);
    const { priceListTree } = props;

    const handleChange = (activeKey: string) => {
        setActiveTab(activeKey);
    };

    const containsItemWithIncorrectPrice = (globalAdjustment: number, adjustmentType: AdjustmentType) => {
        if (adjustmentType && globalAdjustment && adjustmentType === AdjustmentType.FixedAmount) {
            const itemWithNegativeOrZeroPrice = props.initialValues.priceLevels.selectedItems.some(
                (pl) =>
                    (pl.price || 0) + (globalAdjustment || 0) < 0 ||
                    (pl.children && pl.children.some((c) => (c?.price || 0) + (globalAdjustment || 0) < 0))
            );

            return itemWithNegativeOrZeroPrice;
        }

        return false;
    };

    const large = useMedia("(min-width: 896px)");

    const handleSave = (data: EditablePriceListSettings, clone: boolean = false) => {
        if (
            data.globalAdjustment &&
            data.adjustmentType &&
            containsItemWithIncorrectPrice(data.globalAdjustment, data.adjustmentType)
        ) {
            message.error(
                "Failed to save. This adjustment is resulting in some Level Prices being incorrect. Remove item or change adjustment."
            );
        } else {
            setBlockNavigation(false);
            if (clone) {
                const children = props.initialValues.priceLevels.selectedItems.reduce((all: PriceListItem[], item) => {
                    return all.concat(item.children || []);
                }, []);

                const valuesToClone = props.initialValues.priceLevels.selectedItems
                    .filter((pl) => pl.priceLevelPrice !== undefined && pl.priceLevelPrice >= 0)
                    .concat(children);
                data.displayName += " (clone)";
                props.onClone(data, valuesToClone);
            } else {
                props.onSubmit(data);
            }
        }
    };

    const handleArchive = () => {
        setBlockNavigation(false);
        props.onArchive();
    };

    const handleManageClick = () => {
        setItemSelectVisible(true);
    };

    const handleSelectorSave = (products: EditablePriceLevel[], modifiers: EditablePriceLevel[]) => {
        props.onSaveItems(props.initialValues.settings, products, modifiers);
    };

    const handleNavPrevention = (preventNavigation: boolean) => {
        setBlockNavigation(preventNavigation);
    };

    const handleSettingsNavPrevention = (preventNavigation: boolean) => {
        setBlockNavigation(preventNavigation);
        setBlockTabs(preventNavigation);
    };

    return (
        <>
            <div className="titleRow">
                <Link
                    to={`/${props.match.params.region}/${props.match.params.location}/menu/pricelists`}
                    className="link-back"
                >
                    <ArrowLeftOutlined />
                </Link>
                <PageTitle title={props.initialValues.settings.displayName} description="" />
            </div>
            <Tabs activeKey={activeTab} onChange={handleChange} animated={false}>
                <TabPane
                    tab="Items"
                    key={TabKeys.Items}
                    disabled={isNew(props.initialValues.settings.id) || (activeTab !== TabKeys.Items && blockTabs)}
                >
                    <LocationLocaleContext.Consumer>
                        {(locale) => (
                            <EditPriceLevelsTab
                                priceLevels={props.initialValues.priceLevels}
                                {...props}
                                onManageClick={handleManageClick}
                                locale={locale}
                                priceList={props.initialValues.settings}
                                onChange={handleNavPrevention}
                            />
                        )}
                    </LocationLocaleContext.Consumer>
                </TabPane>
                <TabPane tab="Settings" key={TabKeys.Settings} className="tab-settings">
                    <EditPriceListTab
                        settings={props.initialValues.settings}
                        {...props}
                        onSave={handleSave}
                        onArchive={handleArchive}
                        onChange={handleSettingsNavPrevention}
                    />
                </TabPane>
            </Tabs>
            <Drawer
                placement="right"
                onClose={() => setItemSelectVisible(false)}
                visible={itemSelectVisible}
                className="edit-drawer price-levels-drawer"
                width={large ? 680 : "100%"}
                destroyOnClose
                title="Manage list"
            >
                <PriceLevelItemSelector
                    listItems={priceListTree}
                    products={props.products}
                    modifiers={props.modifiers}
                    onSave={handleSelectorSave}
                    initialSelectedItems={props.initialValues.priceLevels.selectedItems}
                    onClose={() => setItemSelectVisible(false)}
                    priceList={props.initialValues.settings}
                />
            </Drawer>
            <ConfirmModal
                showModal={blockNavigation}
                title={`Do you want to save your changes to ${
                    props.initialValues.settings.displayName || "this price list"
                }?`}
                subTitle="You have unsaved changes for this price list"
                isPrompt={blockNavigation}
                cancelText="Discard changes"
                confirmText="Keep editing"
            />
        </>
    );
};
