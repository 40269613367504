import { opsApi } from "API";
import { AdjustmentType } from "..";
import { GraphQLInputModel, GraphQLItemId } from "common/scaffolding/types";
import { isNew } from "common/utility/modelUtils";
import { PriceLevelInputModel } from "./savePriceLevels";

export const savePriceList = async (
    locationId: string,
    priceListId: string | undefined,
    priceList: PriceListInputModel
) => {
    const isCreate = isNew(priceListId);

    const res = isCreate
        ? (await opsApi.graphQLQuery<{ createPriceList: GraphQLItemId }>(createQuery, { locationId, priceList })).data
              .createPriceList
        : (
              await opsApi.graphQLQuery<{ updatePriceList: GraphQLItemId }>(updateQuery, {
                  locationId,
                  priceListId,
                  priceList,
              })
          ).data.updatePriceList;

    priceListId = res.id;
    return res;
};

export interface PriceListInputModel extends GraphQLInputModel {
    posId: string | null;
    globalAdjustment: number | null;
    adjustmentType: AdjustmentType | null;
    products?: PriceLevelInputModel[];
    modifiers?: PriceLevelInputModel[];
    isClone: boolean;
}

const createQuery = `
    mutation($locationId: ID!, $priceList: PriceListUpdateInput!) {
        createPriceList(locationId: $locationId, data: $priceList) {
            id
        }
    }
`;

const updateQuery = `
    mutation($locationId: ID!, $priceListId: ID!, $priceList: PriceListUpdateInput!) {
        updatePriceList(locationId: $locationId, id: $priceListId, data: $priceList) {
            id
        }
    }
`;
