import styles from "./SchedulePage.module.scss";

import { CalendarDateFormat, useCalendarContext } from "features/service/context/CalendarContext";

import { DatePickerInput } from "core/components/form/datePicker";
import { NavButton } from "core/components/navButton/NavButton";
import { Calendar, CalendarWrapper, StickyHeader } from ".";
import { priorityServiceCalendar } from "features/service/actions";
import { useDispatch, useSelector } from "react-redux";
import { getNow } from "features/service/selectors";
import { useCallback, useEffect, useMemo } from "react";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import moment from "moment";
import { getCalendarItemsForDay, haveDatesBeenFetched } from "features/service/selectors/getCalendar";
import { AppState } from "features";
import { createAction as trackingActions } from "common/appInsights/actions/track";
import { getDateChangedData } from "features/service/tracking/getTrackingData";
import { Tab } from "features/service/types/Tab";

export const DayServicesPage = () => {
    const dispatch = useDispatch();

    const { singleDayDateString, setSingleDayDateString } = useCalendarContext();

    const venueNow = useSelector(getNow);

    const activeLocation = useSelector(getActiveLocation);

    const today = new Date(venueNow.format(CalendarDateFormat));
    const trackDateChange = useCallback(
        (date: string) => {
            dispatch(trackingActions.track(getDateChangedData(date, Tab.DAY)));
        },
        [dispatch]
    );
    const handlePrevious = useCallback(() => {
        if (!singleDayDateString) {
            return;
        }

        const newSelectedDate = moment(singleDayDateString, CalendarDateFormat)
            .subtract(1, "days")
            .format(CalendarDateFormat);

        setSingleDayDateString(newSelectedDate);
        trackDateChange(newSelectedDate);
    }, [setSingleDayDateString, singleDayDateString, trackDateChange]);

    const handleNext = useCallback(() => {
        if (!singleDayDateString) {
            return;
        }

        const newSelectedDate = moment(singleDayDateString, CalendarDateFormat)
            .add(1, "days")
            .format(CalendarDateFormat);

        setSingleDayDateString(newSelectedDate);
        trackDateChange(newSelectedDate);
    }, [setSingleDayDateString, singleDayDateString, trackDateChange]);

    const handleDateSelect = useCallback(
        (selectedDate: Date | undefined) => {
            const newSelectedDate = moment(selectedDate).format(CalendarDateFormat);
            setSingleDayDateString(newSelectedDate);
            trackDateChange(newSelectedDate);
        },
        [setSingleDayDateString, trackDateChange]
    );

    useEffect(() => {
        if (!singleDayDateString && activeLocation) {
            const initSingleDayDateString = venueNow.format(CalendarDateFormat);
            setSingleDayDateString(initSingleDayDateString);
        }
    }, [activeLocation, setSingleDayDateString, singleDayDateString, venueNow]);

    const selectedDate = useMemo(() => {
        if (!singleDayDateString) {
            return undefined;
        }
        return moment(singleDayDateString, CalendarDateFormat).toDate();
    }, [singleDayDateString]);

    const dateAlreadyFetched = useSelector((state: AppState) =>
        haveDatesBeenFetched(state, singleDayDateString ? [singleDayDateString] : [])
    );

    useEffect(() => {
        if (!singleDayDateString || !activeLocation || dateAlreadyFetched) {
            return;
        }
        dispatch(
            priorityServiceCalendar(activeLocation.id, {
                startDate: singleDayDateString,
                dayCount: 1,
            })
        );
    }, [activeLocation, dispatch, singleDayDateString, dateAlreadyFetched]);

    const calendarItems = useSelector((state: AppState) => getCalendarItemsForDay(state, singleDayDateString));

    return (
        <CalendarWrapper>
            <StickyHeader>
                <div className={styles.controlsContainer}>
                    <div className={styles.datePickerContainer}>
                        <NavButton direction="previous" onClick={handlePrevious} />
                        <DatePickerInput
                            canClearInput={false}
                            closeOnDayClick={true}
                            locale="au" // TODO should we respect the venue or internal teams locale?
                            onSelect={handleDateSelect}
                            onTodayClick={handleDateSelect}
                            today={today}
                            value={selectedDate}
                        />
                        <NavButton direction="next" onClick={handleNext} />
                    </div>
                </div>
            </StickyHeader>
            <Calendar calendarItems={calendarItems} viewMode="day" />
        </CalendarWrapper>
    );
};
