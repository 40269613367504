import styles from "./ListItem.module.scss";

import { ArrayHelpers } from "formik";
import { getTextFilter } from "../selectors/getFilters";
import { HighlightSearchTerm } from "core/components/highlightSearchTerm";
import { ListItemStatus } from "./ListItemStatus";
import { LocationRouteParams } from "common/types";
import { LocationSummary } from "features/location";
import { RemoveButton } from "core/components/form/removeButton";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Tooltip } from "core/components/tooltip/Tooltip";
import { ListItemLastUpdated } from "./ListItemLastUpdated";

export interface ListItemProps {
    disableRemove: boolean;
    index: number;
    item: LocationSummary;
    onChildLinkClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const ListItem = ({ disableRemove, index, item, onChildLinkClick, remove }: ListItemProps & ArrayHelpers) => {
    const searchTerm = useSelector(getTextFilter);

    const handleRemove = useCallback(() => remove(index), [index, remove]);

    const { region } = useParams<LocationRouteParams>();

    return (
        <div role="row" className={styles.containerPrimary}>
            <a
                aria-labelledby={`name-{item.id}`}
                className={styles.rowEditLink}
                href={`/${region}/${item.slug}`}
                onClick={onChildLinkClick}
            />
            <div className={styles.innerContainer}>
                <div className={styles.contentContainer}>
                    <div className={styles.detailsContainer}>
                        <span role="cell" className={styles.cellName} id={`name-{item.id}`}>
                            <HighlightSearchTerm source={item.displayName} searchTerm={searchTerm} />
                        </span>
                        <span role="cell" className={classNames(styles.cellMedium, styles.sentenceCase)}>
                            <ListItemLastUpdated item={item} />
                        </span>
                        <span role="cell" className={styles.cellSmall}>
                            <ListItemStatus item={item} />
                        </span>
                    </div>
                    <span role="cell" className={classNames(styles.cellInteractive, styles.cellTiny)}>
                        <Tooltip
                            content="Unlink venue"
                            disabled={disableRemove}
                            offset={[0, 12]}
                            placement="bottom-end"
                        >
                            <RemoveButton disabled={disableRemove} onClick={handleRemove} />
                        </Tooltip>
                    </span>
                </div>
            </div>
        </div>
    );
};
