import { EditableCmsItem } from "common/scaffolding/types";
import { ReactElement, useMemo } from "react";
import { useFormikContext } from "formik";
import { Select, Option } from "core/components/form/select";
import { SingleValue } from "react-select";

export type DisplayNamePlaceholderFn = <TOption extends RecommendableOption>(
    displayNamePlaceholder: string,
    index: number,
    option: TOption
) => string;

interface Props {
    disabled?: boolean;
    fieldName: string;
    placeholder?: string;
    getDisplayNamePlaceholder?: DisplayNamePlaceholderFn;
    displayNamePlaceholder?: string;
}

export interface RecommendableOption extends EditableCmsItem {
    recommended?: boolean;
}

export const SelectRecommended = <TValues, TOption extends RecommendableOption>({
    disabled = false,
    fieldName,
    placeholder = "Select an option",
    displayNamePlaceholder = "Unnamed option",
}: Props): ReactElement => {
    const { values, setFieldValue } = useFormikContext<TValues>();

    // recommendable options
    const options = useMemo<TOption[]>(
        () => values[fieldName]?.filter((option: TOption) => option.id) || [],
        [values, fieldName]
    );

    const recommendedOption = useMemo<TOption | undefined>(
        () => options.find(({ recommended }) => recommended),
        [options]
    );

    // select menu options
    const selectOptions: Option[] = useMemo<Option[]>(
        () =>
            options?.map((option: TOption, index) => ({
                label: option.displayName || getDisplayNamePlaceholder(displayNamePlaceholder, index, option),
                value: option.id,
            })) || [],
        [options, displayNamePlaceholder]
    );

    const onChange = (selected: SingleValue<Option>): boolean => {
        let newOptions: TOption[] = options.map((option: TOption) => ({
            ...option,
            recommended: option.id === selected?.value || undefined,
        }));

        setFieldValue(fieldName, newOptions);

        return false;
    };

    return (
        <Select
            disabled={disabled}
            placeholder={placeholder}
            options={selectOptions}
            value={selectOptions.find((selectOption) => selectOption.value === recommendedOption?.id) || undefined}
            onChange={(value) => onChange(value as SingleValue<Option>)}
            search={true}
            isClearable={true}
        />
    );
};

const getDisplayNamePlaceholder: DisplayNamePlaceholderFn = <TOption extends RecommendableOption>(
    displayNamePlaceholder: string,
    index: number,
    option: TOption
) => {
    return `<${displayNamePlaceholder} #${index + 1}>`;
};
