import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { FeatureConfiguration } from "features";
import { ModalCrudRouteContainer, ListComponent } from "common/scaffolding/components/ModalCrudRoute";
import { reducer } from "features/posCatalogueImport/reducers";
import * as actions from "features/posCatalogueImport/actions";
import { EditWrapper } from "common/scaffolding/components/EditWrapper";
import { PosCatalogueImportPage } from "./components/PosCatalogueImportPage";
export { actions };
export * from "features/posCatalogueImport/types";

const List: ListComponent = {
    route: ":tab(products|modifiers)?",
    component: PosCatalogueImportPage,
    contentWidth: ContentWidth.STANDARD,
    wrapper: EditWrapper,
};

export default function (config: FeatureConfiguration) {
    config.reducers.posCatalogueImport = reducer;

    config.addRoutes(config.routes.locationRoutes, [
        <ModalCrudRouteContainer name="import" key="pos-catalogue-import" section="pos" listComponent={List} />,
    ]);
}
