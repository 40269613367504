import { opsApi } from "API";
import { BulkDeleteInput, BulkDeleteResult } from "../types";

export const bulkDelete = async (locationId: string, items: BulkDeleteInput) => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId, items });

    return res.data.uploadPosCatalogue;
};

type GraphQLPayload = { bulkDeleteResult: BulkDeleteResult };

const query = `
  mutation ($locationId: ID!, $items: BulkDeleteInput!) {
    catalogueBulkDelete(locationId: $locationId, items: $items) {
      deleteSuccess
      errors
      summary {
        productsDeleted
        variantsDeleted
        modifierGroupsDeleted
        modifierOptionsDeleted
      },
    }
  }
`;
