import { Button } from "antd";
import { Route } from "react-router";
import { ButtonType } from "antd/lib/button";
import "./NewDataButton.scss";

export interface Props {
    disabled?: boolean;
    text: string;
    newItemRoute: string;
    type?: ButtonType;
}

export const NewDataButton = ({ disabled, text, newItemRoute, type }: Props) => (
    <Route
        render={({ history }) => (
            <Button
                className="add-new-item"
                type={type}
                onClick={() =>
                    history.push({
                        pathname: newItemRoute,
                        state: { newService: true },
                    })
                }
                disabled={disabled}
            >
                {text}
            </Button>
        )}
    />
);
