import { State as ListState, reducer as listReducer } from "features/category/reducers/list";
import { State as EditState, reducer as editReducer } from "features/category/reducers/edit";
import { combineReducers } from "redux";

export interface State {
    categories: {
        list: ListState;
        edit: EditState;
    };
}

export const reducer = combineReducers({
    list: listReducer,
    edit: editReducer,
});
