import { opsApi } from "API";

export const archiveMembership = async (locationId: string, membershipId: string) => {
    await opsApi.graphQLQuery(query, { locationId, membershipId });
};

const query = `
    mutation($locationId: ID!, $membershipId: ID!) {
        deleteMembership(locationId: $locationId, membershipProgramId: $membershipId) 
    }
`;
