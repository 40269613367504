import { createAction } from "../reducers/edit";
import { PackageUpdateInput } from "../types";
import { savePackage } from "../api/savePackage";
import { scaffoldSaveAction } from "common/scaffolding/actions/scaffoldSaveAction";
import { History } from "history";
import { edit } from "./edit";
import { getSaveGroupTabTrackingEventData } from "../selectors/getSaveGroupTabPackageTrackingEventData";
import { AppState } from "../../state";

export const save = (
    locationId: string,
    region: string,
    formData: PackageUpdateInput,
    history: History,
    keepEditing: boolean
) =>
    scaffoldSaveAction(
        (state) => state.groupTabs.edit,
        createAction,
        async (packageId: string | undefined) => {
            return (await savePackage(locationId, packageId, formData)).id;
        },
        undefined,
        false,
        (id: string) => {
            return keepEditing ? edit(locationId, id) : history.replace(`/${region}/${locationId}/menu/grouptabs`);
        },
        undefined,
        (appState: AppState, saveResult?: string) => getSaveGroupTabTrackingEventData(appState, formData, saveResult)
    );
