import { AppState } from "features/state";
import { createSelector } from "reselect";
import { BooleanOrEitherFilter, CatalogueFilters } from "../types";

const getCatalogueFilters = ({ catalogue: { filters } }: AppState) => filters;

export const getActiveFilters = createSelector(getCatalogueFilters, (filters) => {
    return filters.active;
});

export const getActiveCategoryIds = createSelector(getCatalogueFilters, (filters) => {
    return filters.active.categories.map((category) => category.value);
});

export const getSkuFilter = createSelector(getCatalogueFilters, (filters) => {
    return filters.active.sku;
});

export const getMissingContentFilter = createSelector(getCatalogueFilters, (filters) => {
    return filters.active.missingContent;
});

export const getIsLinkedFilter = createSelector(getCatalogueFilters, (filters) => {
    return filters.active.isLinked;
});

export const getTextFilter = createSelector(getCatalogueFilters, (filters) => {
    return filters.text;
});

export const getProductsFilterCount = createSelector(getCatalogueFilters, (filters) => {
    return filters.active ? getFilterCount(filters.active, true) : 0;
});

export const getModifiersFilterCount = createSelector(getCatalogueFilters, (filters) => {
    return filters.active ? getFilterCount(filters.active, false) : 0;
});

export const getHasFilters = createSelector(getProductsFilterCount, getTextFilter, (activeFilterCount, text) => {
    return !!activeFilterCount || !!text.length;
});

export const getActiveStatuses = createSelector(getCatalogueFilters, (filters) => {
    return filters.active.status?.map((status) => status.value) || [];
});

export const getActiveSyncStatuses = createSelector(getCatalogueFilters, (filters) => {
    return filters.active.syncStatus?.map((syncStatus) => syncStatus.value) || [];
});

function getFilterCount(filters: CatalogueFilters, includeCategories: boolean) {
    const categoriesCount = includeCategories ? filters.categories?.length || 0 : 0;

    const skuFilterCount = getBooleanOrEitherFilterCount(filters.sku);

    const missingContentFilterCount = getBooleanOrEitherFilterCount(filters.missingContent);

    const isLinkedFilterCount = getBooleanOrEitherFilterCount(filters.isLinked);

    const statusFiltersCount = filters.status?.length || 0;

    const syncStatusFilterCount = filters.syncStatus?.length || 0;

    return (
        categoriesCount +
        skuFilterCount +
        statusFiltersCount +
        syncStatusFilterCount +
        missingContentFilterCount +
        isLinkedFilterCount
    );
}

function getBooleanOrEitherFilterCount(filter: BooleanOrEitherFilter) {
    return filter.value === true || filter.value === false ? 1 : 0;
}
