import classNames from "classnames";
import { ArrowRightIcon } from "common/legacyIcons";
import { useEffect, useState } from "react";
import "./TimeRangePicker.scss";
import { TimeSelector } from "./TimeSelector";

export interface Props {
    id: string;
    startTime?: number;
    endTime?: number;
    onChange: (start?: number, end?: number) => void;
    disabled?: "start" | "end" | boolean;
    className?: string;
}
export const TimeRangePicker = ({ id, startTime, endTime, onChange, className, disabled = false }: Props) => {
    const [start, setStart] = useState(startTime);
    const [end, setEnd] = useState(endTime);
    const [defaultOpenValue, setDefaultOpenValue] = useState<number | undefined>(undefined);

    const handleStartChange = (time?: number) => {
        setStart(time);
        onChange(time, end);
    };

    const handleEndChange = (time?: number) => {
        setEnd(time);
        onChange(start, time);
    };

    useEffect(() => {
        setStart(startTime);
        setEnd(endTime);
        !endTime && setDefaultOpenValue(startTime);
    }, [startTime, endTime]);

    return (
        <div className={classNames("timerange-picker", className)}>
            <TimeSelector
                id={id + "-start"}
                value={start}
                onChange={handleStartChange}
                disabled={disabled === "start" || disabled === true}
                className="timerange-picker__start"
                placeholder="Start"
            />{" "}
            <ArrowRightIcon />
            <TimeSelector
                id={id + "-end"}
                value={end}
                defaultOpenValue={defaultOpenValue}
                onChange={handleEndChange}
                disabled={disabled === "end" || disabled === true}
                className="timerange-picker__end"
                placeholder="End"
            />
        </div>
    );
};
