import { GraphQLError } from "graphql";

type FieldErrorMap = { [field: string]: string };

export class ValidationError extends Error {
    constructor(public errors: FieldErrorMap) {
        super("Validation failed");
    }

    static fromGraphQLErrors(errors: GraphQLError[]) {
        const validationErrors = errors.filter((e) => e.extensions["code"] === "validation_failed");

        if (validationErrors.length === 0) {
            return null;
        }

        const errorMap = validationErrors.reduce((map, error) => {
            map[error.path!.slice(1).join(".")!] = error.message;
            return map;
        }, {});

        return new ValidationError(errorMap);
    }
}
