import { opsApi } from "API";
import { isNew } from "common/utility/modelUtils";
import { Promotion, GraphQLPromotion } from "../types";
import { promotionObject } from "./listPromotions";

export const savePromotion = async (
    locationId: string,
    promotionId: string | undefined,
    promotionData: GraphQLPromotion
) => {
    const isCreate = isNew(promotionId);

    // strip unneeded props before saving
    const { id, usageCount, ...data } = promotionData;

    try {
        const res = isCreate
            ? (
                  await opsApi.graphQLQuery<{ createPromotion: Promotion }>(createQuery, {
                      locationId,
                      promotionUpdateInput: data,
                  })
              ).data.createPromotion
            : (
                  await opsApi.graphQLQuery<{ updatePromotion: Promotion }>(updateQuery, {
                      locationId,
                      promotionId,
                      promotionUpdateInput: data,
                  })
              ).data.updatePromotion;

        return res;
    } catch (e) {
        throw e;
    }
};

const updateQuery = `
    mutation ($locationId: ID!, $promotionId: ID!, $promotionUpdateInput: PromotionUpdateInput!) {
        updatePromotion(locationId: $locationId,id: $promotionId, data: $promotionUpdateInput) {
            ${promotionObject},
            restrictions {
                minSpend,
                maxMemberUsage,
                maxCodeUsage,
                requiredItems {
                    productIds,
                    variantIds
                },
                schedule {
                    startDate,
                    endDate,
                    activeDaysOfWeek
                    dailyStartTime
                    dailyEndTime
                }
            }
        }
    }
`;

const createQuery = `
    mutation ($locationId: ID!, $promotionUpdateInput: PromotionUpdateInput!) {
        createPromotion(locationId: $locationId, data: $promotionUpdateInput) {
            ${promotionObject}
            restrictions {
                minSpend,
                maxMemberUsage,
                maxCodeUsage,
                requiredItems {
                    productIds,
                    variantIds
                }
                schedule {
                    startDate,
                    endDate,
                    activeDaysOfWeek
                    dailyStartTime
                    dailyEndTime
                }
            }
        }
    }
`;
