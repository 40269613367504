import { Chatbot } from "common/icons/Chatbot";
import { Button } from "core/components/button";
import { Icon } from "core/components/icon";
import { useEffect } from "react";
import styles from "./ZendeskChatPlugin.module.scss";

declare global {
    interface Window {
        zE: any;
    }
}

export const ZendeskChatPlugin = () => {
    useEffect(() => {
        if (!document.getElementById("ze-snippet")) {
            const embedScript = document.createElement("script");
            embedScript.src = "https://static.zdassets.com/ekr/snippet.js?key=a7957ef2-3357-468c-9e08-157aa20885c9";
            embedScript.id = "ze-snippet";
            document.head.appendChild(embedScript);
        }
    }, []);

    return (
        <div className="zendesk-chat-plugin">
            <Button
                className={styles.launcher}
                onClick={() => {
                    window.zE("messenger", "open");
                }}
            >
                <Icon className={styles.icon} size="extraLarge">
                    <Chatbot />
                </Icon>
                Need help?
            </Button>
        </div>
    );
};
