import { Link } from "react-router-dom";
import alert from "assets/icons/alert.svg";
import "./SurchargeAlert.scss";

export interface Props {
    region: string;
    location: string;
}

export const SurchargeAlert = ({ region, location }: Props) => {
    return (
        <div className="surcharge-alert">
            <img src={alert} alt="Surcharges" />
            <span>
                <Link to={`/${region}/${location}/menu/surcharges`}>Another surcharge</Link>
                <span> is overriding this value.</span>
            </span>
        </div>
    );
};
