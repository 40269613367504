import styles from "./NestedModifiers.module.scss";

import { EditableModifier } from "features/modifier/types";
import { Row } from "core/components/card";
import { ListItem } from "core/components/list";
import { Unnested } from "components/icons";
import { useSelector } from "react-redux";
import { AppState } from "features";
import { getModifierCatalogueItems } from "features/catalogue/selectors/getModifierCatalogueItems";
import { CatalogueItem } from "features/catalogue";
import { RemoveButton } from "core/components/form/removeButton";
import classNames from "classnames";
import { FieldArray, FormikProps, getIn } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";

interface Props {
    pathPrefix: string;
    disabled?: boolean;
    form: FormikProps<EditableModifier>;
}

interface ActiveExitAnimations {
    [key: string]: boolean;
}

interface RemoveHandlers {
    [key: string]: {
        timeout: number;
        removeHandler: () => void;
    };
}

const EXIT_ANIM_DURATION = 400;

const exitAnimStyle = { "--exit-animation-duration": `${EXIT_ANIM_DURATION}ms` } as React.CSSProperties;

export const NestedModifiers = ({ pathPrefix, disabled, form }: Props) => {
    const { values } = form;

    const allModifiers = useSelector<AppState, CatalogueItem[]>((state) => getModifierCatalogueItems(state));

    const ids: string[] = useMemo(() => getIn(values, `${pathPrefix}.modifiers`) || [], [pathPrefix, values]);

    const [activeExitAnimations, setActiveExitAnimations] = useState<ActiveExitAnimations>({});

    const removeHandlers = useRef<RemoveHandlers>({});

    const nestedModifiers: CatalogueItem[] = useMemo(() => {
        if (!ids || !allModifiers) {
            return [];
        }
        return ids
            .map((modifierGroupId) => allModifiers.find(({ id }) => modifierGroupId === id))
            .filter(Boolean) as CatalogueItem[];
    }, [allModifiers, ids]);

    // clean up timeouts and exec all removeHandlers on unmount
    useEffect(
        () => () => {
            Object.entries(removeHandlers.current).map(([id, { timeout, removeHandler }]) => {
                clearTimeout(timeout);
                removeHandler();
            });
        },
        []
    );

    const removeWithAnimation = (idToRemove: string, removeHelper: (index: number) => undefined) => {
        const removeHandler = () => {
            const idx = ids.findIndex((id) => id === idToRemove);
            removeHelper(idx);
        };

        const timeout = window.setTimeout(() => {
            // exec and delete handler
            removeHandlers.current[idToRemove]?.removeHandler();
            delete removeHandlers.current[idToRemove];

            // update active anims
            const update = { ...activeExitAnimations };
            delete update[idToRemove];
            setActiveExitAnimations(update);
        }, EXIT_ANIM_DURATION + 20); // slight delay to prevent janky animation end

        setActiveExitAnimations({ ...activeExitAnimations, [idToRemove]: true });

        removeHandlers.current[idToRemove] = {
            timeout,
            removeHandler,
        };
    };

    if (!nestedModifiers.length) {
        return null;
    }

    return (
        <Row background="neutral">
            <FieldArray
                name={`${pathPrefix}.modifiers`}
                render={({ remove }) => (
                    <ul className={styles.nestedModifiersList}>
                        {nestedModifiers.map(({ id, displayName, children }) => (
                            <li
                                key={id}
                                className={classNames(
                                    styles.nestedModifiersListItem,
                                    activeExitAnimations[id!] && styles.nestedModifiersListItemExit
                                )}
                                style={exitAnimStyle}
                            >
                                <ListItem
                                    as="div"
                                    thumb={<Unnested />}
                                    heading={displayName}
                                    body={`${children?.length || 0} option${children?.length !== 1 ? "s" : ""}`}
                                    actions={
                                        disabled ? undefined : (
                                            <RemoveButton
                                                onClick={() => removeWithAnimation(id!, remove)}
                                                disabled={form.values.isLinked}
                                            />
                                        )
                                    }
                                />
                            </li>
                        ))}
                    </ul>
                )}
            />
        </Row>
    );
};
