import { opsApi } from "API";
import { GraphQLItemId } from "common/scaffolding/types";
import { isNew } from "common/utility/modelUtils";

export async function saveSection(
    location: string,
    sectionId: string | undefined,
    section: SectionInputModel
): Promise<string> {
    const isCreate = isNew(sectionId);

    const res = isCreate
        ? (await opsApi.graphQLQuery<{ createSection: GraphQLItemId }>(createQuery, { location, section })).data
              .createSection
        : (await opsApi.graphQLQuery<{ updateSection: GraphQLItemId }>(updateQuery, { location, sectionId, section }))
              .data.updateSection;

    return res.id;
}

export interface SectionInputModel {
    displayName: string;
    services: string[];
    posId: string | null;
    posMenuId: string | null;
    sectionType: "outdoor" | "indoor" | null;
    enableTips: boolean;
    locationTipsEnabled: boolean;
    applySurcharges: boolean;
    applyTrayCharge: boolean;
    sectionDemoDetails?: {
        demoTableNumber?: string;
        isDemoEnabled?: boolean;
        demoExpirySeconds: number | null;
    };
}

const createQuery = `
    mutation($location: ID!, $section: SectionUpdateInput!) {
        createSection(locationId: $location, data: $section) {
            id
        }
    }
`;

const updateQuery = `
    mutation($location: ID!, $sectionId: ID!, $section: SectionUpdateInput!) {
        updateSection(locationId: $location, id: $sectionId, data: $section) {
            id
        }
    }
`;
