import { downloadBlob } from "common/utility/fileUtils";
import { AppDispatch, AppState } from "features/state";
import { getPosCatalogue } from "../selectors/getPosCatalogueItems";
import { PosModifier } from "../types";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { generateCsvBlob, CsvWriter } from "common/data/generateCsvBlob";

export const exportModifiersCsv = () => (dispatch: AppDispatch, getState: () => AppState) => {
    const state = getState();
    const catalogue = getPosCatalogue(state)!;
    const activeLocation = getActiveLocation(state)!;

    const csvBlob = generateCsvBlob<ModifierCsvRow>((writer) => {
        writer(MODIFIERS_CSV_HEADER);

        for (const modifier of catalogue.modifiers) {
            generateModifierRow(modifier, writer);
        }
    });

    downloadBlob(csvBlob, `${activeLocation.slug}-modifiers.csv`);
};

function generateModifierRow(modifier: PosModifier, writer: CsvWriter<ModifierCsvRow>): void {
    if (modifier.options.length === 0) {
        return;
    }

    writer([
        modifier.displayName,
        modifier.options[0].displayName,
        String(modifier.options[0].price || 0),
        modifier.options[0].sku,
    ]);

    for (let o = 1; o < modifier.options.length; o++) {
        const option = modifier.options[o];

        writer([null, option.displayName, String(option.price || 0), option.sku]);
    }
}

type ModifierCsvRow = [string | null, string | null, string, string];

const MODIFIERS_CSV_HEADER: ModifierCsvRow = ["Modifier List Name", "Modifier", "Price", "SKU"];
