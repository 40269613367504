import styles from "../ListItem.module.scss";

import classNames from "classnames";
import { getTextFilter } from "features/catalogue/selectors/getFilters";
import { HighlightSearchTerm } from "core/components/highlightSearchTerm";
import { RowSelection } from "../RowSelection";
import { CatalogueItem } from "features/catalogue/types";
import { LinkedItemBadge } from "../controlledItem/LinkedItemBadge";
import { Image } from "./Image";
import { Link } from "react-router-dom";
import { ListItemPublishStatus } from "../ListItemPublishStatus";
import { ListItemSyncStatus } from "../ListItemSyncStatus";
import { useSelector } from "react-redux";
import { getPosSyncEnabled } from "features/catalogue/selectors/getPosSyncEnabled";
import { getStatusFromValidationStatus } from "core/components/syncStatus";

export interface ListItemProps {
    canBulkSelect: boolean;
    disableSelection?: boolean;
    editLink?: string;
    item: CatalogueItem;
    formatCurrency: (x: number) => string;
    parentItem: CatalogueItem | null;
}

export const ListItem = (props: ListItemProps) => {
    const {
        canBulkSelect,
        disableSelection,
        editLink,
        formatCurrency,
        item,
        item: {
            // calories TODO https://meanduapp.atlassian.net/browse/INT-277
            categories,
            displayName,
            image,
            internalName,
            parentId,
            price,
            sku,
        },
        parentItem,
    } = props;

    const displayNamePrefix = parentId ? "⸺ " : "";
    const containerStyle = parentId ? styles.containerSecondary : styles.containerPrimary;
    const searchTerm = useSelector(getTextFilter);

    const posSyncEnabled = useSelector(getPosSyncEnabled);
    const isValidated = !item.children?.length;
    const status = getStatusFromValidationStatus(item.validationStatus);
    const hasSyncStatus = status && posSyncEnabled && isValidated;

    return (
        <div role="row" className={containerStyle}>
            <Link
                aria-label={`Edit ${parentItem ? parentItem.displayName : item.displayName}`}
                className={styles.rowEditLink}
                to={`${editLink}`}
                tabIndex={parentId ? -1 : 0}
            />
            <div className={styles.innerContainer}>
                {canBulkSelect && (
                    <div role="cell" className={styles.check}>
                        <RowSelection
                            key={item.key}
                            collectionName="products"
                            item={item}
                            disabled={disableSelection || false}
                            parentItem={parentItem}
                        />
                    </div>
                )}
                <div className={styles.contentContainer}>
                    <span role="cell" className={styles.cellThumbWithName}>
                        {!item.parentId && (
                            <span className={styles.thumb}>
                                <Image image={image || undefined} aria-labelledby={`label-${item.id}`} />
                            </span>
                        )}
                        <span className={styles.namesStack}>
                            <span className={parentId ? styles.nameSecondary : styles.namePrimary}>
                                <span id={`label-${item.id}`}>
                                    <span className={styles.displayNamePrefix} aria-hidden>
                                        {displayNamePrefix}
                                    </span>
                                    <span className="sr-only">{parentId ? "child" : "parent"}</span>
                                    {item.isLinked && !parentId && <LinkedItemBadge />}
                                    <HighlightSearchTerm source={displayName} searchTerm={searchTerm} />
                                </span>
                            </span>
                            {internalName && (
                                <span className={styles.nameSecondary}>
                                    <HighlightSearchTerm source={internalName} searchTerm={searchTerm} />
                                </span>
                            )}
                        </span>
                    </span>
                    <span
                        role="cell"
                        className={classNames(
                            styles.cellSmall,
                            styles.cellHideFirst,
                            styles.cellShowUntilMedium,
                            styles.cellFillWidthUntilMedium,
                            "hide-if-parent-location"
                        )}
                    >
                        {categories?.map((c) => c.displayName).join(", ")}
                    </span>
                    <span
                        role="cell"
                        className={classNames(
                            styles.cellTiny,
                            styles.cellNumber,
                            styles.cellFirstWrap,
                            !price && styles.cellLabelEmpty
                        )}
                    >
                        <span className={styles.cellLabel}>Price </span>
                        {price ? formatCurrency(price) : ""}
                    </span>
                    <span
                        role="cell"
                        className={classNames(
                            hasSyncStatus ? styles.cellSmallWithStatus : styles.cellSmall,
                            styles.cellNumber,
                            !price && styles.cellFirstWrap,
                            !sku && styles.cellLabelEmpty
                        )}
                    >
                        <span className={styles.cellLabel}>SKU: </span>
                        <span className={classNames(hasSyncStatus && styles.cellSmallWithStatusLabel)}>
                            <HighlightSearchTerm source={sku || ""} searchTerm={searchTerm} />
                        </span>
                        <ListItemSyncStatus item={item} />
                    </span>
                    {/* TODO calories https://meanduapp.atlassian.net/browse/INT-277 */}
                    {/* <span role="cell" className={classNames(styles.cellSmall, styles.cellNumber, styles.cellHideLast)}>
                    </span> */}
                    <span role="cell" className={classNames(styles.cellSmall, styles.cellFillWidthUntilMedium)}>
                        <ListItemPublishStatus item={item} />
                    </span>
                </div>
            </div>
        </div>
    );
};
