import { PageTitle } from "common/scaffolding/components/DataListingPage/PageTitle";
import { CrudPermissions } from "features/location/types/createCrudPermissions";
import { useCallback, useState } from "react";
import { defaultSurchargeValues, EditableSurcharge } from "../types";
import "./SurchargesPage.scss";
import { Tabs } from "antd";
import { ConfirmModal } from "common/scaffolding/components/ConfirmModal/ConfirmModal";
import { ValidationError } from "../types/ValidationError";
import { validateSurcharges } from "./validateSurcharges";
import { RecurringSurchargesTable } from "./RecurringSurchargesTable";
import { OneTimeSurchargesTable } from "./OneTimeSurchargesTable";
import { PreviousSurchargesTable } from "./PreviousSurchargesTable";
import { checkRowIsDirty, hasEntries } from "../surchargeHelper";
import classNames from "classnames";
import { ActionFooter, StatusMessage } from "core/components/actionFooter";
import { Button } from "core/components/button";
import { SaveStatus } from "common/loader";
const { TabPane } = Tabs;

export interface Props {
    permissions: CrudPermissions;
    hasUpdatePermission: boolean;
    surchargesEnabled: boolean;
    initialRecurringSurcharges: EditableSurcharge[];
    initialOneTimeSurcharges: EditableSurcharge[];
    previousSurcharges: EditableSurcharge[];
    surchargeIdColumn?: string;
    venueTime: moment.Moment;
    onToggleDisablePublish: (disabled: boolean) => void;
    onSurchargesToggle: (enabled: boolean) => void;
    onSubmit: (updatedSurcharges: EditableSurcharge[], oneTimeSurcharges: EditableSurcharge[]) => void;
    saveStatus?: SaveStatus;
}

export const SurchargesPage = ({
    surchargesEnabled,
    hasUpdatePermission,
    initialRecurringSurcharges,
    initialOneTimeSurcharges,
    previousSurcharges,
    surchargeIdColumn,
    venueTime,
    onToggleDisablePublish,
    onSurchargesToggle,
    onSubmit,
    saveStatus,
}: Props) => {
    const [recurringDatasource, setRecurringDatasource] = useState(initialRecurringSurcharges);
    const [oneTimeDatasource, setOneTimeDatasource] = useState(initialOneTimeSurcharges);
    const [updatedRowIds, setUpdatedRowIds] = useState<Set<string>>(new Set());
    const [invalidItems, setInvalidItems] = useState<ValidationError[]>([]);

    const validate = useCallback(
        async (surcharges: EditableSurcharge[], oneTime: EditableSurcharge[]): Promise<boolean> => {
            const errors = await validateSurcharges(
                surcharges,
                oneTime,
                recurringDatasource,
                oneTimeDatasource,
                !!surchargeIdColumn,
                surchargeIdColumn || "",
                venueTime
            );
            setInvalidItems(errors || []);
            //TODO: add scroll to first error later
            return !(errors && errors.length);
        },
        [oneTimeDatasource, recurringDatasource, surchargeIdColumn, venueTime]
    );

    const handleChange = useCallback(
        (surcharges: EditableSurcharge[], removedKey?: string) => {
            removedKey && setInvalidItems(invalidItems.filter((x) => x.rowId !== removedKey));
            setRecurringDatasource(surcharges);
        },
        [invalidItems]
    );

    const handleOneTimeChange = useCallback(
        (surcharges: EditableSurcharge[], removedKey?: string) => {
            removedKey && setInvalidItems(invalidItems.filter((x) => x.rowId !== removedKey));
            setOneTimeDatasource(surcharges);
        },
        [invalidItems]
    );

    const handleSubmit = useCallback(async () => {
        const updatedRecurringRecords = recurringDatasource.filter((d) => updatedRowIds.has(d.rowKey));
        const updatedOneTimeRecords = oneTimeDatasource.filter((d) => updatedRowIds.has(d.rowKey));
        if (!!updatedRecurringRecords.length || !!updatedOneTimeRecords.length) {
            const isValid = await validate(updatedRecurringRecords, updatedOneTimeRecords);
            isValid && onSubmit(updatedRecurringRecords, updatedOneTimeRecords);
        }
    }, [onSubmit, validate, oneTimeDatasource, recurringDatasource, updatedRowIds]);

    const handleRowsTouched = useCallback(
        (touchedIds: Set<string>) => {
            onToggleDisablePublish(!!touchedIds.size);
            setUpdatedRowIds(touchedIds);
        },
        [onToggleDisablePublish]
    );

    const handleClearRow = useCallback(
        (
            surcharge: EditableSurcharge,
            surcharges: EditableSurcharge[],
            initialSurcharges: EditableSurcharge[],
            onChangeCallback: (surcharges: EditableSurcharge[], removedKey?: string) => void
        ) => {
            const idx = surcharges.findIndex((x) => x.rowKey === surcharge.rowKey);

            surcharges[idx] = {
                ...surcharge,
                ...defaultSurchargeValues,
                markedForDelete: true,
                validationError: "",
            };

            onChangeCallback(surcharges, surcharge.rowKey);
            const updatedIds = updatedRowIds;

            if (!checkRowIsDirty(surcharge, initialSurcharges, true)) {
                updatedIds.delete(surcharge.rowKey);
            } else {
                updatedIds.add(surcharge.rowKey);
            }
            handleRowsTouched(updatedIds);
        },
        [handleRowsTouched, updatedRowIds]
    );

    const handleRecurringClear = useCallback(
        (surcharge: EditableSurcharge) => {
            const newDatasource = [...recurringDatasource];
            handleClearRow(surcharge, newDatasource, initialRecurringSurcharges, handleChange);
        },
        [handleClearRow, handleChange, initialRecurringSurcharges, recurringDatasource]
    );

    const handleOneTimeClear = useCallback(
        (surcharge: EditableSurcharge) => {
            const newDatasource = [...oneTimeDatasource];
            handleClearRow(surcharge, newDatasource, initialOneTimeSurcharges, handleOneTimeChange);
        },
        [handleClearRow, handleOneTimeChange, initialOneTimeSurcharges, oneTimeDatasource]
    );

    const getRowClass = useCallback((record: EditableSurcharge) => {
        return classNames(!hasEntries(record) && "row-empty", record.isLive && "row-live");
    }, []);

    return (
        <div className="surcharges-page">
            <div className="surcharges-page__content">
                <PageTitle
                    title="Surcharges"
                    description="Nominate which days of the week, or one-time dates that you wish to apply surcharges to."
                    disabled={!hasUpdatePermission}
                    toggleState={surchargesEnabled}
                    onToggleChange={onSurchargesToggle}
                />
                <div className="surcharges-page__body">
                    <div className="surcharges-page__body__title">Recurring surcharges</div>
                    <div className="surcharges-page__body__description">
                        Set which days of the week should have a recurring surcharge. Changes will require a publish.
                    </div>
                    <RecurringSurchargesTable
                        surchargeIdColumn={surchargeIdColumn}
                        surcharges={recurringDatasource}
                        initialSurcharges={initialRecurringSurcharges}
                        disabled={!surchargesEnabled}
                        getRowClass={getRowClass}
                        invalidItems={invalidItems}
                        onChange={handleChange}
                        onRowsTouched={handleRowsTouched}
                        onClearRow={handleRecurringClear}
                        touchedIds={updatedRowIds}
                    />
                </div>
                <div className="surcharges-page__body">
                    <div className="surcharges-page__body__title">One-time surcharges</div>
                    <div className="surcharges-page__body__description">
                        Nominate specific dates for surcharges, these will override the daily settings. Perfect for
                        holidays or special events.
                    </div>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab={`Upcoming (${initialOneTimeSurcharges.length - 1})`} key="1">
                            <OneTimeSurchargesTable
                                surchargeIdColumn={surchargeIdColumn}
                                surcharges={oneTimeDatasource}
                                initialSurcharges={initialOneTimeSurcharges}
                                disabled={!surchargesEnabled}
                                getRowClass={getRowClass}
                                invalidItems={invalidItems}
                                onChange={handleOneTimeChange}
                                onRowsTouched={handleRowsTouched}
                                onClearRow={handleOneTimeClear}
                                touchedIds={updatedRowIds}
                            />
                        </TabPane>
                        <TabPane tab="Previous" key="2">
                            <PreviousSurchargesTable
                                surchargeIdColumn={surchargeIdColumn}
                                surcharges={previousSurcharges}
                            />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
            {surchargesEnabled && (
                <ActionFooter align="left" forceDirty={!!updatedRowIds.size}>
                    {/* FIXME legacy : semantics are wrong. This should be in a form and of type submit */}
                    <Button
                        loading={saveStatus === "saving"}
                        type="button"
                        onClick={handleSubmit}
                        disabled={updatedRowIds.size === 0}
                    >
                        Save
                    </Button>
                    <StatusMessage forceDirty={!!updatedRowIds.size} />
                </ActionFooter>
            )}
            <ConfirmModal
                showModal={!!updatedRowIds.size}
                title={`Do you want to save your changes to surcharges?`}
                subTitle="You have unsaved changes"
                isPrompt={!!updatedRowIds.size}
                cancelText="Discard changes"
                confirmText="Keep editing"
            />
        </div>
    );
};
