import { SearchInput, SearchInputContainerProps, SelectItem } from "components/forms/SearchInput";
import { FastField } from "formik";
import { orderFlowOptions } from "../types/OrderFlow";

const options: SelectItem[] = orderFlowOptions.map((orderFlow) => ({
    id: orderFlow.value,
    displayName: orderFlow.label,
}));

export const OrderFlowSelector = ({ placeholder, disabled, name }: SearchInputContainerProps) => (
    <FastField name={name} component={SearchInput} disabled={disabled} placeholder={placeholder} options={options} />
);
