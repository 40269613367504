import styles from "./Image.module.scss";

import { Icon } from "core/components/icon";
import { menuDataUrl } from "common/menuData/menuDataUrl";
import { PictureOutlined } from "common/icons";
import { Spinner } from "core/components/spinner";
import { useCallback, useEffect, useRef, useState } from "react";

type ImageProps = Omit<React.HTMLProps<HTMLImageElement>, "crossOrigin">;

interface Props {
    image?: string | undefined;
}

export const Image = ({ image, ...rest }: Props & ImageProps) => {
    const [hasError, setHasError] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [imageSrc, setImageSrc] = useState("");
    const retryCount = useRef(0);

    const handleError = useCallback(() => {
        setHasError(true);
        if (retryCount.current < 2) {
            //retry for just uploaded images still processing;
            setTimeout(
                () => {
                    //fallback to original after second attempt if thumb not ready
                    retryCount.current === 1 && setImageSrc(imageSrc.replace("-thumb", "-orig"));
                    retryCount.current++;
                    setHasError(false);
                },
                retryCount.current === 0 ? 3000 : 200
            );
            return;
        }
        setLoaded(true);
    }, [imageSrc, retryCount]);

    useEffect(() => {
        image && setImageSrc(menuDataUrl(image));
    }, [image]);

    return (
        <span className={styles.root}>
            {!hasError && !!imageSrc ? (
                <img
                    className={styles.image}
                    onError={handleError}
                    onLoad={() => setLoaded(true)}
                    src={imageSrc}
                    {...rest}
                />
            ) : (
                <div className={styles.placeholder}>
                    <Icon size="extraLarge" outline>
                        <PictureOutlined />
                    </Icon>
                </div>
            )}
            <span className={!imageSrc || loaded ? styles.overlayLoaded : styles.overlay}>
                <Spinner />
            </span>
        </span>
    );
};
