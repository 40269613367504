import { FeatureConfiguration } from "..";
import { reducer } from "./reducers";
import * as actions from "./actions";
import { Route } from "react-router";
import { Col } from "antd";
import { DailySalesReportPageContainer } from "./containers/DailySalesReportContainer";
import { withPermissions } from "features/location/components/withPermission";

export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.dailySalesReport = reducer;

    config.addRoutes(config.routes.locationRoutes, [
        <Route
            key="reports-dailysales"
            path={`/:region/:location/reports/dailysales`}
            render={() => (
                <Col span={24} md={24} className="content__col content__col__dailysalesreportpage">
                    <div className="content__col-list">
                        <Route component={withPermissions(DailySalesReportPageContainer, "reports:sales")} />
                    </div>
                </Col>
            )}
        />,
    ]);
}
