import styles from "./CataloguePage.module.scss";

import { BulkDeleting } from "./BulkDeleting";
import { CardFooter } from "core/components/card";
import { CatalogueEmpty } from "./CatalogueEmpty";
import { CatalogueFilters, CatalogueItem, CollectionName } from "../types";
import { CatalogueLoadError } from "./CatalogueLoadError";
import { CatalogueLoading } from "./CatalogueLoading";
import { ComponentType, Dispatch, memo, useCallback, useEffect, useMemo, useRef } from "react";
import { List } from "./List";
import { ListHeaderRow } from "./ListHeaderRow";
import { ListItemGroup } from "./ListItemGroup";
import { LoadStatus } from "common/loader";
import { modifier, product } from ".";
import { Pagination } from "core/components/pagination";
import { scrollIntoView } from "common/utility/scrollIntoView";
import { SetStateAction } from "react";

import classNames from "classnames";

interface Props {
    activeFilters: CatalogueFilters;
    bulkDeleteStatus: LoadStatus;
    canBulkDelete: boolean;
    canBulkSelect: boolean;
    collectionName: CollectionName;
    fetchStatus: LoadStatus;
    formatCurrency: (x: number) => string;
    headers: ComponentType<product.HeadersProps> | ComponentType<modifier.HeadersProps>;
    isLoaded: boolean;
    items: CatalogueItem[];
    listItem: ComponentType<product.ListItemProps> | ComponentType<modifier.ListItemProps>;
    noResults: boolean;
    pageIndex: number;
    pageSize: number;
    retry: () => void;
    setPageIndex: Dispatch<SetStateAction<number>>;
    setPageSize: Dispatch<SetStateAction<number>>;
    textFilter: string;
    warnings: ComponentType<{}>;
}

const CatalogueTabComponent = ({
    activeFilters,
    canBulkDelete,
    canBulkSelect,
    collectionName,
    fetchStatus,
    bulkDeleteStatus,
    formatCurrency,
    headers,
    items,
    isLoaded,
    listItem,
    noResults,
    pageIndex,
    pageSize,
    retry,
    setPageIndex,
    setPageSize,
    textFilter,
    warnings: Warnings,
}: Props) => {
    const resetOnFilterChange = useRef(false);

    const start = (pageIndex - 1) * pageSize;

    const end = pageIndex * pageSize;

    const pageItems = useMemo(() => items.slice(start, end), [items, start, end]);

    const handlePageSizeChange = useCallback(
        (size: number) => {
            if (size !== pageSize) {
                setPageSize(size);
                setPageIndex(1);
                scrollIntoView("#catalogue-list");
            }
        },
        [pageSize, setPageSize, setPageIndex]
    );

    // reset page on filters change
    useEffect(() => {
        if (resetOnFilterChange.current) {
            setPageIndex(1);
        } else {
            resetOnFilterChange.current = true;
        }
    }, [activeFilters, setPageIndex, textFilter]);

    if (fetchStatus === "loading" || fetchStatus === "unloaded") {
        return <CatalogueLoading />;
    }

    if (bulkDeleteStatus === "loading") {
        return <BulkDeleting />;
    }

    if (fetchStatus === "failed") {
        return <CatalogueLoadError retry={retry} />;
    }

    return (
        <>
            <Warnings />
            <div className={classNames(isLoaded ? styles.resultsTableLoaded : styles.resultsTable)} role="table">
                {!noResults && (
                    <ListHeaderRow
                        collectionName={collectionName}
                        pageItems={pageItems}
                        headers={headers}
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        canBulkDelete={canBulkDelete}
                        canBulkSelect={canBulkSelect}
                    />
                )}
                {pageItems.length === 0 && (
                    <div className={styles.emptyMessageContainer}>
                        <CatalogueEmpty collectionName={collectionName} />
                    </div>
                )}
                <List>
                    {pageItems.map((item) => {
                        return (
                            <ListItemGroup
                                key={item.key}
                                collectionName={collectionName}
                                formatCurrency={formatCurrency}
                                item={item}
                                canBulkSelect={canBulkSelect}
                                component={listItem}
                            />
                        );
                    })}
                </List>
                {!noResults && (
                    <CardFooter>
                        <Pagination
                            allowUserPageSize
                            pageSize={pageSize}
                            pageIndex={pageIndex}
                            buffer={2}
                            count={items.length}
                            onChange={(index) =>
                                setPageIndex((prevIndex) => {
                                    if (prevIndex !== index) {
                                        scrollIntoView("#catalogue-list");
                                    }
                                    return index;
                                })
                            }
                            onPageSizeChange={(size) => handlePageSizeChange(size)}
                        />
                    </CardFooter>
                )}
            </div>
        </>
    );
};

export const CatalogueTab = memo(CatalogueTabComponent);
