import styles from "./ListItem.module.scss";

import { CatalogueItem } from "../types";
import { getPosSyncEnabled } from "../selectors/getPosSyncEnabled";
import { getStatusFromValidationStatus, SyncStatus } from "core/components/syncStatus";
import { useSelector } from "react-redux";

interface Props {
    item: CatalogueItem;
}

export const ListItemSyncStatus = ({ item }: Props) => {
    const posSyncEnabled = useSelector(getPosSyncEnabled);
    const isValidated = !item.children?.length;
    const status = getStatusFromValidationStatus(item.validationStatus);

    if (!status || !posSyncEnabled || !isValidated) {
        return null;
    }

    return (
        <span className={styles.syncContainer}>
            <SyncStatus status={status} />
        </span>
    );
};
