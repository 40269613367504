import { message } from "antd";
import { opsApiFetch } from "API/fetch";
import { downloadBlob } from "common/utility/fileUtils";

export async function fetchDownloadPromotionConfiguration(
    locationId: string,
    promotionId: string,
    fileName: string,
    cb?: () => void
) {
    try {
        const res = await opsApiFetch(`/export/locations/${locationId}/promotions/${promotionId}`);
        if (!res.ok) {
            throw new Error("Failed to download promotion");
        }
        const blob = await res.blob();
        downloadBlob(blob, fileName);
    } catch {
        message.error("Download failed");
    } finally {
        cb?.();
    }
}
