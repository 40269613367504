import { isNew } from "common/utility/modelUtils";
import { createAction } from "../reducers/edit";
import { EditableLocation } from "../types/EditableLocation";
import { scaffoldEditAction } from "common/scaffolding/actions/scaffoldEditAction";
import { editLocation, GraphQLLocation } from "../api/editLocation";
import { EditableNewLocation } from "../types/EditableNewLocation";

export const edit = (slug: string) =>
    scaffoldEditAction(
        slug,
        (state) => state.locations.edit,
        createAction,
        async () => {
            if (isNew(slug)) {
                return newEditableLocation();
            }

            const location = await editLocation(slug);
            return mapEditableLocation(location);
        }
    );

const mapEditableLocation = (input: GraphQLLocation): EditableLocation => ({
    id: input.id,
    displayName: input.displayName,
    billingSystemId: input.billingSystemId,
    stagedBillingSystemId: input.stagedBillingSystemId,
    companyDetails: input.companyDetails,
    lastPublished: input.lastPublished,
    lastPublishedUpdate: input.lastPublishedUpdate,
    slug: input.slug,
    isDemo: input.isDemo || false,
    isSalesMode: input.isSalesMode || false,
    backgroundImage: input.backgroundImage,
    menuBackgroundImage: input.menuBackgroundImage,
    heroImage: input.heroImage,
    defaultCatalogueImage: input.defaultCatalogueImage,
    themeColor: input.themeColor,
    timeZone: input.timeZone,
    locale: input.locale,
    groupId: input.groupId,
    isMarketingEnabled: mapMarketingEnabled(input),
    stripeCommission: input.stripeCommission,
    logoUrl: input.logoUrl,
});

export const newEditableLocation = (): EditableNewLocation => ({
    displayName: "",
    slug: "",
    timeZone: "",
    locale: "",
    groupId: "",
    newGroupName: "",
    isLiveOrdersEnabled: true,
    clone: false,
    createAsBrand: false,
    orderFlow: null as any,
});

const mapMarketingEnabled = (input: GraphQLLocation): boolean => {
    if (input.groupId) {
        return (input.group && input.group.marketingOptions && input.group.marketingOptions.enabled) || false;
    }

    return (input.marketingOptions && input.marketingOptions.enabled) || false;
};
