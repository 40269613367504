import { opsApi } from "API";

export async function createDefaultQrCode(locationSlug: string, url: string, table: string, section?: string) {
    const getTagQuery: string = `
      mutation ($location: ID!, $data: QrCodeUpdateInput!) {
        createQrCode(
          locationId: $location,
          data: $data
        ){
          token
        }
      }`;

    const response = await opsApi.graphQLQuery(getTagQuery, { location: locationSlug, data: { url, table, section } });

    return response.data.createQrCode;
}
