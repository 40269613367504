import { scaffoldSaveAction } from "common/scaffolding/actions/scaffoldSaveAction";
import { fromPercentageWithPrecision, numberOrZero } from "common/utility/numberUtils";
import { Group } from "features/group";
import { EditableGroup } from "features/group/types/EditableGroup";
import { fetchActive } from "features/location/actions";
import { GraphQLTrayChargeInputModel, TrayCharge } from "features/trayCharge/types";
import { edit } from ".";
import { LocationSettingsUpdateModel, saveLocationSettings } from "../api/saveLocationSettings";
import { saveTrayCharge } from "features/trayCharge/api/saveTrayCharge";
import { createAction } from "../reducers/edit";
import { getSaveLocationSettingsTrackingEventData } from "../selectors/getSaveLocationSettingsTrackingEventData";
import { fromPercentage, hasDefinedValue } from "../tipsHelpers";
import { EditableLocationSettings, TipOptions } from "../types";
import { GraphQLVenueServiceFeeInputModel, VenueServiceFee } from "features/venueServiceFee/types";
import { saveVenueServiceFee } from "features/venueServiceFee/api/saveVenueServiceFee";

export const save = (
    location: string,
    editableLocationSettings: EditableLocationSettings,
    callback?: () => void,
    clone: boolean = false
) =>
    scaffoldSaveAction(
        (state) => state.LocationSettings.edit,
        createAction,
        async (locationId: string | undefined, dispatch) => {
            const locationSettingsUpdate = mapToLocationSettingsInputModel(editableLocationSettings);
            if (!editableLocationSettings.enableDietaryNotifier) {
                locationSettingsUpdate.fromTheVenue = undefined;
            }
            if (locationSettingsUpdate.fromTheVenue) {
                locationSettingsUpdate.fromTheVenue = stripHtml(locationSettingsUpdate.fromTheVenue);
            }

            // save location settings
            const updatedLocationSettings = await saveLocationSettings(locationId, locationSettingsUpdate);

            // save location settings - tray charge
            const trayChargeUpdate = editableLocationSettings.trayCharge;
            if (trayChargeUpdate?.id || trayChargeUpdate?.enabled) {
                await saveTrayCharge(
                    locationId!,
                    trayChargeUpdate?.id,
                    mapToTrayChargeInputModel(editableLocationSettings.trayCharge!)
                );
                updatedLocationSettings.trayCharge = editableLocationSettings.trayCharge;
            }

            // save location settings - venue service fee
            const venueServiceFeeUpdate = editableLocationSettings.venueServiceFee;
            if (venueServiceFeeUpdate?.id || venueServiceFeeUpdate?.enabled) {
                await saveVenueServiceFee(
                    locationId!,
                    venueServiceFeeUpdate?.id,
                    mapToVenueServiceFeeInputModel(editableLocationSettings.venueServiceFee!)
                );
                updatedLocationSettings.venueServiceFee = editableLocationSettings.venueServiceFee;
            }

            await dispatch(fetchActive(updatedLocationSettings.slug, true));
            await dispatch(edit(updatedLocationSettings.id));
            return updatedLocationSettings;
        },
        undefined,
        clone,
        callback,
        undefined,
        (appState) => getSaveLocationSettingsTrackingEventData(appState, editableLocationSettings)
    );

export const mapToTrayChargeInputModel = (trayCharge: TrayCharge): GraphQLTrayChargeInputModel => ({
    enabled: trayCharge.enabled,
    name: (trayCharge.name || "Venue tray charge").trim(),
    fixedAmount: numberOrZero(trayCharge.fixedAmount),
    posId: trayCharge.posId,
});

export const mapToVenueServiceFeeInputModel = (venueServiceFee: VenueServiceFee): GraphQLVenueServiceFeeInputModel => ({
    enabled: venueServiceFee.enabled,
    factor: fromPercentageWithPrecision(venueServiceFee.percentage, 4),
});

export const mapToLocationSettingsInputModel = ({
    ageRestriction,
    allowNotesToKitchen,
    allowTips,
    confirmEighteenPlus,
    enableAnotherRound,
    enableGroupTabs,
    enableQuickSell,
    quickSellDrinksOnly,
    enableSurcharges,
    facebookPixelId,
    fromTheVenue,
    fromTheVenueTitle,
    group,
    idleTimeoutMinutes,
    isBuzzer,
    isLiveOrdersEnabled,
    marketingOptions,
    mustAcceptAllergenNotice,
    orderBatchTimeSeconds,
    drinkOrderBatchTimeSeconds,
    foodOrderBatchTimeSeconds,
    sectionsWithDocketMerging,
    tipOptions,
    unusedPartyTimeoutMinutes,
    groupTabGratuityFactor,
    enablePosSync,
    alcoholicDrinksRestriction,
    enableProductVideos,
    enableAbsorbMerchantFee,
    orderFlow,
    splitPaymentOptions,
}: EditableLocationSettings): LocationSettingsUpdateModel => ({
    ageRestriction,
    allowNotesToKitchen,
    allowTips,
    confirmEighteenPlus,
    defaultTipLevel: tipOptions.defaultIndex, // deprecated
    enableAnotherRound,
    enableGroupTabs,
    enableQuickSell,
    quickSellDrinksOnly,
    enableSurcharges,
    facebookPixelId,
    fromTheVenue,
    fromTheVenueTitle,
    group: mapToEditableGroup(group),
    idleTimeoutMinutes,
    isBuzzer,
    isLiveOrdersEnabled,
    marketingOptions,
    mustAcceptAllergenNotice,
    orderBatchTimeSeconds,
    // Saving as 0 for drink and food means overwriting the default orderBatchTimeSeconds
    drinkOrderBatchTimeSeconds: drinkOrderBatchTimeSeconds || 0,
    foodOrderBatchTimeSeconds: foodOrderBatchTimeSeconds || 0,
    sectionsWithDocketMerging,
    tipOptions: mapTips(tipOptions),
    unusedPartyTimeoutMinutes,
    groupTabGratuityFactor: groupTabGratuityFactor ? fromPercentage(groupTabGratuityFactor) : undefined,
    enablePosSync,
    alcoholicDrinksRestriction,
    enableProductVideos,
    enableAbsorbMerchantFee,
    orderFlow,
    splitPaymentOptions: splitPaymentOptions,
});

function mapToEditableGroup(group: Group | undefined): EditableGroup | undefined {
    return group
        ? {
              id: group.id,
              marketingOptions: group.marketingOptions || { enabled: false, prompt: false },
          }
        : undefined;
}

const sort = (a: number | null, b: number | null) => {
    a = a || 0;
    b = b || 0;
    return a - b;
};

const mapTips = (input: TipOptions): TipOptions => {
    if (!input.levels || !hasDefinedValue(input.levels)) {
        return {
            ...input,
            levels: null,
        };
    }

    const levels = input.levels.map(Number);

    const preselectedValue = input.defaultIndex > -1 ? levels[input.defaultIndex] : null;

    const sorted = levels.sort(sort);

    const defaultIndex = preselectedValue ? sorted.indexOf(preselectedValue) : input.defaultIndex;

    const levelsPercent = sorted.map(fromPercentage);

    return {
        ...input,
        defaultIndex,
        levels: levelsPercent,
    };
};

const stripHtml = (value: string) => new DOMParser().parseFromString(value, "text/html").body.textContent || undefined;
