import { ViewBrandingPageContainer } from "./containers/ViewBrandingPageContainer";
import { EditBrandingPageContainer } from "./containers/EditBrandingPageContainer";
import { FeatureConfiguration } from "features";
import { withPermissions } from "features/location/components/withPermission";
import { Route } from "react-router";
import { Col } from "antd";

export * from "./types";

export default function (config: FeatureConfiguration) {
    config.addRoutes(config.routes.locationRoutes, [
        <Route
            path={"/:region/:location/branding"}
            key="branding"
            render={() => (
                <>
                    <Col span={24} xl={12} className="content__col content__col--branding">
                        <div className="content__col-view">
                            <Route component={withPermissions(ViewBrandingPageContainer, "location:update")} />
                        </div>
                    </Col>
                    <Col span={24} xl={12} className="content__col content__col--branding">
                        <div className="content__col-edit">
                            <Route component={withPermissions(EditBrandingPageContainer, "location:update")} />
                        </div>
                    </Col>
                </>
            )}
        />,
    ]);
}
