import { opsApi } from "API";
import { PackageSummary } from "../types";

export const listPackages = async (locationId: string) => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId });
    return res.data.packages;
};

type GraphQLPayload = { packages: PackageSummary[] };

const query = `
    query ($locationId: ID!) {
        packages(locationId: $locationId) {
            id
            displayName
            description
            enabled
            itemCount
            usedCount
        }
    }
`;
