import { FieldInputProps, FormikProps } from "formik";
import { initIdsByTypeDictionary } from "common/data/treeUtils";
import { TreeItem } from "common/types";

import { EditablePromotion, PromotionItems, promotionItemTypes } from "../../types";
import { useEffect } from "react";
import { usePrevious } from "common/hooks";

interface Props {
    form: FormikProps<EditablePromotion>;
    field: FieldInputProps<PromotionItems>;
    selectedItems: TreeItem[];
}

// this component binds the selected tree items to the formik field value
export const ProductSelectionsAdapter = ({ field: { name }, form, selectedItems }: Props) => {
    const {
        values: { applyToAll },
        setFieldValue,
    } = form;

    const prevApplyToAll = usePrevious(applyToAll);

    // handle tree selections
    useEffect(() => {
        if (applyToAll) {
            return;
        }

        const idsByType = initIdsByTypeDictionary(promotionItemTypes);

        const selections: PromotionItems = {
            productIds: [],
            variantIds: [],
        };

        selectedItems
            .filter((node: TreeItem) => promotionItemTypes.includes(node.type))
            .forEach((node: TreeItem) => {
                // check dictionary to only add items once
                if (!idsByType[node.type][node.id]) {
                    idsByType[node.type][node.id] = true;
                    selections[`${node.type}Ids`]?.push(node.id);
                }
            });

        // keep ids sorted for better 'dirty' checks
        selections.productIds.sort();
        selections.variantIds.sort();

        setFieldValue(name, selections);
    }, [selectedItems, applyToAll, name, setFieldValue]);

    // scroll product tree into view when 'apply to all' toggled off
    useEffect(() => {
        if (!applyToAll && prevApplyToAll) {
            const el = document.querySelector(".tree-wrapper");
            el?.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [applyToAll, prevApplyToAll]);

    return null;
};
