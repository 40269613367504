import { TakeawayScheduleOptions } from "features/location";
import { EditableTakeawayOptions } from "features/takeaway/types";
import * as Yup from "yup";
import { getVenueAddressSchema } from "./VenueAddressSchema";

const minReadyTimeValue = 0;
const maxReadyTimeValue = 1440;
const readyTimeValueRangeMsg = `The order lead time must be between ${minReadyTimeValue} and ${maxReadyTimeValue} minutes.`;

export const getTakeawayOptionsSchema = () => {
    return Yup.object<EditableTakeawayOptions>().shape({
        enabled: Yup.boolean(),
        minReadyTimeMins: Yup.number()
            .typeError("Valid number required")
            .required(readyTimeValueRangeMsg)
            .min(minReadyTimeValue, readyTimeValueRangeMsg)
            .max(maxReadyTimeValue, readyTimeValueRangeMsg),
        sectionId: Yup.string()
            .nullable(true)
            .when("enabled", {
                is: true,
                then: Yup.string().required("A section is required when Takeaway is enabled."),
                otherwise: Yup.string(),
            }),
        contactPhoneNumber: Yup.string()
            .nullable(true)
            .matches(/^[^a-zA-Z]*$/, "The contact phone number cannot contain alpha characters."),
        collectionInstructions: Yup.string().nullable(true),
        scheduleOptions: Yup.object<TakeawayScheduleOptions>()
            .nullable(true)
            .shape({
                enabled: Yup.boolean(),
                scheduleWindowMinutes: Yup.number().typeError("Valid number required."),
            }),
        venueAddress: getVenueAddressSchema(),
    });
};
