import moment from "moment";
import { Row, Col, Button } from "antd";
import { EditableService } from "features/service/types/EditableService";
import { ArrayHelpers, FormikProps, Field } from "formik";
import { RenderDateRange, STORAGE_DATE_FORMAT } from "./DateRange";

import "./EditDateRanges.scss";

export interface Props {
    name: string;
    form: FormikProps<EditableService>;
    disabled?: boolean;
    dateFormat: string;
}

export function EditDateRanges({
    form: {
        values: { dates },
    },
    dateFormat,
    push,
    remove,
    disabled,
}: Props & ArrayHelpers): React.ReactElement {
    const addDateRange = () => {
        push({
            startDate: moment(new Date()).format(STORAGE_DATE_FORMAT),
            endDate: moment(new Date()).format(STORAGE_DATE_FORMAT),
        });
    };

    const handleRemove = (index: number) => {
        remove(index);
    };

    return (
        <Row justify="space-between" style={{ flexDirection: "column", alignItems: "start" }}>
            <Col>
                {dates &&
                    dates.map((dateRange, index: number) => (
                        <Field
                            key={`${index}-${dateRange.startDate.toString()}-${dateRange.endDate.toString()}`}
                            name={`dates.${index}`}
                            component={RenderDateRange}
                            dateFormat={dateFormat}
                            onRemove={() => handleRemove(index)}
                            disabled={disabled}
                        />
                    ))}
            </Col>

            <Col className="date-range__add">
                <Button onClick={addDateRange} title="Add" disabled={disabled}>
                    Add a date range
                </Button>
            </Col>
        </Row>
    );
}
