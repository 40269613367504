import styles from "./Checkbox.module.scss";

import { FieldInputProps } from "formik";
import classNames from "classnames";
import { CheckMark } from "common/icons";
import { Icon } from "core/components/icon/Icon";
import warnOnce from "warn-once";
import { ChangeEvent, forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useRef } from "react";

export type InputProps = Omit<React.HTMLProps<HTMLInputElement>, "onChange">;

export interface Props {
    className?: string;
    disabled?: boolean;
    field?: FieldInputProps<any>;
    id?: string;
    indeterminate?: boolean;
    label?: string;
    labelClassName?: string;
    onChange?: (checked: boolean, event: ChangeEvent<HTMLInputElement>) => boolean | void;
}

export const Checkbox = forwardRef<HTMLInputElement, InputProps & Props>(
    (
        // form and children are not used, but destructured so they are not passed in ...rest
        { className, disabled, field, form, indeterminate, label, labelClassName, onChange, ...rest },
        ref: Ref<HTMLInputElement | null>
    ) => {
        const inputRef = useRef<HTMLInputElement | null>(null);

        const name: string | undefined = field?.name || rest.name;

        useImperativeHandle(ref, () => inputRef.current);

        // because indeterminate cannot be passed in markup
        useEffect(() => {
            if (inputRef.current) {
                inputRef.current.indeterminate = !!indeterminate;
            }
        }, [indeterminate]);

        const handleChange = useCallback(
            (event) => {
                const checked = event.target?.checked;
                const proceed = onChange?.(checked, event);
                if (proceed !== false) {
                    field?.onChange(event);
                }
            },
            [field, onChange]
        );

        if (field) {
            warnOnce(
                field.checked === undefined || field.checked === null,
                `Checkbox did not receive a field.checked property for \`${field?.name}\`. Did you forget to add type="checkbox" to Field props?`
            );
        }

        return (
            <div className={classNames(styles.checkboxContainer, className)}>
                <label className={classNames(styles.label, disabled && styles.disabled)}>
                    <input
                        type="checkbox"
                        disabled={disabled}
                        {...(name ? { "data-testid": `input-${name}` } : null)}
                        {...rest}
                        {...field}
                        ref={inputRef}
                        onChange={handleChange}
                    />
                    <span
                        className={classNames(
                            label && styles.labelText,
                            !label && styles.labelTextHidden,
                            labelClassName
                        )}
                    >
                        {label}
                    </span>
                    <Icon className={styles.checkboxIcon} size="small">
                        <CheckMark />
                    </Icon>
                </label>
            </div>
        );
    }
);
