import { ReactElement } from "react";
import styles from "./Spinner.module.scss";

interface Props {
    size?: "default" | "medium" | "large";
}

export const Spinner = ({ size = "default" }: Props): ReactElement => {
    return (
        <div className={styles.spinner}>
            <div className={styles[`icon--${size}`]}></div>
        </div>
    );
};
