import { Promotion, PromotionScheduleState, SortablePromotion } from "./Promotion";

export const isExpired = (promotion: Promotion) => {
    if (!promotion.status) {
        return false;
    }
    return promotion.status.toString() === PromotionScheduleState[PromotionScheduleState.EXPIRED];
};

export const isNotExpired = (promotion: Promotion) => {
    if (!promotion.status) {
        return true;
    }
    return promotion.status.toString() !== PromotionScheduleState[PromotionScheduleState.EXPIRED];
};

export const getUnsortablePromotions = (promotions: Promotion[]) => {
    if (!promotions) {
        return [];
    }
    return promotions.filter((promotion) => promotion.status === null || !promotion?.restrictions?.schedule?.startDate);
};

export const getSortablePromotions = (promotions: Promotion[]): SortablePromotion[] => {
    if (!promotions) {
        return [];
    }
    return promotions.filter(
        (promotion) =>
            promotion.status !== null &&
            promotion.status !== undefined &&
            !!promotion?.restrictions?.schedule?.startDate
    ) as SortablePromotion[];
};

export const sortPromotions = (promotions: Promotion[]): Promotion[] => {
    const unsortable = getUnsortablePromotions(promotions);

    const sortable = getSortablePromotions(promotions);

    const notExpired = sortNonExpiredPromotions(sortable.filter(isNotExpired));

    const expired = sortPromotionsByEndDate(sortable.filter(isExpired)).reverse();

    return [...unsortable, ...notExpired, ...expired];
};

export const sortNonExpiredPromotions = (promotions: SortablePromotion[]) =>
    [...promotions].sort((a, b) => {
        if (PromotionScheduleState[a.status] > PromotionScheduleState[b.status]) {
            return 1;
        }

        if (PromotionScheduleState[a.status] < PromotionScheduleState[b.status]) {
            return -1;
        }

        if (a.restrictions.schedule.startDate > b.restrictions.schedule.startDate) {
            return -1;
        }
        if (a.restrictions.schedule.startDate < b.restrictions.schedule.startDate) {
            return 1;
        }

        return 0;
    });

export const sortPromotionsByEndDate = (promotions: SortablePromotion[]) =>
    [...promotions].sort((a, b) => {
        if (b.restrictions.schedule.endDate && a.restrictions.schedule.endDate) {
            if (a.restrictions.schedule.endDate > b.restrictions.schedule.endDate) {
                return 1;
            }
            if (a.restrictions.schedule.endDate < b.restrictions.schedule.endDate) {
                return -1;
            }
        }

        return 0;
    });
