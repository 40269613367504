import { ListSectionsPageContainer } from "./containers/ListSectionsPageContainer";
import { EditSectionPageContainer } from "./containers/EditSectionPageContainer";
import { FeatureConfiguration } from "features";
import * as actions from "./actions";
import { reducer } from "./reducers";
import { CrudRoute } from "common/scaffolding/components/CrudRoute";
import { withPermissions } from "features/location/components/withPermission";

export * from "./types";

export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.sections = reducer;

    config.addRoutes(config.routes.locationRoutes, [
        <CrudRoute
            name="sections"
            section={null}
            key="sections"
            listComponent={withPermissions(ListSectionsPageContainer, "section:read")}
            editComponent={withPermissions(EditSectionPageContainer, "section:read")}
        />,
    ]);
}
