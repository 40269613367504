import styles from "./PosLookupField.module.scss";
import { PosFieldProps } from "./PosFieldProps";
import { PosLookup, PosLookupItem } from "features/posConfiguration/types/PosLookup";
import { Loaded } from "common/loader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PosLookupFieldDefinition } from "features/location";
import { fetchLookup } from "features/posConfiguration/actions/fetchLookup";
import { useMemo } from "react";
import { Input } from "core/components/form/input";
import { Select, Option as SelectOption } from "core/components/form/select";
import { SortableTransfer, Option as TransferOption } from "../SortableTransfer";
import { Field, Formik } from "formik";
import { Effect } from "common/FormikEdffect";
import { FormikState } from "formik";
import { Spinner } from "core/components/spinner";
import { PosFieldScope, usePosLookupScope } from "../posUI/PosFieldScope";
import { getPosLookup } from "features/posConfiguration/selectors/getPosLookup";
import { useCallback } from "react";

export interface PosLookupFieldProps extends PosFieldProps<PosLookupFieldDefinition> {
    lookupState: Loaded<PosLookup>;
    lookupScope?: string;
}

const PosLookupFieldInner = (props: PosLookupFieldProps) => {
    const isMulti = props.definition.multiValueDelimiter;

    if (isMulti) {
        return <PosLookupFieldMulti {...props} />;
    } else {
        return <PosLookupFieldSingle {...props} />;
    }
};

const PosLookupFieldSingle = ({ definition, lookupState, lookupScope, ...rest }: PosLookupFieldProps) => {

    const dispatch = useDispatch();

    const [options, setOptions] = useState<SelectOption[]>([]);

    const getLookupLabel = useCallback(
        (item: PosLookupItem) => definition.lookupValue ? item.value : `${item.value} (${item.key})`,
        [definition.lookupValue]);

    const getLookupValue = useCallback(
        (item: PosLookupItem) => definition.lookupValue ? item.value : item.key,
        [definition.lookupValue]);

    useEffect(() => {
        if (lookupState.status === "unloaded") {
            dispatch(fetchLookup(definition.lookup, lookupScope));
        }

        if (lookupState.status !== "loaded") {
            return;
        }

        setOptions(lookupState.data.items.map((item) => ({
            label: getLookupLabel(item),
            value: getLookupValue(item),
        })));
    }, [lookupState, definition, dispatch, lookupScope, getLookupValue, getLookupLabel]);

    useEffect(() => {
        if (lookupState.status === "loaded") {
            if (rest.field.value && !lookupState.data.items.find(x => getLookupValue(x) === rest.field.value)) {
                rest.form.setFieldValue(rest.field.name, "", true);
            }
        }
    }, [rest.form, rest.field, lookupState, getLookupValue])

    const allowEmpty = useMemo(() => {
        return !definition.validation || new RegExp(definition.validation).test("");
    }, [definition]);

    const { placeholder } = definition;

    if (lookupState.status === "failed") {
        return <Input markRequired={!allowEmpty} placeholder={placeholder || undefined} {...rest} />;
    }

    return (
        <>
            <PosFieldScope field={rest.field.name} scopeKey={definition.name} />
            <Select
                markRequired={!allowEmpty}
                isClearable={allowEmpty}
                options={options}
                isLoading={lookupState.status === "loading"}
                {...rest}
            />
        </>
    );
};

const PosLookupFieldMulti = ({ definition, lookupState, lookupScope, ...rest }: PosLookupFieldProps) => {
    const delimiter = definition.multiValueDelimiter!;

    const fieldName = rest.field.name;

    const dispatch = useDispatch();

    const [options, setOptions] = useState<TransferOption[]>([]);

    const [initialFormData] = useState(() => {
        const initialValueParts: string[] = rest.field.value ? rest.field.value.split(delimiter) : [];
        const formValues = {};
        formValues[fieldName] = initialValueParts;
        return formValues;
    });

    useEffect(() => {
        if (lookupState.status === "unloaded") {
            dispatch(fetchLookup(definition.lookup, lookupScope));
        }

        if (lookupState.status !== "loaded") {
            return;
        }

        setOptions(
            lookupState.data.items.map(({ value, key }) => ({
                displayName: `${value} (${key})`,
                id: key,
            }))
        );
    }, [lookupState, definition.lookup, dispatch, lookupScope]);

    const allowEmpty = useMemo(() => {
        return !definition.validation || new RegExp(definition.validation).test("");
    }, [definition]);

    function updateFieldValue(current: FormikState<{}>, state: FormikState<{}>) {
        const parts = state.values[fieldName] as string[];

        if (current.values[fieldName] === parts) {
            return;
        }

        if (lookupState.status === "loaded") {
            rest.form.setFieldTouched(fieldName, true);
            if (!parts || parts.length === 0) {
                rest.form.setFieldValue(fieldName, null);
            } else {
                rest.form.setFieldValue(fieldName, parts.join(delimiter));
            }
        }
    }

    const { placeholder } = definition;

    if (lookupState.status === "failed") {
        return <Input markRequired={!allowEmpty} placeholder={placeholder || undefined} {...rest} />;
    }

    if (lookupState.status === "loading") {
        return (
            <Formik initialValues={initialFormData} onSubmit={() => {}}>
                <div className={styles.loadingTransfer}>
                    <SortableTransfer options={[]} disabled {...rest} />
                    <div className={styles.loadingTransferSpinnerContainer}>
                        <Spinner size="large" />
                    </div>
                </div>
            </Formik>
        );
    }

    // SortableTransfer relies on a `string[]` form value, but we need to map
    // it to a delimited string value. To achieve, this, we host a nested
    // Formik form and use an `Effect` to monitor changes to the value and
    // project it back into the parent form (as a delimited string).
    //
    // Since we're monitoring changes to the field, there's no need to handle
    // 'submit'
    return (
        <Formik initialValues={initialFormData} onSubmit={() => {}}>
            {(form) => {
                return (
                    <>
                        <Effect onChange={updateFieldValue} />
                        <Field name="posMenuId" component={SortableTransfer} options={options} />
                    </>
                );
            }}
        </Formik>
    );
};

export const PosLookupField = (props: PosFieldProps<PosLookupFieldDefinition>) => {
    const [lookupScope, _] = usePosLookupScope(props.definition.lookupScope);
    const lookupState = useSelector(getPosLookup)(props.definition.lookup, lookupScope);

    const childProps = {
        ...props,
        lookupState,
        lookupScope
    };

    return <PosLookupFieldInner {...childProps} />
};
