import { opsApi } from "API";
import { CompanyDetails } from "../types/CompanyDetails";
import { GraphQLItem } from "../../../common/scaffolding/types";
import { MarketingOptions } from "features/locationSettings/types/MarketingOptions";
import { Group } from "features/group";

export const editLocation = async (locationId: string): Promise<GraphQLLocation> => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId });
    return res.data.location;
};

type GraphQLPayload = { location: GraphQLLocation };

export interface GraphQLLocation extends GraphQLItem {
    billingSystemId: string;
    stagedBillingSystemId: string | null;
    slug: string;
    groupId?: string;
    lastPublished: string;
    logoUrl: string;
    lastPublishedUpdate: string;
    companyDetails: CompanyDetails;
    isDemo: boolean;
    isSalesMode: boolean;
    backgroundImage?: string;
    menuBackgroundImage?: string;
    heroImage?: string;
    defaultCatalogueImage?: string;
    themeColor?: string;
    timeZone: string;
    locale: string;
    marketingOptions?: MarketingOptions;
    group?: Group;
    stripeCommission?: number;
}

const query = `
    query($locationId: ID!) {
        location(locationId: $locationId) {
            id,
            displayName,
            groupId,
            billingSystemId,
            stagedBillingSystemId,
            slug,
            lastPublished,
            lastPublishedUpdate,
            companyDetails {
                registeredName,
                abn,
                address,
                privacyPolicyUrl
            },
            isDemo,
            isSalesMode,
            backgroundImage,
            menuBackgroundImage,
            heroImage,
            logoUrl,
            defaultCatalogueImage,
            themeColor,
            timeZone,
            locale, 
            marketingOptions {
                enabled
            },
            group {
                marketingOptions {
                    enabled
                }
            },
            stripeCommission
        }
    }
`;
