import "./App.scss";
import { RouteComponentProps, Redirect } from "react-router";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Layout } from "features/structure/screen";
import { locationRoute, LocationContext } from "features/location";
import { CallbackPageContainer } from "features/callback/containers/CallbackPageContainer";
import { withLogin, auth } from "common/auth";
import featureConfig from "features";
import { region } from "features/region";
import { getLegacyRegion } from "features/region/getLegacyRegion";
import { withRegion } from "features/region/components/withRegion";
import { IsPosMenuSupported } from "features/location/selectors/getPosMenuSupported";
import { PageView } from "common/appInsights/components/PageView";

const locationRoutes = ({ location, match }: RouteComponentProps<any>) => (
    <>
        <PageView />
        <Layout>{featureConfig.routes.locationRoutes}</Layout>
    </>
);

const getUserConfirmation = (dialogKey: string, callback: (ok: boolean) => void) => {
    // use "message" as Symbol-based key
    const dialogTrigger = window[Symbol.for(dialogKey)];

    if (dialogTrigger) {
        // pass the callback to delegate
        // to the invoked dialog
        return dialogTrigger(callback);
    }

    // Fallback to allowing navigation
    callback(true);
};

const protectedLocationRoutes = withLogin(withRegion(locationRoute(locationRoutes)), () => auth);

const RedirectToDefaultLocation = withLogin(
    withRegion(
        locationRoute(() => (
            <LocationContext.Consumer>
                {(props) => (
                    <Redirect
                        to={
                            props.slug !== "" && IsPosMenuSupported(props.posMetadata.operations)
                                ? `/${region.getActiveRegion().id}/${props.slug}/sections`
                                : `/${region.getActiveRegion().id}/${props?.slug || ""}`
                        }
                    />
                )}
            </LocationContext.Consumer>
        ))
    ),
    () => auth
);

const RedirectToDefaultRegion = withLogin(
    withRegion((props: RouteComponentProps) =>
        region.isLegacyRegion() ? (
            <Redirect to={`/${getLegacyRegion()}${props.location.pathname}`} />
        ) : (
            <Redirect to={`/${getLegacyRegion()}`} />
        )
    ),
    () => auth
);

// TODO use of BrowserRouter here prevents ConnectedRouter working correctly
// (store.router not updated) but is necessary for getUserConfirmation
const App = () => (
    <Router getUserConfirmation={getUserConfirmation}>
        <Switch>
            <Route path="/:region([a-z]{2,3})">
                <Switch>
                    <Route path="/:region" exact component={RedirectToDefaultLocation as any} />
                    <Route path="/:region/:location" component={protectedLocationRoutes} />
                </Switch>
            </Route>

            <Route path="/auth/callback" render={(props) => <CallbackPageContainer auth={auth} {...props} />} />

            <Route path="/" exact component={RedirectToDefaultLocation as any} />

            <Route render={RedirectToDefaultRegion} />
        </Switch>
    </Router>
);

export default App;
