import { opsApi } from "API";
import { trayChargeQueryFields } from "features/trayCharge/api/index";
import { GraphQLTrayChargeInputModel, GraphQLTrayChargeQueryModel } from "features/trayCharge/types";

export const saveTrayCharge = async (
    locationId: string,
    trayChargeId: string | undefined,
    inputModel: GraphQLTrayChargeInputModel
) => {
    const isCreate = trayChargeId === undefined || trayChargeId === "";

    const res = isCreate
        ? (
              await opsApi.graphQLQuery<{ createTrayCharge: GraphQLTrayChargeQueryModel }>(createQuery, {
                  locationId,
                  item: inputModel,
              })
          ).data.createTrayCharge
        : (
              await opsApi.graphQLQuery<{ updateTrayCharge: GraphQLTrayChargeQueryModel }>(updateQuery, {
                  locationId,
                  id: trayChargeId,
                  item: inputModel,
              })
          ).data.updateTrayCharge;

    if (!res) {
        throw `Something went wrong while ${isCreate ? "creating" : "updating"} tray charge ${trayChargeId}`;
    }

    return res;
};

const createQuery = `
    mutation ($locationId: ID!, $item: TrayChargeUpdateInput!) {
        createTrayCharge(locationId: $locationId, data: $item) {
            ${trayChargeQueryFields}
        }
    }
`;

const updateQuery = `
    mutation ($locationId: ID!, $id : ID!, $item: TrayChargeUpdateInput!) {
          updateTrayCharge(locationId: $locationId, id: $id, data: $item) {
            ${trayChargeQueryFields}
        }
    }
`;
