import { PaginationItem, PaginationOptions, usePagination } from "common/hooks";
import { AngleDown } from "common/icons";
import { useCallback, useMemo } from "react";
import { Button } from "../button";
import { Option, Select } from "../form/select";
import { Icon } from "../icon";
import styles from "./Pagination.module.scss";

interface CommonProps extends PaginationOptions {
    onChange: (page: number) => void;
    onPageSizeChange?: (page: number) => void;
}

type PageSizeProps =
    | { allowUserPageSize?: false; pageSizeOptions?: never }
    | { allowUserPageSize: true; pageSizeOptions?: PageSizeOption[] };

type Props = CommonProps & PageSizeProps;

interface ItemProps {
    item: PaginationItem;
    format: (n: any) => string;
    numPages: number;
    pageIndex: number;
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

interface PageSizeOption extends Option {
    value: number;
}

const defaultPageSizeOptions: PageSizeOption[] = [
    {
        label: "10 / page",
        value: 10,
    },
    {
        label: "25 / page",
        value: 25,
    },
    {
        label: "50 / page",
        value: 50,
    },
    {
        label: "100 / page",
        value: 100,
    },
];

export const Item = ({ item, format, numPages, pageIndex, onClick }: ItemProps) => {
    switch (item) {
        case "gapBefore":
            return (
                <Button
                    className={styles.gapBefore}
                    noBackground={true}
                    noBorder={true}
                    onClick={onClick}
                    padding="icon"
                    role="secondary"
                    type="button"
                    value={Math.max(pageIndex - 5, 1)}
                ></Button>
            );
        case "gapAfter":
            return (
                <Button
                    className={styles.gapAfter}
                    noBackground={true}
                    noBorder={true}
                    onClick={onClick}
                    padding="icon"
                    role="secondary"
                    type="button"
                    value={Math.min(pageIndex + 5, numPages)}
                ></Button>
            );
        case pageIndex:
            return (
                <>
                    <span className="sr-only">{`Current page is ${pageIndex}`}</span>
                    <Button aria-hidden className={styles.currentPage} as="span" role="secondary" padding="icon">
                        {format(pageIndex)}
                    </Button>
                </>
            );
    }

    return (
        <Button
            className={styles.button}
            role="secondary"
            type="button"
            onClick={onClick}
            padding="icon"
            value={item}
            aria-label={`Page ${item}`}
        >
            {format(item)}
        </Button>
    );
};

export const Pagination = ({
    allowUserPageSize = false,
    buffer,
    count,
    onChange,
    onPageSizeChange,
    pageIndex,
    pageSize,
    pageSizeOptions,
}: Props) => {
    const { prevEnabled, items, nextEnabled, numPages, start, end } = usePagination({
        pageSize,
        pageIndex,
        buffer,
        count,
    });

    const handlePageClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            const page = parseInt(e.currentTarget.value);
            onChange(page);
        },
        [onChange]
    );

    const handlePrevious = useCallback(() => {
        onChange(Math.max(pageIndex - 1, 1));
    }, [onChange, pageIndex]);

    const handleNext = useCallback(() => {
        onChange(Math.min(pageIndex + 1, numPages));
    }, [numPages, onChange, pageIndex]);

    // TODO support non english locales when needed
    const numberFormat = useMemo(() => new Intl.NumberFormat("en-AU"), []);

    if (count === 0) {
        return null;
    }

    return (
        <nav className={styles.root} aria-label="results table">
            <div className={styles.meta}>
                <span role="text">
                    <span>Showing</span>
                    <span className="sr-only">results </span>
                    <span> {numberFormat.format(start)} —</span>
                    <span className="sr-only">to </span>
                    <span>
                        {" "}
                        {numberFormat.format(end)} of {numberFormat.format(count)}
                    </span>
                </span>
            </div>
            {numPages > 1 && (
                <div className={styles.buttons}>
                    <Button
                        className={prevEnabled ? styles.button : styles.buttonDisabled}
                        role="secondary"
                        type="button"
                        padding="icon"
                        onClick={handlePrevious}
                        disabled={!prevEnabled}
                        aria-disabled={!prevEnabled}
                    >
                        <Icon size="small" className={styles.iconPrev}>
                            <AngleDown title="Previous Page" />
                        </Icon>
                    </Button>
                    {items.map((item) => (
                        <Item
                            key={item}
                            item={item}
                            pageIndex={pageIndex}
                            onClick={handlePageClick}
                            numPages={numPages}
                            format={numberFormat.format}
                        />
                    ))}
                    <Button
                        className={nextEnabled ? styles.button : styles.buttonDisabled}
                        role="secondary"
                        type="button"
                        padding="icon"
                        onClick={handleNext}
                        disabled={!nextEnabled}
                        aria-disabled={!nextEnabled}
                    >
                        <Icon size="small" className={styles.iconNext}>
                            <AngleDown title="Next page" />
                        </Icon>
                    </Button>
                </div>
            )}
            <div className={styles.after}>
                {allowUserPageSize && (
                    <div className={styles.selectContainer}>
                        <Select
                            menuPlacement="top"
                            menuPosition="absolute"
                            options={pageSizeOptions || defaultPageSizeOptions}
                            value={(pageSizeOptions || defaultPageSizeOptions).find(({ value }) => value === pageSize)}
                            onChange={(option: any) => {
                                onPageSizeChange?.(option?.value);
                            }}
                        />
                    </div>
                )}
            </div>
        </nav>
    );
};
