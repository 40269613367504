import { createAction } from "../reducers/list";
import { listPackages } from "../api/listPackages";
import { AppDispatch, AppState } from "features";
import { getLocationPermissions } from "features/location/selectors/getLocationPermissions";

export const list = (location: string) => async (dispatch: AppDispatch, getState: () => AppState) => {
    const state = getState();
    const permissions = getLocationPermissions(state);

    if (!permissions.has("packages:read")) {
        dispatch(createAction.loaded([]));
        return;
    }

    try {
        dispatch(createAction.loading());
        var packages = await listPackages(location);
        dispatch(createAction.loaded(packages));
    } catch (e) {
        dispatch(createAction.loadFailed(e));
    }
};
