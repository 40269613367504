import { AppState } from "features/state";
import { connect } from "react-redux";
import { Nav, Props } from "./Nav";
import { withRouter } from "react-router";
import { getMainMenu } from "features/structure/selectors/getMainMenu";
import { getIsPosMenuSupported } from "features/location/selectors/getPosMenuSupported";
import { NavNode } from "features/structure/types/NavMenu";

function mapStateFromProps(state: AppState): Props {
    return {
        navigation: getMainMenu(state) as NavNode[],
        posMenuSupported: getIsPosMenuSupported(state),
    };
}

export const NavContainer = withRouter(connect(mapStateFromProps)(Nav));
