import { connect } from "react-redux";
import { Props, ListSectionsPage } from "../components/ListSectionsPage";
import * as actions from "../actions";
import { RouteComponentProps } from "react-router";
import { withLoader, PageLoadingProps } from "common/loader/withLoader";
import { AppState } from "features";
import { getCrudPermissions } from "features/location/selectors/getCrudPermissions";
import { DataProps, FunctionProps } from "types/utils";
import { LocationRouteParams } from "common/types";

const mapStateToProps = (
    state: AppState,
    { match: { params } }: RouteComponentProps<LocationRouteParams>
): DataProps<PageLoadingProps<Props>> => {
    const {
        sections: { list },
    } = state;

    if (list.status === "loaded") {
        return {
            loadStatus: list.status,
            items: list.data,
            permissions: getCrudPermissions(state).section,
        };
    }

    return {
        loadStatus: list.status,
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { match: { params } }: RouteComponentProps<LocationRouteParams>
): FunctionProps<PageLoadingProps<Props>> => ({
    fetch: () => dispatch(actions.list(params.location)),
});

export const ListSectionsPageContainer = connect(mapStateToProps, mapDispatchToProps)(withLoader(ListSectionsPage));
