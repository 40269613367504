import { opsApi } from "API";

export const editHyperwalletUser = async (locationId: string): Promise<GraphQLHyperwalletUser> => {
    try {
        const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId });
        return res.data.hyperwalletUser || newHyperwalletUser();
    } catch (e) {
        console.log(e);
        throw e;
    }
};

const newHyperwalletUser = (): GraphQLHyperwalletUser => ({
    isNew: true,
});

type GraphQLPayload = { hyperwalletUser: GraphQLHyperwalletUser };

export interface GraphQLHyperwalletUser {
    businessName?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    isNew: boolean;
}

const query = `
    query($locationId: ID!) {
        hyperwalletUser(locationId: $locationId) {
            businessName,
            firstName,
            lastName,
            email,
            phoneNumber
        }
    }
`;
