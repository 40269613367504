import * as Yup from "yup";
import { CompanyDetails } from "../types/CompanyDetails";
import { EditableLocation } from "../types/EditableLocation";

export const companyDetailsSchema = Yup.object<CompanyDetails>().shape({
    registeredName: Yup.string().nullable(true).required("Required"),
    abn: Yup.string().nullable(true).required("Required"),
    address: Yup.string().nullable(true).required("Required"),
    privacyPolicyUrl: Yup.string().url("Must be a valid URL beginning with http or https").nullable(true),
});

export const editableLocationSchema = Yup.object<EditableLocation>().shape({
    displayName: Yup.string(),
    id: Yup.string(),
    lastPublished: Yup.string().nullable(true),
    lastPublishedUpdate: Yup.string().nullable(true),
    slug: Yup.string(),
    companyDetails: companyDetailsSchema,
    billingSystemId: Yup.string().when(
        "$requiresBillingSystem",
        (requiresBillingSystem: boolean, schema: Yup.StringSchema) =>
            requiresBillingSystem ? schema.nullable(true).required("Required") : schema.nullable(true)
    ),
    stagedBillingSystemId: Yup.string().nullable(true).notRequired(),
    isDemo: Yup.boolean(),
    isSalesMode: Yup.boolean(),
    timeZone: Yup.string().required("Required"),
    locale: Yup.string().required("Required"),
    isMarketingEnabled: Yup.boolean(),
    stripeCommission: Yup.number().when(
        "$requiresStripeCommission",
        (requiresStripeCommission: boolean, schema: Yup.NumberSchema) =>
            requiresStripeCommission
                ? schema.min(0).max(99.99, "Max commission is 99.99%").required("Required")
                : schema.nullable(true)
    ),
});
