import styles from "./EditStatementDescriptors.module.scss";

import { Alert, AlertStatus } from "core/components/alert";
import { Card, Row, Title } from "core/components/card";
import { CardWidth, Confirm, Modal, ModalRenderer } from "core/components/modal";
import { CharacterCount } from "core/components/form/input/CharacterCount";
import { createAction } from "../reducers/update";
import { CrudActionFooter } from "core/components/actionFooter";
import { FastField, Form, Formik } from "formik";
import { FieldErrors } from "core/components/form/fieldErrors";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { getUpdate } from "../selectors/getUpdate";
import { Input } from "core/components/form/input";
import { ScrollToFormikError } from "components/forms/ScrollToFormikError";
import { useSnackbarContext } from "core/components/snackbar/SnackbarContext";
import { StatementDescriptors } from "../types/StatementDescriptors";
import { statementDescriptorsSchema } from "../schema/StatementDescriptorsSchema";
import { Status } from "core/components/snackbar/types/Message";
import { StripeAccount, UpdateResult } from "../types";
import { updateStatementDescriptors } from "../actions";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePrevious } from "common/hooks";

interface Props {
    enabled: boolean;
    onUpdated: (result: UpdateResult) => void;
    stripeAccount: StripeAccount | undefined;
}

export const EditStatementDescriptors = ({ enabled, stripeAccount, onUpdated }: Props) => {
    const dispatch = useDispatch();

    const activeLocation = useSelector(getActiveLocation);

    const updateState = useSelector(getUpdate);

    const updateStatus = updateState.status;

    const prevUpdateStatus = usePrevious(updateStatus);

    const { addMessage } = useSnackbarContext();

    const [showFailedModal, setShowFailedModal] = useState(false);

    const handleSubmit = (data: StatementDescriptors) => {
        if (!activeLocation?.id || !stripeAccount?.accountId) {
            return;
        }
        dispatch(updateStatementDescriptors(activeLocation.id, data));
    };

    useEffect(() => {
        if (updateState.status && updateState.status !== prevUpdateStatus) {
            if (updateState.status === "loaded") {
                addMessage({ status: Status.SUCCESS, content: "Updated successfully" });
                onUpdated(updateState.data);
                dispatch(createAction.reset());
            }

            if (updateState.status === "failed") {
                setShowFailedModal(true);
            }
        }
    }, [dispatch, addMessage, onUpdated, prevUpdateStatus, updateState]);

    useEffect(
        () => () => {
            dispatch(createAction.reset());
        },
        [dispatch]
    );

    const initialValues: StatementDescriptors = useMemo(() => {
        return {
            paymentStatementDescriptor: stripeAccount?.paymentStatementDescriptor || "",
            payoutStatementDescriptor: stripeAccount?.payoutStatementDescriptor || "",
        };
    }, [stripeAccount]);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={statementDescriptorsSchema}
        >
            {(form) => (
                <Form className={styles.form}>
                    <ScrollToFormikError />
                    <Card>
                        <Row collapse="down">
                            <Title title="Statement descriptors" />
                        </Row>
                        <Row collapse="down" className={styles.fieldInfo}>
                            Change how customers see me&u payments on their statements, and how your payouts from me&u
                            appear.
                        </Row>
                        <Row collapse={enabled ? "down" : undefined}>
                            <Alert status={AlertStatus.INFO}>
                                {enabled ? (
                                    <>
                                        Any descriptor changes will affect all locations that share a Stripe Connect
                                        account.
                                    </>
                                ) : (
                                    <>
                                        You can configure statement descriptors after your charge information has been
                                        completed.
                                    </>
                                )}
                            </Alert>
                        </Row>
                        {enabled && (
                            <>
                                <Row collapse="down">
                                    <FieldErrors fieldNames={["payoutStatementDescriptor"]}>
                                        <FastField
                                            name="payoutStatementDescriptor"
                                            markRequired={true}
                                            component={Input}
                                            label="Payout descriptor"
                                            autoComplete="off"
                                            after={() => (
                                                <CharacterCount fieldName="payoutStatementDescriptor" max={9} />
                                            )}
                                        />
                                    </FieldErrors>
                                </Row>
                                <Row collapse="both" className={styles.fieldInfoRow}>
                                    Configure how you want your payments from me&u to appear on your statements.
                                </Row>
                                <Row collapse="down">
                                    <FieldErrors fieldNames={["paymentStatementDescriptor"]}>
                                        <FastField
                                            name="paymentStatementDescriptor"
                                            markRequired={true}
                                            component={Input}
                                            label="Payment descriptor"
                                            autoComplete="off"
                                            after={() => (
                                                <CharacterCount fieldName="paymentStatementDescriptor" max={16} />
                                            )}
                                        />
                                    </FieldErrors>
                                </Row>
                                <Row collapse="up" className={styles.fieldInfoRow}>
                                    Configure what customers see on their card statements. We suggest using the name of
                                    your venue.
                                </Row>
                                <CrudActionFooter
                                    showDelete={false}
                                    showDuplicate={false}
                                    saving={updateStatus === "loading"}
                                    submitMode="save"
                                    saveProps={{ disabled: !form.dirty }}
                                />
                            </>
                        )}
                    </Card>
                    <ModalRenderer target="#modal">
                        <Modal
                            cardClassName={styles.card}
                            contentContainerClassName={styles.contentContainer}
                            footer={<Confirm confirmLabel="Try again" onConfirm={() => handleSubmit(form.values)} />}
                            onClose={() => setShowFailedModal(false)}
                            title={`Unable to connect to Stripe`}
                            visible={showFailedModal}
                            width={CardWidth.NARROW}
                        >
                            <Row>
                                We were unable to connect to Stripe and your changes were not saved. If you continue to
                                encounter this issue please contact us.
                            </Row>
                        </Modal>
                    </ModalRenderer>
                </Form>
            )}
        </Formik>
    );
};
