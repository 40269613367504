import { opsApi } from "API";
import { UploadEndpoint } from "API/upload";

export type BulkUploadType = "modifier" | "product";

const createBulkUploadEndpointQuery = `
  mutation ($locationId: ID!, $filename: String!) {
    createBulkUploadEndpoint(locationId: $locationId, filename: $filename) {
        uploadUrl,
        uploadMethod,
        path
    }
  }
`;

export async function createBulkUploadEndpoint(file: File, locationId: string) {
    const variables = {
        locationId,
        filename: file.name,
    };

    const result = await opsApi.graphQLQuery<{ createBulkUploadEndpoint: UploadEndpoint }>(
        createBulkUploadEndpointQuery,
        variables
    );

    return result.data.createBulkUploadEndpoint;
}
