import { opsApi } from "../../../API";
import { GraphQLItem } from "../../../common/scaffolding/types";

export const listMenus = async (location: string) => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { location });
    return res.data.menus;
};

type GraphQLPayload = { menus: GraphQLMenuSummary[] };

export interface GraphQLMenuSummary extends GraphQLItem {
    services: GraphQLItem[];
}

const query = `
    query($location: ID!) { 
        menus(locationId: $location) { 
            id, 
            internalName, 
            displayName, 
            services { 
                id, 
                internalName, 
                displayName 
            } 
        }
    }
`;
