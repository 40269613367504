import { normaliseText } from "common/utility/StringUtils";

interface Props {
    displayName: string;
    internalName?: string;
    searchTerm?: string;
}

const highlightSearchTerm = (source: string, searchTerm: string) => {
    const normalisedString = normaliseText(source);
    const index = normalisedString.indexOf(searchTerm);
    const beforeStr = source.substr(0, index);
    const afterStr = source.substr(index + searchTerm.length);
    return index > -1 ? (
        <>
            {beforeStr}
            <span className="site-tree-search-value">{source.substr(index, searchTerm.length)}</span>
            {afterStr}
        </>
    ) : (
        <span className="raw-title">{source}</span>
    );
};

export const Title = ({ displayName, internalName, searchTerm }: Props): JSX.Element => {
    const titlePrimary = searchTerm ? highlightSearchTerm(displayName, searchTerm) : displayName;
    const titleSecondary = searchTerm && internalName ? highlightSearchTerm(internalName, searchTerm) : internalName;

    return (
        <>
            {internalName ? (
                <>
                    {titlePrimary} <i>({titleSecondary})</i>
                </>
            ) : (
                <>{titlePrimary}</>
            )}
        </>
    );
};
