import { AppDispatch, AppState } from "features/state";
import { priorityServiceCalendar as priorityServiceCalendarApi } from "../api/serviceCalendar";
import { ServiceCalendarRequestDataInput } from "../types/ServiceCalendarRequestDataInput";
import { createAction } from "../reducers/calendar";

export const priorityServiceCalendar =
    (location: string, range: ServiceCalendarRequestDataInput) =>
    async (dispatch: AppDispatch, getState: () => AppState) => {
        if (getState().services.calendar.status === "loading") {
            return;
        }

        dispatch(createAction.loading());

        try {
            const result = await priorityServiceCalendarApi(location, range);
            dispatch(createAction.loaded(range, result));
        } catch (e: any) {
            dispatch(createAction.failed(e));
        }
    };
