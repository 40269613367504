import { createAction } from "../reducers/list";
import { normalizeSummaryItems } from "foundation/dataConventions/normalizeSummaryItems";
import { scaffoldListAction } from "../../../common/scaffolding/actions/scaffoldListAction";
import { CourseSummary } from "../types";
import { listCourses } from "../api/listCourses";

export const list = (location: string) =>
    scaffoldListAction<CourseSummary>(
        (state) => state.courses.list,
        createAction,
        async () => {
            const courses = await listCourses(location);
            return normalizeSummaryItems(courses);
        }
    );
