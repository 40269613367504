import { CmsItemSummary, GraphQLItem } from "common/scaffolding/types";

export interface TaxClassSummary extends GraphQLItem, CmsItemSummary {
    displayName: string;
    taxType: string;
    taxRate: number | undefined;
    posTaxId?: string | null;
}

export interface TaxClassAssignment {
    targetType: string;
    targetId: string;
    parentId?: string;
}

export interface EditableTaxClass extends TaxClassSummary {
    applyToAll: boolean;
    taxAssignments: TaxClassAssignment[];
    exclusive: boolean;
}

export interface TaxClassUpdateInput extends EditableTaxClass {}

export enum TaxType {
    PERCENTAGE_PER_ITEM = "PERCENTAGE_PER_ITEM",
}

export const TaxTypeLabel = {
    [TaxType.PERCENTAGE_PER_ITEM]: "Percentage per item",
};
