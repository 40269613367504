import { opsApi } from "API";
import { Stats } from "../types";
export const getStats = async (locationId: string) => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId });
    return res.data.locationGroupTabStats;
};

type GraphQLPayload = { stats: Stats };

const query = `
    query ($locationId: ID!) {
        locationGroupTabStats(locationId: $locationId) {
            averageTabMembers
            averageTabLimit
            totalSpend
            dateUpdated
        }
    }
`;
