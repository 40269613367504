import { opsApi } from "API";
import { EditableGroup } from "features/group/types/EditableGroup";
import { AlcoholicDrinksRestriction } from "features/location/types/AlcoholicDrinksRestriction";
import { GraphQLItemId } from "common/scaffolding/types";
import { MarketingOptions } from "../types/MarketingOptions";
import { activeLocationFields } from "../../location/api/fetchLocation";
import { TipOptions } from "../types";
import { OrderFlowType } from "../types/OrderFlow";
import { SplitPaymentOption } from "../types/SplitPaymentOptions";

export const saveLocationSettings = async (
    locationId: string | undefined,
    locationSettingsUpdate: LocationSettingsUpdateModel
) => {
    const res = await opsApi.graphQLQuery<{ updateLocationSettings: GraphQLItemId }>(updateQuery, {
        locationId,
        locationSettingsUpdate,
    });
    return res.data.updateLocationSettings;
};

export interface LocationSettingsUpdateModel {
    ageRestriction?: number;
    allowNotesToKitchen?: boolean;
    allowTips?: boolean;
    confirmEighteenPlus?: boolean;
    defaultTipLevel?: number; // deprecated
    enableAnotherRound: boolean;
    enableGroupTabs: boolean;
    enableQuickSell: boolean;
    quickSellDrinksOnly: boolean;
    enableSurcharges: boolean;
    facebookPixelId?: string;
    fromTheVenue?: string;
    fromTheVenueTitle?: string;
    group?: EditableGroup;
    idleTimeoutMinutes: number;
    isBuzzer?: boolean;
    isLiveOrdersEnabled: boolean;
    marketingOptions?: MarketingOptions;
    mustAcceptAllergenNotice?: boolean;
    orderBatchTimeSeconds?: number;
    drinkOrderBatchTimeSeconds?: number;
    foodOrderBatchTimeSeconds?: number;
    sectionsWithDocketMerging?: string[];
    tipOptions: TipOptions;
    unusedPartyTimeoutMinutes: number;
    groupTabGratuityFactor?: number;
    enablePosSync?: boolean;
    alcoholicDrinksRestriction?: AlcoholicDrinksRestriction;
    enableProductVideos: boolean;
    enableAbsorbMerchantFee: boolean;
    orderFlow: OrderFlowType;
    splitPaymentOptions: SplitPaymentOption[];
}

const updateQuery = `
    mutation($locationId: ID!, $locationSettingsUpdate: LocationSettingsUpdateInput!) {
        updateLocationSettings(locationId: $locationId, locationSettings: $locationSettingsUpdate) {
            ${activeLocationFields}
        }
    }
`;
