import styles from "./EditModifierPage.module.scss";
import modalStyles from "features/catalogue/components/ModalContent.module.scss";

import { ReactElement, useCallback, useMemo, useState } from "react";
import { Formik, Field, FieldArray, FormikProps, getIn, validateYupSchema, yupToFormErrors, FastField } from "formik";
import {
    EditableModifier,
    EditableModifierOption,
    EditableModifierSubmission,
    ModifySelectionOptions,
} from "features/modifier/types";
import { editableModifierSchema } from "features/modifier/schemas";
import { EditModifierOptions } from "features/modifier/components/EditModifierPage/EditModifierOptions";
import { Alert, Form, Spin } from "antd";
import { ProductSummary } from "features/catalogue/types";
import { SortableTransfer } from "components/forms/SortableTransfer";
import { Status } from "features/catalogue/types/StatusDictionary";
import { SelectRecommended } from "components/forms/SelectRecommended";
import { config } from "common/config";
import { CrudActionFooter, SubmitMode } from "core/components/actionFooter/CrudActionFooter";
import { ScrollToFormikError } from "components/forms/ScrollToFormikError";
import { buildInputRegex } from "core/components/form/InputNumber";
import { PageHeader } from "core/components/pageHeader";
import { useIsNewRoute, useLoadStatus } from "common/hooks";
import { Card, Title, Row } from "core/components/card";
import { Button } from "core/components/button";
import { Input } from "core/components/form/input";
import { Label } from "core/components/form/input/Label";
import { FieldErrors } from "core/components/form/fieldErrors";
import { ModifierSelectionRules } from "./ModifierSelectionRules";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "features";
import { getIsEditingNestedModifier } from "features/catalogue/selectors/getIsEditingNestedModifier";
import { Warning } from "core/components/iconWithText/Warning";
import { RadioGroup } from "core/components/form/radio";
import { initialiseModifierRules } from "features/modifier/ModifierRulesHelper";
import { parseNumberString } from "core/utility/input/parsers";
import { Switch } from "core/components/form/switch";
import { EditModifierProducts } from "./EditModifierProducts";
import { useHistory, useParams } from "react-router";
import { ModifierAlerts } from "./ModifierAlerts";
import { getEnableEnhancedModifierEdit } from "../../selectors/getEnableEnhancedModifierEdit";
import { ItemModifierInputProductModel } from "features/modifier/api/saveModifier";
import { Badge } from "core/components/badge";
import { CardWidth, Confirm, Modal, ModalRenderer } from "core/components/modal";
import { Prompt } from "core/components/modal/Prompt";
import { getIsParentLocation } from "features/location/selectors/getIsParentLocation";
import { getIsChildLocation } from "features/location/selectors/getIsChildLocation";
import { CatalogueItemNotFound } from "features/catalogue/components/CatalogueItemNotFound";
import { isLoaded } from "common/loader/isLoaded";
import * as categoryActions from "features/category/actions";
import * as modifierActions from "features/modifier/actions";
import { actions as catalogueActions } from "features/catalogue";
import { getCrudPermissions } from "features/location/selectors/getCrudPermissions";
import { getIsEnergyContentSupported } from "features/location/selectors/getEnergyContentSupported";
import { getEditModifier } from "features/catalogue/selectors/getEditModifier";
import { PageContainer } from "core/components/pageContainer/PageContainer";
import { CardsContainer } from "core/components/card/CardsContainer";
export interface State {
    submitMode: SubmitMode;
}
interface Props {
    onClose: () => void;
}

let tempOptionId: number = 0;

interface RouteParams {
    id: string;
    location: string;
    region: string;
    type: string;
}

export const EditModifierPage = ({ onClose }: Props): ReactElement => {
    const [submitMode, setSubmitMode] = useState<SubmitMode>("save");
    const isNewRoute = useIsNewRoute();
    const isNew = useIsNewRoute();
    const energyContentSupported = useSelector(getIsEnergyContentSupported);
    const isNested = useSelector<AppState, boolean>(getIsEditingNestedModifier);
    const permissions = useSelector(getCrudPermissions).product;
    const initialValues = useSelector(getEditModifier);
    const history = useHistory();

    const { location, id, region } = useParams<RouteParams>();

    const enableEnhancedModifierEdit = useSelector(getEnableEnhancedModifierEdit);
    const isChildLocation = useSelector(getIsChildLocation) || false;
    const isParentLocation = useSelector(getIsParentLocation) || false;
    const upsellTypes = config.REACT_APP_ENABLE_UPSELL_TYPES === "1";

    const [showDuplicateConfirm, setShowDuplicateConfirm] = useState(false);

    const disableLinkedFields = (isChildLocation && initialValues?.isLinked) || false;

    const links = useMemo(
        () => ({
            priceLists: `/${region}/${location}/menu/pricelists`,
        }),
        [region, location]
    );

    const dispatch = useDispatch();
    const categoryList = useSelector((state: AppState) => state.categories.list);
    const edit = useSelector((state: AppState) => state.modifiers.edit);
    const modifiersList = useSelector((state: AppState) => state.modifiers.list);
    const menuItemsList = useSelector((state: AppState) => state.menuItems.list);

    const fetch = useCallback(() => {
        !isLoaded(categoryList) && dispatch(categoryActions.list(location));
        !isLoaded(modifiersList) && dispatch(modifierActions.list(location));
        !isLoaded(menuItemsList) && dispatch(catalogueActions.listProducts(location));
        dispatch(modifierActions.edit(location, id));
    }, [categoryList, dispatch, id, location, modifiersList, menuItemsList]);

    const fetchStatus = useLoadStatus(
        [edit.status, categoryList.status, modifiersList.status, menuItemsList.status],
        fetch,
        {
            refetchOnMount: true,
        }
    );

    const products = useMemo(() => (menuItemsList.status === "loaded" ? menuItemsList.data : []), [menuItemsList]);
    const saveStatus = isLoaded(edit) && edit.saveStatus;
    const handleBlockTransition = useCallback(() => saveStatus !== "saved", [saveStatus]);

    const onArchive = useCallback(() => {
        dispatch(catalogueActions.archiveModifier(location, region, history));
    }, [dispatch, history, location, region]);

    const onSave = useCallback(
        (data: EditableModifierSubmission) => {
            dispatch(catalogueActions.saveModifier(location, region, data, onClose, false, history));
        },
        [dispatch, location, region, onClose, history]
    );

    const handleSubmit = useCallback(
        (data: EditableModifier) => {
            setSubmitMode("save");
            onSave(
                mapEditableModifierToSubmission(data, products, enableEnhancedModifierEdit, {
                    isClone: false,
                    isChildLocation,
                })
            );
        },
        [enableEnhancedModifierEdit, isChildLocation, products, onSave]
    );

    const handleDelete = useCallback(() => {
        setSubmitMode("archive");
        onArchive?.();
    }, [onArchive]);

    const onClone = useCallback(
        (data: EditableModifierSubmission) => {
            dispatch(catalogueActions.saveModifier(location, region, data, onClose, true, history));
        },
        [dispatch, location, region, onClose, history]
    );

    const handleDuplicate = useCallback(
        (data: EditableModifier) => {
            if (disableLinkedFields) {
                setShowDuplicateConfirm(true);
                return;
            }

            setSubmitMode("clone");
            onClone(
                mapEditableModifierToSubmission(data, products, enableEnhancedModifierEdit, {
                    isClone: true,
                    isChildLocation,
                })
            );
        },
        [disableLinkedFields, enableEnhancedModifierEdit, isChildLocation, products, onClone]
    );

    const handleDirtyFormCheck = useCallback(
        (form: FormikProps<EditableModifier>) => {
            if (!initialValues) {
                return;
            }
            if (
                initialValues.id !== "new" &&
                initialValues.status !== Status.MissingContent &&
                onClose &&
                !form.dirty
            ) {
                onClose();
                return;
            }
            form.handleSubmit();
        },
        [initialValues, onClose]
    );

    const validate = useCallback(
        (value: EditableModifier) => {
            return validateYupSchema(value, editableModifierSchema, false, { upsellTypes }).then(
                () => ({}),
                (err: any) => yupToFormErrors(err)
            );
        },
        [upsellTypes]
    );

    if (fetchStatus === "failed") {
        return <CatalogueItemNotFound collectionName="modifier" />;
    }

    if (!initialValues) {
        return (
            <div className="loader__container loader__container--overlay">
                <Spin />
            </div>
        );
    }

    // format prices in initial values to x.xx
    initialValues.options = initialValues.options.map((option) => ({
        ...option,
        price: Number(option.price || 0).toFixed(2),
        sku: option.sku || "",
        energyContent: !!option.energyContent ? option.energyContent : "",
    }));

    if (isNewRoute && !initialValues.options?.length) {
        initialValues.options.push({
            displayName: "",
            sku: "",
            price: undefined,
            taxRate: null,
            id: `${++tempOptionId}`,
            isNew: true,
            energyContent: "",
            isLinked: false,
        });
    }

    const modifierExtendedRules = initialiseModifierRules({
        selectOption: ModifySelectionOptions.Unlimited,
        minSelection: Number(initialValues.minSelection || 0),
        maxSelection: Number(initialValues.maxSelection || 0),
        maxSelectionPerOption: Number(initialValues.maxSelectionPerOption || 0),
        required: initialValues.required,
    });

    initialValues.minSelection = `${modifierExtendedRules.minSelection || ""}`;
    initialValues.maxSelection = `${modifierExtendedRules.maxSelection || ""}`;
    initialValues.maxSelectionPerOption = `${modifierExtendedRules.maxSelectionPerOption || ""}`;
    initialValues.minMaxSelect = modifierExtendedRules.selectOption;

    initialValues.internalName = initialValues.internalName || "";
    initialValues.maxSelection = `${initialValues.maxSelection || ""}`;
    initialValues.isLinked = isChildLocation && initialValues.isLinked;

    const isUpdate = !isNew;

    const disableFields = (isUpdate && permissions && !permissions.canUpdate) || false;

    const templateTypeOptions = [
        { text: "Food", value: "food" },
        { text: "Non-alcoholic drink", value: "drink" },
        { text: "Alcoholic drink", value: "alcoholic-drink" },
    ];

    return (
        <PageContainer>
            <PageHeader
                title={`${isNewRoute ? "Create" : "Edit"} Modifier Group`}
                titleAfter={initialValues.isLinked ? <Badge backgroundColorScheme="mid">Brand</Badge> : undefined}
                backHandler={onClose}
            />
            <Formik
                onSubmit={handleSubmit}
                validationSchema={editableModifierSchema}
                initialValues={initialValues}
                key={initialValues.id}
                validate={validate}
            >
                {(form) => (
                    <>
                        {enableEnhancedModifierEdit && <ModifierAlerts priceListsLink={links.priceLists} />}
                        <CardsContainer
                            as={Form}
                            onFinish={() => {
                                handleDirtyFormCheck(form);
                            }}
                            className={styles.form}
                        >
                            <ScrollToFormikError />

                            <Card>
                                <Row collapse="down">
                                    <Title title="General information" />
                                </Row>
                                <Row collapse="down">
                                    <FieldErrors fieldNames={["displayName"]}>
                                        <Field
                                            name="displayName"
                                            component={Input}
                                            label="Modifier Group name"
                                            placeholder="Modifier Group Name"
                                            disabled={disableFields || disableLinkedFields}
                                            markRequired
                                        />
                                    </FieldErrors>
                                </Row>
                                <Row>
                                    <FieldErrors fieldNames={["internalName"]}>
                                        <Field
                                            name="internalName"
                                            component={Input}
                                            label="Internal name"
                                            placeholder="Internal Name (optional)"
                                            disabled={disableFields}
                                        />
                                    </FieldErrors>
                                </Row>
                            </Card>

                            <Card>
                                <Row collapse="down">
                                    <Title title="Modifiers" />
                                    <Button
                                        disabled={disableFields || disableLinkedFields}
                                        colorScheme="interactive"
                                        type="button"
                                        onClick={() => {
                                            form.setFieldValue("options", [
                                                ...form.values.options,
                                                {
                                                    displayName: "",
                                                    sku: "",
                                                    price: "0.00",
                                                    taxRate: null,
                                                    id: `${++tempOptionId}`,
                                                    isNew: true,
                                                },
                                            ]);
                                        }}
                                    >
                                        Add option
                                    </Button>
                                </Row>
                                {isNested && (
                                    <Row collapse="down">
                                        <div className={styles.errorContainer}>
                                            <Warning>
                                                Nested modifiers are disabled for this Modifier Group as it’s already
                                                nested to another.
                                            </Warning>
                                        </div>
                                    </Row>
                                )}
                                {form.errors.options && !Array.isArray(form.errors.options) && (
                                    <Row>
                                        <FieldErrors fieldNames={["options"]} />
                                    </Row>
                                )}
                                <FieldArray
                                    name="options"
                                    render={(props) => (
                                        <EditModifierOptions
                                            {...props}
                                            disabled={disableFields}
                                            disableLinkedFields={disableLinkedFields}
                                            isNested={isNested}
                                            energyContentSupported={energyContentSupported}
                                        />
                                    )}
                                />
                            </Card>

                            <Card>
                                <Row collapse="down">
                                    <Title title="Rules" />
                                </Row>
                                <>
                                    <Row border="bottom">
                                        <ModifierSelectionRules
                                            disabled={disableFields || disableLinkedFields}
                                            initialValues={form.initialValues}
                                            errorFieldNames={getMinMaxErrorFields(form)}
                                        />
                                    </Row>

                                    <Row>
                                        <div className={styles.inlineFieldsContainer}>
                                            <span>Each modifier can be selected up to</span>
                                            <span className={styles.tinyInlineField}>
                                                <Field
                                                    name="maxSelectionPerOption"
                                                    component={Input}
                                                    disabled={disableFields || disableLinkedFields}
                                                    parseValue={parseNumberString}
                                                    inputTest={buildInputRegex({
                                                        numDigits: 2,
                                                    })}
                                                    pattern="\d*"
                                                />
                                            </span>
                                            <span>time(s)</span>
                                            <FieldErrors fieldNames={["maxSelectionPerOption"]} />
                                        </div>
                                    </Row>
                                </>
                            </Card>
                            {form.values?.options?.length > 1 && (
                                <Card>
                                    <Row collapse="down">
                                        <Title title="Recommendations" />
                                    </Row>
                                    <Row>
                                        <div className={styles.selectRow}>
                                            <div>Recommend a modifier option to your guests.</div>
                                            <div className={styles.selectContainer}>
                                                <SelectRecommended<EditableModifier, EditableModifierOption>
                                                    fieldName="options"
                                                    placeholder="Select a modifier"
                                                    displayNamePlaceholder="Unnamed Modifier"
                                                    disabled={disableFields || disableLinkedFields}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                </Card>
                            )}
                            <Card>
                                <Row collapse="down">
                                    <Title title="Smart sort" />
                                    <div className="ml-4">
                                        <Field
                                            name="smartSorting"
                                            type="checkbox"
                                            component={Switch}
                                            disabled={disableFields || disableLinkedFields}
                                        />
                                    </div>
                                </Row>
                                <Row>
                                    <div>
                                        Automatically sort your options by best performance. This will override your
                                        existing sorting if enabled.
                                    </div>
                                </Row>
                            </Card>
                            <Card>
                                {!upsellTypes && (
                                    <>
                                        <Row collapse="down">
                                            <Title title="Printing options" />
                                            <div className="ml-4">
                                                <Field
                                                    name="upsell"
                                                    type="checkbox"
                                                    component={Switch}
                                                    disabled={disableFields || disableLinkedFields}
                                                />
                                            </div>
                                        </Row>
                                        <Row>
                                            <div>Print modifiers as if they are products.</div>
                                        </Row>
                                    </>
                                )}
                                {upsellTypes && (
                                    <>
                                        <Row collapse="down">
                                            <Title title="Print as Products" />
                                            <div className="ml-4">
                                                <Field
                                                    name="upsell"
                                                    type="checkbox"
                                                    component={Switch}
                                                    disabled={disableFields || disableLinkedFields}
                                                />
                                            </div>
                                        </Row>
                                        <Row>
                                            <div>Print modifiers on production dockets, as if they were products.</div>
                                        </Row>
                                        {form.values.upsell && (
                                            <Row border="top">
                                                <div>
                                                    <Label
                                                        label="Select a Modifier Group type"
                                                        markRequired
                                                        className="mb-2"
                                                    />
                                                    <Field
                                                        name="type"
                                                        component={RadioGroup}
                                                        options={templateTypeOptions}
                                                        disabled={disableFields || disableLinkedFields}
                                                    />
                                                    <FieldErrors fieldNames={["type"]} />
                                                    <div className="mt-4">
                                                        Select the type that most accurately represents this Modifier
                                                        Group. This is used for surcharges, food & drinks order holding
                                                        times and your sales reports.
                                                    </div>
                                                </div>
                                            </Row>
                                        )}
                                    </>
                                )}
                            </Card>

                            {products && enableEnhancedModifierEdit && (
                                <EditModifierProducts disableFields={disableFields || disableLinkedFields} />
                            )}

                            {products && !enableEnhancedModifierEdit && (
                                <Card>
                                    <Row collapse="down">
                                        <Title title="Products" />
                                    </Row>
                                    <Row>
                                        <div className={styles.transferContainer}>
                                            <FastField
                                                name="items"
                                                component={SortableTransfer}
                                                options={products}
                                                disabled={disableFields || disableLinkedFields}
                                            />
                                        </div>
                                    </Row>
                                </Card>
                            )}

                            {!!initialValues.priceLists.length && !enableEnhancedModifierEdit && (
                                <Alert
                                    message="This modifier has price levels."
                                    type="info"
                                    className={styles.priceLevelAlert}
                                    showIcon
                                />
                            )}
                            <CrudActionFooter
                                saving={saveStatus === "saving"}
                                submitMode={submitMode}
                                showDuplicate={permissions?.canCreate && isUpdate}
                                showDelete={permissions?.canDelete && isUpdate}
                                showSave={!disableFields}
                                duplicateProps={{
                                    onClick: () => {
                                        if (form.isValid) {
                                            handleDuplicate(form.values);
                                        } else {
                                            // force ScrollToFormikError to run (wont submit because form is invalid)
                                            form.submitForm();
                                        }
                                    },
                                }}
                                confirmDelete={{
                                    title: "Are you sure you want to delete this modifier group?",
                                    deleteLabel: "Delete modifier group",
                                    children: getDeleteWarning(isParentLocation),
                                }}
                                deleteProps={{
                                    disabled: disableLinkedFields,
                                    onClick: handleDelete,
                                }}
                                saveProps={{
                                    disabled: !form.dirty,
                                }}
                                position="fixed"
                            />

                            <ModalRenderer target="#modal">
                                <Prompt
                                    title="Unsaved changes"
                                    when={form.dirty}
                                    blockTransition={handleBlockTransition}
                                    cancelLabel="Keep editing"
                                    confirmLabel="Discard changes"
                                >
                                    {isUpdate ? "Changes have" : "Product has"} not been saved. Are you sure you want to
                                    leave this page?
                                </Prompt>
                            </ModalRenderer>

                            <ModalRenderer target="#modal">
                                <Modal
                                    footer={
                                        <Confirm
                                            confirmLabel="Duplicate"
                                            onConfirm={() => {
                                                setSubmitMode("clone");
                                                onClone(
                                                    mapEditableModifierToSubmission(
                                                        form.values,
                                                        products,
                                                        enableEnhancedModifierEdit,
                                                        { isClone: true, isChildLocation }
                                                    )
                                                );
                                            }}
                                        />
                                    }
                                    onClose={() => setShowDuplicateConfirm(false)}
                                    title="Duplicating a brand modifier group"
                                    visible={showDuplicateConfirm}
                                    width={CardWidth.NARROW}
                                >
                                    <Row>
                                        Duplicating this item will remove the brand link and it will exist as a venue
                                        modifier group.
                                    </Row>
                                </Modal>
                            </ModalRenderer>
                        </CardsContainer>
                    </>
                )}
            </Formik>
        </PageContainer>
    );
};

type MapEditableModifierOptions = {
    isClone: boolean;
    isChildLocation: boolean;
};

const mapEditableModifierToSubmission = (
    data: EditableModifier,
    menuItems?: ProductSummary[],
    enableEnhancedModifierEdit?: boolean,
    options?: MapEditableModifierOptions
): EditableModifierSubmission => {
    if (!enableEnhancedModifierEdit) {
        return {
            ...data,
            products: undefined,
        };
    }

    // Delete deprecated `items` field and migrate to `products`
    // TODO remove with enableEnhancedModifierEdit flag & deprecated `items` code
    delete data.items;

    const { productIds = [], variantIds = [] } = data.products || {};

    if (!menuItems) {
        return {
            ...data,
            products: [],
        };
    }

    const isClone = options?.isClone || false;

    const isChildLocation = options?.isChildLocation || false;

    const disallowLinkedProducts = isClone && isChildLocation;

    const availableProducts = disallowLinkedProducts ? menuItems.filter(({ isLinked }) => !isLinked) : menuItems;

    const products: ItemModifierInputProductModel[] = availableProducts
        .map((product) => {
            const hasVariants = !!product?.variants?.length;

            const selectedVariantIds =
                product?.variants?.map((variant) => variant.id).filter((id) => variantIds.includes(id)) || [];

            const selected = productIds.includes(product.id) || !!selectedVariantIds.length;

            if (!selected) {
                return null;
            }

            if (selectedVariantIds.length) {
                return {
                    productId: product.id,
                    variantIds: selectedVariantIds,
                };
            }

            return {
                productId: product.id,
                variantIds: hasVariants ? [] : null,
            };
        })
        .filter(Boolean) as ItemModifierInputProductModel[];

    return {
        ...data,
        products,
    };
};

function getDeleteWarning(isParentLocation: boolean) {
    return (
        <div className={modalStyles.messageContainer}>
            {isParentLocation ? (
                <>
                    <p>
                        You are about to delete this modifier group from your Brand Catalogue. This action cannot be
                        undone.
                    </p>
                    <p>This modifier group will be removed from venues next time they update.</p>
                </>
            ) : (
                <p>This modifier group will be removed from your catalogue. This action cannot be undone.</p>
            )}
        </div>
    );
}

function getMinMaxErrorFields(form: FormikProps<EditableModifier>): string[] {
    const { errors, touched } = form;
    const minMaxErrors = ["minSelection", "maxSelection"].filter((name) => getIn(errors, name) && getIn(touched, name));
    return minMaxErrors?.length ? [minMaxErrors[0]] : [];
}
