import { opsApi } from "API";
import { CourseSummary } from "features/course";
import { PriceListSummary } from "features/priceList";
import { GraphQLItem, GraphQLItemId } from "common/scaffolding/types";
import { GraphQLDateRange } from "./listServices";

export async function editService(location: string, serviceId: string): Promise<GraphQLService> {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { location, serviceId });
    return res.data.service;
}

type GraphQLPayload = { service: GraphQLService };

export interface GraphQLService extends GraphQLItem {
    startTime: number;
    endTime: number;
    dates: Array<GraphQLDateRange>;
    categories: Array<GraphQLItemId>;
    menus: Array<GraphQLItemId>;
    courses: Array<GraphQLItemId>;
    setMenus: Array<GraphQLItemId>;
    defaultCourse: CourseSummary;
    dropDownListOptions: CourseSummary[];
    daysOfWeek: number;
    surcharge: number | null;
    surchargeSku: string | null;
    available: boolean | null;
    posId: string | null;
    sections: Array<GraphQLItemId>;
    menuPriority: string | null;
    featured: GraphQLFeaturedProduct;
    priceList: PriceListSummary | null;
    membershipPriceLists: GraphQLMembershipPriceList[];
    applyAlcoholicDrinksRestriction: boolean | null;
}

export interface GraphQLFeaturedProduct {
    title: string | null;
    items: string[];
}

export interface GraphQLMembershipPriceList {
    membershipLevel: GraphQLItemId;
    priceList: GraphQLItemId;
}

const query = `
    query ($location: ID!, $serviceId: ID!) { 
        service(locationId: $location, id: $serviceId) {
            internalName,
            displayName,
            id,
            startTime,
            endTime,
            dates {
                startDate,
                endDate
            },
            menus {
                id
            },
            categories {
                id
            }
            courses {
                id
            },
            setMenus {
                id
            },
            defaultCourse{
                id
            },
            daysOfWeek,
            surcharge,
            surchargeSku,
            available,
            posId,
            sections {
                id
            },
            menuPriority
            featured {
                title
                items
            },
            priceList {
                id
            },
            membershipPriceLists {
                membershipLevel {
                    id
                }
                priceList {
                    id
                }
            },
            applyAlcoholicDrinksRestriction
        } 
    }
`;
