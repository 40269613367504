import { AppState } from "features/state";
import { isLoaded } from "common/loader/isLoaded";
import { createSelector } from "reselect";
import { getPosConfigurationFields } from "./getPosConfigurationFields";
import { PosFieldDefinition } from "features/location";
import { PosConfigurationSetting } from "../types/PosConfigurationSetting";
import { PosSecretValueSymbol } from "../types/PosSecretValueSymbol";

export const getPosConfiguration = (state: AppState) =>
    isLoaded(state.posConfiguration) ? state.posConfiguration.data : { timestamp: 0, data: [] };

export const getPosConfigurationInitialValues = createSelector(
    getPosConfiguration,
    getPosConfigurationFields,
    (configuration, fields) =>
        fields.reduce((values, field) => {
            const setting = configuration.data.find((c) => c.key === field.name);

            values[field.name] = setting ? getInitialValue(field, setting) : null;
            return values;
        }, {})
);

function getInitialValue(field: PosFieldDefinition, setting: PosConfigurationSetting) {
    if (field.input === "secret" && setting.value !== null) {
        return PosSecretValueSymbol;
    }

    return setting.value;
}
