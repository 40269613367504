import styles from "./RadioGroup.module.scss";

import { ChangeEvent, useCallback, useState } from "react";
import { Field, FieldProps } from "formik";
import classNames from "classnames";
import uniqueId from "lodash/uniqueId";
import { Label } from "../input/Label";

export type Direction = "row" | "column";
export interface RadioGroupOption {
    text: string;
    value: any;
    description?: string;
}

export interface Props {
    options: RadioGroupOption[];
    disabled: boolean;
    defaultValue?: any;
    className?: string;
    direction?: Direction;
    labelClassName?: string;
    legend?: string;
    markRequired?: boolean;
    onChange?: (value: any, event: ChangeEvent<HTMLInputElement>) => boolean | void;
}

export const RadioGroup = (props: Props & FieldProps) => {
    const {
        field: { name, onChange: onFieldChange },
        options,
        disabled,
        className,
        direction = "row",
        legend,
        labelClassName,
        markRequired = false,
        onChange,
    } = props;

    const [radioGroupId, _] = useState(uniqueId("radio-group-"));

    const handleChange = useCallback(
        (event) => {
            const value = event.target?.value;
            const proceed = onChange?.(value, event);
            if (proceed !== false) {
                onFieldChange(event);
            }
        },
        [onChange, onFieldChange]
    );

    return (
        <fieldset className={classNames(direction === "column" ? styles.rootColumn : styles.root, className)}>
            {(legend || markRequired) && (
                <Label as="legend" className={styles.legend} label={legend} markRequired={markRequired} name={name} />
            )}
            {options.map((option) => {
                const id = `${radioGroupId}-${option.value}`;
                return (
                    <div key={id} className={direction === "column" ? styles.containerColumn : styles.container}>
                        <span className={classNames(styles.inputContainer, disabled && styles.disabled)}>
                            <Field
                                type="radio"
                                id={id}
                                disabled={disabled}
                                name={name}
                                value={option.value}
                                label={option.text}
                                className="radio-input"
                                onChange={handleChange}
                            />
                            <label htmlFor={id} className={styles.label}>
                                <span className={labelClassName}>{option.text}</span>
                            </label>
                        </span>
                        {option.description && <div className={styles.description}>{option.description}</div>}
                    </div>
                );
            })}
        </fieldset>
    );
};
