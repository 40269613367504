import styles from "./CalendarItem.module.scss";

import { getServices } from "features/service/selectors";
import { gridIncrementY } from "./calendarGridSettings";
import { Link } from "react-router-dom";
import { listRoute, servicesRoute } from "features/service";
import { LocationLocaleContext } from "features/location";
import { ServiceCalendarItem } from "features/service/types";
import { ServiceRouteParams } from "features/service/types/ServiceRouteParams";
import { Tooltip } from "core/components/tooltip/Tooltip";
import { toPercentage } from "common/utility/numberUtils";
import { useContext, useMemo } from "react";
import { useRouteMatch } from "react-router";
import { useSelector } from "react-redux";
import classNames from "classnames";

interface Props {
    calendarItem: ServiceCalendarItem;
    numRows: number;
    style: React.CSSProperties;
}

export const CalendarItem = ({ calendarItem, numRows, style }: Props) => {
    const { serviceName } = calendarItem.service || {};

    const services = useSelector(getServices);

    const match = useRouteMatch<ServiceRouteParams>(`${servicesRoute}/${listRoute}`);

    const editUrl = match?.url ? `${match.url}/${calendarItem.service.id}` : "";

    const myService = useMemo(
        () => services?.find((s) => s.id === calendarItem.service.id),
        [calendarItem.service.id, services]
    );

    const locationLocale = useContext(LocationLocaleContext);

    const hasPriceLists = !!myService?.priceLists?.length;
    const hasSurcharge = !!myService?.surcharge;

    const colorIndex = calendarItem.service.colourIndex % 18;

    return (
        <div className={classNames(styles.container, styles[`color${colorIndex}`])} style={style}>
            {editUrl && <Link aria-label={serviceName} to={editUrl} className={styles.link} />}
            {/* -21 adjustment makes text look right when accounting for padding, line-height etc */}
            <div className={styles.detailsContainer} style={{ maxHeight: Math.max(34, numRows * gridIncrementY - 21) }}>
                <div className={styles.prominent}>{serviceName}</div>
                <div className={styles.additional}>{calendarItem.service.description}</div>
                {(hasPriceLists || hasSurcharge) && (
                    <div className={styles.attributes}>
                        {hasPriceLists && (
                            <Tooltip
                                content={myService?.priceLists.map((p, index) => (
                                    <div key={index}>{p.displayName}</div>
                                ))}
                                placement="bottom"
                                offset={[0, 12]}
                            >
                                <span aria-hidden className={styles.attributeIcon}>
                                    {locationLocale.currencySymbol}
                                </span>
                                <span className="sr-only" role="text">
                                    <span>has price lists</span>
                                    {myService?.priceLists.map((p, index) => (
                                        <span key={index}>{p.displayName}</span>
                                    ))}
                                </span>
                            </Tooltip>
                        )}
                        {hasSurcharge && (
                            <Tooltip
                                content={`${toPercentage(myService?.surcharge)}% surcharge applied`}
                                placement="bottom"
                                offset={[0, 12]}
                            >
                                <span className={styles.attributeIconAlt} role="text">
                                    <span className="sr-only">
                                        has surcharge applied {toPercentage(myService?.surcharge)}
                                    </span>
                                    %
                                </span>
                            </Tooltip>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
