import { opsApi } from "API";
import { isNew } from "common/utility/modelUtils";
import { GraphQLVenueMembershipInput, MembershipProgram, VenueMembershipProgram } from "../types";
import { membershipQueryRet } from "./listMemberships";

export const saveMembership = async (
    locationId: string,
    membershipProgramId: string | undefined,
    membershipProgramData: GraphQLVenueMembershipInput
) => {
    const isCreate = isNew(membershipProgramId);

    const res = isCreate
        ? (
              await opsApi.graphQLQuery<{ createMembership: VenueMembershipProgram }>(createQuery, {
                  locationId,
                  input: {
                      venueMembershipProgramInput: membershipProgramData,
                  },
              })
          ).data.createMembership
        : (
              await opsApi.graphQLQuery<{ updateMembership: MembershipProgram }>(updateQuery, {
                  locationId,
                  input: {
                      venueMembershipProgramInput: membershipProgramData,
                  },
              })
          ).data.updateMembership;

    if (!res) {
        throw `Something went wrong while ${isCreate ? "creating" : "updating"} membership ${membershipProgramId}`;
    }

    return res;
};

const updateQuery = `
    mutation UpdateVenueMembership($locationId: ID!, $input: MembershipProgramInput!) {
        updateMembership(locationId: $locationId, membershipProgramInput: $input) {
            ${membershipQueryRet}
        }
    }
`;

const createQuery = `
    mutation CreateVenueMembership($locationId: ID!, $input: MembershipProgramInput!) {
        createMembership(locationId: $locationId, membershipProgramInput: $input) {
            ${membershipQueryRet}
        }
    }
`;
