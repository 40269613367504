import { CloseOutlined } from "@ant-design/icons";
import { Row, Col, Button } from "antd";
import { DatePicker } from "antd";
import moment from "moment";

import "./DateRange.scss";
import { FieldProps } from "formik";
import { RangeValue } from "rc-picker/lib/interface";
import { Moment } from "moment";
import { useState } from "react";

const { RangePicker } = DatePicker;

export const STORAGE_DATE_FORMAT = "YYYY-MM-DD";

export interface Props {
    index: number;
    dateFormat: string;
    onRemove: () => void;
    disabled?: boolean;
}

export const RenderDateRange = (props: Props & FieldProps) => {
    const {
        field: {
            name,
            value: { startDate, endDate },
        },
        form,
        disabled,
    } = props;

    const handleChange = (dates: RangeValue<Moment>, [startDate, endDate]: string[]) => {
        form.setFieldValue(name, {
            startDate: moment(startDate, props.dateFormat).format(STORAGE_DATE_FORMAT),
            endDate: moment(endDate, props.dateFormat).format(STORAGE_DATE_FORMAT),
        });
    };

    const [defaultValue] = useState<[moment.Moment, moment.Moment]>(() => [
        moment(startDate, STORAGE_DATE_FORMAT),
        moment(endDate, STORAGE_DATE_FORMAT),
    ]);

    return (
        <Row justify="space-between" className="date-range__range-picker">
            <Col span={20}>
                <RangePicker
                    defaultValue={defaultValue}
                    format={props.dateFormat}
                    allowClear={false}
                    onChange={handleChange}
                    disabled={disabled}
                />
            </Col>
            <Col span={4}>
                <Button
                    className="date-range__delete"
                    shape="circle"
                    icon={<CloseOutlined />}
                    size="small"
                    onClick={props.onRemove}
                />
            </Col>
        </Row>
    );
};
