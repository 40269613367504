import * as React from "react";
import { Spin } from "antd";

export interface CallbackProps {
    handleAuthentication: () => {};
}

export class CallbackPage extends React.Component<CallbackProps> {
    constructor(props: any) {
        super(props);
        this.props.handleAuthentication();
    }

    render() {
        return (
            <div className="loader__container loader__container--overlay">
                <Spin />
            </div>
        );
    }
}
