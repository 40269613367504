import { PosFieldDefinition } from "features/location";
import { ActiveLocation } from "features/location/types/ActiveLocation";
import { useMemo } from "react";
import { WellKnownPosField, WellKnownPosOperation } from "../posField/wellKnown";

interface Props<T extends WellKnownPosOperation = "OrderCreate"> {
    restaurantLocation: ActiveLocation;
    operationName: T;
    fieldName: WellKnownPosField<T>;
}

export function usePosField({ restaurantLocation, operationName, fieldName }: Props): PosFieldDefinition | null {
    const posField = useMemo(() => {
        const operation = restaurantLocation.posMetadata.operations.find((o) => o.name === operationName);

        if (!operation?.supported) {
            return null;
        }

        const posField = operation.fields.find((o) => o.name === fieldName);

        if (posField === undefined) {
            return null;
        }

        return posField;
    }, [restaurantLocation, operationName, fieldName]);

    return posField;
}
