import { createAction } from "features/modifier/reducers/edit";
import { scaffoldArchiveAction } from "../../../common/scaffolding/actions/scaffoldArchiveAction";
import { archiveModifier as archive } from "features/modifier/api/archiveModifier";
import { AppDispatch } from "features";
import { History } from "history";
import { EditableModifier } from "features/modifier";
import { setLastItemUpdated } from "features/location/actions/setLastItemUpdated";

export const archiveModifier = (locationId: string, region: string, history: History) =>
    scaffoldArchiveAction<string, EditableModifier>(
        (state) => state.modifiers.edit,
        createAction,
        (id: string) => archive(locationId, id),
        (dispatch: AppDispatch) => {
            dispatch(setLastItemUpdated());
            history.replace(`/${region}/${locationId}/menu/catalogue/modifiers`);
            return;
        }
    );
