import { opsApi } from "API";
import { BrandDetails } from "../types";

export const saveLocation = async (location: string, locationId: string | undefined, brandDetails: BrandDetails) => {
    const res = await opsApi.graphQLQuery<{ updateBrandDetails: GraphQLPayload }>(updateQuery, {
        locationId,
        brandDetails,
    });
    return res.data.updateBrandDetails.id;
};

type GraphQLPayload = { id: string };

const updateQuery = `
    mutation ($locationId: ID!, $brandDetails: BrandDetailsUpdateInput!) {
        updateBrandDetails(locationId: $locationId, brandDetails: $brandDetails) {
            themeColor,
            logoUrl,
            heroImage,
            backgroundImage,
            menuBackgroundImage
        }
    }
`;
