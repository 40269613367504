import { UploadFileType } from "components/forms";
import * as React from "react";

import { menuDataUrl } from "../../common/menuData/menuDataUrl";

export interface ImagePreviewProps {
    previewsrc: string | File | undefined;
    type?: UploadFileType;
}

export interface ImagePreviewState {
    inputSrc: string | File | undefined;
    displaySrc: string | undefined;
    isObjectUrl: boolean;
    type?: UploadFileType;
}

export class ImagePreview extends React.Component<
    React.ImgHTMLAttributes<HTMLImageElement> & ImagePreviewProps,
    ImagePreviewState
> {
    constructor(props: ImagePreviewProps) {
        super(props);

        const { previewsrc, type } = props;

        this.state = ImagePreview.getState(previewsrc, type);
    }

    static getDerivedStateFromProps(props: ImagePreviewProps, state: ImagePreviewState) {
        if (props.previewsrc !== state.inputSrc) {
            if (state.isObjectUrl) {
                URL.revokeObjectURL(state.displaySrc!);
            }

            return ImagePreview.getState(props.previewsrc);
        }

        return null;
    }

    static getState(src: string | File | undefined, type?: UploadFileType) {
        if (!src || typeof src === "string") {
            return {
                displaySrc: src ? menuDataUrl(src) : undefined,
                inputSrc: src,
                isObjectUrl: false,
                type,
            };
        } else {
            return {
                displaySrc: URL.createObjectURL(src),
                inputSrc: src,
                isObjectUrl: true,
                type,
            };
        }
    }

    render() {
        return this.state.type === UploadFileType.Video ? (
            <video playsInline muted controls>
                <source src={this.state.displaySrc} type="video/mp4" />
            </video>
        ) : (
            <img src={this.state.displaySrc} alt="" {...this.props} />
        );
    }
}
