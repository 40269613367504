import { Formik, FastField } from "formik";
import { FieldErrors, TextInput } from "components/forms";
import { Form, Divider, Button, Alert } from "antd";
import { SaveStatus } from "common/loader";
import { PageTitle } from "common/scaffolding/components/DataListingPage/PageTitle";
import { HyperwalletUser } from "../types/HyperwalletUser";
import { hyperwalletUserSchema } from "../schema/hyperwalletUserSchema";
import { EditRow } from "common/scaffolding/components/EditRow";

export interface Props {
    initialValues: HyperwalletUser;
    saveStatus: SaveStatus;
    onSubmit: (data: HyperwalletUser, isNew: boolean) => void;
}

export const HyperwalletUserPage = ({ initialValues, saveStatus, onSubmit }: Props) => {
    return (
        <div className="edit-config">
            <PageTitle title="Hyperwallet User Configuration" description="" />
            {!initialValues.isNew && (
                <Alert message="This location is already configured for Hyperwallet" type="info" showIcon />
            )}
            {initialValues.isNew && (
                <Formik
                    validationSchema={hyperwalletUserSchema}
                    initialValues={initialValues}
                    onSubmit={(values) => onSubmit(values, initialValues.isNew)}
                    key={initialValues ? initialValues.id : "new"}
                >
                    {(form) => (
                        <Form onFinish={form.handleSubmit}>
                            <Divider />
                            <EditRow title="Company Name" subTitle="">
                                <FieldErrors name="businessName" form={form}>
                                    <FastField name="businessName" component={TextInput} />
                                </FieldErrors>
                            </EditRow>
                            <EditRow title="First Name" subTitle="">
                                <FieldErrors name="firstName" form={form}>
                                    <FastField name="firstName" component={TextInput} />
                                </FieldErrors>
                            </EditRow>
                            <EditRow title="Last Name" subTitle="">
                                <FieldErrors name="lastName" form={form}>
                                    <FastField name="lastName" component={TextInput} />
                                </FieldErrors>
                            </EditRow>
                            <EditRow title="Email" subTitle="">
                                <FieldErrors name="email" form={form}>
                                    <FastField name="email" component={TextInput} />
                                </FieldErrors>
                            </EditRow>
                            <EditRow title="Phone Number" subTitle="">
                                <FieldErrors name="phoneNumber" form={form}>
                                    <FastField name="phoneNumber" component={TextInput} />
                                </FieldErrors>
                            </EditRow>
                            <div className="edit-form--commands">
                                <Button type="primary" htmlType="submit" loading={saveStatus === "saving"}>
                                    Save
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};
