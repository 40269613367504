export const QuickSellIsOff = () => (
    <div className="quick-sell-page__off">
        <div className="quick-sell-page__off__header">Introducing Quick Sell</div>
        <div className="quick-sell-page__off__subheader">
            Boost your average order value using your best selling products, powered by your sales data.
        </div>
        <div className="quick-sell-page__off__title">Boost your average order value</div>
        <div className="quick-sell-page__off__subtitle">
            Quick Sell targets low spend orders strategically to increase average guest spend.
        </div>
        <div className="quick-sell-page__off__title">Powered by your sales data</div>
        <div className="quick-sell-page__off__subtitle">
            Quick Sell showcases your best selling products, and changes throughout the day to match what your guests
            are ordering.
        </div>
        <div className="quick-sell-page__off__title">Ordering made super quick</div>
        <div className="quick-sell-page__off__subtitle">
            When your customers are ready to send their order, they can add any of your Quick Sells, with a single tap.
        </div>
    </div>
);
