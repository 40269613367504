import * as Yup from "yup";
import { EditableVariant, EditMenuItemTagMap, EditableProduct } from "features/catalogue/types";
import { FileSchemaType } from "common/upload";
import { TagGroup } from "features/tags";
import { EditableModifier, EditableModifierOption } from "features/modifier";
import { energyContentSchema } from "features/modifier/schemas";

export const optionalPriceSchema = Yup.lazy((value) =>
    value === "" ? Yup.string().notRequired() : Yup.number().nullable(true)
);

export const requiredPriceSchema = Yup.number()
    .nullable(true)
    .typeError("Please enter a valid price")
    .required("Price required")
    .min(0, "Please enter a positive price");

export const uploadedImageSchema = Yup.lazy((value) =>
    !value || typeof value === "string"
        ? Yup.string()
              .nullable(true)
              .when("$requireImage", (requireImage: boolean, schema: Yup.StringSchema) =>
                  requireImage ? schema.required("Please select an image") : schema.notRequired()
              )
        : new FileSchemaType()
              .nullable(true)
              .type(/image\/(jpg|jpeg|png)/i, "Image must be .jpg or .png")
              .fileSize(1024 * 1024 * 4, "Please select an image smaller than 4MB")
              .required("Please select an image")
);

export const uploadedVideoSchema = Yup.lazy((value) =>
    !value || typeof value === "string"
        ? Yup.string().nullable(true)
        : new FileSchemaType()
              .nullable(true)
              .type(/video\/(mp4)/i, "Please select a valid mp4")
              .fileSize(1024 * 1024 * 5, "Please select a video smaller than 5MB")
              .required("Please select a video")
);

export const optionalImageSchema = Yup.lazy((value) =>
    !value || typeof value === "string"
        ? Yup.string().nullable(true).notRequired()
        : new FileSchemaType()
              .nullable(true)
              .type(/image\/(jpg|jpeg|png)/i, "Please select a valid JPG or PNG")
              .fileSize(1024 * 1024 * 4, "Please select an image smaller than 4MB")
              .notRequired()
);

export const uploadedBrandingImageSchema = Yup.lazy((value) =>
    typeof value === "string"
        ? Yup.string().required("Please select an image")
        : new FileSchemaType()
              .nullable(true)
              .type(/image\/(jpg|jpeg|png)/i, "Please select a valid JPG or PNG")
              .fileSize(1024 * 1024 * 8, "Please select an image smaller than 8MB")
);

export const editableModificationOptionSchema = Yup.object<EditableModifierOption>().shape({
    displayName: Yup.string().required("Please enter a name"),
    sku: Yup.string().nullable(true),
    price: optionalPriceSchema,
    taxRate: Yup.number().nullable(true),
    isLinked: Yup.boolean(),
});

export const editablePriceSchema = Yup.object<EditableVariant>().shape({
    displayName: Yup.string().notRequired(),
    price: requiredPriceSchema,
    taxRate: Yup.number().nullable(true),
    sku: Yup.string().nullable(true).notRequired(),
    modifiers: Yup.array().of(Yup.string()),
    isLinked: Yup.boolean(),
});

export const editableVariantSchema = Yup.object<EditableVariant>().shape({
    displayName: Yup.string().required("Please enter a name"),
    price: requiredPriceSchema,
    taxRate: Yup.number().nullable(true),
    sku: Yup.string().nullable(true),
    modifiers: Yup.array().of(Yup.string()),
    energyContent: energyContentSchema,
    isLinked: Yup.boolean(),
});

export const editableMenuItemSchema = Yup.object<EditableProduct>().shape({
    id: Yup.string().notRequired(),
    internalName: Yup.string().nullable(true).notRequired(),
    displayName: Yup.string().required("Please enter a name"),
    description: Yup.string().notRequired(),
    image: uploadedImageSchema,
    video: uploadedVideoSchema,
    modifiers: Yup.array().of(
        Yup.object<EditableModifier>().when(["$prices"], (prices: Object[], schema) =>
            schema.test({
                name: "noVariants",
                message: "Modifier must be applied to at least one variant",
                test: function (value: EditableModifier) {
                    if (!prices || prices.length < 2) {
                        return true;
                    }

                    return (prices as EditableVariant[]).some((price) => price.modifiers?.includes(value.id!));
                },
            })
        )
    ),
    prices: Yup.lazy<EditableVariant[]>((variants) =>
        variants.length === 1
            ? Yup.array().of(editablePriceSchema).min(1).max(1)
            : Yup.array().of(editableVariantSchema).min(2)
    ),
    categories: Yup.array().of(Yup.string()),
    special: Yup.boolean(),
    popular: Yup.boolean(),
    recommendedMenuItems: Yup.array().of(Yup.string()),
    tags: Yup.object<EditMenuItemTagMap>().when(
        "$tagGroups",
        (tagGroups: TagGroup[], schema: Yup.ObjectSchema<EditMenuItemTagMap>) =>
            schema.shape(
                tagGroups?.reduce((shape, group) => {
                    shape[group.id] = Yup.array().of(Yup.string()); // TODO: min??? maybe via context?
                    return shape;
                }, {})
            )
    ),
    tagList: Yup.array().of(Yup.string()),
    template: Yup.string(),
    descriptors: Yup.mixed(),
    priceLists: Yup.array().of(Yup.string()),
    status: Yup.number(),
    itemType: Yup.mixed()
        .oneOf(["food", "drink"])
        .nullable(true)
        .when(
            "$requireType",
            (requireType: boolean, schema: Yup.StringSchema) =>
                requireType ? schema.required("Please select an item type") : schema.notRequired() // backwards compat
        ),
    isLinked: Yup.boolean(),
});
