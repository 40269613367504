import { FastField } from "formik";

import { timeZones } from "../data/TimeZoneData";
import { SelectItem, SearchInput, SearchInputContainerProps } from "components/forms/SearchInput";

const options: SelectItem[] = timeZones.map((timezone) => ({
    id: timezone,
    displayName: timezone,
}));

export const TimeZoneSelector = ({ placeholder, disabled, name }: SearchInputContainerProps) => (
    <FastField name={name} component={SearchInput} disabled={disabled} placeholder={placeholder} options={options} />
);
