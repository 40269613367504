import { AppState } from "features";
import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { getCrudPermissions } from "features/location/selectors/getCrudPermissions";
import { getPosFieldSettings } from "components/forms/posUI/posSettings";
import { isLoaded } from "common/loader/isLoaded";
import { ListTaxesPage, Props } from "../components/ListTaxesPage";
import { LocationComponentProps, LocaleComponentProps, withLocation, withLocale } from "features/location";
import { RouteComponentProps, withRouter } from "react-router";
import { withLoader, PageLoadingProps } from "common/loader/withLoader";

import * as actions from "features/taxes/actions";

const mapStateToProps = (
    state: AppState,
    { restaurantLocation: { postype }, match: { params } }: LocationComponentProps & RouteComponentProps<RouteParams>
): DataProps<PageLoadingProps<Props>> => {
    const {
        taxes: { list },
    } = state;

    if (isLoaded(list)) {
        const taxPosIdFieldSettings = getPosFieldSettings(postype || "", "taxPosId");

        return {
            taxes: list.data,
            permissions: getCrudPermissions(state).taxes,
            loadStatus: "loaded",
            showIdColumn: taxPosIdFieldSettings?.visible || false,
        };
    }

    return {
        loadStatus: list.status,
    };
};

const mapDispatchToProps = (
    dispatch: any,
    {
        restaurantLocation,
        match: { params },
    }: LocationComponentProps & LocaleComponentProps & RouteComponentProps<RouteParams>
): FunctionProps<PageLoadingProps<Props>> => ({
    fetch: () => {
        dispatch(actions.list(restaurantLocation.id));
    },
});

interface RouteParams {
    location: string;
    type: string;
}

export const ListTaxesPageContainer = withRouter(
    withLocation(withLocale(connect(mapStateToProps, mapDispatchToProps)(withLoader(ListTaxesPage))))
);
