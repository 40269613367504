import { opsApi } from "API";
import { GraphQLItem, GraphQLItemId } from "../../../common/scaffolding/types";

export const listTagGroups = async () => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query);
    return res.data.tagGroups;
};

type GraphQLPayload = { tagGroups: GraphQLTagGroupSummary[] };

export interface GraphQLTagGroupSummary extends GraphQLItem {
    editorMode: string;
    parentTagGroup: GraphQLItemId;
    tags: Array<{
        id: string;
        displayName: string;
        icon: string;
        parentTagId: string;
    }>;
}

const query = `
    query { 
        tagGroups {
            id,
            displayName,
            editorMode,
            parentTagGroup { 
                id 
            },
            tags {
                id,
                displayName,
                icon,
                parentTagId,
            }
        }
    }
`;
