import { PosFieldProps } from "../PosFieldProps";
import { PosSwitchFieldDefinition } from "features/location";
import { SwitchInput } from "../../SwitchInput";

export const PosSwitchField = ({ form, field, meta, disabled }: PosFieldProps<PosSwitchFieldDefinition>) => (
    <SwitchInput
        parseValue={parseValue}
        formatValue={formatValue}
        field={field}
        form={form}
        meta={meta}
        disabled={disabled}
    />
);

const parseValue = (value: string) => value === "1";
const formatValue = (value: boolean) => (value ? "1" : "0");
