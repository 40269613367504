import { PropsWithChildren, ReactElement, useEffect, useRef } from "react";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";

interface Props {
    provided: DraggableProvided;
    snapshot: DraggableStateSnapshot;
}

// Antd's Transfer component only lets us control the inner list item content.
// When drag n drop styles are applied to 'displace' siblings, it translates
// only the inner content which looks poor and can make text hidden.
// This component applies the transform styles of non-dragged items to their
// parent (list item) element instead of the draggable component itself.

export const SortableTransferItem = ({ children, provided, snapshot }: PropsWithChildren<Props>): ReactElement => {
    const ref = useRef<HTMLDivElement>(null);
    const parentRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
        // get the list item DOM ancestor
        const parent: HTMLElement = ref.current?.parentNode?.parentNode as HTMLElement;
        parentRef.current = parent;
        provided.innerRef(ref.current);

        return () => {
            provided?.innerRef(null);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!parentRef.current) {
            return;
        }

        // the dragged item
        if (snapshot.isDragging) {
            parentRef.current.classList.add("--is-dragging");
            return;
        }

        // other items
        parentRef.current.classList.remove("--is-dragging");

        // apply the translation to the parent, not the element
        if (provided?.draggableProps?.style?.hasOwnProperty("transform")) {
            const transform = provided.draggableProps.style.transform || "";
            parentRef.current.style.transform = transform;
        }
    }, [provided.draggableProps.style, snapshot.isDragging]);

    // only apply the style to the dragged item
    const style = {
        display: "inline-block",
        ...(snapshot.isDragging ? provided.draggableProps.style : null),
    };

    return (
        <div ref={ref} {...provided.draggableProps} {...provided.dragHandleProps} style={style}>
            {children}
        </div>
    );
};
