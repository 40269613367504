import { FieldInputProps, FormikProps } from "formik";
import { initIdsByTypeDictionary } from "common/data/treeUtils";
import { TreeItem } from "common/types";
import { useEffect } from "react";

import { EditableTaxClass, TaxClassAssignment } from "../types";

interface Props {
    form: FormikProps<EditableTaxClass>;
    field: FieldInputProps<TaxClassAssignment>;
    selectedItems: TreeItem[];
    validTaxTargets: string[];
}

// this component binds the selected tree items to the formik field value
export const SelectionsAdapter = ({ field: { name }, form, selectedItems, validTaxTargets }: Props) => {
    useEffect(() => {
        const idsByType = initIdsByTypeDictionary(validTaxTargets);

        const items = selectedItems
            .filter((node: TreeItem) => validTaxTargets.includes(node.type))
            .map((node: TreeItem) => {
                // check dictionary to only add items once
                if (!idsByType[node.type][node.id]) {
                    idsByType[node.type][node.id] = true;
                    return {
                        targetId: node.id,
                        targetType: node.type,
                        parentId: node.parent,
                    };
                } else {
                    return null;
                }
            })
            .filter(Boolean);

        form.setFieldValue(name, items);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItems]);

    return null;
};
