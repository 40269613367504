import { FeatureConfiguration } from "..";
import { reducer } from "features/location/reducers";

import * as actions from "features/location/actions";

import { Route } from "react-router";
import { Col } from "antd";
import { withPermissions } from "features/location/components/withPermission";
import { EditCompanyDetailsPageContainer } from "features/location/containers/EditCompanyDetailsContainer";

export * from "features/location/types/LocationSummary";
export * from "features/location/types/ActiveLocation";

export { actions };

export * from "features/location/containers/withLocation";

export default function (config: FeatureConfiguration) {
    config.reducers.locations = reducer;

    config.addRoutes(config.routes.locationRoutes, [
        <Route
            path={"/:region/:location/edit"}
            key="location-edit"
            render={() => (
                <>
                    <Col span={24} md={24} className="content__col">
                        <div className="content__col-edit">
                            <Route component={withPermissions(EditCompanyDetailsPageContainer, "location:update")} />
                        </div>
                    </Col>
                </>
            )}
        />,
    ]);
}
