import { RouteComponentProps } from "react-router";
import { region } from "..";
import { Layout } from "features/structure/screen/Layout";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import { AppState } from "features/state";
import { isLoaded } from "common/loader/isLoaded";
import { Card, Row } from "core/components/card";
import { RegionSelectorContainer } from "../containers/RegionSelectorContainer";

export const withRegion =
    <P extends Object>(Component: React.ComponentType<P>): React.SFC<P & RouteComponentProps<any>> =>
    (props) => {
        const selectedRegion = useSelector((state: AppState) => state.region.selectedRegion);

        if (isLoaded(selectedRegion)) {
            const hasAccess = region.hasAccess();
            if (hasAccess === true) {
                return <Component {...props} />;
            } else if (hasAccess === false) {
                return (
                    <Layout empty>
                        <Card>
                            <Row>
                                <span>
                                    <strong>Access denied</strong> - cannot access region
                                </span>
                                <RegionSelectorContainer />
                            </Row>
                        </Card>
                    </Layout>
                );
            }
        }

        return (
            <div className="loader__container loader__container--overlay">
                <Spin />
            </div>
        );
    };
