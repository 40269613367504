import Table from "antd/lib/table";
import classNames from "classnames";
import { EditableSurcharge } from "../types";
import { OneTimeSurchargeColumns } from "./SurchargeColumns";

export interface Props {
    surchargeIdColumn?: string;
    surcharges: EditableSurcharge[];
}

export const PreviousSurchargesTable = ({ surchargeIdColumn, surcharges }: Props) => {
    return (
        <Table
            columns={OneTimeSurchargeColumns({ surchargeIdColumn, disableFields: true })}
            className={classNames(surchargeIdColumn && "with-surcharge-id")}
            dataSource={surcharges}
            size="small"
            rowKey="rowKey"
            pagination={false}
        ></Table>
    );
};
