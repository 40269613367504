import { AppState } from "features";
import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { isLoaded } from "common/loader/isLoaded";
import { ListAuditLogEntriesPage, Props } from "../components/ListAuditLogEntriesPage";
import { LocationComponentProps, LocaleComponentProps, withLocation, withLocale } from "features/location";
import { RouteComponentProps, withRouter } from "react-router";
import { withLoader, PageLoadingProps } from "common/loader/withLoader";

import * as actions from "features/auditlog/actions";

const mapStateToProps = (
    state: AppState,
    { restaurantLocation: { postype }, match: { params } }: LocationComponentProps & RouteComponentProps<RouteParams>
): DataProps<PageLoadingProps<Props>> => {
    const {
        auditlog: { list },
        locations: { active: activeLocation },
    } = state;

    if (isLoaded(activeLocation) && isLoaded(list)) {
        return {
            entries: list.data,
            loadStatus: "loaded",
        };
    }

    return {
        loadStatus: list.status,
    };
};

const mapDispatchToProps = (
    dispatch: any,
    {
        restaurantLocation,
        match: { params },
    }: LocationComponentProps & LocaleComponentProps & RouteComponentProps<RouteParams>
): FunctionProps<PageLoadingProps<Props>> => ({
    fetch: () => {
        dispatch(actions.list(restaurantLocation.id));
    },
});

interface RouteParams {
    location: string;
    type: string;
}

export const ListAuditLogEntriesPageContainer = withRouter(
    withLocation(withLocale(connect(mapStateToProps, mapDispatchToProps)(withLoader(ListAuditLogEntriesPage))))
);
