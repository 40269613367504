import { createAction } from "../../location/reducers/edit";
import { createAction as listActions } from "../../location/reducers/list";
import { BrandDetails, Branding } from "../types";
import { LocationImageType, upload } from "API/upload";
import { scaffoldSaveAction } from "../../../common/scaffolding/actions/scaffoldSaveAction";
import { saveLocation } from "../api/saveLocation";
import { fetchActive } from "features/location/actions";
import { getSaveBrandingTrackingEventData } from "../selectors/getSaveBrandingTrackingEventData";

export const save = (location: string, brandDetails: Branding) =>
    scaffoldSaveAction(
        (state) => state.locations.edit,
        createAction,
        async (locationId: string | undefined, dispatch) => {
            const brandDetailsUpdate = await mapUpdateModel(brandDetails, location);
            const updatedLocation = await saveLocation(location, locationId, brandDetailsUpdate);
            await dispatch(fetchActive(location, true));
            return updatedLocation;
        },
        undefined,
        false,
        listActions.reload,
        undefined,
        (appState) => getSaveBrandingTrackingEventData(appState, brandDetails)
    );

const mapUpdateModel = async (
    { themeColor, backgroundImage, heroImage, logoUrl, defaultCatalogueImage, menuBackgroundImage }: Branding,
    location: string
): Promise<BrandDetails> => ({
    themeColor,
    backgroundImage: await processImageValue(backgroundImage!, location, "background", true),
    menuBackgroundImage: await processImageValue(menuBackgroundImage!, location, "menuBackground", true),
    heroImage: await processImageValue(heroImage!, location, "hero", true),
    logoUrl: await processImageValue(logoUrl!, location, "logo", false),
    defaultCatalogueImage: await processImageValue(defaultCatalogueImage!, location, "food", false),
});

async function processImageValue(
    value: File | string | null,
    location: string,
    type: LocationImageType,
    replaceOrig: boolean
) {
    if (value && typeof value !== "string") {
        const res = await upload(value, location, type, true);
        return replaceOrig ? res.replace("-orig", "-full") : res;
    }
    return value;
}
