import { opsApi } from "../../../API";

export const archiveCourse = async (locationId: string, courseId: string) => {
    await opsApi.graphQLQuery(query, { locationId, courseId });
};

const query = `
    mutation($locationId: ID!, $courseId: ID!) {
        deleteCourse(locationId: $locationId, id: $courseId) {
            id
        }
    }
`;
