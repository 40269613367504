import styles from "./ListItem.module.scss";

import { Button } from "core/components/button";
import { Checkbox } from "core/components/form/checkbox";
import { useFormikContext } from "formik";
import { CollectionName, CatalogueItem, CatalogueSelections, CatalogueItemSelections } from "../types";
import { ComponentType, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { createAction } from "../reducers/filters";
import { product, modifier } from ".";
import { getModifiersFilterCount, getProductsFilterCount, getTextFilter } from "../selectors/getFilters";
import {
    getAllPageItemsSelected,
    getPageSelectionInfo,
    getSelectionsWithoutPageItems,
} from "../selectors/getPageSelectionInfo";
import { ModalRenderer } from "core/components/modal";
import { FiltersModal } from "./FiltersModal";
import { Badge } from "core/components/badge";
import { Input } from "core/components/form/input";
import { Icon } from "core/components/icon";
import { Search } from "components/icons";
import { ActionsPopup } from "core/components/actionsPopup/ActionsPopup";
import { Card } from "core/components/card";
import { Bin } from "common/icons";
import { CatalogueContext } from "../context/CatalogueContext";
import { useDispatch, useSelector } from "react-redux";
import { getFilteredModifiersSelection, getFilteredProductsSelection } from "../selectors/getFilteredCatalogueItems";
import { Tooltip } from "core/components/tooltip/Tooltip";
import { trackEventFilterText } from "../actions/tracking";
import { getProductCatalogueItems } from "../selectors/getProductCatalogueItems";
import { getModifierCatalogueItems } from "../selectors/getModifierCatalogueItems";
import { getIsChildLocation } from "features/location/selectors/getIsChildLocation";
import { getIsParentLocation } from "features/location/selectors/getIsParentLocation";

interface Props {
    canBulkDelete: boolean;
    canBulkSelect: boolean;
    collectionName: CollectionName;
    headers: ComponentType<product.HeadersProps> | ComponentType<modifier.HeadersProps>;
    pageIndex: number;
    pageItems: CatalogueItem[];
    pageSize: number;
}

export const ListHeaderRow = ({
    canBulkDelete,
    canBulkSelect,
    collectionName,
    headers: Headers,
    pageIndex,
    pageItems,
    pageSize,
}: Props) => {
    const dispatch = useDispatch();

    const [showFiltersModal, setShowFiltersModal] = useState(false);

    const productsFilterCount = useSelector(getProductsFilterCount);

    const modifersFilterCount = useSelector(getModifiersFilterCount);

    const isParentLocation = useSelector(getIsParentLocation);

    const textFilter = useSelector(getTextFilter);

    const { setBulkDeleteOpen } = useContext(CatalogueContext);

    const form = useFormikContext<CatalogueSelections>();

    const selections = form.values[collectionName];

    const { numSelected, numItems } = getPageSelectionInfo({ pageItems, selections });

    const indeterminate = numSelected > 0 && numSelected < numItems;

    const isChecked = numSelected > 0 && numSelected === numItems;

    const filteredProducts = useSelector(getFilteredProductsSelection);
    const filteredModifiers = useSelector(getFilteredModifiersSelection);
    const filteredItems = collectionName === "products" ? filteredProducts : filteredModifiers;

    const products = useSelector(getProductCatalogueItems);
    const modifiers = useSelector(getModifierCatalogueItems);

    const isChildLocation = useSelector(getIsChildLocation);

    const activeCollection = collectionName === "products" ? products : modifiers;

    const handleClear = useCallback(() => {
        form.setFieldValue(collectionName, {});
    }, [form, collectionName]);

    const handleSelectAll = useCallback(() => {
        form.setFieldValue(collectionName, filteredItems);
    }, [collectionName, form, filteredItems]);

    const handleCheckboxChange = useCallback(() => {
        pageItems.forEach((item) => {
            form.setFieldTouched(`${collectionName}.${item.id}`, true, false);
        });

        if (isChecked) {
            form.setFieldValue(collectionName, getSelectionsWithoutPageItems({ pageItems, selections }));
        } else {
            form.setFieldValue(collectionName, {
                ...selections,
                ...getAllPageItemsSelected({ pageItems, selections }),
            });
        }
    }, [collectionName, isChecked, form, pageItems, selections]);

    const handleTextFilterChange = useCallback(
        (value: string) => {
            dispatch(createAction.setTextFilter(value));
        },
        [dispatch]
    );

    useEffect(() => {
        const trackFilterText = setTimeout(() => {
            if (textFilter) {
                trackEventFilterText(collectionName, textFilter, !!Object.keys(filteredItems || {}).length);
            }
        }, 700);

        return () => clearTimeout(trackFilterText);
    }, [collectionName, textFilter, filteredItems]);

    const isProductSelected = collectionName === "products";

    const filterCount = isProductSelected ? productsFilterCount : modifersFilterCount;

    const hasSelections = numSelected !== 0 && pageItems.length > 0;

    const hasNoSelections = numSelected === 0 && pageItems.length > 0;

    const hasLinkedSelections = getSelectionsHaveLinkedItems(selections, activeCollection);

    const bulkDeleteDisabled = hasLinkedSelections && isChildLocation;

    const bulkDeleteDisabledReason = hasLinkedSelections ? "Unable to delete brand items" : "";

    const handleBulkDelete = useCallback(() => {
        setBulkDeleteOpen(true);
    }, [setBulkDeleteOpen]);

    return (
        <>
            <div className={styles.containerHeaderTop}>
                <div className={styles.innerContainerHeaderTop}>
                    <div className={styles.cellGrow}>
                        <Input
                            blockEnter
                            onChange={handleTextFilterChange}
                            value={textFilter}
                            placeholder={`Search ${collectionName}`}
                            before={
                                <Icon verticalAlign="middle">
                                    <Search />
                                </Icon>
                            }
                            onClear={() => {
                                handleTextFilterChange("");
                            }}
                        />
                    </div>
                    <div className={styles.cellStatic}>
                        <Button role="secondary" type="button" onClick={() => setShowFiltersModal(true)}>
                            <>
                                {!!filterCount && (
                                    <Badge
                                        textColorScheme="light"
                                        backgroundColorScheme="dark"
                                        shape="circle"
                                        className={styles.badge}
                                    >
                                        {filterCount}
                                    </Badge>
                                )}
                                <span>Filter</span>
                            </>
                        </Button>
                    </div>
                </div>
            </div>
            <div className={hasSelections ? styles.containerHeaderBottom : styles.containerHeaderHidden}>
                <div className={styles.innerContainer}>
                    <div className={styles.controlsContainer}>
                        {canBulkSelect && (
                            <div>
                                <SelectAllTooltip isChecked={isChecked} disabled={!hasSelections}>
                                    <Checkbox
                                        key={`${pageSize}-${pageIndex}-${isChecked}`}
                                        indeterminate={indeterminate}
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                    />
                                </SelectAllTooltip>
                            </div>
                        )}
                        <div className={styles.controlsInnerContainer}>
                            <div className={styles.controlsCompact}>
                                <ActionsPopup closeOnMenuClick={true}>
                                    <Card cardStyle="popup">
                                        <Button
                                            shape="listItem"
                                            type="button"
                                            onClick={handleClear}
                                        >{`Clear all ${collectionName}`}</Button>
                                        <Button
                                            shape="listItem"
                                            type="button"
                                            onClick={handleSelectAll}
                                        >{`Select all ${collectionName}`}</Button>
                                        {canBulkDelete && (
                                            <Tooltip
                                                content={bulkDeleteDisabledReason}
                                                disabled={!bulkDeleteDisabled}
                                                offset={[0, 12]}
                                                placement="bottom-end"
                                            >
                                                <Button
                                                    shape="listItem"
                                                    type="button"
                                                    onClick={handleBulkDelete}
                                                    colorScheme="critical"
                                                    disabled={bulkDeleteDisabled}
                                                >
                                                    <Bin />
                                                    {`Delete ${collectionName}`}
                                                </Button>
                                            </Tooltip>
                                        )}
                                    </Card>
                                </ActionsPopup>
                            </div>
                            <div className={styles.controlsLarge}>
                                <Button role="secondary" type="button" onClick={handleClear}>
                                    {`Clear all ${collectionName}`}
                                </Button>
                                <Button
                                    role="secondary"
                                    type="button"
                                    onClick={handleSelectAll}
                                >{`Select all ${collectionName}`}</Button>
                                {canBulkDelete && (
                                    <Tooltip
                                        content={bulkDeleteDisabledReason}
                                        disabled={!bulkDeleteDisabled}
                                        offset={[0, 12]}
                                        placement="top-end"
                                    >
                                        <Button
                                            type="button"
                                            colorScheme="critical"
                                            label="Delete"
                                            role="secondary"
                                            onClick={handleBulkDelete}
                                            disabled={bulkDeleteDisabled}
                                        >
                                            <Icon size="small">
                                                <Bin />
                                            </Icon>
                                            <span>{`Delete ${collectionName}`}</span>
                                        </Button>
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div role="rowGroup">
                <div
                    role="row"
                    className={hasNoSelections ? styles.containerHeaderBottom : styles.containerHeaderHidden}
                >
                    <div className={styles.innerContainer}>
                        {canBulkSelect && (
                            <div role="columnheader" className={styles.check}>
                                <SelectAllTooltip isChecked={isChecked} disabled={!hasNoSelections}>
                                    <Checkbox
                                        key={`${pageSize}-${pageIndex}-${isChecked}`}
                                        indeterminate={indeterminate}
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                    />
                                </SelectAllTooltip>
                            </div>
                        )}
                        <Headers />
                    </div>
                </div>
            </div>
            <ModalRenderer target="#modal">
                <FiltersModal
                    collectionName={collectionName}
                    visible={showFiltersModal}
                    onClose={() => setShowFiltersModal(false)}
                    onConfirm={() => {}}
                    enableCategoryFilters={isProductSelected && !isParentLocation}
                />
            </ModalRenderer>
        </>
    );
};

interface SelectAllTooltipProps {
    disabled: boolean;
    isChecked: boolean;
}

const SelectAllTooltip = ({ children, disabled, isChecked }: PropsWithChildren<SelectAllTooltipProps>) => {
    return (
        <Tooltip
            content={`${isChecked ? "Deselect" : "Select"} all on page`}
            disabled={disabled}
            offset={[-16, 12]}
            placement="top-start"
            portal={false}
        >
            {children}
        </Tooltip>
    );
};

function getSelectionsHaveLinkedItems(selections: CatalogueItemSelections, collection: CatalogueItem[]) {
    const selectedParentIds = Object.keys(selections);

    if (selectedParentIds.length) {
        for (const item of collection) {
            if (item.isLinked && item.id && selectedParentIds.includes(item.id)) {
                return true;
            }
        }
    }

    return false;
}
