import { InputNumberAddon } from "components/forms/InputNumberAddon";
import { PriceListItem } from "../../types";
import { useCallback, useEffect, useState } from "react";

export interface Props {
    priceLevel: PriceListItem;
    handleUpdate: (item: PriceListItem, isValid: boolean, price?: number) => void;
    canUpdate: boolean;
    addonBefore?: string;
}

export const EditPriceInline = ({ priceLevel, addonBefore, handleUpdate }: Props) => {
    const [valid, setValid] = useState(true);

    const handlePriceChange = (price: number | undefined) => {
        const isValid = price !== undefined && price >= 0;
        setValid(isValid);
        handleUpdate(priceLevel, isValid, price);
    };

    const setInitialValidation = useCallback(() => {
        const isValid = priceLevel.priceLevelPrice !== undefined && priceLevel.priceLevelPrice >= 0;
        setValid(isValid);
        !isValid && handleUpdate(priceLevel, isValid);
        // including handleUpdate as a dependency causing revalidation issues, remove for now. Some refactoring needed
        // eslint-disable-next-line
    }, [priceLevel]);

    useEffect(() => {
        setInitialValidation();
    }, [setInitialValidation]);

    return (
        <InputNumberAddon
            defaultValue={priceLevel.priceLevelPrice}
            placeholder="Price"
            addonBefore={addonBefore}
            decimalGroup={2}
            onChange={handlePriceChange}
            disabled={false}
            maxLength={6}
            allowUndefined={true}
            className={!valid ? "invalid" : ""}
        />
    );
};
