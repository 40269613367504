import { PictureOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import classNames from "classnames";
import { menuDataUrl } from "common/menuData/menuDataUrl";
import { useEffect, useRef, useState } from "react";
import "./Image.scss";

export interface Props {
    image: string;
    type: "inline" | "thumb";
    alt?: string;
}

export const Image = ({ image, type, alt }: Props) => {
    const [hasError, setHasError] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [imageSrc, setImageSrc] = useState("");
    const retryCount = useRef(0);

    const handleError = () => {
        setHasError(true);
        if (retryCount.current < 2) {
            //retry for just uploaded images still processing;
            const timeout = retryCount.current === 0 ? 3000 : 200;
            setTimeout(() => {
                //fallback to original after second attempt if thumb not ready
                retryCount.current === 1 && setImageSrc(imageSrc.replace("-thumb", "-orig"));
                setHasError(false);
            }, timeout);
            retryCount.current++;
            return;
        }
        setLoaded(true);
    };
    useEffect(() => {
        image && setImageSrc(menuDataUrl(image));
    }, [image]);

    return (
        <div className={classNames(`list-image-wrapper-${type}`, (!imageSrc || loaded) && "loaded")}>
            <div className="image-overlay">
                <Spin />
            </div>
            {!hasError && !!imageSrc ? (
                <img className="" onError={handleError} onLoad={() => setLoaded(true)} src={imageSrc} alt={alt} />
            ) : (
                <div className="list-image-placeholder">
                    <PictureOutlined />
                </div>
            )}
        </div>
    );
};
