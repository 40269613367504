import { FeatureConfiguration } from "features";
import { withPermissions } from "features/location/components/withPermission";
import { ListPromotionsPageContainer } from "./containers/ListPromotionsPageContainer";
import { EditPromotionPageContainer } from "./containers/EditPromotionPageContainer";
import { reducer } from "./reducers";
import * as actions from "./actions";

import { EditComponent, ModalCrudRouteContainer, ListComponent } from "common/scaffolding/components/ModalCrudRoute";
import { ModalEditWrapper } from "common/scaffolding/components/EditWrapper";
import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { ListWrapper } from "common/scaffolding/components/ListWrapper";

export { actions };

const List: ListComponent = {
    route: "",
    component: withPermissions(ListPromotionsPageContainer, "promotions:read"),
    contentWidth: ContentWidth.STANDARD,
    wrapper: ListWrapper,
};

const Edit: EditComponent = {
    route: ":id",
    editTitle: "Edit Promotion",
    createTitle: "Add Promotion",
    component: withPermissions(EditPromotionPageContainer, "promotions:update"),
    contentWidth: ContentWidth.STANDARD,
    wrapper: ModalEditWrapper,
};

export default function (config: FeatureConfiguration) {
    config.reducers.promotions = reducer;

    config.addRoutes(config.routes.locationRoutes, [
        <ModalCrudRouteContainer
            name="promotions"
            key="promotions"
            listComponent={List}
            editComponents={[Edit]}
            listWidth={22}
            section={null}
        />,
    ]);
}
