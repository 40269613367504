import { MinusOutlined, ApartmentOutlined } from "@ant-design/icons";
import { ColumnProps, TablePaginationConfig } from "antd/lib/table";
import { LocationLocale } from "features/location/types/LocationLocale";
import memoizeOne from "memoize-one";
import { TableListing } from "common/scaffolding/components/TableListing/TableListing";
import { CatalogueItem } from "../../catalogue/types/CatalogueProduct";
import { useEffect, useState, useCallback, ReactNode } from "react";
import { LocationLocaleContext } from "features/location";
import { RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";
import { filterSearchable, MatchCallback, MatchText } from "common/utility/filterSearchable";
import { ExtendedPermissions } from "features/location/types/ExtendedPermissions";
import { Image } from "common/scaffolding/components/Image";
import { FEATURE_CONFIG } from "common/feature-variables";

export interface Props {
    items: CatalogueItem[];
    permissions: ExtendedPermissions;
    showCategories?: boolean;
    pageSize?: number;
    parentRecordText: string;
    textFilter?: string;
    categoryFilters?: string[];
    displayImages?: boolean;
    setPagingAvailable: (hasPaging: boolean) => void;
}

const rowClassName = (record: any) => (record.status === 3 ? "missing-content" : "");

const matchCatalogueItem: MatchCallback<CatalogueItem> = (item: CatalogueItem, matchText: MatchText) =>
    matchText(item.sku);

export const CatalogueListing = ({
    items,
    showCategories = true,
    pageSize = 25,
    history,
    match,
    parentRecordText,
    textFilter = "",
    categoryFilters,
    displayImages,
    setPagingAvailable,
}: Props & RouteComponentProps<any, StaticContext, any>) => {
    const [datasource, setDatasource] = useState<CatalogueItem[]>(items);
    const [expandedKeys, setExpandedKeys] = useState<string[]>(items.slice(0, pageSize).map((i) => i.id!));
    const bindColumns = memoizeOne((locale: LocationLocale) => {
        let columns: ColumnProps<CatalogueItem>[] = [
            {
                title: "Name",
                key: "displayName",
                render: (text, record) => (
                    <>
                        {record.parentId &&
                            (record.type === "modifier" ? (
                                <ApartmentOutlined className="child-icon" />
                            ) : (
                                <MinusOutlined className="child-icon" />
                            ))}{" "}
                        {record.displayName}
                        {record.internalName && <i> ({record.internalName})</i>}
                    </>
                ),
            },
            {
                title: "Price",
                dataIndex: "price",
                key: "price",
                className: "col-right num-field col-catalogue-price",
                render: (text, record) => <>{text && <>{locale.formatCurrency(text)}</>}</>,
            },
            {
                title: "SKU",
                dataIndex: "sku",
                key: "sku",
                className: "col-right num-field col-catalogue-sku",
                render: (text, record) => <>{record.sku}</>,
            },
        ];

        if (showCategories) {
            columns.splice(1, 0, {
                title: "Category",
                key: "categories",
                dataIndex: "categories",
                className: "truncate col-catalogue-category",
                render: (text, record) =>
                    record.categories && <>{record.categories.map((c) => c.displayName).join(", ")}</>,
            });
        }

        if (FEATURE_CONFIG.enableListingImages === "1" && displayImages) {
            columns.splice(0, 0, {
                title: "",
                key: "image",
                className: "col-catalogue-image",
                render: (text, record) =>
                    !record.parentId && <Image image={record.image || ""} type="thumb" alt={record.displayName} />,
            });
        }

        return columns;
    });

    const handlePage = (page: number) => {
        const ids = datasource.slice((page - 1) * pageSize, page * pageSize).map((i) => i.id!);
        setExpandedKeys(ids);
    };

    const applyFilters = (searchText: string, categories?: string[]) => {
        let filteredItems = items;
        searchText && (filteredItems = filterSearchable(items, searchText, true, undefined, matchCatalogueItem));
        filteredItems = filteredItems.reduce((result, existingItem) => {
            if (!categories || matchesCategories(existingItem, categories)) {
                result.push(existingItem);
            }
            return result;
        }, [] as CatalogueItem[]);

        setDatasource(filteredItems);
        setPagingAvailable(pageSize < filteredItems.length);
        const ids = filteredItems.slice(0, pageSize).map((i) => i.id!);
        setExpandedKeys(ids);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const applyFiltersCallback = useCallback(applyFilters, []);

    useEffect(() => {
        applyFiltersCallback(textFilter, categoryFilters);
    }, [items, textFilter, categoryFilters, applyFiltersCallback]);

    function matchesCategories(item: CatalogueItem, categories: string[]) {
        if (!categories.length) {
            return true;
        }

        if (!item.categories || !item.categories.length) {
            return categories.includes("unassigned");
        }

        return item.categories.find((c) => categories.indexOf(c.id) !== -1) !== undefined;
    }

    const paginationSettings: TablePaginationConfig = {
        size: "default",
        position: ["bottomCenter"],
        pageSize: pageSize,
        showSizeChanger: false,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        itemRender: (
            page: number,
            type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
            originalElement: ReactNode
        ) => <div className="pagination-wrapper">{originalElement}</div>,
    };
    return (
        <>
            <LocationLocaleContext.Consumer>
                {(locale) => (
                    <TableListing
                        columns={bindColumns(locale)}
                        dataSource={datasource}
                        keyField="key"
                        col={24}
                        className="catalogue-table"
                        pagination={paginationSettings}
                        expandedKeys={expandedKeys}
                        emptyText={`No ${parentRecordText}s to display`}
                        onPageChange={handlePage}
                        rowClassName={rowClassName}
                    />
                )}
            </LocationLocaleContext.Consumer>
        </>
    );
};
