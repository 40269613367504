import * as Yup from "yup";
import { EditableTaxClass, TaxClassAssignment } from "../types";

export interface EditableTaxClassValidationContext extends EditableTaxClass {
    validatePos: boolean;
}

const editableTaxClassAssignmentSchema = Yup.array().of(
    Yup.object<TaxClassAssignment>().shape({
        targetType: Yup.string(),
        targetId: Yup.string(),
    })
);

export const editableTaxSchema = Yup.object<EditableTaxClass>().shape({
    id: Yup.string(),
    displayName: Yup.string().max(32, "Name must be less than 33 characters").required("Name is required"),
    posTaxId: Yup.string()
        .nullable(true)
        .when("$validatePos", {
            is: true,
            then: Yup.string().required("Tax ID is required"),
            otherwise: Yup.string().nullable(true),
        }),
    taxType: Yup.string().required("Type is required"),
    taxRate: Yup.number()
        .required("Tax rate is required")
        .min(0.01, "Tax rate must be at least 0.01%")
        .max(100, "Value cannot exceed 100%"),
    applyToAll: Yup.boolean(),
    taxAssignments: editableTaxClassAssignmentSchema.test("assignments", "No items selected", function (value) {
        const context: EditableTaxClassValidationContext = this.options.context as EditableTaxClassValidationContext;
        const { applyToAll, taxAssignments } = context || {};

        if (applyToAll) {
            return true;
        }

        return taxAssignments.length > 0;
    }),
    exclusive: Yup.boolean(),
});
