import { opsApi } from "API";
import { MembershipLevelSummary } from "..";

export const listMembershipLevels = async (location: string) => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { location });
    return res.data.membershipLevels;
};

type GraphQLPayload = { membershipLevels: MembershipLevelSummary[] };

const query = `
    query($location: ID!) {
        membershipLevels(locationId: $location) {
            id,
            displayName,
            posId
        }
    }
`;
