import { createAction } from "../reducers/edit";
import { createAction as listActions } from "features/location/reducers/list";
import { LocationRelations } from "../types";
import { scaffoldSaveAction } from "common/scaffolding/actions/scaffoldSaveAction";
import { ActiveLocation } from "features/location";
import { saveLocationRelations } from "../api/saveLocationRelations";
import { fetchActive } from "features/location/actions";

export const save = (location: ActiveLocation, locationRelations: LocationRelations) =>
    scaffoldSaveAction(
        (state) => state.locations.active,
        createAction,
        async (locationId: string | undefined, dispatch) => {
            if (!locationId) return null;
            await saveLocationRelations(locationId, locationRelations);
            await dispatch(fetchActive(locationId, true));
            return null;
        },
        undefined,
        false,
        listActions.reload,
        undefined
    );
