import styles from "./ListPromotions.module.scss";
import { CrudPermissions } from "features/location/types/createCrudPermissions";
import { LocaleComponentProps, LocationComponentProps } from "features/location";
import { RouteComponentProps, useLocation } from "react-router";
import { Promotion } from "../types";
import { EmptyPromotions } from "./EmptyPromotions";
import { Button } from "core/components/button";
import { Link } from "react-router-dom";
import { PageHeader } from "core/components/pageHeader";
import { PromotionSummary } from "./PromotionSummary";
import { Card } from "core/components/card";

export interface Props {
    promotions: Promotion[];
    permissions: CrudPermissions;
}

export const ListPromotionsPage = ({
    locale,
    promotions,
    match,
    permissions,
    history,
}: Props & LocationComponentProps & LocaleComponentProps & RouteComponentProps<any>) => {
    const { canCreate, canUpdate } = permissions || {};
    const location = useLocation();

    return promotions?.length ? (
        <div className={styles.container}>
            <PageHeader
                title="Promotions"
                actions={
                    <Button as={Link} to={`${location.pathname}/new`} disabled={!canCreate}>
                        Add promotion
                    </Button>
                }
            />
            <Card as="ul" className={styles.list}>
                {promotions.map((promotion, index) => (
                    <PromotionSummary key={promotion.id} promotion={promotion} canUpdate={canUpdate} index={index} />
                ))}
            </Card>
        </div>
    ) : (
        <EmptyPromotions />
    );
};
