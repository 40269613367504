import { FeatureConfiguration } from "features";
import { withPermissions } from "features/location/components/withPermission";
import { ListTaxesPageContainer } from "./containers/ListTaxesPageContainer";
import { EditTaxPageContainer } from "./containers/EditTaxPageContainer";
import { reducer } from "./reducers";
import * as actions from "./actions";

import { EditComponent, ModalCrudRouteContainer, ListComponent } from "common/scaffolding/components/ModalCrudRoute";

export { actions };

const List: ListComponent = {
    route: "",
    component: withPermissions(ListTaxesPageContainer, "taxes:read"),
};

const Edit: EditComponent = {
    route: ":id",
    editTitle: "Edit Tax",
    createTitle: "Add Tax",
    component: withPermissions(EditTaxPageContainer, "taxes:update"),
};

export default function (config: FeatureConfiguration) {
    config.reducers.taxes = reducer;

    config.addRoutes(config.routes.locationRoutes, [
        <ModalCrudRouteContainer
            name="taxes"
            key="taxes"
            listComponent={List}
            editComponents={[Edit]}
            listWidth={22}
        />,
    ]);
}
