import { Formik, FastField } from "formik";
import { EditRow } from "common/scaffolding/components/EditRow";
import { FieldErrors, ImageUpload, TextInput, RadioButtonGroup } from "components/forms";
import { Form } from "antd";
import { BrandDetails, Branding, editableBrandingSchema } from "..";
import { RadioChangeEvent } from "antd/lib/radio";

import "./EditBrandingPage.scss";
import { SaveStatus } from "common/loader";
import { ActionFooter, StatusMessage } from "core/components/actionFooter";
import { Button } from "core/components/button";
import { ScrollToFormikError } from "components/forms/ScrollToFormikError";

export interface Props {
    saveStatus: SaveStatus;
    onSubmit: (data: Branding) => void;
    meanduColor: string;
    initialValues: BrandDetails;
    menuBackgroundImageEnabled: boolean;
}

const customColorOptions = [
    { text: "Yes", value: true },
    { text: "No", value: false },
];

export const EditBrandingPage = ({
    saveStatus,
    onSubmit,
    initialValues,
    meanduColor,
    menuBackgroundImageEnabled,
}: Props) => {
    return (
        <div className="edit-brand">
            <Formik
                validationSchema={editableBrandingSchema}
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={onSubmit}
                key={"edit-barding"}
            >
                {(form) => (
                    <Form onFinish={form.handleSubmit}>
                        <ScrollToFormikError />
                        <EditRow
                            title="Custom Venue Colour"
                            subTitle={"Use a custom colour instead of the me&u colour"}
                        >
                            <FieldErrors name="useCustomColor" form={form}>
                                <FastField
                                    name="useCustomColor"
                                    component={RadioButtonGroup}
                                    onChange={(e: RadioChangeEvent) => {
                                        if (e.target.value) {
                                            form.setFieldValue("themeColor", initialValues.themeColor);
                                        } else {
                                            form.setFieldValue("themeColor", meanduColor);
                                        }
                                    }}
                                    options={customColorOptions}
                                />
                            </FieldErrors>
                        </EditRow>
                        <EditRow title="Theme color" subTitle={"Select venue colour"}>
                            <FieldErrors name="themeColor" form={form}>
                                {!form.values.useCustomColor ? (
                                    <FastField
                                        name="themeColor"
                                        component={TextInput}
                                        type="color"
                                        label="Choose theme color"
                                        disabled
                                    />
                                ) : (
                                    <FastField
                                        name="themeColor"
                                        component={TextInput}
                                        type="color"
                                        label="Choose theme color"
                                    />
                                )}
                            </FieldErrors>
                            <div className="edit-brand__theme-text-color">{form.values.themeColor}</div>
                        </EditRow>
                        <EditRow
                            title="Logo"
                            onDelete={form.values.logoUrl ? () => form.setFieldValue("logoUrl", "") : undefined}
                            subTitle={"Upload an svg as the venue logo"}
                        >
                            <FieldErrors name="logoUrl" form={form}>
                                <FastField
                                    name="logoUrl"
                                    component={ImageUpload}
                                    className="small-upload-image"
                                    label="Click to upload venue logo"
                                />
                            </FieldErrors>
                        </EditRow>
                        {menuBackgroundImageEnabled && (
                            <EditRow
                                title="Menu Background Image"
                                onDelete={
                                    form.values.menuBackgroundImage
                                        ? () => form.setFieldValue("menuBackgroundImage", "")
                                        : undefined
                                }
                                subTitle={
                                    <p>
                                        Min: 1600px high
                                        <br />
                                        Must be .png or .svg
                                        <br />
                                        Maximum size: 1MB
                                    </p>
                                }
                            >
                                <FieldErrors name="menuBackgroundImage" form={form}>
                                    <FastField
                                        name="menuBackgroundImage"
                                        component={ImageUpload}
                                        label="Click to upload the menuBackgroundImage"
                                    />
                                </FieldErrors>
                            </EditRow>
                        )}
                        <EditRow
                            title="Hero Image"
                            subTitle={
                                <p>
                                    Min: 800px wide
                                    <br />
                                    Min: 550px high
                                    <br />
                                    Must be .png or .jpg
                                    <br />
                                    Maximum size: 8MB
                                </p>
                            }
                        >
                            <FieldErrors name="heroImage" form={form}>
                                <FastField
                                    name="heroImage"
                                    component={ImageUpload}
                                    className="small-upload-image"
                                    label="Click to upload the heroImage"
                                />
                            </FieldErrors>
                        </EditRow>
                        <EditRow
                            title="Background Image"
                            onDelete={
                                form.values.backgroundImage
                                    ? () => form.setFieldValue("backgroundImage", "")
                                    : undefined
                            }
                            subTitle={
                                <p>
                                    Min: 1600px high
                                    <br />
                                    Must be .png or .jpg
                                    <br />
                                    Maximum size: 8MB
                                </p>
                            }
                        >
                            <FieldErrors name="backgroundImage" form={form}>
                                <FastField
                                    name="backgroundImage"
                                    component={ImageUpload}
                                    label="Click to upload the backgroundImage"
                                />
                            </FieldErrors>
                        </EditRow>

                        <EditRow
                            title="Default Image"
                            subTitle={
                                <span>
                                    Used for products and categories with no image set
                                    <br />
                                    800x560
                                    <br />
                                    Must be .jpg, .png
                                    <br />
                                    Maximum size: 4MB
                                </span>
                            }
                        >
                            <FieldErrors name="defaultCatalogueImage" form={form}>
                                <FastField
                                    name="defaultCatalogueImage"
                                    component={ImageUpload}
                                    label="Click to upload image"
                                />
                            </FieldErrors>
                        </EditRow>

                        <ActionFooter>
                            <StatusMessage />
                            <Button loading={saveStatus === "saving"} type="submit" disabled={!form.dirty}>
                                Save
                            </Button>
                        </ActionFooter>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
