import { EditableCategory } from "../types";
import { createAction } from "../reducers/edit";
import { upload } from "API/upload";
import { scaffoldSaveAction } from "../../../common/scaffolding/actions/scaffoldSaveAction";
import { CategoryInputModel, saveCategory } from "../api/saveCategory";
import { mapListItem } from "./list";
import { actions as catalogueActions } from "features/catalogue";

export const save = (
    location: string,
    editableCategory: EditableCategory,
    clone: boolean = false,
    refreshMenuItems = false
) =>
    scaffoldSaveAction(
        (state) => state.categories.edit,
        createAction,
        async (categoryId: string | undefined, dispatch) => {
            const categoryInputModel = await mapInputModel(editableCategory, location);
            const result = await saveCategory(location, categoryId, categoryInputModel);

            if (refreshMenuItems) {
                await dispatch(catalogueActions.listProducts(location, true));
            }

            return mapListItem(result);
        },
        undefined,
        clone
    );

const mapInputModel = async (
    { internalName, displayName, type, services, menus, menuItems, image, special, showFromPrice }: EditableCategory,
    location: string
): Promise<CategoryInputModel> => ({
    internalName,
    displayName,
    type,
    services,
    menus,
    items: menuItems,
    image: image && typeof image !== "string" ? await upload(image as File, location, "category") : image,
    special,
    showFromPrice,
});
