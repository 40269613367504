import { createAction } from "../reducers/edit";
import { scaffoldEditAction } from "../../../common/scaffolding/actions/scaffoldEditAction";
import { editHyperwalletUser, GraphQLHyperwalletUser } from "../api/editHyperwalletUser";
import { HyperwalletUser } from "../types/HyperwalletUser";

export const edit = (slug: string) =>
    scaffoldEditAction(
        slug,
        (state) => state.paymentUser.edit,
        createAction,
        async () => {
            const location = await editHyperwalletUser(slug);
            return mapHyperwalletUser(location);
        }
    );

const mapHyperwalletUser = (input: GraphQLHyperwalletUser): HyperwalletUser => ({
    businessName: input.businessName,
    firstName: input.firstName,
    lastName: input.lastName,
    email: input.email,
    phoneNumber: input.phoneNumber,
    isNew: input.isNew || false,
});
