import { createAction } from "features/catalogue/reducers/product/edit";
import { scaffoldArchiveAction } from "common/scaffolding/actions/scaffoldArchiveAction";
import { archiveProduct as archiveProductAPI } from "features/catalogue/api/archiveProduct";
import { AppDispatch } from "features";
import { History } from "history";
import { setLastItemUpdated } from "features/location/actions/setLastItemUpdated";

export const archiveProduct = (locationId: string, region: string, history: History) =>
    scaffoldArchiveAction(
        (state) => state.menuItems.edit,
        createAction,
        (id) => archiveProductAPI(locationId, id),
        (dispatch: AppDispatch) => {
            dispatch(setLastItemUpdated());
            history.replace(`/${region}/${locationId}/menu/catalogue/products`);
            return;
        }
    );
