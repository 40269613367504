import { DAYS_OF_WEEK } from "common/utility/dateUtils";
import { scaffoldEditAction } from "common/scaffolding/actions/scaffoldEditAction";
import { isNew } from "common/utility/modelUtils";
import { createAction } from "../reducers/edit";
import {
    EditablePromotion,
    mapGraphQLPromotionToEditablePromotion,
    PromotionDiscountType,
    PromotionType,
} from "../types";
import { editPromotion } from "../api";

export const edit = (locationId: string, id: string) =>
    scaffoldEditAction(
        id,
        (state) => state.promotions.edit,
        createAction,
        async () => {
            if (isNew(id)) {
                return newEditablePromotion();
            }

            const promotion = await editPromotion(locationId, id);

            return mapGraphQLPromotionToEditablePromotion(promotion);
        }
    );

const newEditablePromotion = (): EditablePromotion => {
    return {
        id: "new",
        applyToAll: true,
        displayName: "",
        internalName: "",
        posId: "",
        code: "",
        generatedCodeCount: "",
        promotionType: PromotionType.SINGLECODE,
        discount: {
            type: PromotionDiscountType.PERCENTAGE,
            value: "",
        },
        priceListId: "",
        hasUniqueCode: true,
        restrictions: {
            minSpend: "",
            maxMemberUsage: 1,
            maxCodeUsage: 1,
            requiredItems: null,
            schedule: {
                startDate: "", // default value (venue's current date) provided by EditPromotionPage
                endDate: null,
                activeDaysOfWeek: DAYS_OF_WEEK,
                dailyStartTime: 0,
                dailyEndTime: 2400,
                specificInactiveDates: [],
            },
        },
        usageCount: 0,
        memberUsage: "limited",
        codeUsage: "unlimited",
    };
};
