import styles from "./EditModifierOptions.module.scss";

import { ReactElement, useState } from "react";
import { ArrayHelpers, FormikProps, Field } from "formik";
import { EditableModifier } from "features/modifier/types";
import { LocationLocaleContext } from "features/location";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { Row } from "core/components/card";
import { FieldErrors } from "core/components/form/fieldErrors";
import { Button } from "core/components/button";
import { Icon } from "core/components/icon/Icon";
import { Input } from "core/components/form/input";
import { buildInputRegex } from "core/components/form/InputNumber";
import { CancelBig } from "common/icons";
import { NestedModifiers } from ".";
import { Nested } from "components/icons";
import { EditNestedModifiers } from ".";
import { useSelector } from "react-redux";
import { getPosSyncEnabled } from "features/catalogue/selectors/getPosSyncEnabled";
import { ItemValidationStatus } from "features/catalogue";
import { PosFieldSyncStatus } from "features/catalogue/components/product/edit/PosFieldSyncStatus";

export interface Props {
    name: string;
    form: FormikProps<EditableModifier>;
    disabled: boolean;
    disableLinkedFields: boolean;
    isNested?: boolean;
    energyContentSupported?: boolean;
}
export const priceInputTest = buildInputRegex({ numDigits: 6, numDecimals: 2, allowNegative: false });

export const EditModifierOptions = (props: Props & ArrayHelpers): ReactElement => {
    const { isNested, remove, form, disabled, disableLinkedFields, energyContentSupported } = props;

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;
        props.move(result.source.index, result.destination.index);
    };

    const onBeforeDragStart = () => {
        (document.activeElement as any)?.blur();
    };

    const {
        form: {
            values: { options },
        },
    } = props;

    return (
        <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onBeforeDragStart}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} className={styles.container}>
                        {options.map((option, index) => (
                            <EditModifierOption
                                key={`${index}`}
                                index={index}
                                pathPrefix={`options.${index}`}
                                hasMultiple={form.values.options.length > 1}
                                isNested={isNested}
                                onRemove={remove}
                                disabled={disabled}
                                disableLinkedFields={disableLinkedFields}
                                form={form}
                                energyConentSupported={energyContentSupported}
                                syncStatus={option.validationStatus}
                            />
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

interface EditModifierOptionProps {
    pathPrefix: string;
    index: number;
    onRemove: (index: number) => void;
    hasMultiple: boolean;
    isNested?: boolean;
    disabled?: boolean;
    disableLinkedFields: boolean;
    form: FormikProps<EditableModifier>;
    energyConentSupported?: boolean;
    syncStatus: ItemValidationStatus | undefined;
}

export const EditModifierOption = ({
    pathPrefix,
    hasMultiple,
    disabled,
    disableLinkedFields,
    index,
    isNested,
    onRemove,
    form,
    energyConentSupported,
    syncStatus,
}: EditModifierOptionProps): ReactElement => {
    const [editNestedVisible, setNestedModalVisible] = useState<boolean>(false);

    const layoutVariation = energyConentSupported ? 2 : 1;

    const posSyncEnabled = useSelector(getPosSyncEnabled);

    return (
        <>
            <Draggable draggableId={index.toString()} index={index} isDragDisabled={disabled || disableLinkedFields}>
                {(provided, snapshot) => (
                    <div
                        className={snapshot.isDragging ? styles.containerDragging : styles.container}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={provided.draggableProps.style}
                    >
                        <Row background="default">
                            <FieldErrors
                                fieldNames={["displayName", "sku", "price", "energyContent"].map(
                                    (name) => `${pathPrefix}.${name}`
                                )}
                            >
                                <div className={styles["fieldsContainer--" + layoutVariation]}>
                                    <div className={styles.fieldContainerBig}>
                                        <Field
                                            name={`${pathPrefix}.displayName`}
                                            component={Input}
                                            placeholder="Name"
                                            disabled={disabled || disableLinkedFields}
                                        />
                                    </div>

                                    <div className={styles.fieldContainerStandard}>
                                        <Field
                                            after={
                                                posSyncEnabled && syncStatus ? (
                                                    <PosFieldSyncStatus syncStatus={syncStatus} />
                                                ) : null
                                            }
                                            name={`${pathPrefix}.sku`}
                                            component={Input}
                                            placeholder="SKU"
                                            disabled={disabled || posSyncEnabled}
                                        />
                                    </div>

                                    <LocationLocaleContext.Consumer>
                                        {(locale) => (
                                            <div
                                                className={
                                                    energyConentSupported
                                                        ? styles.fieldContainerStandard
                                                        : styles.fieldContainerStandardLast
                                                }
                                            >
                                                <Field
                                                    after={
                                                        posSyncEnabled && syncStatus ? (
                                                            <PosFieldSyncStatus syncStatus={syncStatus} />
                                                        ) : null
                                                    }
                                                    name={`${pathPrefix}.price`}
                                                    component={Input}
                                                    before={
                                                        <span className={styles.subtle}>{locale.currencySymbol}</span>
                                                    }
                                                    placeholder="Price"
                                                    inputTest={priceInputTest}
                                                    parseValue={(x: string) => Number(x || 0).toFixed(2)}
                                                    maxLength={6}
                                                    disabled={disabled || disableLinkedFields || posSyncEnabled}
                                                />
                                            </div>
                                        )}
                                    </LocationLocaleContext.Consumer>

                                    {energyConentSupported && (
                                        <div className={styles.fieldContainerStandardLast}>
                                            <Field
                                                name={`${pathPrefix}.energyContent`}
                                                component={Input}
                                                disabled={disabled || disableLinkedFields}
                                                inputTest={buildInputRegex({
                                                    numDigits: 5,
                                                })}
                                                pattern="\d*"
                                                after="kcal"
                                                afterClassName={styles.subtle}
                                            />
                                        </div>
                                    )}

                                    <div className={styles.fieldContainerFixed}>
                                        <Button
                                            role="secondary"
                                            padding="icon"
                                            type="button"
                                            disabled={isNested || disabled || disableLinkedFields}
                                            onClick={() => {
                                                setNestedModalVisible(true);
                                            }}
                                        >
                                            <Icon size="tiny">
                                                <Nested />
                                            </Icon>
                                        </Button>
                                    </div>

                                    {hasMultiple && (
                                        <div className={styles.fieldContainerFixed}>
                                            <Button
                                                role="secondary"
                                                padding="icon"
                                                disabled={disabled || disableLinkedFields}
                                                onClick={() => onRemove(index)}
                                                type="button"
                                            >
                                                <Icon size="tiny">
                                                    <CancelBig />
                                                </Icon>
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </FieldErrors>
                        </Row>

                        <NestedModifiers key={index} pathPrefix={pathPrefix} form={form} disabled={disabled} />
                    </div>
                )}
            </Draggable>

            <EditNestedModifiers
                pathPrefix={pathPrefix}
                form={form}
                disabled={disabled}
                visible={editNestedVisible}
                onClose={() => {
                    setNestedModalVisible(false);
                }}
            />
        </>
    );
};
