import styles from "./SelectDemoExpiry.module.scss";

import { ReactElement, useMemo } from "react";
import { EditableSection } from "features/section/types";
import { calculateTimeToLive } from "features/section/helper";
import { Select } from "core/components/form/select";

interface Props {
    disabled: boolean;
    name: string;
    initialValues: EditableSection;
}

const getOptionDisplayText = (option: number) => {
    switch (option) {
        case 0.5:
            return "30 minutes";
        case 1:
            return "1 hour";
        case 2:
            return "2 hours";
    }
    return "Next dining day";
};

const disableOptions = [0.5, 1, 2, 0].map((option) => {
    return { label: `${getOptionDisplayText(option)}`, value: option };
});

const rtf1 = new Intl.RelativeTimeFormat("en", { style: "narrow" });

export const SelectDemoExpiry = (props: Props): ReactElement => {
    const displayTimeToActive = useMemo(() => {
        const timeDiff = calculateTimeToLive(props.initialValues.demoQr, 60);
        if (timeDiff) {
            if (timeDiff < 60) {
                return rtf1.format(timeDiff, "minutes");
            }
            const hours = Math.round(timeDiff / 60);
            if (hours > 0) {
                return rtf1.format(hours, "hours");
            }
        }
        return null;
    }, [props.initialValues]);
    return (
        <>
            <Select {...props} placeholder="Set an expiry" isClearable options={disableOptions} />
            {displayTimeToActive && <div className={styles.selectedDemoExpiry}>Expires {displayTimeToActive}</div>}
        </>
    );
};
