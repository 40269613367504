import { Card, Row, Title } from "core/components/card";
import { FieldErrors } from "core/components/form/fieldErrors";
import { Input } from "core/components/form/input";
import { Label } from "core/components/form/input/Label";
import { Select } from "core/components/form/select";
import { getSectionOptions } from "features/takeaway/selectors";
import { EditableTakeawayOptions } from "features/takeaway/types";
import { Field, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import styles from "./TakeawayPage.module.scss";

interface Props {
    disabled: boolean;
}

export const TakeawayPageConfigurationCard = ({ disabled }: Props) => {
    const { values } = useFormikContext<EditableTakeawayOptions>();
    const sectionOptions = useSelector(getSectionOptions) || [];

    return (
        <Card>
            <Row collapse="down">
                <Title title="Configuration" />
            </Row>
            <Row>
                <div>
                    <h3 className="text-subheading">Order lead time</h3>
                    <div>Set up a buffer of time before a takeaway order can be collected.</div>
                </div>
            </Row>
            <Row collapse="up">
                <FieldErrors fieldNames={["minReadyTimeMins"]}>
                    <Label label="Minimum order lead time" name="minReadyTimeMins" markRequired />
                    <div className={styles.orderLeadTime}>
                        <Field
                            name="minReadyTimeMins"
                            component={Input}
                            type="number"
                            disabled={disabled}
                            maxLength={4}
                            after="minutes"
                        />
                    </div>
                </FieldErrors>
            </Row>
            <Row border="top">
                <div>
                    <h3 className="text-subheading">Assign a section</h3>
                    <div>Only one section can be linked to your takeaway service.</div>
                </div>
            </Row>
            <Row collapse="up">
                <FieldErrors fieldNames={["sectionId"]}>
                    <Field
                        name="sectionId"
                        component={Select}
                        label="Select a section"
                        markRequired
                        blurInputOnSelect={false}
                        className={styles.selectMid}
                        placeholder="Select a section"
                        options={sectionOptions}
                        search={true}
                        isClearable={!values.enabled}
                        disabled={disabled}
                    />
                </FieldErrors>
            </Row>
        </Card>
    );
};
