import * as Yup from "yup";
import { EditableNewLocation } from "../types/EditableNewLocation";
import { querySlugAvailable } from "../api/querySlugAvailable";

const slugSchema = Yup.string()
    .min(5, "At least 5 characters")
    .matches(/^[a-z0-9-]{4,}$/, "Lowercase, numbers, and hyphens only")
    .matches(/^[a-z]/, "Must start with a letter")
    .matches(/[^-]$/, "Cannot end with a hyphen");

export const editableNewLocationSchema = Yup.object<EditableNewLocation>().shape({
    displayName: Yup.string().required("Name is required"),
    slug: slugSchema
        .test("slugAvailable", "Not available", async (value: string) => {
            // abort test if no value, return true to let required rule handle it
            if (!value) {
                return true;
            }
            // only query slug availability if other rules are satisfied
            if (await slugSchema.isValid(value)) {
                return querySlugAvailable(value);
            }
            return true;
        })
        .required("Slug is required"),
    timeZone: Yup.string().required("Time zone is required"),
    locale: Yup.string().required("Currency is required"),
    clone: Yup.boolean(),
    isLiveOrdersEnabled: Yup.boolean(),
    newGroupName: Yup.string().when("groupId", (groupId: string, schema: Yup.StringSchema) => {
        return groupId === "new" ? schema.required("Group name is required") : schema.notRequired();
    }),
    createAsBrand: Yup.boolean().nullable(true),
    orderFlow: Yup.mixed().required("Order Flow is required"),
});
