import styles from "./TextArea.module.scss";
import { ChangeEvent, forwardRef, useEffect, useState } from "react";
import { FieldProps, FormikProps, getIn } from "formik";
import classNames from "classnames";
import { Label } from "../input/Label";

export type TextAreaProps = Omit<React.HTMLProps<HTMLTextAreaElement>, "onChange">;

type ResetConfig = { value: boolean; touched: boolean; error: boolean };
export interface Props {
    autoReset?: boolean | ResetConfig;
    className?: string;
    disabled?: boolean;
    form?: FormikProps<any>;
    formatOnChange?: (value: string) => string;
    inputTest?: RegExp;
    label?: string;
    markRequired?: boolean;
    name?: string;
    onBlur?: (event: FocusEvent) => void;
    onChange?: (value: string, event: ChangeEvent<HTMLTextAreaElement>) => boolean;
    onFocus?: (event: FocusEvent) => void;
    parseValue?: (value: string) => any;
    trim?: boolean;
    value?: string;
    masked?: {
        mask: (val?: string) => string | undefined;
        unmask: (val: string) => string;
    };
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps & Props & Partial<FieldProps<any>>>(
    (
        {
            autoReset = true,
            className,
            disabled = false,
            field,
            form,
            formatOnChange,
            inputTest,
            label,
            markRequired = false,
            meta,
            name,
            onBlur,
            onChange,
            onFocus,
            parseValue = (x) => x,
            trim = true,
            value,
            masked,
            ...rest
        },
        ref
    ) => {
        const [focused, setFocused] = useState<boolean>(false);
        const { setFieldValue, setFieldTouched, setFieldError, touched, errors } = form || {};
        const { onBlur: fieldOnBlur } = field || {};

        name = field ? field.name : name;
        value = field ? field.value : value;

        if (masked) {
            value = masked.mask(value);
        }

        const hasError = name && getIn(errors, name) && getIn(touched, name);

        // reset form state if field unmounted?
        useEffect(
            () => () => {
                const { value, error, touched } =
                    typeof autoReset === "object"
                        ? autoReset
                        : {
                              value: autoReset,
                              error: autoReset,
                              touched: autoReset,
                          };

                value && field?.name && setFieldValue?.(name!, undefined);
                touched && field?.name && setFieldTouched?.(name!, false);
                error && field?.name && setFieldError?.(name!, undefined);
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            []
        );

        const textareaClasses = classNames({
            [styles.textarea]: !disabled && !focused,
            [styles.textareaFocused]: focused,
            [styles.textareaDisabled]: disabled || rest.readOnly,
            [styles.textareaError]: hasError,
            "input--error": hasError,
        });

        return (
            <div className={hasError ? styles.containerError : styles.container}>
                {(label || markRequired) && <Label label={label} markRequired={markRequired} name={name} />}
                <textarea
                    className={classNames(styles.textarea, className, textareaClasses)}
                    {...(name ? { "data-testid": `textarea-${name}` } : null)}
                    disabled={disabled}
                    name={name}
                    onFocus={(e) => {
                        onFocus?.(e);
                        setFocused(true);
                    }}
                    onChange={(e) => {
                        const val = masked ? masked.unmask(e.target.value) : e.target.value;
                        if (inputTest && !inputTest.test(val)) return;

                        const value = formatOnChange ? formatOnChange(val) : val;

                        if (onChange) {
                            const proceed = onChange(value, e);
                            if (proceed === false) {
                                return;
                            }
                        }

                        field?.name && setFieldValue?.(name!, value);
                    }}
                    onBlur={(e) => {
                        let value = trim ? e.target.value.trim() : e.target.value;
                        value = masked ? masked.unmask(value) : value;
                        setFocused(false);
                        field?.name && setFieldValue?.(name!, parseValue(value));
                        fieldOnBlur?.(e);
                        onBlur?.(e);
                    }}
                    ref={ref}
                    value={value ?? undefined}
                    {...rest}
                />
            </div>
        );
    }
);
