import styles from "./EditWrapper.module.scss";

import { PropsWithChildren, useRef, useState } from "react";
import useResizeObserver from "use-resize-observer";
import { EditWrapperProps } from "../ModalCrudRoute";
import classNames from "classnames";
import { motion } from "framer-motion";
import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { scrollClass } from "./EditWrapper";

const transition = {
    duration: 0.2,
    ease: "easeIn",
};

const variants = {
    open: {
        opacity: 1,
        transition,
    },
    closed: {
        opacity: 0,
        transition,
    },
};

export const ModalEditWrapper = ({
    children,
    contentWidth = ContentWidth.STANDARD,
    visible,
}: PropsWithChildren<EditWrapperProps>) => {
    const [hasScroll, setHasScroll] = useState(false);

    const ref = useRef<HTMLDivElement>(null);

    useResizeObserver<HTMLDivElement>({
        ref,
        onResize: () => {
            const container = ref.current;
            if (container) {
                setHasScroll(container.scrollHeight > container.clientHeight);
            }
        },
    });

    return (
        <div className={styles.wrapper}>
            <motion.div
                className={styles.position}
                key="position"
                initial={false}
                animate={visible ? "open" : "closed"}
                exit="closed"
                variants={variants}
            >
                {visible && (
                    <div
                        data-scrollcontainer
                        className={classNames(styles.container, hasScroll && scrollClass)}
                        ref={ref}
                    >
                        <div className={classNames(styles.content, `content--${contentWidth}`)}>
                            <div key="children" className={styles.body}>
                                {children}
                            </div>
                        </div>
                    </div>
                )}
            </motion.div>
        </div>
    );
};
