import { RouteComponentProps, useParams } from "react-router";
import styles from "./Analytics.module.scss";

import { withPermissions } from "features/location/components/withPermission";
import { LocationComponentProps, withLocation } from "features/location";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { getDashboardUrl } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { PageError } from "core/components/pageError";
import { getAnalyticsLayoutConfig } from "features/location/selectors/getAnalyticsLayoutConfig";
import { Tabs, TabPanel } from "core/components/tabs";
import { AnalyticsTab } from "./AnalyticsTab";
import { createAction as trackingActions } from "common/appInsights/actions/track";

const analyticsTool = "metabase";

export interface Props {}

export interface AnalyticsPageParams {
    folderSlug: string;
    pageSlug: string;
}

export const AnalyticsPageComponent = ({
    restaurantLocation,
}: Props & LocationComponentProps & RouteComponentProps<any>) => {
    const dispatch = useDispatch();

    const { folderSlug, pageSlug } = useParams<AnalyticsPageParams>();

    const analyticsLayoutConfig = useSelector(getAnalyticsLayoutConfig);

    const lastFetchedId = useRef("");

    const page = useMemo(
        () =>
            analyticsLayoutConfig?.folders
                .find((folder) => folder.slug === folderSlug)
                ?.pages.find((page) => page.slug === pageSlug),
        [analyticsLayoutConfig, folderSlug, pageSlug]
    );

    const pageTabs = useMemo(() => page?.tabs || [], [page]);

    const fetch = useCallback(
        (id: string) => {
            lastFetchedId.current = id;
            if (id) {
                dispatch(getDashboardUrl(restaurantLocation.id, analyticsTool, id));
            }
        },
        [dispatch, restaurantLocation.id]
    );

    const trackTabChange = useCallback(
        (tabDisplayName: string) => {
            dispatch(
                trackingActions.track({
                    event: {
                        name: "ANALYTICS/TAB_CLICKED",
                    },
                    customProperties: {
                        current_page: pageSlug,
                        current_tab: tabDisplayName,
                    },
                })
            );
        },
        [dispatch, pageSlug]
    );

    const handleTabChange = useCallback(
        (tabIndex: number) => {
            if (pageTabs.length > 0) {
                const currentTab = pageTabs[tabIndex];
                fetch(currentTab.id);
                trackTabChange(currentTab.displayName);
            }
        },
        [fetch, pageTabs, trackTabChange]
    );

    // fetch the first tab whenever tabs changes
    useEffect(() => {
        if (pageTabs.length > 0) {
            handleTabChange(0);
        }
    }, [fetch, pageTabs, handleTabChange]);

    const handleDashboardFail = useCallback(() => {
        fetch(lastFetchedId.current);
    }, [fetch]);

    if (pageTabs.length === 0) {
        return (
            <PageError
                heading="Oh no! There was a problem loading Analytics"
                message="There was a technical problem that prevented me&u from loading this page. Try reloading this page or try again later."
            />
        );
    }

    return (
        <div className={styles.analyticsPage}>
            <Tabs initialIndex={0} onChange={handleTabChange}>
                {pageTabs.map((tab) => (
                    <TabPanel key={tab.id} id={tab.id} label={tab.displayName}>
                        <AnalyticsTab onRetry={handleDashboardFail} />
                    </TabPanel>
                ))}
            </Tabs>
        </div>
    );
};

export const AnalyticsPage = withPermissions(withLocation(AnalyticsPageComponent), "metabase:read");
