import { createAction as trackingActions } from "common/appInsights/actions/track";
import { RouteParams } from "common/appInsights/plugins";
import { config } from "common/config";
import useTimeoutToggle from "common/hooks/useTimeoutToggle";
import { downloadFile } from "common/utility/fileUtils";
import copy from "copy-to-clipboard";
import { Button } from "core/components/button";
import { Card, Row, Title } from "core/components/card";
import { Input } from "core/components/form/input";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

export const TakeawayPageSharingCard = () => {
    const { region, location: slug } = useParams<RouteParams>();
    const track = useTracker();
    const [downloading, setDownloading] = useState(false);
    const [showCopiedToClipboardMsg, toggleCopiedToClipboardMsg] = useTimeoutToggle();

    const takeawayUrl = `${config.REACT_APP_ORDER_APP_URL}/order/${slug}?r=${region}`;
    const qrCodeGeneratorUrl = `https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=${takeawayUrl}&choe=UTF-8`;

    const copyTakeawayUrl = () => {
        toggleCopiedToClipboardMsg();
        copy(takeawayUrl);
        track.qrUrlCopied();
    };

    const downloadQrCode = async () => {
        const timeout = setTimeout(() => setDownloading(true), 250);
        try {
            await downloadFile(qrCodeGeneratorUrl, `takeaway-${slug}.png`);
            track.qrCodeDownloaded();
        } finally {
            clearTimeout(timeout);
            setDownloading(false);
        }
    };

    return (
        <Card>
            <Row collapse="down">
                <Title title="Sharing" />
            </Row>
            <Row>
                <div>
                    <h3 className="text-subheading">Takeaway URL</h3>
                    <div>Use this URL to allow customers to access your takeaway service.</div>
                </div>
                <Button
                    type="button"
                    colorScheme="interactive"
                    onClick={copyTakeawayUrl}
                    active={showCopiedToClipboardMsg}
                >
                    {!showCopiedToClipboardMsg ? "Copy" : "Copied to clipboard"}
                </Button>
            </Row>
            <Row collapse="up">
                <Input value={takeawayUrl} disabled />
            </Row>
            <Row border="top">
                <Title title="QR code" />
                <Button type="button" colorScheme="interactive" onClick={downloadQrCode} loading={downloading}>
                    {downloading ? "Downloading" : "Download"}
                </Button>
            </Row>
        </Card>
    );
};

const useTracker = () => {
    const dispatch = useDispatch();
    return {
        qrUrlCopied: () => dispatch(trackingActions.track({ event: { name: "TAKEAWAY/QR_COPIED" } })),
        qrCodeDownloaded: () => dispatch(trackingActions.track({ event: { name: "TAKEAWAY/QR_DOWNLOADED" } })),
    };
};
