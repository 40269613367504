import { VenueAddress } from "features/location";
import * as Yup from "yup";

export const getVenueAddressSchema = () => {
    return Yup.object<VenueAddress>()
        .nullable(true)
        .shape({
            addressLine1: Yup.string().required("Street address is required."),
            addressLine2: Yup.string().nullable(true),
            city: Yup.string().required("Suburb/Town is required."),
            postcode: Yup.string().required("Postcode is required."),
            region: Yup.string().nullable(true),
            country: Yup.string().required("Country is required."),
            googleBusinessName: Yup.string().nullable(true),
        });
};
