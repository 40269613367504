import { CatalogueItem } from "../../catalogue/types/CatalogueProduct";
import { CatalogueListing } from "./CatalogueListing";
import { RouteComponentProps } from "react-router";
import { useState } from "react";
import { ExtendedPermissions } from "features/location/types/ExtendedPermissions";
import { Divider } from "antd";
import { CatalogueHeader } from "./CatalogueHeader";
import { FilterCategory } from "../../catalogue/types/FilterCategory";

export interface Props {
    products: CatalogueItem[];
    categories: FilterCategory[];
    permissions: ExtendedPermissions;
    handleSetPagingAvailable: (hasPaging: boolean) => void;
    exportData?: () => void;
}

const pageSize = 25;

export const ProductsTab = ({
    products,
    permissions,
    match,
    history,
    location,
    handleSetPagingAvailable,
    categories,
    exportData,
}: Props & RouteComponentProps<any>) => {
    const [categoryFilters, setCategoryFilters] = useState<string[]>([]);
    const [textFilter, setTextFilter] = useState<string>("");

    const handleSearch = (searchText: string) => {
        setTextFilter(searchText);
    };

    const handleCategorySelect = (categories: string[]) => {
        setCategoryFilters(categories);
    };

    return (
        <>
            <CatalogueHeader
                categories={categories}
                parentRecordText="product"
                onCategoryFilter={handleCategorySelect}
                onTextFilter={handleSearch}
                permissions={permissions}
                exportData={exportData}
            />
            <CatalogueListing
                items={products}
                permissions={permissions}
                pageSize={pageSize}
                match={match}
                history={history}
                location={location}
                parentRecordText="product"
                textFilter={textFilter}
                categoryFilters={categoryFilters}
                setPagingAvailable={handleSetPagingAvailable}
                displayImages
            />
            <Divider style={{ visibility: "hidden" }} />
        </>
    );
};
