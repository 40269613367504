import { EditableTaxClass, TaxClassAssignment } from "./types";

export const toPercentage = (input: number) => parseFloat((input * 100).toFixed(2));

export const fromPercentage = (input: number) => {
    if (input === null || input === undefined) {
        return 0;
    }

    input = typeof input === "string" ? parseFloat(input) : input;

    return input / 100;
};

export const equalTaxClasses = (initialValues: EditableTaxClass, values: EditableTaxClass): boolean => {
    const { taxAssignments, taxRate, internalName, ...rest } = initialValues;

    // non-special primitive checks
    for (const key of Object.keys(rest)) {
        if (initialValues[key] !== values[key]) {
            return false;
        }
    }

    // allow falsey internalName to be treated as equal
    const nameA = internalName ?? "";
    const nameB = values.internalName ?? "";

    if (nameA !== nameB) {
        return false;
    }

    // allow for different types when checking taxRates
    if (fromPercentage(taxRate!) !== fromPercentage(values.taxRate!)) {
        return false;
    }

    // cannot be equal if taxAssignments arrays are unequal lengths
    if (taxAssignments.length !== values.taxAssignments.length) {
        return false;
    }

    // create copies of assignments
    const taxAssignmentsA = [...taxAssignments];
    const taxAssignmentsB = [...values.taxAssignments];

    while (taxAssignmentsA.length) {
        // get an assignment from a
        const assignmentA = taxAssignmentsA.pop() as TaxClassAssignment;

        // find matching assignment in b
        const matchIndex = taxAssignmentsB.findIndex((value) => {
            return value.targetId === assignmentA.targetId;
        });

        // if we didn't find matching assignment in b bail
        if (matchIndex === -1) {
            return false;
        }

        // remove assignment from b
        const assignmentB = taxAssignmentsB.splice(matchIndex, 1)[0];

        // check types match (we already know targetIds do)
        if (assignmentA.targetType !== assignmentB.targetType) {
            return false;
        }
    }
    return true;
};
