import { PosFieldProps } from "../PosFieldProps";
import { PosLookupField } from "./PosLookupField";
import { PosTextField } from "./PosTextField";
import { PosFieldDefinition } from "features/location";
import { PosSecretField } from "./PosSecretField";
import { PosSwitchField } from "./PosSwitchField";
import { PosReferenceField } from "./PosReferenceField";

export const PosField = (props: PosFieldProps<PosFieldDefinition>) => {
    const { definition, ...otherProps } = props;

    if (definition.input === "lookup") {
        return <PosLookupField {...otherProps} definition={definition} />;
    }

    if (definition.input === "secret") {
        return <PosSecretField {...otherProps} definition={definition} />;
    }

    if (definition.input === "switch") {
        return <PosSwitchField {...otherProps} definition={definition} />;
    }

    if (definition.input === "reference") {
        return <PosReferenceField {...otherProps} definition={definition} />;
    }

    return <PosTextField {...otherProps} definition={definition} />;
};
