import styles from "./CatalogueWarnings.module.scss";

import { AlertStatus } from "core/components/alert";
import { CatalogueItem, CollectionName } from "../types";
import { createAction } from "features/catalogue/reducers/filters";
import { ReactNode, useCallback, useMemo } from "react";
import { getPosSyncEnabled } from "features/catalogue/selectors/getPosSyncEnabled";
import { simplePlural } from "common/utility/StringUtils";
import { Status } from "../types/StatusDictionary";
import { missingContentFilterOption, statusOptions, syncStatusOptions } from "./FiltersModal";
import { useDispatch, useSelector } from "react-redux";
import { getCollectionNameSingular } from "../selectors/getSelectionInfo";
import { AlertsList } from "core/components/alert/AlertsList";
import { getIsParentLocation } from "features/location/selectors/getIsParentLocation";

interface Props {
    collectionName: CollectionName;
    items: CatalogueItem[];
    itemsWithSyncFailed: CatalogueItem[];
    itemsWithSyncSuccess: CatalogueItem[];
}

export const CatalogueWarnings = ({ collectionName, items, itemsWithSyncFailed, itemsWithSyncSuccess }: Props) => {
    const dispatch = useDispatch();

    const posSyncEnabled = useSelector(getPosSyncEnabled);

    const isParentLocation = useSelector(getIsParentLocation);

    const handleMissingContentWarningClick = useCallback(() => {
        if (isParentLocation) {
            dispatch(createAction.setMissingContentFilter(missingContentFilterOption));
        } else {
            const option = statusOptions.find((option) => option.value === Status.MissingContent)!;
            dispatch(createAction.setStatusFilters([option]));
        }
    }, [dispatch, isParentLocation]);

    const handleSyncFailedWarningClick = useCallback(() => {
        const option = syncStatusOptions.find((option) => option.value === "failed")!;
        dispatch(createAction.setSyncStatusFilters([option]));
    }, [dispatch]);

    const itemName = getCollectionNameSingular(collectionName);

    const warnings = useMemo(() => {
        const warnings: ReactNode[] = [];

        if (posSyncEnabled && itemsWithSyncFailed.length) {
            warnings.push(
                <>
                    {itemsWithSyncSuccess.length} {itemName}
                    {simplePlural(itemsWithSyncSuccess.length)} synced successfully. {itemsWithSyncFailed.length}{" "}
                    {itemName}
                    {simplePlural(itemsWithSyncFailed.length)} failed to sync and won’t be published.{" "}
                    <button className={styles.button} onClick={handleSyncFailedWarningClick}>
                        <strong>Filter by failed to sync.</strong>
                    </button>
                </>
            );
        }

        const incompleteItems = items.filter((i) => i.status === Status.MissingContent);
        const incompleteItemsCount = incompleteItems.length;

        if (incompleteItemsCount > 0) {
            warnings.push(
                <div>
                    {incompleteItemsCount} {itemName}
                    {simplePlural(incompleteItemsCount)} {incompleteItemsCount === 1 ? "is" : "are"} missing content and
                    won’t be published.{" "}
                    <button className={styles.button} onClick={handleMissingContentWarningClick}>
                        <strong>Filter by missing content.</strong>
                    </button>
                </div>
            );
        }
        return warnings;
    }, [
        handleMissingContentWarningClick,
        handleSyncFailedWarningClick,
        itemName,
        items,
        itemsWithSyncFailed.length,
        itemsWithSyncSuccess.length,
        posSyncEnabled,
    ]);

    return <AlertsList alerts={warnings} status={AlertStatus.WARNING} />;
};
