import { opsApi } from "API";
import { activeLocationFields } from "./fetchLocation";
import { LocationSummary } from "..";

export const saveLocation = async (
    location: string,
    locationId: string | undefined,
    companyDetailsUpdate: CompanyDetailsUpdateModel
) => {
    const res = await opsApi.graphQLQuery<{ updateCompanyDetails: LocationSummary }>(updateQuery, {
        locationId,
        companyDetailsUpdate,
    });
    return res.data.updateCompanyDetails;
};

export interface CompanyDetailsUpdateModel {
    billingSystemId: string;
    isDemo: boolean;
    isSalesMode: boolean;
    registeredName: string;
    abn: string;
    address: string;
    privacyPolicyUrl: string;
    displayName: string;
    timeZone: string;
    locale: string;
    groupId?: string;
    newGroupName?: string;
    stripeCommission?: number;
}

const updateQuery = `
    mutation($locationId: ID!, $companyDetailsUpdate: CompanyDetailsUpdateInput!) {
        updateCompanyDetails(locationId: $locationId, companyDetails: $companyDetailsUpdate) {
            ${activeLocationFields}
        }
    }
`;
