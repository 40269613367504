import { isId } from "common/utility/StringUtils";

const locationSlugParamIndex = 2;

export const getChangeLocationUrl = (slug: string, location = window.location.pathname) => {
    const pathArray = location.split("/");
    pathArray[locationSlugParamIndex] = slug;
    const firstId = pathArray.findIndex((part) => isId(part));
    return pathArray.slice(0, firstId >= 0 ? firstId : pathArray.length).join("/");
};
