import styles from "./NavButton.module.scss";

import classNames from "classnames";
import { AngleDown } from "common/icons";
import { Button, Props as ButtonProps } from "../button/Button";

interface Props {
    hidden?: boolean;
    direction: "previous" | "next";
}

export const NavButton = ({
    className,
    direction = "next",
    hidden,
    padding = "icon",
    role = "secondary",
    ...rest
}: Props & ButtonProps) => {
    const buttonClasses = classNames(className, {
        [styles.previous]: direction === "previous",
        [styles.next]: direction === "next",
        [styles.hidden]: hidden,
    });

    return (
        <Button className={buttonClasses} role={role} padding={padding} {...rest}>
            <AngleDown />
        </Button>
    );
};
