import { Button } from "core/components/button";
import { CardWidth, Confirm, Modal } from "core/components/modal";
import { createAction } from "../reducers/filters";
import { getActiveFilters } from "../selectors/getFilters";
import { getInitialState } from "../reducers/filters";
import { Select, sortOptions } from "core/components/form/select";
import { Row } from "core/components/card";
import { UpdateStatusFilter, UpdateStatus, LocationFilters } from "../types";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEqual from "lodash/isEqual";
import { updateStatusInfo } from "../selectors/getUpdateStatusInfo";
import { useMedia } from "common/hooks";

interface Props {
    onClose: () => void;
    onConfirm: () => boolean | void;
    visible: boolean;
}

export const statusOptions: UpdateStatusFilter[] = Object.values(UpdateStatus).map((status) => ({
    label: updateStatusInfo[status].label,
    value: status,
}));

export const FiltersModal = ({ onClose, onConfirm, visible }: Props) => {
    const dispatch = useDispatch();

    const activeFilters = useSelector(getActiveFilters);

    const [selectedFilters, setSelectedFilters] = useState<LocationFilters>(activeFilters);

    const [selectMenuOpen, setSelectMenuOpen] = useState(false);

    const isMedium = useMedia("(min-width: 672px)");

    const handleOpen = useCallback(() => {
        setSelectedFilters(activeFilters);
    }, [activeFilters]);

    const handleStatusChange = useCallback(
        (selections) => {
            setSelectedFilters({
                ...selectedFilters,
                updateStatus: selections.sort(sortOptions),
            });
        },
        [selectedFilters]
    );

    const handleClearAll = useCallback(() => {
        setSelectedFilters(getInitialState().active);
    }, []);

    const handleConfirm = useCallback(() => {
        dispatch(createAction.setFilters(selectedFilters));
    }, [dispatch, selectedFilters]);

    const isDirty = useMemo(() => {
        return !isEqual(selectedFilters, activeFilters);
    }, [activeFilters, selectedFilters]);

    return (
        <Modal
            footer={
                <>
                    <Button nowrap role="secondary" type="button" onClick={handleClearAll}>
                        Clear all
                    </Button>
                    <Confirm confirmLabel="Ok" onConfirm={handleConfirm} confirmProps={{ disabled: !isDirty }} />
                </>
            }
            onOpen={handleOpen}
            onClose={onClose}
            title="Filter venues"
            visible={visible}
            width={CardWidth.NARROW}
        >
            <Row>
                <Select
                    blurInputOnSelect={false}
                    closeMenuOnSelect={false}
                    isMulti
                    label="Status"
                    menuPlacement="auto"
                    menuPosition={isMedium ? "fixed" : "absolute"}
                    onChange={handleStatusChange}
                    onMenuOpen={() => setSelectMenuOpen(true)}
                    onMenuClose={() => setSelectMenuOpen(false)}
                    options={statusOptions}
                    value={selectedFilters?.updateStatus}
                />
            </Row>
            {selectMenuOpen && <div className="hide-on-medium" style={{ height: 130 }} />}
        </Modal>
    );
};
