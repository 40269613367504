import { InfoCircleFilled } from "@ant-design/icons";
import { Button } from "antd";
import { ImportResult } from "features/catalogue/types/ImportResult";
import { UploadLoader } from "./UploadLoader";

export interface Props {
    parentRecordTitle: string;
    childRecordTitle: string;
    imported?: ImportResult;
    onClose: () => void;
    loading: boolean;
}

export const UploadFailed = ({ parentRecordTitle, childRecordTitle, imported, onClose, loading }: Props) => {
    return (
        <div className="bulk-uploader--content">
            <UploadLoader loading={loading} />
            <div className="bulk-uploader--header failed">
                <i className="exclamation-circle" />
                <h1>Upload failed</h1>
                <p>
                    Your file was not uploaded and no {parentRecordTitle}s & {childRecordTitle}s have been added. Review
                    your document, and try again.
                </p>
            </div>
            <div className="bulk-uploader--body">
                <div className="body-top failed">
                    <h2>What's changed</h2>
                    <p>
                        <InfoCircleFilled /> No changes were made to your catalogue
                    </p>
                    {imported && imported.errors && imported.errors.length > 0 && (
                        <div className="import-error">
                            <InfoCircleFilled />
                            {imported.errors.length === 1 && imported.errors[0].lineNumber <= 0 ? (
                                <>
                                    {imported.errors[0].errorTitle && <h3>{imported.errors[0].errorTitle}</h3>}
                                    {imported.errors[0].errorLink ? (
                                        <a
                                            href={imported.errors[0].errorLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {imported.errors[0].errors.join(", ")}
                                        </a>
                                    ) : (
                                        imported.errors[0].errors.join(", ")
                                    )}
                                </>
                            ) : (
                                <>
                                    <h3>{imported.errors.length} total errors on the following lines</h3>
                                    {imported.errors.map((e) => e.lineNumber).join(", ")}
                                </>
                            )}
                        </div>
                    )}
                    <div className="info">
                        <span>
                            <b>Uploads can fail due to any of the following reasons:</b>
                        </span>
                        Any additional or renamed columns not provided in the template. Rows with missing content. Any
                        variants or options with no parent
                    </div>
                </div>
            </div>
            <div className="bulk-uploader--footer">
                <Button type="primary" onClick={onClose}>
                    Close
                </Button>
            </div>
        </div>
    );
};
