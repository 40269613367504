import styles from "./PublishStatus.module.scss";

import { PropsWithChildren, ReactElement } from "react";
import classNames from "classnames";

export type Status = "published" | "staged" | "not-published" | "warning";

interface Props {
    status?: Status;
    nowrap?: boolean;
}

export const PublishStatus = ({
    children,
    status = "published",
    nowrap = true,
}: PropsWithChildren<Props>): ReactElement => {
    return <span className={classNames(styles.default, styles[status], nowrap && styles.nowrap)}>{children}</span>;
};
