import { AppState } from "features/state";
import { createSelector } from "reselect";
import { LocationFilters } from "../types";

const getLocationFilters = ({ locationRelations: { filters } }: AppState) => filters;

export const getActiveFilters = createSelector(getLocationFilters, (filters) => {
    return filters.active;
});

export const getTextFilter = createSelector(getLocationFilters, (filters) => {
    return filters.text;
});

export const getLocationsFilterCount = createSelector(getLocationFilters, (filters) => {
    return filters.active ? getFilterCount(filters.active) : 0;
});

export const getHasFilters = createSelector(getLocationsFilterCount, getTextFilter, (filterCount, text) => {
    return !!filterCount || !!text.length;
});

export const getActiveStatuses = createSelector(getLocationFilters, (filters) => {
    return filters.active.updateStatus?.map((status) => status.value) || [];
});

function getFilterCount(filters: LocationFilters) {
    return filters.updateStatus?.length || 0;
}
