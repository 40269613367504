import { AppState } from "features";
import * as actions from "../actions";
import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { PageLoadingProps, withLoader } from "common/loader/withLoader";
import { RouteComponentProps, withRouter } from "react-router";
import { isLoaded } from "common/loader/isLoaded";
import { LocationComponentProps, withLocation } from "features/location/containers/withLocation";
import { HyperwalletUser } from "../types/HyperwalletUser";
import { HyperwalletUserPage, Props } from "../components/HyperwalletUserPage";

const mapStateToProps = (
    state: AppState,
    { match: { params } }: RouteComponentProps<RouteParams>
): DataProps<PageLoadingProps<Props>> => {
    const {
        paymentUser: { edit },
    } = state;

    if (isLoaded(edit)) {
        return {
            initialValues: edit.data,
            saveStatus: edit.saveStatus,
            loadStatus: "loaded",
        };
    }

    return { loadStatus: edit.status };
};

interface RouteParams {
    location: string;
}

const mapDispatchToProps = (
    dispatch: any,
    { restaurantLocation, match: { params } }: LocationComponentProps & RouteComponentProps<RouteParams>
): FunctionProps<PageLoadingProps<Props>> => ({
    fetch: () => {
        dispatch(actions.edit(restaurantLocation.id));
    },
    onSubmit: (data: HyperwalletUser, isNew: boolean) => {
        dispatch(actions.save(restaurantLocation.id, data, isNew));
    },
});

export const HyperwalletUserPageContainer = withRouter(
    withLocation(connect(mapStateToProps, mapDispatchToProps)(withLoader(HyperwalletUserPage)))
);
