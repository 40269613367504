import { AppState } from "features";
import { RouteComponentProps, withRouter } from "react-router";
import { getLocationPermissions } from "../selectors/getLocationPermissions";
import { connect } from "react-redux";
import React from "react";
import { PermissionSet } from "../types/PermissionSet";

interface Props {
    permissions: PermissionSet;
    children: (permissions: PermissionSet) => React.ReactElement<any>;
}

const PermissionsInner: React.SFC<Props> = ({ permissions, children }: Props) => children(permissions);

interface OuterProps {
    children: (permissions: PermissionSet) => React.ReactElement<any>;
}

interface RouteParams {
    location: string;
}

function mapStateToProps(
    state: AppState,
    { children, match: { params } }: RouteComponentProps<RouteParams> & OuterProps
): Props {
    const permissions = getLocationPermissions(state);

    return {
        permissions,
        children,
    };
}

function mergeProps(stateProps: Props, dispatchProps: any, ownProps: OuterProps): Props {
    return stateProps;
}

export const Permissions = withRouter(
    connect(mapStateToProps, null, mergeProps)(PermissionsInner) as any as React.ComponentType<
        OuterProps & RouteComponentProps<RouteParams>
    >
);
