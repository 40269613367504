import styles from "./EditMembership.module.scss";

import { LocationComponentProps, withLocation } from "features/location";
import { CrudActionFooter, SubmitMode } from "core/components/actionFooter/CrudActionFooter";
import { EditableVenueMembershipProgram } from "../types";
import { Formik, Form, Field, validateYupSchema, yupToFormErrors } from "formik";
import { PageHeader } from "core/components/pageHeader";
import { RouteComponentProps, useParams, withRouter } from "react-router-dom";
import { ScrollToFormikError } from "components/forms/ScrollToFormikError";
import { useIsNewRoute, useLoadStatus } from "common/hooks";
import { useCallback, useMemo, useState } from "react";
import { Card, Row, Title } from "core/components/card";
import { Input } from "core/components/form/input";
import { FieldErrors } from "core/components/form/fieldErrors";
import { Select } from "core/components/form/select";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../actions";
import { actions as priceListActions } from "features/priceList";
import { AppState } from "features";
import { PageLoading } from "core/components/pageLoading";
import { PageError } from "core/components/pageError";
import { Button } from "core/components/button";
import { getCrudPermissions } from "features/location/selectors/getCrudPermissions";
import { archive, saveVenueMembership } from "../actions";
import { fetchLocation } from "features/location/api/fetchLocation";
import { getEditableMembershipSchema } from "../schema/EditableMembershipSchema";
import { getEditMembershipObject } from "../selectors";
import { getPriceListsOptions } from "features/priceList/selectors";
import { Subtitle } from "core/components/card/Subtitle";
import { FileSvg } from "common/icons/File";
import { fetchDownloadMembershipMembers } from "../api/downloadMembershipList";
import { PageContainer } from "core/components/pageContainer/PageContainer";
import { CardsContainer } from "core/components/card/CardsContainer";

interface RouteParams {
    id: string;
    location: string;
    region: string;
}

interface Props extends RouteComponentProps<RouteParams>, LocationComponentProps {
    onClose: () => void;
}

// FUTURE USE if validation required
const validate = (values: EditableVenueMembershipProgram) => {
    const val = validateYupSchema(values, getEditableMembershipSchema()).then(
        () => ({}),
        (err: any) => yupToFormErrors(err)
    );

    return val;
};

export const EditMembershipPage = withRouter(
    withLocation(({ onClose, restaurantLocation: { id: locationId } }: Props) => {
        const isNew = useIsNewRoute();
        const dispatch = useDispatch();
        const { region, location: slug, id } = useParams<RouteParams>();

        const membershipState = useSelector((state: AppState) => state.memberships.edit);
        const priceListsStatus = useSelector((state: AppState) => state.priceLists.list.status);
        const locationStatus = useSelector((state: AppState) => state.locations.active.status);
        const priceListsOptions = useSelector(getPriceListsOptions);
        const membershipData = useSelector(getEditMembershipObject);
        const { canDelete, canUpdate } = useSelector((state: AppState) => getCrudPermissions(state).memberships) || {};
        const disableFields = !canUpdate && false;
        const [submitMode, setSubmitMode] = useState<SubmitMode>("save");
        const [downloading, setDownloading] = useState(false);
        const fileName = useMemo(
            () => `membership-report-${membershipData.displayName}-${new Date().toISOString().split("T")[0]}.csv`,
            [membershipData]
        );

        const handleSubmit = useCallback(
            (data: EditableVenueMembershipProgram) => {
                setSubmitMode("save");

                dispatch(saveVenueMembership(locationId, data));
            },
            [dispatch, locationId]
        );

        const handleDelete = useCallback(
            (data: EditableVenueMembershipProgram) => {
                setSubmitMode("archive");
                dispatch(archive(locationId, data));
            },
            [dispatch, locationId]
        );

        const membershipPath = useMemo(() => `/${region}/${slug}/membership`, [region, slug]);

        const fetch = useCallback(() => {
            if (locationStatus !== "loaded") {
                dispatch(fetchLocation(slug));
            }
            dispatch(actions.edit(locationId, id));
            dispatch(priceListActions.list(locationId));
        }, [dispatch, locationId, slug, id, locationStatus]);

        const fetchStatus = useLoadStatus([membershipState.status, priceListsStatus, locationStatus], fetch, {
            refetchOnMount: true,
        });

        if (fetchStatus === "loading" || fetchStatus === "unloaded") {
            return <PageLoading message="Loading Loyalty information" />;
        }

        if (fetchStatus === "failed") {
            return (
                <PageError
                    defaultActionText={"Go back to Memberships"}
                    defaultActionTo={membershipPath}
                    defaultActionClick={fetch}
                />
            );
        }

        return (
            <PageContainer>
                <PageHeader
                    title={`${isNew ? "Create" : "Edit"} loyalty program`}
                    subtitle={
                        membershipData.type === "Venue"
                            ? "Users that opt-in in the me&u app will join this loyalty program."
                            : `Users are able to activate their ${membershipData.type} loyalty account via the me&u app.`
                    }
                    backHandler={onClose}
                />
                <Formik validate={validate} initialValues={membershipData} onSubmit={handleSubmit} enableReinitialize>
                    {(form) => (
                        <CardsContainer as={Form} className={styles.form}>
                            <ScrollToFormikError />
                            <Card>
                                <Row collapse="down">
                                    <Title title="General information" />
                                </Row>
                                <Row collapse="down">
                                    <FieldErrors fieldNames={["displayName"]}>
                                        <Field
                                            name="displayName"
                                            component={Input}
                                            label="Name"
                                            placeholder="This will be shown to your customers"
                                            disabled={disableFields}
                                            markRequired
                                        />
                                    </FieldErrors>
                                </Row>
                                <Row collapse="down">
                                    <FieldErrors fieldNames={["privacyPolicyLink"]}>
                                        <Field
                                            name="privacyPolicyLink"
                                            component={Input}
                                            label="Privacy Policy URL"
                                            placeholder="https://privacypolicy.com"
                                            disabled={disableFields}
                                            markRequired
                                        />
                                    </FieldErrors>
                                </Row>
                                <Row>
                                    <FieldErrors fieldNames={["priceListId"]}>
                                        <Field
                                            name="priceListId"
                                            component={Select}
                                            label="Pricing"
                                            placeholder="Select a Price List"
                                            disabled={disableFields}
                                            blurInputOnSelect={false}
                                            markRequired
                                            options={priceListsOptions}
                                        />
                                    </FieldErrors>
                                </Row>
                            </Card>
                            <CrudActionFooter
                                saving={membershipState.saveStatus === "saving"}
                                submitMode={submitMode}
                                showDuplicate={false}
                                showDelete={canDelete && !isNew}
                                showSave={!disableFields}
                                confirmDelete={{
                                    title: "Are you sure you want to delete this loyalty program?",
                                    deleteLabel: "Delete loyalty program",
                                    children:
                                        "By deleting this loyalty program, customers will no longer have access to offers through me&u.",
                                }}
                                deleteProps={{
                                    onClick: () => handleDelete(form.values),
                                }}
                                saveProps={{
                                    disabled: !form.dirty,
                                }}
                                position="fixed"
                            />
                        </CardsContainer>
                    )}
                </Formik>
                {membershipData.id && membershipData.type === "Venue" && (
                    <Card>
                        <Row collapse="down">
                            <Title title="Customer list" fill="none" />
                        </Row>
                        <Row collapse="up">
                            <Subtitle subtitle="Download a list of customers that have opted in to your loyalty program in the me&u app." />
                        </Row>
                        <Row className={styles.downloadRow}>
                            <FileSvg />
                            <div className={styles.downloadText}>{fileName}</div>
                            <Button
                                loading={downloading}
                                role="secondary"
                                onClick={() => {
                                    setDownloading(true);
                                    const startDownload = Date.now();
                                    fetchDownloadMembershipMembers(locationId, membershipData.id!, fileName, () => {
                                        const timeDiff = Date.now() - startDownload;
                                        if (timeDiff > 1000) {
                                            setDownloading(false);
                                        } else {
                                            window.setTimeout(() => setDownloading(false), 1000 - timeDiff);
                                        }
                                    });
                                }}
                            >
                                Download
                            </Button>
                        </Row>
                    </Card>
                )}
            </PageContainer>
        );
    })
);
