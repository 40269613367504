import styles from "./TakeawayPage.module.scss";

import { Phone } from "common/icons/Phone";
import { Card, Row, Title } from "core/components/card";
import { FieldErrors } from "core/components/form/fieldErrors";
import { Input } from "core/components/form/input";
import { Label } from "core/components/form/input/Label";
import { TextArea } from "core/components/form/textarea";
import { Icon } from "core/components/icon";
import { Field } from "formik";

interface Props {
    disabled: boolean;
}

export const TakeawayPageCollectionCard = ({ disabled }: Props) => {
    return (
        <Card>
            <Row collapse="down">
                <Title title="Collection details" />
            </Row>
            <Row>
                <div>
                    <h3 className="text-subheading">Venue contact</h3>
                    <div>
                        Add your phone number. The phone number is shown to your customers after they submit their
                        order.
                    </div>
                </div>
            </Row>
            <Row collapse="up">
                <FieldErrors fieldNames={["contactPhoneNumber"]}>
                    <Label label="Phone number" />
                    <div className={styles.phoneNumber}>
                        <Field
                            name="contactPhoneNumber"
                            component={Input}
                            placeholder="(00) 000 0000"
                            disabled={disabled}
                            before={
                                <Icon size="medium" verticalAlign="middle">
                                    <Phone />
                                </Icon>
                            }
                            inputTest={/^[^a-zA-Z]*$/}
                        />
                    </div>
                </FieldErrors>
            </Row>
            <Row border="top">
                <div>
                    <h3 className="text-subheading">Takeaway address</h3>
                    <div>
                        Linked to Google Maps and Apple Maps. The address is shown to your customers in the me&u app.
                    </div>
                </div>
            </Row>
            <Row collapse="up">
                <FieldErrors fieldNames={["venueAddress.addressLine1"]}>
                    <Label label="Street address" markRequired />
                    <div>
                        <Field
                            name="venueAddress.addressLine1"
                            component={Input}
                            placeholder="Example: Suite 2/91 Campbell St"
                            disabled={disabled}
                        />
                    </div>
                </FieldErrors>
            </Row>
            <Row collapse="up" className={styles.fieldContainer}>
                <FieldErrors fieldNames={["venueAddress.city"]}>
                    <Label label="Suburb/Town" markRequired />
                    <div>
                        <Field
                            name="venueAddress.city"
                            component={Input}
                            placeholder="Example: Surry Hills"
                            disabled={disabled}
                        />
                    </div>
                </FieldErrors>
                <FieldErrors fieldNames={["venueAddress.postcode"]}>
                    <Label label="Postcode" markRequired />
                    <div>
                        <Field
                            name="venueAddress.postcode"
                            component={Input}
                            placeholder="Example: 2010"
                            disabled={disabled}
                        />
                    </div>
                </FieldErrors>
            </Row>
            <Row collapse="up">
                <FieldErrors fieldNames={["venueAddress.googleBusinessName"]}>
                    <Label label="Google business name" />
                    <div>
                        <Field
                            name="venueAddress.googleBusinessName"
                            component={Input}
                            placeholder="Copy and paste your name as shown in Google"
                            disabled={disabled}
                        />
                    </div>
                </FieldErrors>
            </Row>
            <Row border="top">
                <div>
                    <h3 className="text-subheading">Collection instructions</h3>
                    <div>Inform your customers where to pick up their order.</div>
                </div>
            </Row>
            <Row collapse="up">
                <FieldErrors fieldNames={["collectionInstructions"]}>
                    <div>
                        <Field
                            name="collectionInstructions"
                            component={TextArea}
                            rows={5}
                            placeholder="Write your message here. Up to 280 characters."
                            disabled={disabled}
                            maxLength={280}
                        />
                    </div>
                </FieldErrors>
            </Row>
        </Card>
    );
};
