import { opsApi } from "API";

export const querySlugAvailable = async (slug: string): Promise<boolean> => {
    const res = await opsApi.graphQLQuery<{ locationSlugAvailable: boolean }>(query, { slug });
    return res.data.locationSlugAvailable;
};

const query = `
    query($slug: String!) {
        locationSlugAvailable(slug: $slug)
    }
`;
