import styles from "./ListServicesPageWrapper.module.scss";
import { Button } from "core/components/button";
import { EmptyMessage } from "core/components/card";
import { getActiveLocationSelector } from "features/location/selectors/getActiveLocation";
import { getSelectedRegion } from "features/region/selectors";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Card, Row } from "core/components/card";
import { Tab } from "features/service/types/Tab";

interface Props {
    tab: Tab;
}

export const ServicesNotFound = ({ tab }: Props) => {
    const location = useSelector(getActiveLocationSelector)?.slug;
    const activeRegion = useSelector(getSelectedRegion)?.id;

    const newServiceLink = `/${activeRegion}/${location}/menu/services/${tab}/new`;
    const heading = "You have no services";
    const message =
        "Create a service to populate this page, until you have an active service your venue will remain offline";

    return (
        <Card>
            <Row className={styles.servicesNotFound}>
                <EmptyMessage message={message} heading={heading} size="standard">
                    <Button as={Link} to={newServiceLink} role="secondary">
                        New Service
                    </Button>
                </EmptyMessage>
            </Row>
        </Card>
    );
};
