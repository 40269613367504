import * as Yup from "yup";
import { EditableWaiter, WaiterSummary } from "features/waiter/types";
import { validateWaiterPin } from "../actions/validateWaiterPin";

export const editableWaiterSchema = Yup.object<EditableWaiter>().shape({
    displayName: Yup.string().required("Please enter a name"),
    pin: Yup.string().when(
        ["$allWaiters", "$waiterId"],
        (allWaiters: WaiterSummary[], waiterId: string, schema: Yup.StringSchema) =>
            schema
                .ensure()
                .required("Please enter a waiter PIN")
                .test("min", "Please enter a 4 digit PIN", (value: string) => value.length === 4)
                .test("unique", "This PIN is already in use", function (value: string) {
                    return validateWaiterPin(allWaiters, waiterId, value);
                })
    ),
    accessLevel: Yup.string(),
});
