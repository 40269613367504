import { createAction } from "../reducers/edit";
import { AppState, AppDispatch } from "features/state";
import { getPosConfiguration } from "../api/getPosConfiguration";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { timestamp } from "common/data/timetamped";

export const edit = () => async (dispatch: AppDispatch, getState: () => AppState) => {
    const state = getState();
    const location = getActiveLocation(state)!;
    const configState = state.posConfiguration.status;

    if (configState === "loading") {
        return;
    }

    try {
        dispatch(createAction.loading(location.id));

        const posConfiguration = await getPosConfiguration(location.id);

        dispatch(createAction.loaded(location.id, timestamp(posConfiguration)));
    } catch (e) {
        dispatch(createAction.loadFailed(location.id, e));
    }
};
