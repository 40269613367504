import modalStyles from "features/catalogue/components/ModalContent.module.scss";

import { actions as tagsActions } from "features/tags";
import { actions as templateActions, MenuItemTemplate } from "features/menuitemtemplate";
import { ActiveLocation, LocationLocaleContext } from "features/location";
import { AppState } from "features";
import { Badge } from "core/components/badge";
import { CardsContainer } from "core/components/card/CardsContainer";
import { CardWidth, Confirm, Modal, ModalRenderer } from "core/components/modal";
import { CatalogueItemNotFound } from "../../CatalogueItemNotFound";
import { CategorySummary } from "features/category";
import { CrudActionFooter, SubmitMode } from "core/components/actionFooter";
import { CrudPermissions } from "features/location/types/createCrudPermissions";
import { editableMenuItemSchema } from "features/catalogue/schema";
import { EditableProduct } from "features/catalogue/types";
import { Formik, Form, yupToFormErrors, validateYupSchema } from "formik";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { getEditProductWithTags, getEditProductWithDefaultTemplate } from "features/catalogue/selectors/getEditProduct";
import { getIsChildLocation } from "features/location/selectors/getIsChildLocation";
import { getIsParentLocation } from "features/location/selectors/getIsParentLocation";
import { getModifiersList } from "features/catalogue/selectors/getModifierCatalogueItems";
import { getPosSyncEnabled } from "features/catalogue/selectors/getPosSyncEnabled";
import { getProductVideoEnabled } from "features/catalogue/selectors/getProductVideoEnabled";
import { getState } from "common/selectors/getState";
import { getTempPriceId } from "features/catalogue/components/product/edit/productHelpers";
import { isLoaded } from "common/loader/isLoaded";
import { LoadStatus, SaveStatus } from "common/loader";
import { LocationLocale } from "features/location/types/LocationLocale";
import { ModifierSummary } from "features/modifier";
import { PageContainer } from "core/components/pageContainer/PageContainer";
import { PageHeader } from "core/components/pageHeader";
import { PageLoading } from "core/components/pageLoading";
import { ProductAlerts } from "./ProductAlerts";
import { ProductCategories } from "./ProductCategories";
import { ProductDefaultVariant } from "./ProductDefaultVariant";
import { ProductDetails } from "./ProductDetails";
import { ProductFlags } from "./ProductFlags";
import { ProductImage } from "./ProductImage";
import { ProductModifiers } from "./ProductModifiers";
import { ProductPrices } from "./ProductPrices";
import { ProductTags } from "./ProductTags";
import { ProductVideo } from "./ProductVideo";
import { Prompt } from "core/components/modal/Prompt";
import { Row } from "core/components/card";
import { ScrollToFormikError } from "components/forms/ScrollToFormikError";
import { Status } from "features/catalogue/types";
import { TagGroup } from "features/tags";
import { useCallback, useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { useIsNewRoute, useLoadStatus } from "common/hooks";
import { usePermissions } from "features/location/hooks";
import * as catalogueActions from "features/catalogue/actions";
import * as categoryActions from "features/category/actions";
import * as modifierActions from "features/modifier/actions";

interface Props {
    onClose: () => void;
}

interface RouteParams {
    id: string;
    location: string;
    region: string;
    templateid: string;
    type: string;
}

const EditProductPageContainer = ({ onClose }: Props) => {
    const activeLocation = useSelector(getActiveLocation);
    const categoryList = useSelector((state: AppState) => state.categories.list);
    const edit = useSelector((state: AppState) => state.menuItems.edit);
    const modifiersList = useSelector(getModifiersList);
    const posSyncEnabled = useSelector(getPosSyncEnabled);
    const state = useSelector(getState);
    const tagList = useSelector((state: AppState) => state.tags.list);
    const templateList = useSelector((state: AppState) => state.menuItemTemplates.list);
    const productVideoEnabled = useSelector(getProductVideoEnabled) || false;
    const isChildLocation = useSelector(getIsChildLocation) || false;
    const isParentLocation = useSelector(getIsParentLocation) || false;

    const dispatch = useDispatch();
    const history = useHistory();
    const isNew = useIsNewRoute();
    const locationLocale = useContext(LocationLocaleContext);
    const permissions = usePermissions().product;
    const routeParams = useParams<RouteParams>();
    const initialValues = isNew ? getEditProductWithDefaultTemplate(state) : getEditProductWithTags(state);

    const { location, id, region } = routeParams;

    const onArchive = useCallback(() => {
        dispatch(catalogueActions.archiveProduct(location, region, history));
    }, [dispatch, history, location, region]);

    const onClone = useCallback(
        (data: EditableProduct, template: MenuItemTemplate) => {
            dispatch(catalogueActions.saveProduct(location, region, data, template, true, history));
        },
        [dispatch, history, location, region]
    );

    const onSubmit = useCallback(
        (data: EditableProduct, template: MenuItemTemplate) => {
            dispatch(catalogueActions.saveProduct(location, region, data, template, false, history));
        },
        [dispatch, history, location, region]
    );

    const fetch = useCallback(() => {
        !isLoaded(templateList) && dispatch(templateActions.list());

        !isLoaded(categoryList) && dispatch(categoryActions.list(location));

        !isLoaded(tagList) && dispatch(tagsActions.list());

        !isLoaded(modifiersList) && dispatch(modifierActions.list(location));

        dispatch(catalogueActions.editProduct(location, id));
    }, [categoryList, dispatch, id, location, modifiersList, tagList, templateList]);

    const fetchStatus = useLoadStatus(
        [edit.status, categoryList.status, templateList.status, tagList.status, modifiersList.status],
        fetch,
        {
            refetchOnMount: true,
        }
    );

    const categoryListData = isLoaded(categoryList) ? categoryList.data : [];

    const modifiersListData = isLoaded(modifiersList) ? modifiersList.data : [];

    const tagListData = isLoaded(tagList) ? tagList.data : [];

    const templateListData = isLoaded(templateList) ? templateList.data : [];

    const template = initialValues && templateListData.find((template) => template.id === initialValues.template);

    if (initialValues) {
        // add psuedo-field `itemType` to initialValues for correct form.dirty checks
        initialValues.itemType = template?.type;
        // FE only field `showVideo` to enable video upload
        initialValues.showVideoUpload = productVideoEnabled && !!initialValues.video;

        initialValues.isLinked = isChildLocation && initialValues.isLinked;
    }

    const links = useMemo(
        () => ({
            products: `/${region}/${location}/menu/catalogue/products`,
            priceLists: `/${region}/${location}/menu/pricelists`,
        }),
        [region, location]
    );

    return (
        <EditProductPageComponent
            activeLocation={activeLocation}
            categoryList={categoryListData}
            fetch={fetch}
            fetchStatus={fetchStatus}
            initialValues={initialValues}
            isNew={isNew}
            isChildLocation={isChildLocation}
            isParentLocation={isParentLocation}
            links={links}
            locationLocale={locationLocale}
            modifiersList={modifiersListData}
            onArchive={onArchive}
            onClone={onClone}
            onClose={onClose}
            onSubmit={onSubmit}
            permissions={permissions}
            posSyncEnabled={posSyncEnabled || false}
            productVideoEnabled={productVideoEnabled}
            saveStatus={edit.saveStatus}
            tagList={tagListData}
            templateList={templateListData}
        />
    );
};

interface ComponentProps extends Props {
    activeLocation: ActiveLocation | undefined;
    categoryList: CategorySummary[];
    initialValues: EditableProduct | null;
    isNew: boolean;
    isChildLocation: boolean;
    isParentLocation: boolean;
    fetch: () => void;
    fetchStatus: LoadStatus;
    links: { products: string; priceLists: string };
    locationLocale: LocationLocale;
    modifiersList: ModifierSummary[];
    onArchive: () => void;
    onClone: (values: EditableProduct, template: MenuItemTemplate) => void;
    onSubmit: (values: EditableProduct, template: MenuItemTemplate) => void;
    permissions: CrudPermissions;
    posSyncEnabled: boolean;
    productVideoEnabled: boolean;
    saveStatus: SaveStatus | undefined;
    tagList: TagGroup[];
    templateList: MenuItemTemplate[];
}

const EditProductPageComponent = ({
    activeLocation,
    categoryList,
    initialValues,
    isNew,
    isChildLocation,
    isParentLocation,
    fetch,
    fetchStatus,
    links,
    locationLocale,
    modifiersList,
    onArchive,
    onClone,
    onClose,
    onSubmit,
    permissions,
    posSyncEnabled,
    productVideoEnabled,
    saveStatus,
    tagList,
    templateList,
}: ComponentProps) => {
    const [submitMode, setSubmitMode] = useState<SubmitMode>("save");

    const [showDuplicateConfirm, setShowDuplicateConfirm] = useState(false);

    const disableLinkedFields = (isChildLocation && initialValues?.isLinked) || false;

    // initial template id
    const templateId = initialValues?.template || "";

    // initial template
    const template: MenuItemTemplate | undefined = useMemo(() => {
        return (
            templateList.find((template: MenuItemTemplate) => template.id === templateId) ||
            templateList.find((template: MenuItemTemplate) => template.type === "food")
        );
    }, [templateList, templateId]);

    const handleSubmit = useCallback(
        (values) => {
            const selectedTemplate = templateList.find((template) => template.id === values.template);
            if (!selectedTemplate) {
                return;
            }
            setSubmitMode("save");
            onSubmit(values, selectedTemplate);
        },
        [onSubmit, templateList]
    );

    const handleDelete = useCallback(() => {
        setSubmitMode("archive");
        onArchive();
    }, [onArchive]);

    const handleDuplicate = useCallback(
        (values) => {
            const selectedTemplate = templateList.find((template) => template.id === values.template);
            if (!selectedTemplate) {
                return;
            }

            if (disableLinkedFields) {
                setShowDuplicateConfirm(true);
                return;
            }

            setSubmitMode("clone");
            onClone(values, selectedTemplate);
        },
        [disableLinkedFields, onClone, templateList]
    );

    const handleBlockTransition = useCallback(() => saveStatus !== "saved", [saveStatus]);

    if (fetchStatus === "failed") {
        return <CatalogueItemNotFound collectionName={"product"} />;
    }

    if (fetchStatus === "loading" || fetchStatus === "unloaded" || !initialValues) {
        return <PageLoading message="Loading product" />;
    }

    const isUpdate = !!initialValues.id;

    const disableFields = isUpdate && !permissions.canUpdate;
    const allowTemplateTypeChange = !isUpdate || !initialValues.template;

    initialValues.prices = initialValues.prices.map((price) => {
        return price.id
            ? price
            : {
                  ...price,
                  id: `${getTempPriceId()}`,
                  isNew: true,
              };
    });

    return (
        <PageContainer>
            <PageHeader
                title={`${isNew ? "Create" : "Edit"} Product`}
                titleAfter={initialValues.isLinked ? <Badge backgroundColorScheme="mid">Brand</Badge> : undefined}
                backHandler={onClose}
            />
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                key={initialValues.id}
                validate={(values) => {
                    const selectedTemplateId = values.template || "";

                    const selectedTemplate = templateList.find((template) => template.id === selectedTemplateId);

                    const tagGroups = selectedTemplate
                        ? tagList.filter((tag: TagGroup) => selectedTemplate.tagGroups.includes(tag.id))
                        : [];

                    const context = {
                        tagGroups,
                        template: selectedTemplate,
                        requireImage:
                            values.status !== Status.MissingContent &&
                            !activeLocation?.defaultCatalogueImage &&
                            !isParentLocation,
                        requireType: true,
                        prices: values.prices,
                    };

                    return validateYupSchema(values, editableMenuItemSchema, undefined, context).then(
                        () => ({}),
                        (err: any) => yupToFormErrors(err)
                    );
                }}
            >
                {(form) => (
                    <>
                        <ProductAlerts posSyncEnabled={posSyncEnabled} priceListsLink={links.priceLists} />
                        <CardsContainer as={Form}>
                            <ScrollToFormikError />

                            <ProductDetails
                                allowTemplateTypeChange={allowTemplateTypeChange}
                                disableFields={disableFields}
                                disableLinkedFields={disableLinkedFields}
                                templateList={templateList}
                                posSyncEnabled={posSyncEnabled}
                                syncStatus={initialValues.validationStatus}
                            />

                            <ProductTags
                                disableFields={disableFields || disableLinkedFields}
                                tagList={tagList}
                                templateList={templateList}
                            />

                            <ProductImage
                                disableFields={disableFields || disableLinkedFields}
                                productVideoEnabled={productVideoEnabled}
                            />

                            {productVideoEnabled && (
                                <ProductVideo disableFields={disableFields || disableLinkedFields} />
                            )}

                            <ProductPrices
                                locationLocale={locationLocale}
                                disableFields={disableFields}
                                disableLinkedFields={disableLinkedFields}
                                posSyncEnabled={posSyncEnabled}
                                syncStatus={initialValues.validationStatus}
                            />

                            <ProductDefaultVariant disableFields={disableFields || disableLinkedFields} />

                            <ProductModifiers
                                disableFields={disableFields || disableLinkedFields}
                                modifiers={modifiersList}
                            />

                            {!isParentLocation && (
                                <ProductCategories disableFields={disableFields} categories={categoryList} />
                            )}

                            <ProductFlags disableFields={disableFields || disableLinkedFields} />

                            <CrudActionFooter
                                saving={saveStatus === "saving"}
                                submitMode={submitMode}
                                showDuplicate={permissions.canCreate && !posSyncEnabled && !!initialValues.id}
                                showDelete={permissions.canDelete && !!initialValues.id}
                                showSave={!disableFields}
                                duplicateProps={{
                                    onClick: () => {
                                        if (form.isValid) {
                                            handleDuplicate(form.values);
                                        } else {
                                            // force ScrollToFormikError to run (wont submit because form is invalid)
                                            form.submitForm();
                                        }
                                    },
                                }}
                                confirmDelete={{
                                    title: "Are you sure you want to delete this product?",
                                    deleteLabel: "Delete product",
                                    children: getDeleteWarning(isParentLocation),
                                }}
                                deleteProps={{
                                    onClick: handleDelete,
                                    disabled: disableLinkedFields,
                                }}
                                saveProps={{
                                    disabled: !form.dirty && isUpdate,
                                }}
                                position="fixed"
                            />

                            <ModalRenderer target="#modal">
                                <Prompt
                                    title="Unsaved changes"
                                    when={form.dirty}
                                    blockTransition={handleBlockTransition}
                                    cancelLabel="Keep editing"
                                    confirmLabel="Discard changes"
                                >
                                    {isUpdate ? "Changes have" : "Product has"} not been saved. Are you sure you want to
                                    leave this page?
                                </Prompt>
                            </ModalRenderer>

                            <ModalRenderer target="#modal">
                                <Modal
                                    footer={
                                        <Confirm
                                            confirmLabel="Duplicate"
                                            onConfirm={() => {
                                                setSubmitMode("clone");
                                                onClone(form.values, template!);
                                            }}
                                        />
                                    }
                                    onClose={() => setShowDuplicateConfirm(false)}
                                    title="Duplicating a brand product"
                                    visible={showDuplicateConfirm}
                                    width={CardWidth.NARROW}
                                >
                                    <Row>
                                        Duplicating this item will remove the brand link and it will exist as a venue
                                        product.
                                    </Row>
                                </Modal>
                            </ModalRenderer>
                        </CardsContainer>
                    </>
                )}
            </Formik>
        </PageContainer>
    );
};

export const EditProductPage = EditProductPageContainer;

function getDeleteWarning(isParentLocation: boolean) {
    return (
        <div className={modalStyles.messageContainer}>
            {isParentLocation ? (
                <>
                    <p>You are about to delete this product from your Brand Catalogue. This action cannot be undone.</p>
                    <p>This product will be removed from venues next time they update.</p>
                </>
            ) : (
                <p>This product will be removed from your catalogue. This action cannot be undone.</p>
            )}
        </div>
    );
}
