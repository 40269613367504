import { AppState } from "features";
import { CatalogueContext } from "features/catalogue/context/CatalogueContext";
import { CatalogueTab } from "../CatalogueTab";
import { getActiveFilters, getTextFilter } from "features/catalogue/selectors/getFilters";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { getFilteredProducts } from "features/catalogue/selectors/getFilteredCatalogueItems";
import { getProductCatalogueItems } from "features/catalogue/selectors/getProductCatalogueItems";
import { Headers } from "./Headers";
import { ListItem } from "./ListItem";
import { mergeStatus } from "common/loader";
import { useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLoadStatus } from "common/hooks";
import { usePermissions } from "features/location/hooks";
import { Warnings } from "./Warnings";
import * as categoryActions from "features/category/actions";
import * as catalogueActions from "features/catalogue/actions";

export const Tab = () => {
    const activeLocation = useSelector(getActiveLocation);
    const dispatch = useDispatch();
    const allItems = useSelector(getProductCatalogueItems);
    const filteredItems = useSelector(getFilteredProducts);
    const menuItems = useSelector((state: AppState) => state.menuItems.list);
    const categoryList = useSelector((state: AppState) => state.categories.list);
    const isLoaded = mergeStatus(categoryList.status, menuItems.status) === "loaded";
    const activeFilters = useSelector(getActiveFilters);
    const textFilter = useSelector(getTextFilter);

    const { formatCurrency, productsPageIndex, productsPageSize, setProductsPageIndex, setStoredProductsPageSize } =
        useContext(CatalogueContext);

    const fetch = useCallback(() => {
        if (!activeLocation) {
            return;
        }
        dispatch(catalogueActions.listProducts(activeLocation.id));
        dispatch(categoryActions.list(activeLocation.id));
    }, [activeLocation, dispatch]);

    const fetchStatus = useLoadStatus([categoryList.status, menuItems.status], fetch);
    const bulkDeleteStatus = useSelector((state: AppState) => state.catalogue.bulkDelete.status);

    const {
        product: { canBulkDelete },
    } = usePermissions();

    return (
        <CatalogueTab
            activeFilters={activeFilters}
            bulkDeleteStatus={bulkDeleteStatus}
            canBulkDelete={canBulkDelete}
            canBulkSelect={true}
            collectionName="products"
            fetchStatus={fetchStatus}
            formatCurrency={formatCurrency}
            headers={Headers}
            isLoaded={isLoaded}
            items={filteredItems}
            listItem={ListItem}
            noResults={allItems.length === 0}
            pageIndex={productsPageIndex}
            pageSize={productsPageSize}
            retry={fetch}
            setPageIndex={setProductsPageIndex}
            setPageSize={setStoredProductsPageSize}
            textFilter={textFilter}
            warnings={Warnings}
        />
    );
};
