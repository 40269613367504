import React from "react";

import { Permissions } from "./Permissions";

export const withPermissions =
    <P extends {}>(Component: React.ComponentType<P>, ...requiredPermissions: string[]): React.SFC<P> =>
    (props: P) =>
        (
            <Permissions>
                {(permissions) =>
                    permissions.hasAll(...requiredPermissions) ? <Component {...props} /> : <p>Access denied</p>
                }
            </Permissions>
        );

export const withPermissionToggle =
    <P extends {}>(Component: React.ComponentType<P>, ...requiredPermissions: string[]): React.SFC<P> =>
    (props: P) =>
        (
            <Permissions>
                {(permissions) => (permissions.hasAll(...requiredPermissions) ? <Component {...props} /> : <></>)}
            </Permissions>
        );
