import { ActionMeta } from "react-select";
import { EditableProduct } from "features/catalogue/types";
import { Field, useFormikContext } from "formik";
import { Option, Select } from "core/components/form/select";
import { TagGroup } from "features/tags";
import { useCallback, useMemo } from "react";

interface Props {
    disableFields: boolean;
    onChange: (name: string) => void;
    tagGroup: TagGroup;
}

export const EditTagGroup = ({ disableFields, onChange, tagGroup }: Props) => {
    const { id, displayName, editorMode } = tagGroup;

    const {
        setFieldValue,
        setFieldTouched,
        values: { tags },
    } = useFormikContext<EditableProduct>();

    const tagOptions = useMemo(() => {
        const parentTagGroup = tagGroup.parentTagGroup;

        return (
            tagGroup.tags
                // only get tags that are children of the selected parent tag
                .filter((tag) => {
                    if (!tag.parentTagId) {
                        return true;
                    }

                    if (!parentTagGroup) {
                        return false;
                    }

                    return tags[parentTagGroup]?.includes(tag.parentTagId) || false;
                })
                .map((tag) => ({
                    label: tag.displayName,
                    value: tag.id,
                }))
        );
    }, [tagGroup.parentTagGroup, tagGroup.tags, tags]);

    const handleChange = useCallback(
        (selected: Option, meta: ActionMeta<Option>) => {
            const { name } = meta;

            if (!name) {
                return false;
            }

            onChange(name);

            setFieldValue(name, selected?.value ? [selected.value] : undefined);

            setFieldTouched(name, true);

            // prevent Select from setting field value
            return false;
        },
        [onChange, setFieldTouched, setFieldValue]
    );

    const allowMultiple = editorMode === "multiple";

    return (
        <Field
            label={displayName}
            name={`tags.${id}`}
            key={id}
            blurInputOnSelect={!allowMultiple}
            closeMenuOnSelect={!allowMultiple}
            component={Select}
            disabled={disableFields}
            isClearable={true}
            isMulti={allowMultiple}
            menuPlacement="auto"
            menuShouldScrollIntoView
            options={tagOptions}
            placeholder={`Select ${displayName.toLowerCase()} ...`}
            // undefined because Select with isMulti handles arrays internally
            onChange={allowMultiple ? undefined : handleChange}
        />
    );
};
