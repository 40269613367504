import { createAction } from "../reducers/list";
import { Group } from "..";
import { scaffoldListAction } from "../../../common/scaffolding/actions/scaffoldListAction";
import { listGroups } from "../api/listGroups";
import { applySummaryDisplayName } from "foundation/dataConventions/applySummaryDisplayName";

export const list = (refresh?: boolean) =>
    scaffoldListAction<Group>(
        (state) => state.groups.list,
        createAction,
        async () => {
            const groups = await listGroups();

            return groups.map(applySummaryDisplayName);
        },
        refresh
    );
