import { createAction } from "../reducers/edit";
import { scaffoldEditAction } from "common/scaffolding/actions/scaffoldEditAction";
import { fetchQuickSellData } from "../api";

export const getQuickSellData = (locationId: string) =>
    scaffoldEditAction(
        locationId,
        (state) => state.quickSell.data,
        createAction,
        async () => {
            const data = await fetchQuickSellData(locationId);
            return data;
        }
    );
