import { createSelector } from "reselect";
import { AppState } from "../..";
import { PermissionSet } from "../types/PermissionSet";

export const getActiveLocation = ({ locations: { active } }: AppState) =>
    active.status === "loaded" ? active.data : undefined;

export const getLocationPermissions = createSelector(
    getActiveLocation,
    (location) => new PermissionSet(location ? location.authInfo.permissions : [])
);
