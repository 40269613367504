import { ColorScheme } from "core/components/status";
import { UpdateStatus } from "../types";

export const updateStatusInfo: { [key: string]: { label: string; colorScheme: ColorScheme } } = {
    [UpdateStatus.UP_TO_DATE]: {
        label: "Up to date",
        colorScheme: "success",
    },
    [UpdateStatus.DUE_TO_UPDATE]: {
        label: "Due to update",
        colorScheme: "warning",
    },
    [UpdateStatus.NEVER_UPDATED]: {
        label: "Never updated",
        colorScheme: "disabled",
    },
};

export function getUpdateStatusInfo(updateStatus: UpdateStatus) {
    return updateStatusInfo[updateStatus] || null;
}
