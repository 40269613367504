import styles from "./Variants.module.scss";

import { ArrayHelpers, FastField, useFormikContext } from "formik";
import { buildInputRegex } from "core/components/form/InputNumber";
import { Button } from "core/components/button";
import { CancelBig } from "common/icons";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { EditableProduct, EditableVariant } from "features/catalogue/types";
import { FieldErrors } from "core/components/form/fieldErrors";
import { getIsEnergyContentSupported } from "features/location/selectors/getEnergyContentSupported";
import { Grip } from "common/icons/Grip";
import { Icon } from "core/components/icon";
import { Input } from "core/components/form/input";
import { ItemValidationStatus } from "features/catalogue/types";
import { LocationLocale } from "features/location/types/LocationLocale";
import { PosFieldSyncStatus } from "./PosFieldSyncStatus";
import { useSelector } from "react-redux";

interface Props {
    disableFields: boolean;
    disableLinkedFields: boolean;
    locationLocale: LocationLocale;
    posSyncEnabled: boolean;
    syncStatus: ItemValidationStatus | undefined;
}

export const priceInputTest = buildInputRegex({ numDigits: 6, numDecimals: 2, allowNegative: false });

export const Variants = ({
    disableFields,
    disableLinkedFields,
    locationLocale,
    move,
    posSyncEnabled,
    remove,
    syncStatus,
}: Props & ArrayHelpers) => {
    const {
        values: { prices },
    } = useFormikContext<EditableProduct>();

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;
        move(result.source.index, result.destination.index);
    };

    const onBeforeDragStart = () => {
        (document.activeElement as any)?.blur();
    };

    return (
        <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onBeforeDragStart}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} className={styles.variantsList}>
                        {prices.map((variant, index) => (
                            <Variant
                                disabled={disableFields}
                                disableLinkedFields={disableLinkedFields}
                                index={index}
                                key={`${index}`}
                                locationLocale={locationLocale}
                                onRemove={remove}
                                pathPrefix={`prices.${index}`}
                                posSyncEnabled={posSyncEnabled}
                                syncStatus={syncStatus}
                                variant={variant}
                            />
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

interface VariantProps {
    disabled?: boolean;
    disableLinkedFields: boolean;
    index: number;
    locationLocale: LocationLocale;
    onRemove: (index: number) => void;
    pathPrefix: string;
    posSyncEnabled: boolean;
    syncStatus: ItemValidationStatus | undefined;
    variant: EditableVariant;
}

export const Variant = ({
    disabled,
    disableLinkedFields,
    index,
    locationLocale,
    onRemove,
    pathPrefix,
    posSyncEnabled,
    syncStatus: productSyncStatus,
    variant,
}: VariantProps) => {
    const energyContentSupported = useSelector(getIsEnergyContentSupported) || false;

    const {
        values: { prices },
    } = useFormikContext<EditableProduct>();

    const hasMultiple = prices.length > 1;

    const fieldsContainerClass =
        styles[`variant--${hasMultiple ? "multi" : "single"}--${energyContentSupported ? "three" : "two"}`];

    const syncStatus = hasMultiple ? variant.validationStatus : productSyncStatus;

    return (
        <Draggable
            draggableId={index.toString()}
            index={index}
            isDragDisabled={!hasMultiple || disabled || disableLinkedFields}
        >
            {(provided, snapshot) => (
                <div
                    className={snapshot.isDragging ? styles.containerDragging : styles.container}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                    aria-label={`Variant ${index + 1}`}
                >
                    <div className={styles.variantContainer}>
                        {hasMultiple && !disabled && !disableLinkedFields && (
                            <div className={styles.gripCell}>
                                <Icon size="small" className={styles.gripIcon}>
                                    <Grip />
                                </Icon>
                            </div>
                        )}
                        <FieldErrors
                            fieldNames={["displayName", "sku", "price", "energyContent"].map(
                                (name) => `${pathPrefix}.${name}`
                            )}
                        >
                            <div className={fieldsContainerClass}>
                                {hasMultiple && (
                                    <div className={styles.cell0}>
                                        <div className={styles.cell0_0}>
                                            <FastField
                                                autoComplete="off"
                                                name={`${pathPrefix}.displayName`}
                                                component={Input}
                                                placeholder="Name"
                                                disabled={disabled || disableLinkedFields}
                                                label="Name"
                                            />
                                        </div>
                                        <div className={styles.cell0_1}>
                                            <RemoveButton
                                                disabled={disabled || disableLinkedFields}
                                                index={index}
                                                onRemove={onRemove}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className={styles.cell1}>
                                    <FastField
                                        after={
                                            posSyncEnabled && syncStatus ? (
                                                <PosFieldSyncStatus syncStatus={syncStatus} />
                                            ) : null
                                        }
                                        autoComplete="off"
                                        name={`${pathPrefix}.sku`}
                                        component={Input}
                                        placeholder="SKU"
                                        disabled={disabled || posSyncEnabled}
                                        label="SKU"
                                    />
                                </div>
                                <div className={styles.cell2}>
                                    <FastField
                                        after={
                                            posSyncEnabled && syncStatus ? (
                                                <PosFieldSyncStatus syncStatus={syncStatus} />
                                            ) : null
                                        }
                                        autoComplete="off"
                                        name={`${pathPrefix}.price`}
                                        component={Input}
                                        before={<span className={styles.subtle}>{locationLocale.currencySymbol}</span>}
                                        placeholder="Price"
                                        inputTest={priceInputTest}
                                        parseValue={(x: string) => Number(x || 0).toFixed(2)}
                                        maxLength={6}
                                        disabled={disabled || posSyncEnabled || disableLinkedFields}
                                        label="Price"
                                    />
                                </div>
                                {energyContentSupported && (
                                    <div className={styles.cell3}>
                                        <FastField
                                            autoComplete="off"
                                            name={`${pathPrefix}.energyContent`}
                                            component={Input}
                                            disabled={disabled || disableLinkedFields}
                                            inputTest={buildInputRegex({
                                                numDigits: 5,
                                            })}
                                            pattern="\d*"
                                            after="kcal"
                                            afterClassName={styles.subtle}
                                            label="Calories"
                                        />
                                    </div>
                                )}
                                {hasMultiple && (
                                    <div className={styles.cell4}>
                                        <RemoveButton
                                            disabled={disabled || disableLinkedFields}
                                            index={index}
                                            onRemove={onRemove}
                                        />
                                    </div>
                                )}
                            </div>
                        </FieldErrors>
                    </div>
                </div>
            )}
        </Draggable>
    );
};

interface RemoveButtonProps {
    disabled?: boolean;
    onRemove: (index: number) => void;
    index: number;
}

const RemoveButton = ({ disabled, onRemove, index }: RemoveButtonProps) => {
    return (
        <Button
            className={styles.removeButton}
            role="secondary"
            padding="icon"
            disabled={disabled}
            onClick={() => onRemove(index)}
            type="button"
            aria-label="Remove variant"
        >
            <Icon size="tiny">
                <CancelBig />
            </Icon>
        </Button>
    );
};
