import { auth } from "common/auth";
import styles from "./UserProfileLink.module.scss";
export interface Props {
    onLogout: () => void;
}

export const UserProfileLink = () => {
    const user = auth.getState();

    if (user === null) {
        return null;
    }

    return (
        <div className={styles.root}>
            <div className={styles.name}>Signed in as {user.name}</div>
            <button onClick={() => auth.logout()} className={styles.logout}>
                Log out
            </button>
        </div>
    );
};
