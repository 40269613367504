import styles from "./ListServicesPageWrapper.module.scss";

import { PageHeader } from "core/components/pageHeader";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { useSelector } from "react-redux";
import { RouteComponentProps, Redirect, useParams, Switch, Route } from "react-router";
import { DayServicesPage, ListServicesPage, HeaderActions, WeekServicesPage, ServicesNotFound } from ".";
import { Props as ListServicesPageProps } from "./ListServicesPage";
import { CalendarProvider } from "features/service/context/CalendarContext";
import { ServiceRouteParams } from "features/service/types/ServiceRouteParams";
import { servicesRoute } from "features/service";
import { Tab } from "features/service/types/Tab";

export const ListServicesPageWrapper = (props: ListServicesPageProps & RouteComponentProps<any>) => {
    const activeLocation = useSelector(getActiveLocation);

    const { tab } = useParams<ServiceRouteParams>();

    const servicesExist = !!props.liveServices.length || !!props.otherServices.length;

    if (!activeLocation) {
        return null;
    }

    if (!activeLocation.enableServiceSchedule) {
        return (
            <Switch>
                <Redirect from={`${servicesRoute}/${Tab.LIST}/:id?`} to={`${servicesRoute}/:id?`} />
                <Redirect from={`${servicesRoute}/${Tab.DAY}/:id?`} to={`${servicesRoute}/:id?`} />
                <Redirect from={`${servicesRoute}/${Tab.WEEK}/:id?`} to={`${servicesRoute}/:id?`} />
                <ListServicesPage {...props} useLegacyLayout={true} />
            </Switch>
        );
    }

    return (
        <div className={tab === Tab.LIST ? styles.paddedContainer : styles.container}>
            <CalendarProvider>
                <PageHeader
                    title="Services"
                    subtitle="Services let you control when your menus are visible on me&u"
                    actions={<HeaderActions newItemPath={`${props.match.url}/new`} permissions={props.permissions} />}
                />
                <Switch>
                    <Route
                        key="services-list"
                        path={`${servicesRoute}/${Tab.LIST}/:id?`}
                        render={() => <ListServicesPage {...props} />}
                    />
                    <Route
                        key="services-week"
                        path={`${servicesRoute}/${Tab.WEEK}/:id?`}
                        render={() => (servicesExist ? <WeekServicesPage /> : <ServicesNotFound tab={Tab.WEEK} />)}
                    />
                    <Route
                        key="services-day"
                        path={`${servicesRoute}/${Tab.DAY}/:id?`}
                        render={() => (servicesExist ? <DayServicesPage /> : <ServicesNotFound tab={Tab.DAY} />)}
                    />
                    <Redirect from={`${servicesRoute}/:id?`} to={`${servicesRoute}/${Tab.LIST}/:id?`} />
                </Switch>
            </CalendarProvider>
        </div>
    );
};
