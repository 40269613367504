import { Card, Row, Title } from "core/components/card";
import { FastField, useFormikContext } from "formik";
import { FieldErrors } from "core/components/form/fieldErrors";
import { MediaUpload, MediaUploadDragInfo } from "core/components/mediaUpload";
import { menuDataUrl } from "common/menuData/menuDataUrl";
import { Subtitle } from "core/components/card/Subtitle";
import { Switch } from "core/components/form/switch";

interface Props {
    disableFields: boolean;
    productVideoEnabled: boolean;
}

export const ProductImage = ({ disableFields, productVideoEnabled }: Props) => {
    const {
        values: { itemType },
    } = useFormikContext<any>();

    let dimensions;

    if (itemType === "food") {
        dimensions = " Dimensions 800x560.";
    } else if (itemType === "drink") {
        dimensions = " Dimensions 800x1048.";
    } else {
        dimensions = "";
    }

    return (
        <Card>
            <Row collapse="down">
                <Title title="Image" />
            </Row>
            <Row collapse="both">
                <div>
                    <Subtitle subtitle={`Must be .jpg or .png. Maximum size: 4MB.${dimensions}`} />
                </div>
            </Row>
            <Row>
                <FieldErrors fieldNames={["image"]}>
                    <FastField
                        acceptedFileTypes={{
                            "image/png": [],
                            "image/jpg": [],
                            "image/jpeg": [],
                        }}
                        component={MediaUpload}
                        defaultAltText="product"
                        disabled={disableFields}
                        getDragMessage={getDragMessage}
                        getThumbUrl={getThumbUrl}
                        maxFileSizeMegabytes={4}
                        name="image"
                        resetFileInfo={true}
                        showFile={true}
                        showReset={false}
                        uploadButtonLabel="Upload image"
                    />
                </FieldErrors>
            </Row>
            {productVideoEnabled && (
                <Row border="top">
                    <label htmlFor="showVideoUpload">Attach a video</label>
                    <FastField
                        name="showVideoUpload"
                        component={Switch}
                        disabled={disableFields}
                        type="checkbox"
                        id="showVideoUpload"
                    />
                </Row>
            )}
        </Card>
    );
};

function getDragMessage({ isDragActive }: MediaUploadDragInfo) {
    return isDragActive ? "Drop image" : "or drag to upload";
}

function getThumbUrl(thumb: string | undefined) {
    return thumb ? menuDataUrl(thumb) : undefined;
}
