import { AppState } from "features";
import { Props, LocationSelector } from "../components/LocationSelector";
import { LocationComponentProps } from "..";
import { connect } from "react-redux";
import { withLocation } from "./withLocation";
import { DataProps } from "types/utils";

function mapStateToProps(state: AppState, { restaurantLocation }: LocationComponentProps): DataProps<Props> {
    return {
        restaurantLocation,
    };
}

export const LocationSelectorContainer = withLocation(connect(mapStateToProps)(LocationSelector));
