import { AppDispatch, AppState } from "features";
import { createAction } from "features/catalogue/reducers/posSync";
import { fetchActive } from "features/location/actions";
import { syncPosCatalogue } from "../api/syncPosCatalogue";
import * as catalogueActions from "features/catalogue/actions";
import * as modifierActions from "features/modifier/actions";
import { createAction as trackingActions } from "common/appInsights/actions/track";
import { getSyncFromPosTrackingEventData } from "../selectors/getSyncFromPosTrackingEventData";
import { setLastItemUpdated } from "features/location/actions/setLastItemUpdated";

export const syncFromPos = (locationId: string) => async (dispatch: AppDispatch, getState: () => AppState) => {
    const state = getState();

    if (state.catalogue.posSync.status === "loading") {
        return;
    }

    dispatch(createAction.loading());

    try {
        const result = await syncPosCatalogue(locationId);

        if (!result.syncStarted) {
            throw "Sync failed - there is already an operation in progress for this venue";
        }

        if (!result.catalogueRetrieved || !result.catalogueSynced) {
            throw result.responseMessage;
        }

        dispatch(setLastItemUpdated());

        dispatch(createAction.loaded(result));

        dispatch(fetchActive(locationId, true));

        dispatch(modifierActions.list(locationId, true));

        dispatch(catalogueActions.listProducts(locationId, true));
    } catch (e: any) {
        dispatch(createAction.failed(e));
    } finally {
        dispatch(trackingActions.track(getSyncFromPosTrackingEventData(getState())));
    }
};
