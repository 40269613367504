import { ActiveLocation } from "features/location";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { createSelector } from "reselect";
import { getPromotionsList } from ".";
import { Promotion, PromotionDiscountType, PromotionScheduleStateKeys } from "../types";

export const getPromotionsSummary = createSelector(getPromotionsList, getActiveLocation, (list, location) =>
    location ? list.map((p) => updatePromitionStatus(location, p)) : list
);

function updatePromitionStatus(location: ActiveLocation, promotion: Promotion): Promotion {
    const lastPublishedMs = location.lastPublished ? new Date(location.lastPublished).getTime() : Number.MIN_VALUE;
    const promotionLastUpdatedMs = promotion?.dateUpdated
        ? new Date(promotion.dateUpdated).getTime()
        : Number.MIN_VALUE;

    return {
        ...promotion,
        status:
            promotion.discount.type === PromotionDiscountType.PRICELIST && promotionLastUpdatedMs > lastPublishedMs
                ? ("STAGED" as PromotionScheduleStateKeys)
                : promotion.status,
    };
}
