import { getPosFieldSchema } from "components/forms/posField/getPosFieldSchema";
import { ActiveLocation } from "features/location";
import { validationEnabledFn, ValidationOptions } from "features/locationSettings/schema/ValidationOptions";
import { TrayCharge } from "features/trayCharge/types";
import * as Yup from "yup";

export const getTrayChargeSchema = (options: ValidationOptions) => {
    const { minNameCharLength, maxNameCharLength, nameRangeMsg, nameCharacterMsg } = getNameRules();
    const { minFixedAmount, maxFixedAmount, fixAmountRequiredMsg, fixAmountRangeMsg } = getFixedAmountRules(options);

    return Yup.object<TrayCharge>().shape({
        id: Yup.string(),
        enabled: Yup.boolean(),
        name: Yup.string().when(["id", "enabled"], {
            is: validationEnabledFn,
            then: Yup.string()
                .nullable(true)
                .min(minNameCharLength, nameRangeMsg)
                .max(maxNameCharLength, nameRangeMsg)
                .matches(/^[a-zA-Z]+[a-zA-Z ]+$/, nameCharacterMsg),
        }),
        fixedAmount: Yup.number().when(["id", "enabled"], {
            is: validationEnabledFn,
            then: Yup.number()
                .typeError("Valid number required")
                .required(fixAmountRequiredMsg)
                .min(minFixedAmount, fixAmountRangeMsg)
                .max(maxFixedAmount, fixAmountRangeMsg),
        }),
        posId: findPosFieldSchema(),
    });
};

function getNameRules() {
    const minNameCharLength = 5;
    const maxNameCharLength = 28;
    const nameRangeMsg = `Tray charge name must be between ${minNameCharLength} and ${maxNameCharLength} characters.`;
    const nameCharacterMsg = `Tray charge name cannot contain numbers or special characters and must start with a letter.`;
    return { minNameCharLength, maxNameCharLength, nameRangeMsg, nameCharacterMsg };
}

function getFixedAmountRules(options: ValidationOptions) {
    const minFixedAmount = 0.1;
    const maxFixedAmount = 99.99;
    const fixAmountRequiredMsg = `A tray charge amount between ${options.formatCurrency(
        minFixedAmount
    )} and ${options.formatCurrency(maxFixedAmount)} is required.`;
    const fixAmountRangeMsg = `Tray charge amount must be between ${options.formatCurrency(
        minFixedAmount
    )} and ${options.formatCurrency(maxFixedAmount)}.`;
    return {
        minFixedAmount,
        maxFixedAmount,
        fixAmountRequiredMsg,
        fixAmountRangeMsg,
    };
}

function findPosFieldSchema() {
    return Yup.string().when(
        ["id", "enabled", "$restaurantLocation"],
        (id, enabled, restaurantLocation: ActiveLocation, schema) => {
            if (!validationEnabledFn(id, enabled)) return schema;
            const posField = restaurantLocation.posMetadata.operations
                .filter((o) => o.supported)
                .find((o) => o.name === "OrderCreate")
                ?.fields.find((f) => f.name === "trayChargePosId");

            if (posField) {
                return getPosFieldSchema(posField);
            }

            return schema.nullable(true);
        }
    );
}
