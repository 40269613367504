import { Stats } from "../types";
import { LocationLocaleContext } from "features/location";
import { StatsItem } from "./StatsItem";
import classNames from "classnames";
import "./GroupTabsStats.scss";

export interface Props {
    stats: Stats | undefined;
    groupTabsEnabled: boolean;
}

export const GroupTabsStats = ({ stats, groupTabsEnabled }: Props) => {
    const { averageTabMembers, averageTabLimit, totalSpend } = stats || {};

    const classes = classNames({
        "group-tabs-stats": true,
        "group-tabs-stats--empty": !stats,
        "group-tabs-stats--disabled": !groupTabsEnabled,
    });

    return (
        <LocationLocaleContext.Consumer>
            {(locale) => (
                <div className={classes}>
                    <StatsItem
                        name="Average Group Tab members"
                        value={
                            averageTabMembers !== undefined ? locale.formatNumber(Math.round(averageTabMembers)) : "-"
                        }
                    />
                    <StatsItem
                        name="Average Group Tab limit"
                        value={averageTabLimit !== undefined ? locale.formatCurrency(averageTabLimit) : "-"}
                    />
                    <StatsItem
                        name="Total spend"
                        value={totalSpend !== undefined ? locale.formatCurrency(totalSpend) : "-"}
                    />
                </div>
            )}
        </LocationLocaleContext.Consumer>
    );
};
