import Table from "antd/lib/table";
import classNames from "classnames";
import { useCallback } from "react";
import { applyDatasourceUpdates } from "../surchargeHelper";
import { EditableSurcharge } from "../types";
import { FieldUpdate } from "../types/SurchargeField";
import { SurchargeTableProps } from "../types/SurchargeTableProps";
import { RecurringSurchargeColumns } from "./SurchargeColumns";

export const RecurringSurchargesTable = ({
    surchargeIdColumn,
    invalidItems,
    touchedIds,
    surcharges,
    initialSurcharges,
    getRowClass,
    disabled,
    onChange,
    onRowsTouched,
    onClearRow,
}: SurchargeTableProps) => {
    const handleUpdateField = useCallback(
        (surcharge: EditableSurcharge, updates: FieldUpdate[], checkCheckBoxes: boolean = false) => {
            const newDatasource = applyDatasourceUpdates(
                surcharge,
                surcharges,
                initialSurcharges,
                updates,
                checkCheckBoxes,
                touchedIds
            );
            onRowsTouched(touchedIds);
            onChange(newDatasource);
        },
        [onChange, onRowsTouched, surcharges, touchedIds, initialSurcharges]
    );

    return (
        <Table
            columns={RecurringSurchargeColumns({
                surchargeIdColumn,
                invalidItems,
                onUpdateField: handleUpdateField,
                onClearRow: onClearRow,
                disableFields: disabled,
            })}
            className={classNames("surcharges-page__body__recurring-table", surchargeIdColumn && "with-surcharge-id")}
            dataSource={surcharges}
            size="small"
            rowKey="day"
            pagination={false}
            rowClassName={getRowClass}
        ></Table>
    );
};
