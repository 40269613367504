import styles from "../ListItem.module.scss";

import classNames from "classnames";
import { useMedia } from "common/hooks";

export interface HeadersProps {}

export const Headers = ({}: HeadersProps) => {
    const isMedium = useMedia("(min-width: 672px)");

    return (
        <div className={styles.contentContainer}>
            <span role="columnheader" className={classNames(styles.cellHeader, styles.cellName)}>
                {isMedium ? "Product" : "Products"}
            </span>
            <span
                role="columnheader"
                className={classNames(
                    styles.cellHeader,
                    styles.cellSmall,
                    styles.cellHideFirst,
                    "hide-if-parent-location"
                )}
            >
                Category
            </span>
            <span
                role="columnheader"
                className={classNames(styles.cellHeader, styles.cellTiny, styles.cellNumber, "hide-until-medium")}
            >
                Price
            </span>
            <span
                role="columnheader"
                className={classNames(
                    styles.cellHeader,
                    styles.cellSmall,
                    styles.cellNumber,
                    styles.cellFirstWrap,
                    "hide-until-medium"
                )}
            >
                SKU
            </span>
            {/* TODO calories https://meanduapp.atlassian.net/browse/INT-277 */}
            {/* <span
            role="cell"
            className={classNames(
                styles.cellHeader,
                styles.cellSmall,
                styles.cellNumber,
                styles.cellHideLast
            )}
        >
            Calories
        </span> */}
            <span role="columnheader" className={classNames(styles.cellHeader, styles.cellSmall, "hide-until-medium")}>
                Status
            </span>
        </div>
    );
};
