import styles from "./ModalSyncSuccessful.module.scss";
import { Alert, AlertStatus } from "core/components/alert";
import { CardWidth, Confirm, Modal } from "core/components/modal";
import { Row } from "core/components/card";
import { CheckCircle } from "common/icons";
import { PosSyncResult } from "../types";
import { simplePlural } from "common/utility/StringUtils";

interface Props {
    result: PosSyncResult;
    onClose: () => void;
    visible: boolean;
}

export const ModalSyncSuccessful = ({ result, onClose, visible }: Props) => {
    const footer = <Confirm confirmLabel="Continue" showCancel={false} />;
    const missingInPos = !result.syncStats
        ? false
        : !!result.syncStats.productStats.missingFromPosCount ||
          !!result.syncStats.variantStats.missingFromPosCount ||
          !!result.syncStats.modifierGroupStats.missingFromPosCount ||
          !!result.syncStats.modifierOptionStats.missingFromPosCount;

    const productsSynced = result.syncStats?.productStats.syncedCount || 0;
    const variantsSynced = result.syncStats?.variantStats.syncedCount || 0;
    const modifiersGroupsSynced = result.syncStats?.modifierGroupStats.syncedCount || 0;
    const modifierOptionsSynced = result.syncStats?.modifierOptionStats.syncedCount || 0;
    const missingProducts = result.syncStats?.productStats.missingFromPosCount || 0;
    const missingModifierGroups = result.syncStats?.modifierGroupStats.missingFromPosCount || 0;

    return (
        <Modal visible={visible} onClose={onClose} title={"Sync successful"} footer={footer} width={CardWidth.NARROW}>
            <Row className={styles.successRow}>
                <span className={styles.successIcon}>
                    <CheckCircle />
                </span>
                <div className={styles.successInfo}>
                    <h2 className={styles.successHeading}>Sync successful</h2>
                    <ul>
                        <li>{`${productsSynced} Product${simplePlural(
                            productsSynced
                        )} updated (with ${variantsSynced} Variant${simplePlural(variantsSynced)})`}</li>
                        <li>{`${modifiersGroupsSynced} Modifier${simplePlural(
                            modifiersGroupsSynced
                        )} updated (with ${modifierOptionsSynced} Modifier option${simplePlural(
                            modifierOptionsSynced
                        )})`}</li>
                    </ul>
                </div>
                {!result.catalogueSynced && (
                    <Alert title="Unable to sync with POS" status={AlertStatus.WARNING}>
                        {`${result.responseMessage}`}
                    </Alert>
                )}
                {result.catalogueSynced && missingInPos && (
                    <Alert title="Some Items have failed to sync" status={AlertStatus.WARNING}>
                        {`${missingProducts} product${simplePlural(
                            missingProducts
                        )} and ${missingModifierGroups} modifier${simplePlural(
                            missingModifierGroups
                        )} are missing from the POS. They need to be reviewed before being published.`}
                    </Alert>
                )}
            </Row>
        </Modal>
    );
};
