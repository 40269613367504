import * as React from "react";
import { CompanyDetails } from "../types/CompanyDetails";
import { FastField, Field, FieldProps, Formik, validateYupSchema, yupToFormErrors } from "formik";
import { Divider, Form } from "antd";
import { EditRow } from "common/scaffolding/components/EditRow";
import { SaveStatus } from "common/loader";
import { editableLocationSchema } from "../schema/EditableLocationSchema";
import { CheckboxInput, FieldErrors, PercentageInput, TextInput } from "components/forms";
import { EditableLocation } from "../types/EditableLocation";
import { PageTitle } from "common/scaffolding/components/DataListingPage/PageTitle";
import { TimeZoneSelector } from "./TimeZoneSelector";
import { LocaleCurrencySelector } from "./LocaleCurrencySelector";
import { Group } from "features/group/types/Group";
import { GroupSelector, NEW_GROUP } from "features/group/components/GroupSelector";
import { PermissionSet } from "../types/PermissionSet";
import { QuickCopyInput } from "components/forms/QuickCopyInput";

import "./EditCompanyDetails.scss";
import { ActionFooter, DeleteButton, StatusMessage, SubmitMode } from "core/components/actionFooter";
import { Button } from "core/components/button";
import { ScrollToFormikError } from "components/forms/ScrollToFormikError";

export interface EditLocationProps {
    initialValue: EditableLocation;
    fetch: () => void;
    onSubmit: (data: EditableLocation) => void;
    onDelete: () => void;
    saveStatus?: SaveStatus;
    groups: Group[];
    permissions: PermissionSet;
    requiresBillingSystemId: boolean;
    requiresStripeCommission: boolean;
}

export interface EditLocationState {
    companyDetails: CompanyDetails;
    billingSystemId: string;
    stripeCommission?: number;
    submitMode: SubmitMode;
    canUpdateSettings: boolean;
    canDelete: boolean;
    canCreateGroup: boolean;
    confirmDelete: boolean;
}

export class EditCompanyDetailsPage extends React.Component<EditLocationProps, EditLocationState> {
    constructor(props: EditLocationProps) {
        super(props);

        this.state = {
            companyDetails: props.initialValue.companyDetails,
            billingSystemId: props.initialValue.billingSystemId,
            stripeCommission: props.initialValue.stripeCommission,
            submitMode: "save",
            canUpdateSettings: props.permissions.has("location:settings:update"),
            canDelete: props.permissions.has("location:delete"),
            canCreateGroup: props.permissions.has("group:create"),
            confirmDelete: false,
        };
    }

    handleOnSubmit = (data: EditableLocation) => {
        const companyDetails = data.companyDetails;
        const billingSystemId = data.billingSystemId;
        const stripeCommission = data.stripeCommission;

        this.setState({
            companyDetails,
            billingSystemId,
            stripeCommission,
        });

        this.props.onSubmit(data);
    };

    handleDelete = () => {
        this.setState({
            submitMode: "archive",
        });
        this.props.onDelete();
    };

    validate = (value: EditableLocation) => {
        const context = {
            companyDetails: this.state.companyDetails,
            billingSystemId: this.state.billingSystemId,
            stripeCommission: this.state.stripeCommission,
            requiresBillingSystem: this.props.requiresBillingSystemId,
            requiresStripeCommission: this.props.requiresStripeCommission,
        };

        return validateYupSchema(value, editableLocationSchema, undefined, context).then(
            () => ({}),
            (err: any) => yupToFormErrors(err)
        );
    };

    getBusinessNumberLabel = () => {
        const {
            initialValue: { locale },
        } = this.props;

        switch (locale.toLowerCase()) {
            case "en-au":
                return "ABN";
            case "en-nz":
                return "NZBN";
            case "en-gb":
                return "VAT Number";
            case "en-us":
                return "EIN";
            default:
                return "ABN";
        }
    };

    render() {
        const { initialValue, saveStatus, groups } = this.props;
        const disableFields = false;
        const businessNumberLabel = this.getBusinessNumberLabel();

        return (
            <div className="edit-company-details-page">
                <Formik
                    validate={this.validate}
                    initialValues={initialValue}
                    onSubmit={this.handleOnSubmit}
                    enableReinitialize
                >
                    {(form) => (
                        <Form
                            onFinish={form.handleSubmit}
                            layout="vertical"
                            autoComplete="off"
                            className="padding-bottom-60"
                        >
                            <ScrollToFormikError />
                            <PageTitle
                                title="Company Details"
                                description="This is the tax information about the venue. It will appear on customer receipts, so please make sure it is accurate and kept updated."
                            />
                            <Divider />
                            <EditRow
                                title="Display Name"
                                titleInfo="(Requires publish)"
                                subTitle="Enter the name of your venue as you want it to display in me&u."
                            >
                                <FieldErrors name="displayName" form={form}>
                                    <FastField
                                        name="displayName"
                                        component={TextInput}
                                        placeholder="Display Name"
                                        disabled={disableFields}
                                    />
                                </FieldErrors>
                            </EditRow>
                            {groups.length > 0 && (
                                <EditRow
                                    title="Group"
                                    subTitle="Add this location to an existing group or create a new group."
                                >
                                    <>
                                        <FieldErrors name="groupId" form={form}>
                                            <GroupSelector
                                                name="groupId"
                                                disabled={disableFields}
                                                placeholder="Select a group"
                                                groups={groups}
                                                enableNew={this.state.canCreateGroup}
                                            />
                                        </FieldErrors>
                                        <Field>
                                            {({ form }: FieldProps) =>
                                                form.values.groupId === NEW_GROUP.id ? (
                                                    <FieldErrors name="newGroupName" form={form}>
                                                        <FastField
                                                            name={"newGroupName"}
                                                            component={TextInput}
                                                            disabled={disableFields}
                                                            placeholder="Enter a new group name"
                                                            disableSearch={true}
                                                            allowClear={true}
                                                        />
                                                    </FieldErrors>
                                                ) : null
                                            }
                                        </Field>
                                    </>
                                </EditRow>
                            )}
                            <EditRow title="Venue ID">
                                <FastField name="id" component={QuickCopyInput} readonly />
                            </EditRow>
                            <EditRow title="Registered Name" subTitle="Enter the registered business name.">
                                <FieldErrors name="companyDetails.registeredName" form={form}>
                                    <FastField
                                        name="companyDetails.registeredName"
                                        component={TextInput}
                                        placeholder="Registered Business Name"
                                        disabled={disableFields}
                                    />
                                </FieldErrors>
                            </EditRow>
                            <EditRow title={businessNumberLabel} subTitle={`Enter the ${businessNumberLabel}.`}>
                                <FieldErrors name="companyDetails.abn" form={form}>
                                    <FastField
                                        name="companyDetails.abn"
                                        component={TextInput}
                                        placeholder="123 456 789"
                                        disabled={disableFields}
                                    />
                                </FieldErrors>
                            </EditRow>
                            <EditRow title="Address" subTitle="Enter their addess.">
                                <FieldErrors name="companyDetails.address" form={form}>
                                    <FastField
                                        name="companyDetails.address"
                                        component={TextInput}
                                        placeholder="10 Elm Street, Surry Hills NSW"
                                        disabled={disableFields}
                                    />
                                </FieldErrors>
                            </EditRow>
                            {initialValue.isMarketingEnabled && (
                                <EditRow
                                    title="Privacy Policy URL"
                                    subTitle="Insert a URL to your venue's privacy policy if you want customers to be able to view it when showing the marketing opt-in modal."
                                >
                                    <FieldErrors name="companyDetails.privacyPolicyUrl" form={form}>
                                        <FastField
                                            name="companyDetails.privacyPolicyUrl"
                                            component={TextInput}
                                            placeholder="https://www.privacypolicy.com"
                                            disabled={disableFields}
                                        />
                                    </FieldErrors>
                                </EditRow>
                            )}
                            {this.state.canUpdateSettings && (
                                <>
                                    <EditRow
                                        title="Time Zone"
                                        subTitle="Select the time zone that your venue is located in."
                                    >
                                        <FieldErrors name="timeZone" form={form}>
                                            <TimeZoneSelector
                                                name="timeZone"
                                                disabled={disableFields}
                                                placeholder="Select a time zone"
                                            />
                                        </FieldErrors>
                                    </EditRow>
                                    <EditRow
                                        title="Currency"
                                        titleInfo="(Requires publish)"
                                        subTitle="Select the currency you want your customers to pay with."
                                    >
                                        <FieldErrors name="locale" form={form}>
                                            <LocaleCurrencySelector
                                                name="locale"
                                                placeholder="Select a currency"
                                                disabled={disableFields}
                                            />
                                        </FieldErrors>
                                    </EditRow>
                                </>
                            )}
                            <Divider />
                            <PageTitle
                                title="Billing Details"
                                description="This information is required so that orders can be processed and payments issued to the venue."
                            />
                            <Divider />
                            <EditRow
                                title="Billing System Id"
                                subTitle="The ID of this venue from our billing system."
                            >
                                <FieldErrors name="billingSystemId" form={form}>
                                    <FastField
                                        name="billingSystemId"
                                        component={TextInput}
                                        placeholder="1234"
                                        disabled={disableFields}
                                    />
                                </FieldErrors>
                            </EditRow>
                            {form.values.stagedBillingSystemId && (
                                <EditRow
                                    title="Staged Billing System Id"
                                    subTitle="Copy this into Billing System Id when onboarding has completed"
                                >
                                    <FastField
                                        name="stagedBillingSystemId"
                                        component={QuickCopyInput}
                                        readonly
                                    />
                                </EditRow>
                            )}
                            {this.props.requiresStripeCommission && (
                                <EditRow
                                    title="Stripe Commission Rate"
                                    subTitle="The commission taken by me&u for Stripe payments."
                                >
                                    <FieldErrors name="stripeCommission" form={form}>
                                        <FastField
                                            name="stripeCommission"
                                            component={PercentageInput}
                                            disabled={disableFields}
                                        />
                                    </FieldErrors>
                                </EditRow>
                            )}
                            <Divider />
                            <EditRow
                                title="Demo Mode"
                                subTitle="When enabled, payments will not be taken. Orders will still be sent to POS, if integrated."
                            >
                                <FieldErrors name="isDemo" form={form}>
                                    <FastField name="isDemo" component={CheckboxInput} disabled={disableFields} />
                                </FieldErrors>
                            </EditRow>
                            {form.values.isDemo && (
                                <EditRow
                                    title="Sales mode"
                                    subTitle="When enabled, any indications that the venue is in demo mode will be hidden in the me&u app."
                                >
                                    <FieldErrors name="isSalesMode" form={form}>
                                        <Field name="isSalesMode" component={CheckboxInput} disabled={disableFields} />
                                    </FieldErrors>
                                </EditRow>
                            )}
                            <ActionFooter align="left">
                                {!disableFields && (
                                    <Button loading={saveStatus === "saving"} type="submit" disabled={!form.dirty}>
                                        Save
                                    </Button>
                                )}
                                <StatusMessage />
                                {this.state.canDelete && (
                                    <>
                                        <DeleteButton
                                            buttonProps={{
                                                disabled: !this.state.canDelete,
                                                type: "button",
                                                onClick: this.handleDelete,
                                            }}
                                            saving={saveStatus === "saving"}
                                            submitMode={this.state.submitMode}
                                            confirmDelete={{
                                                title: `Are you sure you want to delete this location '${this.props.initialValue.displayName}'?`,
                                            }}
                                        />
                                    </>
                                )}
                            </ActionFooter>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}
