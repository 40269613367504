import { connect } from "react-redux";
import { AppState } from "features";
import * as actions from "features/priceList/actions";
import { actions as categoryActions } from "features/category";
import { actions as catalogueActions } from "features/catalogue";
import { actions as modifierActions } from "features/modifier";
import { RouteComponentProps, withRouter } from "react-router";
import { withLoader, PageLoadingProps } from "common/loader/withLoader";
import { DataProps, FunctionProps } from "types/utils";
import { isLoaded } from "common/loader/isLoaded";
import { isNew } from "common/utility/modelUtils";
import { EditPriceListPage, Props, TabKeys } from "../components/EditPriceListPage";
import { LocationComponentProps, withLocation } from "features/location";
import { EditablePriceLevel, EditablePriceListSettings, PriceListItem } from "../types";
import { getCrudPermissions } from "features/location/selectors/getCrudPermissions";
import { mergeStatus } from "common/loader";
import { getProductCatalogueItems } from "features/catalogue/selectors/getProductCatalogueItems";
import { getModifierCatalogueItems } from "features/catalogue/selectors/getModifierCatalogueItems";
import { getPriceOverrideCatalogueTree } from "common/selectors/getCatalogueTree";

const mapStateToProps = (
    state: AppState,
    { match: { params } }: RouteComponentProps<RouteParams>
): DataProps<PageLoadingProps<Props>> => {
    const {
        priceLists: { edit, bulkEdit },
        categories: { list: categoryList },
        menuItems: { list: menuItemList },
        modifiers: { list: modifierList },
    } = state;

    const overallFetchStatus = mergeStatus(edit.status, categoryList.status, menuItemList.status, modifierList.status);

    if (isLoaded(edit) && isLoaded(categoryList) && isLoaded(menuItemList) && isLoaded(modifierList)) {
        return {
            initialValues: edit.data,
            categories: categoryList.data,
            saveStatus: edit.saveStatus,
            loadStatus: "loaded",
            bulkSaveStatus: bulkEdit.saveStatus,
            permissions: getCrudPermissions(state).pricelist,
            products: getProductCatalogueItems(state),
            modifiers: getModifierCatalogueItems(state),
            priceListTree: getPriceOverrideCatalogueTree(state),
        };
    }

    return {
        loadStatus: overallFetchStatus,
    };
};

const mapDispatchToProps = (
    dispatch: any,
    {
        restaurantLocation,
        history,
        match: {
            params: { location, id, region },
        },
    }: LocationComponentProps & RouteComponentProps<RouteParams>
): FunctionProps<PageLoadingProps<Props>> => ({
    fetch: () => {
        dispatch(actions.edit(restaurantLocation.id, id, isNew(id) ? TabKeys.Settings : TabKeys.Items));
        dispatch(categoryActions.list(location));
        dispatch(catalogueActions.listProducts(location));
        dispatch(modifierActions.list(location));
    },
    onSubmit: (data: EditablePriceListSettings) => {
        dispatch(actions.save(location, region, data, history));
    },
    onBulkSave: (priceLevels: PriceListItem[], deleteItems: PriceListItem[]) => {
        dispatch(actions.bulkSave(restaurantLocation.id, id, priceLevels, deleteItems));
    },
    onArchive: () => dispatch(actions.archive(restaurantLocation, region, history)),
    onClone: (data: EditablePriceListSettings, priceLevels: PriceListItem[]) => {
        dispatch(actions.clone(restaurantLocation, region, data, priceLevels, history));
    },
    onSaveItems: (data: EditablePriceListSettings, products: EditablePriceLevel[], modifiers: EditablePriceLevel[]) => {
        dispatch(actions.saveItems(location, region, id, products, modifiers));
    },
});

interface RouteParams {
    id: string;
    location: string;
    region: string;
}

export const EditPriceListsPageContainer = withRouter(
    withLocation(connect(mapStateToProps, mapDispatchToProps)(withLoader(EditPriceListPage)))
);
