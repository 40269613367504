import { createSelector } from "reselect";
import { getActiveLocation, getLocationPermissions } from "../../location/selectors/getLocationPermissions";
import { PageSectionAccess } from "../components/EditLocationSettingsPage";
import { posOperationBehaviorFilter } from "../../posConfiguration/selectors/posOperationFilter";
import { getLocationSettings } from ".";

export const getTrayChargeSettingsAccess = createSelector(
    getActiveLocation,
    getLocationSettings,
    getLocationPermissions,
    posOperationBehaviorFilter("OrderCreate", "TrayCharge"),
    (location, settings, permissions, posSupportsTrayCharge): PageSectionAccess => {
        const locale = location?.locale;
        const trayCharge = settings?.trayCharge;
        const hasUpdatePermission = permissions.hasAny("settings:update");

        const localeSupportsTrayCharge = locale !== "en-US";
        const hasReadTrayChargePermission = permissions.hasAny("traycharge:read");
        const hasUpdateTrayChargePermission = permissions.hasAny("traycharge:create", "traycharge:update");
        const trayChargeIsActive = !!trayCharge?.enabled;

        if (!localeSupportsTrayCharge || !posSupportsTrayCharge) return "off";
        if (hasUpdatePermission && hasUpdateTrayChargePermission) return "editable";
        if (trayChargeIsActive && hasReadTrayChargePermission) return "read-only";

        return "off";
    }
);
