import { connect } from "react-redux";
import { withLoader, PageLoadingProps } from "common/loader/withLoader";
import { ViewBrandingPage, Props } from "../components/ViewBrandingPage";
import { AppState, AppDispatch } from "features";
import { LocationComponentProps, withLocation } from "features/location";
import { DataProps, FunctionProps } from "types/utils";
import { withRouter } from "react-router";

const mapStateToProps = (
    state: AppState,
    { restaurantLocation }: LocationComponentProps
): DataProps<PageLoadingProps<Props>> => {
    return {
        loadStatus: "loaded",
        displayName: restaurantLocation.displayName,
        backgroundImage: restaurantLocation.backgroundImage,
        menuBackgroundImage: restaurantLocation.menuBackgroundImage,
        heroImage: restaurantLocation.heroImage,
        logoUrl: restaurantLocation.logoUrl,
        themeColor: restaurantLocation.themeColor,
    };
};
const mapDispatchToProps = (dispatch: AppDispatch): FunctionProps<PageLoadingProps<Props>> => ({});

export const ViewBrandingPageContainer = withRouter(
    withLocation(connect(mapStateToProps, mapDispatchToProps)(withLoader(ViewBrandingPage)))
);
