import { actions as categoryActions } from "features/category";
import { actions as catalogueActions } from "features/catalogue";
import { actions as modifierActions } from "features/modifier";
import { AppState } from "features";
import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { EditableTaxClass } from "../types";
import { EditTaxPage, Props } from "../components/EditTaxPage";
import { getCrudPermissions } from "features/location/selectors/getCrudPermissions";
import { isLoaded } from "common/loader/isLoaded";
import { LocationComponentProps, withLocation } from "features/location";
import { mergeStatus } from "common/loader";
import { RouteComponentProps, withRouter } from "react-router";
import { withLoader, PageLoadingProps } from "common/loader/withLoader";

import { getTaxesProductTree } from "../selectors/getTaxesProductTree";
import { fromPercentage } from "../taxesHelpers";
import * as actions from "../actions";
import { getPosFieldSettings } from "components/forms/posUI/posSettings";
import { LocationRouteParams } from "common/types";

const mapStateToProps = (
    state: AppState,
    {
        restaurantLocation: { postype },
        match: { params },
    }: LocationComponentProps & RouteComponentProps<LocationRouteParams>
): DataProps<PageLoadingProps<Props>> => {
    const {
        taxes: { edit },
        categories: { list: categoryList },
        menuItems: { list: menuItemList },
        modifiers: { list: modifierList },
    } = state;

    const overallFetchStatus = mergeStatus(edit.status);

    if (isLoaded(edit) && isLoaded(categoryList) && isLoaded(menuItemList) && isLoaded(modifierList)) {
        const taxPosIdFieldSettings = getPosFieldSettings(postype || "", "taxPosId");
        return {
            tax: edit.data,
            saveStatus: edit.saveStatus,
            loadStatus: "loaded",
            permissions: getCrudPermissions(state).taxes,
            productTree: getTaxesProductTree(state),
            taxPosIdFieldSettings,
        };
    }

    return {
        loadStatus: overallFetchStatus,
    };
};

const mapDispatchToProps = (
    dispatch: any,
    {
        restaurantLocation,
        history,
        match: {
            params: { location, id, region },
        },
    }: LocationComponentProps & RouteComponentProps<RouteParams>
): FunctionProps<PageLoadingProps<Props>> => ({
    fetch: () => {
        dispatch(actions.edit(restaurantLocation.id, id));
        dispatch(categoryActions.list(location));
        dispatch(catalogueActions.listProducts(location));
        dispatch(modifierActions.list(location));
    },
    onSubmit: (data: EditableTaxClass) => {
        data = mapEditableTaxClass(data);
        dispatch(actions.save(location, data));
    },
    onArchive: () => {
        dispatch(actions.archive(location));
    },
});

interface RouteParams {
    id: string;
    location: string;
    region: string;
}

export const EditTaxPageContainer = withRouter(
    withLocation(connect(mapStateToProps, mapDispatchToProps)(withLoader(EditTaxPage)))
);

const mapEditableTaxClass = (tax: EditableTaxClass): EditableTaxClass => {
    return {
        ...tax,
        ...(tax.applyToAll ? { taxAssignments: [] } : null),
        taxRate: fromPercentage(tax.taxRate!),
    };
};
