import { FeatureConfiguration } from "..";
import { reducer } from "features/waiter/reducers";

import * as actions from "features/waiter/actions";
import { withPermissions } from "features/location/components/withPermission";
import { ListWaitersPageContainer } from "./containers/ListWaitersPageContainer";
import { EditWaiterPage } from "./components/EditWaiterPage";
import { CrudRoute } from "common/scaffolding/components/CrudRoute";

export * from "features/waiter/types";

export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.waiters = reducer;

    config.addRoutes(config.routes.locationRoutes, [
        <CrudRoute
            key="staff"
            name="staff"
            section={null}
            listComponent={withPermissions(ListWaitersPageContainer, "staff:read")}
            editComponent={withPermissions(EditWaiterPage, "staff:read")}
        />,
    ]);
}
