import { CatalogueItem } from "../types";
import { createSelector } from "reselect";
import { getModifierCatalogueItems } from "./getModifierCatalogueItems";
import { ItemValidationStatus } from "features/catalogue/types";
import { getProductCatalogueItems } from "./getProductCatalogueItems";

const SUCCESSFUL_SYNC_STATUSES: ItemValidationStatus[] = ["VALID", "CHANGED"]; // TODO check with BE what's appropriate here as an outcome of https://meanduapp.atlassian.net/browse/ROCK-182

const getCatalogueItemsWithSyncSuccess = (items: CatalogueItem[]): CatalogueItem[] =>
    items.filter(({ validationStatus }) => SUCCESSFUL_SYNC_STATUSES.includes(validationStatus));

export const getModifierGroupsWithSyncSuccess = createSelector(
    getModifierCatalogueItems,
    (modifierGroups): CatalogueItem[] => getCatalogueItemsWithSyncSuccess(modifierGroups)
);

export const getProductsWithSyncSuccess = createSelector(getProductCatalogueItems, (products): CatalogueItem[] =>
    getCatalogueItemsWithSyncSuccess(products)
);
