export type Timestamped<T> = {
    timestamp: number;
    data: T;
};

export const timestamp = <T>(data: T) =>
    ({
        timestamp: new Date().getTime(),
        data,
    } as Timestamped<T>);
