import { opsApi } from "API";
import { Analytics } from "../types/Analytics";

export const getAnalyticsDashboardUrl = async (locationId: string, analyticsTool: string, tabId: string) => {
    const response = await opsApi.fetch(`/analytics/dashboard/${locationId}/${analyticsTool}/${tabId}`, {
        method: "GET",
    });

    if (!response.ok) {
        throw response;
    }

    return (await response.json()) as Analytics;
};
