import { AppState } from "features";
import { createSelector } from "reselect";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { getGroupList } from "features/group/selectors";
import { getLocationsList } from "features/location/selectors/getLocationsList";
import { LocationSummary } from "features/location/types/LocationSummary";
import { LocationType } from "features/location/types/LocationType";
import { Option } from "core/components/form/select";
import { ActiveLocation } from "features/location/types/ActiveLocation";

const getLinkableLocations = createSelector(
    getActiveLocation,
    getLocationsList,
    (_: AppState, selectedIds: string[]) => selectedIds, // component prop
    (activeLocation, locations, selectedIds): LocationSummary[] => {
        if (!activeLocation) {
            return [];
        }
        return locations.filter(getIsLinkableLocation(activeLocation, selectedIds));
    }
);

const getIsLinkableLocation =
    (activeLocation: ActiveLocation, selectedIds: string[]) => (location: LocationSummary) => {
        if (location.locationType === LocationType.BRAND) {
            return false;
        }

        if (location.parentLocationId && location.parentLocationId !== activeLocation?.id) {
            return false;
        }

        if (location.id === activeLocation.id) {
            return false;
        }

        return !selectedIds.includes(location.id);
    };

const getLocationsInMyGroup = createSelector(
    getActiveLocation,
    getLinkableLocations,
    (activeLocation, locations): LocationSummary[] => {
        if (!activeLocation?.group) {
            return [];
        }
        return locations.filter((location) => location.groupId === activeLocation.group?.id);
    }
);

const getLocationsNotInMyGroup = createSelector(
    getActiveLocation,
    getLinkableLocations,
    (activeLocation, locations): LocationSummary[] => {
        return locations.filter((location) => !location.groupId || location.groupId !== activeLocation?.group?.id);
    }
);

const getMyGroupName = createSelector(getActiveLocation, getGroupList, (activeLocation, groups): string | undefined => {
    if (!activeLocation?.group) {
        return undefined;
    }

    return activeLocation.group.displayName;
});

export const getLinkableLocationOptions = createSelector(
    getMyGroupName,
    getLocationsInMyGroup,
    getLocationsNotInMyGroup,
    (groupName, groupLocations, nonGroupLocations) => {
        return [
            {
                label: groupName,
                options: groupLocations.map<Option>((location) => ({
                    label: location.displayName,
                    value: location.id,
                })),
            },
            {
                label: "Other",
                options: nonGroupLocations.map<Option>((location) => ({
                    label: location.displayName,
                    value: location.id,
                })),
            },
        ];
    }
);
