import { FeatureConfiguration } from "..";
import { reducer } from "./reducers";
import * as actions from "./actions";
import { Route } from "react-router";
import { Col } from "antd";
import { withPermissions } from "../location/components/withPermission";
import { HyperwalletUserPageContainer } from "./containers/HyperwalletUserPageContainer";

export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.paymentUser = reducer;

    config.addRoutes(config.routes.locationRoutes, [
        <Route
            path={"/:region/:location/payments"}
            key="payment"
            render={() => (
                <Col span={24} md={24} className="content__col">
                    <div className="content__col-edit">
                        <Route component={withPermissions(HyperwalletUserPageContainer, "payment:read")} />
                    </div>
                </Col>
            )}
        />,
    ]);
}
