import { Input } from "core/components/form/input";

import { PosFieldProps } from "./PosFieldProps";
import { PosTextFieldCommon } from "features/location";
import { useMemo } from "react";

export const PosTextField = ({ definition, hideMarkRequired, ...rest }: PosFieldProps<PosTextFieldCommon>) => {
    const allowEmpty = useMemo(() => {
        return !definition.validation || new RegExp(definition.validation).test("");
    }, [definition]);

    return (
        <Input
            markRequired={!hideMarkRequired && !allowEmpty}
            placeholder={definition?.placeholder || undefined}
            autoComplete="off"
            {...rest}
        />
    );
};
