import { createAction } from "features/catalogue/reducers/product/edit";
import { DescriptorMap, EditableProduct, EditableVariant } from "features/catalogue/types";
import { mapEditableModifier } from "features/modifier/actions";
import { EditableModifier } from "features/modifier";
import { isNew } from "common/utility/modelUtils";
import { editProduct as editProductAPI, GraphQLDescriptor, GraphQLMenuItem, GraphQLVariant } from "../api/editProduct";
import { scaffoldEditAction } from "common/scaffolding/actions/scaffoldEditAction";

export const editProduct = (locationId: string, menuItemId: string) =>
    scaffoldEditAction(
        menuItemId,
        (state) => state.menuItems.edit,
        createAction,
        async () => {
            if (isNew(menuItemId)) {
                return newProduct();
            }

            const product = await editProductAPI(locationId, menuItemId);

            return mapEditableProduct(product);
        }
    );

const mapEditableProduct = ({
    id,
    internalName,
    displayName,
    description,
    descriptors,
    categories,
    image,
    video,
    price,
    taxRate,
    sku,
    relatedItems,
    template,
    tags,
    modifiers,
    variants,
    special,
    popular,
    priceLists,
    status,
    energyContent,
    validationStatus,
    isLinked,
}: GraphQLMenuItem): EditableProduct => {
    const mappedModifiers: EditableModifier[] =
        modifiers &&
        modifiers.map((m) => ({
            ...mapEditableModifier(m),
        }));

    return {
        id,
        internalName,
        displayName,
        categories: (categories || []).map((c) => c.id),
        description: description || "",
        image,
        video,
        prices:
            !variants || variants.length === 0
                ? [
                      {
                          price: price?.toFixed(2),
                          taxRate,
                          sku,
                          displayName: "",
                          modifiers: mappedModifiers.map((m) => m.id!),
                          energyContent: energyContent?.toString(),
                          isLinked,
                      },
                  ]
                : mapVariants(variants, mappedModifiers),
        recommendedMenuItems: (relatedItems || []).map((i) => i.id),
        modifiers: mappedModifiers,
        template: template.id,
        tags: {},
        tagList: tags && tags.map((tag) => tag.id),
        special: special || false,
        popular: popular || false,
        descriptors: descriptors && createDescriptorMap(descriptors),
        priceLists: priceLists || [],
        status: status,
        validationStatus,
        isLinked,
    };
};

function mapVariants(variants: GraphQLVariant[], allModifiers: EditableModifier[]): EditableVariant[] {
    return variants.map((variant) => ({
        ...variant,
        price: variant?.price?.toFixed(2),
        energyContent: variant?.energyContent?.toString(),
        modifiers: variant.modifiers || allModifiers.map((m) => m.id),
    }));
}

function createDescriptorMap(descriptors: GraphQLDescriptor[]): DescriptorMap {
    return descriptors.reduce((map, { key, value }) => {
        map[key] = value;
        return map;
    }, {});
}

const newProduct = (): EditableProduct => ({
    id: "",
    displayName: "",
    description: "",
    categories: [],
    image: null as any,
    video: null as any,
    internalName: "",
    prices: [
        {
            displayName: "",
            price: null,
            taxRate: null,
            sku: null,
            modifiers: [],
            isLinked: false,
        },
    ],
    recommendedMenuItems: [],
    template: "",
    tags: {},
    tagList: [],
    modifiers: [],
    descriptors: {},
    special: false,
    popular: false,
    priceLists: [],
    status: 0,
    isLinked: false,
});
