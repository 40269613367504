import { AppDispatch, AppState } from "features";
import { createAction } from "../reducers/list";
import { listAuditLogEntries } from "../api/listAuditLogEntries";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";

export const list = (location: string) => async (dispatch: AppDispatch, getState: () => AppState) => {
    const location = getActiveLocation(getState())!;

    try {
        dispatch(createAction.loading());
        const entries = await listAuditLogEntries("Location", location.id);

        entries.sort((a,b) => b.timestamp.localeCompare(a.timestamp))

        dispatch(createAction.loaded(entries));
    } catch (e) {
        dispatch(createAction.loadFailed(e));
    }
};
