import styles from "./ModifierSelectionRules.module.scss";

import { FieldErrors } from "core/components/form/fieldErrors";
import { Input } from "core/components/form/input";
import { Field, useFormikContext } from "formik";
import { ReactElement, useEffect, useState } from "react";
import { ModifySelectionOptions } from "features/modifier/types";
import { buildInputRegex } from "core/components/form/InputNumber";
import { EditableModifier } from "features/modifier";
import { Select } from "core/components/form/select";
import { parseNumberString } from "core/utility/input/parsers";

export interface Props {
    disabled: boolean;
    initialValues: any;
    errorFieldNames: string[];
}

const options = [
    {
        label: "Unlimited",
        value: ModifySelectionOptions.Unlimited,
    },
    {
        label: "At least *",
        value: ModifySelectionOptions.AtLeast,
    },
    {
        label: "Exactly *",
        value: ModifySelectionOptions.Exactly,
    },
    {
        label: "Up to",
        value: ModifySelectionOptions.UpTo,
    },
    {
        label: "Between *",
        value: ModifySelectionOptions.Between,
    },
];

export function ModifierSelectionRules({ disabled, errorFieldNames }: Props): ReactElement {
    const { setFieldValue, setFieldTouched, values } = useFormikContext<EditableModifier>();
    const [fieldsVisible, setFieldsVisible] = useState<any>({ min: false, max: false });
    const { minSelection, maxSelection, minMaxSelect } = values;

    useEffect(() => {
        switch (minMaxSelect) {
            case ModifySelectionOptions.Exactly:
                setFieldsVisible({ min: true, max: false });
                const value = minSelection || (minSelection !== "" ? maxSelection : "");
                setFieldValue("minSelection", value);
                setFieldValue("maxSelection", value);
                break;

            case ModifySelectionOptions.Unlimited:
                setFieldsVisible({ min: false, max: false });
                setFieldValue("minSelection", "");
                setFieldValue("maxSelection", "");
                break;

            case ModifySelectionOptions.AtLeast:
                setFieldsVisible({ min: true, max: false });
                setFieldValue("minSelection", minSelection || maxSelection || "");
                setFieldValue("maxSelection", "");
                break;

            case ModifySelectionOptions.UpTo:
                setFieldsVisible({ min: false, max: true });
                setFieldValue("minSelection", "");
                setFieldValue("maxSelection", maxSelection || minSelection || "");
                break;

            case ModifySelectionOptions.Between:
                setFieldsVisible({ min: true, max: true });
                if (minSelection === "") {
                    setFieldTouched("minSelection", false);
                }
                if (maxSelection === "") {
                    setFieldTouched("maxSelection", false);
                }
                setFieldValue("minSelection", minSelection || "");
                setFieldValue("maxSelection", maxSelection || "");
                break;
        }
        // ignore other deps, this effect should only run when minMaxSelect changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [minMaxSelect]);

    useEffect(() => {
        // keep min and max in sync when operator is 'Exactly'
        if (minMaxSelect === ModifySelectionOptions.Exactly) {
            setFieldValue("maxSelection", minSelection);
        }
        // ignore minMaxSelect, setFieldValue dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [minSelection]);

    return (
        <>
            <FieldErrors fieldNames={errorFieldNames}>
                <div className={styles.container}>
                    <span className={styles.before}>Customers can select</span>
                    <Field
                        className={styles.selectModifierRule}
                        name="minMaxSelect"
                        component={Select}
                        options={options}
                        disabled={disabled}
                    />
                    {fieldsVisible.min && (
                        <span className={styles.tinyInlineField}>
                            <Field
                                name="minSelection"
                                parseValue={parseNumberString}
                                component={Input}
                                disabled={disabled}
                                inputTest={buildInputRegex({
                                    numDigits: 2,
                                })}
                                pattern="\d*"
                                autoReset={{
                                    value: false,
                                    error: true,
                                    touched: true,
                                }}
                            />
                        </span>
                    )}
                    {fieldsVisible.max && (
                        <>
                            {minMaxSelect === ModifySelectionOptions.Between && <span>and</span>}
                            <span className={styles.tinyInlineField}>
                                <Field
                                    name="maxSelection"
                                    parseValue={parseNumberString}
                                    component={Input}
                                    disabled={disabled}
                                    inputTest={buildInputRegex({
                                        numDigits: 2,
                                    })}
                                    pattern="\d*"
                                    autoReset={{
                                        value: false,
                                        error: true,
                                        touched: true,
                                    }}
                                />
                            </span>
                        </>
                    )}
                    <span>option(s)</span>
                </div>
            </FieldErrors>
        </>
    );
}
