import { opsApi } from "API";
import { CmsItemSummary, GraphQLItem } from "../../../common/scaffolding/types";

export async function listServices(location: string): Promise<GraphQLServiceSummary[]> {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { location });
    return res.data.services;
}

type GraphQLPayload = { services: GraphQLServiceSummary[] };

export interface GraphQLServiceSummary extends GraphQLItem {
    daysOfWeek: number;
    startTime: number;
    endTime: number;
    dates: GraphQLDateRange[];
    sections: CmsItemSummary[];
    surcharge?: number;
    activeMinutes: number;
    available: boolean | null;
    priceLists: CmsItemSummary[];
}

export interface GraphQLDateRange {
    startDate: string;
    endDate: string;
}

export const serviceListFields = `
    id,
    internalName,
    displayName,
    daysOfWeek,
    startTime,
    endTime,
    dates {
        startDate,
        endDate
    },
    sections {
        id,
        displayName
    },
    surcharge,
    activeMinutes,
    available,
    priceLists {
        id,
        displayName
    }
`;

const query = `
    query($location: ID!) { 
        services(locationId: $location) { 
            ${serviceListFields}
        } 
    }
`;
