import styles from "./ListMemberships.module.scss";
import { EmptyMemberships } from "./EmptyMemberships";
import { PageHeader } from "core/components/pageHeader";
import { Card, Row } from "../../../core/components/card";
import { MembershipSummary } from "./MembershipSummary";
import { Col } from "../../../core/components/grid/Grid";
import { getCrudPermissions } from "../../location/selectors/getCrudPermissions";
import * as actions from "../actions";
import { useContext } from "react";
import { LocationContext } from "../../location";
import { AppState } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import { useLoadStatus } from "../../../common/hooks";
import { PageLoading } from "../../../core/components/pageLoading";
import { Button } from "../../../core/components/button";
import { PageError } from "../../../core/components/pageError";
import { goBack } from "connected-react-router";
import { getMembershipSummaryList } from "../selectors";

export const ListMembershipsPage = () => {
    const dispatch = useDispatch();
    const location = useContext(LocationContext);
    const membershipList = useSelector((state: AppState) => state.memberships.list);
    const membershipSummaries = useSelector(getMembershipSummaryList);
    const permissions = useSelector((state: AppState) => getCrudPermissions(state).memberships);
    const { canUpdate } = permissions || {};
    const fetch = () => {
        dispatch(actions.list(location.id));
    };

    let fetchStatus = useLoadStatus([membershipList.status], fetch, { refetchOnMount: true });

    if (fetchStatus === "loading" || fetchStatus === "unloaded") {
        return <PageLoading message={"Loading Loyalty Information"} />;
    }

    if (fetchStatus === "failed") {
        return (
            <PageError
                heading="Oh no!"
                message="There was a technical problem that prevented me&u from loading this page. Try reloading this page or try again later."
                actions={
                    <>
                        <Button onClick={() => dispatch(goBack())} role="secondary">
                            Go back
                        </Button>
                        <Button onClick={fetch}>Retry</Button>
                    </>
                }
            />
        );
    }

    return membershipSummaries?.length ? (
        <div className={styles.container}>
            <PageHeader title="Loyalty" />
            <Card as="ul" className={styles.list}>
                <Row as={"div"} className={styles.headerRow} border={"bottom"}>
                    <Col cols={{ xs: 9, md: 10 }} className={styles.columnLeftAlign}>
                        <span className={styles.heading}>General Information</span>
                    </Col>
                    <Col cols={{ xs: 3, md: 2 }} className={styles.columnRightAlign}>
                        {membershipSummaries.find((m) => !!m.publishedStatus) && (
                            <span className={styles.heading}>Status</span>
                        )}
                    </Col>
                </Row>
                {membershipSummaries.map((membershipSummary, index) => (
                    <MembershipSummary
                        key={membershipSummary.id}
                        summary={membershipSummary}
                        canUpdate={canUpdate}
                        index={index}
                    />
                ))}
            </Card>
        </div>
    ) : (
        <EmptyMemberships />
    );
};
