import { AppState } from "features";
import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { EditablePromotion } from "../types";
import { EditPromotionPage, Props } from "../components/EditPromotionPage";
import { getCrudPermissions } from "features/location/selectors/getCrudPermissions";
import { isLoaded } from "common/loader/isLoaded";
import { LocationComponentProps, withLocation } from "features/location";
import { mergeStatus } from "common/loader";
import { RouteComponentProps, withRouter } from "react-router";
import { withLoader, PageLoadingProps } from "common/loader/withLoader";
import * as actions from "../actions";
import { LocationRouteParams } from "common/types";
import { actions as categoryActions } from "features/category";
import { actions as catalogueActions } from "features/catalogue";
import { actions as priceListActions } from "features/priceList";
import { getProductCatalogueItems } from "features/catalogue/selectors/getProductCatalogueItems";
import { getCatalogueTree } from "common/selectors/getCatalogueTree";
import { getNow } from "features/service/selectors/getVenueTime";

const mapStateToProps = (
    state: AppState,
    {
        restaurantLocation: { postype, timeZone },
        match: { params },
    }: LocationComponentProps & RouteComponentProps<LocationRouteParams>
): DataProps<PageLoadingProps<Props>> => {
    const {
        promotions: { edit },
        categories: { list: categoryList },
        menuItems: { list: menuItemList },
        priceLists: { list: priceListsList },
    } = state;

    const overallFetchStatus = mergeStatus(
        edit.status,
        categoryList.status,
        menuItemList.status,
        priceListsList.status
    );

    if (isLoaded(edit) && isLoaded(categoryList) && isLoaded(menuItemList) && isLoaded(priceListsList)) {
        const [products, _] = getCatalogueTree(state);
        return {
            initialValues: edit.data,
            categories: categoryList.data,
            saveStatus: edit.saveStatus,
            loadStatus: "loaded",
            permissions: getCrudPermissions(state).promotions,
            products: getProductCatalogueItems(state),
            productTree: products.children,
            fromMonth: new Date(2022, 0, 1),
            venueTime: getNow(state),
            venueTimeZone: timeZone || "Australia/Sydney",
        };
    }

    return {
        loadStatus: overallFetchStatus,
    };
};

const mapDispatchToProps = (
    dispatch: any,
    {
        restaurantLocation,
        history,
        match: {
            params: { location, id, region },
        },
    }: LocationComponentProps & RouteComponentProps<RouteParams>
): FunctionProps<PageLoadingProps<Props>> => ({
    fetch: () => {
        dispatch(actions.edit(restaurantLocation.id, id));
        dispatch(categoryActions.list(location));
        dispatch(catalogueActions.listProducts(location));
        dispatch(priceListActions.list(location));
    },
    onSubmit: (data: EditablePromotion) => {
        dispatch(actions.save(restaurantLocation.id, data));
    },
    onArchive: (data: EditablePromotion) => {
        dispatch(actions.archive(location, data));
    },
});

interface RouteParams {
    id: string;
    location: string;
    region: string;
}

export const EditPromotionPageContainer = withRouter(
    withLocation(connect(mapStateToProps, mapDispatchToProps)(withLoader(EditPromotionPage)))
);
