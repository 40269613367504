import { getActiveLocation, getLocationPermissions } from "features/location/selectors/getLocationPermissions";
import { PageSectionAccess } from "features/locationSettings/components/EditLocationSettingsPage";
import { createSelector } from "reselect";

export const getAlcoholicDrinkRestrictionAccess = createSelector(
    getActiveLocation,
    getLocationPermissions,
    (location, permissions): PageSectionAccess => {
        const hasLocationUpdatePermission = permissions.hasAny("location:settings:update");
        const restrictionEnabled = !!location?.alcoholicDrinksRestriction?.enabled;

        if (hasLocationUpdatePermission) return "editable";
        if (restrictionEnabled) return "read-only";
        return "off";
    }
);
