import styles from "./CataloguePage.module.scss";

import { Button } from "core/components/button";
import { createAction } from "../reducers/filters";
import { EmptyMessage } from "core/components/card";
import { getActiveLocationSelector } from "features/location/selectors/getActiveLocation";
import { getHasFilters } from "../selectors/getFilters";
import { getIsPosImportSupported } from "features/location/selectors/getPosMenuSupported";
import { getSelectedRegion } from "features/region/selectors";
import { Icon } from "core/components/icon/Icon";
import { Link } from "react-router-dom";
import { OpenInWindow } from "common/icons";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePermissions } from "features/location/hooks";
import { EmptyMessageForFilters } from "core/components/card/EmptyMessageForFilters";

interface Props {
    collectionName: string;
}

export const CatalogueEmpty = ({ collectionName }: Props) => {
    const dispatch = useDispatch();

    const location = useSelector(getActiveLocationSelector)?.slug;
    const activeRegion = useSelector(getSelectedRegion)?.id;
    const {
        catalogue: { canImportPos },
    } = usePermissions();

    const posImportSupported = useSelector(getIsPosImportSupported) || false;
    const posImportLink = posImportSupported
        ? `/${activeRegion}/${location}/pos/import/${collectionName}?from=${collectionName}`
        : undefined;

    const learnMoreLink = posImportSupported
        ? "https://meandu.helpjuice.com/en_US/pos-catalogue-sync"
        : "https://meandu.helpjuice.com/en_US/153315-creating-and-managing-your-menu";

    const hasFilters = useSelector(getHasFilters);
    const heading = `Your ${collectionName} catalogue is empty`;

    const message = posImportSupported
        ? `Import ${collectionName} from your POS to use them in your catalogue`
        : `Import CSV or create ${collectionName} to use them in your catalogue`;

    const handleClearFilters = useCallback(() => {
        dispatch(createAction.clearFilters());
    }, [dispatch]);

    return hasFilters ? (
        <EmptyMessageForFilters onClear={handleClearFilters} />
    ) : (
        <EmptyMessage message={message} heading={heading} size="standard">
            <div className={styles.emptyCatalogueActions}>
                <Button as="a" href={learnMoreLink} target="_blank" role="secondary">
                    <Icon className={styles.icon} size="small">
                        <OpenInWindow />
                    </Icon>
                    Learn more
                </Button>
                {posImportSupported && canImportPos && (
                    <Button as={Link} to={posImportLink}>
                        Import
                    </Button>
                )}
            </div>
        </EmptyMessage>
    );
};
