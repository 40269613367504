import styles from "./PromotionSchedule.module.scss";

import { AngleDown, Time } from "common/icons";
import { Card, Row, Title } from "core/components/card";
import { DatePickerInput, DatePickerInputRange } from "core/components/form/datePicker";
import { DAYS_OF_WEEK } from "common/utility/dateUtils";
import { EditablePromotion } from "../../types";
import { Field, FieldArray, useFormikContext } from "formik";
import { FieldErrors } from "core/components/form/fieldErrors";
import { Icon } from "core/components/icon";
import { isNotPastDate } from "../../schema/EditablePromotionSchema";
import { LabelledTimePicker } from "core/components/form/timePicker/LabelledTimepicker";
import { LocationLocaleContext } from "features/location";
import { Moment } from "moment";
import { Select } from "core/components/form/select";
import { useContext, useEffect, useRef, useState } from "react";
import { ExcludedDates } from "./ExcludedDates";
import { CommonEditProps } from "../EditPromotionPage";

interface Props extends CommonEditProps {
    venueTime: Moment;
    venueTimeZone: string;
    fromMonth: Date;
}

const Weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((label, index) => {
    return {
        label,
        value: DAYS_OF_WEEK[index],
    };
});

export const PromotionSchedule = ({ disableFields, fromMonth, venueTime, venueTimeZone }: Props) => {
    const { locale } = useContext(LocationLocaleContext);
    const form = useFormikContext<EditablePromotion>();
    const { initialValues } = form;

    const timePickerContainerRef = useRef<HTMLDivElement | null>(null);
    const [startTimeOpen, setStartTimeOpen] = useState<boolean>(false);
    const [endTimeOpen, setEndTimeOpen] = useState<boolean>(false);

    // use the venue's date as today, but create a date in local timezone
    // for compatibility/convenience with react day picker components
    const today = new Date(venueTime.format("YYYY/MM/DD"));

    const disabledDays = {
        before: today,
    };

    useEffect(() => {
        if (form.values.restrictions?.schedule?.activeDaysOfWeek.length === 0) {
            form.setFieldValue("restrictions.schedule.activeDaysOfWeek", DAYS_OF_WEEK);
        }
    }, [form, form.values.restrictions?.schedule?.activeDaysOfWeek]);

    return (
        <>
            <Card>
                <Row collapse="down">
                    <Title title="Schedule" />
                </Row>
                <Row collapse="down">
                    <FieldErrors fieldNames={["restrictions.schedule.startDate", "restrictions.schedule.endDate"]}>
                        <DatePickerInputRange
                            locale={locale}
                            today={today}
                            fromFieldName="restrictions.schedule.startDate"
                            toFieldName="restrictions.schedule.endDate"
                            fromInputProps={{
                                label: "Start date",
                                markRequired: true,
                                disabled:
                                    disableFields ||
                                    !isNotPastDate(
                                        initialValues.restrictions.schedule.startDate,
                                        venueTime,
                                        venueTimeZone
                                    ),
                                closeOnDayClick: true,
                            }}
                            toInputProps={{ disabled: disableFields, label: "End date" }}
                            fromDayPickerProps={{
                                disabledDays,
                                fromMonth,
                                fixedWeeks: true,
                            }}
                            toDayPickerProps={{
                                disabledDays,
                                fromMonth,
                                fixedWeeks: true,
                            }}
                        />
                    </FieldErrors>
                </Row>
                <Row collapse="down">
                    <FieldErrors fieldNames={["restrictions.schedule.activeDaysOfWeek"]}>
                        <Field
                            disabled={disableFields}
                            name="restrictions.schedule.activeDaysOfWeek"
                            component={Select}
                            options={Weekdays}
                            isMulti={true}
                            label="Active days"
                            markRequired={true}
                            blurInputOnSelect={false}
                            closeMenuOnSelect={false}
                        />
                    </FieldErrors>
                </Row>
                <Row>
                    <FieldErrors
                        fieldNames={["restrictions.schedule.dailyStartTime", "restrictions.schedule.dailyEndTime"]}
                    >
                        <div className={styles.timePickerContainer} ref={timePickerContainerRef}>
                            <Field
                                disabled={disableFields}
                                name="restrictions.schedule.dailyStartTime"
                                component={LabelledTimePicker}
                                label="Start time"
                                hideFooter={true}
                                markRequired={true}
                                getPopupContainer={(trigger: Node) => timePickerContainerRef.current}
                                onOpenChange={setStartTimeOpen}
                                suffixIcon={
                                    <Icon size="tiny" verticalAlign="middle" className={styles.timeIcon}>
                                        {startTimeOpen ? <Time /> : <AngleDown />}
                                    </Icon>
                                }
                            />
                            <Field
                                disabled={disableFields}
                                name="restrictions.schedule.dailyEndTime"
                                component={LabelledTimePicker}
                                getPopupContainer={(trigger: Node) => timePickerContainerRef.current}
                                label="End time"
                                hideFooter={true}
                                markRequired={true}
                                onOpenChange={setEndTimeOpen}
                                suffixIcon={
                                    <Icon size="tiny" verticalAlign="middle" className={styles.timeIcon}>
                                        {endTimeOpen ? <Time /> : <AngleDown />}
                                    </Icon>
                                }
                            />
                        </div>
                    </FieldErrors>
                </Row>
            </Card>
            <Card>
                <Row collapse="down">
                    <Title title="Blackout dates" />
                </Row>
                <Row collapse="both">
                    <p className="my-0">
                        Pause your promotion on certain dates. This will override duration if enabled.
                    </p>
                </Row>
                <Row>
                    <FieldErrors fieldNames={["restrictions.schedule.specificInactiveDates"]}>
                        <div className="cy-5">
                            <Field
                                disabled={disableFields}
                                locale={locale}
                                name="restrictions.schedule.specificInactiveDates"
                                component={DatePickerInput}
                                label="Date"
                                canInputDisabled={false}
                                dayPickerProps={{ disabledDays, fromMonth, fixedWeeks: true }}
                                today={today}
                            />
                            <div>
                                <FieldArray
                                    name="restrictions.schedule.specificInactiveDates"
                                    render={(props) => (
                                        <ExcludedDates {...props} disableFields={disableFields} locale={locale} />
                                    )}
                                />
                            </div>
                        </div>
                    </FieldErrors>
                </Row>
            </Card>
        </>
    );
};
