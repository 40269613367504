import { DownloadOutlined } from "@ant-design/icons";
import { Button, Divider, message } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { UploadFile } from "antd/lib/upload/interface";
import { BulkUploadType } from "features/catalogue/api/bulkUpload";
import { UploadResult } from "features/catalogue/types/UploadResult";
import { useEffect, useState } from "react";
import { UploadLoader } from "./UploadLoader";

export interface Props {
    parentRecordTitle: string;
    onUpload: (file: File, type: BulkUploadType) => void;
    type: BulkUploadType;
    uploadResult?: UploadResult;
    onImport: (fileName: string, preview: boolean) => void;
    templateData: string;
    childRecordTitle: string;
    loading: boolean;
}

export const Upload = ({
    parentRecordTitle,
    childRecordTitle,
    onUpload,
    type,
    uploadResult,
    onImport,
    templateData,
    loading,
}: Props) => {
    const [url, setURL] = useState("");
    const [selectedFile, setSelectedFile] = useState<UploadFile[]>([]);
    const [uploadedFile, setUploadedFile] = useState("");

    const handleBeforeUpload = (file: File) => {
        onUpload(file, type);
        return false;
    };

    const handleRemove = () => {
        setSelectedFile([]);
    };

    useEffect(() => {
        if (uploadResult && uploadResult.uploadSuccess) {
            message.success("File uploaded successfully");
            const { originalFile, uploadFilePath } = uploadResult;
            const uploadFile: UploadFile = {
                uid: "1",
                name: originalFile.name,
                status: "done",
                url: "",
                size: originalFile.size,
                type: originalFile.type,
            };

            const file = uploadFilePath && !!uploadFilePath.split("/").length && uploadFilePath.split("/").pop();

            setUploadedFile(file || "");
            setSelectedFile([uploadFile]);
        }
    }, [uploadResult]);

    useEffect(() => {
        var blob = new Blob([templateData.toString()], {
            type: "text/plain;charset=utf-8",
        });
        var url = URL.createObjectURL(blob);
        setURL(url);
    }, [templateData]);

    return (
        <div className="bulk-uploader--content">
            <UploadLoader loading={loading} />
            <div className="bulk-uploader--header">
                <i className="icon orion-import-database"></i>
                <h1>Import new {parentRecordTitle}s</h1>
                <span>
                    Bulk add new {parentRecordTitle}s and {childRecordTitle}s to your me&u catalogue.
                </span>
            </div>
            <div className="bulk-uploader--body">
                <div className="body-top">
                    <h3>
                        Download the <span className="title-case">{parentRecordTitle}</span> CSV template
                    </h3>
                    Populate the CSV template with your menu data to bulk import items to your me&u catalogue.
                    <Button
                        download={`${parentRecordTitle}s.csv`}
                        icon={<DownloadOutlined />}
                        href={url}
                        target="_blank"
                    >
                        <span className="title-case">Download {parentRecordTitle}s</span>.csv
                    </Button>
                    <Divider />
                    <h3>Ready to upload?</h3>
                    Have your CSV file ready to go? Upload it now.
                    <div className="upload-wrapper">
                        <Dragger
                            multiple={false}
                            beforeUpload={handleBeforeUpload}
                            accept=".csv"
                            fileList={selectedFile}
                            onRemove={handleRemove}
                            showUploadList={{ showRemoveIcon: false }}
                        >
                            <p className="ant-upload-drag-icon">
                                <i className="cloud-upload" />
                            </p>
                            <h4 className="ant-upload-text">Click or drag file to this area to upload</h4>
                            <p className="ant-upload-hint">
                                Upload your completed <span className="title-case">{parentRecordTitle}</span>s CSV. File
                                must be .csv format
                            </p>
                        </Dragger>
                    </div>
                </div>
            </div>
            <div className="bulk-uploader--footer">
                <Button type="primary" onClick={() => onImport(uploadedFile, true)} disabled={!uploadedFile}>
                    Continue
                </Button>
            </div>
        </div>
    );
};
