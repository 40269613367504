import styles from "./CataloguePage.module.scss";

import { PageLoading } from "core/components/pageLoading";

export const CatalogueLoading = () => {
    return (
        <div className={styles.loadContainer}>
            <PageLoading message="Loading Catalogue..." />
        </div>
    );
};
