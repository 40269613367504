import { opsApi } from "API";
import { venueServiceFeeQueryFields } from ".";
import { GraphQLVenueServiceFeeInputModel, GraphQLVenueServiceFeeQueryModel } from "../types";

export const saveVenueServiceFee = async (
    locationId: string,
    venueServiceFeeId: string | undefined,
    inputModel: GraphQLVenueServiceFeeInputModel
) => {
    const isCreate = venueServiceFeeId === undefined || venueServiceFeeId === "";

    const res = isCreate
        ? (
              await opsApi.graphQLQuery<{ createVenueServiceFee: GraphQLVenueServiceFeeQueryModel }>(createQuery, {
                  locationId,
                  item: inputModel,
              })
          ).data.createVenueServiceFee
        : (
              await opsApi.graphQLQuery<{ updateVenueServiceFee: GraphQLVenueServiceFeeQueryModel }>(updateQuery, {
                  locationId,
                  id: venueServiceFeeId,
                  item: inputModel,
              })
          ).data.updateVenueServiceFee;

    if (!res) {
        throw `Something went wrong while ${isCreate ? "creating" : "updating"} tray charge ${venueServiceFeeId}`;
    }

    return res;
};

const createQuery = `
    mutation ($locationId: ID!, $item: VenueServiceFeeUpdateInput!) {
        createVenueServiceFee(locationId: $locationId, data: $item) {
            ${venueServiceFeeQueryFields}
        }
    }
`;

const updateQuery = `
    mutation ($locationId: ID!, $id : ID!, $item: VenueServiceFeeUpdateInput!) {
          updateVenueServiceFee(locationId: $locationId, id: $id, data: $item) {
            ${venueServiceFeeQueryFields}
        }
    }
`;
