import { AppDispatch, AppState } from "features/state";
import { bulkDelete as bulkDeleteApi } from "../api/bulkDelete";
import { BulkDeleteInput } from "../types";
import { createAction } from "../reducers/bulkDelete";
import { actions as modifierActions } from "features/modifier";
import { listProducts } from ".";
import { setLastItemUpdated } from "features/location/actions/setLastItemUpdated";

export const bulkDelete =
    (location: string, items: BulkDeleteInput) => async (dispatch: AppDispatch, getState: () => AppState) => {
        if (getState().catalogue.bulkDelete.status === "loading") {
            return;
        }

        dispatch(createAction.loading());

        try {
            const result = await bulkDeleteApi(location, items);
            dispatch(setLastItemUpdated());
            dispatch(createAction.loaded(result));
            dispatch(modifierActions.list(location, true));
            dispatch(listProducts(location, true));
            // TODO tracking
        } catch (e: any) {
            dispatch(createAction.failed(e));
            //TOOD tracking
        }
    };
