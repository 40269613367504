import * as React from "react";
import { Tag } from "features/tags/types";

import { Tag as AntTag } from "antd";
import { FieldProps } from "formik";

import "./TagButtonGroup.scss";

interface Props {
    tags: Tag[];
    disabled?: boolean;
}

export class TagButtonGroup extends React.Component<Props & FieldProps> {
    handleChange = (tag: Tag, checked: boolean) => {
        const {
            field: { name, value: selectedTags },
            form,
        } = this.props;

        const nextSelectedTags = checked
            ? [...(selectedTags || []), tag.id]
            : selectedTags.filter((id: string) => id !== tag.id);

        form.setFieldValue(name, nextSelectedTags);
    };

    render() {
        const {
            tags,
            field: { value: selectedTagIds },
            disabled,
        } = this.props;

        return tags.map((tag: Tag) => {
            const isChecked = selectedTagIds && selectedTagIds.indexOf(tag.id) > -1;

            if (disabled) {
                return (
                    <AntTag key={tag.id} className={!isChecked ? "ant-tag-cyan tag-button" : "tag-button"}>
                        {tag.displayName}
                    </AntTag>
                );
            }

            return (
                <AntTag.CheckableTag
                    key={tag.id}
                    className={!isChecked ? "ant-tag-cyan tag-button" : "tag-button"}
                    checked={isChecked}
                    onChange={(checked) => this.handleChange(tag, checked)}
                >
                    {tag.displayName}
                </AntTag.CheckableTag>
            );
        });
    }
}
