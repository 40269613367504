import { TextInput, FieldErrors } from "components/forms";
import { Formik, validateYupSchema, yupToFormErrors, FastField, FormikProps } from "formik";
import { EditableWaiter, EditableWaiterParams } from "features/waiter/types";
import { editableWaiterSchema } from "../schema/EditableWaiter";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import "./EditWaiterPage.scss";
import { PageLoading } from "core/components/pageLoading";
import { EditRow } from "common/scaffolding/components/EditRow";
import { Form } from "antd";
import { getCrudPermissions } from "features/location/selectors/getCrudPermissions";
import { CrudActionFooter, SubmitMode } from "core/components/actionFooter/CrudActionFooter";
import * as actions from "../actions";
import {
    getAccessLevelList,
    getAccessLevelListState,
    getDefaultAccessLevel,
    getEditableWaiter,
    getWaiterEditState,
    getWaiterListState,
} from "../selectors";
import { useCallback, useEffect, useState } from "react";
import { useLoadStatus } from "common/hooks";
import { PageError } from "core/components/pageError";
import { Button } from "core/components/button";
import { RadioGroup } from "core/components/form/radio";

export const EditWaiterPage = () => {
    const dispatch = useDispatch();
    const { location, id: waiterId } = useParams<EditableWaiterParams>();

    const staffPermissions = useSelector(getCrudPermissions).staff;
    const allWaiters = useSelector(getWaiterListState);
    const editWaiter = useSelector(getWaiterEditState);
    const editableWaiter = useSelector(getEditableWaiter);
    const accessLevelsState = useSelector(getAccessLevelListState);
    const accessLevels = useSelector(getAccessLevelList);
    const [submitMode, setSubmitMode] = useState<SubmitMode>("save");
    const canSetAccessLevel = staffPermissions.canSetAccessLevel;
    const defaultAccessLevel = useSelector(getDefaultAccessLevel);

    const fetch = useCallback(() => {
        dispatch(actions.edit(location, waiterId));
        dispatch(actions.list(location));
        dispatch(actions.listAccessLevels(location));
    }, [dispatch, location, waiterId]);

    useEffect(() => {
        dispatch(actions.edit(location, waiterId));
    }, [dispatch, location, waiterId]);

    const fetchStatus = useLoadStatus([editWaiter.status, allWaiters.status, accessLevelsState.status], fetch);

    const handleDelete = () => {
        setSubmitMode("archive");
        dispatch(actions.archive(location));
    };

    const handleSubmit = (data: EditableWaiter) => {
        setSubmitMode("save");
        dispatch(actions.save(location, data));
    };

    const validate = (value: EditableWaiter) => {
        if (allWaiters.status != "loaded") return;

        const context = {
            allWaiters: allWaiters.data,
            waiterId,
        };

        return validateYupSchema(value, editableWaiterSchema, undefined, context).then(
            () => ({}),
            (err: any) => yupToFormErrors(err)
        );
    };

    if (fetchStatus === "failed") {
        return <PageError actions={<Button onClick={fetch}>Retry</Button>} />;
    }

    if (fetchStatus === "loading" || fetchStatus === "unloaded" || !editableWaiter) {
        return <PageLoading message="Loading waiter information" />;
    }

    editableWaiter.accessLevel = !editableWaiter?.accessLevel ? defaultAccessLevel : editableWaiter.accessLevel;
    const isUpdate = !!editableWaiter.id;
    const disableFields = isUpdate && !staffPermissions.canUpdate;

    return (
        <Formik validate={validate} initialValues={editableWaiter} enableReinitialize={true} onSubmit={handleSubmit}>
            {(form: FormikProps<EditableWaiter>) => (
                <Form onFinish={form.handleSubmit}>
                    <EditRow title="Name">
                        <FieldErrors name="displayName" form={form}>
                            <FastField
                                name="displayName"
                                component={TextInput}
                                placeholder="Staff name"
                                disabled={disableFields}
                            />
                        </FieldErrors>
                    </EditRow>
                    {(canSetAccessLevel ||
                        accessLevels.findIndex(
                            (a) => a.displayName === "Staff" && a.id === editableWaiter.accessLevel
                        ) !== -1) && (
                        <EditRow
                            title="PIN"
                            subTitle="Staff members can use this 4 digit PIN to access the Venue Management interface"
                        >
                            <FieldErrors name="pin" form={form}>
                                <FastField
                                    name="pin"
                                    component={TextInput}
                                    regex={/^[0-9]{0,4}$/}
                                    placeholder="••••"
                                    disabled={disableFields}
                                />
                            </FieldErrors>
                        </EditRow>
                    )}

                    {!!accessLevels.length && (
                        <EditRow
                            title="Access Level"
                            subTitle="Assign staff member access level within Venue Management application"
                        >
                            <FieldErrors name="accessLevel" form={form}>
                                <FastField
                                    name="accessLevel"
                                    direction="column"
                                    component={RadioGroup}
                                    disabled={disableFields || !canSetAccessLevel}
                                    options={accessLevels
                                        .sort((a, b) => a.displayOrder - b.displayOrder)
                                        .map((a) => {
                                            return {
                                                description: a.description,
                                                text: a.displayName,
                                                value: a.id,
                                            };
                                        })}
                                />
                            </FieldErrors>
                        </EditRow>
                    )}

                    <CrudActionFooter
                        saving={editWaiter.saveStatus === "saving"}
                        submitMode={submitMode}
                        showDuplicate={false}
                        showDelete={!!(staffPermissions.canDelete && editableWaiter.id)}
                        showSave={!disableFields}
                        confirmDelete={true}
                        deleteProps={{
                            onClick: handleDelete,
                        }}
                        saveProps={{
                            disabled: !!(!form.dirty && editableWaiter.id),
                        }}
                    />
                </Form>
            )}
        </Formik>
    );
};
