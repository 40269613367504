import { ChildLocationsPage } from "./components/ChildLocationsPage";
import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { EditWrapper } from "common/scaffolding/components/EditWrapper";
import { FeatureConfiguration } from "features";
import { reducer } from "./reducers";
import { Route } from "react-router";
import { withPermissions } from "features/location/components/withPermission";

const Page = withPermissions(ChildLocationsPage, "settings:read");

export default function (config: FeatureConfiguration) {
    config.reducers.locationRelations = reducer;

    config.addRoutes(config.routes.locationRoutes, [
        <Route
            path={"/:region/:location/venues"}
            key="location-relations"
            exact
            render={() => (
                <EditWrapper contentWidth={ContentWidth.NARROW}>
                    <Page />
                </EditWrapper>
            )}
        />,
    ]);
}
