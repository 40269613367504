import { LocationSummary } from "features/location/types/LocationSummary";
import timeago from "timeago.js";

export function getLocationLastUpdated(location: LocationSummary) {
    const { parentLocationUpdateStatus } = location || {};

    const { lastParentImportUtc } = parentLocationUpdateStatus || {};

    return lastParentImportUtc ? timeago().format(lastParentImportUtc) : "-";
}
