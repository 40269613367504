import { createAction } from "../reducers/details";
import { Dispatch } from "redux";

import { opsApi } from "API";
import { DailySalesDetails, Remittance } from "../types";

export const fetchDetails = (location: string, diningDate: Date) => {
    const query: string = `query ($location: ID!, $diningDate: String!) {
        dailySales(locationId: $location, startDate: $diningDate, endDate: $diningDate) {
            diningDate
            sales
            tips
            refunds
            total
            surcharges
            venueServiceFees
            remittance {
                paymentDate
                processed
                remitted
                absorbedProcessingFee
                fee
                dailyRemittedTotal
            }
        }
      }`;

    const formattedDiningDate = diningDate.toISOString().split("T")[0];

    return async (dispatch: Dispatch) => {
        try {
            dispatch(createAction.loading());

            const items = (
                await opsApi.graphQLQuery<GraphQLResponse>(query, { location, diningDate: formattedDiningDate })
            ).data.dailySales;

            const report = buildReport(items);

            dispatch(createAction.loaded(report));
        } catch (e) {
            dispatch(createAction.failed(e));
        }
    };
};

interface GraphQLResponse {
    dailySales: GraphQLDailySales[];
}

interface GraphQLDailySales {
    diningDate: string;
    outstanding: number;
    sales: number;
    tips: number;
    refunds: number;
    total: number;
    surcharges: number;
    venueServiceFees: number;
    remittance: GraphQLRemittance[];
}

interface GraphQLRemittance {
    paymentDate: string;
    processed: number;
    remitted: number;
    absorbedProcessingFee: number;
    fee: number;
    dailyRemittedTotal: number;
}

function buildReport(items: GraphQLDailySales[]): DailySalesDetails {
    const item = items[0];

    let totalAbsorbedProcessingFee = 0;
    let totalFee = 0;
    let totalRemitted = 0;

    for (let remittance of item.remittance) {
        totalAbsorbedProcessingFee += remittance.absorbedProcessingFee;
        totalFee += remittance.fee;
        totalRemitted += remittance.remitted;
    }

    return {
        diningDate: item.diningDate,
        sales: item.sales,
        tips: item.tips,
        subTotal: item.sales + item.tips,
        grandTotal: item.total,
        remitted: totalRemitted,
        surcharges: item.surcharges,
        venueServiceFees: item.venueServiceFees,
        refunded: item.refunds,
        absorbedProcessingFee: totalAbsorbedProcessingFee,
        fee: totalFee,
        remittance: item.remittance.map(mapRemittance),
    };
}

function mapRemittance({
    paymentDate,
    processed,
    absorbedProcessingFee,
    fee,
    remitted,
    dailyRemittedTotal,
}: GraphQLRemittance): Remittance {
    return {
        paymentDate: new Date(paymentDate),
        processed,
        absorbedProcessingFee,
        fee,
        remitted,
        dailyRemittedTotal,
    };
}
