import { Card, Row, Title } from "core/components/card";
import { FastField } from "formik";
import { Label } from "core/components/form/input/Label";
import { Switch } from "core/components/form/switch";

interface Props {
    disableFields: boolean;
}

export const ProductFlags = ({ disableFields }: Props) => {
    return (
        <Card>
            <Row collapse="down">
                <Title title="Flags" />
            </Row>
            <Row border="bottom">
                <Label label="Recommended" htmlFor="input-recommended" />
                <FastField
                    name="special"
                    component={Switch}
                    label="Recommended"
                    disabled={disableFields}
                    type="checkbox"
                    id="input-recommended"
                />
            </Row>
            <Row>
                <Label label="Most popular" htmlFor="input-popular" />
                <FastField
                    name="popular"
                    component={Switch}
                    label="Most popular"
                    disabled={disableFields}
                    type="checkbox"
                    id="input-popular"
                />
            </Row>
        </Card>
    );
};
