import { SelectItem } from "components/forms/SearchInput";

export enum SplitPaymentOption {
    SelectAmount = "SelectAmount",
    SplitEvenly = "SplitEvenly",
}

export const SplitPaymentOptionLabel = {
    [SplitPaymentOption.SelectAmount]: "Select Amount",
    [SplitPaymentOption.SplitEvenly]: "Split Evenly",
};

export const splitPaymentOptions: SelectItem[] = Object.keys(SplitPaymentOption).map((key) => {
    return { displayName: SplitPaymentOptionLabel[key], id: key };
});
