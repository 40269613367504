import { createAction } from "../reducers/edit";
import { scaffoldSaveAction } from "common/scaffolding/actions/scaffoldSaveAction";
import { EditableVenueMembershipProgram, GraphQLVenueMembershipInput, MembershipProgram } from "../types";
import { saveMembership } from "../api/saveMembership";
import { AppState } from "features";
import { getSaveMembershipTrackingEventData } from "../selectors";

export const saveVenueMembership = (locationId: string, formData: EditableVenueMembershipProgram) =>
    scaffoldSaveAction(
        (state) => state.memberships.edit,
        createAction,
        async (membershipId: string | undefined) =>
            await saveMembership(locationId, membershipId, mapEditableMembershipToGraphQLMembership(formData)),
        undefined,
        false,
        undefined,
        undefined,
        (appState: AppState, saveResult?: MembershipProgram) =>
            getSaveMembershipTrackingEventData(appState, formData, saveResult)
    );

const mapEditableMembershipToGraphQLMembership = (
    membership: EditableVenueMembershipProgram
): GraphQLVenueMembershipInput => {
    return {
        id: membership.id,
        displayName: membership.displayName,
        privacyPolicyLink: membership.privacyPolicyLink!,
        membershipLevels: [
            {
                id: membership.levelId || undefined,
                displayName: membership.displayName,
                priceListId: membership.priceListId,
            },
        ],
    };
};
