import { opsApi } from "API";
import { StatementDescriptors } from "../types/StatementDescriptors";
import { GraphQLPayload } from "./types";
import { UpdateResult } from "../types/UpdateResult";

export const updateStatementDescriptors = async (
    locationId: string,
    input: StatementDescriptors
): Promise<UpdateResult> => {
    try {
        const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId, input });
        return res.data.updateStatementDescriptors;
    } catch (e) {
        throw e;
    }
};

const query = `
    mutation($locationId: ID!, $input: StatementDescriptorsInput!) {
        updateStatementDescriptors(locationId: $locationId, statementDescriptors: $input) {
            paymentStatementDescriptor
            payoutStatementDescriptor
        }
    }
`;
