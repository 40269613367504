import styles from "./ListItem.module.scss";

import { CatalogueItem, CatalogueSelections } from "../types";
import { Checkbox } from "core/components/form/checkbox";
import { getIn, useFormikContext } from "formik";
import { memo, useCallback, useMemo } from "react";

export interface Props {
    collectionName: "products" | "modifiers";
    disabled: boolean;
    item: CatalogueItem;
    parentItem: CatalogueItem | null;
}

const RowSelectionComponent = ({ collectionName, disabled, item, parentItem }: Props) => {
    const form = useFormikContext<CatalogueSelections>();

    const { setFieldValue, setFieldTouched, values, touched } = form;

    const isParent = !parentItem;

    const parent = isParent ? item : parentItem;

    const parentId = isParent ? item.id : item.parentId;

    // selections for the parent item and its children
    const itemSelections = useMemo(
        () => (parentId ? getIn(values, [collectionName, parentId]) : null),
        [values, collectionName, parentId]
    );

    const isParentTouched = useMemo(
        () => (parentId ? getIn(touched, [collectionName, parentId]) : false),
        [touched, collectionName, parentId]
    );

    const numChildren = item.children?.length || 0;

    const numChildrenSelected = item.children?.filter((child) => itemSelections?.includes(child.id)).length || 0;

    const indeterminate = numChildrenSelected > 0 && numChildren > numChildrenSelected;

    const checked = isParent
        ? itemSelections === true || (numChildren > 0 && numChildren === numChildrenSelected)
        : itemSelections?.includes(item.id) || false;

    const handleChange = useCallback(() => {
        const isParent = !item.parentId;

        if (!parent) {
            return;
        }

        const numChildren = parent.children?.length || 0;

        const numChildrenSelected = parent.children?.filter((child) => itemSelections?.includes(child.id)).length || 0;

        const parentIsChecked = itemSelections === true || (numChildren > 0 && numChildren === numChildrenSelected);

        if (isParent) {
            if (parentIsChecked) {
                const childIds = item.children?.map(({ id }) => id);
                // only deselect current item.children in case some selections are hidden by filters
                const newSelections = Array.isArray(itemSelections)
                    ? itemSelections.filter((value) => !childIds?.includes(value))
                    : [];

                setFieldValue(`${collectionName}.${item.id}`, newSelections.length ? newSelections : undefined);
            } else {
                setFieldValue(
                    `${collectionName}.${item.id}`,
                    item.children?.length ? item.children.map((child) => child.id) : true
                );

                if (!isParentTouched) {
                    setFieldTouched(`${collectionName}.${item.id}`, true, false);
                }
            }
        } else {
            let val: string[] | undefined;

            if (!itemSelections || !Array.isArray(itemSelections)) {
                val = [item.id!];
            } else {
                if (itemSelections.includes(item.id)) {
                    val =
                        itemSelections.length === 1
                            ? undefined
                            : itemSelections?.filter((id: string) => item.id !== id);
                } else {
                    val = [...itemSelections, item.id];
                }
            }

            setFieldValue(`${collectionName}.${item.parentId}`, val);

            if (!isParentTouched) {
                setFieldTouched(`${collectionName}.${item.parentId}`, true, false);
            }
        }
    }, [collectionName, item, itemSelections, isParentTouched, parent, setFieldTouched, setFieldValue]);

    return (
        <Checkbox
            className={styles.rowSelect}
            key={item.key}
            checked={checked}
            disabled={disabled}
            id={item.key}
            indeterminate={indeterminate}
            name={`${collectionName}.${item.id}`}
            onChange={handleChange}
            value={item.id}
            aria-labelledby={`label-${item.id}`}
        />
    );
};

export const RowSelection = memo(RowSelectionComponent);
