import { connect } from "react-redux";
import { AppState } from "features";
import * as actions from "features/priceList/actions";
import { RouteComponentProps, withRouter } from "react-router";
import { withLoader, PageLoadingProps } from "common/loader/withLoader";
import { DataProps, FunctionProps } from "types/utils";
import { isLoaded } from "common/loader/isLoaded";
import { ListPriceListsPage, Props } from "../components/ListPriceListsPage";
import { LocationComponentProps, withLocation } from "features/location";
import { getCrudPermissions } from "features/location/selectors/getCrudPermissions";

const mapStateToProps = (
    state: AppState,
    { match: { params } }: RouteComponentProps<RouteParams>
): DataProps<PageLoadingProps<Props>> => {
    const {
        priceLists: { list },
    } = state;

    if (isLoaded(list)) {
        return {
            priceLists: list.data,
            loadStatus: "loaded",
            permissions: getCrudPermissions(state).pricelist,
        };
    }

    return {
        loadStatus: list.status,
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { restaurantLocation, match: { params } }: LocationComponentProps & RouteComponentProps<RouteParams>
): FunctionProps<PageLoadingProps<Props>> => ({
    fetch: () => {
        dispatch(actions.list(restaurantLocation.id));
    },
});

interface RouteParams {
    location: string;
    type: string;
}

export const ListPriceListsPageContainer = withRouter(
    withLocation(connect(mapStateToProps, mapDispatchToProps)(withLoader(ListPriceListsPage)))
);
