import { Card, Row, Title } from "core/components/card";
import { FastField, useFormikContext } from "formik";
import { FieldErrors } from "core/components/form/fieldErrors";
import { MediaUpload, MediaUploadDragInfo } from "core/components/mediaUpload";
import { Subtitle } from "core/components/card/Subtitle";
import { useEffect, useRef } from "react";
import { usePrevious } from "common/hooks";
import { UploadFileType } from "components/forms";
import { menuDataUrl } from "common/menuData/menuDataUrl";

interface Props {
    disableFields: boolean;
}

export const ProductVideo = ({ disableFields }: Props) => {
    const {
        setFieldValue,
        values: { showVideoUpload, video },
    } = useFormikContext<any>();

    const prevShowVideoUpload = usePrevious(showVideoUpload);

    const containerRef = useRef<HTMLDivElement>(null);

    const allowScroll = useRef(false);

    useEffect(() => {
        if (showVideoUpload && !prevShowVideoUpload && containerRef.current && allowScroll.current) {
            containerRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }

        if (!showVideoUpload && video) {
            setFieldValue("video", undefined);
        }

        // setting this after first run prevents scroll on mount when showing the video upload
        allowScroll.current = true;
    }, [prevShowVideoUpload, setFieldValue, showVideoUpload, video]);

    if (!showVideoUpload) {
        return null;
    }

    return (
        <Card ref={containerRef}>
            <Row collapse="down">
                <Title title="Media" />
            </Row>
            <Row collapse="both">
                <Subtitle subtitle="Must be .mp4. Maximum size: 5MB" />
            </Row>
            <Row>
                <FieldErrors fieldNames={["video"]}>
                    <FastField
                        acceptedFileTypes={{
                            "video/mp4": [],
                        }}
                        component={MediaUpload}
                        disabled={disableFields}
                        getDragMessage={getDragMessage}
                        getThumbUrl={getThumbUrl}
                        maxFileSizeMegabytes={4}
                        name="video"
                        resetFileInfo={true}
                        showDelete={true}
                        showFile={true}
                        showReset={false}
                        type={UploadFileType.Video}
                        uploadButtonLabel="Upload media"
                    />
                </FieldErrors>
            </Row>
        </Card>
    );
};

function getDragMessage({ isDragActive }: MediaUploadDragInfo) {
    return isDragActive ? "Drop media" : "or drag to upload";
}

function getThumbUrl(thumb: string | undefined) {
    return thumb ? menuDataUrl(thumb) : undefined;
}
