import * as React from "react";
import { FieldProps } from "formik";

import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";

import "./RadioButtonGroup.scss";

interface Option {
    text: string;
    value: any;
}

export interface Props {
    options: Option[];
    disabled: boolean;
    onChange?: (e: RadioChangeEvent) => void;
    defaultValue?: any;
}

export interface State {
    value: string;
}

export class RadioButtonGroup extends React.Component<Props & FieldProps, State> {
    constructor(props: Props & FieldProps) {
        super(props);

        const {
            field: { value },
            defaultValue,
        } = props;

        this.state = {
            value: defaultValue === undefined ? value : defaultValue,
        };
    }

    handleChange = (e: RadioChangeEvent) => {
        const {
            form,
            onChange,
            field: { name },
        } = this.props;

        onChange && onChange(e);
        form.setFieldValue(name, e.target.value);

        this.setState({
            value: e.target.value,
        });
    };

    render() {
        const { options, disabled } = this.props;

        const radioList = options.map((option) => (
            <Radio key={option.value} value={option.value}>
                {option.text}
            </Radio>
        ));

        return (
            <Radio.Group
                onChange={this.handleChange}
                value={this.state.value}
                className="radio--container"
                disabled={disabled}
            >
                {radioList}
            </Radio.Group>
        );
    }
}
