import { opsApi } from "API";
import { AppState } from "features";
import { shouldListPublishedCategories } from "features/location/selectors/getPosMenuSupported";
import { GraphQLItem } from "../../../common/scaffolding/types";

export const listCategories = async (location: string, state: AppState) => {
    const usePublishedCategories = shouldListPublishedCategories(state);

    const res = await opsApi.graphQLQuery<GraphQLPayload>(buildQuery(usePublishedCategories), { location });
    return res.data.categories;
};

type GraphQLPayload = { categories: GraphQLCategorySummary[] };

export interface GraphQLCategorySummary extends GraphQLItem {
    type: "food" | "drink";
    menus: GraphQLItem[];
    services: GraphQLItem[];
    thumbnailImage?: string;
}

function buildQuery(shouldUsePublishedCategories: boolean) {
    let queryType: string;
    if (shouldUsePublishedCategories) {
        queryType = "categories: publishedCategories";
    } else {
        queryType = "categories";
    }

    return `query($location: ID!) {
        ${queryType} (locationId: $location) {
            ${categoryListFields}
        }
    }`;
}

export const categoryListFields = `
    id,
    internalName,
    displayName,
    type,
    menus {
        id,
        internalName,
        displayName
    },
    services {
        id,
        internalName,
        displayName
    },
    thumbnailImage
`;
