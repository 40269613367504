import { FeatureConfiguration } from "features";
import { Route } from "react-router";
import { DashboardPageContainer } from "./containers/DashboardPageContainer";
import { Col } from "antd";

export default function (config: FeatureConfiguration) {
    config.addRoutes(config.routes.locationRoutes, [
        <Route
            exact
            key="dashboard"
            path="/:region/:location"
            render={({ match }) => (
                <>
                    <Col span={24} md={24} className={`content__col content__col--dashboard`}>
                        <div className="content__col-edit">
                            <Route component={DashboardPageContainer} />
                        </div>
                    </Col>
                </>
            )}
        />,
    ]);
}
