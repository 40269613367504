import { useCallback, useMemo } from "react";
import { ColumnProps, TablePaginationConfig } from "antd/lib/table";
import { CrudPermissions } from "features/location/types/createCrudPermissions";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { NewDataButton } from "common/scaffolding/components/DataListingPage/NewDataButton";
import { PackageSummary } from "..";
import { RouteComponentProps } from "react-router";
import { TableListing } from "../../../common/scaffolding/components/TableListing/TableListing";
import "./GroupTabsListPackages.scss";
import classNames from "classnames";

export interface Props {
    groupTabsEnabled: boolean;
    packages?: PackageSummary[];
    permissions: CrudPermissions;
}

const paginationSettings: TablePaginationConfig = {
    size: "default",
    position: ["bottomRight"],
    pageSize: 10,
    showSizeChanger: false,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
};

export const GroupTabsListPackages = ({
    groupTabsEnabled,
    history,
    match,
    packages,
    permissions,
}: Props & RouteComponentProps<any>) => {
    const canCreate = permissions.canUpdate && groupTabsEnabled;

    const bindColumns = useMemo(() => {
        let columns: ColumnProps<PackageSummary>[] = [
            {
                title: "Package name",
                dataIndex: "displayName",
                key: "displayName",
            },
            {
                title: "Products",
                dataIndex: "itemCount",
                key: "itemCount",
            },
            {
                title: "Times used",
                dataIndex: "usedCount",
                key: "usedCount",
            },
            {
                title: "Status",
                dataIndex: "enabled",
                key: "enabled",
                render: (status) =>
                    status ? (
                        <span className="group-tabs-packages__status--active">Active</span>
                    ) : (
                        <span className="group-tabs-packages__status">Unavailable</span>
                    ),
            },
        ];

        if (permissions.canUpdate) {
            columns = columns.concat({
                title: "",
                key: "action",
                className: "col-actions col-center",
                render: (text, record) => (
                    <Link to={`${match.url}/${record.id}`}>
                        <EditOutlined />
                    </Link>
                ),
            });
        }
        return columns;
    }, [permissions, match]);

    const handleRowClick = useCallback(
        (record: PackageSummary) => {
            history.push({
                pathname: `${match.url}/${record.id}`,
            });
        },
        [history, match.url]
    );

    const classes = classNames({
        "group-tabs-packages": true,
        "group-tabs-packages--disabled": !groupTabsEnabled,
    });

    return (
        <div className={classes}>
            <div className="group-tabs-packages__row">
                <NewDataButton text="Create package" newItemRoute={`${match.url}/new`} disabled={!canCreate} />
            </div>

            {packages && packages.length ? (
                <div className="group-tabs-packages__row">
                    <TableListing
                        columns={bindColumns}
                        dataSource={packages}
                        keyField="id"
                        pagination={paginationSettings}
                        onRowClick={groupTabsEnabled ? handleRowClick : undefined}
                        col={24}
                        className={"group-tabs-packages__table"}
                    />
                </div>
            ) : (
                <div className="group-tabs-packages__row">
                    By creating packages, customers will be able to select and order from preset menus.
                </div>
            )}
        </div>
    );
};
