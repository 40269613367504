import { opsApi } from "API";
import { PosConfigurationSetting } from "../types/PosConfigurationSetting";
import { posConfigurationFields } from "./getPosConfiguration";
import { ValidationError } from "common/forms/ValidationError";

export const updatePosConfiguration = async (
    slug: string,
    settings: PosConfigurationSetting[]
): Promise<PosConfigurationSetting[]> => {
    try {
        const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId: slug, settings });
        return res.data.updatePosConfiguration;
    } catch (error) {
        const validationError = ValidationError.fromGraphQLErrors(error.errors);

        if (validationError) {
            throw validationError;
        }

        throw error;
    }
};

type GraphQLPayload = { posConfiguration: PosConfigurationSetting[] };

const query = `
    mutation ($locationId: ID!, $settings: [PosConfigurationSettingInput!]!) {
        updatePosConfiguration(locationId: $locationId, settings: $settings) {
            ${posConfigurationFields}
        }
    }
`;
