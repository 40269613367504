import { createAction } from "../reducers/edit";
import { scaffoldArchiveAction } from "common/scaffolding/actions/scaffoldArchiveAction";
import { archivePromotion } from "../api";
import { AppState } from "../../state";
import { getArchivePromotionTrackingEventData } from "../selectors";
import { NotificationMessage } from "../../../common/notificationMessage";
import { EditablePromotion } from "../types";

export const archive = (locationId: string, promotion: EditablePromotion) =>
    scaffoldArchiveAction(
        (state) => state.promotions.edit,
        createAction,
        (id) => archivePromotion(locationId, id),
        null,
        NotificationMessage.ARCHIVE_SUCCESS,
        (appState: AppState) => getArchivePromotionTrackingEventData(appState, promotion)
    );
