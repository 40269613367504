export const QuickSellMissingData = () => (
    <div className="quick-sell-page__missing-data">
        <div className="quick-sell-page__missing-data__image">
            <svg width="51" height="32" viewBox="0 0 51 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#empty-icon-quicksell)">
                    <path
                        d="M25.1429 32.001C39.0289 32.001 50.2857 29.555 50.2857 26.5376C50.2857 23.5203 39.0289 21.0742 25.1429 21.0742C11.2568 21.0742 0 23.5203 0 26.5376C0 29.555 11.2568 32.001 25.1429 32.001Z"
                        fill="#F5F5F5"
                    />
                    <path
                        d="M43.2141 10.7403L35.2423 1.7631C34.8596 1.1512 34.301 0.78125 33.7125 0.78125H16.5729C15.9844 0.78125 15.4258 1.1512 15.0431 1.76232L7.07129 10.7411V17.952H43.2141V10.7403Z"
                        stroke="#D9D9D9"
                    />
                    <path
                        d="M32.6958 13.2153C32.6958 11.9627 33.4768 10.9285 34.4456 10.9277H43.2141V25.0834C43.2141 26.7404 42.177 28.0985 40.8963 28.0985H9.38915C8.10843 28.0985 7.07129 26.7396 7.07129 25.0834V10.9277H15.8399C16.8086 10.9277 17.5896 11.9603 17.5896 13.213V13.2302C17.5896 14.4829 18.3793 15.4944 19.3473 15.4944H30.9381C31.9061 15.4944 32.6958 14.4735 32.6958 13.2208V13.2153V13.2153Z"
                        fill="#FAFAFA"
                        stroke="#D9D9D9"
                    />
                </g>
                <defs>
                    <clipPath id="empty-icon-quicksell">
                        <rect width="50.2857" height="32" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
        <div className="quick-sell-page__missing-data__title">
            No Quick Sell compatible products are currently available.
        </div>
        <div className="quick-sell-page__missing-data__description">
            To use Quick Sell your catalogue must contain at least one food product without variants or required
            modifiers. This list is refreshed daily.
        </div>
    </div>
);
