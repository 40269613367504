import { DeleteOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";

import "./EditRow.scss";
export interface EditRowInnerProps {
    labelFor?: string;
    title?: string;
    titleInfo?: string;
    subTitle?: string | React.ReactNode;
    className?: string;
    labelCol?: number;
    col?: number;
    align?: string;
    onDelete?: () => void;
    children: React.ReactNode;
}

export const EditRow = (props: EditRowInnerProps) => (
    <Row justify="start" className={props.className} gutter={16} align="top">
        <Col span={props.labelCol || 10} className="col-info">
            <label htmlFor={props.labelFor}>
                {props.title && (
                    <h2 className="title">
                        {props.title} {props.titleInfo && <span>{props.titleInfo}</span>}
                        {!!props.onDelete && <DeleteOutlined onClick={props.onDelete} />}
                    </h2>
                )}
                {props.subTitle && <div className={`sub-title ${!props.title && "no-title"}`}>{props.subTitle}</div>}
            </label>
        </Col>
        <Col span={props.col ? props.col : 14} className="col-content">
            {props.children}
        </Col>
    </Row>
);
