import { FeatureConfiguration } from "features";
import { reducer } from "features/modifier/reducers";
import * as actions from "features/modifier/actions";

export { actions };
export * from "features/modifier/types";

export default function (config: FeatureConfiguration) {
    // legacy modifier pages have been removed but we need the
    // redux state for other sections to work correctly
    //
    // TODO move modifiers state to catalogue feature
    config.reducers.modifiers = reducer;
}
