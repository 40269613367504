import { PropsWithChildren, ReactElement, useRef, useState } from "react";
import { Props as ButtonProps } from "core/components/button";
import { DeletePrompt, SubmitMode } from "./CrudActionFooter";
import { Bin } from "common/icons";
import { ActionButton } from ".";
import { ConfirmDeleteModal } from "./ConfirmDeleteModal";
import * as React from "react";

interface Props {
    buttonProps?: PropsWithChildren<ButtonProps & Record<string, any>>;
    confirmDelete?: DeletePrompt | boolean;
    saving: boolean;
    submitMode: SubmitMode;
}

export const DeleteButton = ({ buttonProps, saving, submitMode, confirmDelete }: Props): ReactElement => {
    const [showConfirmPrompt, setShowConfirmPrompt] = useState(false);
    const deleteMouseEvent = useRef<React.MouseEvent<HTMLElement>>();
    const onClick = !!confirmDelete
        ? (e: React.MouseEvent<HTMLElement>) => {
              deleteMouseEvent.current = e;
              setShowConfirmPrompt(true);
          }
        : buttonProps?.onClick;

    const confirmDeleteOptions = resolveConfirmDeleteOptions(confirmDelete);

    return (
        <>
            <ActionButton
                buttonProps={{ ...buttonProps, onClick }}
                colorScheme="critical"
                icon={<Bin />}
                label="Delete"
                role="secondary"
                saving={saving}
                submitMode={submitMode}
                targetSubmitMode="archive"
            />
            {confirmDeleteOptions && (
                <ConfirmDeleteModal
                    visible={showConfirmPrompt}
                    title={confirmDeleteOptions.title}
                    deleteLabel={confirmDeleteOptions.deleteLabel}
                    children={confirmDeleteOptions.children}
                    onClick={() => {
                        deleteMouseEvent.current && buttonProps?.onClick?.(deleteMouseEvent.current);
                        deleteMouseEvent.current = undefined;
                    }}
                    onClose={() => setShowConfirmPrompt(false)}
                />
            )}
        </>
    );
};

const resolveConfirmDeleteOptions = (options?: DeletePrompt | boolean) => {
    if (options === true) return {};
    if (options === false) return undefined;
    return options;
};
