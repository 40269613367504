import { FieldInputProps, FormikProps } from "formik";
import { TreeItem } from "common/types";

import { EditableModifier } from "../../types";
import { useEffect } from "react";
import { initIdsByTypeDictionary } from "common/data/treeUtils";
import { EditableModifierProducts } from "features/modifier/types";

interface Props {
    form: FormikProps<EditableModifier>;
    field: FieldInputProps<string[]>;
    selectedItems: TreeItem[];
}

const includedTypes = ["product", "variant"];

// this component binds the selected tree items to the formik field value
export const ProductSelectionsAdapter = ({ field: { name }, form: { setFieldValue }, selectedItems }: Props) => {
    // handle tree selections
    useEffect(() => {
        const idsByType = initIdsByTypeDictionary(includedTypes);

        const selections: EditableModifierProducts = {
            productIds: [],
            variantIds: [],
        };

        selectedItems
            .filter((node: TreeItem) => includedTypes.includes(node.type))
            .forEach((node: TreeItem) => {
                // check dictionary to only add items once
                if (!idsByType[node.type][node.id]) {
                    idsByType[node.type][node.id] = true;
                    selections[`${node.type}Ids`]?.push(node.id);
                }
            });

        // keep ids sorted for better 'dirty' checks
        selections.productIds.sort();
        selections.variantIds.sort();

        setFieldValue(name, selections);
    }, [selectedItems, name, setFieldValue]);

    return null;
};
