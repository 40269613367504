import styles from "./SchedulePage.module.scss";

import { CalendarDateFormat, useCalendarContext } from "features/service/context/CalendarContext";

import { DatePickerInput } from "core/components/form/datePicker";
import { NavButton } from "core/components/navButton/NavButton";
import { Calendar, CalendarWrapper, StickyHeader } from ".";
import { Select } from "core/components/form/select";
import { priorityServiceCalendar } from "features/service/actions";
import { useDispatch, useSelector } from "react-redux";
import { getNow } from "features/service/selectors";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import moment from "moment";
import { getCalendarSectionOptions } from "features/service/selectors/getCalendarSections";
import {
    getCalendarItemsForWeek,
    getDateStringArray,
    haveDatesBeenFetched,
} from "features/service/selectors/getCalendar";
import { AppState } from "features";
import { createAction as trackingActions } from "common/appInsights/actions/track";
import { TrackEventData } from "common/appInsights/types/TrackEventData";
import {
    getDateChangedData,
    getSectionChangedData,
    getStartDateChangedData,
} from "features/service/tracking/getTrackingData";
import { Tab } from "features/service/types/Tab";

export const WeekServicesPage = () => {
    const dispatch = useDispatch();

    const { weekStartDateString, setWeekStartDateString } = useCalendarContext();

    const venueNow = useSelector(getNow);

    const activeLocation = useSelector(getActiveLocation);

    const today = new Date(venueNow.format(CalendarDateFormat));

    const sectionOptions = useSelector(getCalendarSectionOptions);

    const [selectedSection, setSelectedSection] = useState(sectionOptions.length ? sectionOptions[0] : undefined);

    const trackChange = useCallback(
        (eventData: TrackEventData) => {
            dispatch(trackingActions.track(eventData));
        },
        [dispatch]
    );

    const handlePrevious = useCallback(() => {
        if (!weekStartDateString) {
            return;
        }

        const newStartDate = moment(weekStartDateString, CalendarDateFormat)
            .subtract(7, "days")
            .format(CalendarDateFormat);

        setWeekStartDateString(newStartDate);
        trackChange(getStartDateChangedData(newStartDate, Tab.WEEK));
    }, [setWeekStartDateString, weekStartDateString, trackChange]);

    const handleNext = useCallback(() => {
        if (!weekStartDateString) {
            return;
        }

        const newStartDate = moment(weekStartDateString, CalendarDateFormat).add(7, "days").format(CalendarDateFormat);

        setWeekStartDateString(newStartDate);
        trackChange(getStartDateChangedData(newStartDate, Tab.WEEK));
    }, [setWeekStartDateString, weekStartDateString, trackChange]);

    const handleDateSelect = useCallback(
        (selectedDate: Date | undefined) => {
            const newStartDate = moment(selectedDate).startOf("week").format(CalendarDateFormat);
            setWeekStartDateString(newStartDate);
            trackChange(getDateChangedData(moment(selectedDate).format(CalendarDateFormat), Tab.WEEK));
        },
        [setWeekStartDateString, trackChange]
    );

    const handleSectionSelect = useCallback(
        (selection) => {
            setSelectedSection(selection);
            trackChange(getSectionChangedData(selection.value, Tab.WEEK));
        },
        [trackChange]
    );

    useEffect(() => {
        if (!weekStartDateString && activeLocation) {
            const initWeekStartDateString = venueNow.clone().startOf("week").format(CalendarDateFormat);

            setWeekStartDateString(initWeekStartDateString);
        }
    }, [activeLocation, setWeekStartDateString, weekStartDateString, venueNow]);

    const weekStartDate = useMemo(() => {
        if (!weekStartDateString) {
            return undefined;
        }
        return moment(weekStartDateString, CalendarDateFormat).toDate();
    }, [weekStartDateString]);

    const datesAlreadyFetched = useSelector((state: AppState) =>
        haveDatesBeenFetched(state, getDateStringArray(weekStartDateString, 7))
    );

    useEffect(() => {
        if (!weekStartDateString || !activeLocation || datesAlreadyFetched) {
            return;
        }
        dispatch(
            priorityServiceCalendar(activeLocation.id, {
                startDate: weekStartDateString,
                dayCount: 7,
            })
        );
    }, [activeLocation, dispatch, weekStartDateString, datesAlreadyFetched]);

    // FIXME the params obj is prob breaking memoization
    const calendarItems = useSelector((state: AppState) =>
        getCalendarItemsForWeek(state, { weekStartDateString, sectionId: selectedSection?.value })
    );

    return (
        <CalendarWrapper>
            <StickyHeader isExtended={true}>
                <div className={styles.controlsContainer}>
                    <div className={styles.datePickerContainer}>
                        <NavButton direction="previous" onClick={handlePrevious} />
                        <DatePickerInput
                            canClearInput={false}
                            closeOnDayClick={true}
                            locale="au" // TODO should we respect the venue or internal teams locale?
                            onSelect={handleDateSelect}
                            onTodayClick={handleDateSelect}
                            today={today}
                            value={weekStartDate}
                        />
                        <NavButton direction="next" onClick={handleNext} />
                    </div>
                    <div className={styles.selectContainer}>
                        <Select
                            options={sectionOptions}
                            value={selectedSection}
                            onChange={handleSectionSelect}
                            menuPosition="absolute"
                        />
                    </div>
                </div>
            </StickyHeader>
            <Calendar calendarItems={calendarItems} today={today} viewMode="week" />
        </CalendarWrapper>
    );
};
