import { CheckCircleFilled, InfoCircleFilled } from "@ant-design/icons";
import { Button } from "antd";
import { ImportResult } from "features/catalogue/types/ImportResult";
import { UploadLoader } from "./UploadLoader";

export interface Props {
    parentRecordTitle: string;
    childRecordTitle: string;
    imported?: ImportResult;
    onLoadNew: () => void;
    loading: boolean;
}

export const UploadSuccessful = ({ parentRecordTitle, childRecordTitle, imported, onLoadNew, loading }: Props) => {
    return (
        <div className="bulk-uploader--content">
            <UploadLoader loading={loading} />
            <div className="bulk-uploader--header success">
                <i className="check-circle" />
                <h1>Upload successful</h1>
                <span>
                    Your file was successfully uploaded and all {parentRecordTitle}s & {childRecordTitle}s have been
                    added to your me&u catalogue.
                </span>
            </div>
            <div className="bulk-uploader--body">
                <div className="body-top">
                    <h2>What's changed</h2>
                    {imported && imported.summary && (
                        <div className="import-success">
                            <p>
                                <CheckCircleFilled /> {imported.summary.parentRecordsUpdated}{" "}
                                <span className="title-case">{parentRecordTitle}</span>s added
                            </p>
                            <p>
                                <CheckCircleFilled /> {imported.summary.childRecordsUpdated}{" "}
                                <span className="title-case">{childRecordTitle}</span>s added to new {parentRecordTitle}
                                s
                            </p>
                        </div>
                    )}
                    <div className="review">
                        <InfoCircleFilled />
                        <h3>Review your content</h3>
                        <span>Your new items will need additional content before publishing.</span>
                    </div>
                </div>
            </div>
            <div className="bulk-uploader--footer">
                <Button type="primary" onClick={onLoadNew}>
                    View new {parentRecordTitle}s
                </Button>
            </div>
        </div>
    );
};
