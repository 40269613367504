import styles from "./ActionsPopup.module.scss";

import { PropsWithChildren, useCallback, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { Button, Props as ButtonProps } from "../button";
import { Placement } from "@popperjs/core";
import { useKeyboard, useOnClickOutside, useOnFocusOutside } from "common/hooks";
import { More } from "common/icons/More";

interface Props {
    buttonProps?: PropsWithChildren<ButtonProps & Record<string, any>>;
    closeOnMenuClick?: boolean;
    menuPlacement?: Placement;
    menuModifiers?: any;
}

export const ActionsPopup = ({
    children,
    closeOnMenuClick,
    buttonProps = {},
    menuPlacement = "bottom-end",
    menuModifiers = [
        {
            name: "offset",
            options: { offset: [0, 4] },
        },
    ],
}: PropsWithChildren<Props>) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLDivElement | null>(null);
    const [menuElement, setMenuElement] = useState<HTMLDivElement | null>(null);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const popper = usePopper(buttonRef.current, menuElement, {
        placement: menuPlacement,
        modifiers: menuModifiers,
    });

    const handleBtnClick = useCallback(() => {
        setMenuOpen(!menuOpen);
    }, [menuOpen]);

    const handleClose = useCallback(() => {
        menuOpen && setMenuOpen(false);
    }, [menuOpen]);

    const handleActionClick = useCallback(() => {
        menuOpen && closeOnMenuClick && setMenuOpen(false);
    }, [closeOnMenuClick, menuOpen]);

    useOnClickOutside(containerRef, handleClose, menuOpen);
    useOnFocusOutside(containerRef, handleClose, menuOpen);
    useKeyboard("Escape", handleClose, menuOpen);

    if (!buttonProps?.children) {
        buttonProps = {
            children: <More />,
            padding: "icon",
            ...buttonProps,
        };
    }

    return (
        <div ref={containerRef}>
            <div className={styles.container} ref={buttonRef}>
                <Button role="secondary" type="button" onClick={handleBtnClick} {...buttonProps} />
            </div>
            {menuOpen && (
                <div
                    style={popper.styles.popper}
                    {...popper.attributes.popper}
                    ref={setMenuElement}
                    onClick={handleActionClick}
                >
                    {children}
                </div>
            )}
        </div>
    );
};
