import { getSaveServiceTrackingEventData } from "features/service/selectors";
import { EditableService, ServiceSummary } from "features/service/types";
import { createAction } from "../reducers/edit";
import { createAction as calendarActions } from "../reducers/calendar";
import { scaffoldSaveAction } from "common/scaffolding/actions/scaffoldSaveAction";
import { saveService, ServiceInputModel } from "../api/saveService";
import { History } from "history";
import { AppDispatch, AppState } from "features";
import { mapListItem } from "./list";

export const save = (location: string, formData: EditableService, clone: boolean = false, history: History) =>
    scaffoldSaveAction(
        (state) => state.services.edit,
        createAction,
        async (serviceId: string | undefined) => {
            const serviceInputModel = mapInputModel(formData);
            return mapListItem(await saveService(location, serviceId, serviceInputModel));
        },
        undefined,
        clone,
        (_, dispatch: AppDispatch) => {
            dispatch(calendarActions.reset());
            return true;
        },
        false,
        (appState: AppState, saveResult?: ServiceSummary) =>
            getSaveServiceTrackingEventData(appState, formData, saveResult)
    );

const mapInputModel = ({
    internalName,
    displayName,
    times: { startTime, endTime },
    dates,
    categories,
    menus,
    courses,
    setMenus,
    defaultCourse,
    daysOfWeek,
    surcharge,
    surchargeSku,
    available,
    posId,
    sections,
    menuPriority,
    featured,
    priceList,
    membershipPriceLists,
    applyAlcoholicDrinksRestriction,
}: EditableService): ServiceInputModel => ({
    internalName,
    displayName,
    startTime,
    endTime,
    categories,
    menus,
    dates,
    courses,
    setMenus,
    defaultCourse: defaultCourse || null,
    daysOfWeek: daysOfWeek.reduce((a, b) => a | b, 0),
    surcharge: surcharge ? surcharge / 100 : surcharge,
    surchargeSku: surchargeSku,
    available: available,
    posId,
    sections,
    menuPriority,
    featured: {
        title: featured && featured.title ? featured.title : null,
        items: featured && featured.item ? [featured.item] : [],
    },
    priceList,
    membershipPriceLists: Object.keys(membershipPriceLists).map((membershipLevel) => ({
        membershipLevel,
        priceList: membershipPriceLists[membershipLevel],
    })),
    applyAlcoholicDrinksRestriction,
});
