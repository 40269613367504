import styles from "./PosCatalogueImportPage.module.scss";

import { ExtendedPermissions } from "features/location/types/ExtendedPermissions";
import { List } from "features/posCatalogueImport/components/List";
import { ListItemGroup } from "./ListItemGroup";
import { Pagination } from "core/components/pagination";
import { PosCatalogueImportItem } from "../types";
import { scrollIntoView } from "common/utility/scrollIntoView";
import {
    ComponentType,
    Dispatch,
    memo,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
} from "react";
import { useLocalStorage } from "common/hooks/useLocalStorage";
import { ListHeaderRow } from "./ListHeaderRow";
import { modifier, product } from ".";
import { CardFooter, EmptyMessage } from "core/components/card";
import { normaliseText } from "common/utility/StringUtils";
import { FiltersContext } from "../context/FiltersContext";
import { PreselectedItemsAlert } from "./PreselectedItemsAlert";
import { EmptyMessageForFilters } from "core/components/card/EmptyMessageForFilters";

export interface Props {
    collectionName: "products" | "modifiers";
    exportData?: () => void;
    formatCurrency: (x: number) => string;
    listItem: ComponentType<product.ListItemProps> | ComponentType<modifier.ListItemProps>;
    headers: ComponentType<product.HeadersProps> | ComponentType<modifier.HeadersProps>;
    items: PosCatalogueImportItem[];
    pageIndex: number;
    pageSizeStorageKey: string;
    permissions: ExtendedPermissions;
    relatedCollectionName?: "modifiers";
    setPageIndex: Dispatch<SetStateAction<number>>;
}

export const ListItemsTabComponent = ({
    collectionName,
    formatCurrency,
    headers,
    items,
    listItem: ListItem,
    pageIndex,
    pageSizeStorageKey,
    permissions,
    relatedCollectionName,
    setPageIndex,
}: Props) => {
    const resetOnFilterChange = useRef(false);

    const [pageSize, setStoredPageSize] = useLocalStorage<number>(pageSizeStorageKey, 25);

    const start = (pageIndex - 1) * pageSize;

    const end = pageIndex * pageSize;

    const { categories, filterCount, filterText, setFilterText, setCategories } = useContext(FiltersContext);

    const categoryFilters = useMemo(
        () => (collectionName === "products" ? categories : []),
        [categories, collectionName]
    );

    const filteredItems = useMemo(() => {
        if (filterCount === 0 && !filterText) {
            return items;
        }

        const searchTerm = normaliseText(filterText.trim());

        let filteredItems = [...items];

        if (categoryFilters.length) {
            filteredItems = filteredItems.filter((item: PosCatalogueImportItem) => {
                // uncategorised
                if (!item.categories?.length && categoryFilters.includes("")) {
                    return true;
                }

                return item.categories?.some((category) => categoryFilters.includes(category.id)) || false;
            });
        }

        if (filterText) {
            filteredItems = filteredItems
                .map((item: PosCatalogueImportItem) => {
                    const displayName = normaliseText(item.displayName || "");
                    const id = normaliseText(item.sku || item.id || "");

                    if (displayName.includes(searchTerm) || id.includes(searchTerm)) {
                        return item;
                    }

                    if (!item.children || item.collapse) {
                        return null;
                    }

                    const matchingChildren = item.children.filter((child) => {
                        const displayName = normaliseText(child.displayName || "");
                        const id = normaliseText(child.sku || "");
                        return displayName.includes(searchTerm) || id.includes(searchTerm);
                    });

                    if (matchingChildren.length) {
                        return item;
                    }

                    return null;
                })
                .filter(Boolean) as PosCatalogueImportItem[];
        }

        return filteredItems;
    }, [categoryFilters, filterCount, filterText, items]);

    const pageItems = useMemo(() => filteredItems.slice(start, end), [filteredItems, start, end]);

    const handlePageSizeChange = useCallback(
        (size: number) => {
            if (size !== pageSize) {
                setStoredPageSize(size);
                setPageIndex(1);
                scrollIntoView("#pos-catalog-import");
            }
        },
        [pageSize, setStoredPageSize, setPageIndex]
    );

    const handleClearFilters = useCallback(() => {
        setCategories([]);
        setFilterText("");
    }, [setCategories, setFilterText]);

    // reset page on filters change
    useEffect(() => {
        if (resetOnFilterChange.current) {
            setPageIndex(1);
        } else {
            resetOnFilterChange.current = true;
        }
    }, [categoryFilters, setPageIndex, collectionName, filterText]);

    const hasFilters = !!(categoryFilters.length || filterText.length);

    return (
        <>
            <PreselectedItemsAlert collectionName={collectionName} />
            <div className={styles.resultsTable} role="table">
                <ListHeaderRow
                    collectionName={collectionName}
                    pageItems={pageItems}
                    headers={headers}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    relatedCollectionName={relatedCollectionName}
                />
                {pageItems.length === 0 && (
                    <div className={styles.emptyMessageContainer}>
                        {hasFilters ? (
                            <EmptyMessageForFilters onClear={handleClearFilters} />
                        ) : (
                            <EmptyMessage message="" size="standard"></EmptyMessage>
                        )}
                    </div>
                )}
                <List>
                    {pageItems.map((item) => {
                        if (collectionName === "products") {
                            return (
                                <ListItemGroup
                                    key={item.key}
                                    collectionName={collectionName}
                                    relatedCollectionName={"modifiers"}
                                    formatCurrency={formatCurrency}
                                    item={item}
                                    permissions={permissions}
                                    component={ListItem}
                                />
                            );
                        } else {
                            return (
                                <ListItemGroup
                                    key={item.key}
                                    collectionName={collectionName}
                                    formatCurrency={formatCurrency}
                                    item={item}
                                    permissions={permissions}
                                    component={ListItem}
                                />
                            );
                        }
                    })}
                </List>
            </div>
            <CardFooter>
                <Pagination
                    allowUserPageSize
                    pageSize={pageSize}
                    pageIndex={pageIndex}
                    buffer={2}
                    count={filteredItems.length}
                    onChange={(index) =>
                        setPageIndex((prevIndex) => {
                            if (prevIndex !== index) {
                                scrollIntoView("#pos-catalog-import");
                            }
                            return index;
                        })
                    }
                    onPageSizeChange={(size) => handlePageSizeChange(size)}
                />
            </CardFooter>
        </>
    );
};

export const ListItemsTab = memo(ListItemsTabComponent);
