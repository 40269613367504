import { connect } from "react-redux";
import { Props } from "features/posCatalogue/components/ModifiersTab";
import { AppState } from "features";
import { FunctionProps, DataProps } from "types/utils";
import { ModifiersTab } from "../components/ModifiersTab";
import { ExtendedPermissions } from "features/location/types/ExtendedPermissions";
import { getModifierPosCatalogueItems } from "../selectors/getPosCatalogueItems";
import { withRouter } from "react-router";
import { exportModifiersCsv } from "../actions/exportModifiersCsv";

export interface OwnProps {
    permissions: ExtendedPermissions;
    handleSetPagingAvailable: (hasPaging: boolean) => void;
    initialStatuses?: string[];
}

const mapStateToProps = (state: AppState, { permissions }: OwnProps): DataProps<Props> => {
    const {
        posCatalogue: { list },
    } = state;

    if (list.status !== "loaded") {
        throw new Error("Not loaded");
    }

    return {
        modifiers: getModifierPosCatalogueItems(state)!,
        permissions,
    };
};

const mapDispatchToProps = (dispatch: any, { handleSetPagingAvailable }: OwnProps): FunctionProps<Props> => ({
    handleSetPagingAvailable: (hasPaging: boolean) => handleSetPagingAvailable(hasPaging),
    exportData: () => dispatch(exportModifiersCsv()),
});

export const ModifiersContainer = connect(mapStateToProps, mapDispatchToProps)(withRouter(ModifiersTab));
