import styles from "./ModalContent.module.scss";

import { Bin } from "common/icons";
import { bulkDelete } from "../actions";
import {
    BulkDeleteInput,
    BulkDeleteItem,
    CatalogueItem,
    CatalogueItemSelections,
    CatalogueSelections,
    CollectionInfo,
    SelectionInfo,
} from "../types";
import { CardWidth, Confirm, Modal } from "core/components/modal";
import { CatalogueContext } from "../context/CatalogueContext";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { getModifierCatalogueItems } from "../selectors/getModifierCatalogueItems";
import { getProductCatalogueItems } from "../selectors/getProductCatalogueItems";
import { Row } from "core/components/card";
import { useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { getSelectionInfo } from "../selectors/getSelectionInfo";
import { simplePlural } from "common/utility/StringUtils";
import { AppState } from "features";
import { useSnackbarContext } from "core/components/snackbar/SnackbarContext";
import { usePrevious } from "common/hooks";
import { Status } from "core/components/snackbar/types/Message";
import { useParams } from "react-router";
import { CatalogueRouteParams } from "../types/CatalogueRouteParams";
import { getIsParentLocation } from "features/location/selectors/getIsParentLocation";

interface Props {
    isParentLocation: boolean;
    onClose: () => void;
    onConfirm: () => boolean | void;
    selectionInfo: SelectionInfo;
    visible: boolean;
    tab: string;
}

const BulkDeleteContainer = () => {
    const dispatch = useDispatch();

    const form = useFormikContext<CatalogueSelections>();
    const { values } = form;
    const { addMessage } = useSnackbarContext();
    const { setModifiersPageIndex, setProductsPageIndex } = useContext(CatalogueContext);

    const { tab } = useParams<CatalogueRouteParams>();

    const { bulkDeleteOpen, setBulkDeleteOpen } = useContext(CatalogueContext);

    const activeLocation = useSelector(getActiveLocation);
    const isParentLocation = useSelector(getIsParentLocation) || false;

    const products = useSelector(getProductCatalogueItems);
    const modifiers = useSelector(getModifierCatalogueItems);

    const selectionInfo = getSelectionInfo({ selections: values, products, modifiers });

    const bulkDeleteStatus = useSelector((state: AppState) => state.catalogue.bulkDelete.status);
    const prevBulkDeleteStatus = usePrevious(bulkDeleteStatus);

    const handleClose = useCallback(() => {
        setBulkDeleteOpen(false);
    }, [setBulkDeleteOpen]);

    const handleConfirm = useCallback(() => {
        if (!activeLocation || !bulkDeleteOpen) {
            return;
        }

        const input: BulkDeleteInput =
            tab === "products"
                ? {
                      products: getItemSelections(values.products, products),
                      modifiers: [],
                  }
                : {
                      products: [],
                      modifiers: getItemSelections(values.modifiers, modifiers),
                  };

        if (tab === "products") {
            setProductsPageIndex(1);
        }

        if (tab === "modifiers") {
            setModifiersPageIndex(1);
        }

        dispatch(bulkDelete(activeLocation.id, input));
    }, [
        activeLocation,
        bulkDeleteOpen,
        tab,
        values.products,
        values.modifiers,
        products,
        modifiers,
        dispatch,
        setProductsPageIndex,
        setModifiersPageIndex,
    ]);

    useEffect(() => {
        const collectionInfo = getActiveCollection(tab, selectionInfo);
        const total = collectionInfo.numParentsSelected + collectionInfo.numChildrenSelected;

        if (prevBulkDeleteStatus && bulkDeleteStatus !== prevBulkDeleteStatus) {
            if (bulkDeleteStatus === "loaded") {
                addMessage({ status: Status.SUCCESS, content: `${total} items deleted` });

                if (tab === "products") {
                    form.setFieldValue("products", {});
                    form.setFieldValue("selections", { modifiers: selectionInfo.modifiers, products: [] });
                } else {
                    form.setFieldValue("modifiers", {});
                    form.setFieldValue("selections", { modifiers: [], products: selectionInfo.products });
                }
            }

            if (bulkDeleteStatus === "failed") {
                addMessage({ status: Status.ERROR, content: "Some items failed to delete" });
            }
        }
    }, [bulkDeleteStatus, prevBulkDeleteStatus, addMessage, tab, selectionInfo, form]);

    return (
        <BulkDeleteModalComponent
            isParentLocation={isParentLocation}
            onClose={handleClose}
            onConfirm={handleConfirm}
            selectionInfo={selectionInfo}
            visible={bulkDeleteOpen}
            tab={tab ?? "products"}
        />
    );
};

// @private
export const BulkDeleteModalComponent = ({
    isParentLocation,
    onClose,
    onConfirm,
    selectionInfo,
    visible,
    tab,
}: Props) => {
    const collectionInfo = getActiveCollection(tab, selectionInfo);
    const total = collectionInfo.numParentsSelected + collectionInfo.numChildrenSelected;

    return (
        <Modal
            cardClassName={styles.card}
            contentContainerClassName={styles.contentContainer}
            footer={
                <Confirm
                    confirmLabel={
                        <>
                            <Bin />
                            <span>Delete {total} items</span>
                        </>
                    }
                    onConfirm={onConfirm}
                    confirmProps={{
                        colorScheme: "critical",
                    }}
                />
            }
            onClose={onClose}
            title={`Are you sure you want to delete ${total} item${simplePlural(total)}?`}
            visible={visible}
            width={CardWidth.NARROW}
        >
            <Row>{getDeleteWarning(collectionInfo, isParentLocation)}</Row>
        </Modal>
    );
};

export const BulkDeleteModal = BulkDeleteContainer;

function getItemSelections(itemSelections: CatalogueItemSelections, items: CatalogueItem[]): BulkDeleteItem[] {
    const selectedParentIds = Object.entries(itemSelections)
        .filter(([_, value]) => value)
        .map(([key]) => key);

    let itemsToDelete: BulkDeleteItem[] = [];

    if (selectedParentIds.length) {
        items
            .filter(({ id }) => id && selectedParentIds.includes(id))
            .forEach((item) => {
                if (item.id) {
                    const selections = itemSelections[item.id];
                    const children = item.children?.filter((child) => !!child.id) || [];

                    if (selections === true || !children.length) {
                        itemsToDelete.push({
                            id: item.id,
                        });
                    }

                    if (Array.isArray(selections)) {
                        // all children are selected - can just delete parent
                        if (children.every(({ id }) => id && selections.includes(id))) {
                            itemsToDelete.push({
                                id: item.id,
                            });
                        } else {
                            // some children are selected
                            itemsToDelete = [
                                ...itemsToDelete,
                                ...children
                                    .filter(({ id }) => id && selections.includes(id))
                                    .map(({ id }) => ({ id: id!, parentId: item.id })),
                            ];
                        }
                    }
                }
            });
    }

    return itemsToDelete;
}

const getActiveCollection = (tab: string | undefined, selectionInfo: SelectionInfo) =>
    tab === "products" ? selectionInfo.products : selectionInfo.modifiers;

function getDeleteWarning(collectionInfo: CollectionInfo, isParentLocation: boolean) {
    const fragments: string[] = [];

    if (collectionInfo.numParentsSelected) {
        fragments.push(collectionInfo.parentSummary);
    }

    if (collectionInfo.numChildrenSelected) {
        fragments.push(
            collectionInfo.numParentsSelected
                ? `(including ${collectionInfo.childSummary})`
                : collectionInfo.childSummary
        );
    }

    return (
        <div className={styles.messageContainer}>
            {isParentLocation ? (
                <>
                    <p>
                        You are about to delete {fragments.join(" ")} from your Brand Catalogue. This action cannot be
                        undone.
                    </p>
                    <p>These items will be removed from venues next time they update.</p>
                </>
            ) : (
                <p>
                    You have selected {fragments.join(" ")}. Deleted items will be removed from your catalogue and
                    cannot be restored.
                </p>
            )}
        </div>
    );
}
