import "../EditPriceListPage.scss";
import { Formik, Field, yupToFormErrors, validateYupSchema, FastField } from "formik";
import { FieldErrors, TextInput } from "components/forms";
import { SaveStatus } from "common/loader";
import { EditRow } from "common/scaffolding/components/EditRow";
import { Form } from "antd";
import { isNew } from "common/utility/modelUtils";
import { AdjustmentType, EditablePriceListSettings } from "../../types";
import { useState } from "react";
import { editablePriceListSettingsSchema } from "../../schema/EditablePriceListSchema";
import { PosEditRow } from "components/forms/posUI/PosEditRow";
import { RouteComponentProps } from "react-router-dom";
import { SwitchInput } from "components/forms/SwitchInput";
import { PriceListPermissions } from "features/priceList/types/PriceListPermissions";
import { InputNumberAddon } from "components/forms/InputNumberAddon";
import { Effect } from "common/FormikEdffect";
import { FormikProps } from "formik";
import { AdjustmentTypeSelector } from "../AdjustmentTypeSelector";
import { CrudActionFooter, SubmitMode } from "core/components/actionFooter/CrudActionFooter";
import { ScrollToFormikError } from "components/forms/ScrollToFormikError";

export interface Props extends RouteComponentProps<RouteParams> {
    settings: EditablePriceListSettings;
    saveStatus: SaveStatus;
    onSave: (data: EditablePriceListSettings, clone: boolean) => void;
    permissions: PriceListPermissions;
    onRemove: (productId: string, type: string, itemId: string) => void;
    onArchive: () => void;
    onChange: (preventNavigation: boolean) => void;
}

interface RouteParams {
    id: string;
    location: string;
}

const validate = (value: EditablePriceListSettings) => {
    const context = {
        validatePos: document.getElementsByName("posId").length > 0,
    };

    return validateYupSchema(value, editablePriceListSettingsSchema, undefined, context).then(
        () => ({}),
        (err: any) => yupToFormErrors(err)
    );
};

export const EditPriceListTab = ({ settings, saveStatus, onSave, permissions, onArchive, onChange }: Props) => {
    const [submittingMode, setSubmittingMode] = useState<SubmitMode>("save");
    const [allowChangeTrigger, setAllowChangeTrigger] = useState(true);
    const isUpdate = !isNew(settings.id);
    const canUpdate = permissions.canUpdate;
    const canClone = permissions.canUpdate && isUpdate;
    const canDelete = permissions.canDelete && isUpdate;
    const canCreateBulkRule = permissions.canCreateBulkRule;

    const handleDuplicate = (data: EditablePriceListSettings) => {
        setSubmittingMode("clone");
        setAllowChangeTrigger(false);
        onSave(data, true);
    };

    const handleDelete = () => {
        setSubmittingMode("archive");
        setAllowChangeTrigger(false);
        onArchive();
    };

    const handleSubmit = (data: EditablePriceListSettings) => {
        setSubmittingMode("save");
        setAllowChangeTrigger(false);
        onSave(data, false);
    };

    const handleChange = (form: FormikProps<EditablePriceListSettings>) => {
        allowChangeTrigger && onChange(form.dirty);
    };

    return (
        <Formik
            validate={validate}
            initialValues={settings}
            onSubmit={handleSubmit}
            key={settings.id}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {(form) => (
                <Form onFinish={form.handleSubmit} autoComplete="off">
                    <ScrollToFormikError />
                    <Effect
                        onChange={() => {
                            handleChange(form);
                        }}
                    />
                    <EditRow labelCol={12} col={10} title="Price List Name" subTitle="">
                        <FieldErrors name="displayName" form={form}>
                            <Field name="displayName" component={TextInput} placeholder="Price List Name" />
                        </FieldErrors>
                    </EditRow>
                    <PosEditRow
                        labelCol={12}
                        col={6}
                        fieldName="priceListPosId"
                        defaultTitle="Price List POS ID"
                        defaultSubTitle=""
                    >
                        <FieldErrors name="posId" form={form}>
                            <FastField name="posId" component={TextInput} placeholder="Price List POS ID" />
                        </FieldErrors>
                    </PosEditRow>
                    {canCreateBulkRule && (
                        <>
                            <EditRow
                                labelCol={12}
                                col={6}
                                title="Global adjustment"
                                subTitle="Apply a set percentage increase / discount across all items in this price list"
                            >
                                <FieldErrors name="useGlobalAdjustment" form={form}>
                                    <Field name="useGlobalAdjustment" component={SwitchInput} />
                                </FieldErrors>
                            </EditRow>
                            {form.values.useGlobalAdjustment ? (
                                <EditRow
                                    labelCol={12}
                                    col={6}
                                    title="Adjustment type"
                                    subTitle="Select adjustment type."
                                >
                                    <FieldErrors name="adjustmentType" form={form}>
                                        <AdjustmentTypeSelector
                                            name="adjustmentType"
                                            placeholder="Select adjustment type."
                                            key="adjustmentkey"
                                        />
                                    </FieldErrors>
                                </EditRow>
                            ) : (
                                <EditRow
                                    labelCol={12}
                                    col={6}
                                    title="Adjustment type"
                                    subTitle="Select adjustment type."
                                >
                                    <FieldErrors name="adjustmentType" form={form}>
                                        <AdjustmentTypeSelector
                                            name="adjustmentType"
                                            disabled
                                            placeholder="Select adjustment type"
                                            key="adjustmentkeyDisabled"
                                        />
                                    </FieldErrors>
                                </EditRow>
                            )}

                            {form.values.useGlobalAdjustment && !!form.values?.adjustmentType && (
                                <EditRow
                                    labelCol={12}
                                    col={6}
                                    title="Adjustment amount"
                                    subTitle={
                                        form.values.adjustmentType === AdjustmentType.Percentage
                                            ? "Percentage to apply. Use negative values for discounts and positive values for increases"
                                            : "Fixed amount to apply. Use negative values for discounts and positive values for increases"
                                    }
                                >
                                    <FieldErrors name="globalAdjustment" form={form}>
                                        <Field
                                            name="globalAdjustment"
                                            component={InputNumberAddon}
                                            placeholder="eg. -10"
                                            addonBefore={
                                                form.values.adjustmentType === AdjustmentType.Percentage ? "%" : "$"
                                            }
                                            maxLength={6}
                                            type="number"
                                            allowNegative
                                        />
                                    </FieldErrors>
                                </EditRow>
                            )}
                        </>
                    )}
                    <CrudActionFooter
                        saving={saveStatus === "saving"}
                        submitMode={submittingMode}
                        showDuplicate={canClone}
                        showDelete={canDelete}
                        showSave={canUpdate}
                        position={"fixed"}
                        duplicateProps={{
                            onClick: () => {
                                if (form.isValid) {
                                    handleDuplicate(form.values);
                                } else {
                                    // force ScrollToFormikError to run (wont submit because form is invalid)
                                    form.submitForm();
                                }
                            },
                        }}
                        confirmDelete={{
                            title: "Are you sure you want to delete this Price List?",
                            deleteLabel: "Delete Price List",
                            children:
                                "This Price List may be in use by other features in your venue. Deleting this Price List may affect those features.",
                        }}
                        deleteProps={{
                            onClick: handleDelete,
                        }}
                        saveProps={{
                            disabled: !form.dirty && isUpdate,
                        }}
                    />
                </Form>
            )}
        </Formik>
    );
};
