import { opsApi } from "API";
import { GraphQLItemId, GraphQLItem } from "common/scaffolding/types";
import { SectionDemoQrInput } from "../types";

export async function editSection(locationId: string, sectionId: string): Promise<GraphQLSection> {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId, sectionId });
    return res.data.section;
}

type GraphQLPayload = { section: GraphQLSection };

export interface GraphQLSection extends GraphQLItem {
    services: Array<GraphQLItemId>;
    posId: string | null;
    posMenuId: string | null;
    sectionType: "indoor" | "outdoor" | null;
    enableTips?: boolean;
    applySurcharges?: boolean;
    applyTrayCharge?: boolean;
    demoTableNumber?: string;
    demoQrToken: string;
    demoQr?: SectionDemoQrInput;
}

const query = `
    query($locationId: ID!, $sectionId: ID!) { 
        section(locationId: $locationId, id: $sectionId) {
            id,
            displayName,
            services {
                id
            },
            posId,
            posMenuId,
            sectionType,
            enableTips,
            applySurcharges,
            applyTrayCharge,
            demoTableNumber,
            demoQrToken,
            demoQr {
                isDemo,
                dateUpdated,
                timeToLiveSeconds
            }
        } 
    }
`;
