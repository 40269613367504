import { createAction } from "../reducers/edit";
import { AppState, AppDispatch } from "features/state";
import { updatePosConfiguration } from "../api/updatePosConfiguration";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { PosConfigurationSetting } from "../types/PosConfigurationSetting";
import { fetchActive } from "features/location/actions";
import { getPosConfigurationFields } from "../selectors/getPosConfigurationFields";
import { message } from "antd";
import { NotificationMessage } from "common/notificationMessage";
import { timestamp } from "common/data/timetamped";
import { PosSecretValueSymbol } from "../types/PosSecretValueSymbol";

export const save = (data: {}) => async (dispatch: AppDispatch, getState: () => AppState) => {
    const state = getState();
    const location = getActiveLocation(state)!;

    const settings: PosConfigurationSetting[] = getPosConfigurationFields(state)
        .map((field) => ({ key: field.name, value: data[field.name] }))
        .filter((setting) => setting.value !== undefined && setting.value !== PosSecretValueSymbol);

    try {
        dispatch(createAction.saving(location.id));

        const posConfiguration = await updatePosConfiguration(location.id, settings);

        await dispatch(fetchActive(location.id, true));

        message.success(NotificationMessage.SUCCESS);

        dispatch(createAction.saved(location.id));

        // Save doesn't actually update state, but we need it to
        dispatch(createAction.loaded(location.id, timestamp(posConfiguration)));
    } catch (e) {
        dispatch(createAction.saveFailed(location.id, e));

        throw e;
    }
};
