import { opsApi } from "API";
import { ImportResult } from "../types/ImportResult";

const bulkUploadProductsQuery = `
  mutation ($locationId: ID!, $filename: String!, $preview: Boolean!) {
    bulkUploadProducts(locationId: $locationId, fileName: $filename, preview: $preview) {
        summary {
            parentRecordsUpdated,
            childRecordsUpdated
        },
        errors {
            lineNumber,
            errors,
            errorTitle,
            errorLink
        }
    }
  }
`;

export async function uploadProducts(fileName: string, locationId: string, preview: boolean) {
    const variables = {
        locationId,
        filename: fileName,
        preview,
    };

    const result = await opsApi.graphQLQuery<{ bulkUploadProducts: ImportResult }>(bulkUploadProductsQuery, variables);

    return result.data.bulkUploadProducts;
}
