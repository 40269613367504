import { EditableCmsItemBase, GraphQLItem } from "../common/scaffolding/types";

interface Props<T> {
    item: T;
    className?: string;
}

export const ResolvedDisplayName = <T extends GraphQLItem | EditableCmsItemBase>({ item, className }: Props<T>) => (
    <div className={className}>
        {item.displayName} {item.internalName && <i>{` (${item.internalName})`}</i>}
    </div>
);
