import { scaffoldArchiveAction } from "common/scaffolding/actions/scaffoldArchiveAction";
import { fetchActive } from "features/location/actions";
import { archiveSection } from "features/section/api/archiveSection";
import { createAction } from "features/section/reducers/edit";
import { NotificationMessage } from "common/notificationMessage";
import { getArchiveSectionTrackingEventData } from "../selectors";
import { createAction as calendarActions } from "../../service/reducers/calendar";
import { AppDispatch } from "../../state";

export const archive = (location: string) =>
    scaffoldArchiveAction(
        (state) => state.sections.edit,
        createAction,
        async (id, dispatch) => {
            const result = await archiveSection(location, id);
            await dispatch(fetchActive(location, true));
            return result;
        },
        (dispatch: AppDispatch) => {
            dispatch(calendarActions.reset());
            return true;
        },
        NotificationMessage.ARCHIVE_SUCCESS,
        getArchiveSectionTrackingEventData
    );
