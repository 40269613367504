import { ActiveLocation } from "features/location/types/ActiveLocation";
import { AppState } from "features";
import { createSelector } from "reselect";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { getActiveStatuses, getTextFilter } from "./getFilters";
import { getLocationsList } from "features/location/selectors/getLocationsList";
import { getLocationUpdateStatus } from "./getLocationUpdateStatus";
import { LocationItemFilterCallback, UpdateStatusFilterValue } from "../types";
import { LocationSummary } from "features/location/types/LocationSummary";
import { normaliseText } from "common/utility/StringUtils";
import { sortByDisplayName } from "foundation/dataConventions/sortByDisplayName";

export const getFilteredLocations = createSelector(
    getActiveLocation,
    getLocationsList,
    getActiveStatuses,
    getTextFilter,
    (_: AppState, selectedIds: string[]) => selectedIds, // component prop
    (activeLocation, locations, activeStatuses, textFilter, selectedIds): LocationSummary[] => {
        const selectedLocations = locations
            .filter((location) => selectedIds.includes(location.id))
            .sort(sortByDisplayName);

        const locationFilters: LocationItemFilterCallback[] = [
            isIncludedByStatusFilter(activeStatuses, activeLocation),
            isIncludedByTextFilter(textFilter),
        ];

        return selectedLocations.filter((location) => locationFilters.every((filter) => filter(location)));
    }
);

function isIncludedByStatusFilter(
    activeStatuses: UpdateStatusFilterValue[],
    activeLocation: ActiveLocation | undefined
): LocationItemFilterCallback {
    if (activeStatuses.length === 0) {
        return () => true;
    }

    return (location) => {
        return includesStatus(location, activeStatuses, activeLocation);
    };
}

function includesStatus(
    location: LocationSummary,
    activeStatuses: UpdateStatusFilterValue[],
    activeLocation: ActiveLocation | undefined
) {
    const locationStatus = getLocationUpdateStatus(location, activeLocation);

    return activeStatuses.includes(locationStatus);
}

function isIncludedByTextFilter(searchTerm: string): LocationItemFilterCallback {
    if (!searchTerm) {
        return () => true;
    }

    return (item) => {
        return itemHasSearchTerm(item, searchTerm) || false;
    };
}

function itemHasSearchTerm(item: LocationSummary, searchTerm: string) {
    const search = normaliseText(searchTerm);

    const displayName = normaliseText(item.displayName || "");

    return displayName.includes(search);
}
