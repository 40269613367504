import { Select } from "antd";
import { ServiceSummary } from "features/service";

interface Props {
    services: ServiceSummary[];
    setSelectedService: (value?: string) => void;
}

export const ServiceSelector = ({ services, setSelectedService }: Props) => (
    <>
        <div className="quick-sell-page__services__title">Services</div>
        <Select
            className="quick-sell-page__services__select"
            dropdownMatchSelectWidth={false}
            defaultValue={"All services"}
            key={"select-service"}
            onChange={(value?: string) => setSelectedService(value)}
        >
            <Select.Option key={"All services"} value={""}>
                All services
            </Select.Option>
            {services.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                    {item.displayName}
                </Select.Option>
            ))}
        </Select>
    </>
);
