import { RouteComponentProps, Route, StaticContext } from "react-router";
import { Col } from "antd";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";

export interface Props {
    name: string;
    section?: string | null;
    listComponent: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    editComponent: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    listWidth?: number;
    editWidth?: number;
    showBackLink?: boolean;
}

export const CrudRoute = ({
    name,
    section,
    listComponent,
    editComponent: EditComponent,
    listWidth = 12,
    editWidth = 12,
    showBackLink = true,
}: Props) => {
    const route = section !== null ? `${section || "menu"}/${name}` : name;

    const calculateColWidth = (props: RouteComponentProps<any, StaticContext, any>) =>
        props.location.pathname === `/${props.match.params.region}/${props.match.params.location}/${route}`
            ? listWidth
            : 24 - editWidth;

    return (
        <>
            <Route
                path={`/:region/:location/${route}`}
                render={(props) => (
                    <>
                        <Col span={24} xl={calculateColWidth(props)} className={`content__col content__col--${name}`}>
                            <div className="content__col-list">
                                <Route component={listComponent} />
                            </div>
                        </Col>

                        <Route
                            exact
                            path={`/:region/:location/${route}/:id`}
                            render={() => (
                                <Col span={24} xl={editWidth} className={`content__col content__col--${name}`}>
                                    <div className="content__col-edit">
                                        <Route
                                            exact
                                            path={`/:region/:location/${route}/:id`}
                                            render={(props) => (
                                                <>
                                                    {showBackLink && (
                                                        <div className="link-back__container">
                                                            <Link
                                                                className="link-back__link"
                                                                to={`/${props.match.params.region}/${props.match.params.location}/${route}`}
                                                            >
                                                                <CloseOutlined />
                                                            </Link>
                                                        </div>
                                                    )}
                                                    <EditComponent key={props.match.params.id} {...props} />
                                                </>
                                            )}
                                        />
                                    </div>
                                </Col>
                            )}
                        />
                    </>
                )}
            />
        </>
    );
};
