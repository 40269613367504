import { AlertStatus } from "core/components/alert";
import { ReactNode, useMemo } from "react";
import { useFormikContext } from "formik";
import { EditableProduct } from "features/catalogue/types";
import { Link } from "react-router-dom";
import { AlertsList } from "core/components/alert/AlertsList";
import { useSelector } from "react-redux";
import { getIsParentLocation } from "features/location/selectors/getIsParentLocation";
import { getIsChildLocation } from "features/location/selectors/getIsChildLocation";

interface Props {
    priceListsLink: string;
    posSyncEnabled: boolean;
}

export const ProductAlerts = ({ priceListsLink, posSyncEnabled }: Props) => {
    const {
        initialValues: { priceLists, isLinked },
    } = useFormikContext<EditableProduct>();

    const isChildLocation = useSelector(getIsChildLocation);

    const isParentLocation = useSelector(getIsParentLocation);

    const alerts = useMemo(() => {
        const alerts: ReactNode[] = [];

        if (posSyncEnabled) {
            alerts.push(<>This product is controlled by POS, some fields are read only.</>);
        }

        if (priceLists.length && !isParentLocation) {
            alerts.push(
                <>
                    This product has price levels which are set in{" "}
                    <Link to={priceListsLink} className={"link-reset"}>
                        <strong>Price Lists</strong>
                    </Link>
                </>
            );
        }

        if (isParentLocation) {
            alerts.push(<>Categories should be applied at venue level</>);
        }

        if (isChildLocation && isLinked) {
            alerts.push(
                <>
                    This product is brand controlled. To make other venue specific modifications you can duplicate the
                    product.
                </>
            );
        }

        return alerts;
    }, [posSyncEnabled, priceLists.length, isParentLocation, isChildLocation, isLinked, priceListsLink]);

    return <AlertsList alerts={alerts} status={AlertStatus.INFO} />;
};
