import { createAction } from "../reducers/bulkEdit";
import { scaffoldSaveAction } from "../../../common/scaffolding/actions/scaffoldSaveAction";
import { PriceListItem } from "../types";
import { PriceLevelInputModel, PriceLevelsInputModel, savePriceLevels } from "../api/savePriceLevels";
import { edit } from "./edit";
import { TabKeys } from "../components/EditPriceListPage";
import { message } from "antd";

export const bulkSave = (
    locationId: string,
    priceListId: string,
    priceLevels: PriceListItem[],
    deleteItems: PriceListItem[]
) =>
    scaffoldSaveAction(
        (state) => state.priceLists.edit,
        createAction,
        async (priceListId: string | undefined) => {
            const priceLevelsUpdate = mapPriceLevelsInputModel(priceLevels, deleteItems);
            return await savePriceLevels(locationId, priceListId!, priceLevelsUpdate);
        },
        undefined,
        false,
        (data: string) => {
            message.success(`${data} ${parseInt(data) === 1 ? "item" : "items"} changed`);
            return edit(locationId, priceListId, TabKeys.Items);
        },
        true
    );

export const mapPriceLevelsInputModel = (
    priceLevels: PriceListItem[],
    deleteItems: PriceListItem[]
): PriceLevelsInputModel => {
    return {
        productDeletions: mapProductsInputModel(deleteItems),
        modifierDeletions: mapModifiersInputModel(deleteItems),
        productPriceLevels: mapProductsInputModel(priceLevels),
        modifierPriceLevels: mapModifiersInputModel(priceLevels),
    };
};

export const mapProductsInputModel = (priceLevels: PriceListItem[]): PriceLevelInputModel[] => {
    return priceLevels
        .filter((level) => level.itemType === "Product" || level.itemType === "Variant")
        .map((pl) => {
            return {
                id: pl.parentId ? pl.parentId : pl.id,
                price: pl.priceLevelPrice,
                childId: pl.parentId ? pl.id : undefined,
            };
        });
};

export const mapModifiersInputModel = (priceLevels: PriceListItem[]): PriceLevelInputModel[] => {
    return priceLevels
        .filter((level) => level.itemType === "Modifier" || level.itemType === "Option")
        .map((pl) => {
            return {
                id: pl.parentId ? pl.parentId : pl.id,
                price: pl.priceLevelPrice,
                childId: pl.parentId ? pl.id : undefined,
            };
        });
};
