import styles from "./LabelledTimepicker.module.scss";

import { FieldProps } from "formik";
import { ReactElement } from "react";
import { Label, Props as LabelProps } from "../input/Label";
import { TimePicker } from "./TimePicker";

export const LabelledTimePicker = ({ label, markRequired, name, ...rest }: LabelProps & FieldProps): ReactElement => {
    return (
        <div className={styles.root}>
            <Label label={label} markRequired={markRequired} name={name}></Label>
            <TimePicker name={name} {...rest} />
        </div>
    );
};
