import { AppDispatch, AppState } from "features";
import { BulkUploadType, createBulkUploadEndpoint } from "../api/bulkUpload";
import { createAction } from "../reducers/upload";
import { UploadResult } from "../types/UploadResult";

export function uploadImportFile(location: string, type: BulkUploadType, file?: File) {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();

        if (state.catalogue.bulkImport.status === "loading") {
            return;
        }

        dispatch(createAction.loading());
        try {
            if (!file) {
                dispatch(createAction.failed("No file provided"));
                return;
            }

            const endpoint = await createBulkUploadEndpoint(file, location);

            const { uploadUrl, uploadMethod, path } = endpoint;

            const uploadResult = await fetch(uploadUrl, {
                method: uploadMethod,
                body: file,
                headers: {
                    // https://feedback.azure.com/forums/217298-storage/suggestions/11387868-add-x-ms-request-headers-in-shared-access-signatu
                    "x-ms-blob-type": "BlockBlob",
                },
            });
            const mappedResult = mapUploadImport(path, file, uploadResult);
            dispatch(createAction.loaded(mappedResult));
        } catch (e) {
            dispatch(createAction.failed(e));
        }
    };
}

const mapUploadImport = (path: string, file: File, uploadResult?: Response): UploadResult => {
    return {
        originalFile: file,
        uploadFilePath: path,
        uploadSuccess: uploadResult ? uploadResult.ok : false,
        error: uploadResult && !uploadResult.ok ? new Error(uploadResult.url) : undefined,
    };
};
