import { FieldProps } from "formik";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Switch } from "antd";

import { useState } from "react";
export interface SwitchProps {
    label?: string;
    style?: React.CSSProperties;
    disabled?: boolean;
    id?: string;
    parseValue?: (value: any) => boolean;
    formatValue?: (input: boolean) => any;
    validateOnChange?: boolean;
}

export const SwitchInput = ({
    id,
    label,
    disabled,
    style,
    form,
    field: { name, value },
    parseValue,
    formatValue,
    validateOnChange,
}: FieldProps & SwitchProps) => {
    const [isChecked, setIsChecked] = useState<boolean>(parseValue ? parseValue(value) : value);

    const handleChange = (checked: boolean, event: React.MouseEvent) => {
        form.setFieldTouched(name, true);
        form.setFieldValue(name, formatValue ? formatValue(checked) : checked, validateOnChange);
        setIsChecked(checked);
    };

    return (
        <Switch
            checked={isChecked}
            onChange={handleChange}
            style={style}
            disabled={disabled}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
        />
    );
};
