interface Props {
    name: string;
    value: string;
}

export const StatsItem = ({ name, value }: Props) => (
    <div className="group-tabs-stats__box">
        <div className="group-tabs-stats__title">{name}</div>
        <div className="group-tabs-stats__value">{value}</div>
    </div>
);
