import { createSelector } from "reselect";
import { AppState } from "features/state";
import { StatusDictionary } from "../types/StatusDictionary";
import { ModifierSummary } from "features/modifier";
import { CatalogueModifierItem, CatalogueModifierOption } from "../types";
import { getIsChildLocation } from "features/location/selectors/getIsChildLocation";

export const getModifiersList = (state: AppState) => state.modifiers.list;

export const getModifierCatalogueItems = createSelector(
    getModifiersList,
    getIsChildLocation,
    (modifiers, isChildLocation) => {
        if (modifiers.status !== "loaded") {
            return [];
        }

        return modifiers.data.map((modifier): CatalogueModifierItem => {
            return {
                children: mapModifierChildren(modifier, !!isChildLocation),
                id: modifier.id,
                type: "modifier",
                displayName: modifier.displayName,
                internalName: modifier.internalName,
                sku: modifier.sku,
                status: modifier.status,
                statusName: StatusDictionary[modifier.status] || StatusDictionary[0],
                validationStatus: modifier.validationStatus,
                key: modifier.id,
                upsell: modifier.upsell,
                isLinked: modifier.isLinked && !!isChildLocation,
            };
        });
    }
);

function mapModifierChildren(modifier: ModifierSummary, isChildLocation: boolean): CatalogueModifierOption[] {
    return modifier.options
        ? modifier.options.map<CatalogueModifierOption>((o) => {
              return {
                  id: o.id,
                  type: "option",
                  displayName: o.displayName,
                  price: o.price,
                  sku: o.sku,
                  parentId: modifier.id,
                  validationStatus: o.validationStatus,
                  validationStatusReason: o.validationStatusReason,
                  key: `${modifier.id}-${o.id}`,
                  modifiers: o.modifiers,
                  energyContent: o.energyContent,
                  isLinked: o.isLinked && isChildLocation,
              };
          })
        : [];
}
