import { PageTitle } from "./PageTitle";
import { NewDataButton } from "./NewDataButton";
import "./DataListingPage.scss";
import { Col, Collapse, Row } from "antd";
import { DataItem } from "./DataListing/DataListing";
import { DataListing } from "./DataListing";
import { DataListingFilter, Option } from "common/scaffolding/components/DataListingPage/DataListing/DataListingFilter";

const { Panel } = Collapse;

export interface ItemsGroup<T extends DataItem> {
    title: React.ReactNode;
    className?: string;
    items: T[];
    children?: React.ReactNode;
    renderHeader?: (item: T, index?: number) => React.ReactNode;
    renderFooter?: (item: T, index?: number) => React.ReactNode;
}

export interface Props<T extends DataItem> {
    title: string;
    description: string | React.ReactNode;
    newItemLabel: string;
    newItemRoute: string;
    editItemRoute: string;
    items?: T[];
    children?: React.ReactNode;
    renderItemInfo?: (item: T, index?: number) => React.ReactNode;
    disableAdd?: boolean;
    itemsGroups?: ItemsGroup<T>[];
    showFilter?: boolean;
    filterOptions?: Option[];
    selectedFilter?: string;
    onFilter?: (id: string) => void;
    emptyText?: string;
    displayImages?: boolean;
}

export const DataListingPage = <T extends DataItem>({
    title,
    description,
    newItemLabel,
    newItemRoute,
    editItemRoute,
    items,
    children,
    renderItemInfo,
    disableAdd,
    itemsGroups,
    showFilter,
    filterOptions,
    selectedFilter,
    onFilter,
    emptyText,
    displayImages,
}: Props<T>) => (
    <div className="data-listing-page">
        {(title || description) && <PageTitle title={title} description={description} />}
        <Row justify="space-between">
            {!disableAdd && (
                <Col>
                    <NewDataButton text={newItemLabel} newItemRoute={newItemRoute} />
                </Col>
            )}
            {showFilter && (
                <Col className="listing-col-right">
                    <div>Show</div>{" "}
                    <DataListingFilter
                        name="sectionFilter"
                        options={filterOptions}
                        onChange={onFilter}
                        selectedItem={selectedFilter}
                        disabled={false}
                        displaySelectAll
                        selectAllText={"All sections"}
                    />{" "}
                </Col>
            )}
        </Row>
        {children}
        {itemsGroups && (
            <Collapse expandIconPosition="end" defaultActiveKey={["0"]}>
                {itemsGroups.map((itemsGroup, index) => (
                    <Panel
                        key={index.toString()}
                        header={
                            <span className="data-listing-page__section-title">
                                {itemsGroup.title}
                                <span className="data-listing-page__item-count">{itemsGroup.items.length}</span>
                            </span>
                        }
                    >
                        {itemsGroup.children}
                        <DataListing
                            className={itemsGroup.className}
                            items={itemsGroup.items}
                            renderItemInfo={renderItemInfo as any}
                            renderHeader={itemsGroup.renderHeader as any}
                            renderFooter={itemsGroup.renderFooter as any}
                            editItemRoute={editItemRoute}
                            emptyText={emptyText}
                            displayImages={displayImages}
                        />
                    </Panel>
                ))}
            </Collapse>
        )}
        {items && (
            <DataListing
                items={items}
                renderItemInfo={renderItemInfo as any}
                editItemRoute={editItemRoute}
                emptyText={emptyText}
                displayImages={displayImages}
            />
        )}
    </div>
);
