import { opsApi } from "API";
import { GraphQLItem, GraphQLItemId } from "../../../common/scaffolding/types";

export const listMenuItemTemplates = async () => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query);
    return res.data.menuItemTemplates;
};

type GraphQLPayload = { menuItemTemplates: GraphQLMenuItemTemplate[] };

export interface GraphQLMenuItemTemplate extends GraphQLItem {
    type: "food" | "drink";
    descriptors: Array<{ key: string; displayName: string }>;
    tagGroups: Array<GraphQLItemId>;
    recommended: boolean;
    rating: boolean;
}

const query = `
    query {
        menuItemTemplates {
            id,
            displayName,
            type,
            tagGroups {
                id   
            },
            descriptors {
                key,
                displayName
            }
        }
    }
`;
