import { opsApi } from "API";
import { mapStripeAccount } from ".";
import { StripeAccount } from "../types/StripeAccount";
import { GraphQLPayload } from "./types";

export const getStripeAccountLoginLink = async (locationId: string, refresh: boolean): Promise<StripeAccount> => {
    try {
        const res = await opsApi.graphQLQuery<GraphQLPayload>(fetchQuery, { locationId, shouldRefresh: refresh });
        return mapStripeAccount(res.data.getStripeAccountLoginLink || null);
    } catch (e) {
        console.log(e);
        throw e;
    }
};

const fetchQuery = `
    query($locationId: ID!, $shouldRefresh: Boolean!) {
        getStripeAccountLoginLink(locationId: $locationId, refresh: $shouldRefresh) {
            accountId
            chargesEnabled
            payoutsEnabled
            detailsSubmitted
            accountCreationLink {
                url
                expiresAt
            }
            paymentStatementDescriptor
            payoutStatementDescriptor
        }
    }
`;
