import { opsApi } from "API";
import { menuDataUrl } from "common/menuData/menuDataUrl";
import { UploadFileType } from "components/forms";

export type LocationImageType =
    | "category"
    | "food"
    | "drink"
    | "hero"
    | "logo"
    | "setmenu"
    | "staff"
    | "background"
    | "menuBackground";

export interface UploadEndpoint {
    uploadUrl: string;
    uploadMethod: string;
    path: string;
}

export async function upload(
    file: File,
    location: string,
    type: LocationImageType,
    returnFullUrl?: boolean,
    uploadFileType?: UploadFileType
) {
    const endpoint = await createUploadEndpoint(
        file,
        location,
        type,
        uploadFileType === UploadFileType.Video ? "createLocationVideoEndpoint" : "createLocationImageEndpoint"
    );

    const { uploadUrl, uploadMethod, path } = endpoint;

    const uploadResult = await fetch(uploadUrl, {
        method: uploadMethod,
        body: file,
        headers: {
            // https://feedback.azure.com/forums/217298-storage/suggestions/11387868-add-x-ms-request-headers-in-shared-access-signatu
            "x-ms-blob-type": "BlockBlob",
        },
    });

    if (!uploadResult.ok) {
        throw new Error(uploadResult.url);
    }

    return returnFullUrl ? menuDataUrl(path) : path;
}

const createLocationUploadEndpointQuery = (mutationName: string) => `
  mutation ($locationId: ID!, $type: String!, $filename: String!) {
    ${mutationName}(locationId: $locationId, type: $type, filename: $filename) {
        uploadUrl,
        uploadMethod,
        path
    }
  }
`;

async function createUploadEndpoint(file: File, locationId: string, type: LocationImageType, mutationName: string) {
    const variables = {
        locationId,
        type,
        filename: file.name,
    };

    const result = await opsApi.graphQLQuery<{ createLocationUploadEndpoint: UploadEndpoint }>(
        createLocationUploadEndpointQuery(mutationName),
        variables
    );

    return result.data[mutationName];
}
