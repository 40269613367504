import { createSelector } from "reselect";
import { getActiveLocation, getLocationPermissions } from "features/location/selectors/getLocationPermissions";
import { getNavStructure } from "../api/navStructure";
import {
    NavItem,
    NavItemGroup,
    NavItemDisplayFilter,
    NavNode,
    isNavItem,
    NavGroup,
    isNavGroup,
} from "../types/NavMenu";
import { generatePath } from "react-router";
import { region } from "features/region";
import {
    shouldHideCataloguePages,
    shouldHideCategoriesPage,
    shouldHidePriceListPage,
    shouldHideServicesPage,
    shouldHideTaxPage,
} from "features/location/selectors/getPosMenuSupported";
import { getLocationCurrency } from "features/location/selectors/getLocationLocale";
import { AppState } from "features/state";
import { PermissionSet } from "features/location/types/PermissionSet";
import { ActiveLocation } from "features/location";
import { mapAnalyticsLayoutConfig } from "./mapAnalyticsLayoutConfig";
import { getAnalyticsLayoutConfig } from "features/location/selectors/getAnalyticsLayoutConfig";
import { config } from "../../../common/config";
import { getIsParentLocation } from "features/location/selectors/getIsParentLocation";

const getState = (state: AppState) => state;

export const getPagesToHide = createSelector(
    shouldHideCataloguePages,
    shouldHideCategoriesPage,
    shouldHideServicesPage,
    shouldHidePriceListPage,
    shouldHideTaxPage,
    (hideCatalogue, hideCategories, hideServices, hidePricelist, hideTaxes) => {
        const pagesToHide: string[] = [];

        if (hideCatalogue) {
            pagesToHide.push("menu/catalogue");
        }

        if (hideCategories) {
            pagesToHide.push("menu/categories");
        }

        if (hideServices) {
            pagesToHide.push("menu/services");
        }

        if (hidePricelist) {
            pagesToHide.push("menu/pricelists");
        }

        if (hideTaxes) {
            pagesToHide.push("menu/taxes");
        }

        return pagesToHide;
    }
);

type ItemFilter = (item: NavItem) => boolean;

export const getMainMenu = createSelector(
    getState,
    getActiveLocation,
    getLocationPermissions,
    getNavStructure,
    getLocationCurrency,
    getPagesToHide,
    getIsParentLocation,
    (state, location, permissions, navStructure: NavNode[], currency, hiddenPages, isParentLocation) => {
        const itemFilters: ItemFilter[] = [
            isPermitted(permissions),
            isDisplayable(state),
            isFeatureEnabled(location),
            isConfigEnabled(),
            isCurrencyEnabled(currency),
            isNotHiddenPage(hiddenPages),
        ];

        isParentLocation && itemFilters.push(isParentLocationEnabled());
        !isParentLocation && itemFilters.push(isNonParentLocationEnabled());

        const routeParams = {
            location: location && location.slug,
            locationId: location && location.id,
            region: region.getActiveRegion().id,
        };

        if (config.REACT_APP_ENABLE_ANALYTICS_LAYOUT_CONFIG === "1") {
            const analyticsLayoutConfig = getAnalyticsLayoutConfig(state);

            if (analyticsLayoutConfig) {
                const mappedAnalyticsNavGroup = mapAnalyticsLayoutConfig(analyticsLayoutConfig);
                navStructure = [...navStructure, mappedAnalyticsNavGroup];
            }
        }

        return navStructure
            .map((node) => {
                if (isNavGroup(node)) {
                    const mapped = mapNavGroup(node, itemFilters, routeParams);
                    return mapped.navItemGroups.length > 0 ? mapped : null;
                } else if (isNavItem(node)) {
                    return mapNavItem(node, itemFilters, routeParams);
                } else {
                    return null;
                }
            })
            .filter(Boolean);
    }
);

function mapNavGroup(group: NavGroup, itemFilters: ItemFilter[], routeParams: any) {
    return {
        ...group,
        navItemGroups: group.navItemGroups
            .map((item) => {
                const mappedNavItemGroup = mapNavItemGroup(item, itemFilters, routeParams);
                return mappedNavItemGroup.children.length > 0 ? mappedNavItemGroup : null;
            })
            .filter(Boolean),
    };
}

function mapNavItemGroup(group: NavItemGroup, itemFilters: ItemFilter[], routeParams: any) {
    return {
        ...group,
        children: group.children.map((item) => mapNavItem(item, itemFilters, routeParams)).filter(Boolean),
    };
}

function mapNavItem(item: NavItem, itemFilters: ItemFilter[], routeParams: any): NavItem | null {
    return isValidItem(item, itemFilters) ? populateRouteParams(item, routeParams) : null;
}

function populateRouteParams(item: NavItem, params: any): NavItem {
    return {
        ...item,
        route: item.route ? generatePath(item.route, params) : undefined,
        externalUrl: item.externalUrl ? replaceVariables(item.externalUrl, params) : undefined,
    };
}

// generatePath doesn't like the port :3003 (local dev) so we just do it ourselves
function replaceVariables(input: string, args: { [key: string]: string }) {
    return input.replace(/:([a-zA-Z]+)/g, (_, variable) => args[variable]);
}

function matchesFilter(filter: NavItemDisplayFilter | NavItemDisplayFilter[], state: AppState) {
    if (Array.isArray(filter)) {
        return filter.every((f) => f(state));
    }

    return filter(state);
}

function isValidItem(item: NavItem, filters: ItemFilter[]) {
    return filters.every((filter) => filter(item));
}

function isPermitted(permissions: PermissionSet): ItemFilter {
    return (item) => permissions.has(item.permission);
}

function isDisplayable(state: AppState): ItemFilter {
    return (item) => !item.displayFilter || matchesFilter(item.displayFilter, state);
}

function isFeatureEnabled(location: ActiveLocation | undefined): ItemFilter {
    return (item) => !!(!item.featureToggle || (location && location[item.featureToggle]));
}

function isConfigEnabled(): ItemFilter {
    return (item) => item.configEnabled !== false;
}

function isCurrencyEnabled(currency: string): ItemFilter {
    return (item) => !item.includeForCurrencies?.length || item.includeForCurrencies.includes(currency);
}

function isParentLocationEnabled(): ItemFilter {
    return (item) => !!(item.includeForParentLocation || item.includeForParentLocationOnly);
}

function isNonParentLocationEnabled(): ItemFilter {
    return (item) => !item.includeForParentLocationOnly;
}

function isNotHiddenPage(pagesToHide: string[]): ItemFilter {
    return (item: NavItem) => {
        if (!item.route) {
            return true;
        }

        return !pagesToHide.includes(item.route);
    };
}
