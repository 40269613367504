import { getLocationLastUpdated } from "../selectors/getLocationLastUpdated";
import { LocationSummary } from "features/location";
import { useState } from "react";
import { useInterval } from "common/hooks";

interface Props {
    item: LocationSummary;
}

export const ListItemLastUpdated = ({ item }: Props) => {
    const [tick, setTick] = useState(false);

    let lastUpdated = getLocationLastUpdated(item);

    const interval = /second|minute/i.test(lastUpdated) ? 10000 : 60000;

    // trigger re-render
    useInterval(() => setTick(!tick), interval);

    // dont show seconds
    if (/second/i.test(lastUpdated)) {
        lastUpdated = "Just now";
    }

    return <>{lastUpdated}</>;
};
