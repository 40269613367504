import { AppState } from "features";
import { CatalogueContext } from "features/catalogue/context/CatalogueContext";
import { CatalogueTab } from "../CatalogueTab";
import { getActiveFilters, getTextFilter } from "features/catalogue/selectors/getFilters";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { getFilteredModifierGroups } from "features/catalogue/selectors/getFilteredCatalogueItems";
import { getModifierCatalogueItems } from "features/catalogue/selectors/getModifierCatalogueItems";
import { Headers } from "./Headers";
import { ListItem } from "./ListItem";
import { useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLoadStatus } from "common/hooks";
import { usePermissions } from "features/location/hooks";
import { Warnings } from "./Warnings";
import * as modifierActions from "features/modifier/actions";

export const Tab = () => {
    const activeLocation = useSelector(getActiveLocation);
    const dispatch = useDispatch();
    const allItems = useSelector(getModifierCatalogueItems);
    const filteredItems = useSelector(getFilteredModifierGroups);
    const modifiers = useSelector((state: AppState) => state.modifiers.list);
    const isLoaded = modifiers.status === "loaded";
    const activeFilters = useSelector(getActiveFilters);
    const textFilter = useSelector(getTextFilter);

    const { formatCurrency, modifiersPageIndex, modifiersPageSize, setModifiersPageIndex, setStoredModifiersPageSize } =
        useContext(CatalogueContext);

    const fetch = useCallback(() => {
        if (!activeLocation) {
            return;
        }
        dispatch(modifierActions.list(activeLocation.id));
    }, [activeLocation, dispatch]);

    const fetchStatus = useLoadStatus([modifiers.status], fetch);
    const bulkDeleteStatus = useSelector((state: AppState) => state.catalogue.bulkDelete.status);

    const {
        product: { canBulkDelete },
    } = usePermissions();

    return (
        <CatalogueTab
            activeFilters={activeFilters}
            bulkDeleteStatus={bulkDeleteStatus}
            canBulkDelete={canBulkDelete}
            canBulkSelect={true}
            collectionName="modifiers"
            fetchStatus={fetchStatus}
            formatCurrency={formatCurrency}
            headers={Headers}
            isLoaded={isLoaded}
            items={filteredItems}
            listItem={ListItem}
            noResults={allItems.length === 0}
            pageIndex={modifiersPageIndex}
            pageSize={modifiersPageSize}
            retry={fetch}
            setPageIndex={setModifiersPageIndex}
            setPageSize={setStoredModifiersPageSize}
            textFilter={textFilter}
            warnings={Warnings}
        />
    );
};
