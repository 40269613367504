import { useEffect, useState } from "react";
import { BulkUploadType } from "../../api/bulkUpload";
import { UploadResult } from "../../types/UploadResult";
import { ImportResult } from "../../types/ImportResult";
import { Upload } from "./Upload";
import { UploadFailed } from "./UploadFailed";
import { UploadValid } from "./UploadValid";
import { UploadSuccessful } from "./UploadSuccessful";
import { CSSTransition } from "react-transition-group";
import "./Uploader.scss";
import { CloseOutlined } from "@ant-design/icons";
import classNames from "classnames";

export interface Props {
    type: BulkUploadType;
    uploadResult?: UploadResult;
    importResult?: ImportResult;
    onUpload: (file: File, type: BulkUploadType) => void;
    onImport: (fileName: string, preview: boolean) => void;
    parentRecordTitle: string;
    childRecordTitle: string;
    onClose: () => void;
    onLoadNew: () => void;
    importStatus: string;
    uploadStatus: string;
    templateData: string;
}

export const Uploader = ({
    type,
    uploadResult,
    onUpload,
    onImport,
    importResult,
    parentRecordTitle,
    childRecordTitle,
    onClose,
    onLoadNew,
    importStatus,
    templateData,
    uploadStatus,
}: Props) => {
    const [loading, setLoading] = useState(false);
    const [imported, setImported] = useState<ImportResult>();
    const [uploaded, setUploaded] = useState<UploadResult>();
    const [visibleState, setVisibleState] = useState<"loading" | "upload" | "valid" | "error" | "success">("upload");

    useEffect(() => {
        setUploaded(uploadResult);
    }, [uploadResult]);

    useEffect(() => {
        setImported(importResult);
        if (importResult && importResult.errors && !!importResult.errors.length) {
            setVisibleState("error");
        } else if (importResult && importResult.preview && !(importResult.errors && !!importResult.errors.length)) {
            setVisibleState("valid");
        } else if (importResult && !importResult.preview && !(importResult.errors && !!importResult.errors.length)) {
            setVisibleState("success");
        }
    }, [importResult]);

    useEffect(() => {
        setLoading(importStatus === "loading" || uploadStatus === "loading");
    }, [importStatus, uploadStatus]);

    return (
        <div className="bulk-uploader">
            <CloseOutlined
                className={classNames("modal-close", visibleState === "error" && "modal-close-black")}
                onClick={onClose}
            />
            <CSSTransition classNames="fade" timeout={200} unmountOnExit in={visibleState === "upload"}>
                <Upload
                    parentRecordTitle={parentRecordTitle}
                    childRecordTitle={childRecordTitle}
                    onUpload={onUpload}
                    type={type}
                    onImport={onImport}
                    uploadResult={uploaded}
                    templateData={templateData}
                    loading={loading}
                />
            </CSSTransition>
            <CSSTransition classNames="fade" timeout={200} unmountOnExit in={visibleState === "valid"}>
                <UploadValid
                    imported={imported}
                    parentRecordTitle={parentRecordTitle}
                    childRecordTitle={childRecordTitle}
                    onClose={onClose}
                    onImport={onImport}
                    uploadResult={uploaded}
                    loading={loading}
                />
            </CSSTransition>
            <CSSTransition classNames="fade" timeout={200} unmountOnExit in={visibleState === "error"}>
                <UploadFailed
                    imported={imported}
                    parentRecordTitle={parentRecordTitle}
                    childRecordTitle={childRecordTitle}
                    onClose={onClose}
                    loading={loading}
                />
            </CSSTransition>
            <CSSTransition classNames="fade" timeout={200} unmountOnExit in={visibleState === "success"}>
                <UploadSuccessful
                    imported={imported}
                    parentRecordTitle={parentRecordTitle}
                    childRecordTitle={childRecordTitle}
                    onLoadNew={onLoadNew}
                    loading={loading}
                />
            </CSSTransition>
        </div>
    );
};
