import styles from "./CataloguePage.module.scss";

import { Button } from "core/components/button";
import { EmptyMessage } from "core/components/card";
import { getActiveLocationSelector } from "features/location/selectors/getActiveLocation";
import { getSelectedRegion } from "features/region/selectors";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

interface Props {
    collectionName: string;
}

export const CatalogueItemNotFound = ({ collectionName }: Props) => {
    const location = useSelector(getActiveLocationSelector)?.slug;
    const activeRegion = useSelector(getSelectedRegion)?.id;

    const goBackLink = `/${activeRegion}/${location}/menu/catalogue/${collectionName}s`;
    const heading = `This ${collectionName} could not be found`;
    const message = `An update from the brand catalogue may have removed this ${collectionName} from the venue catalogue`;

    return (
        <EmptyMessage message={message} heading={heading} size="standard">
            <div className={styles.emptyCatalogueActions}>
                <Button as={Link} to={goBackLink}>
                    Go back to the catalogue
                </Button>
            </div>
        </EmptyMessage>
    );
};
