import * as Yup from "yup";
import { EditableCategory } from "features/category/types";
import { FileSchemaType } from "common/upload";

export const uploadedImageSchema = Yup.lazy((value) =>
    !value || typeof value === "string"
        ? Yup.string()
              .nullable(true)
              .when("$requireImage", (requireImage: boolean, schema: Yup.StringSchema) =>
                  requireImage ? schema.required("Please select an image") : schema.notRequired()
              )
        : new FileSchemaType()
              .nullable(true)
              .type(/image\/(jpg|jpeg|png)/i, "Please select a valid JPG or PNG")
              .fileSize(1024 * 1024 * 4, "Please select an image smaller than 4MB")
              .required("Please select an image")
);

export const editableCategorySchema = Yup.object<EditableCategory>().shape({
    internalName: Yup.string().nullable(true),
    displayName: Yup.string().required("Please enter a name"),
    type: Yup.mixed().oneOf(["food", "drink"]),
    services: Yup.array().of(Yup.string()),
    menus: Yup.array().of(Yup.string()),
    menuItems: Yup.array().of(Yup.string()),
    image: uploadedImageSchema,
    special: Yup.boolean(),
    showFromPrice: Yup.boolean(),
});
