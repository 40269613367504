import styles from "./Switch.module.scss";

import { CheckMark, CloseSmall } from "common/icons";
import { FieldInputProps } from "formik";
import { Icon } from "core/components/icon/Icon";
import classNames from "classnames";
import warnOnce from "warn-once";

type InputProps = React.HTMLProps<HTMLInputElement>;
export interface Props {
    disabled?: boolean;
    className?: string;
    field: Partial<FieldInputProps<any>>;
}

export const Switch = ({ disabled, field, form, children, className, ...rest }: InputProps & Props) => {
    // form and children are not used, but destructured so they are not passed in ...rest
    const { checked, value } = field || {};

    warnOnce(
        field.checked === undefined || field.checked === null,
        `Switch did not receive a field.checked property for \`${field?.name}\`. Did you forget to add type="checkbox" to Field props?`
    );

    return (
        <label className={classNames(styles.switchContainer, className, disabled && styles.disabled)}>
            <input type="checkbox" disabled={disabled} {...field} value={value ?? ""} {...rest} />
            <div className={styles.pip}>
                <Icon size="tiny" className={styles.switchIcon}>
                    {checked ? <CheckMark /> : <CloseSmall />}
                </Icon>
            </div>
        </label>
    );
};
