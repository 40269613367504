import styles from "./ListItem.module.scss";
import { Checkbox } from "../form/checkbox";
import { ReactElement, isValidElement, PropsWithChildren, ReactNode } from "react";
import { Field, FieldAttributes } from "formik";

interface Props {
    as?: React.ElementType;
    actions?: ReactNode;
    body?: ReactNode;
    details?: ReactNode;
    heading?: ReactNode;
    checkable?: boolean;
    checkboxFieldProps?: FieldAttributes<any>;
    thumb?: ReactElement;
}

// ensure plain text is wrapped in a html element
const Element = ({ children, className }: PropsWithChildren<{ className: string }>) => {
    return isValidElement(children) ? children : <p className={className}>{children}</p>;
};

export const ListItem = ({
    as: Component = "li",
    actions,
    body,
    details,
    heading,
    checkable = false,
    checkboxFieldProps,
    thumb,
    ...rest
}: Props & Record<string, any>): ReactElement => {
    return (
        <Component className={styles.container} {...rest}>
            <div className={styles.innerContainer}>
                {checkable && checkboxFieldProps && (
                    <div className={styles.check}>
                        <Field type="checkbox" component={Checkbox} {...checkboxFieldProps} />
                    </div>
                )}
                {thumb && <div className={styles.thumb}>{thumb}</div>}
                <div className={styles.contentContainer}>
                    {heading && <Element className={styles.heading}>{heading}</Element>}
                    {body && <Element className={styles.body}>{body}</Element>}
                    {details && <Element className={styles.body}>{details}</Element>}
                </div>
                {actions && <div className={styles.actionsContainer}>{actions}</div>}
            </div>
        </Component>
    );
};
