import { createAction } from "../reducers/list";
import { sortByDisplayName } from "foundation/dataConventions/sortByDisplayName";
import { LocationSummary } from "..";
import { scaffoldListAction } from "../../../common/scaffolding/actions/scaffoldListAction";
import { listLocations } from "../api/listLocations";

export const list = (refresh: boolean = false) =>
    scaffoldListAction<LocationSummary>(
        (state) => state.locations.list,
        createAction,
        async () => {
            const locations = await listLocations();
            return locations.sort(sortByDisplayName);
        },
        refresh
    );
