import { AppDispatch, AppState } from "features";
import { TaxClassSummary } from "../types";
import { createAction } from "../reducers/list";
import { listTaxClasses } from "../api/listTaxClasses";

export const list = (location: string) => async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
        dispatch(createAction.loading());
        var taxes = await listTaxClasses(location);
        dispatch(createAction.loaded(mapEditableTaxes(taxes)));
    } catch (e) {
        dispatch(createAction.loadFailed(e));
    }
};

const mapEditableTaxes = (taxes: TaxClassSummary[]): TaxClassSummary[] => {
    return taxes.map((taxSummary) => {
        // FUTURE USE
        return {
            ...taxSummary,
        };
    });
};
