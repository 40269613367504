import { FeatureConfiguration } from "..";
import { reducer } from "features/category/reducers";

import * as actions from "features/category/actions";
import { ListCategoriesPageContainer } from "./containers/ListCategoriesPageContainer";
import { EditCategoryPageContainer } from "./containers/EditCategoryPageContainer";
import { CrudRoute } from "common/scaffolding/components/CrudRoute";
import { withPermissions } from "features/location/components/withPermission";

export * from "features/category/types";

export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.categories = reducer;

    config.addRoutes(config.routes.locationRoutes, [
        <CrudRoute
            name="categories"
            key="categories"
            listComponent={withPermissions(ListCategoriesPageContainer, "menudata:read")}
            editComponent={withPermissions(EditCategoryPageContainer, "menudata:read")}
        />,
    ]);
}
