import styles from "./DatePicker.module.scss";
import reactDayPickerClasses from "./ReactDayPicker.module.scss";
import DayPicker, { DayModifiers, DayPickerProps } from "react-day-picker";
import { DatePickerNavBar } from ".";
import { DayPickerContainer, DayPickerContext } from "./DayPickerContainer";
import { FieldInputProps, FieldMetaProps, FormikProps } from "formik";
import { MouseEvent, useContext } from "react";
import moment from "moment";

export interface DatePickerProps extends DayPickerProps {
    locale: string;
    storageFormat?: string;
    field?: FieldInputProps<any>;
    meta?: FieldMetaProps<any>;
    onChange?: (day: Date, modifiers: DayModifiers, e: MouseEvent<HTMLDivElement>) => boolean | void;
    onTodayClick?: (day: Date, modifiers: DayModifiers, e: MouseEvent<HTMLButtonElement>) => boolean | void;
    todayUpdatesSelection?: boolean;
    form?: FormikProps<any>;
    today?: Date;
}

const renderDay = (day: Date) => <span className={styles.day}>{day.getDate()}</span>;

export const DatePicker = ({
    initialMonth,
    field,
    form,
    locale,
    meta,
    onChange,
    onTodayClick,
    todayUpdatesSelection = false,
    selectedDays,
    storageFormat = "YYYY-MM-DD",
    todayButton,
    today = new Date(),
    ...rest
}: DatePickerProps) => {
    const { setFieldValue, setFieldTouched } = form || {};
    const { name, value: fieldValue } = field || {};

    const updateValue = (day: Date, toggle: boolean = false) => {
        if (Array.isArray(fieldValue)) {
            const value = moment(day).format(storageFormat);
            if (fieldValue.includes(value)) {
                if (toggle) {
                    name && setFieldValue?.(name, fieldValue.filter((date) => date !== value).sort());
                }
            } else {
                name && setFieldValue?.(name, [...fieldValue, value].sort());
            }
        } else {
            name && setFieldValue?.(name, moment(day).format(storageFormat));
        }
        name && setFieldTouched?.(name, true);
    };

    const handleDayClick = (day: Date, modifiers: DayModifiers, e: MouseEvent<HTMLDivElement>) => {
        if (modifiers[reactDayPickerClasses.disabled]) {
            return;
        }

        const proceed = onChange?.(day, modifiers, e);

        if (proceed !== false) {
            updateValue(day, true);
        }
    };

    // ignore React day pickers today date in favour of our own
    const handleTodayClick = (_: Date, modifiers: DayModifiers, e: MouseEvent<HTMLButtonElement>) => {
        const proceed = onTodayClick?.(today, modifiers, e);

        if (proceed !== false) {
            if (todayUpdatesSelection) {
                updateValue(today, false);
            }
        }
    };

    if (fieldValue) {
        if (Array.isArray(fieldValue)) {
            selectedDays = fieldValue.map((value) => new Date(value));
        } else {
            selectedDays = [new Date(fieldValue)];
        }
    }

    // wrap (styled) day picker with a state we can access
    return (
        <DayPickerContainer initialMonth={initialMonth} locale={locale} storageFormat={storageFormat}>
            {({ month, setMonth }) => (
                <StyledDayPicker
                    month={month}
                    onDayClick={handleDayClick}
                    onTodayButtonClick={handleTodayClick}
                    selectedDays={selectedDays}
                    today={today}
                    {...rest}
                />
            )}
        </DayPickerContainer>
    );
};

// customise the appearance of DayPicker
export const StyledDayPicker = ({ showOutsideDays = true, today = new Date(), modifiers = {}, ...props }) => {
    modifiers = {
        [reactDayPickerClasses.today]: today,
        ...modifiers,
    };

    const { setMonth } = useContext(DayPickerContext);

    return (
        <DayPicker
            classNames={reactDayPickerClasses as any}
            navbarElement={DatePickerNavBar}
            captionElement={() => null}
            renderDay={renderDay}
            todayButton="Today"
            showOutsideDays={showOutsideDays}
            modifiers={modifiers}
            onTodayButtonClick={(_: Date, modifiers: DayModifiers, e: MouseEvent<HTMLButtonElement>) => {
                setMonth(today);
                props.handleTodayClick?.(today, modifiers, e);
            }}
            {...props}
        />
    );
};
