import { Button, Tooltip } from "antd";
import { ColumnProps } from "antd/lib/table";
import { PageTitle } from "common/scaffolding/components/DataListingPage/PageTitle";
import { TableListing } from "common/scaffolding/components/TableListing/TableListing";
import { LocationLocaleContext } from "features/location";
import { LastUpdated } from "components/lastUpdated/LastUpdated";
import { LocationLocale } from "features/location/types/LocationLocale";
import { ServiceSummary } from "features/service/types";
import { useEffect, useState } from "react";
import { QuickSellItem, QuickSellSummary, ServiceQuickSell } from "../types";
import { QuickSellIsOff } from "./QuickSellIsOff";
import { QuickSellMissingData } from "./QuickSellMissingData";
import "./QuickSellPage.scss";
import { QuickSellPerformance } from "./QuickSellPerformance";
import { ServiceSelector } from "./ServiceSelector";
import { Checkbox } from "core/components/form/checkbox";
import { CrudPermissions } from "features/location/types/createCrudPermissions";

export interface Props {
    permissions: CrudPermissions;
    hasLocationUpdatePermission: boolean;
    canCreateDemo: boolean;
    isQuickSellOn: boolean;
    isQuickSellDrinksOnly: boolean;
    summary: QuickSellSummary;
    services: ServiceSummary[];
    handleQuickSellToggle: (on: boolean) => void;
    handleDrinksOnlyQuickSellToggle: (on: boolean) => void;
    generateDemoQuickSellData: () => void;
}

const getQuickSellColumns: (locale: LocationLocale) => ColumnProps<QuickSellItem>[] = (locale: LocationLocale) => [
    {
        title: (
            <Tooltip
                className="quick-sell-page__products__header-tooltip"
                overlayClassName="quick-sell-page__products__header-tooltip__overlay"
                placement="topLeft"
                title={
                    <>
                        <div className="quick-sell-page__products__header-tooltip__title">Rank</div>
                        <div className="quick-sell-page__products__header-tooltip__description">
                            Higher ranked products have a higher chance of being displayed.
                        </div>
                    </>
                }
            >
                Rank
            </Tooltip>
        ),
        key: "rank",
        width: 52,
        align: "center",
        render: (text, record, index) => <div>{index + 1}</div>,
    },
    {
        title: (
            <Tooltip
                className="quick-sell-page__products__header-tooltip"
                overlayClassName="quick-sell-page__products__header-tooltip__overlay"
                placement="topLeft"
                title={
                    <>
                        <div className="quick-sell-page__products__header-tooltip__title">Product</div>
                        <div className="quick-sell-page__products__header-tooltip__description">
                            Product Quick sells only display products without variants or required modifiers to allow
                            for quick ordering.
                        </div>
                    </>
                }
            >
                Product
            </Tooltip>
        ),
        dataIndex: "displayName",
        key: "product",
        render: (text, record) => (
            <div>
                {text}{" "}
                {record.archived ? (
                    <span className="quick-sell-page__products__internal-name">[archived]</span>
                ) : (
                    record.internalName && (
                        <span className="quick-sell-page__products__internal-name">({record.internalName})</span>
                    )
                )}
            </div>
        ),
    },
    {
        title: "Conversion",
        dataIndex: "conversion",
        key: "conversion",
        align: "right",
        width: 110,
        render: (text, record) => <div>{locale.formatPercent(record.conversion)}</div>,
    },
    {
        title: "Sales",
        dataIndex: "sales",
        key: "sales",
        align: "right",
        width: 90,
        render: (text, record) => <div>{locale.formatNumber(record.sales)}</div>,
    },
    {
        title: "SKU",
        dataIndex: "sku",
        key: "sku",
        align: "right",
        width: 150,
    },
];

export const QuickSellPage = ({
    isQuickSellOn,
    isQuickSellDrinksOnly,
    summary,
    hasLocationUpdatePermission,
    services,
    handleQuickSellToggle,
    canCreateDemo,
    generateDemoQuickSellData,
    handleDrinksOnlyQuickSellToggle,
}: Props) => {
    const [selectedService, setSelectedService] = useState<string | undefined>();
    const [conversion, setConversion] = useState<number | undefined>();
    const [sales, setSales] = useState<number | undefined>();
    const [value, setValue] = useState<number | undefined>();
    const [products, setProducts] = useState<QuickSellItem[] | undefined>();

    useEffect(() => {
        const data =
            selectedService && summary ? summary.services.find((s) => s.serviceId === selectedService) : summary;
        if (data) {
            setConversion(data.conversion);
            setSales(data.totalSales);
            setValue(data.totalValue);
            setProducts((data as ServiceQuickSell).items);
        } else {
            setConversion(undefined);
            setSales(undefined);
            setValue(undefined);
            setProducts(undefined);
        }
    }, [selectedService, summary]);

    return (
        <LocationLocaleContext.Consumer>
            {(locale) => (
                <div className="quick-sell-page">
                    {canCreateDemo && (
                        <Button onClick={generateDemoQuickSellData} className="quick-sell-page__create-demo">
                            Create Demo
                        </Button>
                    )}
                    <PageTitle
                        title="Quick Sell"
                        description={
                            <>
                                Quick Sell displays all of your easy to order items up to a value of{" "}
                                {locale.formatCurrency(30)}, and uses a ranking system to select which products to show
                                your customers.
                                {isQuickSellOn && (
                                    <Checkbox
                                        className="quick-sell-page__food-and-drinks text-body"
                                        label="Limit Quick Sell to orders that contain drinks only"
                                        defaultChecked={isQuickSellDrinksOnly}
                                        onChange={(checked) => {
                                            handleDrinksOnlyQuickSellToggle(checked);
                                        }}
                                    />
                                )}
                            </>
                        }
                        additionalInfo={
                            isQuickSellOn && summary ? (
                                <LastUpdated dateUpdated={summary.dateUpdated} locale={locale} />
                            ) : undefined
                        }
                        disabled={!hasLocationUpdatePermission}
                        toggleState={isQuickSellOn}
                        onToggleChange={handleQuickSellToggle}
                    />

                    {isQuickSellOn ? (
                        <>
                            {!!services.length && (
                                <ServiceSelector services={services} setSelectedService={setSelectedService} />
                            )}
                            {conversion !== undefined ? (
                                <>
                                    <div className="quick-sell-page__performance">
                                        <QuickSellPerformance
                                            name="Percentage Conversion"
                                            value={conversion ? locale.formatPercent(conversion) : "-"}
                                        />
                                        <QuickSellPerformance
                                            name="Products Sold"
                                            value={sales ? locale.formatNumber(sales) : "-"}
                                        />
                                        <QuickSellPerformance
                                            name="Value of Products Sold"
                                            value={value ? locale.formatCurrency(value) : "-"}
                                        />
                                    </div>
                                    {products && !!products.length && (
                                        <TableListing
                                            className="quick-sell-page__products"
                                            columns={getQuickSellColumns(locale)}
                                            dataSource={products}
                                            keyField="itemId"
                                            col={22}
                                            rowClassName={(record) => (record.archived ? "archived" : "")}
                                        />
                                    )}
                                    {!(products && products.length) && !selectedService && (
                                        <div className="quick-sell-page__select-service">
                                            Select a service to view performance and current rankings by service.
                                        </div>
                                    )}
                                </>
                            ) : (
                                <QuickSellMissingData />
                            )}
                        </>
                    ) : (
                        <QuickSellIsOff />
                    )}
                </div>
            )}
        </LocationLocaleContext.Consumer>
    );
};
