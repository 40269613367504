import { AppState } from "features";
import { createAction } from "../reducers/stats";
import { Dispatch } from "redux";
import { getStats } from "../api/getStats";

export const stats = (location: string) => {
    return async (dispatch: Dispatch, getState: () => AppState) => {
        const state = getState().groupTabs.stats;

        if (state.status === "loaded" || state.status === "loading") {
            return;
        }

        try {
            dispatch(createAction.loading());
            const data = await getStats(location);
            dispatch(createAction.loaded(data));
        } catch (e) {
            if (process.env.NODE_ENV === "development") {
                console.error(e);
            }
            dispatch(createAction.failed(e));
        }
    };
};
