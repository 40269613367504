import { AppState } from "features";
import { createSelector } from "reselect";

export const getWaiters = (state: AppState) => state.waiters;

export const getWaiterListState = createSelector(getWaiters, (waiters) => waiters.list);

export const getWaiterEditState = createSelector(getWaiters, (waiters) => waiters.edit);

export const getAccessLevelListState = createSelector(getWaiters, (waiters) => waiters.accessList);

export const getWaiterList = createSelector(getWaiterListState, (listState) => listState.status == "loaded" ? listState.data : []);

export const getEditableWaiter = createSelector(getWaiterEditState, (editState) => editState.status == "loaded" ? editState.data : undefined);

export const getAccessLevelList = createSelector(getAccessLevelListState, (accessLevels) => accessLevels.status == "loaded" ? accessLevels.data : []);

export const getDefaultAccessLevel = createSelector(getAccessLevelList, 
    (accessLevels) => {
        const defaultLevel = accessLevels.find((al) => al.isDefault);
        return !!defaultLevel ? defaultLevel.id : "";
    }
);