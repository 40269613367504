import { TrackEventData } from "common/appInsights/types/TrackEventData";
import { KeyedLoaded } from "common/loader";
import { NotificationMessage } from "common/notificationMessage";
import { AppAction, AppDispatch, AppState } from "features";
import { SaveActionCreators, scaffoldSaveAction } from "./scaffoldSaveAction";

export const scaffoldArchiveAction = <TKey extends string, TData>(
    listSelector: (state: AppState) => KeyedLoaded<any, TKey | undefined>,
    actionCreators: SaveActionCreators<TKey, null>,
    saveAction: (key: TKey, dispatch: AppDispatch) => Promise<void>,
    successCallback: ((dispatch: AppDispatch) => AppAction<any> | boolean | void) | null = null, // boolean is used as should goBack in scaffoldSaveAction
    successNotification: NotificationMessage = NotificationMessage.ARCHIVE_SUCCESS,
    getTrackingData?: (state: AppState) => TrackEventData | TrackEventData[]
) =>
    scaffoldSaveAction<TKey, TData, null, TKey | null>(
        listSelector,
        actionCreators,
        async (key: TKey | undefined, dispatch) => {
            if (key === undefined) {
                return null;
            }
            await saveAction(key, dispatch);
            return null;
        },
        successNotification,
        false,
        successCallback ? (_, dispatch) => successCallback(dispatch) : null,
        false,
        getTrackingData
    );
