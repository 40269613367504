import * as Yup from "yup";
import { EditablePriceLevels, PriceListItem } from "../types";

export const editablePriceLevelsItemSchema = Yup.object<PriceListItem>().shape({
    id: Yup.string(),
    displayName: Yup.string(),
    itemType: Yup.string(),
    displayType: Yup.string(),
    price: Yup.number(),
    priceLevelPrice: Yup.number().nullable(true).required().min(0).max(1000),
    sku: Yup.string().nullable(true),
    children: Yup.array().of(Yup.mixed()).nullable(true),
    canEdit: Yup.boolean(),
    parentId: Yup.string().nullable(true),
    internalName: Yup.string(),
    key: Yup.string(),
});

export const editablePriceLevelsSchema = Yup.object<EditablePriceLevels>().shape({
    selectedItems: Yup.array().of(editablePriceLevelsItemSchema),
});
