import { AppState } from "features";
import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { getCrudPermissions } from "features/location/selectors/getCrudPermissions";
import { isLoaded } from "common/loader/isLoaded";
import { ListPromotionsPage, Props } from "../components/ListPromotionsPage";
import { LocationComponentProps, LocaleComponentProps, withLocation, withLocale } from "features/location";
import { RouteComponentProps, withRouter } from "react-router";
import { withLoader, PageLoadingProps } from "common/loader/withLoader";

import * as actions from "features/promotions/actions";
import { getSortedPromotions } from "../selectors/getSortedPromotions";

const mapStateToProps = (
    state: AppState,
    { restaurantLocation: { postype }, match: { params } }: LocationComponentProps & RouteComponentProps<RouteParams>
): DataProps<PageLoadingProps<Props>> => {
    const {
        promotions: { list },
        locations: { active: activeLocation },
    } = state;

    if (isLoaded(activeLocation) && isLoaded(list)) {
        return {
            promotions: getSortedPromotions(state),
            permissions: getCrudPermissions(state).promotions,
            loadStatus: "loaded",
        };
    }

    return {
        loadStatus: list.status,
    };
};

const mapDispatchToProps = (
    dispatch: any,
    {
        restaurantLocation,
        match: { params },
    }: LocationComponentProps & LocaleComponentProps & RouteComponentProps<RouteParams>
): FunctionProps<PageLoadingProps<Props>> => ({
    fetch: () => {
        dispatch(actions.list(restaurantLocation.id));
    },
});

interface RouteParams {
    location: string;
    type: string;
}

export const ListPromotionsPageContainer = withRouter(
    withLocation(withLocale(connect(mapStateToProps, mapDispatchToProps)(withLoader(ListPromotionsPage))))
);
