import { ActiveLocation, LocationLocaleContext } from "features/location";
import { CatalogueItem } from "features/catalogue";
import { CrudActionFooter, SubmitMode } from "core/components/actionFooter/CrudActionFooter";
import { CrudPermissions } from "features/location/types/createCrudPermissions";
import { EditablePromotion, PromotionItems } from "../types";
import { FilterCategory } from "features/catalogue/types/FilterCategory";
import { Formik, Form, validateYupSchema, yupToFormErrors, FormikProps } from "formik";
import { getEditablePromotionSchema, ValidationOptions } from "../schema/EditablePromotionSchema";
import { Moment } from "moment";
import { PageHeader } from "core/components/pageHeader";
import { PromotionDetails, PromotionProducts, PromotionRestrictions, PromotionSchedule, PromotionType } from "./edit";
import { RouteComponentProps } from "react-router-dom";
import { SaveStatus } from "common/loader";
import { ScrollToFormikError } from "components/forms/ScrollToFormikError";
import { TreeItem } from "common/types";
import { useCurrencyFormatter, useIsNewRoute } from "common/hooks";
import { useContext, useEffect, useRef, useState } from "react";
import { ExportImportButton } from "./edit/ExportImportButton";
import { queryPromoCodeAvailable } from "../api/queryPromoCodeAvailable";
import { PageContainer } from "core/components/pageContainer/PageContainer";
import { CardsContainer } from "core/components/card/CardsContainer";

export interface CommonEditProps {
    disableFields: boolean;
}

export interface Props extends RouteComponentProps<RouteParams> {
    id: string;
    categories: FilterCategory[];
    fromMonth: Date;
    initialValues: EditablePromotion;
    onSubmit: (values: EditablePromotion) => void;
    onArchive: (values: EditablePromotion) => void;
    onClose: () => void;
    permissions: CrudPermissions;
    products: CatalogueItem[];
    saveStatus: SaveStatus;
    productTree: TreeItem[];
    restaurantLocation: ActiveLocation;
    venueTime: Moment;
    venueTimeZone: string;
}

interface RouteParams {
    id: string;
    location: string;
    region: string;
}

const getValidate = (options: ValidationOptions, restaurantLocation: ActiveLocation) => (values: EditablePromotion) => {
    return validateYupSchema(values, getEditablePromotionSchema(options), undefined, {
        values,
        restaurantLocation,
    }).then(
        () => ({}),
        (err: any) => yupToFormErrors(err)
    );
};

export const EditPromotionPage = ({
    match: {
        params: { id },
    },
    fromMonth,
    onArchive,
    onClose,
    onSubmit,
    permissions,
    saveStatus,
    products,
    productTree,
    restaurantLocation,
    initialValues,
    venueTime,
    venueTimeZone,
}: Props) => {
    const [submitMode, setSubmitMode] = useState<SubmitMode>("save");
    const isNew = useIsNewRoute();
    const { canDelete, canUpdate } = permissions || {};
    const disableFields = !canUpdate;
    const [validatingCode, setValidatingCode] = useState<boolean>(false);
    const locationLocale = useContext(LocationLocaleContext);
    const fromRef = useRef<FormikProps<EditablePromotion> | null>(null);

    useEffect(() => {
        // sort incoming ids for better 'dirty' checks
        initialValues.restrictions.requiredItems?.productIds?.sort();
        initialValues.restrictions.requiredItems?.variantIds?.sort();
        if (!initialValues.restrictions.schedule.startDate) {
            initialValues.restrictions.schedule.startDate = venueTime.format("YYYY-MM-DD");
        }
    }, [
        initialValues.restrictions.requiredItems?.productIds,
        initialValues.restrictions.requiredItems?.variantIds,
        initialValues.restrictions.schedule,
        venueTime,
    ]);

    const handleSubmit = (data: EditablePromotion) => {
        if (validatingCode) {
            return;
        }

        setSubmitMode("save");

        if (data.applyToAll || !data.restrictions?.requiredItems) {
            onSubmit(data);
        } else {
            onSubmit({
                ...data,
                restrictions: {
                    ...data.restrictions,
                    requiredItems: mapSelectedProducts(data.restrictions.requiredItems, products),
                },
            });
        }
    };

    const handleDelete = (data: EditablePromotion) => {
        setSubmitMode("archive");
        onArchive(data);
    };

    const setEditablePromotion = async (promotion: EditablePromotion) => {
        fromRef.current?.setValues(promotion, true);

        const result = await queryPromoCodeAvailable(
            promotion.promotionType,
            promotion.code,
            restaurantLocation.id,
            id
        );
        fromRef.current?.setFieldValue("hasUniqueCode", result);
        Object.keys(promotion).forEach((p) => fromRef.current?.setFieldTouched(p, true));
    };

    const currencyFormatter = useCurrencyFormatter(locationLocale);

    return (
        <PageContainer>
            <PageHeader
                title={`${isNew ? "Add" : "Edit"} Promotion`}
                backHandler={onClose}
                actions={
                    <ExportImportButton
                        restaurantLocation={restaurantLocation}
                        setEditablePromotion={setEditablePromotion}
                        promotionId={initialValues.id}
                        promotionName={initialValues.displayName}
                    />
                }
            />
            <Formik
                validate={getValidate(
                    {
                        formatCurrency: currencyFormatter.format,
                        locationId: restaurantLocation.id,
                        initialStartDate: initialValues.restrictions?.schedule.startDate || undefined,
                        venueTime,
                        venueTimeZone,
                    },
                    restaurantLocation
                )}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                innerRef={fromRef}
            >
                {(form) => (
                    <CardsContainer as={Form}>
                        <ScrollToFormikError />
                        <PromotionDetails
                            disableFields={disableFields}
                            id={id}
                            restaurantLocation={restaurantLocation}
                            setValidatingCode={setValidatingCode}
                        />
                        <PromotionType disableFields={disableFields} />
                        <PromotionRestrictions disableFields={disableFields} />
                        <PromotionSchedule
                            disableFields={disableFields}
                            fromMonth={fromMonth}
                            venueTime={venueTime}
                            venueTimeZone={venueTimeZone}
                        />
                        <PromotionProducts disableFields={disableFields} productTree={productTree} />
                        <CrudActionFooter
                            saving={saveStatus === "saving"}
                            submitMode={submitMode}
                            showDuplicate={false}
                            showDelete={canDelete && !isNew}
                            showSave={!disableFields}
                            confirmDelete={{
                                title: "Are you sure you want to delete this promotion?",
                                deleteLabel: "Delete promotion",
                                children: "Customers won’t be able to use this promotion again.",
                            }}
                            deleteProps={{
                                onClick: () => handleDelete(form.values),
                            }}
                            saveProps={{ disabled: !form.dirty }}
                            position="fixed"
                        />
                    </CardsContainer>
                )}
            </Formik>
        </PageContainer>
    );
};

const mapSelectedProducts = (selections: PromotionItems, allProducts: CatalogueItem[]) => {
    // strip products with variants (only the variant selections are required)
    const productIds = selections.productIds.filter((productId) => {
        const product = allProducts.find(({ id }) => id === productId);
        return (product && (!product.children || product.children.length === 0)) || false;
    });

    return {
        ...selections,
        productIds,
    };
};
