import { scaffoldEditAction } from "common/scaffolding/actions/scaffoldEditAction";
import { Group } from "features/group";
import { AlcoholicDrinksRestriction } from "features/location/types/AlcoholicDrinksRestriction";
import { GraphQLTrayChargeQueryModel, TrayCharge } from "features/trayCharge/types";
import { GraphQLVenueServiceFeeQueryModel, VenueServiceFee } from "features/venueServiceFee/types";
import { isNew } from "common/utility/modelUtils";
import { editLocationSettings, GraphQLLocationSettings } from "../api/editLocationSettings";
import { createAction } from "../reducers/edit";
import { toPercentage } from "../tipsHelpers";
import { EditableLocationSettings, TipOptions } from "../types";
import { toPercentageWithPrecision } from "common/utility/numberUtils";

export const edit = (slug: string) =>
    scaffoldEditAction(
        slug,
        (state) => state.LocationSettings.edit,
        createAction,
        async () => {
            if (isNew(slug)) {
                return newEditableLocationSettings();
            }

            const location = await editLocationSettings(slug);
            return mapEditableLocationSettings(location);
        }
    );

const mapEditableLocationSettings = (input: GraphQLLocationSettings): EditableLocationSettings => ({
    ageRestriction: input.ageRestriction,
    allowNotesToKitchen: input.allowNotesToKitchen,
    allowTips: input.allowTips,
    confirmEighteenPlus: input.confirmEighteenPlus,
    defaultTipLevels: mapDefaultTipLevels(input.defaultTipLevels),
    enableAnotherRound: input.enableAnotherRound,
    enableDietaryNotifier: !!input.fromTheVenue,
    enableGroupTabs: input.enableGroupTabs,
    enableNoTip: input.enableNoTip,
    enableQuickSell: input.enableQuickSell,
    quickSellDrinksOnly: input.quickSellDrinksOnly,
    enableSurcharges: input.enableSurcharges,
    enableSurchargesFeature: input.enableSurchargesFeature,
    facebookPixelId: input.facebookPixelId,
    fromTheVenue: input.fromTheVenue,
    fromTheVenueTitle: input.fromTheVenueTitle,
    group: input.group && mapGroup(input.group),
    id: input.id,
    idleTimeoutMinutes: input.idleTimeoutMinutes,
    isBuzzer: input.isBuzzer,
    isLiveOrdersEnabled: input.isLiveOrdersEnabled,
    marketingOptions: input.marketingOptions || { enabled: false, prompt: false },
    mustAcceptAllergenNotice: input.mustAcceptAllergenNotice,
    orderBatchTimeSeconds: input.orderBatchTimeSeconds,
    drinkOrderBatchTimeSeconds: input.drinkOrderBatchTimeSeconds,
    foodOrderBatchTimeSeconds: input.foodOrderBatchTimeSeconds,
    sectionsWithDocketMerging: input.sectionsWithDocketMerging,
    slug: input.slug,
    tipOptions: mapTips(input.tipOptions),
    unusedPartyTimeoutMinutes: input.unusedPartyTimeoutMinutes,
    groupTabGratuityFactor: input.groupTabGratuityFactor ? toPercentage(input.groupTabGratuityFactor) : undefined,
    enablePosSync: input.enablePosSync,
    trayCharge: mapTrayCharge(input.trayCharge),
    venueServiceFee: mapVenueServiceFee(input.venueServiceFee),
    alcoholicDrinksRestriction: input.alcoholicDrinksRestriction || newAlcoholicDrinksRestriction(),
    enableProductVideos: input.enableProductVideos,
    enableAbsorbMerchantFee: input.enableAbsorbMerchantFee,
    orderFlow: input.orderFlow,
    splitPaymentOptions: input.splitPaymentOptions ?? [],
});

const mapTrayCharge = (trayCharge?: GraphQLTrayChargeQueryModel): TrayCharge => {
    return !trayCharge
        ? newTrayCharge()
        : {
              ...trayCharge,
              fixedAmount: trayCharge.fixedAmount?.toFixed(2) || "",
          };
};

const mapVenueServiceFee = (venueServiceFee?: GraphQLVenueServiceFeeQueryModel): VenueServiceFee => {
    return !venueServiceFee
        ? newVenueServiceFee()
        : {
              ...venueServiceFee,
              percentage: toPercentageWithPrecision(venueServiceFee.factor, 4),
          };
};

const mapGroup = (input: Group): Group => ({
    ...input,
    marketingOptions: input.marketingOptions || { enabled: false, prompt: false },
});

const mapTips = (input: TipOptions): TipOptions => {
    let { defaultIndex, levels, showNoTip } = input;
    defaultIndex = defaultIndex === undefined || defaultIndex === undefined ? -1 : defaultIndex;
    levels = levels ? levels.map((val) => (val ? toPercentage(val) : null)) : [null, null, null];
    return {
        defaultIndex,
        levels,
        showNoTip,
    };
};

const mapDefaultTipLevels = (input: number[]): number[] => {
    return input ? input.map((val) => (val ? toPercentage(val) : 0)) : [];
};

const newTrayCharge = (): TrayCharge => ({
    id: "",
    enabled: false,
    fixedAmount: "",
    name: "",
    posId: "",
});

const newVenueServiceFee = (): VenueServiceFee => ({
    id: "",
    enabled: false,
    percentage: null,
});

const newEditableLocationSettings = (): EditableLocationSettings => ({
    ageRestriction: undefined,
    allowNotesToKitchen: false,
    allowTips: false,
    confirmEighteenPlus: false,
    defaultTipLevels: [],
    enableAnotherRound: true,
    enableDietaryNotifier: false,
    enableGroupTabs: true,
    enableNoTip: false,
    enableQuickSell: true,
    quickSellDrinksOnly: false,
    enableSurcharges: false,
    enableSurchargesFeature: false,
    fromTheVenue: undefined,
    fromTheVenueTitle: "",
    id: "",
    idleTimeoutMinutes: 5,
    isBuzzer: false,
    isLiveOrdersEnabled: true,
    marketingOptions: null as any,
    mustAcceptAllergenNotice: false,
    orderBatchTimeSeconds: undefined,
    drinkOrderBatchTimeSeconds: undefined,
    foodOrderBatchTimeSeconds: undefined,
    sectionsWithDocketMerging: [],
    slug: "",
    tipOptions: {
        defaultIndex: -1,
        levels: [null, null, null],
        showNoTip: false,
    },
    unusedPartyTimeoutMinutes: 30,
    groupTabGratuityFactor: undefined,
    trayCharge: undefined,
    alcoholicDrinksRestriction: newAlcoholicDrinksRestriction(),
    enableProductVideos: false,
    enableAbsorbMerchantFee: false,
    orderFlow: null as any,
    splitPaymentOptions: [],
});

const newAlcoholicDrinksRestriction = (): AlcoholicDrinksRestriction => ({
    enabled: false,
    applyAcrossAllServices: false,
    maxDrinksPerOrder: 4,
});
