import { useState, useCallback, useEffect } from "react";
import { Props } from "./ViewBrandingPage";
import SVG from "react-inlinesvg";
import { preloadImage } from "../utils";
import { ImageLoader } from "./ImageLoader";

let fetchTimer = 0;

export const BackgroundPage = ({ backgroundImage, logoUrl }: Props) => {
    const [imageReady, setBackgroundReady] = useState(false);
    const fetchBackgroundImage = useCallback(() => {
        window.clearTimeout(fetchTimer);
        if (backgroundImage) {
            preloadImage(backgroundImage)
                .then(() => {
                    setBackgroundReady(true);
                })
                .catch(() => {
                    setBackgroundReady(false);
                    fetchTimer = window.setTimeout(fetchBackgroundImage, 1000);
                });
        }
    }, [backgroundImage]);

    useEffect(() => {
        fetchBackgroundImage();
        return () => window.clearTimeout(fetchTimer);
    }, [backgroundImage, fetchBackgroundImage]);

    if (!backgroundImage) {
        return <div className="empty">No image set</div>;
    }

    return (
        <div
            className="branding-preview__background"
            style={
                imageReady
                    ? {
                          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImage}`,
                      }
                    : undefined
            }
        >
            {!imageReady ? (
                <ImageLoader />
            ) : (
                !!logoUrl && logoUrl.endsWith(".svg") && <SVG src={logoUrl} color="white" />
            )}
        </div>
    );
};
