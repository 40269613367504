import { useState } from "react";
import { Button } from "antd";
import { BackgroundPage } from "./BackgroundPage";
import { MenuPage } from "./MenuPage";
import { ListPage } from "./ListPage";
import { HistoryItemPage } from "./HistoryItemPage";
import "./ViewBrandingPage.scss";

export interface Props {
    displayName?: string;
    backgroundImage?: string;
    menuBackgroundImage?: string;
    heroImage?: string;
    logoUrl?: string;
    themeColor?: string;
}

type Mode = "Background" | "Menu" | "Order History List" | "Order History Item";

const Modes: Mode[] = ["Background", "Menu", "Order History List", "Order History Item"];

export const ViewBrandingPage = ({
    backgroundImage,
    heroImage,
    logoUrl,
    themeColor,
    displayName,
    menuBackgroundImage,
}: Props) => {
    const getSelected = (mode: Mode) => {
        switch (mode) {
            case "Background":
                return {
                    mode,
                    render: BackgroundPage,
                };
            case "Menu":
                return {
                    mode,
                    render: MenuPage,
                };
            case "Order History List":
                return {
                    mode,
                    render: ListPage,
                };
            case "Order History Item":
                return {
                    mode,
                    render: HistoryItemPage,
                };
        }
    };

    const [selected, setSelected] = useState(getSelected("Background"));

    return (
        <div className="branding-preview">
            <div className="mobile-wrapper">
                <div className="notch">
                    <div className="speaker" />
                    <div className="camera" />
                </div>
                <div className="mobile-wrapper__content">
                    {selected.render({
                        backgroundImage,
                        heroImage,
                        logoUrl,
                        themeColor,
                        displayName,
                        menuBackgroundImage,
                    } as Props)}
                </div>
            </div>
            <div className="branding-preview__modes">
                {Modes.map((m, i) => (
                    <Button
                        key={"btn_" + m}
                        type={m === selected.mode ? "primary" : "default"}
                        onClick={() => setSelected(getSelected(m))}
                    >
                        {m}
                    </Button>
                ))}
            </div>
        </div>
    );
};
