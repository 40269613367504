import { Modal } from "antd";
import { useEffect, useState } from "react";
import { ExtendedPermissions } from "features/location/types/ExtendedPermissions";
import { ImportResult } from "../types/ImportResult";
import { UploadResult } from "../types/UploadResult";
import { BulkUploadType } from "../api/bulkUpload";
import { Uploader } from "./Uploader/Uploader";

export interface Props {
    permissions: ExtendedPermissions;
    onImport: (fileName: string, preview: boolean) => void;
    onUpload: (file: File, type: BulkUploadType) => void;
    uploadResult?: UploadResult;
    importResult?: ImportResult;
    parentRecordText: string;
    childRecordText: string;
    onLoadNew: () => void;
    importStatus: string;
    uploadStatus: string;
    templateData: string;
    onResetForm: () => void;
    initialStatuses?: string[];
    visible: boolean;
}

export const ModalUpload = ({
    permissions,
    onImport,
    importResult,
    parentRecordText,
    childRecordText,
    onLoadNew,
    importStatus,
    templateData,
    uploadResult,
    onUpload,
    uploadStatus,
    onResetForm,
    visible,
}: Props) => {
    const [imported, setImported] = useState<ImportResult>();
    const [uploaded, setUploaded] = useState<UploadResult>();

    useEffect(() => {
        setImported(importResult);
    }, [importResult]);

    useEffect(() => {
        setUploaded(uploadResult);
    }, [uploadResult]);

    const handleCloseModal = () => {
        onResetForm();
    };

    return (
        <Modal
            className="catalogue-upload-modal"
            open={visible}
            okButtonProps={{ className: "hidden" }}
            destroyOnClose={true}
            footer={null}
            width={467}
            maskClosable={false}
        >
            <Uploader
                type="product"
                onImport={onImport}
                importResult={imported}
                uploadResult={uploaded}
                uploadStatus={uploadStatus}
                parentRecordTitle={parentRecordText}
                childRecordTitle={childRecordText}
                onClose={handleCloseModal}
                importStatus={importStatus}
                templateData={templateData}
                onLoadNew={onLoadNew}
                onUpload={onUpload}
            />
        </Modal>
    );
};
