import { validateYupSchema } from "formik";
import { Moment } from "moment";
import moment from "moment-timezone";
import { ValidationError as YupValidationError } from "yup";
import { oneTimeSurchargeSchema, recurringSurchargeSchema } from "../schema/EditableSurcharge";
import { EditableSurcharge } from "../types";
import { ValidationError } from "../types/ValidationError";

const validateYupSurcharge = async (
    surcharge: EditableSurcharge,
    validateSurchargeId: boolean,
    surchargeIdName: string,
    schema: any,
    locationDateInt: number,
    locationTime: number
): Promise<ValidationError[]> => {
    if (surcharge.markedForDelete) {
        return [];
    }

    const context = { validateSurchargeId, surchargeIdName, locationDateInt, locationTime };

    const result: ValidationError[] = await validateYupSchema(surcharge, schema, undefined, context).then(
        () => {
            return [];
        },
        (err: any) => {
            return err.inner.map((e: YupValidationError) => {
                return { rowId: surcharge.rowKey, field: e.path, message: e.message };
            });
        }
    );

    return result;
};

const validateYupSurcharges = async (
    surcharges: EditableSurcharge[],
    validateSurchargeId: boolean,
    surchargeIdName: string,
    schema: any,
    locationDateInt: number,
    locationTime: number
) => {
    return await Promise.all(
        surcharges.map((s: EditableSurcharge) =>
            validateYupSurcharge(s, validateSurchargeId, surchargeIdName, schema, locationDateInt, locationTime)
        )
    ).then((values) => values);
};

const overLapValidationMessage = "Times cannot overlap with previous day. ";
const dateClashValidationMessage = "This surcharge conflicts with another. Select another date/time. ";

export const validateSurcharges = async (
    recurringSurcharges: EditableSurcharge[],
    oneTimeSurcharges: EditableSurcharge[],
    currentRecurringSurcharges: EditableSurcharge[],
    currentOneTimeSurcharges: EditableSurcharge[],
    validateSurchargeId: boolean,
    surchargeIdName: string,
    localDateTime: Moment
) => {
    const venueTime = parseInt(localDateTime.format("HHmm"));
    const venueDateInt = parseInt(localDateTime.format("YYYYMMDD"));

    const recurringResult = await validateYupSurcharges(
        recurringSurcharges,
        validateSurchargeId,
        surchargeIdName,
        recurringSurchargeSchema,
        venueDateInt,
        venueTime
    );
    const oneTimeResult = await validateYupSurcharges(
        oneTimeSurcharges,
        validateSurchargeId,
        surchargeIdName,
        oneTimeSurchargeSchema,
        venueDateInt,
        venueTime
    );
    const errors = recurringResult.concat(oneTimeResult).reduce((a, b) => {
        return (a || []).concat(b);
    });

    const dateClashes = oneTimeSurcharges.filter(
        (x) =>
            !x.markedForDelete &&
            !!x.date &&
            currentOneTimeSurcharges.some(
                (c) =>
                    !c.markedForDelete &&
                    !!c.date &&
                    c.rowKey !== x.rowKey &&
                    moment(c.date).isSame(moment(x.date), "day")
            )
    );

    dateClashes &&
        dateClashes.forEach((s) => {
            errors.push({ rowId: s.rowKey, field: "date", message: dateClashValidationMessage });
        });

    const overlaps = currentRecurringSurcharges.filter((x) =>
        currentRecurringSurcharges.some(
            (c) =>
                x.day &&
                (c.day === x.day / 2 || (c.day === 64 && x.day === 1)) &&
                c.endTime &&
                c.startTime &&
                x.startTime &&
                c.endTime < c.startTime &&
                x.startTime < c.endTime
        )
    );

    overlaps &&
        overlaps.forEach((s) => {
            errors.push({ rowId: s.rowKey, field: "startTime", message: overLapValidationMessage });
        });

    currentRecurringSurcharges.forEach((s) => setValidationMessages(s, errors));
    currentOneTimeSurcharges.forEach((s) => setValidationMessages(s, errors));

    return errors;
};

const setValidationMessages = (surcharge: EditableSurcharge, errors: ValidationError[]) => {
    const surchargeErrors = errors.filter((e) => e.rowId === surcharge.rowKey);
    !surchargeErrors || !surchargeErrors.length
        ? (surcharge.validationError = undefined)
        : (surcharge.validationError =
              surchargeErrors.length > 1
                  ? "Missing or invalid fields. "
                  : surchargeErrors.map((e) => e.message).join(""));
};
