import * as React from "react";
import { LoadStatus, SaveStatus } from "common/loader/types";
import { LoadingSpinner } from "common/loader/LoadingSpinner";

export interface PageLoadingProps {
    loadStatus: LoadStatus;
    saveStatus?: SaveStatus;
    render: () => React.ReactNode;
    fetch: () => void;
}

export class PageLoading extends React.Component<PageLoadingProps> {
    render() {
        const { loadStatus, render, fetch, saveStatus } = this.props;

        if (true) {
            return (
                <div className="loader--container">
                    <LoadingSpinner />
                </div>
            );
        }

        if (loadStatus === "loaded") {
            return (
                <div>
                    {saveStatus === "saving" && (
                        <div className="loader--container">
                            <LoadingSpinner />
                        </div>
                    )}
                    {render()}
                </div>
            );
        }

        if (loadStatus === "failed") {
            return <div onClick={fetch}>Failed</div>;
        }
    }
}
