import Table from "antd/lib/table";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { applyDatasourceUpdates } from "../surchargeHelper";
import { EditableSurcharge } from "../types";
import { FieldUpdate } from "../types/SurchargeField";
import { SurchargeTableProps } from "../types/SurchargeTableProps";
import { OneTimeSurchargeColumns } from "./SurchargeColumns";

export const OneTimeSurchargesTable = ({
    surchargeIdColumn,
    invalidItems,
    touchedIds,
    surcharges,
    initialSurcharges,
    getRowClass,
    disabled,
    onChange,
    onRowsTouched,
    onClearRow,
}: SurchargeTableProps) => {
    const [datasource, setDatasource] = useState(initialSurcharges);
    const handleUpdateField = useCallback(
        (surcharge: EditableSurcharge, updates: FieldUpdate[], checkCheckBoxes: boolean = false) => {
            const updatedIds = touchedIds;
            const newDatasource = applyDatasourceUpdates(
                surcharge,
                surcharges,
                initialSurcharges,
                updates,
                checkCheckBoxes,
                touchedIds,
                true
            );
            onRowsTouched(updatedIds);
            onChange(newDatasource);
        },
        [onChange, onRowsTouched, surcharges, touchedIds, initialSurcharges]
    );

    useEffect(() => {
        setDatasource(surcharges.filter((s) => !s.markedForDelete));
    }, [surcharges]);

    return (
        <Table
            columns={OneTimeSurchargeColumns({
                surchargeIdColumn,
                invalidItems,
                onUpdateField: handleUpdateField,
                onClearRow: onClearRow,
                disableFields: disabled,
            })}
            className={classNames(surchargeIdColumn && "with-surcharge-id")}
            dataSource={datasource}
            size="small"
            rowKey="rowKey"
            pagination={false}
            rowClassName={getRowClass}
        ></Table>
    );
};
