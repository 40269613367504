import { opsApi } from "API";
import { GraphQLItemId } from "../../../common/scaffolding/types";

export const deleteLocation = async (
    locationId: string | undefined
) => {
    const res = await opsApi.graphQLQuery<{ deleteLocation: GraphQLItemId }>(deleteQuery, {
        locationId
    });
    return res.data.deleteLocation;
};

const deleteQuery = `
    mutation($locationId: ID!) {
        deleteLocation(locationId: $locationId) {
            id
        }
    }
`;
