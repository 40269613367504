import styles from "./ModalImportConfirm.module.scss";
import { Alert, AlertStatus } from "core/components/alert";
import { CardWidth, Confirm, Modal } from "core/components/modal";
import { getState } from "common/selectors/getState";
import { Row } from "core/components/card";
import { SelectionInfo } from "../types";
import { useCallback, useMemo, useState } from "react";
import { useLoadStatus } from "common/hooks";
import { useDispatch, useSelector } from "react-redux";
import { Warning } from "core/components/iconWithText";
import { CheckCircle } from "common/icons";
import { Link } from "react-router-dom";
import { getPosCatalogueUploadSummary } from "../selectors/getPosCatalogueUploadResult";
import { simplePlural } from "common/utility/StringUtils";
import { ActiveLocation } from "features/location";
import { SummaryResult } from "../types/UploadResult";
import { createAction } from "../reducers/upload";
import { Checkbox } from "core/components/form/checkbox";
import { Field } from "formik";

interface Props {
    cataloguePath: string;
    info: SelectionInfo;
    onClose: () => void;
    onConfirm: () => boolean;
    restaurantLocation: ActiveLocation;
    visible: boolean;
}

// TODO support non english locales when needed
const numberFormat = new Intl.NumberFormat("en-AU");

const hasSomeResults = (results?: SummaryResult[]) => {
    return results?.some((summaryResult) => !!summaryResult.result.length) || false;
};

const getMissingContentMessage = ({
    productsMissingContent,
    modifierGroupsMissingContent,
}: {
    productsMissingContent: boolean | undefined;
    modifierGroupsMissingContent: boolean | undefined;
}): string => {
    const missing = [];
    if (productsMissingContent) {
        missing.push("products");
    }
    if (modifierGroupsMissingContent) {
        missing.push("modifer groups");
    }

    return missing.join(" and ");
};

export const ModalImportConfirm = ({ cataloguePath, info, onClose, onConfirm, restaurantLocation, visible }: Props) => {
    const state = useSelector(getState);
    const uploadStatus = useLoadStatus([state.posCatalogueImport.upload.status]);
    const [displayResult, setDisplayResult] = useState(false);
    const dispatch = useDispatch();
    const {
        products,
        variants,
        modifierGroups,
        modifierOptions,
        productsMissingContent,
        modifierGroupsMissingContent,
    } = useSelector(getPosCatalogueUploadSummary) || {};

    const handleConfirm = useCallback(() => {
        setDisplayResult(true);
        return onConfirm();
    }, [onConfirm]);

    const handleClose = useCallback(() => {
        setDisplayResult(false);
        return onClose();
    }, [onClose]);

    const handleSuccessConfirm = useCallback(() => {
        dispatch(createAction.reset());
        // keep dialog open as this action navigates away from page anyway
        return false;
    }, [dispatch]);

    const uploadLoaded = uploadStatus === "loaded";
    const uploadFailed = uploadStatus === "failed";
    const uploadLoading = uploadStatus === "loading";
    const displayFailed = displayResult && uploadFailed;
    const displaySuccess = displayResult && uploadLoaded;

    const cataloguePathParts = cataloguePath.split("/");

    const forceModifiersTab = modifierGroupsMissingContent && !productsMissingContent;

    const forceProductsTab = productsMissingContent && !modifierGroupsMissingContent;

    const someItemsMissingContent = productsMissingContent || modifierGroupsMissingContent;

    if (forceModifiersTab) {
        cataloguePathParts[cataloguePathParts.length - 1] = "modifiers";
    }

    if (forceProductsTab) {
        cataloguePathParts[cataloguePathParts.length - 1] = "products";
    }

    const successPath = useMemo(
        () =>
            cataloguePathParts.join("/") +
            (someItemsMissingContent ? `?s=${encodeURIComponent("Missing content")}` : ""),
        [cataloguePathParts, someItemsMissingContent]
    );

    const footer = useMemo(() => {
        if (displayFailed) {
            return <Confirm confirmLabel="Retry" confirmProps={{ type: "button" }} onConfirm={handleConfirm} />;
        }

        if (displaySuccess) {
            return (
                <Confirm
                    confirmLabel="View Catalogue"
                    confirmProps={{ as: Link, to: successPath }}
                    onConfirm={handleSuccessConfirm}
                    showCancel={false}
                />
            );
        }

        return (
            <Confirm
                confirmLabel="Continue"
                confirmProps={{ type: "button", loading: uploadLoading }}
                onConfirm={handleConfirm}
            />
        );
    }, [successPath, displayFailed, displaySuccess, uploadLoading, handleConfirm, handleSuccessConfirm]);

    const title = useMemo(() => {
        if (displayFailed) {
            return "Import failed";
        }

        if (displaySuccess) {
            return "Import successful";
        }

        return "Import changes";
    }, [displayFailed, displaySuccess]);

    const noResults =
        !hasSomeResults(products) &&
        !hasSomeResults(variants) &&
        !hasSomeResults(modifierGroups) &&
        !hasSomeResults(modifierOptions);

    return (
        <Modal visible={visible} onClose={handleClose} title={title} footer={footer} width={CardWidth.NARROW}>
            {displayFailed && (
                <Row className={styles.row}>
                    <p className={styles.message}>Oh no! We weren't able to import those items from POS.</p>
                    <Warning>An error occurred. Please try again</Warning>
                </Row>
            )}

            {displaySuccess && (
                <Row className={styles.successRow}>
                    <span className={styles.successIcon}>
                        <CheckCircle />
                    </span>
                    <div className={styles.successInfo}>
                        <h2 className={styles.successHeading}>Import successful</h2>
                        <div className={styles.successSummary}>
                            {noResults ? (
                                <p className={styles.message}>No changes made</p>
                            ) : (
                                <>
                                    {products &&
                                        products.map((result, index) => (
                                            <SummaryResultRow key={index} result={result} name="Product" />
                                        ))}
                                    {variants &&
                                        variants.map((result, index) => (
                                            <SummaryResultRow key={index} result={result} name="Variant" />
                                        ))}
                                    {modifierGroups &&
                                        modifierGroups.map((result, index) => (
                                            <SummaryResultRow key={index} result={result} name="Modifier group" />
                                        ))}
                                    {modifierOptions &&
                                        modifierOptions.map((result, index) => (
                                            <SummaryResultRow key={index} result={result} name="Modifier option" />
                                        ))}
                                </>
                            )}
                        </div>
                    </div>
                    {someItemsMissingContent && (
                        <Alert title="Some items are missing content" status={AlertStatus.WARNING}>
                            {`Some ${getMissingContentMessage({
                                modifierGroupsMissingContent,
                                productsMissingContent,
                            })} need to be reviewed before being able to be published.`}
                        </Alert>
                    )}
                </Row>
            )}

            {!displayFailed && !displaySuccess && (
                <Row className={styles.confirmRow}>
                    <p>Are you sure you want to import these items from POS to your catalogue?</p>
                    <ul>
                        {!!info.products.numParentsSelected && <li>{`${info.products.parentSummary}`}</li>}
                        {!!info.products.numChildrenSelected && <li>{`${info.products.childSummary}`}</li>}
                        {!!info.modifiers.numParentsSelected && <li>{`${info.modifiers.parentSummary}`}</li>}
                        {!!info.modifiers.numChildrenSelected && <li>{`${info.modifiers.childSummary}`}</li>}
                    </ul>
                    <p>
                        This may affect menus, services and categories. Any matching items that already exist will be
                        updated.
                    </p>
                    <div>
                        <Field
                            component={Checkbox}
                            disabled={uploadLoading}
                            name="includeProductModifierRelationships"
                            type="checkbox"
                            label="Include product modifier relationships"
                        />
                    </div>
                    <div>
                        <Alert status={AlertStatus.WARNING}>
                            This action cannot be undone. If you want to remove these items later, you must remove them
                            from the catalogue.
                        </Alert>
                    </div>
                </Row>
            )}
        </Modal>
    );
};

interface SummaryResultProps {
    result: SummaryResult;
    name: string;
}

export const SummaryResultRow = ({ result: { count, result }, name }: SummaryResultProps) => {
    return (
        <p className={styles.message}>
            {numberFormat.format(count)} {name}
            {simplePlural(count)} {count === 1 ? "has" : "have"} been {result.toLowerCase()}.
        </p>
    );
};
