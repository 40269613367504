import { createSelector } from "reselect";
import { EnhancedCatalogueModifierItem } from "..";
import { getEditModifier } from "./getEditModifier";
import { getModifierCatalogueItems } from "./getModifierCatalogueItems";

const compare = (a: EnhancedCatalogueModifierItem, b: EnhancedCatalogueModifierItem) => {
    const aName = a.displayName?.toLowerCase() || "";
    const bName = b.displayName?.toLowerCase() || "";

    if (a.hasNested !== b.hasNested) {
        if (!a.hasNested && b.hasNested) {
            return -1;
        }
        if (a.hasNested && !b.hasNested) {
            return 1;
        }
    }

    if (aName < bName) {
        return -1;
    }

    if (aName > bName) {
        return 1;
    }

    return 0;
};

// get all modifiers except the one being edited and enhance them with 'hasNested'
export const getEnhancedNonEditModifiers = createSelector(
    getModifierCatalogueItems,
    getEditModifier,
    (allModifiers, editModifier) => {
        if (!editModifier || !allModifiers) {
            return [];
        }

        const allModifierIds = allModifiers.map(({ id }) => id);

        return allModifiers
            .filter(({ id }) => id !== editModifier.id)
            .map((modifier) => {
                const hasNested = !!modifier.children?.find(
                    (option) => option.modifiers?.filter((id) => allModifierIds.includes(id))?.length
                );
                return {
                    ...modifier,
                    hasNested,
                };
            })
            .sort(compare) as EnhancedCatalogueModifierItem[];
    }
);
