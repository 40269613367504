import { opsApi } from "API";

export const savePriceLevels = async (locationId: string, priceListId: string, priceLevels: PriceLevelsInputModel) => {
    const res = await opsApi.graphQLQuery<any>(updateQuery, { locationId, priceListId, priceLevels });
    return res.data.bulkUpdatePriceLevels;
};

export interface PriceLevelsInputModel {
    productPriceLevels: PriceLevelInputModel[];
    modifierPriceLevels: PriceLevelInputModel[];
    productDeletions: PriceLevelInputModel[];
    modifierDeletions: PriceLevelInputModel[];
}

export interface PriceLevelInputModel {
    id?: string;
    price?: number;
    childId?: string;
}

const updateQuery = `
    mutation($locationId: ID!, $priceListId: ID!, $priceLevels: PriceLevelBulkUpdateInput!) {
        bulkUpdatePriceLevels(locationId: $locationId, priceListId: $priceListId, priceLevels: $priceLevels) 
    }
`;
