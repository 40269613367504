import { PosUISettings } from ".";

export const omnivoreUISettings: PosUISettings = {
    servicePosId: {
        visible: false,
    },
    surchargeSku: {
        visible: true,
        title: "Service Charge ID",
        subTitle:
            "The Omnivore Service Charge ID to associate with the surcharge. Required if a surcharge is included.",
    },
    priceListPosId: {
        visible: false,
    },
    taxPosId: {
        visible: false,
    },
};
