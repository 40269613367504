import * as React from "react";
import { FieldProps } from "formik";
import { Select } from "antd";
import { FieldErrors } from "components/forms";
import { ResolvedDisplayName } from "components/ResolvedDisplayName";

interface Option {
    id: string;
    displayName: string;
    internalName?: string;
}

export interface Props {
    name: string;
    options: Option[];
    mode?: "multiple" | "tags" | undefined;
    disabled: boolean;
    placeholder?: string;
}

export interface State {
    selectedItems: string[];
}

export class DataSelectItem extends React.Component<Props & FieldProps, State> {
    constructor(props: Props & FieldProps) {
        super(props);
        const {
            field: { value: selectedItems },
        } = this.props;

        this.state = {
            selectedItems,
        };
    }

    handleChange = (selectedItems: string[]) => {
        const {
            form,
            field: { name },
        } = this.props;

        form.setFieldValue(name, selectedItems);
        this.setState({ selectedItems });
        form.setFieldTouched(name, true);
    };

    render() {
        const {
            options,
            mode = "multiple",
            form,
            field: { name, onBlur },
            disabled,
            placeholder,
        } = this.props;
        const { selectedItems } = this.state;

        return (
            <FieldErrors name={name} form={form}>
                <Select
                    mode={mode}
                    placeholder={placeholder || `Select ${name} ...`}
                    value={selectedItems}
                    onChange={this.handleChange}
                    disabled={disabled}
                    onBlur={onBlur}
                    filterOption={(input, option) => {
                        const currentOption = options.find((o) => o.id === option?.value);
                        if (!currentOption) {
                            return false;
                        }
                        const displayName = currentOption.displayName || "";
                        const internalName = currentOption.internalName ? ` ${currentOption.internalName}` : "";
                        return `${displayName}${internalName}`.toLowerCase().indexOf(input?.trim().toLowerCase()) >= 0;
                    }}
                >
                    {options.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                            <ResolvedDisplayName item={item} />
                        </Select.Option>
                    ))}
                </Select>
            </FieldErrors>
        );
    }
}
