import styles from "./Menu.module.scss";

import { Chevron } from "core/components/icon/Chevron";
import { Icon } from "core/components/icon/Icon";
import { isParent, MenuItemParent, MenuNode } from "./types/Menu";
import { FocusEvent, ReactElement, useEffect, useRef } from "react";
import { useAccordion } from "common/hooks";

interface BaseMenuProps<T> {
    itemComponent: React.FC<MenuComponentProps<T>>;
    parentComponent: React.FC<MenuComponentProps<T>>;
    expandedKeys?: string[];
    getParentExpanded?(node: MenuItemParent<T>): boolean;
    onExpandedChanged?(expanded: boolean, node: MenuNode<T>): void;
    onFocus?(e: FocusEvent<HTMLElement>): void; // TODO not sure if this should be here
    expandEnabled?: boolean;
}

interface Props<T> extends BaseMenuProps<T> {
    menu: MenuNode<T>[];
}

interface MenuComponentProps<T> {
    level: number;
    node: MenuNode<T>;
    onFocus?(e: FocusEvent<HTMLElement>): void;
}

interface MenuElementProps<T> extends BaseMenuProps<T>, MenuComponentProps<T> {}

export interface MenuItemProps<T> extends MenuComponentProps<T> {}

export interface MenuItemParentProps<T> extends MenuComponentProps<T> {}

export function Menu<T>({ menu, onFocus, ...rest }: Props<T>): ReactElement {
    return (
        <ul className={styles.menu}>
            {menu.map((node) => {
                return <MenuElement node={node} key={node.id} level={0} onFocus={onFocus} {...rest} />;
            })}
        </ul>
    );
}

function MenuElement<T>({ level, node, ...rest }: MenuElementProps<T>) {
    const Component = isParent(node) ? ParentItem : Item;
    return <Component level={level} node={node} {...rest} key={node.id} />;
}

function ParentItem<T>({
    node,
    itemComponent,
    parentComponent: Component,
    getParentExpanded,
    onFocus,
    level,
    expandedKeys,
    onExpandedChanged,
    expandEnabled = true,
}: MenuElementProps<T>) {
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const contentRef = useRef<HTMLUListElement | null>(null);

    const parentNode = isParent(node) ? node : null;

    const { expanded, setExpanded, contentStyle } = useAccordion(contentRef, {
        startExpanded: expandedKeys?.includes(node.id) ?? false,
    });

    useEffect(() => {
        setExpanded(expandedKeys?.includes(node.id) ?? false);
    }, [expandedKeys, getParentExpanded, node.id, parentNode, setExpanded]);

    const onClick = () => {
        onExpandedChanged?.(!expanded, node);
    };

    if (parentNode === null) {
        return null;
    }

    const canExpand = !!parentNode.children.length;

    return (
        <li className={styles.container}>
            <span className={canExpand ? styles[`parentMenuItem${level}`] : styles[`item${level}`]}>
                {canExpand && (
                    <button
                        aria-expanded={expanded}
                        aria-haspopup={true}
                        className={styles.submenuButton}
                        disabled={!expandEnabled}
                        onClick={onClick}
                        onFocus={onFocus}
                        ref={buttonRef}
                    >
                        <Icon className={expanded ? styles.submenuArrowExpanded : styles.submenuArrowCollapsed}>
                            <Chevron />
                        </Icon>
                    </button>
                )}
                <Component node={parentNode} level={level} onFocus={onFocus} />
            </span>

            <ul className={styles.submenuContent} ref={contentRef} style={contentStyle} aria-hidden={!expanded}>
                {parentNode.children.map((node) => (
                    <MenuElement
                        expandedKeys={expandedKeys}
                        expandEnabled={expandEnabled}
                        getParentExpanded={getParentExpanded}
                        itemComponent={itemComponent}
                        key={node.id}
                        level={level + 1}
                        node={node}
                        onExpandedChanged={onExpandedChanged}
                        onFocus={onFocus}
                        parentComponent={Component}
                    />
                ))}
            </ul>
        </li>
    );
}

function Item<T>({ node, level, itemComponent: Component, onFocus }: MenuElementProps<T>) {
    return (
        <li role="menuitem" className={styles.container}>
            <span className={styles[`item${level}`]}>
                <Component node={node} level={level} onFocus={onFocus} />
            </span>
        </li>
    );
}
