import { Button } from "core/components/button";
import { PageError } from "core/components/pageError";
import styles from "./CataloguePage.module.scss";

interface Props {
    retry: () => void;
}

export const CatalogueLoadError = ({ retry }: Props) => {
    return (
        <div className={styles.loadContainer}>
            <PageError
                heading="Oh no! Something went wrong."
                message="Catalogue failed to load. Try reloading this page or try again later."
                actions={
                    <>
                        <Button onClick={retry}>Retry</Button>
                    </>
                }
            />
        </div>
    );
};
