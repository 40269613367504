import { message } from "antd";
import { opsApiFetch } from "API/fetch";
import { downloadBlob } from "common/utility/fileUtils";

export async function fetchDownloadMembershipMembers(
    locationId: string,
    programId: string,
    fileName: string,
    cb: () => void
) {
    try {
        const res = await opsApiFetch(`/reports/locations/${locationId}/memberships/${programId}`);
        if (!res.ok) {
            throw new Error("Failed to download membership");
        }
        const blob = await res.blob();
        downloadBlob(blob, fileName);
    } catch {
        message.error("Download failed");
    } finally {
        cb();
    }
}
