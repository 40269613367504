import { withSvg } from "core/components/svg/Svg";

export const MultiplePromoCodes = withSvg(
    12,
    12
)(() => (
    <>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.125 2.25C0.917893 2.25 0.75 2.41789 0.75 2.625C0.75 2.83211 0.917893 3 1.125 3C1.33211 3 1.5 2.83211 1.5 2.625C1.5 2.41789 1.33211 2.25 1.125 2.25ZM0 2.625C0 2.00368 0.50368 1.5 1.125 1.5C1.74632 1.5 2.25 2.00368 2.25 2.625C2.25 3.24632 1.74632 3.75 1.125 3.75C0.50368 3.75 0 3.24632 0 2.625Z"
            fill="#4D5358"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.75 2.625C3.75 2.41789 3.91789 2.25 4.125 2.25H11.625C11.8321 2.25 12 2.41789 12 2.625C12 2.83211 11.8321 3 11.625 3H4.125C3.91789 3 3.75 2.83211 3.75 2.625Z"
            fill="#4D5358"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.125 5.625C0.917893 5.625 0.75 5.79289 0.75 6C0.75 6.20711 0.917893 6.375 1.125 6.375C1.33211 6.375 1.5 6.20711 1.5 6C1.5 5.79289 1.33211 5.625 1.125 5.625ZM0 6C0 5.37868 0.50368 4.875 1.125 4.875C1.74632 4.875 2.25 5.37868 2.25 6C2.25 6.62132 1.74632 7.125 1.125 7.125C0.50368 7.125 0 6.62132 0 6Z"
            fill="#4D5358"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.75 6C3.75 5.79289 3.91789 5.625 4.125 5.625H11.625C11.8321 5.625 12 5.79289 12 6C12 6.20711 11.8321 6.375 11.625 6.375H4.125C3.91789 6.375 3.75 6.20711 3.75 6Z"
            fill="#4D5358"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.125 9C0.917893 9 0.75 9.16789 0.75 9.375C0.75 9.58211 0.917893 9.75 1.125 9.75C1.33211 9.75 1.5 9.58211 1.5 9.375C1.5 9.16789 1.33211 9 1.125 9ZM0 9.375C0 8.75368 0.50368 8.25 1.125 8.25C1.74632 8.25 2.25 8.75368 2.25 9.375C2.25 9.99632 1.74632 10.5 1.125 10.5C0.50368 10.5 0 9.99632 0 9.375Z"
            fill="#4D5358"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.75 9.375C3.75 9.16789 3.91789 9 4.125 9H11.625C11.8321 9 12 9.16789 12 9.375C12 9.58211 11.8321 9.75 11.625 9.75H4.125C3.91789 9.75 3.75 9.58211 3.75 9.375Z"
            fill="#4D5358"
        />
    </>
));
