import { AppState } from "features";
import { KeyedLoaded } from "common/loader";
import { Dispatch, Action } from "redux";

export interface EditActionCreators<TData, TKey = string> {
    loading(key: TKey | undefined): Action;
    loaded(key: TKey | undefined, data: TData): Action;
    loadFailed(key: TKey, error: any): Action;
}

export function scaffoldEditAction<TData, TKey = string>(
    key: TKey,
    stateSelector: (state: AppState) => KeyedLoaded<TData, TKey | undefined>,
    actionCreators: EditActionCreators<TData, TKey>,
    editAction: () => Promise<TData>
) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
        const editState = stateSelector(getState());
        if (editState.status === "loading") {
            return;
        }

        try {
            dispatch(actionCreators.loading(key));

            const data = await editAction();

            dispatch(actionCreators.loaded(key, data));
        } catch (e) {
            if (process.env.NODE_ENV === "development") {
                console.error(e);
            }
            dispatch(actionCreators.loadFailed(key, e));
        }
    };
}
