import { opsApi } from "API";
import { mapStripeAccount } from ".";
import { StripeAccount } from "../types/StripeAccount";
import { emptyStripeAccount, GraphQLPayload } from "./types";

export const createStripeAccount = async (locationId: string): Promise<StripeAccount> => {
    try {
        const res = await opsApi.graphQLQuery<GraphQLPayload>(createQuery, { locationId });
        return mapStripeAccount(res.data.createStripeAccount || emptyStripeAccount());
    } catch (e) {
        console.log(e);
        throw e;
    }
};

const createQuery = `
    mutation($locationId: ID!) {
        createStripeAccount(locationId: $locationId) {
            accountId
            chargesEnabled
            payoutsEnabled
            detailsSubmitted
            accountCreationLink {
                url
                expiresAt
            }
        }
    }
`;
