import { FeatureConfiguration } from "features";
import { withPermissions } from "features/location/components/withPermission";
import { reducer } from "./reducers";
import * as actions from "./actions";

import { ModalCrudRouteContainer, ListComponent, EditComponent } from "common/scaffolding/components/ModalCrudRoute";
import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { ListWrapper } from "common/scaffolding/components/ListWrapper";
import { ListMembershipsPage } from "./components/ListMembershipsPage";
import { ModalEditWrapper } from "common/scaffolding/components/EditWrapper";
import { EditMembershipPage } from "./components/EditMembershipPage";

export { actions };

const List: ListComponent = {
    route: "",
    component: withPermissions(ListMembershipsPage, "membership:read"),
    contentWidth: ContentWidth.STANDARD,
    wrapper: ListWrapper,
};

const Edit: EditComponent = {
    route: ":id",
    component: withPermissions(EditMembershipPage, "membership:update"),
    contentWidth: ContentWidth.STANDARD,
    wrapper: ModalEditWrapper,
};

export default function (config: FeatureConfiguration) {
    config.reducers.memberships = reducer;

    config.addRoutes(config.routes.locationRoutes, [
        <ModalCrudRouteContainer
            name="membership"
            key="membership"
            listComponent={List}
            editComponents={[Edit]}
            listWidth={22}
            section={null}
        />,
    ]);
}
