import { opsApi } from "API";
import { Group } from "features/group";
import { GraphQLItem } from "common/scaffolding/types";
import { alcoholicDrinksRestrictionQueryFields } from "features/location/api/fetchLocation";
import { AlcoholicDrinksRestriction } from "features/location/types/AlcoholicDrinksRestriction";
import { trayChargeQueryFields } from "features/trayCharge/api";
import { GraphQLTrayChargeQueryModel } from "features/trayCharge/types";
import { MarketingOptions, TipOptions } from "../types";
import { venueServiceFeeQueryFields } from "features/venueServiceFee/api";
import { GraphQLVenueServiceFeeQueryModel } from "features/venueServiceFee/types";
import { OrderFlowType } from "../types/OrderFlow";
import { SplitPaymentOption } from "../types/SplitPaymentOptions";

export const editLocationSettings = async (locationId: string): Promise<GraphQLLocationSettings> => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId });
    return res.data.location;
};

type GraphQLPayload = { settings: GraphQLLocationSettings };

export interface GraphQLLocationSettings extends GraphQLItem {
    ageRestriction?: number;
    allowNotesToKitchen: boolean;
    allowTips: boolean;
    confirmEighteenPlus: boolean;
    defaultTipLevels: number[];
    enableAnotherRound: boolean;
    enableDietaryNotifier: boolean;
    enableGroupTabs: boolean;
    enableNoTip: boolean;
    enableQuickSell: boolean;
    quickSellDrinksOnly: boolean;
    enableSurcharges: boolean;
    enableSurchargesFeature: boolean;
    facebookPixelId?: string;
    fromTheVenue?: string;
    fromTheVenueTitle?: string;
    group?: Group;
    idleTimeoutMinutes: number;
    isBuzzer: boolean;
    isLiveOrdersEnabled: boolean;
    marketingOptions?: MarketingOptions;
    mustAcceptAllergenNotice?: boolean;
    orderBatchTimeSeconds?: number;
    drinkOrderBatchTimeSeconds?: number;
    foodOrderBatchTimeSeconds?: number;
    sectionsWithDocketMerging: string[];
    slug: string;
    tipOptions: TipOptions;
    unusedPartyTimeoutMinutes: number;
    groupTabGratuityFactor?: number;
    enablePosSync?: boolean;
    trayCharge?: GraphQLTrayChargeQueryModel;
    venueServiceFee?: GraphQLVenueServiceFeeQueryModel;
    alcoholicDrinksRestriction?: AlcoholicDrinksRestriction;
    enableProductVideos: boolean;
    enableAbsorbMerchantFee: boolean;
    orderFlow: OrderFlowType;
    splitPaymentOptions: SplitPaymentOption[];
}

const query = `
    query($locationId: ID!) {
        location(locationId: $locationId) {
            id,
            allowNotesToKitchen,
            confirmEighteenPlus,
            ageRestriction,
            slug,
            isBuzzer,
            orderBatchTimeSeconds,
            drinkOrderBatchTimeSeconds,
            foodOrderBatchTimeSeconds,
            unusedPartyTimeoutMinutes,
            allowTips,
            marketingOptions {
                enabled,
                prompt
            },
            mustAcceptAllergenNotice,
            fromTheVenue,
            fromTheVenueTitle,
            sectionsWithDocketMerging,
            idleTimeoutMinutes,
            group {
                id,
                displayName,
                authInfo {
                    permissions
                },
                marketingOptions {
                    enabled,
                    prompt
                }
            },
            isLiveOrdersEnabled,
            enableAbsorbMerchantFee,
            enableAnotherRound,
            enableQuickSell,
            quickSellDrinksOnly,
            facebookPixelId,
            enableSurcharges,
            enableGroupTabs,
            enableNoTip,
            defaultTipLevels,
            tipOptions {
                defaultIndex
                levels
                showNoTip
            },
            groupTabGratuityFactor,
            enablePosSync,
            trayCharge {
                ${trayChargeQueryFields}
            },
            venueServiceFee {
                ${venueServiceFeeQueryFields}
            },
            alcoholicDrinksRestriction {
                ${alcoholicDrinksRestrictionQueryFields}
            },
            enableProductVideos,
            orderFlow,
            splitPaymentOptions
        }
    }
`;
