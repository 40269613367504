// Note localisation doesn't work in tests because of Intl.DateTimeFormat bugs in node v12

export function getShortDateFormatter(locale: string) {
    let formatter = new Intl.DateTimeFormat(locale, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });

    return {
        format: (date: Date) => formatter.format(date),
    };
}

export function getShortDatePlaceholder(locale: string) {
    const formatted = getShortDateFormatter(locale).format(new Date("2022/03/31"));

    return formatted.replace("2022", "YYYY").replace("03", "MM").replace("31", "DD");
}

export enum DaysOfWeek {
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 4,
    THURSDAY = 8,
    FRIDAY = 16,
    SATURDAY = 32,
    SUNDAY = 64,
}

export const DAYS_OF_WEEK = [
    DaysOfWeek.MONDAY,
    DaysOfWeek.TUESDAY,
    DaysOfWeek.WEDNESDAY,
    DaysOfWeek.THURSDAY,
    DaysOfWeek.FRIDAY,
    DaysOfWeek.SATURDAY,
    DaysOfWeek.SUNDAY,
];

/** number representing all days of week */
export const ALL_DAYS = DAYS_OF_WEEK.reduce((total, n) => total | n, 0);
