import { AlcoholicDrinksRestriction } from "features/location/types/AlcoholicDrinksRestriction";
import * as Yup from "yup";

const minLimitDrinksValue = 1;
const maxLimitDrinksValue = 10;
const limitDrinksMsg = `Must be between ${minLimitDrinksValue} and ${maxLimitDrinksValue}`;

export const alcoholicDrinksRestrictionSchema = Yup.object<AlcoholicDrinksRestriction>().shape({
    enabled: Yup.boolean(),
    applyAcrossAllServices: Yup.boolean(),
    maxDrinksPerOrder: Yup.number()
        .typeError("Valid number required")
        .min(minLimitDrinksValue, limitDrinksMsg)
        .max(maxLimitDrinksValue, limitDrinksMsg),
});
