import { TextInput } from "../../TextInput";
import { PosFieldProps } from "../PosFieldProps";
import { PosTextFieldCommon } from "features/location";

export const PosTextField = ({
    form,
    field,
    meta,
    definition: { placeholder },
    disabled,
}: PosFieldProps<PosTextFieldCommon>) => (
    <TextInput
        form={form}
        field={field}
        meta={meta}
        placeholder={placeholder || undefined}
        disabled={disabled === undefined ? false : disabled}
        autoComplete="off"
    />
);
