import { FeatureConfiguration } from "features";
import { reducer } from "./reducers";
import * as actions from "./actions";
import { withPermissions } from "features/location/components/withPermission";
import { ModalCrudRouteContainer, ListComponent } from "common/scaffolding/components/ModalCrudRoute";
import { ContentWidth } from "common/scaffolding/types/ContentWidth";
import { ListAuditLogEntriesPageContainer } from "./containers/ListAuditLogEntriesPageContainer";
import { ListWrapper } from "common/scaffolding/components/ListWrapper";

export { actions };
export * from "./types";

const List: ListComponent = {
    route: "",
    component: withPermissions(ListAuditLogEntriesPageContainer, "auditlog:read"),
    contentWidth: ContentWidth.STANDARD,
    wrapper: ListWrapper
};

export default function (config: FeatureConfiguration) {
    config.reducers.auditlog = reducer;

    config.addRoutes(config.routes.locationRoutes, [
        <ModalCrudRouteContainer
            name="auditlog"
            key="auditlog"
            section={null}
            listComponent={List}
            listWidth={22}
        />,
    ]);
}
