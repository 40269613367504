import classNames from "classnames";
import { forwardRef, ReactElement } from "react";

interface Props {
    as?: React.ElementType;
    children?: React.ReactNode;
    className?: string;
}

interface ContainerProps extends Props {
    containerClass: string;
}

interface GridProps extends Props {
    gridClass: string;
}

// ideally this would somehow connect with what's avail in grid config/css, for now just hard code
interface MediaQueryProp {
    xs?: number;
    md?: number;
    xl?: number;
}

interface ColProps extends Props {
    cols?: number | MediaQueryProp;
    start?: number | MediaQueryProp;
    classPrefix?: string;
}

const parseMediaQueryProp = (prop: number | MediaQueryProp): MediaQueryProp => {
    // if we got object return it
    if (typeof prop === "object" && prop !== null) {
        return prop;
    }

    // at this point, prop should be number. Use it as smallest MQ
    return {
        xs: prop,
    };
};

export const Container = forwardRef<ReactElement, ContainerProps & Record<string, any>>(
    ({ children, className, as: Component = "div", containerClass = "grid-container", ...rest }, ref) => (
        <Component className={classNames([containerClass, className])} ref={ref} {...rest}>
            {children}
        </Component>
    )
);

export const Grid = forwardRef<ReactElement, GridProps & Record<string, any>>(
    ({ children, className, as: Component = "div", gridClass = "grid", ...rest }, ref) => (
        <Component className={classNames([gridClass, className])} ref={ref} {...rest}>
            {children}
        </Component>
    )
);

export const Col = forwardRef<ReactElement, ColProps & Record<string, any>>(
    ({ children, className, as: Component = "div", cols = 12, start = {}, classPrefix = "col-", ...rest }, ref) => {
        const colsMQ = parseMediaQueryProp(cols);
        const startMQ = parseMediaQueryProp(start);

        const classes = classNames({
            [`${classPrefix}xs-${colsMQ.xs || "12"}`]: true,
            [`${classPrefix}md-${colsMQ.md}`]: colsMQ.md,
            [`${classPrefix}xl-${colsMQ.xl}`]: colsMQ.xl,
            [`${classPrefix}xs-start-${startMQ.xs}`]: startMQ.hasOwnProperty("xs"),
            [`${classPrefix}md-start-${startMQ.md}`]: startMQ.hasOwnProperty("md"),
            [`${classPrefix}xl-start-${startMQ.xl}`]: startMQ.hasOwnProperty("xl"),
            [`${className}`]: className,
        });

        return (
            <Component className={classes} ref={ref} {...rest}>
                {children}
            </Component>
        );
    }
);
