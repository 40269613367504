import * as React from "react";
import { FieldProps } from "formik";
import { Checkbox } from "antd";

export interface CheckBoxProps {
    label?: string;
    style?: React.CSSProperties;
    disabled: boolean;
    id?: string;
}

export class CheckboxInput extends React.Component<FieldProps & CheckBoxProps> {
    render() {
        const {
            label,
            style,
            field: { name, value, onChange },
            disabled,
            id,
        } = this.props;

        return (
            <Checkbox id={id} name={name} checked={value} onChange={onChange} style={style} disabled={disabled}>
                {label}
            </Checkbox>
        );
    }
}
