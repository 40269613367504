import { PosFieldProps } from "../PosFieldProps";
import { AppState } from "features";
import { PosLookup } from "features/posConfiguration/types/PosLookup";
import { Loaded } from "common/loader";
import { DataProps } from "types/utils";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { PosTextField } from "./PosTextField";
import { SearchInput, SelectItem } from "../../SearchInput";
import { PosLookupFieldDefinition } from "features/location";
import { fetchLookup } from "features/posConfiguration/actions/fetchLookup";
import { useMemo } from "react";
import { getPosLookup } from "features/posConfiguration/selectors/getPosLookup";

interface PosLookupFieldProps extends PosFieldProps<PosLookupFieldDefinition> {
    lookupState: Loaded<PosLookup>;
}

const PosLookupFieldInner = ({ form, field, meta, definition, disabled, lookupState }: PosLookupFieldProps) => {
    const dispatch = useDispatch();

    const [options, setOptions] = useState<SelectItem[]>([]);

    useEffect(() => {
        if (lookupState.status === "unloaded") {
            dispatch(fetchLookup(definition.lookup));
        }

        if (lookupState.status !== "loaded") {
            return;
        }

        setOptions(
            lookupState.data.items.map((i) => ({
                displayName: `${i.value} (${i.key})`,
                id: i.key,
            }))
        );
    }, [lookupState, definition, dispatch]);

    const allowClear = useMemo(() => {
        return !definition.validation || new RegExp(definition.validation).test("");
    }, [definition]);

    if (lookupState.status === "failed") {
        return (
            <PosTextField
                form={form}
                field={field}
                meta={meta}
                definition={definition}
                disabled={disabled === undefined ? false : disabled}
            />
        );
    }

    return (
        <SearchInput
            name={field.name}
            options={options}
            form={form}
            field={field}
            meta={meta}
            disabled={disabled}
            loading={lookupState.status === "loading"}
            allowClear={allowClear}
        />
    );
};

function mapStateToProps(
    state: AppState,
    ownProps: PosFieldProps<PosLookupFieldDefinition>
): DataProps<PosLookupFieldProps> {
    const lookupState = getLookupState(state, ownProps.definition.lookup);

    return {
        ...ownProps,
        lookupState,
    };
}

export const PosLookupField = connect(mapStateToProps)(PosLookupFieldInner);

function getLookupState(state: AppState, lookupType: string | null): Loaded<PosLookup> {
    if (!lookupType) {
        return {
            status: "unloaded",
        };
    }

    return getPosLookup(state)(lookupType);
}
