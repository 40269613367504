import { StatBar } from "common/icons";
import { AnalyticsLayoutConfig } from "features/location";
import { NavGroup, NavItem, NavItemGroup } from "../types/NavMenu";

export const mapAnalyticsLayoutConfig = (analyticsConfig: AnalyticsLayoutConfig) => {
    return {
        text: "Analytics",
        navItemGroups: analyticsConfig.folders.map(
            (folder) =>
                ({
                    text: folder.displayName,
                    icon: StatBar,
                    children: folder.pages
                        .filter((p) => p.tabs.length > 0)
                        .map(
                            (page) =>
                                ({
                                    text: page.displayName,
                                    permission: "metabase:read",
                                    route: `analytics/${folder.slug}/${page.slug}`,
                                } as NavItem)
                        ),
                } as NavItemGroup)
        ),
    } as NavGroup;
};
