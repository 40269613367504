import { createSelector } from "reselect";
import {
    CatalogueItem,
    CatalogueItemFilterCallback,
    CatalogueItemSelections,
    ItemValidationStatus,
    SkuFilter,
    StatusFilterValue,
    SyncStatusFilterValue,
    Status,
    MissingContentFilter,
    IsLinkedFilter,
    BooleanOrEitherFilter,
} from "../types";
import { getProductCatalogueItems } from "./getProductCatalogueItems";
import {
    getActiveCategoryIds,
    getActiveStatuses,
    getActiveSyncStatuses,
    getIsLinkedFilter,
    getMissingContentFilter,
    getSkuFilter,
    getTextFilter,
} from "./getFilters";
import { UNASSIGNED_CATEGORY_VALUE } from "./getCategoryFilterOptions";
import { getModifierCatalogueItems } from "./getModifierCatalogueItems";
import { normaliseText } from "common/utility/StringUtils";

export const getFilteredProducts = createSelector(
    getProductCatalogueItems,
    getActiveCategoryIds,
    getSkuFilter,
    getMissingContentFilter,
    getIsLinkedFilter,
    getActiveStatuses,
    getActiveSyncStatuses,
    getTextFilter,
    (
        products,
        activeCategoryIds,
        skuFilter,
        missingContentFilter,
        isLinkedFilter,
        activeStatuses,
        activeSyncStatuses,
        textFilter
    ): CatalogueItem[] => {
        const productFilters: CatalogueItemFilterCallback[] = [
            isIncludedByCategory(activeCategoryIds),
            isIncludedBySkuFilter(skuFilter),
            isIncludedByMissingContentFilter(missingContentFilter),
            isIncludedByIsLinkedFilter(isLinkedFilter),
            isIncludedByStatusFilter(activeStatuses, activeSyncStatuses),
            isIncludedByTextFilter(textFilter),
        ];

        return products.filter((product) => productFilters.every((filter) => filter(product)));
    }
);

export const getFilteredModifierGroups = createSelector(
    getModifierCatalogueItems,
    getSkuFilter,
    getMissingContentFilter,
    getIsLinkedFilter,
    getActiveStatuses,
    getActiveSyncStatuses,
    getTextFilter,
    (
        modifierGroups,
        skuFilter,
        missingContentFilter,
        isLinkedFilter,
        activeStatuses,
        activeSyncStatuses,
        textFilter
    ): CatalogueItem[] => {
        const productFilters: CatalogueItemFilterCallback[] = [
            isIncludedBySkuFilter(skuFilter),
            isIncludedByMissingContentFilter(missingContentFilter),
            isIncludedByIsLinkedFilter(isLinkedFilter),
            isIncludedByStatusFilter(activeStatuses, activeSyncStatuses),
            isIncludedByTextFilter(textFilter),
        ];

        return modifierGroups.filter((modifierGroup) => productFilters.every((filter) => filter(modifierGroup)));
    }
);

export const getFilteredProductsSelection = createSelector(getFilteredProducts, (products) => {
    return getItemsSelection(products);
});

export const getFilteredModifiersSelection = createSelector(getFilteredModifierGroups, (modifiers) => {
    return getItemsSelection(modifiers);
});

function isIncludedByCategory(activeCategoryIds: string[]) {
    if (activeCategoryIds.length === 0) {
        return () => true;
    }

    const hasUnassigned = activeCategoryIds.includes(UNASSIGNED_CATEGORY_VALUE);

    return (product: CatalogueItem) => {
        if (hasUnassigned && !product.categories?.length) {
            return true;
        }

        return (
            product.categories?.some((category) => {
                return activeCategoryIds.includes(category.id);
            }) || false
        );
    };
}

function isIncludedBySkuFilter(filter: SkuFilter): CatalogueItemFilterCallback {
    return applyBooleanOrEitherFilter(filter, isMissingSku, true);
}

function isIncludedByMissingContentFilter(filter: MissingContentFilter): CatalogueItemFilterCallback {
    return applyBooleanOrEitherFilter(filter, isMissingContent);
}

function isIncludedByIsLinkedFilter(filter: IsLinkedFilter): CatalogueItemFilterCallback {
    return applyBooleanOrEitherFilter(filter, isLinked);
}

function isMissingSku(item: CatalogueItem) {
    return item.children?.length ? item.children.some((c) => !c.sku) : !item.sku;
}

function isMissingContent(item: CatalogueItem) {
    return item.status === Status.MissingContent;
}

function isLinked(item: CatalogueItem) {
    return item.isLinked;
}

function isIncludedByStatusFilter(
    activeStatuses: StatusFilterValue[],
    activeSyncStatuses: SyncStatusFilterValue[]
): CatalogueItemFilterCallback {
    if (activeStatuses.length === 0 && activeSyncStatuses.length === 0) {
        return () => true;
    }

    return (item) => {
        if (includesStatus(item, activeStatuses)) {
            return true;
        }

        if (activeSyncStatuses.length === 0) {
            return false;
        }

        if (item.children?.length) {
            return item.children.some((child) => includesSyncStatus(child, activeSyncStatuses));
        }

        return includesSyncStatus(item, activeSyncStatuses);
    };
}

function includesStatus(item: CatalogueItem, activeStatuses: StatusFilterValue[]) {
    if (item.status === undefined || item.status === null) {
        return false;
    }

    return activeStatuses.includes(item.status);
}

function includesSyncStatus(item: CatalogueItem, activeSyncStatuses: SyncStatusFilterValue[]) {
    const itemSyncFilterStatus = getSyncFilterStatus(item.validationStatus);

    return (itemSyncFilterStatus && activeSyncStatuses.includes(itemSyncFilterStatus)) || false;
}

function isIncludedByTextFilter(searchTerm: string): CatalogueItemFilterCallback {
    if (!searchTerm) {
        return () => true;
    }

    return (item) => {
        if (itemHasSearchTerm(item, searchTerm)) {
            return true;
        }

        if (!item.children) {
            return false;
        }

        return item.children.some((child) => itemHasSearchTerm(child, searchTerm));
    };
}

function itemHasSearchTerm(item: CatalogueItem, searchTerm: string) {
    const search = normaliseText(searchTerm);

    const displayName = normaliseText(item.displayName || "");

    const internalName = normaliseText(item.internalName || "");

    const sku = normaliseText(item.sku || "");

    return displayName.includes(search) || internalName.includes(search) || sku.includes(search);
}

function getItemsSelection(items: CatalogueItem[]) {
    return items.reduce<CatalogueItemSelections>((prev, curr) => {
        if (!curr.id) {
            return prev;
        }
        prev[curr.id] = curr.children?.length ? curr.children.map(({ id }) => id!) : true;

        return prev;
    }, {});
}

function applyBooleanOrEitherFilter(
    filter: BooleanOrEitherFilter,
    filterFn: (item: CatalogueItem) => boolean,
    invert = false
) {
    const { value } = filter;

    if ((value === true && invert === false) || (value === false && invert === true)) {
        return (item: CatalogueItem) => filterFn(item);
    }

    if ((value === false && invert === false) || (value === true && invert === true)) {
        return (item: CatalogueItem) => !filterFn(item);
    }

    return () => true;
}

export function getSyncFilterStatus(status: ItemValidationStatus): SyncStatusFilterValue | undefined {
    switch (status) {
        case "VALID":
            return "synced";
        case "SKIPPED":
            return "skipped";
        case "INVALID":
        case "NONE":
        case "MISSING":
            return "failed";
        default:
            return undefined;
    }
}
