import * as Yup from "yup";
import { StatementDescriptors } from "../types/StatementDescriptors";

export const PAYOUT_STATEMENT_DESCRIPTOR_MAX_LENGTH = 9;
export const PAYMENT_STATEMENT_DESCRIPTOR_MAX_LENGTH = 16;

const getStatementDescriptorSchema = (name: string, max: number, min: number = 0) =>
    Yup.string()
        .required(`${name} is required`)
        .max(max, `${name} must be no more than ${max} characters`)
        .min(min, `${name} must be at least ${min} characters`)
        .matches(/^[^<>\\'"*]*$/, `${name} cannot contain the following special characters < > \\ ' " *`)
        .matches(/^[a-zA-Z0-9 &.!@#$%^&()_+\-=\[\]{};:|,?]*$/, `${name} can only contain Latin characters`)
        .matches(/[a-z]{1}/i, `${name} must contain at least one letter`);

export const statementDescriptorsSchema = Yup.object<StatementDescriptors>().shape({
    payoutStatementDescriptor: getStatementDescriptorSchema(
        "Payout descriptor",
        PAYOUT_STATEMENT_DESCRIPTOR_MAX_LENGTH
    ),
    paymentStatementDescriptor: getStatementDescriptorSchema(
        "Payment descriptor",
        PAYMENT_STATEMENT_DESCRIPTOR_MAX_LENGTH,
        5
    ),
});
