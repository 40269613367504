import { ITelemetryPlugin } from "@microsoft/applicationinsights-core-js";
import { ReduxActionPlugin } from "../types/ReduxActionPlugin";

export function findReduxActionPlugins<T>(
    extensions: (ITelemetryPlugin & Partial<ReduxActionPlugin>)[]
): ReduxActionPlugin[] {
    const ret: ReduxActionPlugin[] = [];

    for (let ext of extensions) {
        if (isReduxActionPlugin(ext)) {
            ret.push(ext);
        }
    }

    return ret;
}

function isReduxActionPlugin(ext: Partial<ReduxActionPlugin>): ext is ReduxActionPlugin {
    return !!ext.handleReduxAction;
}
