import { LocationSummary } from "..";
import { region } from "features/region";
import { LocationListContainer } from "../containers/LocationListContainer";
import { Popup } from "core/components/popup";
export interface Props {
    restaurantLocation: LocationSummary | null;
}

export const LocationSelector = ({ restaurantLocation }: Props) => {
    if (!region.hasAccess() || !restaurantLocation) {
        return null;
    }

    return (
        <Popup
            buttonLabel={restaurantLocation ? restaurantLocation.displayName : "Select location..."}
            offset={{ x: -4, y: 4 }}
            align="bottom-left"
            clickInsideCloses={false}
        >
            <LocationListContainer />
        </Popup>
    );
};
