import { validationEnabledFn } from "features/locationSettings/schema/ValidationOptions";
import { VenueServiceFee } from "features/venueServiceFee/types";
import * as Yup from "yup";

export const getVenueServiceFeeSchema = () => {
    const { minPercentage, maxPercentage, percentageRequiredMsg, percentageRangeMsg } = getPercentageRules();

    return Yup.object<VenueServiceFee>().shape({
        id: Yup.string(),
        enabled: Yup.boolean(),
        percentage: Yup.number()
            .nullable(true)
            .when(["id", "enabled"], {
                is: validationEnabledFn,
                then: Yup.number()
                    .typeError("Valid number required")
                    .required(percentageRequiredMsg)
                    .min(minPercentage, percentageRangeMsg)
                    .max(maxPercentage, percentageRangeMsg),
            }),
    });
};

function getPercentageRules() {
    const minPercentage = 0.01;
    const maxPercentage = 99.99;
    const percentageRequiredMsg = `A venue service fee between ${minPercentage}% and ${maxPercentage}% is required.`;
    const percentageRangeMsg = `Venue service fee must be between ${minPercentage}% and ${maxPercentage}%.`;
    return {
        minPercentage,
        maxPercentage,
        percentageRequiredMsg,
        percentageRangeMsg,
    };
}
