import { PropsWithChildren, ReactElement, ReactNode } from "react";
import { ActionFooter, DeleteButton, DuplicateButton, Position, StatusMessage } from ".";
import { Button, Props as ButtonProps } from "core/components/button";

export type SubmitMode = "save" | "clone" | "archive";

export interface DeletePrompt {
    title?: string;
    deleteLabel?: string;
    children?: ReactNode;
}

interface Props {
    saving: boolean;
    submitMode: SubmitMode;
    showDuplicate?: boolean;
    showDelete?: boolean;
    showSave?: boolean;
    showStatus?: boolean;
    saveProps?: PropsWithChildren<ButtonProps & Record<string, any>>;
    duplicateProps?: PropsWithChildren<ButtonProps & Record<string, any>>;
    deleteProps?: PropsWithChildren<ButtonProps & Record<string, any>>;
    forceDirty?: boolean;
    position?: Position;
    confirmDelete?: DeletePrompt | boolean;
}

export const CrudActionFooter = ({
    saving,
    submitMode = "save",
    showDuplicate = true,
    showDelete = true,
    showSave = true,
    showStatus = true,
    saveProps,
    duplicateProps,
    deleteProps,
    forceDirty,
    position,
    confirmDelete,
}: Props): ReactElement => {
    return (
        <ActionFooter forceDirty={forceDirty} position={position}>
            {showDuplicate && <DuplicateButton buttonProps={duplicateProps} saving={saving} submitMode={submitMode} />}
            {showDelete && (
                <DeleteButton
                    buttonProps={deleteProps}
                    saving={saving}
                    submitMode={submitMode}
                    confirmDelete={confirmDelete}
                />
            )}
            {showStatus && <StatusMessage forceDirty={forceDirty} />}
            {showSave && (
                <Button loading={submitMode === "save" && saving} type="submit" {...saveProps}>
                    {saveProps?.children ? saveProps.children : "Save"}
                </Button>
            )}
        </ActionFooter>
    );
};
