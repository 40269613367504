import { createAction } from "../reducers/edit";
import { scaffoldSaveAction } from "../../../common/scaffolding/actions/scaffoldSaveAction";
import { HyperwalletUserUpdateModel, saveHyperwalletUser } from "../api/saveHyperwalletUser";
import { HyperwalletUser } from "../types/HyperwalletUser";
import { AppDispatch } from "features";
import { edit } from ".";

export const save = (
    location: string,
    hyperwalletUser: HyperwalletUser,
    isNew: boolean,
    callback?: () => void,
    clone: boolean = false
) =>
    scaffoldSaveAction(
        (state) => state.paymentUser.edit,
        createAction,
        async () => {
            const hyperwalletUserUpdate = mapUpdateModel(hyperwalletUser);
            return await saveHyperwalletUser(location, hyperwalletUserUpdate, isNew);
        },
        undefined,
        clone,
        (id: string, dispatch: AppDispatch) => {
            dispatch(edit(location));
            return;
        },
        true
    );

export const mapUpdateModel = ({
    businessName,
    firstName,
    lastName,
    email,
    phoneNumber,
}: HyperwalletUser): HyperwalletUserUpdateModel => ({
    businessName: businessName || "",
    firstName: firstName || "",
    lastName: lastName || "",
    email: email || "",
    phoneNumber: phoneNumber || undefined,
});
