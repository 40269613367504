import { createAction } from "../reducers/filters";
import { EmptyMessage } from "core/components/card";
import { EmptyMessageForFilters } from "core/components/card/EmptyMessageForFilters";
import { getHasFilters } from "../selectors/getFilters";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

export const ChildLocationsEmpty = () => {
    const dispatch = useDispatch();

    const hasFilters = useSelector(getHasFilters);

    const handleClearFilters = useCallback(() => {
        dispatch(createAction.clearFilters());
    }, [dispatch]);

    return hasFilters ? (
        <EmptyMessageForFilters onClear={handleClearFilters} />
    ) : (
        <EmptyMessage
            heading="You have no linked venues"
            message="Link a venue to accept updates from this brand"
            size="standard"
        ></EmptyMessage>
    );
};
