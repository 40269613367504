import { NavNode, NavNodeTextOptions } from "../types/NavMenu";
import { config } from "common/config";
import { posOperationBehaviorFilter, posOperationFilter } from "features/posConfiguration/selectors/posOperationFilter";
import { Home, Menu, Wallet } from "common/icons";
import { Marketing } from "common/icons/Marketing";
import { OpenInWindow } from "common/icons/OpenInWindow";
import { showMembershipsFilter } from "features/memberships/selectors/showMembershipsFilter";
import { showPriceListsFilter } from "features/priceList/selectors/showPriceListsFilter";

const getMyVenueGroupName = ({ isParentLocation }: NavNodeTextOptions) => {
    return isParentLocation ? "My Brand" : "My Venue";
};

const navStructure: NavNode[] = [
    {
        text: "Launch Venue Manager",
        icon: OpenInWindow,
        permission: "deviceenrolment:create",
        externalUrl: `${config.REACT_APP_VENUE_APP_LINK!}/:region/enrol/:location`,
        category: "prominent",
    },
    {
        text: "Management",
        navItemGroups: [
            {
                text: getMyVenueGroupName,
                icon: Home,
                children: [
                    {
                        text: "Venue details",
                        permission: "location:update",
                        route: "edit",
                    },
                    {
                        text: "Branding",
                        permission: "location:update",
                        route: "branding",
                    },
                    {
                        text: "Sections",
                        permission: "section:read",
                        route: "sections",
                    },
                    {
                        text: "Staff",
                        permission: "staff:read",
                        route: "staff",
                    },
                    {
                        text: "Settings",
                        permission: "settings:read",
                        route: "settings",
                    },
                    {
                        text: "POS setup",
                        permission: "location:pos:configuration:read",
                        route: "pos/configuration",
                        displayFilter: posOperationFilter("SetConfiguration"),
                    },
                    {
                        text: "Takeaway",
                        permission: "settings:read",
                        route: "takeaway",
                        includeForCurrencies: ["NZD", "AUD"],
                        displayFilter: posOperationBehaviorFilter("OrderCreate", "Takeaway"),
                    },
                    {
                        text: "Linked venues",
                        permission: "settings:read",
                        route: "venues",
                        includeForParentLocationOnly: true,
                    },
                ],
            },
            {
                text: "Menu",
                icon: Menu,
                children: [
                    {
                        text: "Catalogue",
                        permission: "catalogue:read",
                        route: "menu/catalogue",
                        includeForParentLocation: true,
                    },
                    {
                        text: "Categories",
                        permission: "menudata:read",
                        route: "menu/categories",
                    },
                    {
                        text: "Services",
                        permission: "menudata:read",
                        route: "menu/services",
                    },
                    {
                        text: "Surcharges",
                        permission: "surcharges:read",
                        route: "menu/surcharges",
                        featureToggle: "enableSurchargesFeature",
                    },
                    {
                        text: "Taxes & fees",
                        permission: "taxes:read",
                        route: "menu/taxes",
                        includeForCurrencies: ["USD"],
                    },
                    {
                        text: "Price Lists",
                        permission: "pricelist:update",
                        route: "menu/pricelists",
                        displayFilter: showPriceListsFilter(),
                    },
                    {
                        text: "Quick Sell",
                        permission: "quicksell:read",
                        route: "quicksell",
                    },
                    {
                        text: "Group Tabs",
                        permission: "grouptabs:management:read",
                        route: "menu/grouptabs",
                    },
                ],
            },
            {
                text: "Marketing",
                icon: Marketing,
                children: [
                    {
                        text: "Promotions",
                        permission: "promotions:read",
                        route: "promotions",
                        featureToggle: "enablePromotions",
                        displayFilter: posOperationBehaviorFilter("OrderCreate", "Discounts"),
                    },
                    {
                        text: "Loyalty",
                        permission: "membership:read",
                        route: "membership",
                        configEnabled: config.REACT_APP_ENABLE_MEMBERSHIP === "1",
                        displayFilter: showMembershipsFilter(),
                    },
                ],
            },
            {
                text: "Payments",
                icon: Wallet,
                children: [
                    {
                        text: "Sales summary",
                        permission: "reports:sales",
                        route: "reports/dailysales",
                    },
                    {
                        text: "Hyperwallet",
                        permission: "payment:read",
                        route: "payments",
                    },
                    {
                        text: "Stripe",
                        permission: "stripe:read",
                        route: "stripe",
                        featureToggle: "enableStripeOnboarding",
                    },
                ],
            },
        ],
    },
    {
        text: "Support",
        icon: Home,
        permission: "menudata:read",
        externalUrl: `https://meandu.helpjuice.com/?utm_source=cms&utm_medium=internal&utm_campaign=:locationId`,
    },
];

export const getNavStructure = () => navStructure;
