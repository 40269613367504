import { opsApi } from "API";

export const archiveProduct = async (locationId: string, itemId: string) => {
    await opsApi.graphQLQuery(query, { locationId, itemId });
};

const query = `
    mutation($locationId: ID!, $itemId: ID!) {
        deleteItem(locationId: $locationId, id: $itemId) {
            id
        }
    }
`;
