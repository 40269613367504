import { createAction } from "../reducers/list";
import { TagGroup } from "../types";
import { sortByDisplayName } from "foundation/dataConventions/sortByDisplayName";
import { GraphQLTagGroupSummary, listTagGroups } from "../api/listTagGroups";
import { scaffoldListAction } from "../../../common/scaffolding/actions/scaffoldListAction";

export const list = () =>
    scaffoldListAction<TagGroup>(
        (state) => state.tags.list,
        createAction,
        async () => {
            const tagGroups = await listTagGroups();

            return tagGroups.map(map);
        }
    );

const map = ({ id, displayName, editorMode, parentTagGroup, tags }: GraphQLTagGroupSummary): TagGroup => ({
    id,
    displayName,
    editorMode: editorMode as "single" | "multiple",
    parentTagGroup: parentTagGroup && parentTagGroup.id,
    tags: tags.sort(sortByDisplayName),
});
