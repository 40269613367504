import { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from "react";

type CsvUploadState = {
    showUploadModal: boolean;
    setShowUploadModal: Dispatch<SetStateAction<boolean>>;
};

export const CsvUploadContext = createContext<CsvUploadState>({
    showUploadModal: false,
    setShowUploadModal: () => {},
});

export const CsvUploadProvider = ({ children }: PropsWithChildren<{}>) => {
    const [showUploadModal, setShowUploadModal] = useState(false);

    return (
        <CsvUploadContext.Provider
            value={{
                showUploadModal,
                setShowUploadModal,
            }}
        >
            {children}
        </CsvUploadContext.Provider>
    );
};
