import { CatalogueItem } from "../types";
import { createSelector } from "reselect";
import { getModifierCatalogueItems } from "./getModifierCatalogueItems";
import { ItemValidationStatus } from "features/catalogue/types";
import { getProductCatalogueItems } from "./getProductCatalogueItems";

const FAILED_SYNC_STATUSES: ItemValidationStatus[] = ["INVALID", "MISSING", "PENDING", "NONE"]; // TODO check with BE what's appropriate here as an outcome of https://meanduapp.atlassian.net/browse/ROCK-182

const getCatalogueItemsWithSyncFailed = (items: CatalogueItem[]): CatalogueItem[] =>
    items.filter(({ validationStatus }) => FAILED_SYNC_STATUSES.includes(validationStatus));

export const getModifierGroupsWithSyncFailed = createSelector(
    getModifierCatalogueItems,
    (modifierGroups): CatalogueItem[] => getCatalogueItemsWithSyncFailed(modifierGroups)
);

export const getProductsWithSyncFailed = createSelector(getProductCatalogueItems, (products): CatalogueItem[] =>
    getCatalogueItemsWithSyncFailed(products)
);
