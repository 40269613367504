import { AppState } from "features/state";
import { createSelector } from "reselect";
import { FilterCategory } from "../types/FilterCategory";

const getCategoriesList = (state: AppState) => state.categories.list;

export const UNASSIGNED_CATEGORY_FILTER: FilterCategory = {
    id: "unassigned",
    displayName: "Unassigned",
    internalName: null,
    type: "other",
};

export const getCategoryFilters = createSelector(getCategoriesList, (categoriesList): FilterCategory[] => {
    if (categoriesList.status !== "loaded") {
        return [];
    }

    const baseFilters: FilterCategory[] = categoriesList.data;

    return baseFilters.concat(UNASSIGNED_CATEGORY_FILTER);
});
