import { DaysOfWeek } from "features/service/types";

export enum SurchargeType {
    Recurring = 1,
    OneTime = 2,
}

export interface SurchargesSummary {
    locationId: string;
    recurringSurcharges?: EditableSurcharge[];
    oneTimeSurcharges?: EditableSurcharge[];
    previousSurcharges: EditableSurcharge[];
}

export interface SurchargesFlag {
    enableSurcharges: boolean;
}

export interface EditableSurcharge extends Surcharge {
    markedForDelete?: boolean;
    validationError?: string;
    rowKey: string;
    newRow: boolean;
    isLive?: boolean;
}

export interface Surcharge {
    id?: string;
    date?: Date;
    day?: number;
    dayLabel?: string;
    startTime?: number;
    endTime?: number;
    percentage?: number;
    surchargeId?: string;
    applyToFood: boolean;
    applyToDrink: boolean;
    surchargeType: SurchargeType;
}

export const defaultSurchargeValues = {
    startTime: undefined,
    endTime: undefined,
    percentage: undefined,
    applyToFood: false,
    applyToDrink: false,
    newRow: true,
};

export const DayOfWeekDictionary = {
    1: "Monday",
    2: "Tuesday",
    4: "Wednesday",
    8: "Thursday",
    16: "Friday",
    32: "Saturday",
    64: "Sunday",
};

export const templateSurcharges: EditableSurcharge[] = [
    {
        day: DaysOfWeek.MONDAY,
        dayLabel: DayOfWeekDictionary[DaysOfWeek.MONDAY],
        rowKey: DaysOfWeek.MONDAY.toString(),
        surchargeType: SurchargeType.Recurring,
        ...defaultSurchargeValues,
    },
    {
        day: DaysOfWeek.TUESDAY,
        dayLabel: DayOfWeekDictionary[DaysOfWeek.TUESDAY],
        rowKey: DaysOfWeek.TUESDAY.toString(),
        surchargeType: SurchargeType.Recurring,
        ...defaultSurchargeValues,
    },
    {
        day: DaysOfWeek.WEDNESDAY,
        dayLabel: DayOfWeekDictionary[DaysOfWeek.WEDNESDAY],
        rowKey: DaysOfWeek.WEDNESDAY.toString(),
        surchargeType: SurchargeType.Recurring,
        ...defaultSurchargeValues,
    },
    {
        day: DaysOfWeek.THURSDAY,
        dayLabel: DayOfWeekDictionary[DaysOfWeek.THURSDAY],
        rowKey: DaysOfWeek.THURSDAY.toString(),
        surchargeType: SurchargeType.Recurring,
        ...defaultSurchargeValues,
    },
    {
        day: DaysOfWeek.FRIDAY,
        dayLabel: DayOfWeekDictionary[DaysOfWeek.FRIDAY],
        rowKey: DaysOfWeek.FRIDAY.toString(),
        surchargeType: SurchargeType.Recurring,
        ...defaultSurchargeValues,
    },
    {
        day: DaysOfWeek.SATURDAY,
        dayLabel: DayOfWeekDictionary[DaysOfWeek.SATURDAY],
        rowKey: DaysOfWeek.SATURDAY.toString(),
        surchargeType: SurchargeType.Recurring,
        ...defaultSurchargeValues,
    },
    {
        day: DaysOfWeek.SUNDAY,
        dayLabel: DayOfWeekDictionary[DaysOfWeek.SUNDAY],
        rowKey: DaysOfWeek.SUNDAY.toString(),
        surchargeType: SurchargeType.Recurring,
        ...defaultSurchargeValues,
    },
];

export const templateOneTimeSurcharges: EditableSurcharge = {
    date: undefined,
    rowKey: "new",
    id: "new",
    surchargeType: SurchargeType.OneTime,
    ...defaultSurchargeValues,
};
