import { AppState, AppDispatch } from "features/state";
import { getPosConfigurationLookups } from "../selectors/getPosConfigurationLookups";
import { fetchLookup } from "./fetchLookup";

export const fetchConfigurationLookups = () => async (dispatch: AppDispatch, getState: () => AppState) => {
    const lookups = getPosConfigurationLookups(getState());

    for (let lookup of lookups) {
        dispatch(fetchLookup(lookup));
    }
};
