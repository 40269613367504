import { downloadBlob } from "common/utility/fileUtils";
import { AppDispatch, AppState } from "features/state";
import { getPosCatalogue } from "../selectors/getPosCatalogueItems";
import { PosProduct, PosCategory } from "../types";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { generateCsvBlob, CsvWriter } from "common/data/generateCsvBlob";

export const exportProductsCsv = () => (dispatch: AppDispatch, getState: () => AppState) => {
    const state = getState();
    const catalogue = getPosCatalogue(state)!;
    const activeLocation = getActiveLocation(state)!;

    const csvBlob = generateCsvBlob<ProductCsvRow>((writer) => {
        writer(PRODUCTS_CSV_HEADER);

        for (const product of catalogue.products) {
            generateProductRow(product, catalogue.categories, writer);
        }
    });

    downloadBlob(csvBlob, `${activeLocation.slug}-products.csv`);
};

function generateProductRow(product: PosProduct, categories: PosCategory[], writer: CsvWriter<ProductCsvRow>): void {
    const category = categories.find((c) => c.products.indexOf(product.id));

    const type = (category ? category.type : null) || null;

    if (product.variants.length === 0) {
        return;
    }

    if (product.variants.length === 1) {
        writer([
            type,
            product.displayName,
            product.description,
            null,
            String(product.variants[0].price || 0),
            product.variants[0].sku,
        ]);
    } else {
        writer([
            type,
            product.displayName,
            product.description,
            product.variants[0].displayName,
            String(product.variants[0].price || 0),
            product.variants[0].sku,
        ]);

        for (let v = 1; v < product.variants.length; v++) {
            const variant = product.variants[v];

            writer([null, null, null, variant.displayName, String(variant.price || 0), variant.sku]);
        }
    }
}

type ProductCsvRow = [string | null, string | null, string | null, string | null, string | null, string | null];

const PRODUCTS_CSV_HEADER: ProductCsvRow = [
    "Type",
    "Master Product Name",
    "Description",
    "Variant Name",
    "Price",
    "SKU",
];
