import styles from "./PromotionProducts.module.scss";

import { Card, Row, Title } from "core/components/card";
import { Field, FieldProps, useFormikContext } from "formik";
import { FieldErrors } from "core/components/form/fieldErrors";
import { initIdsByTypeDictionary, visit } from "common/data/treeUtils";
import { ProductTree } from "components/forms/ProductTree/ProductTree";
import { ProductSelectionsAdapter } from "./ProductSelectionsAdapter";
import { Switch } from "core/components/form/switch";
import { TreeItem } from "common/types";
import { useMemo, useState } from "react";
import {
    EditablePromotion,
    PromotionDiscountType,
    PromotionItems,
    promotionItemTypes,
} from "features/promotions/types";
import { CommonEditProps } from "../EditPromotionPage";

interface Props extends CommonEditProps {
    productTree: TreeItem[];
}

export const PromotionProducts = ({ disableFields, productTree }: Props) => {
    const form = useFormikContext<EditablePromotion>();
    const { initialValues } = form;
    const [selectedItems, setSelectedItems] = useState<TreeItem[]>([]);

    const initialSelections = useMemo(() => {
        const idsByType = initIdsByTypeDictionary(promotionItemTypes);
        const selections: TreeItem[] = [];

        if (!initialValues?.restrictions?.requiredItems) {
            return [];
        }

        promotionItemTypes.forEach((type) => {
            const ids = initialValues?.restrictions?.requiredItems?.[`${type}Ids`] || [];
            ids.forEach((id: string) => {
                idsByType[type][id] = true;
            });
        });

        visit(productTree, (node) => {
            if (idsByType?.[node.type]?.[node.id]) {
                selections.push(node);
            }
        });

        return selections;
    }, [initialValues, productTree]);

    const defaultExpandedKeys = useMemo(() => {
        let expandedKeys: string[] = [];
        if (productTree) {
            visit(
                productTree,
                (node) => {
                    if (node.type === "root") {
                        expandedKeys.push(node.key);
                    }
                },
                false
            );
        }
        return expandedKeys;
    }, [productTree]);

    return (
        <Card>
            <Row collapse="down">
                <Title title="Apply to all items" />
                <div className="ml-4">
                    <Field
                        name="applyToAll"
                        type="checkbox"
                        component={Switch}
                        disabled={disableFields || form.values.discount.type === PromotionDiscountType.PERCENTAGE}
                    />
                </div>
            </Row>
            <Row collapse="up">
                <p className={styles.applyAllDisclaimer}>
                    {form.values.discount.type === PromotionDiscountType.PERCENTAGE
                        ? "Percentage promotions are applied to all items and cannot be restricted to specific items."
                        : "Fixed amount & Price List promotions can be applied to all items or restricted to specific items."}
                </p>
            </Row>
            <Row collapse="up" hide={form.values.applyToAll}>
                <FieldErrors fieldNames={["restrictions.requiredItems"]}>
                    <Field name="restrictions.requiredItems">
                        {({ field, form }: FieldProps<PromotionItems>) => {
                            return productTree ? (
                                <>
                                    <ProductSelectionsAdapter field={field} form={form} selectedItems={selectedItems} />
                                    <ProductTree
                                        initialSelectedItems={initialSelections}
                                        initialSelectionsExpansion={["parents"]}
                                        datasource={productTree}
                                        defaultExpandedKeys={defaultExpandedKeys}
                                        disabled={disableFields}
                                        selectedItems={selectedItems}
                                        setSelectedItems={setSelectedItems}
                                        searchPlaceholder="Search for products and variants"
                                        showIcon={true}
                                        onCheck={() => form.setFieldTouched("restrictions.requiredItems", true)}
                                    />
                                </>
                            ) : null;
                        }}
                    </Field>
                </FieldErrors>
            </Row>
        </Card>
    );
};
