import { opsApi } from "API";
import { EditablePackage } from "../types";

export const editPackage = async (locationId: string, packageId: string): Promise<EditablePackage> => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId, packageId });
    return res.data.package;
};

type GraphQLPayload = { package: EditablePackage };

const query = `
    query ($locationId: ID!, $packageId: ID!) {
        package(locationId: $locationId, id: $packageId) {
            id
            displayName
            description
            enabled
            itemCount
            usedCount
            categories {
                id
                items
            }
        }
    }
`;
