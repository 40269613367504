import timeago from "timeago.js";
import { Tooltip } from "core/components/tooltip/Tooltip";
import { Button } from "core/components/button/Button";
import { FileTextOutlined } from "@ant-design/icons";
import { menuDataUrl } from "common/menuData/menuDataUrl";

export interface Props {
    lastPublished: string;
    publishing: boolean;
    onClick: () => void;
    buttonText?: string;
    visible: boolean;
    canViewMenuData: boolean;
    lastPublishedUrl: string;
    disabled: boolean;
}

const PublishButton = ({
    onClick,
    lastPublished,
    publishing,
    buttonText,
    visible,
    canViewMenuData,
    lastPublishedUrl,
    disabled,
}: Props) =>
    visible ? (
        <>
            {canViewMenuData && (
                <div className="hide-until-medium">
                    <Button
                        disabled={!lastPublishedUrl}
                        padding="icon"
                        role="secondary"
                        shape="button"
                        href={lastPublishedUrl ? menuDataUrl(lastPublishedUrl) : null}
                        as="a"
                        target="_blank"
                    >
                        <FileTextOutlined />
                    </Button>
                </div>
            )}
            <Tooltip
                content={lastPublished ? `Last published ${timeago().format(lastPublished)}` : `Not published`}
                placement="top-end"
            >
                <Button loading={publishing} onClick={onClick} disabled={disabled}>
                    {buttonText || "Publish"}
                </Button>
            </Tooltip>
        </>
    ) : null;

export { PublishButton };
