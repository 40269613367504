import { EditOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { ColumnProps, TablePaginationConfig } from "antd/lib/table";
import { NewDataButton } from "common/scaffolding/components/DataListingPage/NewDataButton";
import { PageTitle } from "common/scaffolding/components/DataListingPage/PageTitle";
import { getPosFieldSettings } from "components/forms/posUI/posSettings";
import { ActiveLocation } from "features/location";
import { CrudPermissions } from "features/location/types/createCrudPermissions";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { PriceListSummary } from "../types";
import { TableListing } from "../../../common/scaffolding/components/TableListing/TableListing";
import "./ListPriceListsPage.scss";

export interface Props {
    priceLists: PriceListSummary[];
    permissions: CrudPermissions;
    restaurantLocation: ActiveLocation;
}

const paginationSettings: TablePaginationConfig = {
    size: "default",
    position: ["bottomCenter"],
    pageSize: 10,
    showSizeChanger: false,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
};

export const ListPriceListsPage = ({
    restaurantLocation,
    priceLists,
    match,
    permissions,
    history,
}: Props & RouteComponentProps<any>) => {
    const canCreate = permissions.canCreate;
    const fieldSettings = getPosFieldSettings(restaurantLocation.postype || "", "priceListPosId");
    const posVisible = fieldSettings ? fieldSettings.visible : false;

    const bindColumns = () => {
        let columns: ColumnProps<PriceListSummary>[] = [
            {
                title: "List Name",
                dataIndex: "displayName",
                key: "displayName",
            },
            {
                title: "Price list POS ID",
                dataIndex: "posId",
                key: "posId",
            },
            {
                title: "Population",
                dataIndex: "itemCount",
                key: "itemCount",
                render: (text) => `${text} items`,
            },
        ];

        if (permissions.canUpdate) {
            columns = columns.concat({
                title: "",
                key: "action",
                className: "col-actions col-center",
                render: (text, record) => (
                    <Link to={`${match.url}/${record.id}`}>
                        <EditOutlined />
                    </Link>
                ),
            });
        }

        //only show posId field if pos Id enabled for venue
        return columns.filter((col) => posVisible || col.key !== "posId");
    };

    const handleRowClick = (record: PriceListSummary) => {
        history.push({
            pathname: `${match.url}/${record.id}`,
        });
    };

    return (
        <>
            <PageTitle title="Price Lists" description="" />
            <Col span={18} className="col-content">
                <Row justify="start" align="middle">
                    {canCreate && <NewDataButton text="Add new price list..." newItemRoute={`${match.url}/new`} />}
                </Row>
            </Col>
            <Row>
                <TableListing
                    columns={bindColumns()}
                    dataSource={priceLists}
                    keyField="id"
                    pagination={paginationSettings}
                    onRowClick={handleRowClick}
                />
            </Row>
        </>
    );
};
