import { applySummaryDisplayName } from "./applySummaryDisplayName";
import { sortByDisplayName } from "./sortByDisplayName";
import { CmsItemSummary, GraphQLItem } from "../../common/scaffolding/types";

export function normalizeSummaryItems<T extends GraphQLItem, TOut extends CmsItemSummary>(
    items: T[],
    mutator?: (item: T) => TOut
) {
    return items
        .map((item) => (mutator ? mutator(applySummaryDisplayName(item)) : applySummaryDisplayName(item)))
        .sort(sortByDisplayName);
}
