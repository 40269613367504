import { AlertStatus } from "core/components/alert";
import { ReactNode, useMemo } from "react";
import { useFormikContext } from "formik";
import { EditableProduct } from "features/catalogue/types";
import { Link } from "react-router-dom";
import { AlertsList } from "core/components/alert/AlertsList";
import { useSelector } from "react-redux";
import { getIsChildLocation } from "features/location/selectors/getIsChildLocation";
import { getPosSyncEnabled } from "features/catalogue/selectors/getPosSyncEnabled";

interface Props {
    priceListsLink: string;
}

export const ModifierAlerts = ({ priceListsLink }: Props) => {
    const isChildLocation = useSelector(getIsChildLocation);

    const {
        initialValues: { priceLists, isLinked },
    } = useFormikContext<EditableProduct>();

    const posSyncEnabled = useSelector(getPosSyncEnabled || false);

    const alerts = useMemo(() => {
        const alerts: ReactNode[] = [];

        if (posSyncEnabled) {
            alerts.push(<>This modifier group is controlled by POS, some fields are read only.</>);
        }

        if (priceLists.length) {
            alerts.push(
                <>
                    This modifier has price levels which are set in{" "}
                    <Link to={priceListsLink} className={"link-reset"}>
                        <strong>Price Lists</strong>
                    </Link>
                </>
            );
        }

        if (isChildLocation && isLinked) {
            alerts.push(
                <>
                    This modifier group is brand controlled. To make other venue specific modifications you can
                    duplicate the modifier group.
                </>
            );
        }

        return alerts;
    }, [posSyncEnabled, priceLists.length, isChildLocation, isLinked, priceListsLink]);

    return <AlertsList alerts={alerts} status={AlertStatus.INFO} />;
};
