import styles from "./Tabs.module.scss";

import { Route, Switch } from "react-router-dom";
import { Children, cloneElement, isValidElement, PropsWithChildren } from "react";
import { RouteTabLink } from "./RouteTabLink";
import { RouteTabPanelProps } from "./RouteTabPanel";

interface RouteTabsProps {
    className?: string;
}

export const RouteTabs = ({ children, className }: PropsWithChildren<RouteTabsProps>) => {
    if (!children) {
        return null;
    }
    return (
        <div className={className}>
            <div role="tablist" className={styles.controls}>
                {Children.map(children, (child, index) => {
                    if (!isValidElement<RouteTabPanelProps>(child)) {
                        return null;
                    }

                    return (
                        <RouteTabLink id={child.props.id} route={child.props.route}>
                            {child.props.label}
                        </RouteTabLink>
                    );
                })}
            </div>
            <Switch>
                {Children.map(children, (child) => {
                    if (!isValidElement<RouteTabPanelProps>(child)) {
                        return child;
                    }

                    return (
                        <Route
                            path={child.props.route}
                            render={(props) => cloneElement(child, { ...props, ...child.props }, child.props.children)}
                        />
                    );
                })}
            </Switch>
        </div>
    );
};
