// tslint:disable:jsx-no-lambda

import * as React from "react";

import { RouteComponentProps } from "react-router";
import { SectionSummary } from "../../types";

import { DataListingPage } from "common/scaffolding/components/DataListingPage";

import "./ListSectionsPage.scss";
import { CrudPermissions } from "features/location/types/createCrudPermissions";

export interface Props {
    items: SectionSummary[];
    permissions: CrudPermissions;
}

export class ListSectionsPage extends React.Component<Props & RouteComponentProps<any>> {
    render() {
        const { items, match, permissions } = this.props;

        return (
            <DataListingPage
                title="Sections"
                description="Sections are portions of your restaurant that can be attached to services, so that they can can take orders at separate times."
                newItemLabel="Add new section..."
                newItemRoute={`${match.url}/new`}
                editItemRoute={`${match.url}/:id`}
                items={items}
                disableAdd={!permissions.canCreate}
            />
        );
    }
}
