import { ActionMeta, PropsValue } from "react-select";
import { FastField, getIn, useFormikContext } from "formik";
import { Option, Select } from "core/components/form/select";

interface Props {
    canCreateGroup: boolean;
    disableFields?: boolean;
    name: string;
    newGroupFieldName: string;
    options: Option[];
}

export const GroupSelectOrCreate = ({
    canCreateGroup,
    disableFields = false,
    name,
    newGroupFieldName,
    options,
}: Props) => {
    const { values, setFieldValue } = useFormikContext<Object>();

    const hasValue = !!getIn(values, name);

    const handleGroupChange = (value: PropsValue<Option>, meta: ActionMeta<Option>) => {
        if (meta.action === "create-option") {
            setFieldValue(newGroupFieldName, (value as Option).label);
        } else {
            setFieldValue(newGroupFieldName, undefined);
        }
        return true;
    };

    return (
        <FastField
            allowCreate={canCreateGroup}
            blurInputOnSelect={false}
            component={Select}
            disabled={disableFields}
            isClearable={hasValue}
            label="Group"
            menuPlacement="auto"
            menuPosition="fixed"
            name="groupId"
            onChange={handleGroupChange}
            options={options}
            placeholder={canCreateGroup ? "Search or add new group" : "Search group"}
            search={!hasValue}
        />
    );
};
