import { scaffoldEditAction } from "common/scaffolding/actions/scaffoldEditAction";
import { isNew } from "common/utility/modelUtils";
import { createAction } from "../reducers/edit";
import {
    EditableVenueMembershipProgram,
    GraphQLVenueMembership,
    MembershipLinksEnum,
    MembershipProgramType,
} from "../types";
import { editMembership } from "../api";

export const edit = (locationId: string, id: string) =>
    scaffoldEditAction(
        id,
        (state) => state.memberships.edit,
        createAction,
        async () => {
            if (isNew(id)) {
                return newEditableMembershipProgram();
            }
            const editMembershipData: GraphQLVenueMembership = await editMembership(locationId, id);
            return mapGraphQLMembershipToEditableMembership(editMembershipData);
        }
    );

const newEditableMembershipProgram = (): EditableVenueMembershipProgram => {
    return {
        displayName: "",
        priceListId: "",
        type: "Venue" as MembershipProgramType,
    };
};

const mapGraphQLMembershipToEditableMembership = (
    membership: GraphQLVenueMembership
): EditableVenueMembershipProgram => {
    return {
        id: membership.id,
        displayName: membership.displayName,
        privacyPolicyLink: membership.links?.find((l) => l.key === MembershipLinksEnum.PRIVACY)?.value,
        levelId: membership.membershipLevels[0].id,
        priceListId: membership.membershipLevels[0].priceList?.id || "",
        dateCreated: membership.membershipLevels[0].dateCreated,
        authentication: membership.authentication,
        type: membership.type,
    };
};
