import { opsApi } from "API";
import { GraphQLItem, GraphQLItemId } from "common/scaffolding/types";
import { ProductSubtypes } from "features/modifier/types/ProductSubtypes";
import { UpsellModifierType } from "features/modifier/types/UpsellModifierType";

export const editModifier = async (locationId: string, modifierId: string): Promise<GraphQLModifier> => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId, modifierId });
    return res.data.modifier;
};

type GraphQLPayload = { modifier: GraphQLModifier };

export interface GraphQLModifier extends GraphQLItem {
    minSelection?: number;
    maxSelection?: number;
    required: boolean;
    sku: string;
    options: Array<GraphQLModifierOption>;
    items?: Array<GraphQLItemId>; // TODO @deprecated
    products?: GraphQLModifierProduct[];
    upsell: boolean;
    priceLists: Array<string>;
    status: number;
    smartSorting?: boolean;
    maxSelectionPerOption?: number;
    type: UpsellModifierType | null;
    subtypes: ProductSubtypes | null;
    isLinked: boolean;
}

export interface GraphQLModifierOption {
    id?: string;
    displayName: string;
    price: number;
    taxRate: number | null;
    sku: string;
    recommended?: boolean | undefined;
    modifiers?: Array<string>;
    energyContent?: number;
    isLinked: boolean;
}

export interface GraphQLModifierProduct {
    productId: string;
    variantIds: string[] | null;
}

const query = `
    query ($locationId: ID!, $modifierId: ID!) { 
        modifier(locationId: $locationId, id: $modifierId) {
            id,
            internalName,
            displayName,
            minSelection,
            maxSelection,
            required,
            sku,
            options {
                id,
                displayName,
                price,
                sku,
                recommended,
                modifiers,
                energyContent
                isLinked,
                validationStatus
            },
            # TODO deprecated
            items {
                id
            },
            products {
               productId,
               variantIds
            },
            upsell,
            type,
            subtypes,
            priceLists,
            status,
            smartSorting,
            maxSelectionPerOption
            isLinked
        }
    }
`;
