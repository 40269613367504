import { AppDispatch, AppState } from "features";
import { setLastItemUpdated } from "features/location/actions/setLastItemUpdated";
import { uploadProducts } from "../api/uploadProducts";
import { createAction } from "../reducers/import";

export function importProducts(fileName: string, locationId: string, preview: boolean) {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();

        if (state.catalogue.bulkImport.status === "loading") {
            return;
        }

        dispatch(createAction.loading());

        try {
            const result = await uploadProducts(fileName, locationId, preview);
            const mappedResult = { preview, fileName, ...result };
            dispatch(setLastItemUpdated());
            dispatch(createAction.loaded(mappedResult));
        } catch (e) {
            dispatch(createAction.failed(e));
        }
    };
}
