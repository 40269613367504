import { PosFieldProps } from "../PosFieldProps";
import { PosTextFieldCommon } from "features/location";
import { CopyOutlined } from "@ant-design/icons";
import copy from "copy-to-clipboard";

import "./PosReferenceField.scss";

export const PosReferenceField = ({
    form,
    field,
    definition: { placeholder },
    disabled,
}: PosFieldProps<PosTextFieldCommon>) => (
    <div className="posreferencefield">
        <div className="posreferencefield--value">{field.value}</div>
        <div className="posreferencefield--copy">
            <CopyOutlined onClick={() => copy(field.value)} />
        </div>
    </div>
);
