import { toTitleCase } from "common/utility/StringUtils";
import { DaysOfWeek, EditableService, ServiceSummary } from "features/service/types";
import { AppState } from "features/state";
import isEqual from "lodash/isEqual";
import { createSelector } from "reselect";
import { getTrackingData } from "common/utility/trackingDataUtils";
import { isNew } from "common/utility/modelUtils";

export * from "./getActiveServices";
export * from "./getVenueTime";

const getExistingService = ({ services: { edit } }: AppState) => {
    return edit.status === "loaded" ? edit.data : ({} as EditableService);
};

export const getArchiveServiceTrackingEventData = createSelector(
    [(state: AppState) => getExistingService(state), (_: AppState, service: EditableService) => service],
    (service: EditableService) => {
        const eventName = "SERVICES/DELETION_SAVED";
        const trackingData = {
            service_id: service.id,
        };
        return getTrackingData(eventName, trackingData);
    }
);

export const getSaveServiceTrackingEventData = createSelector(
    [
        (state: AppState) => getExistingService(state),
        (_: AppState, formData: EditableService) => formData,
        (_: AppState, __: EditableService, saveResult?: ServiceSummary) => saveResult,
    ],
    (existing, formData, saveResult) => {
        const serviceTrackingData = getServiceChanges(existing, formData, saveResult);
        const eventName = isNew(existing.id) ? "SERVICES/ADDITION_SAVED" : "SERVICES/MODIFICATION_SAVED";
        return getTrackingData(eventName, serviceTrackingData);
    }
);

export const getServiceChanges = (
    existing: EditableService,
    formData: EditableService,
    saveResult?: ServiceSummary
) => {
    const isNewModel = isNew(formData.id);
    const serviceId = !isNewModel ? formData.id : saveResult?.id;
    const changes: any = {};

    changes.timestamp = new Date().toISOString();

    if (serviceId) {
        changes.service_id = serviceId;
    }

    if (isNewModel || existing.displayName !== formData.displayName) {
        changes.service_name = formData.displayName;
    }

    if (isNewModel || (existing && !isEqual(existing.daysOfWeek, formData.daysOfWeek))) {
        changes.day = mapDaysOfWeekToNames(formData.daysOfWeek);
    }

    if (isNewModel || existing.times.startTime !== formData.times.startTime) {
        changes.time_from = formData.times.startTime;
    }

    if (isNewModel || existing.times.endTime !== formData.times.endTime) {
        changes.time_to = formData.times.endTime;
    }

    if (isNewModel || (existing && !isEqual(existing.dates, formData.dates))) {
        changes.date_ranges = formData.dates.map((d) => ({ from: d.startDate, to: d.endDate }));
    }

    if (isNewModel || existing.menuPriority !== formData.menuPriority) {
        changes.menu_order = formData.menuPriority;
    }

    if (isNewModel || (existing && !isEqual(existing.categories, formData.categories))) {
        changes.categories = formData.categories;
    }

    if (isNewModel || existing.featured !== formData.featured) {
        changes.featured_product = formData.featured;
    }

    if (isNewModel || (existing && !isEqual(existing.sections, formData.sections))) {
        changes.sections = formData.sections;
    }

    if (isNewModel || existing.priceList !== formData.priceList) {
        changes.price_list_id = formData.priceList;
    }

    if (isNewModel || existing.surcharge !== formData.surcharge) {
        changes.surcharge = formData.surcharge;
    }

    if (isNewModel || existing.available !== formData.available) {
        changes.availability = formData.available ? "enabled" : "disabled";
    }

    if (isNewModel || existing.applyAlcoholicDrinksRestriction !== formData.applyAlcoholicDrinksRestriction) {
        changes.alcohol_restriction = !!formData.applyAlcoholicDrinksRestriction;
    }

    return changes;
};

function mapDaysOfWeekToNames(daysOfWeek: DaysOfWeek[]) {
    return daysOfWeek.map((dow) => toTitleCase(DaysOfWeek[dow].substring(0, 3)));
}
