import { opsApi } from "API";
import { SurchargeType } from "../types";

export const editSurcharges = async (locationId: string): Promise<GraphQLSurcharges> => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId });
    return res.data.surcharges;
};

type GraphQLPayload = { locationId: string; venueTime: Date; surcharges: GraphQLSurcharge };

export interface GraphQLSurcharges {
    current: Array<GraphQLSurcharge>;
    previous: Array<GraphQLSurcharge>;
}

export interface GraphQLSurcharge {
    id: string;
    startTime?: number;
    endTime?: number;
    percentage?: number;
    surchargeId?: string;
    applyToFood: boolean;
    applyToDrink: boolean;
    surchargeType: SurchargeType;
    dayOfWeek?: number;
    dayLabel: string;
    date?: Date;
}

const query = `
    query($locationId: ID!) {
        surcharges(locationId: $locationId) {
            current {
                id,
                date,
                startTime,
                endTime,
                percentage,
                applyToFood,
                applyToDrink,
                surchargeType,
                dayOfWeek,
                surchargeId
            },
            previous {
                id,
                date,
                startTime,
                endTime,
                percentage,
                applyToFood,
                applyToDrink,
                surchargeType,
                dayOfWeek,
                surchargeId
            }
        }
    }
`;
