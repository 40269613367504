import { LoadingOutlined } from "@ant-design/icons";

interface Props {
    hideText?: boolean;
}

export const ImageLoader = ({ hideText }: Props) => (
    <div className="loading">
        <LoadingOutlined />
        {!hideText && (
            <span>
                One moment please,
                <br /> processing your image.
            </span>
        )}
    </div>
);
