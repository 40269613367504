import "@formatjs/intl-relativetimeformat/polyfill";

const SECOND = 1000,
    MINUTE = SECOND * 60,
    HOUR = MINUTE * 60,
    DAY = HOUR * 24;
export class LocationLocale {
    private longDateFormat: Intl.DateTimeFormat;
    private relativeDateFormat: Intl.RelativeTimeFormat | undefined;
    private currencyFormat: Intl.NumberFormat;
    private numberFormat: Intl.NumberFormat;
    private percentFormat: Intl.NumberFormat;

    public currencySymbol: string;
    constructor(public locale: string, public currency: string) {
        this.longDateFormat = new Intl.DateTimeFormat(locale, {
            year: "numeric",
            month: "long",
            day: "numeric",
        });

        this.initializeRelativeFormat(locale);

        this.currencyFormat = new Intl.NumberFormat(locale, {
            style: "currency",
            currency: currency,
        });

        this.numberFormat = new Intl.NumberFormat(locale, {});
        this.percentFormat = new Intl.NumberFormat(locale, {
            style: "percent",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        this.currencySymbol = getCurrencySymbol(locale, currency);

        this.getNumberFormatter = this.getNumberFormatter.bind(this);
    }

    initializeRelativeFormat(locale: string) {
        this.relativeDateFormat = new Intl.RelativeTimeFormat(locale, {
            style: "long",
            numeric: "auto",
        });
    }

    formatDate(date: Date) {
        return date.toISOString().split("T")[0];
    }

    formatLongDate(date: Date) {
        return this.longDateFormat.format(date);
    }

    formatCurrency(value: number) {
        return this.currencyFormat.format(value);
    }

    formatRelativeTime(date: Date) {
        const diff = Date.now() - date.getTime();
        if (diff < SECOND) {
            return "just now";
        }
        if (diff < MINUTE) {
            return this.relativeDateFormat!.format(Math.round(-diff / SECOND), "seconds");
        }
        if (diff < HOUR) {
            return this.relativeDateFormat!.format(Math.round(-diff / MINUTE), "minutes");
        }
        if (diff < DAY) {
            return this.relativeDateFormat!.format(Math.round(-diff / HOUR), "hours");
        }
        return this.relativeDateFormat!.format(Math.round(-diff / DAY), "days");
    }

    formatNumber(value: number) {
        return this.numberFormat.format(value);
    }

    formatPercent(value: number) {
        return this.percentFormat.format(value);
    }

    getNumberFormatter(options: Intl.NumberFormatOptions) {
        return new Intl.NumberFormat(this.locale, options);
    }
}

function getCurrencySymbol(locale: string, currency: string) {
    return (0)
        .toLocaleString(locale, {
            style: "currency",
            currency: currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })
        .replace(/\d/g, "")
        .trim();
}
