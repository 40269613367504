import { AppState } from "features";
import { isLoaded } from "common/loader/isLoaded";
import { saveLocationSettings } from "features/locationSettings/api/saveLocationSettings";
import { mapToLocationSettingsInputModel } from "features/locationSettings/actions";
import * as locationSettingsActions from "features/locationSettings/actions";
import { createAction as activeLocationActions } from "../../location/reducers/active";
import { getEnableSurchargesTrackingEventData } from "../selectors/getEnableSurchargesTrackingEventData";

export const saveSurchargesFlag = (locationId: string, isEnabled: boolean) => {
    return async function (dispatch: any, getState: () => AppState) {
        const {
            LocationSettings: { edit },
        } = getState();
        if (isLoaded(edit)) {
            const result = await saveLocationSettings(
                locationId,
                mapToLocationSettingsInputModel({
                    ...edit.data,
                    enableSurcharges: isEnabled,
                })
            );

            result.trackingData = getEnableSurchargesTrackingEventData(getState());
            dispatch(locationSettingsActions.edit(locationId));
            dispatch(activeLocationActions.success(result, getEnableSurchargesTrackingEventData(getState())));
        }
        return undefined;
    };
};
