import { opsApi } from "API";
import { LocationSummary } from "..";

export const listLocations = async () => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query);
    return res.data.locations;
};

type GraphQLPayload = { locations: LocationSummary[] };

const query = `
    query {
        locations {
            id,
            displayName,
            slug
            locationType,
            groupId,
            parentLocationId,
            parentLocationUpdateStatus {
                lastMenuUpdateUtc,
                lastParentImportUtc,
                hasBeenSynced,
                updatesAvailable,
            },
        }
    }
`;
