import { InfoCircleFilled } from "@ant-design/icons";
import { Button } from "antd";
import { ImportResult } from "features/catalogue/types/ImportResult";
import { UploadResult } from "features/catalogue/types/UploadResult";
import { useEffect, useState } from "react";
import { UploadLoader } from "./UploadLoader";

export interface Props {
    parentRecordTitle: string;
    childRecordTitle: string;
    imported?: ImportResult;
    onClose: () => void;
    onImport: (fileName: string, preview: boolean) => void;
    uploadResult?: UploadResult;
    loading: boolean;
}

export const UploadValid = ({
    parentRecordTitle,
    childRecordTitle,
    imported,
    onClose,
    onImport,
    uploadResult,
    loading,
}: Props) => {
    const [uploadedFile, setUploadedFile] = useState("");

    useEffect(() => {
        if (uploadResult && uploadResult.uploadSuccess) {
            const { uploadFilePath } = uploadResult;
            const file = uploadFilePath && !!uploadFilePath.split("/").length && uploadFilePath.split("/").pop();

            setUploadedFile(file || "");
        }
    }, [uploadResult]);

    return (
        <div className="bulk-uploader--content">
            <UploadLoader loading={loading} />
            <div className="bulk-uploader--header">
                <i className="info-circle" />
                <h1>Review your changes</h1>
                <span>
                    Please review your changes carefully and ensure your document is ready to be uploaded. There is no
                    way to undo this action.
                </span>
            </div>
            <div className="bulk-uploader--body">
                <div className="body-top">
                    <h2>What's changing</h2>
                    {imported && imported.summary && (
                        <div className="import-success">
                            {imported.summary.parentRecordsUpdated === 0 ? (
                                <p>
                                    <InfoCircleFilled className="invalid" /> No changes were found
                                </p>
                            ) : (
                                <>
                                    <p>
                                        {imported.summary.parentRecordsUpdated}{" "}
                                        <span className="title-case">{parentRecordTitle}</span>s will be added
                                    </p>
                                    <p>
                                        {imported.summary.childRecordsUpdated}{" "}
                                        <span className="title-case">{childRecordTitle}</span>s will be added to new{" "}
                                        {parentRecordTitle}s
                                    </p>
                                </>
                            )}
                        </div>
                    )}
                    <div className="review">
                        <InfoCircleFilled />
                        <h3>Review your content</h3>
                        <span>Your new items will need additional content before publishing.</span>
                    </div>
                </div>
            </div>
            <div className="bulk-uploader--footer">
                <Button
                    type="primary"
                    onClick={() => onImport(uploadedFile, false)}
                    disabled={!imported || !imported.summary || imported.summary.parentRecordsUpdated <= 0}
                >
                    Confirm changes
                </Button>
                <Button type="default" onClick={onClose}>
                    Cancel
                </Button>
            </div>
        </div>
    );
};
