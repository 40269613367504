import { CourseSummary } from "../../course";
import { EditableCmsItem } from "common/scaffolding/types";

export interface EditableService extends EditableCmsItem {
    times: {
        startTime: number;
        endTime: number;
    };
    dates: EditableDateRange[];
    menus: string[];
    categories: string[];
    courses: string[];
    setMenus: string[];
    defaultCourse: string;
    dropDownListOptions: CourseSummary[];
    daysOfWeek: DaysOfWeek[];
    surcharge: number | null;
    surchargeSku: string | null;
    available: boolean;
    posId: string | null;
    sections: string[];
    menuPriority: string;
    featured: Featured;
    priceList: string | null;
    membershipPriceLists: MembershipPriceListMap;
    applyAlcoholicDrinksRestriction: boolean | null;
}

export interface Featured {
    title?: string;
    item?: string;
}
export interface EditableDateRange {
    startDate: string; // yyyy-MM-dd
    endDate: string;
}

export enum DaysOfWeek {
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 4,
    THURSDAY = 8,
    FRIDAY = 16,
    SATURDAY = 32,
    SUNDAY = 64,
}

export type MembershipPriceListMap = { [membershipLevelId: string]: string };
