// Declared in public/config.(env).js to avoid having to modify these scripts at
// deploy time, which messes with the source maps
const globalConfig: { [key: string]: string | undefined } = (window as any).meanduConfig;

export const config: AppConfiguration = {
    ...process.env,
    ...globalConfig,
} as any;

export interface AppConfiguration {
    REACT_APP_VENUE_APP_LINK: string;
    REACT_APP_MANAGEMENT_API: string;
    REACT_APP_OPS_API: string;
    REACT_APP_MENU_DATA_URI: string;
    REACT_APP_AUTH0_CLIENT_ID: string;
    REACT_APP_AUTH0_DOMAIN: string;
    REACT_APP_AUTH0_CALLBACK_URL: string;
    REACT_APP_ORDER_APP_URL: string;
    REACT_APP_LEGACY_REGION: string;
    REACT_APP_ENABLE_LISTING_IMAGES: string;
    REACT_APP_DISABLE_PERMISSIONS?: string;
    REACT_APP_ENABLE_UPSELL_TYPES: string;
    REACT_APP_ENABLE_MEMBERSHIP: string;
    REACT_APP_ENABLE_MENU_BACKGROUND_IMAGE: string;
    REACT_APP_ENABLE_ANALYTICS_LAYOUT_CONFIG: string;
    REACT_APP_SHOW_CREATE_DEFAULT_TABLE: string;
}
