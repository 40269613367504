import * as React from "react";

import "./Button.scss";

export interface ButtonProps {
    label: string;
    disabled?: boolean;
    type?: "submit" | "reset" | "button";

    onClick?: () => void;
}

export class Button extends React.Component<ButtonProps> {
    render() {
        const { label, disabled, type } = this.props;

        const className = disabled ? `button button--disabled` : "button";

        return (
            <button type={type} disabled={disabled} className={className} onClick={this.onClick}>
                {label}
            </button>
        );
    }

    onClick = () => {
        if (this.props.onClick) {
            this.props.onClick();
        }
    };
}
