import { EditableSurcharge } from "../types";
import { CloseCircleFilled, CloseCircleOutlined } from "@ant-design/icons";
import { Checkbox, DatePicker, Input } from "antd";
import { TimeRangePicker } from "./TimeRangePicker";
import classNames from "classnames";
import { ValidationError } from "../types/ValidationError";
import { FieldUpdate } from "../types/SurchargeField";
import { ColumnProps } from "antd/lib/table";
import { hasEntries } from "../surchargeHelper";
import moment from "moment";
import { Indicator } from "components/forms/Indicator/Indicator";
import { InputNumberAddon } from "components/forms/InputNumberAddon";
import { hoursFromTime } from "common/utility/liveTimes";

import { CalendarOutlined } from "@ant-design/icons";

export interface Props {
    surchargeIdColumn?: string;
    invalidItems?: ValidationError[];
    onClearRow?: (surcharge: EditableSurcharge) => void;
    onUpdateField?: (surcharge: EditableSurcharge, updates: FieldUpdate[], checkCheckBoxes?: boolean) => void;
    disableFields?: boolean;
}

const checkErrorClass = (
    id: string | number | Date,
    field: string,
    errors?: ValidationError[],
    errorClass: string = "has-error"
) => {
    return errors && errors.find((e) => e.rowId === id && e.field === field) ? errorClass : "";
};

const onTimeChange = (
    surcharge: EditableSurcharge,
    onUpdateField?: (surcharge: EditableSurcharge, updates: FieldUpdate[], checkCheckBoxes?: boolean) => void,
    startTime?: number,
    endTime?: number
) => {
    const updates = [
        { field: "startTime", value: startTime },
        { field: "endTime", value: endTime },
    ];
    onUpdateField && onUpdateField(surcharge, updates, true);
};

const onCheckChange = (
    surcharge: EditableSurcharge,
    field: string,
    checked: boolean,
    onUpdateField?: (surcharge: EditableSurcharge, updates: FieldUpdate[], checkCheckBoxes?: boolean) => void
) => {
    onUpdateField && onUpdateField(surcharge, [{ field: field, value: checked }]);
};

export const RecurringSurchargeColumns = ({
    surchargeIdColumn,
    invalidItems,
    onUpdateField,
    onClearRow,
    disableFields = false,
}: Props): ColumnProps<EditableSurcharge>[] => {
    return [
        {
            title: "Day",
            dataIndex: "dayLabel",
            key: "dayLabel",
            className: "col-day",
            width: 184,
            render: (text, record) => (
                <>
                    <div className="col-day__label">{text}</div>
                    {record.validationError && (
                        <div className="row-error">
                            <span>
                                <CloseCircleFilled />
                                {record.validationError}
                            </span>
                        </div>
                    )}
                </>
            ),
        },
        ...SurchargeColumns({ surchargeIdColumn, invalidItems, onUpdateField, onClearRow, disableFields }),
    ];
};

export const OneTimeSurchargeColumns = ({
    surchargeIdColumn,
    invalidItems,
    onUpdateField,
    onClearRow,
    disableFields = false,
}: Props): ColumnProps<EditableSurcharge>[] => {
    return [
        {
            title: "Day",
            dataIndex: "date",
            key: "date",
            className: "col-day",
            width: 184,
            render: (text, record) => (
                <>
                    <div className="col-day__date">
                        <DatePicker
                            suffixIcon={record.isLive ? <Indicator label="LIVE" /> : <CalendarOutlined />}
                            value={record.date ? moment.utc(record.date) : undefined}
                            onChange={(e) =>
                                onUpdateField &&
                                onUpdateField(
                                    record,
                                    [{ field: "date", value: e && e.utc(true).startOf("day").toDate() }],
                                    true
                                )
                            }
                            disabled={record.isLive || disableFields}
                            className={classNames(checkErrorClass(record.rowKey, "date", invalidItems))}
                            placeholder="Select date"
                        />
                    </div>
                    {record.validationError && (
                        <div className="row-error">
                            <span>
                                <CloseCircleFilled />
                                {record.validationError}
                            </span>
                        </div>
                    )}
                </>
            ),
        },
        ...SurchargeColumns({ surchargeIdColumn, invalidItems, onUpdateField, onClearRow, disableFields }),
    ];
};

export const SurchargeColumns = ({
    surchargeIdColumn,
    invalidItems,
    onUpdateField,
    onClearRow,
    disableFields = false,
}: Props): ColumnProps<EditableSurcharge>[] => {
    const columns: ColumnProps<EditableSurcharge>[] = [
        {
            title: "Time range",
            dataIndex: "startTime",
            key: "startTime",
            className: "col-time",
            width: 228,
            render: (text, record) => (
                <TimeRangePicker
                    id={record.rowKey.toString()}
                    startTime={record.startTime}
                    endTime={record.endTime}
                    onChange={(startTime, endTime) => onTimeChange(record, onUpdateField, startTime, endTime)}
                    disabled={disableFields || (record.isLive ? "start" : false)}
                    className={classNames(
                        checkErrorClass(record.rowKey, "startTime", invalidItems, "has-error__start"),
                        checkErrorClass(record.rowKey, "endTime", invalidItems, "has-error__end")
                    )}
                />
            ),
        },
        {
            title: "Duration",
            dataIndex: "duration",
            key: "duration",
            className: "col-duration",
            width: 74,
            render: (text, record) => {
                if (record.startTime !== undefined && record.endTime !== undefined) {
                    const start = hoursFromTime(record.startTime);
                    const end = hoursFromTime(record.endTime);
                    const diff = end > start ? end - start : end + 24 - start;

                    return <div className="col-duration__label">{diff} hrs</div>;
                }
                return <></>;
            },
        },
        {
            title: "Percent",
            dataIndex: "percentage",
            key: "percentage",
            className: "col-input",
            width: 132,
            render: (text, record) => (
                <InputNumberAddon
                    defaultValue={record.percentage}
                    className={classNames(
                        "input-percentage",
                        checkErrorClass(record.rowKey, "percentage", invalidItems)
                    )}
                    addonAfter="%"
                    displayName="surcharge"
                    disabled={disableFields}
                    onChange={(value) => {
                        onUpdateField && onUpdateField(record, [{ field: "percentage", value }], true);
                    }}
                    placeholder="0"
                    maxLength={5}
                    allowUndefined
                    allowClear
                />
            ),
        },
        {
            title: "Applies to",
            dataIndex: "applyTo",
            key: "applyTo",
            className: "col-applyTo",
            width: 160,
            render: (text, record) => (
                <div className={checkErrorClass(record.rowKey, "applyToDrink", invalidItems)}>
                    <Checkbox
                        id={record.rowKey + "-food"}
                        checked={record.applyToFood}
                        value="Food"
                        onChange={(e) => onCheckChange(record, "applyToFood", e.target.checked, onUpdateField)}
                        disabled={disableFields}
                    >
                        Food
                    </Checkbox>
                    <Checkbox
                        id={record.rowKey + "-drink"}
                        checked={record.applyToDrink}
                        value="Drinks"
                        onChange={(e) => onCheckChange(record, "applyToDrink", e.target.checked, onUpdateField)}
                        disabled={disableFields}
                    >
                        Drinks
                    </Checkbox>
                </div>
            ),
        },
        {
            title: "",
            key: "action",
            width: 32,
            render: (text, record) => (
                <span onClick={() => onClearRow && hasEntries(record) && onClearRow(record)}>
                    <CloseCircleOutlined />
                </span>
            ),
            className: "col-center col-actions",
        },
    ];

    if (!!surchargeIdColumn) {
        columns.splice(3, 0, {
            title: surchargeIdColumn,
            key: "surchargeId",
            dataIndex: "surchargeId",
            className: "col-input",
            render: (text, record) => (
                <div className={checkErrorClass(record.rowKey, "surchargeId", invalidItems)}>
                    <Input
                        value={text}
                        onChange={(e) =>
                            onUpdateField &&
                            onUpdateField(record, [{ field: "surchargeId", value: e.target.value }], true)
                        }
                        disabled={disableFields}
                        placeholder={surchargeIdColumn}
                    />
                </div>
            ),
        });
    }

    return columns;
};
