import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { FieldProps } from "formik";
import "./SearchInput.scss";
import classNames from "classnames";
import { ResolvedDisplayName } from "components/ResolvedDisplayName";

const { Option } = Select;

export interface SelectItem {
    id: string | number;
    displayName: string;
    internalName?: string;
}

export interface SearchInputContainerProps {
    name: string;
    placeholder?: string;
    disabled?: boolean;
    allowClear?: boolean;
    disableSearch?: boolean;
    searchIcon?: boolean;
    loading?: boolean;
}

export interface SearchInputProps extends SearchInputContainerProps {
    options: SelectItem[];
}

interface SearchInputState {
    selectedValue: string | number;
}

export class SearchInput extends React.Component<SearchInputProps & FieldProps, SearchInputState> {
    constructor(props: SearchInputProps & FieldProps) {
        super(props);

        const {
            field: { value },
        } = props;

        this.state = {
            selectedValue: value === null ? undefined : value,
        };
    }

    // FIXME expected option type for antd?
    onFilter = (input: string, option: any) => {
        if (!input) {
            return true;
        }

        if (!option.children) {
            return false;
        }

        const item: SelectItem = (option.children as any).props.item;
        const inputLower = input.toLowerCase();

        return (
            item.displayName.toLowerCase().indexOf(inputLower) >= 0 ||
            (!!item.internalName && item.internalName.toLowerCase().indexOf(inputLower) >= 0)
        );
    };

    onChange = (selectedValue: string | number) => {
        const {
            form,
            field: { name },
        } = this.props;

        this.setState({
            selectedValue,
        });

        form.setFieldTouched(name, true);
        form.setFieldValue(name, selectedValue);
    };

    render() {
        const { options, disabled, allowClear, disableSearch, searchIcon, loading } = this.props;

        const selectOptions = options.map((option) => (
            <Option key={option.id} value={option.id}>
                <ResolvedDisplayName item={option} />
            </Option>
        ));

        return (
            <Select
                className={classNames("search-input", searchIcon && "search-icon")}
                showSearch={!disableSearch}
                allowClear={allowClear}
                placeholder={this.props.placeholder || "Please select a value"}
                optionFilterProp="children"
                onChange={this.onChange}
                filterOption={this.onFilter}
                value={this.state.selectedValue}
                disabled={disabled}
                suffixIcon={searchIcon ? <SearchOutlined /> : undefined}
                loading={loading}
            >
                {selectOptions}
            </Select>
        );
    }
}
