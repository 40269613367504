import { AppState } from "features";
import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { AppDispatch } from "features/state";
import { list } from "../actions";
import { list as groupList } from "features/group/actions";
import { LocationListProps, LocationList } from "../components/LocationList";
import { getActiveLocation } from "../selectors/getLocationPermissions";

function mapStateToProps(state: AppState): DataProps<LocationListProps> {
    const activeLocation = getActiveLocation(state);

    const {
        locations: { list },
    } = state;

    const {
        groups: { list: groupsList },
    } = state;

    if (list.status === "loaded" && groupsList.status === "loaded") {
        return {
            canCreate: activeLocation ? activeLocation.authInfo.permissions.indexOf("location:create") !== -1 : false,
            activeLocation,
            locations: list.data,
            loading: false,
            groups: groupsList.data || [],
        };
    }

    return {
        activeLocation: undefined,
        loading: list.status === "loading",
        canCreate: false,
        locations: undefined,
        groups: undefined,
    };
}

function mapDispatchToProps(dispatch: AppDispatch): FunctionProps<LocationListProps> {
    return {
        fetchLocations: () => {
            dispatch(list());
            dispatch(groupList());
        },
    };
}

export const LocationListContainer = connect(mapStateToProps, mapDispatchToProps)(LocationList);
