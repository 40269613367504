import { opsApi } from "API";
import { GraphQLItem } from "../../../common/scaffolding/types";

export const listCourses = async (location: string) => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { location });
    return res.data.courses;
};

type GraphQLPayload = { courses: GraphQLCourseSummary[] };

export interface GraphQLCourseSummary extends GraphQLItem {}

export const courseListFields = `
    id, 
    internalName, 
    displayName
`;

const query = `
    query($location: ID!) { 
        courses(locationId: $location) { 
            ${courseListFields}
        } 
    }
`;
