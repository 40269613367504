import * as Yup from "yup";
import { ActiveLocation, PosFieldDefinition } from "features/location";
import { WellKnownPosFields } from "./wellKnown";

export function findPosField<O extends keyof WellKnownPosFields, F extends WellKnownPosFields[O]>(
    restaurantLocation: ActiveLocation,
    operationName: O,
    fieldName: F
) {
    return restaurantLocation.posMetadata.operations
        .filter((o) => o.supported)
        .find((o) => o.name === operationName)
        ?.fields.find((f) => f.name === fieldName);
}

export function findPosFieldSchema<O extends keyof WellKnownPosFields, F extends WellKnownPosFields[O]>(
    operationName: O,
    fieldName: F
) {
    return Yup.string().when("$restaurantLocation", (restaurantLocation: ActiveLocation, schema) => {
        const posField = findPosField(restaurantLocation, operationName, fieldName);

        if (posField) {
            return getPosFieldSchema(posField);
        }

        return schema.nullable(true);
    });
}

export function getPosFieldSchema(field: PosFieldDefinition) {
    let schema = Yup.string().nullable(true);

    if (field.validation) {
        const regex = new RegExp(field.validation);

        const multiValueDelimiter = field.input === "lookup" && field.multiValueDelimiter;

        const required = !regex.test("");

        if (required) {
            schema = schema.required("Required");
        }

        schema = schema.test("posField", field.validationMessage ?? "Invalid format", (value?: string) => {
            if (!value) {
                return true; // Covered by 'required' above
            }

            if (!multiValueDelimiter) {
                return regex.test(value);
            }

            return value.split(multiValueDelimiter).every(part => regex.test(part));
        });
    }

    return schema;
}
