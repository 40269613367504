import { DataNode } from "antd/lib/tree";

export interface TreeItem extends DataNode {
    displayName: string;
    id: string;
    internalName?: string | null;
    isLeaf?: boolean;
    isLinked?: boolean;
    disabled?: boolean;
    disableCheckbox?: boolean;
    selectable?: boolean;
    children?: TreeItem[];
    type: "root" | "modifier" | "category" | "product" | "variant" | "option";
    parent?: string;
    key: string;
}

// get all descendants (recursively) of a node as a flattened list
export function getDescendants(node: TreeItem, includeRoot: boolean = false) {
    if (!node) {
        return [];
    }

    const getDescendantsRecursive = (node: TreeItem, nodes: TreeItem[] = []) => {
        nodes.push(node);
        node.children?.forEach((child) => getDescendantsRecursive(child, nodes));
        return nodes;
    };

    const nodes: TreeItem[] = getDescendantsRecursive(node);

    if (includeRoot) {
        return nodes;
    }

    // drop root
    const [_, ...children] = nodes;

    return children;
}
