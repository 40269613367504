import { isNew } from "common/utility/modelUtils";
import { createAction } from "../reducers/edit";
import { EditablePriceListSettings } from "../types";
import { PriceListInputModel, savePriceList } from "../api/savePriceList";
import { scaffoldSaveAction } from "common/scaffolding/actions/scaffoldSaveAction";
import { History } from "history";
import { edit } from "./edit";
import { TabKeys } from "../components/EditPriceListPage";

export const save = (
    locationId: string,
    region: string,
    editablePriceList: EditablePriceListSettings,
    history: History
) =>
    scaffoldSaveAction(
        (state) => state.priceLists.edit,
        createAction,
        async (priceListId: string | undefined) => {
            const priceListInputModel = mapInputModel(editablePriceList);
            return (await savePriceList(locationId, priceListId, priceListInputModel)).id;
        },
        undefined,
        false,
        (id: string) => {
            if (!isNew(editablePriceList.id)) {
                return edit(locationId, editablePriceList.id, TabKeys.Items);
            } else {
                return history.replace(`/${region}/${locationId}/menu/pricelists/${id}`);
            }
        }
    );

const mapInputModel = ({
    id,
    displayName,
    posId,
    useGlobalAdjustment,
    globalAdjustment,
    adjustmentType,
}: EditablePriceListSettings): PriceListInputModel => ({
    displayName,
    posId,
    globalAdjustment: useGlobalAdjustment ? globalAdjustment || null : null,
    isClone: false,
    adjustmentType: useGlobalAdjustment ? adjustmentType : null,
});
