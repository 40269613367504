export const GroupTabsIntro = () => (
    <div className="group-tabs-page__intro">
        <div className="group-tabs-page__intro__header">Introducing Group Tabs</div>
        <div className="group-tabs-page__intro__subheader">Allow customers to start Group Tabs on me&u.</div>
        <div className="group-tabs-page__intro__title">Perfect for group ordering</div>
        <div className="group-tabs-page__intro__subtitle">
            Allow for Tabs without the operational headaches of collecting a credit card behind the bar
        </div>
        <div className="group-tabs-page__intro__title">Created for events</div>
        <div className="group-tabs-page__intro__subtitle">
            Tabs with only house beers and wines for an event this weekend? We have you covered on me&u! You will be
            able to set up pre determined menus
        </div>
        <div className="group-tabs-page__intro__title">Track Group Tabs data</div>
        <div className="group-tabs-page__intro__subtitle">
            Understand how many Tabs are being opened, how much customers are spending on Tabs, and unlock insights for
            group ordering on me&u
        </div>
    </div>
);
