import { isNew } from "common/utility/modelUtils";
import { createAction } from "../reducers/edit";
import { EditableWaiter } from "../types";
import { scaffoldEditAction } from "common/scaffolding/actions/scaffoldEditAction";
import { editWaiter, GraphQLStaff } from "../api/editWaiter";

export const edit = (locationId: string, staffId: string) =>
    scaffoldEditAction(
        staffId,
        (state) => state.waiters.edit,
        createAction,
        async () => {
            if (isNew(staffId)) {
                return newStaff();
            }

            const waiter = await editWaiter(locationId, staffId);
            return mapEditableWaiter(waiter);
        }
    );

const mapEditableWaiter = ({ id, displayName, pin, accessLevel }: GraphQLStaff): EditableWaiter => ({
    id,
    displayName,
    pin,
    accessLevel: accessLevel || "",
});

const newStaff = (): EditableWaiter => ({
    displayName: "",
    pin: "",
    accessLevel: "",
});
