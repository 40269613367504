import { opsApi } from "API";
import { GraphQLInputModel } from "common/scaffolding/types";
import { isNew } from "common/utility/modelUtils";
import { courseListFields, GraphQLCourseSummary } from "./listCourses";

export const saveCourse = async (locationId: string, courseId: string | undefined, course: CourseInputModel) => {
    const isCreate = isNew(courseId);

    const res = isCreate
        ? (await opsApi.graphQLQuery<{ createCourse: GraphQLCourseSummary }>(createQuery, { locationId, course })).data
              .createCourse
        : (
              await opsApi.graphQLQuery<{ updateCourse: GraphQLCourseSummary }>(updateQuery, {
                  locationId,
                  courseId,
                  course,
              })
          ).data.updateCourse;

    return res;
};

export interface CourseInputModel extends GraphQLInputModel {
    sku: string;
    categories: string[];
}

const createQuery = `
    mutation($locationId: ID!, $course: CourseUpdateInput!) {
        createCourse(locationId: $locationId, data: $course) {
            ${courseListFields}
        }
    }
`;

const updateQuery = `
    mutation($locationId: ID!, $courseId: ID!, $course: CourseUpdateInput!) {
        updateCourse(locationId: $locationId, id: $courseId, data: $course) {
            ${courseListFields}
        }
    }
`;
