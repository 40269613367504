import { EditableModifierOption } from "features/modifier/types/EditableModifier";
import { createAction } from "features/modifier/reducers/edit";
import {
    EditableModifierSubmission,
    PrintAsProductTypes,
    ProductSubtypes,
    UpsellModifierType,
} from "features/modifier/types";
import { scaffoldSaveAction } from "common/scaffolding/actions/scaffoldSaveAction";
import { ItemModifierInputModel, saveModifier as save } from "features/modifier/api/saveModifier";
import { History } from "history";
import { AppDispatch, AppState } from "features";
import { getSaveModifierTrackingEventData } from "../selectors/getSaveModifierTrackingEventData";
import { ModifierSummary } from "../../modifier";
import { numberOrUndefined } from "common/utility/numberUtils";
import { setLastItemUpdated } from "features/location/actions/setLastItemUpdated";

export const saveModifier = (
    location: string,
    region: string,
    formData: EditableModifierSubmission,
    onClose: () => void,
    clone: boolean = false,
    history: History
) =>
    scaffoldSaveAction(
        (state) => state.modifiers.edit,
        createAction,
        async (modifierId: string | undefined, dispatch: AppDispatch) => {
            const itemModifierInputModel = mapInputModel(formData, clone);
            const result = await save(location, modifierId, itemModifierInputModel);

            dispatch(setLastItemUpdated());

            return result;
        },
        undefined,
        clone,
        (modifier) => {
            const newUrl = clone
                ? `/${region}/${location}/menu/catalogue/modifiers/${modifier.id}`
                : `/${region}/${location}/menu/catalogue/modifiers`;

            history.replace(`${newUrl}${window.location.search}`);

            return;
        },
        undefined,
        (appState: AppState, saveResult?: ModifierSummary) =>
            getSaveModifierTrackingEventData(appState, formData, saveResult)
    );

const mapInputModel = (
    {
        internalName,
        displayName,
        minSelection,
        maxSelection,
        options,
        sku,
        required,
        items,
        products,
        upsell,
        smartSorting,
        maxSelectionPerOption,
        type,
    }: EditableModifierSubmission,
    clone: boolean
): ItemModifierInputModel => {
    const upsellTypes = mapUpsellTypes(type);

    return {
        internalName,
        displayName,
        minSelection: minSelection !== null && minSelection !== undefined ? Number(minSelection) : null,
        maxSelection: maxSelection !== null && maxSelection !== undefined ? Number(maxSelection) : null,
        options: options.map((option) => mapOptions(option, clone)),
        sku,
        required,
        items,
        products,
        smartSorting,
        maxSelectionPerOption:
            maxSelectionPerOption !== null && maxSelectionPerOption !== undefined
                ? Number(maxSelectionPerOption)
                : null,
        upsell,
        type: upsellTypes.type,
        subtypes: upsellTypes.subtypes,
    };
};

const mapUpsellTypes = (
    type: PrintAsProductTypes | null
): { type: UpsellModifierType | null; subtypes: ProductSubtypes | null } => {
    if (type === "food" || type === "drink") return { type, subtypes: null };
    if (type === "alcoholic-drink") return { type: "drink", subtypes: ProductSubtypes.ALCOHOLIC };
    return { type: null, subtypes: null };
};

export const mapEnergyContent = (energyContent?: number | string | null) =>
    !!energyContent && energyContent !== "" ? parseInt(energyContent?.toString()) : null;

const mapOptions = (option: EditableModifierOption, clone: boolean) => {
    const { isLinked, validationStatus, ...rest } = option;
    return {
        ...rest,
        energyContent: mapEnergyContent(option.energyContent),
        price: numberOrUndefined(option.price),
        id: clone ? undefined : option.id,
    };
};
