import { generateCsvBlob } from "common/data/generateCsvBlob";
import { FEATURE_CONFIG } from "common/feature-variables";
import { downloadBlob } from "common/utility/fileUtils";
import { AppDispatch, AppState } from "features";
import { getIsEnergyContentSupported } from "features/location/selectors/getEnergyContentSupported";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { getModifierCatalogueItems } from "../selectors/getModifierCatalogueItems";
import { CatalogueItemSelections } from "../types";
import { CatalogueExportItem } from "../types/CatalogueExportItem";
import { generateModifierRow, getModifierCSVHeader } from "../csv/ModifierCSVFormat";

export const exportModifiersCSV =
    (selections: CatalogueItemSelections | null = null) =>
    (_: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const modifiers = getModifierCatalogueItems(state) as CatalogueExportItem[];
        const activeLocation = getActiveLocation(state)!;
        const energyContent = getIsEnergyContentSupported(state) || false;
        const upsellTypesSupported = FEATURE_CONFIG.enableUpsellTypes === "1";
        const selectedModifiers = selections ? modifiers.filter((x) => selections[x.id!]) : modifiers;

        const csvBlob = generateCsvBlob<Array<string | null>>((writer) => {
            writer(getModifierCSVHeader(energyContent, upsellTypesSupported));

            for (const modifier of selectedModifiers) {
                generateModifierRow(modifier, writer, energyContent, upsellTypesSupported, selections);
            }
        });

        downloadBlob(csvBlob, `${activeLocation.slug}-modifiers.csv`);
    };
