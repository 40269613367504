import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { Props } from "features/service/components/EditServicePage";
import * as actions from "features/service/actions";
import { EditableService } from "features/service/types";
import { mergeStatus } from "common/loader";
import { actions as menuActions } from "features/menu";
import { actions as categoryActions } from "features/category";
import { actions as courseActions } from "features/course";
import { actions as sectionActions } from "features/section";
import { actions as catalogueActions } from "features/catalogue";
import { actions as priceListActions } from "features/priceList";
import { actions as membershipLevelActions } from "features/membershipLevel";
import { withLoader, PageLoadingProps } from "common/loader/withLoader";
import { ignoreFailed, isLoaded } from "common/loader/isLoaded";
import { AppState } from "features";
import { getCrudPermissions } from "features/location/selectors/getCrudPermissions";
import { DataProps, FunctionProps } from "types/utils";
import { previewService } from "features/preview";
import { getMenuItemsSearchOptions } from "features/service/selectors/getMenuItemsSearchOptions";
import { getLocationLocale } from "features/location/selectors/getLocationLocale";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { EditServicePageWrapper } from "../components/EditServicePage/EditServicePageWrapper";

const mapStateToProps = (
    state: AppState,
    { match: { params } }: RouteComponentProps<RouteParams>
): DataProps<PageLoadingProps<Props>> => {
    const {
        services: { edit },
        menus: { list: menuList },
        categories: { list: categoryList },
        courses: { list: courseList },
        sections: { list: sectionList },
        priceLists: { list: priceListsList },
        membershipLevels: { list: membershipLevelsList },
    } = state;

    // To handle users that don't have access to price lists
    const priceLists = ignoreFailed(priceListsList, () => []);

    const overallFetchStatus = mergeStatus(
        edit.status,
        menuList.status,
        courseList.status,
        categoryList.status,
        sectionList.status,
        priceLists.status,
        membershipLevelsList.status
    );

    const menuItems = getMenuItemsSearchOptions(state);

    if (
        isLoaded(edit) &&
        isLoaded(menuList) &&
        isLoaded(courseList) &&
        isLoaded(categoryList) &&
        isLoaded(sectionList) &&
        isLoaded(priceLists) &&
        isLoaded(membershipLevelsList)
    ) {
        const restaurantLocation = getActiveLocation(state);

        return {
            loadStatus: "loaded",
            saveStatus: edit.saveStatus === "saved" ? "saving" : edit.saveStatus,
            initialValues: edit.data,
            categories: categoryList.data,
            menus: menuList.data,
            courses: courseList.data,
            sections: sectionList.data,
            priceLists: priceLists.data,
            permissions: getCrudPermissions(state).menudata,
            menuItems,
            membershipLevels: membershipLevelsList.data,
            locationLocale: getLocationLocale(state),
            surchargesEnabled: !!restaurantLocation?.enableSurcharges,
            alcoholicDrinksRestrictionEnabled: !!(
                restaurantLocation?.alcoholicDrinksRestriction?.enabled &&
                !restaurantLocation?.alcoholicDrinksRestriction?.applyAcrossAllServices
            ),
        };
    }

    return {
        loadStatus: overallFetchStatus,
    };
};

interface RouteParams {
    location: string;
    id: string;
}

const mapDispatchToProps = (
    dispatch: any,
    { history, match: { params } }: RouteComponentProps<RouteParams>
): FunctionProps<PageLoadingProps<Props>> => ({
    fetch: () => {
        dispatch(actions.edit(params.location, params.id));
        dispatch(categoryActions.list(params.location));
        dispatch(menuActions.list(params.location));
        dispatch(courseActions.list(params.location));
        dispatch(sectionActions.list(params.location));
        dispatch(catalogueActions.listProducts(params.location));
        dispatch(priceListActions.list(params.location));
        dispatch(membershipLevelActions.list(params.location));
    },
    onSubmit: (data: EditableService) => dispatch(actions.save(params.location, data, false, history)),
    onClone: (data: EditableService) => dispatch(actions.save(params.location, data, true, history)),
    onArchive: (data: EditableService) => dispatch(actions.archive(params.location, data, history)),
    previewService: (serviceId: string, membershipLevelId?: string) =>
        dispatch(previewService(serviceId, membershipLevelId)),
});

export const EditServicePageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withLoader(EditServicePageWrapper));
