import { opsApi } from "API";
import { AdjustmentType } from "..";
import { GraphQLItem, GraphQLItemId } from "../../../common/scaffolding/types";
import { PriceLevel } from "../types/PriceLevel";

export const editPriceList = async (locationId: string, priceListId: string): Promise<GraphQLPriceList> => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId, priceListId });
    return res.data.priceList;
};

type GraphQLPayload = { priceList: GraphQLPriceList };

export interface GraphQLPriceList extends GraphQLItem {
    displayName: string;
    posId: string | null;
    globalAdjustment?: number;
    adjustmentType: AdjustmentType | null;
    products: Array<GraphQLPriceListProduct>;
    modifiers: Array<GraphQLPriceListModifier>;
}

export interface GraphQLPriceListProduct extends GraphQLItem {
    price: number;
    variants: Array<GraphQLPriceListVariant>;
    sku: string;
    template: { type: string };
    status: number;
    categories: Array<GraphQLItemId>;
    priceLevels: Array<PriceLevel>;
}

export interface GraphQLPriceListVariant {
    id: string;
    price: number;
    taxRate: number | null;
    sku: string;
    internalName: string | null;
    displayName: string;
    priceLevels: Array<PriceLevel>;
}

export interface GraphQLPriceListModifier extends GraphQLItem {
    sku: string;
    options: Array<GraphQLPriceListModifierOption>;
}

export interface GraphQLPriceListModifierOption {
    id: string;
    displayName: string;
    internalName: string | null;
    price: number;
    sku: string;
    priceLevels: Array<PriceLevel>;
}

const query = `
    query ($locationId: ID!, $priceListId: ID!) { 
        priceList(locationId: $locationId, priceListId: $priceListId) {
            id,
            displayName,
            posId,
            globalAdjustment,
            adjustmentType,
            products {
                id,
                internalName,
                displayName,
                price,
                categories {
                    id
                },
                variants {
                    id,
                    price,
                    taxRate,
                    sku,
                    displayName,
                    priceLevels {
                        priceList,
                        price
                    }
                },
                sku,
                priceLevels {
                    priceList,
                    price
                }
             }
            modifiers {
                id,
                internalName,
                displayName,
                sku,
                options {
                    id,
                    displayName,
                    price,
                    sku,
                    priceLevels {
                        priceList,
                        price
                    }
                }
            }
        }
    }
`;
