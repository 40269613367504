// inspired by https://usehooks.com/useOnClickOutside

import { MutableRefObject, useEffect } from "react";

/* `handler` should be memoised for best performance */
export function useOnClickOutside(
    ref: MutableRefObject<HTMLElement | null>,
    handler: (event: TouchEvent | MouseEvent) => void,
    enable: boolean
) {
    useEffect(() => {
        const listener = (event: TouchEvent | MouseEvent) => {
            if (!ref.current || ref.current.contains(event.target as Node | null)) {
                // click inside
                return;
            }
            handler(event);
        };

        if (enable) {
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
        }

        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref, handler, enable]);
}
