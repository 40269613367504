import styles from "../ListItem.module.scss";

import classNames from "classnames";
import { getPosName } from "features/location/selectors/getPosName";
import { useMedia } from "common/hooks";
import { useSelector } from "react-redux";

export interface HeadersProps {}

export const Headers = ({}: HeadersProps) => {
    const isMedium = useMedia("(min-width: 672px)");
    const posName = useSelector(getPosName);

    return (
        <div className={styles.contentContainer}>
            <span role="columnheader" className={classNames(styles.cellHeader, styles.cellName)}>
                {isMedium ? "Modifier" : "Modifiers"}
            </span>
            <span
                role="columnheader"
                className={classNames(styles.cellHeader, styles.cellSmall, styles.cellNumber, "hide-until-medium")}
            >
                Price
            </span>
            <span
                role="columnheader"
                className={classNames(
                    styles.cellHeader,
                    styles.cellSmall,
                    styles.cellNumber,
                    styles.cellFirstWrap,
                    "hide-until-medium"
                )}
            >
                {`${posName} ID`}
            </span>
            {/* TODO https://meanduapp.atlassian.net/browse/INT-277 */}
            {/* <span
            role="cell"
            className={classNames(
                styles.cellHeader,
                styles.cellSmall,
                styles.cellNumber,
                styles.cellHideLast
            )}
        >
            Calories
        </span> */}
        </div>
    );
};
