import styles from "./StripeSetupStep.module.scss";

import { ColorScheme, Status } from "core/components/status";

export interface StripeSetupStepStatus {
    label: string;
    color: ColorScheme;
}

interface Props {
    title: string;
    description: string;
    status: StripeSetupStepStatus;
}

export const StripeSetupStep = ({ title, description, status }: Props) => {
    return (
        <div className={styles.root}>
            <div className={styles.detailsContainer}>
                <h3 className={styles.title}>{title}</h3>
                <p className={styles.description}>{description}</p>
            </div>
            <div className={styles.statusContainer}>
                <Status colorScheme={status.color}>{status.label}</Status>
            </div>
        </div>
    );
};
