import { LoadStatus, mergeStatus } from "common/loader";
import { useEffect } from "react";

export interface PageLoadOptions {
    refetchOnMount?: boolean;
}

export function useLoadStatus(statuses: LoadStatus[], fetch?: () => void, options?: PageLoadOptions) {
    const status = mergeStatus(...statuses);

    useEffect(() => {
        if (options?.refetchOnMount) {
            fetch?.();
        }

        // only trigger on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (status === "unloaded" && !options?.refetchOnMount) {
            fetch?.();
        }
    }, [status, fetch, options?.refetchOnMount]);

    return status;
}
