import styles from "./ProductModifers.module.scss";

import { Card, Row, Title } from "core/components/card";
import { EditableProduct } from "features/catalogue/types";
import { Field, FieldArray, useFormikContext } from "formik";
import { EditableModifier, ModifierSummary } from "features/modifier";
import { filterOptionByLabel, Option, Select } from "core/components/form/select";
import { useCallback, useMemo } from "react";
import { Modifiers } from "./Modifiers";
import { ActionMeta } from "react-select";
import { LinkedItemBadge } from "../../controlledItem/LinkedItemBadge";
import { getIsChildLocation } from "features/location/selectors/getIsChildLocation";
import { useSelector } from "react-redux";

interface Props {
    disableFields: boolean;
    modifiers: ModifierSummary[];
}

interface ModifierGroupSelectOption extends Option {
    isLinked: boolean;
    secondaryLabel: string;
    value: ModifierSummary;
}

export const ProductModifiers = ({ disableFields, modifiers }: Props) => {
    const {
        values: { prices },
        setFieldValue,
    } = useFormikContext<EditableProduct>();

    const isChildLocation = useSelector(getIsChildLocation) || false;

    const modifierGroupOptions = useMemo(() => {
        return modifiers.map((m) => ({
            label: m.displayName + (m.required ? " *" : ""),
            secondaryLabel: m.internalName,
            value: m,
            isLinked: m.isLinked && isChildLocation,
        }));
    }, [modifiers, isChildLocation]);

    const onModifierRemoved = useCallback(
        (modifier: ModifierSummary | EditableModifier) => {
            if (!modifier) {
                return;
            }

            prices.forEach(({ modifiers }, variantIndex) => {
                setFieldValue(
                    `prices.${variantIndex}.modifiers`,
                    modifiers.filter((m) => m !== modifier.id)
                );
            });
        },
        [prices, setFieldValue]
    );

    const handleModifiersChange = useCallback(
        (_, meta: ActionMeta<Option>) => {
            const { action, option } = meta;

            const modifier = option?.value;

            if (!modifier) {
                return;
            }

            if (action === "deselect-option") {
                onModifierRemoved(modifier);
                return;
            }

            if (action === "select-option") {
                // add newly added modifier to all variants
                prices.forEach(({ modifiers }, variantIndex) => {
                    setFieldValue(`prices.${variantIndex}.modifiers`, [...new Set([...modifiers, modifier.id])]);
                });
            }

            // return true to proceed with Select's default field change handler
            return true;
        },
        [onModifierRemoved, prices, setFieldValue]
    );

    return (
        <Card>
            <Row collapse="down">
                <Title title="Modifiers" />
            </Row>

            <Row>
                <div className={styles.modifierSelect}>
                    <Field
                        blurInputOnSelect={false}
                        closeMenuOnSelect={false}
                        component={Select}
                        controlShouldRenderValue={false}
                        disabled={disableFields}
                        disableError={true}
                        optionSelectedTest={modifierOptionSelectedTest}
                        filterOption={filterOptionByLabel}
                        formatOptionLabel={formatModifierOption}
                        isMulti
                        label="Add modifier"
                        menuPlacement="auto"
                        name="modifiers"
                        options={modifierGroupOptions}
                        onChange={handleModifiersChange}
                        placeholder="Select modifiers"
                        search={true}
                    />
                </div>
            </Row>

            <Row className={styles.modifiersRow} collapse="up">
                <FieldArray
                    name="modifiers"
                    render={(props) => (
                        <Modifiers {...props} disableFields={disableFields} onModifierRemoved={onModifierRemoved} />
                    )}
                />
            </Row>
        </Card>
    );
};

const modifierOptionSelectedTest = (value: ModifierSummary, option: ModifierGroupSelectOption) => {
    if (!value || !option.value) {
        return false;
    }
    return value.id === option.value.id;
};

function formatModifierOption({ isLinked, label, secondaryLabel }: ModifierGroupSelectOption) {
    return (
        <>
            {isLinked && <LinkedItemBadge />}
            <span>{label}</span> {secondaryLabel && <span className={styles.labelSecondary}>({secondaryLabel})</span>}
        </>
    );
}
