import styles from "./ListItem.module.scss";

import classNames from "classnames";

export const Headers = () => {
    return (
        <div className={styles.contentContainer}>
            <span role="columnheader" className={classNames(styles.cellHeader, styles.cellGrow)}>
                <span className="sr-only-until-medium">Venue name</span>
                <span aria-hidden className="hide-on-medium">
                    Venues
                </span>
            </span>
            <span role="columnheader" className={classNames(styles.cellHeader, styles.cellMedium)}>
                <span className="sr-only-until-medium">Last updated</span>
            </span>
            <span role="columnheader" className={classNames(styles.cellHeader, styles.cellSmall)}>
                <span className="sr-only-until-medium">Status</span>
            </span>
            <span role="columnheader" className={classNames(styles.cellTiny)}>
                <span className="sr-only">Remove</span>
            </span>
        </div>
    );
};
