import { connect } from "react-redux";
import * as actions from "../actions";
import { PageLoadingProps, withLoader } from "common/loader/withLoader";
import { EditBrandingPage, Props } from "../components/EditBrandingPage";
import { withRouter, RouteComponentProps } from "react-router";
import { withLocation, LocationComponentProps } from "features/location";
import { AppState } from "features";
import { AppDispatch } from "features/state";
import { FunctionProps, DataProps } from "types/utils";
import { Branding } from "../types";
import { actions as locationActions } from "features/location";
import { config } from "../../../common/config";
import { getLocationPermissions } from "features/location/selectors/getLocationPermissions";

export const meanduColor = "#7B8DAF";

export const replaceImageUrlWithOrig = (url?: string) => (url ? url.replace("-full", "-orig") : null);

const mapStateToProps = (
    state: AppState,
    { restaurantLocation }: LocationComponentProps
): DataProps<PageLoadingProps<Props>> => {
    const locationPermissions = getLocationPermissions(state);
    return {
        meanduColor,
        loadStatus: "loaded",
        saveStatus: state.locations.edit.saveStatus,
        initialValues: {
            heroImage: replaceImageUrlWithOrig(restaurantLocation.heroImage),
            backgroundImage: replaceImageUrlWithOrig(restaurantLocation.backgroundImage),
            menuBackgroundImage: replaceImageUrlWithOrig(restaurantLocation.menuBackgroundImage),
            defaultCatalogueImage: restaurantLocation.defaultCatalogueImage,
            themeColor: restaurantLocation.themeColor || meanduColor,
            logoUrl: restaurantLocation.logoUrl || null,
            useCustomColor: restaurantLocation.themeColor ? restaurantLocation.themeColor !== meanduColor : false,
        },
        menuBackgroundImageEnabled:
            config.REACT_APP_ENABLE_MENU_BACKGROUND_IMAGE === "1" &&
            locationPermissions.has("menudata:backgroundImage:update"),
    };
};

interface RouteParams {
    location: string;
    id: string;
}

const mapDispatchToProps = (
    dispatch: AppDispatch,
    { restaurantLocation, match: { params } }: LocationComponentProps & RouteComponentProps<RouteParams>
): FunctionProps<PageLoadingProps<Props>> => ({
    fetch: () => dispatch(locationActions.edit(restaurantLocation.id)),
    onSubmit: (data: Branding) => dispatch(actions.save(params.location, data)),
});

export const EditBrandingPageContainer = withRouter(
    withLocation(connect(mapStateToProps, mapDispatchToProps)(withLoader(EditBrandingPage)))
);
