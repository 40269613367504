import { useEffect, useRef, useState } from "react";

export default function useTimeoutToggle(timeout = 3000) {
    const [state, setState] = useState(false);
    const toggleTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
    const resetTimeout = () => {
        toggleTimeout.current && clearTimeout(toggleTimeout.current);
    };

    useEffect(() => {
        if (state) {
            toggleTimeout.current = setTimeout(() => setState(false), timeout);
        }
        return resetTimeout;
    }, [state, timeout]);

    return [state, () => setState(true)] as const;
}
