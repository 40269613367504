import { actions as catalogueActions } from "features/catalogue";
import { actions as modifierActions } from "features/modifier";
import { AppDispatch, AppState } from "features";
import { uploadPosCatalogue } from "../api/uploadPosCatalogue";
import { createAction } from "../reducers/upload";
import { getPosName } from "features/location/selectors/getPosName";
import {
    ModifierGroupImportInput,
    ModifierOptionImportInput,
    PosCatalogueImportItem,
    PosCatalogueImportVariant,
    PosCatalogueUpload,
    PosCatalogueUploadInput,
    ProductImportInput,
    VariantImportInput,
} from "../types";
import { trackEventAddImportSucceed, trackEventAddImportFailed } from "./trackEventImportPos";

export function upload(locationId: string, posCatalogue: PosCatalogueUpload) {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();

        if (state.posCatalogueImport.upload.status === "loading") {
            return;
        }
        dispatch(createAction.loading());
        const posName = getPosName(state);
        try {
            const payload: PosCatalogueUploadInput = mapPosCatalogueUpload(posCatalogue);

            const result = await uploadPosCatalogue(locationId, payload, false);

            if (!result.startedRunning) {
                throw "Import failed - there is already an operation in progress for this venue";
            }
            dispatch(createAction.loaded(result));

            const modifierChanges = result.summary?.modifierGroups?.length || result.summary?.modifierOptions?.length;
            const productChanges = result.summary?.products?.length || result.summary?.variants?.length;

            if (modifierChanges) {
                dispatch(modifierActions.list(locationId, true));
            }

            if (productChanges) {
                dispatch(catalogueActions.listProducts(locationId, true));
            }

            trackEventAddImportSucceed(result, posName, payload);
        } catch (e: any) {
            dispatch(createAction.failed(e));
            trackEventAddImportFailed(e.message, posName);
        }
    };
}

function mapPosCatalogueUpload({
    products,
    modifiers,
    includeProductModifierRelationships,
}: PosCatalogueUpload): PosCatalogueUploadInput {
    return {
        products: mapPosCatalogueUploadProducts(products),
        modifiers: mapPosCatalogueUploadModifierGroups(modifiers),
        includeProductModifierRelationships,
    };
}

function mapPosCatalogueUploadProducts(products: PosCatalogueImportItem[]): ProductImportInput[] {
    return products.map(({ children, displayName, key, id, energyContent, description }) => ({
        description,
        displayName,
        // TODO https://meanduapp.atlassian.net/browse/INT-277
        // energyContent,
        externalId: id!,
        sourceId: key || "",
        variants: children ? mapPosCatalogueUploadVariants(children as PosCatalogueImportVariant[]) : [],
    }));
}

function mapPosCatalogueUploadVariants(children: PosCatalogueImportVariant[]): VariantImportInput[] {
    return children.map(
        ({ displayName, key, id, energyContent, modifiers, price, sku, selected }) =>
            ({
                description: "",
                displayName,
                doImport: selected || false,
                energyContent: energyContent ?? null,
                externalId: id!,
                modifierGroupIds: modifiers,
                sourceId: key,
                internalName: displayName,
                price: price || 0,
                sku: sku!,
            } || [])
    );
}

function mapPosCatalogueUploadModifierGroups(modifierGroups: PosCatalogueImportItem[]): ModifierGroupImportInput[] {
    return modifierGroups.map(({ children, displayName, key, id, sku }) => ({
        displayName,
        externalId: id!,
        options: children ? mapPosCatalogueUploadModifierOptions(children) : [],
        sourceId: key,
        upsell: false,
        sku: sku!,
    }));
}

function mapPosCatalogueUploadModifierOptions(children: PosCatalogueImportItem[]): ModifierOptionImportInput[] {
    return children
        .filter((child) => child.selected)
        .map(
            ({ displayName, key, id, energyContent, price, sku }) =>
                ({
                    displayName,
                    externalId: id!,
                    sourceId: key,
                    price: price || 0,
                    sku: sku!,
                } || [])
        );
}
