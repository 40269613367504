import * as Yup from "yup";
import { EditableVenueMembershipProgram } from "../types";

export const getEditableMembershipSchema = () =>
    Yup.object<EditableVenueMembershipProgram>().shape({
        displayName: Yup.string()
            .max(45, "Loyalty program name must be between 1 and 45 characters")
            .required("Enter a loyalty program name"),
        privacyPolicyLink: Yup.string().nullable(true).required("Add your privacy policy to show to customers at sign up"),
        priceListId: Yup.string().required("Select a Price List"),
        levelId: Yup.string(),
        type: Yup.string(),
    });
