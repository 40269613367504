import { AppState } from "features";
import { getAlcoholicDrinkRestrictionAccess } from "features/location/selectors/getAlcoholicDrinkRestrictionAccess";
import * as actions from "../actions";
import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { PageLoadingProps, withLoader } from "common/loader/withLoader";
import { RouteComponentProps, withRouter } from "react-router";
import { isLoaded } from "common/loader/isLoaded";
import { EditableLocationSettings } from "../types";
import { EditLocationSettingsPage, Props } from "../components/EditLocationSettingsPage";
import { LocationComponentProps, withLocation } from "features/location/containers/withLocation";
import { mergeStatus } from "common/loader/mergeStatus";
import { actions as sectionActions } from "features/section";
import { getLocationPermissions } from "features/location/selectors/getLocationPermissions";
import { PermissionSet } from "features/location/types/PermissionSet";
import { flush } from "common/appInsights";
import { getIsGroupTabGratuitySupported } from "../../location/selectors/getGroupTabsGratuitySupported";
import { getIsPosSyncSupported } from "features/location/selectors/getPosMenuSupported";
import { getTrayChargeSettingsAccess } from "features/locationSettings/selectors/getTrayChargeSettingsAccess";
import { actions as modifierActions } from "features/modifier";
import { actions as catalogueActions } from "features/catalogue";
import { getVenueServiceFeeSettingsAccess } from "../selectors/getVenueServiceFeeSettingsAccess";
import { getIsAbsorbMerchantFeeSupported } from "../../location/selectors/getAbsorbMerchantFeeSupported";
import { getShowSplitPaymentOptions } from "features/location/selectors/getActiveLocation";

const mapStateToProps = (
    state: AppState,
    { match: { params } }: RouteComponentProps<RouteParams>
): DataProps<PageLoadingProps<Props>> => {
    const {
        LocationSettings: { edit },
        sections: { list: sectionsList },
        locations: { active: activeLocation },
    } = state;

    const overallFetchStatus = mergeStatus(edit.status, sectionsList.status);

    if (isLoaded(edit) && isLoaded(sectionsList) && isLoaded(activeLocation)) {
        const groupPermissions = new PermissionSet(edit.data.group ? edit.data.group.authInfo.permissions : []);
        const hasGroupEditPermission = groupPermissions.has("group:update");

        const permissions = getLocationPermissions(state);
        const trayChargeSettingsAccess = getTrayChargeSettingsAccess(state);
        const venueServiceFeeSettingsAccess = getVenueServiceFeeSettingsAccess(state);
        const alcoholicDrinksRestrictionAccess = getAlcoholicDrinkRestrictionAccess(state);
        const hasUpdatePermission = permissions.hasAny("settings:update");
        const hasLocationUpdatePermission = permissions.hasAny("location:settings:update");
        const hideLiveOrdersCheckbox = !permissions.hasAny("location:deprecated:read");
        const hasLocationFlagUpdatePermission = permissions.hasAny("location:settings:flag:update");
        const levels = edit.data.tipOptions.levels || [];
        const tipOptions = {
            ...edit.data.tipOptions,
            levels,
        };

        const groupTabGratuitySupported = getIsGroupTabGratuitySupported(activeLocation?.data);
        const absorbMerchantFeeSupported = getIsAbsorbMerchantFeeSupported(activeLocation.data); // TODO: add permissions check

        const posSyncSupported = (getIsPosSyncSupported(state) && permissions.hasAny("menudata:pos:sync")) || false;

        return {
            initialValues: {
                ...edit.data,
                tipOptions,
                // Original values will be null, can explicitly save to 0, only replace if null (data migration)
                drinkOrderBatchTimeSeconds: edit.data.drinkOrderBatchTimeSeconds ?? edit.data.orderBatchTimeSeconds,
                foodOrderBatchTimeSeconds: edit.data.foodOrderBatchTimeSeconds ?? edit.data.orderBatchTimeSeconds,
            },
            saveStatus: edit.saveStatus,
            sections: sectionsList.data,
            location: activeLocation.data,
            loadStatus: "loaded",
            hasUpdatePermission,
            hasGroupEditPermission,
            hideLiveOrdersCheckbox,
            hasLocationUpdatePermission,
            groupTabGratuitySupported,
            posSyncSupported,
            trayChargeSettingsAccess,
            venueServiceFeeSettingsAccess,
            alcoholicDrinksRestrictionAccess,
            hasLocationFlagUpdatePermission,
            absorbMerchantFeeSupported,
            showSelectSplitPaymentOptions: getShowSplitPaymentOptions(state),
        };
    }

    return {
        loadStatus: overallFetchStatus,
    };
};

interface RouteParams {
    location: string;
}

const onSave = () => {
    flush();
};

const mapDispatchToProps = (
    dispatch: any,
    { restaurantLocation, match: { params } }: LocationComponentProps & RouteComponentProps<RouteParams>
): FunctionProps<PageLoadingProps<Props>> => ({
    fetch: () => {
        dispatch(actions.edit(restaurantLocation.id));
        dispatch(sectionActions.list(params.location));
    },
    onSubmit: (data: EditableLocationSettings) => {
        dispatch(actions.save(restaurantLocation.id, data, onSave));
        dispatch(modifierActions.list(restaurantLocation.id, true));
        dispatch(catalogueActions.listProducts(restaurantLocation.id, true));
    },
});

export const EditLocationSettingsPageContainer = withRouter(
    withLocation(connect(mapStateToProps, mapDispatchToProps)(withLoader(EditLocationSettingsPage)))
);
