import "./DailySalesDetailsModal.scss";
import { DailySalesDetails } from "../types";
import { LocationLocaleContext } from "features/location";
import { Tooltip } from "antd";

export interface Props {
    details: DailySalesDetails;
    groupTabGratuitySupported: boolean;
}

export const DailySalesDetailsModal = ({ details, groupTabGratuitySupported }: Props) => {
    return (
        <LocationLocaleContext.Consumer>
            {(locale) => (
                <div className="dailysales__details__content">
                    <table className="dailysales__details__figures">
                        <tbody>
                            <tr>
                                <th>{tooltipLabel(fieldLabels.sales)}</th>
                                <td>{locale.formatCurrency(details.sales)}</td>
                            </tr>
                            <tr>
                                <th>{tooltipLabel(fieldLabels.surcharges)}</th>
                                <td>({locale.formatCurrency(details.surcharges)})</td>
                            </tr>
                            <tr>
                                <th>{tooltipLabel(fieldLabels.venueServiceFee)}</th>
                                <td>({locale.formatCurrency(details.venueServiceFees)})</td>
                            </tr>
                            <tr>
                                <th>
                                    {tooltipLabel(
                                        groupTabGratuitySupported ? fieldLabels.tipsAndGratuity : fieldLabels.tips
                                    )}
                                </th>
                                <td>{locale.formatCurrency(details.tips)}</td>
                            </tr>
                            <tr>
                                <th>{tooltipLabel(fieldLabels.subTotal)}</th>
                                <td>{locale.formatCurrency(details.subTotal)}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="dailysales__details__figures">
                        <tbody className="dailysales__details__figures__refunds">
                            <tr>
                                <th>{tooltipLabel(fieldLabels.refunded)}</th>
                                <td>{locale.formatCurrency(0 - details.refunded)}</td>
                            </tr>
                            <tr>
                                <th>{tooltipLabel(fieldLabels.grandTotal)}</th>
                                <td>{locale.formatCurrency(details.grandTotal)}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="dailysales__details__figures">
                        <tbody>
                            <tr>
                                <th>{tooltipLabel(fieldLabels.absorbedMerchantFees)}</th>
                                <td>{locale.formatCurrency(0 - details.absorbedProcessingFee)}</td>
                            </tr>
                            <tr>
                                <th>{tooltipLabel(fieldLabels.fee)}</th>
                                <td>{locale.formatCurrency(0 - details.fee)}</td>
                            </tr>
                            <tr>
                                <th>{tooltipLabel(fieldLabels.remitted)}</th>
                                <th>{locale.formatCurrency(details.remitted)}</th>
                            </tr>
                        </tbody>
                    </table>

                    <div className="dailysales__details__remittance">
                        <table>
                            <thead>
                                <tr>
                                    <th>{tooltipLabel(fieldLabels.paymentDate)}</th>
                                    <th>{tooltipLabel(fieldLabels.remittanceProcessed)}</th>
                                    <th>{tooltipLabel(fieldLabels.absorbedMerchantFees, true)}</th>
                                    <th>{tooltipLabel(fieldLabels.fee, true)}</th>
                                    <th>{tooltipLabel(fieldLabels.remitted)}</th>
                                    <th>{tooltipLabel(fieldLabels.paymentDateRemittance)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {details.remittance.map((remittance, index) => (
                                    <tr key={index}>
                                        <td>{locale.formatDate(new Date(remittance.paymentDate))}</td>
                                        <td>{locale.formatCurrency(remittance.processed)}</td>
                                        <td>{locale.formatCurrency(0 - remittance.absorbedProcessingFee)}</td>
                                        <td>{locale.formatCurrency(0 - remittance.fee)}</td>
                                        <td>{locale.formatCurrency(remittance.remitted)}</td>
                                        <td>
                                            <Tooltip
                                                placement="bottomLeft"
                                                title={`Total remitted on ${locale.formatDate(
                                                    new Date(remittance.paymentDate)
                                                )}`}
                                            >
                                                {locale.formatCurrency(remittance.dailyRemittedTotal)}
                                            </Tooltip>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </LocationLocaleContext.Consumer>
    );
};

type TooltipLabelProps = {
    label: string;
    label2?: string;
    tooltip: string;
};

const tooltipLabel = ({ label, label2, tooltip }: TooltipLabelProps, breakLabels = false) => (
    <Tooltip placement="bottomLeft" title={tooltip} className="hastooltip">
        {label}
        {label2 && (breakLabels ? <br /> : " ")}
        {label2}
    </Tooltip>
);

const fieldLabels = {
    diningDate: {
        label: "Dining Date",
        tooltip: "Date when customer dined",
    },
    sales: {
        label: "Sales",
        tooltip: "Total me&u sales on this dining date",
    },
    tips: {
        label: "Tips",
        tooltip: "Total me&u tips on this dining date",
    },
    tipsAndGratuity: {
        label: "Tips + Gratuity",
        tooltip: "Total me&u tips and gratuity on this dining date",
    },
    subTotal: {
        label: "Subtotal",
        tooltip: "Sales plus tips",
    },
    grandTotal: {
        label: "Grand Total",
        tooltip: "Sub total less refunds",
    },
    absorbedMerchantFees: {
        label: "Absorbed",
        label2: "merchant fees",
        tooltip: "Absorbed merchant fees charged on a payment",
    },
    fee: {
        label: "me&u fee",
        label2: "(% of Sales Total)",
        tooltip: "me&u fee charged on a payment",
    },
    remitted: {
        label: "Remitted",
        tooltip: "Processed minus me&u fee. Note: This is the amount transferred to the restaurant's bank account",
    },
    surcharges: {
        label: "Surcharges (included in Sales)",
        tooltip: "Surcharge total across all orders",
    },
    venueServiceFee: {
        label: "Venue Service Fee (included in Sales)",
        tooltip: "Venue Service Fee total across all orders",
    },
    refunded: {
        label: "Refunded",
        tooltip: "Total refunds issued on this dining date",
    },
    paymentDate: {
        label: "Payment Date",
        tooltip: "Date when me&u transferred funds to the restaurant's bank account",
    },
    paymentDateRemittance: {
        label: "Total Remitted",
        tooltip: "Total amount remitted on this payment date. May cover several dining dates",
    },
    remittanceProcessed: {
        label: "Processed",
        tooltip: "Remitted plus me&u fee",
    },
};
