import styles from "./Tabs.module.scss";

import { PropsWithChildren } from "react";
import classNames from "classnames";

// export with children for typings in RouteTabs which clones element
export interface RouteTabPanelProps extends PropsWithChildren<any> {
    className?: string;
    id: string;
    label: string;
}

export const RouteTabPanel = ({ children, className, id }: RouteTabPanelProps) => {
    return (
        <div
            id={`${id}-tabpanel`}
            role="tabpanel"
            aria-labelledby={id}
            className={classNames(styles.tabPanel, className)}
        >
            {children}
        </div>
    );
};
