import { connect } from "react-redux";
import { ListServicesPageWrapper, Props } from "features/service/components/ListServicesPage";
import * as actions from "features/service/actions";
import { RouteComponentProps } from "react-router";
import { PageLoadingProps, withLoader } from "common/loader/withLoader";
import { AppState } from "features";
import { getCrudPermissions } from "features/location/selectors/getCrudPermissions";
import { DataProps, FunctionProps } from "types/utils";
import { LocationComponentProps, withLocation } from "../../location";
import { getActiveServices, getInactiveSections, getInactiveServices } from "../selectors/getActiveServices";
import { getCurrentDayOfWeek, getPreviewVenueTime, getVenueTime } from "../selectors/getVenueTime";
import { actions as sectionActions } from "../../section";
import { mergeStatus } from "../../../common/loader";
import { isLoaded } from "../../../common/loader/isLoaded";
import { createAction as previewActions } from "../reducers/preview";
import { LocationRouteParams } from "common/types";
import { getLocationLocale } from "features/location/selectors/getLocationLocale";

interface OwnProps extends LocationComponentProps, RouteComponentProps<LocationRouteParams> {}

const mapStateToProps = (
    state: AppState,
    { match: { params }, restaurantLocation: { timeZone, enableSurcharges } }: OwnProps
): DataProps<PageLoadingProps<Props>> => {
    const {
        services: { list },
        sections: { list: sectionList },
    } = state;

    const overallFetchStatus = mergeStatus(list.status, sectionList.status);

    if (isLoaded(list) && isLoaded(sectionList)) {
        return {
            loadStatus: list.status,
            permissions: getCrudPermissions(state).menudata,
            liveServices: getActiveServices(state),
            otherServices: getInactiveServices(state),
            inactiveSections: getInactiveSections(state),
            venueTime: getVenueTime(state),
            currentDayOfWeek: getCurrentDayOfWeek(state),
            previewVenueTime: getPreviewVenueTime(state),
            sections: sectionList.data,
            locationLocale: getLocationLocale(state),
            surchargesEnabled: enableSurcharges,
        };
    }

    return {
        loadStatus: overallFetchStatus,
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { match: { params } }: RouteComponentProps<LocationRouteParams>
): FunctionProps<PageLoadingProps<Props>> => ({
    fetch: () => {
        dispatch(actions.list(params.location));
        dispatch(sectionActions.list(params.location));
    },
    setPreviewDate: (previewDate) => dispatch(previewActions.setPreviewDate(previewDate)),
    resetPreviewDate: () => dispatch(previewActions.resetPreviewDate()),
});

export const ListServicesPageContainer = withLocation(
    connect(mapStateToProps, mapDispatchToProps)(withLoader(ListServicesPageWrapper))
);
