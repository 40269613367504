import { opsApi } from "API";
import { PosConfigurationSetting } from "../types/PosConfigurationSetting";

export const getPosLookup = async (slug: string, lookupType: string, scope: string | undefined) => {
    // Multiple lookups can be loaded at once, so alias them
    const alias = `${lookupType === "" ? "default" : lookupType}PosLookup`;

    const vars = {
        locationId: slug,
    };

    vars[`lookupType${alias}`] = lookupType;
    vars[`lookupScope${alias}`] = scope ?? null;

    const res = await opsApi.graphQLQuery<GraphQLPayload>(query(alias), vars);
    return res.data[alias];
};

type GraphQLPayload = { posConfiguration: PosConfigurationSetting[] };

export const posLookupFields = `
    description
    items {
        key,
        value
    }
`;

const query = (alias: string) => `
    query ($locationId: ID!, $lookupType${alias}: String!, $lookupScope${alias}: String) {
        ${alias}: posLookup(locationId: $locationId, lookupType: $lookupType${alias}, lookupScope: $lookupScope${alias}) {
            ${posLookupFields}
        }
    }
`;
