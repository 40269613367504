import styles from "./Calendar.module.scss";

import { CalendarDateFormat, useCalendarContext } from "features/service/context/CalendarContext";
import { CalendarItem } from "./CalendarItem";
import { CurrentTime } from "./CurrentTime";
import { getCalendarStatus } from "features/service/selectors/getCalendar";
import { getNow } from "features/service/selectors";
import { gridDefaultColumnWidth, gridIncrementY, yLabelsContanerWidth } from "./calendarGridSettings";
import { NavButton } from "core/components/navButton/NavButton";
import { PageLoading } from "core/components/pageLoading";
import { ServiceCalendarSectionItem, ServiceSectionCalendarDay } from "features/service/types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useScrollbarWidth } from "common/hooks";
import { useSelector } from "react-redux";
import classNames from "classnames";
import useResizeObserver from "use-resize-observer";

type Props =
    | { viewMode: "day"; calendarItems?: ServiceCalendarSectionItem[]; today?: never }
    | { viewMode: "week"; calendarItems?: ServiceSectionCalendarDay[]; today: Date };

const yLabels = getYLabels();

export const Calendar = ({ calendarItems, today, viewMode }: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const [columnWidth, setColumnWidth] = useState(gridDefaultColumnWidth);

    const autoScrollRef = useRef(true);

    const scrollbarWidth = useScrollbarWidth(containerRef);

    const [showNavPrevious, setShowNavPrevious] = useState(false);

    const [showNavNext, setShowNavNext] = useState(false);

    const [scrollIndex, setScrollIndex] = useState(0);

    const [scrollIndexOffset, setScrollIndexOffset] = useState(0);

    const { headerPinned, weekStartDateString, singleDayDateString } = useCalendarContext();

    const xLabels = useMemo(() => {
        if (viewMode === "week") {
            return getXDateLabels(weekStartDateString ? new Date(weekStartDateString) : undefined, today);
        } else {
            // for some reason TS type narrowing not working here
            return getXSectionLabels((calendarItems as ServiceCalendarSectionItem[]) ?? []);
        }
    }, [calendarItems, weekStartDateString, today, viewMode]);

    const loadStatus = useSelector(getCalendarStatus);

    const venueNow = useSelector(getNow);

    // this controls whether the columns are allowed to expand to fill the available space
    const useFixedColumnWidth = (calendarItems?.length || 0) < 6;

    const todayColumnIndex = useMemo(() => {
        if (viewMode === "day") {
            if (!singleDayDateString) {
                return -1;
            }

            return singleDayDateString === venueNow.format(CalendarDateFormat) ? 0 : -1;
        }

        return xLabels.findIndex((label) => label.highlight);
    }, [singleDayDateString, venueNow, viewMode, xLabels]);

    const handleContainerResize = useCallback(() => {
        const container = containerRef.current;

        if (!container || !calendarItems?.length) {
            return;
        }

        setColumnWidth(
            useFixedColumnWidth
                ? gridDefaultColumnWidth
                : Math.max(
                      gridDefaultColumnWidth,
                      (container.clientWidth - yLabelsContanerWidth) / calendarItems.length
                  )
        );
    }, [calendarItems, useFixedColumnWidth]);

    const handlePreviousClick = useCallback(() => {
        const container = containerRef.current;

        if (!container) {
            return;
        }

        const left = Math.max(0, (scrollIndex - 1) * columnWidth);

        container.scrollTo({ left, behavior: "smooth" });
    }, [columnWidth, scrollIndex]);

    const handleNextClick = useCallback(() => {
        const container = containerRef.current;

        if (!container) {
            return;
        }

        const left = Math.min((scrollIndex + 1) * columnWidth, container.scrollWidth - container.clientWidth);

        container.scrollTo({ left, behavior: "smooth" });
    }, [columnWidth, scrollIndex]);

    const handleScrollUpdate = useCallback(() => {
        const container = containerRef.current;

        if (!container) {
            return;
        }

        // amount by which scroll is offset from the start of a column, used to correct the
        // current time position when it's left cannot be exactly on a column
        const scrollOffset = container.scrollLeft % columnWidth;

        const maxScrollLeft = container.scrollWidth - container.clientWidth;

        setShowNavPrevious(container.scrollLeft > 0);

        setShowNavNext(container.scrollLeft < container.scrollWidth - container.clientWidth);

        // to avoid excessive re-renders on scroll, only update scrollOffset when we need it (at max scroll)
        setScrollIndexOffset(container.scrollLeft >= maxScrollLeft ? scrollOffset : 0);

        // catch situations where final scroll to position is not exact
        if (scrollOffset / columnWidth > 0.98 && container.scrollLeft < maxScrollLeft) {
            setScrollIndex(Math.round(container.scrollLeft / columnWidth));
        } else {
            setScrollIndex(Math.floor(container.scrollLeft / columnWidth));
        }
    }, [columnWidth]);

    useEffect(() => {
        const container = containerRef.current;

        if (!container) {
            return;
        }

        const listener = () => {
            handleScrollUpdate();
        };

        listener();

        container.addEventListener("scroll", listener);

        return () => {
            container?.removeEventListener("scroll", listener);
        };
    }, [handleScrollUpdate]);

    useResizeObserver<HTMLDivElement>({
        ref: containerRef,
        onResize: () => {
            handleContainerResize();
            handleScrollUpdate();
        },
    });

    // scroll to current time on first load
    useEffect(() => {
        const container = containerRef.current;

        if (!container || !calendarItems?.length || !autoScrollRef.current) {
            return;
        }

        container.scrollTo({ top: getInitialScrollY(venueNow.format("HHmm")), left: 0, behavior: "auto" });

        autoScrollRef.current = false;
    }, [calendarItems, venueNow]);

    // update column width when calendar items change
    useEffect(() => {
        handleContainerResize();
    }, [calendarItems, handleContainerResize]);

    const xLabelBasis =
        useFixedColumnWidth || !calendarItems?.length
            ? `${gridDefaultColumnWidth}px`
            : (100 / calendarItems.length).toFixed(2) + "%";

    const containerClasses = classNames(styles.container, {
        [styles.pinnedContainer]: headerPinned,
        [styles.containerExtendedHeader]: viewMode === "week",
    });

    return (
        <div className={styles.gridNavigationContainer}>
            <div className={containerClasses} ref={containerRef}>
                <div className={styles.labelsCover} />
                <div className={styles.xAxisLabelsContainer}>
                    {calendarItems &&
                        xLabels.map(({ label, highlight }) => (
                            <div
                                className={classNames(styles.xAxisLabel, highlight && styles.xAxisLabelHighlighted)}
                                key={label}
                                style={{
                                    flex: `0 0 ${xLabelBasis}`,
                                    maxWidth: `${columnWidth}px`,
                                }}
                            >
                                <span className={styles.xAxisLabelText}>{label}</span>
                            </div>
                        ))}
                </div>
                <div className={styles.yAxisLabelsContainer}>
                    {yLabels.map((label, index) => (
                        <div className={styles.yAxisLabel} key={`${index}-${label}`}>
                            {label}
                        </div>
                    ))}
                </div>
                <div
                    className={styles.grid}
                    style={{
                        backgroundSize: `${columnWidth}px ${gridIncrementY}px`,
                        gridTemplateColumns: `repeat(${calendarItems?.length}, ${
                            useFixedColumnWidth
                                ? `${gridDefaultColumnWidth}px`
                                : `minmax(${gridDefaultColumnWidth}px, 1fr)`
                        } )`,
                    }}
                >
                    {calendarItems && renderCalendarItems(calendarItems)}
                </div>
                {todayColumnIndex > -1 && (
                    <CurrentTime
                        viewMode={viewMode}
                        style={
                            viewMode === "week"
                                ? {
                                      left: columnWidth * todayColumnIndex + yLabelsContanerWidth,
                                      width: columnWidth,
                                  }
                                : {
                                      left: scrollIndex * columnWidth + scrollIndexOffset + yLabelsContanerWidth,
                                      width: calendarItems?.length
                                          ? calendarItems.length * columnWidth -
                                            (scrollIndex * columnWidth + scrollIndexOffset)
                                          : `calc(100% - ${yLabelsContanerWidth}px)`,
                                  }
                        }
                    />
                )}
            </div>
            <NavButton
                className={styles.gridNavPrevious}
                direction="previous"
                hidden={!showNavPrevious}
                onClick={handlePreviousClick}
            />
            <NavButton
                className={styles.gridNavNext}
                direction="next"
                hidden={!showNavNext}
                onClick={handleNextClick}
                style={{ transform: `translateX(${-1 * scrollbarWidth}px)` }}
            />
            {loadStatus === "loading" && (
                <div className={styles.loadingOverlay}>
                    <PageLoading />
                </div>
            )}
        </div>
    );
};

function getXDateLabels(startDate = new Date(), today?: Date) {
    const labels: { label: string; highlight: boolean }[] = [];

    for (let i = 0; i < 7; i++) {
        const date = new Date(startDate.getTime());
        date.setDate(startDate.getDate() + i);

        labels.push({
            label: `${date.toLocaleDateString("en-AU", {
                weekday: "short",
            })} ${date.getDate()} ${date.toLocaleDateString("en-AU", { month: "short" })}`,
            highlight: isToday(date, today),
        });
    }

    return labels;
}

function isToday(date: Date, today?: Date) {
    if (!today) {
        return false;
    }

    return (
        date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate()
    );
}

function getXSectionLabels(sections: ServiceCalendarSectionItem[]) {
    return sections.map((section) => ({ label: section.sectionName, highlight: false }));
}

function getYLabels() {
    const labels = [];

    for (let i = 0; i < 48; i++) {
        const time = i * 30;
        const hour = String(Math.floor(time / 60)).padStart(2, "0");
        const min = String(time % 60).padStart(2, "0");
        labels.push(`${hour}:${min}`);
    }

    return labels;
}

function getInitialScrollY(time: number | string) {
    // use 0-based row in these calcs
    const row = getRowFromTime(time) - 1;

    // snap to most recent 30 minute interval
    const currentTimeRow = Math.floor(row / 2) * 2;

    // subtract rows for padding
    const initRow = Math.max(0, currentTimeRow - 2);

    return gridIncrementY * initRow;
}

function getRowFromTime(time: number | string) {
    const t = typeof time === "number" ? time : parseInt(time);
    const hours = Math.floor(t / 100);
    const mins = t % 100;

    const row = hours * 4 + Math.floor(mins / 15);
    return 1 + row;
}

function renderCalendarItems(items: ServiceSectionCalendarDay[] | ServiceCalendarSectionItem[]) {
    return items
        ?.map((item, i) => {
            if ("date" in item) {
                // week view (item is ServiceSectionCalendarDay)
                return item.calendarItems.map((calendarItem) => ({
                    calendarItem,
                    gridColumnStart: i + 1,
                    key: `${item.date}-${calendarItem.startTime}`,
                }));
            } else {
                // day view (item is ServiceCalendarSectionItem)
                return item.dates?.[0]?.calendarItems?.map((calendarItem) => ({
                    calendarItem,
                    gridColumnStart: i + 1,
                    key: `${item.sectionName}-${calendarItem.startTime}`,
                }));
            }
        })
        .flat()
        .filter(Boolean)
        .map(({ calendarItem, gridColumnStart, key }) => {
            const gridRowStart = getRowFromTime(calendarItem.startTime);
            const gridRowEnd = getRowFromTime(calendarItem.endTime);
            const numRows = gridRowEnd - gridRowStart;

            return (
                <CalendarItem
                    key={key}
                    calendarItem={calendarItem}
                    numRows={numRows}
                    style={{
                        gridColumnStart,
                        gridRowStart,
                        gridRowEnd,
                    }}
                />
            );
        });
}
