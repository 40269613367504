import { Card, Row, Title } from "core/components/card";
import { SelectRecommended } from "components/forms/SelectRecommended";
import { EditableProduct, EditableVariant } from "features/catalogue/types";
import { useFormikContext } from "formik";

interface Props {
    disableFields: boolean;
}

export const ProductDefaultVariant = ({ disableFields }: Props) => {
    const {
        values: { prices },
    } = useFormikContext<EditableProduct>();

    if (prices.length < 2) {
        return null;
    }

    return (
        <Card>
            <Row collapse="down">
                <Title title="Preselect a variant" />
            </Row>
            <Row>
                <SelectRecommended<EditableProduct, EditableVariant>
                    disabled={disableFields}
                    fieldName="prices"
                    placeholder="Select a variant"
                    displayNamePlaceholder="Unnamed Variant"
                />
            </Row>
        </Card>
    );
};
