import styles from "./SyncStatus.module.scss";

import { ImportantWarning, MinusCircle, Synced, TimeCircle } from "common/icons";
import { Icon } from "core/components/icon";
import { Tooltip } from "../tooltip/Tooltip";
import { ItemValidationStatus } from "features/catalogue";

export type Status = "synced" | "failed" | "skipped" | "awaiting";

interface Props {
    status: Status;
}

const icons = {
    synced: Synced,
    failed: ImportantWarning,
    skipped: MinusCircle,
    awaiting: TimeCircle,
};

function statusTooltip(status: Status) {
    switch (status) {
        case "synced":
            return "Synced with POS";
        case "failed":
            return "Failed to sync";
        case "skipped":
            return "Sync skipped";
        case "awaiting":
            return "Never been synced";
    }
}

export const SyncStatus = ({ status }: Props) => {
    const Svg = icons[status] || null;

    return Svg ? (
        <Tooltip content={statusTooltip(status)} placement="top-end" offset={[16, 12]}>
            <Icon className={styles[status]} size="small" verticalAlign="middle">
                <Svg />
            </Icon>
        </Tooltip>
    ) : null;
};

export function getStatusFromValidationStatus(status: ItemValidationStatus): Status | undefined {
    switch (status) {
        case "VALID":
            return "synced";
        case "INVALID":
            return "failed";
        case "SKIPPED":
            return "skipped";
        case "NONE":
            return "awaiting";
        case "MISSING":
            return "failed";
        default:
            return undefined;
    }
}
