import { connect } from "react-redux";
import { Props } from "features/posCatalogue/components/ProductsTab";
import { AppState } from "features";
import { FunctionProps, DataProps } from "types/utils";
import { ProductsTab } from "../components/ProductsTab";
import { ExtendedPermissions } from "features/location/types/ExtendedPermissions";
import { getProductPosCatalogueItems, getSortedCategories } from "../selectors/getPosCatalogueItems";
import { withRouter } from "react-router";
import { exportProductsCsv } from "../actions/exportProductsCsv";

export interface OwnProps {
    permissions: ExtendedPermissions;
    handleSetPagingAvailable: (hasPaging: boolean) => void;
    initialStatuses?: string[];
}

const mapStateToProps = (state: AppState, { permissions }: OwnProps): DataProps<Props> => {
    const {
        posCatalogue: { list },
    } = state;

    if (list.status !== "loaded") {
        throw new Error("Not loaded");
    }

    return {
        products: getProductPosCatalogueItems(state)!,
        categories: getSortedCategories(state),
        permissions,
    };
};

const mapDispatchToProps = (dispatch: any, { handleSetPagingAvailable }: OwnProps): FunctionProps<Props> => ({
    handleSetPagingAvailable: (hasPaging: boolean) => handleSetPagingAvailable(hasPaging),
    exportData: () => dispatch(exportProductsCsv()),
});

export const ProductsContainer = connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductsTab));
