import { EditableSurcharge, templateOneTimeSurcharges } from "./types";
import { FieldUpdate } from "./types/SurchargeField";

export const hasTextEntries = (record: EditableSurcharge) =>
    (record.startTime !== undefined && record.startTime >= 0) ||
    (record.endTime !== undefined && record.endTime >= 0) ||
    !!record.percentage ||
    !!record.date ||
    !!record.surchargeId;

export const hasEntries = (record: EditableSurcharge) =>
    hasTextEntries(record) || record.applyToFood || record.applyToDrink;

export const isUnchanged = (orig: EditableSurcharge, record: EditableSurcharge) =>
    orig.date === record.date &&
    orig.startTime === record.startTime &&
    orig.endTime === record.endTime &&
    (orig.percentage === record.percentage || (!orig.percentage && !record.percentage)) &&
    orig.surchargeId === record.surchargeId &&
    orig.applyToFood === record.applyToFood &&
    orig.applyToDrink === record.applyToDrink;

export const checkRowIsDirty = (
    surcharge: EditableSurcharge,
    surcharges: EditableSurcharge[],
    isDelete: boolean = false
): boolean => {
    const orig = surcharges.find((i) => i.id === surcharge.id);
    return !((isDelete && !surcharge.id) || (!isDelete && orig && isUnchanged(orig, surcharge)));
};

export const applyDatasourceUpdates = (
    surcharge: EditableSurcharge,
    surcharges: EditableSurcharge[],
    initalSurcharges: EditableSurcharge[],
    updates: FieldUpdate[],
    checkCheckBoxes: boolean = false,
    touchedIds: Set<string>,
    checkNewRow?: boolean
) => {
    const newDatasource = [...surcharges];
    const updateSurcharge = { ...surcharge };
    const idx = newDatasource.findIndex((x) => x.rowKey === surcharge.rowKey);

    updates.forEach((update) => {
        updateSurcharge[update.field] = update.value;
    });

    checkCheckBoxes && updateCheckBoxes(updateSurcharge);
    updateSurcharge.validationError = "";
    updateSurcharge.markedForDelete = !hasEntries(updateSurcharge);

    newDatasource[idx] = updateSurcharge;

    if (!checkRowIsDirty(updateSurcharge, initalSurcharges)) {
        touchedIds.delete(updateSurcharge.rowKey);
        return newDatasource;
    }

    touchedIds.add(updateSurcharge.rowKey);

    if (checkNewRow) {
        (!newDatasource.find((x) => x.newRow) || updateSurcharge.newRow) &&
            newDatasource.push({
                ...templateOneTimeSurcharges,
                rowKey: "new" + newDatasource.length.toString(),
            });
        updateSurcharge.newRow = false;
    }

    return newDatasource;
};

const updateCheckBoxes = (surcharge: EditableSurcharge) => {
    const hasValues = hasTextEntries(surcharge);
    if (hasValues && !surcharge.applyToFood && !surcharge.applyToDrink) {
        surcharge.applyToFood = true;
        surcharge.applyToDrink = true;
        return;
    }

    if (!hasValues) {
        surcharge.applyToFood = false;
        surcharge.applyToDrink = false;
    }
};

export const displayPercentage = (input: number) => parseFloat((input * 100).toFixed(2));
