import { useEffect, useMemo } from "react";
import { EditableLocationSettings } from "../types";
import { Field, FormikProps } from "formik";
import { Row, Col } from "antd";
import { SelectItem, SearchInput, SearchInputContainerProps } from "components/forms/SearchInput";
import { FieldErrors } from "components/forms";

interface Props {
    form: FormikProps<EditableLocationSettings>;
    disabled: boolean;
}

export const PreSelectedTipInput = ({ form, name, disabled }: SearchInputContainerProps & Props) => {
    // enable validation error when changing other fields
    useEffect(() => {
        form.setFieldTouched(name, true);
        // this should only run once (ignore changes to form)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const options: SelectItem[] = useMemo(() => {
        const opts = form.values.defaultTipLevels
            .map((level, index) => {
                // use custom value if truthy or fallback to default
                return (form.values.tipOptions.levels && form.values.tipOptions.levels[index]) || level;
            })
            .map((level: number, index) => ({
                displayName: `${level}%`,
                id: index,
            }));

        // add 'none' option
        opts.unshift({
            displayName: "None",
            id: -1,
        });

        return opts;
    }, [form.values.tipOptions.levels, form.values.defaultTipLevels]);

    return (
        <FieldErrors className="tips-input-field__wrapper" name={name} form={form}>
            <Row>
                <Col span={24} sm={10}>
                    <Field 
                        name={name} 
                        component={SearchInput} 
                        options={options} 
                        disableSearch={true} 
                        disabled={disabled} 
                    />
                </Col>
            </Row>
        </FieldErrors>
    );
};
