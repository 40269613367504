import styles from "./MembershipSummary.module.scss";
import { Row } from "core/components/card";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { MembershipLevelPublishedState, MembershipSummary as Summary } from "../types";
import { Col } from "core/components/grid/Grid";
import classNames from "classnames";
import { ColorScheme, Status as PublishStatus } from "core/components/status";

interface MembershipSummaryProps {
    canUpdate: boolean;
    summary: Summary;
    index: number;
}

export const MembershipSummary = ({ canUpdate, summary, index }: MembershipSummaryProps) => {
    const location = useLocation();
    const link = canUpdate && summary.authType === "optin" ? `${location.pathname}/${summary.id}` : undefined;
    const border = index > 0 ? "top" : undefined;

    return (
        <li className={styles.container}>
            <Row
                as={link ? Link : "div"}
                className={classNames(styles.itemRow, link ? styles.link : styles.disabled)}
                to={link}
                border={border}
            >
                <Col cols={{ xs: 9, md: 10 }} className={styles.columnLeftAlign}>
                    <span className={styles.heading}>{summary.title}</span>
                    <span className={styles.subtitle}>{summary.subtitle}</span>
                </Col>
                <Col cols={{ xs: 3, md: 2 }} className={styles.columnRightAlign}>
                    {summary.publishedStatus && (
                        <PublishStatus colorScheme={getColorScheme(summary.publishedStatus)}>
                            {getPublishStatusLabel(summary.publishedStatus)}
                        </PublishStatus>
                    )}
                </Col>
            </Row>
        </li>
    );
};

const getPublishStatusLabel = (publishStatus: MembershipLevelPublishedState) => {
    switch (publishStatus) {
        case MembershipLevelPublishedState.PUBLISHED:
            return "Published";
        case MembershipLevelPublishedState.STAGED:
            return "Staged changes";
        default:
            return "N/A";
    }
};

const getColorScheme = (publishStatus: MembershipLevelPublishedState): ColorScheme => {
    switch (publishStatus) {
        case MembershipLevelPublishedState.PUBLISHED:
            return "success";
        case MembershipLevelPublishedState.STAGED:
            return "warning";
        default:
            return "disabled";
    }
};
