import { TreeItem } from "common/types";
import { filterSearchable } from "common/utility/filterSearchable";

// DFS recursive tree vistor which calls back with node, parentKeys
export function visit(
    nodes: TreeItem[],
    callback: (node: TreeItem, parentKeys: string[]) => void,
    includeRootParentKeys: boolean = true,
    parentKeys: string[] = []
): void {
    nodes.forEach((node) => {
        callback(node, parentKeys);
        if (node.children) {
            const isRoot = node.type === "root";
            if (!isRoot || includeRootParentKeys) {
                parentKeys.push(node.key);
            }
            visit(node.children, callback, includeRootParentKeys, parentKeys);
            if (!isRoot || includeRootParentKeys) {
                parentKeys.pop();
            }
        }
    });
}

// get keys of item and it's children
export function getKeysRecursive(node: TreeItem, keys: string[] = []) {
    keys.push(node.key);
    node.children?.forEach((child) => getKeysRecursive(child, keys));
    return keys;
}

// build look up of selected ids by type
export function selectedIdsByType(selectedItems: TreeItem[]) {
    const selectedIds = {};
    ["root", "category", "product", "modifier", "variant", "option"].forEach((type) => {
        selectedIds[type] = selectedItems.filter((item) => item.type === type).map(({ id }) => id);
    });
    return selectedIds;
}

// get a filterSearchable friendly (partially flattened) list of TreeItem nodes for searching
// @see src/common/utility/filterSearchable.ts
export function getSearchNodes(datasource: TreeItem[], includedTypes?: string[]) {
    const searchNodes: TreeItem[] = [];

    visit(
        datasource,
        (node) => {
            if (!includedTypes || includedTypes.includes(node.type)) {
                searchNodes.push(node);
            }
        },
        false
    );

    return searchNodes;
}

export function getKeysForSearch(searchNodes: TreeItem[], searchTerm: string): string[] {
    let keys: string[] = [];

    const matches = filterSearchable<TreeItem>(searchNodes, searchTerm, true, true);

    matches.forEach((item) => {
        const matchingChildKeys = (item.children && item.children.map(({ key }) => key)) || [];
        keys.push(item.key, ...matchingChildKeys);
    });

    return [...new Set<string>(keys)];
}

// create nested dictionary keyed by type to store ids
export const initIdsByTypeDictionary = (
    types: string[]
): {
    [K in typeof types[number]]: {
        [id: string]: boolean;
    };
} => {
    return types.reduce((acc, type) => {
        acc[type] = {};
        return acc;
    }, {});
};

// return a copy of tree with any 'root' type nodes disabled
export const disableRoots = (productTree: TreeItem[]): TreeItem[] => {
    return productTree.map((item) => {
        return {
            ...item,
            ...(item.type === "root" ? { checkable: false } : null),
            ...(item.children ? { children: disableRoots(item.children) } : null),
        };
    });
};
