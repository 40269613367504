import { Card, Row, Title } from "core/components/card";
import { CategorySummary } from "features/category";
import { Field, useFormikContext } from "formik";
import { filterOptionByLabel, formatOptionWithSecondaryLabel, Select } from "core/components/form/select";
import { useCallback, useEffect, useMemo } from "react";
import { EditableProduct } from "features/catalogue/types";
import { usePrevious } from "common/hooks";
import { Warning } from "core/components/iconWithText";

interface Props {
    categories: CategorySummary[];
    disableFields: boolean;
}

export const ProductCategories = ({ categories, disableFields }: Props) => {
    const {
        setFieldValue,
        values: { categories: selectedCategories, itemType },
    } = useFormikContext<EditableProduct>();

    const previousItemType = usePrevious(itemType);

    const categoryOptions = useMemo(() => {
        if (!itemType) {
            return [];
        }

        return categories
            .filter((c: CategorySummary) => c.type === itemType)
            .map((c) => ({
                label: c.displayName,
                secondaryLabel: c.internalName,
                value: c.id,
            }));
    }, [categories, itemType]);

    // remove categories when item type changes
    useEffect(() => {
        if (!selectedCategories.length) {
            return;
        }

        if (previousItemType && itemType !== previousItemType) {
            setFieldValue("categories", []);
        }
    }, [categories, itemType, previousItemType, selectedCategories, setFieldValue]);

    const handleItemTypeWarningClick = useCallback(() => {
        document.querySelector("#input-item-type")?.scrollIntoView({ behavior: "smooth" });
    }, []);

    return (
        <Card>
            <Row>
                <Title title="Categories" />
            </Row>
            {!itemType && (
                <Row className="mb-2" collapse="both">
                    <Warning>
                        Select an{" "}
                        <button type="button" className={"button-reset"} onClick={handleItemTypeWarningClick}>
                            <strong>item type</strong>
                        </button>{" "}
                        to see available categories
                    </Warning>
                </Row>
            )}
            <Row collapse="up">
                <Field
                    blurInputOnSelect={false}
                    closeMenuOnSelect={false}
                    component={Select}
                    disabled={disableFields || !itemType}
                    isMulti
                    filterOption={filterOptionByLabel}
                    formatOptionLabel={formatOptionWithSecondaryLabel}
                    menuPlacement="auto"
                    name="categories"
                    options={categoryOptions}
                />
            </Row>
        </Card>
    );
};
