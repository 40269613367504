import { opsApi } from "API";
import { PosMenuData } from "..";

export const fetchPosCatalogue = async (locationId: string) => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId });
    return res.data.posCatalogue;
};

type GraphQLPayload = { posCatalogue: PosMenuData[] };

const query = `
query ($locationId: ID!) {
    posCatalogue(locationId: $locationId) {
      categories {
        id
        displayName
        products
        type
      }
      
      products {
        id
        displayName
        description
        imageUrl
        variants {
          id
          displayName
          price
          sku
          modifiers
        }
      }
      
      modifiers {
        id
        displayName
        sku
        options {
          id
          displayName
          sku
          price
        }
      }
    }
  }
`;
