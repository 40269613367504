import { connect } from "react-redux";
import * as actions from "../actions";
import { PageLoadingProps, withLoader } from "common/loader/withLoader";
import { AppState } from "features";
import { DataProps, FunctionProps } from "types/utils";
import { isLoaded } from "../../../common/loader/isLoaded";
import { PosConfigurationPage, Props } from "../components/PosConfigurationPage";
import { getPosConfigurationSchema } from "../selectors/getPosConfigurationSchema";
import { getPosConfiguration, getPosConfigurationInitialValues } from "../selectors/getPosConfiguration";
import { getPosConfigurationLookupsStatus } from "../selectors/getPosConfigurationLookups";
import { mergeStatus } from "common/loader";
import { getPosMetadata, getPosOperationsSupport } from "../selectors/getPosOperationsSupport";
import { getPosConfigurationFields } from "../selectors/getPosConfigurationFields";
import { getCrudPermissions } from "features/location/selectors/getCrudPermissions";

const mapStateToProps = (state: AppState): DataProps<PageLoadingProps<Props>> => {
    const {
        posConfiguration,
        locations: { active: activeLocation },
    } = state;

    const lookupsStatus = getPosConfigurationLookupsStatus(state);

    const overallFetchStatus = mergeStatus(activeLocation.status, posConfiguration.status, lookupsStatus);

    if (isLoaded(posConfiguration) && lookupsStatus === "loaded") {
        return {
            loadStatus: overallFetchStatus,
            schema: getPosConfigurationSchema(state),
            fields: getPosConfigurationFields(state),
            initialValues: getPosConfigurationInitialValues(state),
            updateTimestamp: getPosConfiguration(state).timestamp,
            saveStatus: posConfiguration.saveStatus,
            supportsClear: getPosOperationsSupport(state)["ClearConfiguration"],
            supportsOrderCreate: getPosOperationsSupport(state)["OrderCreate"],
            permissions: getCrudPermissions(state).posConfiguration,
            posMetadata: getPosMetadata(state),
        };
    }
    return {
        loadStatus: overallFetchStatus,
    };
};

const mapDispatchToProps = (dispatch: any): FunctionProps<PageLoadingProps<Props>> => ({
    fetch: () => {
        dispatch(actions.edit());
        dispatch(actions.fetchConfigurationLookups());
    },
    onSubmit: (settings: {}) => dispatch(actions.save(settings)),
    onClear: () => dispatch(actions.clear()),
});

export const PosConfigurationPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withLoader(PosConfigurationPage));
