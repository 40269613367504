import { createAction as trackingActions } from "common/appInsights/actions/track";
import { RouteParams } from "common/appInsights/plugins";
import { config } from "common/config";
import { Button } from "core/components/button";
import { Row } from "core/components/card";
import { Switch } from "core/components/form/switch";
import { CardWidth, Confirm, Modal, ModalRenderer } from "core/components/modal";
import { PageHeader } from "core/components/pageHeader";
import { getSections } from "features/service/selectors";
import { createDefaultQrCode } from "features/takeaway/api/createDefaultQrCode";
import { EditableTakeawayOptions } from "features/takeaway/types";
import { FormikProps } from "formik";
import { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

interface Props {
    form: FormikProps<EditableTakeawayOptions>;
    disabled: boolean;
}

export const TakeawayPageHeader = ({ form, disabled }: Props) => {
    const takeawayEnabled = form.values.enabled;

    const sections = useSelector(getSections);
    const { region, location: slug } = useParams<RouteParams>();

    const [confirmDisablingTakeaway, setConfirmDisablingTakeaway] = useState(false);
    const track = useTracker();

    const setTakeawayEnabledFormValue = (enabled: boolean) => {
        form.setFieldValue("enabled", enabled);
        track.takeawayToggled(enabled);
    };

    const toggleTakeawaySwitch = (e: ChangeEvent<HTMLInputElement>) => {
        const switchingOffTakeaway = !e.target.checked;
        if (switchingOffTakeaway) {
            setConfirmDisablingTakeaway(true);
        } else {
            setTakeawayEnabledFormValue(true);
        }
    };

    return (
        <>
            <PageHeader
                title="Takeaway"
                className="mb-4"
                actions={
                    <>
                        {config.REACT_APP_SHOW_CREATE_DEFAULT_TABLE === "1" && slug && !takeawayEnabled && (
                            <Button
                                onClick={() => {
                                    createDefaultQrCode(
                                        slug,
                                        `${config.REACT_APP_ORDER_APP_URL}/qr?t=${slug}&r=${region}`,
                                        "50",
                                        sections?.[0]?.id
                                    );
                                }}
                                shape="link"
                            >
                                Create default table
                            </Button>
                        )}
                        <Switch
                            disabled={disabled}
                            field={{
                                name: "enabled",
                                checked: takeawayEnabled,
                                onChange: toggleTakeawaySwitch,
                            }}
                        />
                    </>
                }
            />
            <ModalRenderer target="#modal">
                {confirmDisablingTakeaway && (
                    <Modal
                        title="Are you sure you want to disable takeaway menu?"
                        visible={confirmDisablingTakeaway}
                        onClose={() => setConfirmDisablingTakeaway(false)}
                        footer={
                            <Confirm
                                confirmLabel="Disable"
                                onConfirm={() => {
                                    setTakeawayEnabledFormValue(false);
                                }}
                                onCancel={() => setConfirmDisablingTakeaway(false)}
                            />
                        }
                        width={CardWidth.NARROW}
                    >
                        <Row>Customers won’t be able to order takeaway until you enable this feature again.</Row>
                    </Modal>
                )}
            </ModalRenderer>
        </>
    );
};

const useTracker = () => {
    const dispatch = useDispatch();
    return {
        takeawayToggled: (enabled: boolean) =>
            dispatch(
                trackingActions.track({
                    event: { name: "TAKEAWAY/TOGGLE_SAVED" },
                    customProperties: { takeaway_status: enabled },
                })
            ),
    };
};
