import * as Yup from "yup";
import {
    EditableModifierOption,
    EditableModifier,
    ModifySelectionOptions,
    EditableModifierProducts,
} from "features/modifier/types";

export const optionalPriceSchema = Yup.lazy((value) =>
    value === "" ? Yup.string().notRequired() : Yup.number().nullable(true)
);

export const energyContentSchema = Yup.number()
    .integer("Value cannot contain decimals")
    .min(0, "Must be greater than or equal to 0")
    .max(99999, "Must be between 0 and 99999")
    .nullable(true);

export const editableModificationOptionSchema = Yup.object<EditableModifierOption>().shape({
    displayName: Yup.string().required("Please enter a name"),
    sku: Yup.string().nullable(true),
    price: optionalPriceSchema,
    taxRate: Yup.number().nullable(true),
    energyContent: energyContentSchema,
    isLinked: Yup.boolean(),
});

export const selectionNumberSchema = Yup.number()
    .min(1, "Must be between 1 and 99")
    .max(99, "Must be between 1 and 99")
    .integer("Value cannot contain decimals")
    .required("Please enter a value");

export const editableModifierSchema = Yup.object<EditableModifier>().shape({
    internalName: Yup.string().nullable(true).notRequired(),
    displayName: Yup.string().required("Please enter a name"),
    options: Yup.array().of(editableModificationOptionSchema).min(1, "Please add at least one option"),
    required: Yup.boolean(),
    minMaxSelect: Yup.string().notRequired(), // Not saved, just sets the depending min and max selection
    minSelection: Yup.number()
        .nullable(true)
        .when(["minMaxSelect"], {
            is: (minMaxSelect: string) => {
                const required =
                    minMaxSelect === ModifySelectionOptions.AtLeast || minMaxSelect === ModifySelectionOptions.Exactly;
                return required;
            },
            then: selectionNumberSchema,
        })
        .when(["minMaxSelect", "maxSelection"], {
            is: (minMaxSelect: string, maxSelection: number | undefined) => {
                return minMaxSelect === ModifySelectionOptions.Between && maxSelection !== undefined;
            },
            then: selectionNumberSchema.lessThan(
                Yup.ref("maxSelection"),
                "Maximum value cannot be the same or lower than the minimum"
            ),
        })
        .when(["options", "maxSelectionPerOption"], (options, maxSelectionPerOption, schema) =>
            maxSelectionPerOption
                ? schema.max(
                      (options?.length || 0) * maxSelectionPerOption,
                      "Current rules are invalid. Add more modifier options, change minimum or increase selections per option"
                  )
                : schema
        ),
    maxSelection: Yup.number()
        .nullable(true)
        .when(["minMaxSelect"], {
            is: (minMaxSelect: string) => {
                return minMaxSelect === ModifySelectionOptions.UpTo;
            },
            then: selectionNumberSchema,
        })
        .when(["minMaxSelect"], {
            is: (minMaxSelect: string) => {
                return minMaxSelect === ModifySelectionOptions.Between;
            },
            then: selectionNumberSchema.moreThan(
                Yup.ref("minSelection"),
                "Maximum value cannot be the same or lower than the minimum"
            ),
        }),
    id: Yup.string().notRequired(),
    items: Yup.array().of(Yup.string()), // TODO @deprecated
    products: Yup.object<EditableModifierProducts>()
        .nullable(true)
        .shape({
            productIds: Yup.array().of(Yup.string().nullable(true)).nullable(true),
            variantIds: Yup.array().of(Yup.string().nullable(true)).nullable(true),
        }),
    upsell: Yup.boolean(),
    priceLists: Yup.array().of(Yup.string()),
    status: Yup.number(),
    smartSorting: Yup.boolean().nullable(true),
    maxSelectionPerOption: selectionNumberSchema,
    type: Yup.mixed()
        .nullable(true)
        .when(["upsell", "$upsellTypes"], {
            is: true,
            then: Yup.mixed().oneOf(
                ["food", "drink", "alcoholic-drink"],
                "Must select food, non-alcoholic drink or alcoholic drink."
            ),
        }),
    isLinked: Yup.boolean(),
});
