import { createAction } from "features/service/reducers/list";
import { applySummaryDisplayName } from "foundation/dataConventions/applySummaryDisplayName";
import { GraphQLDateRange, GraphQLServiceSummary, listServices } from "../api/listServices";
import { scaffoldListAction } from "common/scaffolding/actions/scaffoldListAction";
import { DateRange, ServiceSummary } from "../types";

export const list = (location: string) =>
    scaffoldListAction<ServiceSummary>(
        (state) => state.services.list,
        createAction,
        async () => {
            const services = await listServices(location);

            return services.map(mapListItem);
        }
    );

export function mapListItem({
    id,
    internalName,
    displayName,
    daysOfWeek,
    startTime,
    endTime,
    dates,
    sections,
    surcharge,
    activeMinutes,
    available,
    priceLists,
}: GraphQLServiceSummary): ServiceSummary {
    return {
        id,
        internalName,
        displayName,
        daysOfWeek,
        startTime,
        endTime,
        dates: mapDates(dates),
        sections: sections.map(applySummaryDisplayName),
        surcharge,
        activeMinutes,
        available: available === null ? true : available,
        priceLists,
    };
}

function mapDates(dates: GraphQLDateRange[]): DateRange[] {
    return dates.map((date) => ({
        startDate: date.startDate,
        endDate: date.endDate,
    }));
}
