import styles from "./List.module.scss";

import { PropsWithChildren, ReactElement } from "react";
import classNames from "classnames";

interface Props {
    className?: string;
}

export const List = ({ className, children }: PropsWithChildren<Props>): ReactElement => {
    return <ul className={classNames(styles.list, className)}>{children}</ul>;
};
