import { opsApi } from "API";
import { ServiceCalendarRequestDataInput } from "../types/ServiceCalendarRequestDataInput";
import { ServiceCalendarData } from "../types";

export async function priorityServiceCalendar(
    locationId: string,
    range: ServiceCalendarRequestDataInput
): Promise<ServiceCalendarData> {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId, data: range });
    return res.data.priorityServiceCalendar;
}

type GraphQLPayload = { data: ServiceCalendarData };

export const serviceCalendarFields = `
    sections {
        id
        sectionName
        dates {
            date
            calendarItems {
                startTime
                endTime
                service {
                    id
                    serviceName
                    description
                    colourIndex
                    priority
                }
            }
        }
    }
`;

const query = `
  query ($locationId: ID!, $data: ServiceCalendarRequestDataInput!) {
    priorityServiceCalendar(locationId: $locationId, data: $data) {
        ${serviceCalendarFields}
    }
}
`;
