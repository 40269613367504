import { downloadBlob } from "common/utility/fileUtils";
import { AppDispatch, AppState } from "features/state";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { generateCsvBlob } from "common/data/generateCsvBlob";
import { getIsEnergyContentSupported } from "features/location/selectors/getEnergyContentSupported";
import { generateProductRow, getProductCSVHeader } from "../csv/ProductCSVFormat";
import { getProductCatalogueItems } from "../selectors/getProductCatalogueItems";
import { getCategoryFilters } from "../selectors/getCategoryFilters";
import { CatalogueExportItem } from "../types/CatalogueExportItem";
import { PosCategory } from "features/posCatalogueImport";
import { CatalogueItemSelections } from "../types";

export const exportProductsCSV =
    (selections: CatalogueItemSelections | null = null) =>
    (_: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const products = getProductCatalogueItems(state);
        const categories = getCategoryFilters(state) as PosCategory[];
        const activeLocation = getActiveLocation(state)!;
        const energyContent = getIsEnergyContentSupported(state) || false;

        const selectedproducts = selections ? products.filter((x) => selections[x.id!]) : products;

        const csvBlob = generateCsvBlob<Array<string | null>>((writer) => {
            writer(getProductCSVHeader(energyContent));

            for (const product of selectedproducts) {
                generateProductRow(product as CatalogueExportItem, categories, writer, energyContent, selections);
            }
        });
        downloadBlob(csvBlob, `${activeLocation.slug}-products.csv`);
    };
