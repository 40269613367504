import * as React from "react";
import { FieldProps } from "formik";

import "./QuantityInput.scss";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Row, Col, Input } from "antd";

interface Props {
    disabled?: boolean;
}

interface State {
    value: string | number;
}

export class QuantityInput extends React.Component<Props & FieldProps, State> {
    constructor(props: Props & FieldProps) {
        super(props);
        this.state = {
            value: this.props.field.value || 0,
        };
    }

    render() {
        const {
            field: { name },
            disabled,
        } = this.props;

        return (
            <Row justify="start" gutter={16} align="top">
                <Col>
                    <Button shape="circle" icon={<MinusOutlined />} onClick={this.onDecrement} disabled={disabled} />
                </Col>
                <Col span={5}>
                    <Input
                        name={name}
                        value={this.state.value}
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                        disabled={disabled}
                    />
                </Col>
                <Col>
                    <Button shape="circle" icon={<PlusOutlined />} onClick={this.onIncrement} disabled={disabled} />
                </Col>
            </Row>
        );
    }

    onDecrement = () => {
        const {
            form,
            field: { name },
        } = this.props;

        this.setState(
            (prevState) => {
                if (Number(prevState.value) >= 1) {
                    return {
                        value: Number(prevState.value) - 1,
                    };
                }
                return prevState;
            },
            () => form.setFieldValue(name, this.state.value)
        );
    };

    onIncrement = () => {
        const {
            form,
            field: { name },
        } = this.props;

        this.setState(
            (prevState) => {
                return {
                    value: Number(prevState.value) + 1,
                };
            },
            () => form.setFieldValue(name, this.state.value)
        );
    };

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const reg = /^(0|[1-9][0-9]*)(\.[0-9]{0,2})?$/;
        if ((!Number.isNaN(parseFloat(value)) && reg.test(value)) || value === "") {
            this.setState({ value });
        }
    };

    onBlur = () => {
        const {
            form,
            field: { name },
        } = this.props;
        let { value } = this.state;
        if (value && value.toString().charAt(String(value).length - 1) === ".") {
            value = String(value).slice(0, -1);
            this.setState({ value });
        }

        form.setFieldValue(name, value === null ? null : value);
    };
}
