import styles from "./CurrentTime.module.scss";

import { ActiveLocation } from "features/location";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { gridIncrementY, xLabelsContainerHeight } from "./calendarGridSettings";
import { Tooltip } from "core/components/tooltip/Tooltip";
import { useEffect, useMemo, useState } from "react";
import { useInterval } from "common/hooks";
import { useSelector } from "react-redux";
import moment, { Moment } from "moment-timezone";

interface Props {
    style: React.CSSProperties;
    viewMode: "week" | "day";
}

export const CurrentTime = ({ style, viewMode }: Props) => {
    const activeLocation = useSelector(getActiveLocation);

    const [currentTime, setCurrentTime] = useState<Moment | undefined>(getCurrentTime(activeLocation));

    useEffect(() => {
        setCurrentTime(getCurrentTime(activeLocation));
    }, [activeLocation]);

    useInterval(() => {
        setCurrentTime(getCurrentTime(activeLocation));
    }, 10000);

    const top = getY(currentTime);

    const timeInfo = useMemo(
        () => ({
            time: currentTime?.format("HH:mm") ?? "---",
            date: currentTime?.format("DD/MM/YY") ?? "---",
        }),
        [currentTime]
    );

    return (
        <div className={styles.currentTime} style={{ ...style, top }}>
            <Tooltip
                containerClassName={styles.tooltipContainer}
                content={
                    <div>
                        <div>Time at venue: {timeInfo.time}</div>
                        <div>Date at venue: {timeInfo.date}</div>
                    </div>
                }
                placement="bottom-start"
                offset={[viewMode === "day" ? 2 : -4, 20]}
            >
                <div className={styles.tooltipTrigger} />
            </Tooltip>
        </div>
    );
};

function getCurrentTime(activeLocation: ActiveLocation | undefined) {
    if (!activeLocation) {
        return undefined;
    }

    return moment().tz(activeLocation.timeZone || "Australia/Sydney");
}

function getY(currentTime: Moment | undefined) {
    if (!currentTime) {
        return 0;
    }

    return getYFromTime(currentTime.format("HHmm")) + xLabelsContainerHeight;
}

function getYFromTime(time: string) {
    const t = parseInt(time);
    const hours = Math.floor(t / 100);
    const mins = t % 100;

    return (hours * 4 + mins / 15) * gridIncrementY;
}
