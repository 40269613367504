import { AppState } from "features";
import { isLoaded } from "common/loader/isLoaded";
import { saveLocationSettings } from "features/locationSettings/api/saveLocationSettings";
import { mapToLocationSettingsInputModel } from "features/locationSettings/actions";
import * as locationSettingsActions from "features/locationSettings/actions";
import { createAction as trackingActions } from "common/appInsights/actions/track";

export const saveQuickSellFlag = (locationId: string, isEnabled: boolean) => {
    return async function (dispatch: any, getState: () => AppState) {
        const {
            LocationSettings: { edit },
        } = getState();
        if (isLoaded(edit)) {
            await saveLocationSettings(
                locationId,
                mapToLocationSettingsInputModel({
                    ...edit.data,
                    enableQuickSell: isEnabled,
                })
            );

            dispatch(locationSettingsActions.edit(locationId));
            dispatch(
                trackingActions.track({
                    event: {
                        name: "QUICKSELL/TOGGLE_SAVED",
                    },
                    customProperties: {
                        quick_sell_status: isEnabled,
                    },
                })
            );
        }
        return undefined;
    };
};

export const saveQuickSellDrinksOnlyFlag = (locationId: string, quickSellDrinksOnly: boolean) => {
    return async function (dispatch: any, getState: () => AppState) {
        const {
            LocationSettings: { edit },
        } = getState();
        if (isLoaded(edit)) {
            await saveLocationSettings(
                locationId,
                mapToLocationSettingsInputModel({
                    ...edit.data,
                    quickSellDrinksOnly,
                })
            );

            dispatch(
                trackingActions.track({
                    event: {
                        name: "QUICKSELL/QUICK_SELL_FOOD_AND_DRINKS_TOGGLE_SAVED",
                    },
                    customProperties: {
                        qs_is_drinks_only: quickSellDrinksOnly,
                    },
                })
            );
        }
        return undefined;
    };
};
