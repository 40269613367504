import { opsApi } from "API";
import { UploadEndpoint } from "API/upload";

const createPromotionCodesFileEndpointQuery = `
  mutation ($locationId: ID!, $filename: String!) {
    createPromotionCodesFileEndpoint(locationId: $locationId, filename: $filename) {
        uploadUrl,
        uploadMethod,
        path
    }
  }
`;

export async function createPromotionCodesFileEndpoint(file: File, locationId: string) {
    const result = await opsApi.graphQLQuery<{ createPromotionCodesFileEndpoint: UploadEndpoint }>(
        createPromotionCodesFileEndpointQuery,
        { locationId, filename: file.name }
    );

    return result.data.createPromotionCodesFileEndpoint;
}
