import { useContext, useEffect, useRef } from "react";
import { MenuContext } from "./MenuContainer";
import styles from "./NavLayout.module.scss";

export const NavLayout = ({ children }: React.PropsWithChildren<{}>) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const { menuActive, setMenuActive } = useContext(MenuContext);

    // TODO use useEscape hook
    useEffect(() => {
        const keyHandler = (e: KeyboardEvent) => {
            if (menuActive && e.key === "Escape") {
                setMenuActive(false);
            }
        };

        document.addEventListener("keyup", keyHandler);

        return () => {
            document.removeEventListener("keyup", keyHandler);
        };
    }, [menuActive, setMenuActive]);

    // TODO basic test - might need other checks
    const isMenuItem = (target: HTMLElement | null) => {
        while (target && target !== ref.current) {
            if (target.getAttribute("role") === "menuitem" && target.getAttribute("aria-haspopup") !== "true") {
                return true;
            }
            target = target.parentElement;
        }

        return false;
    };

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        // overlay click
        if (e.target === ref.current) {
            e.stopPropagation();
            setMenuActive(false);
        }
        // child menu item
        if (isMenuItem(e.target as HTMLElement)) {
            setMenuActive(false);
        }
    };

    return (
        <div className={styles.container} onClick={handleClick} ref={ref}>
            {children}
        </div>
    );
};
