import { createSelector } from "reselect";
import { getIsPosSyncSupported } from "features/location/selectors/getPosMenuSupported";
import { getActiveLocation, getLocationPermissions } from "features/location/selectors/getLocationPermissions";

export const getPosSyncEnabled = createSelector(
    getIsPosSyncSupported,
    getLocationPermissions,
    getActiveLocation,
    (posSyncSupported, locationPermissions, activeLocation): boolean | undefined => {
        return activeLocation
            ? posSyncSupported && locationPermissions.has("menudata:pos:sync") && activeLocation.enablePosSync
            : undefined;
    }
);
