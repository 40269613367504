import { opsApi } from "API";
import { SurchargeType } from "../types";

export const saveSurcharges = async (locationId: string, surcharges: SurchargeInputModel[]) => {
    return await (
        await opsApi.graphQLQuery(updateQuery, { locationId, surcharges })
    ).data;
};

export interface SurchargeInputModel {
    dayOfWeek?: number;
    date?: Date;
    id?: string;
    startTime: number;
    endTime: number;
    percentage: number;
    applyToFood: boolean;
    applyToDrink: boolean;
    surchargeType: SurchargeType;
    surchargeId?: string;
    isDeleting: boolean;
}

const updateQuery = `
    mutation($locationId: ID!, $surcharges: [SurchargeUpdateInput!]!) {
        saveSurcharges(locationId: $locationId, surcharges: $surcharges)
    }
`;
