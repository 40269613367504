import { Middleware } from "redux";

import initDashboard from "features/dashboard";
import initLocation from "features/location";
import initMenuItemTemplate from "features/menuitemtemplate";
import initCategory from "features/category";
import initMenu from "features/menu";
import initService from "features/service";
import initTags from "features/tags";
import initModifiers from "features/modifier";
import initWaiter from "features/waiter";
import initCourse from "features/course";
import initPosCatalogue from "features/posCatalogue";
import initDailySalesReport from "features/dailySalesReport";
import initSection from "features/section";
import initPreview from "features/preview";
import initBranding from "features/branding";
import initLocationSettings from "features/locationSettings";
import initGroup from "features/group";
import initPriceList from "features/priceList";
import initQuickSell from "features/quickSell";
import initMembershipLevel from "features/membershipLevel";
import initCatalogue from "features/catalogue";
import initRegion from "features/region";
import initPayment from "features/payment";
import initSurcharges from "features/surcharges";
import initGroupTabs from "features/groupTabs";
import initPosConfiguration from "features/posConfiguration";
import initTaxes from "features/taxes";
import initAnalytics from "common/appInsights";
import initStripe from "features/stripe";
import initPromotions from "features/promotions";
import initPosCatalogueImport from "features/posCatalogueImport";
import initAnalyticsDashboard from "features/analytics";
import initMemberships from "features/memberships";
import initAuditLog from "features/auditlog";
import initTakeaway from "features/takeaway";
import initLocationRelations from "features/locationRelations";

export * from "./state";

export interface FeatureConfiguration {
    middleware: Middleware[];
    reducers: any;
    routes: RoutesConfiguration;
    addRoutes(target: React.ReactNode[], routes: React.ReactNode[]): void;
}

export interface RoutesConfiguration {
    locationRoutes: React.ReactNode[];
}

type InitFeatureFn = (config: FeatureConfiguration) => void;

const initFeatureFunctions: InitFeatureFn[] = [
    initDashboard,
    initLocation,
    initCategory,
    initMenu,
    initService,
    initMenuItemTemplate,
    initTags,
    initModifiers,
    initWaiter,
    initCourse,
    initDailySalesReport,
    initPosCatalogue,
    initSection,
    initPreview,
    initBranding,
    initLocationSettings,
    initGroup,
    initPriceList,
    initMembershipLevel,
    initCatalogue,
    initQuickSell,
    initRegion,
    initPayment,
    initSurcharges,
    initGroupTabs,
    initPosConfiguration,
    initTaxes,
    initAnalytics,
    initStripe,
    initPromotions,
    initPosCatalogueImport,
    initAnalyticsDashboard,
    initMemberships,
    initAuditLog,
    initTakeaway,
    initLocationRelations,
];

const config: FeatureConfiguration = {
    middleware: [],
    reducers: {},
    routes: {
        locationRoutes: [],
    },
    addRoutes: (target: React.ReactNode[], routes: React.ReactNode[]) => {
        routes.forEach((r) => {
            target.push(r);
        });
    },
};

for (const initFeature of initFeatureFunctions) {
    initFeature(config);
}

export default config;
