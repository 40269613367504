import { opsApi } from "API";
import { PosSyncResult } from "../types";

const syncPosCatalogueQuery = `
  mutation ($locationId: ID!) {
    syncPosCatalogue(locationId: $locationId) {
      catalogueRetrieved
      catalogueSynced
      responseMessage
      syncStarted
      syncStats {
        productStats {
          syncedCount
          ignoredCount
          missingFromPosCount
        }
        variantStats {
          syncedCount
          ignoredCount
          missingFromPosCount
        }
         modifierGroupStats {
          syncedCount
          ignoredCount
          missingFromPosCount
        }
        modifierOptionStats {
          syncedCount
          ignoredCount
          missingFromPosCount
        }
        syncDurationMilliseconds
        completedTimeUtc
      }
      results {
        sku
        objectType
        outputObjectType
        result
      }
    }
  }
`;

export async function syncPosCatalogue(locationId: string) {
    const variables = {
        locationId,
    };

    const result = await opsApi.graphQLQuery<{ posSyncResult: PosSyncResult }>(syncPosCatalogueQuery, variables);

    return result.data.syncPosCatalogue;
}
