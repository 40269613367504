import * as React from "react";
import { Button, Checkbox, Dropdown, Input, Menu } from "antd";
import "./CheckboxFilter.scss";
import { useEffect, useState } from "react";

export interface Option {
    id: string;
    displayName: string;
    internalName?: string | null;
    displayGroup?: string;
}

export interface Props {
    options: Option[];
    placeholder?: string;
    onSelect: (options: Set<Option>) => void;
    title?: string;
    initialSelection?: string[];
}

export const CheckboxFilter = ({ options, onSelect, placeholder = "Select ...", title, initialSelection }: Props) => {
    const [selectedOptions, setSelectedOptions] = useState<Set<Option>>(new Set());
    const [displayString, setDisplayString] = useState("");
    const [menuVisible, setMenuVisible] = useState(false);
    const [groups, setGroups] = useState<string[]>([]);

    useEffect(() => {
        if (initialSelection) {
            const selected = options.filter((o) => initialSelection.indexOf(o.id) !== -1);
            setSelectedOptions(new Set(selected));
        }
    }, [initialSelection, options]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onSelectCallback = React.useCallback(onSelect, []);

    useEffect(() => {
        onSelectCallback(selectedOptions);
        setDisplayString(
            Array.from(selectedOptions)
                .map((o) => o.displayName)
                .join(", ")
        );
    }, [selectedOptions, onSelectCallback]);

    const onClick = (item: Option) => {
        const items = selectedOptions;
        selectedOptions.has(item) ? items.delete(item) : items.add(item);
        setSelectedOptions(new Set(items));
    };

    const onClearFilter = () => {
        const items = selectedOptions;
        items.clear();

        setSelectedOptions(items);
        setDisplayString("");
        onSelect(items);
        setMenuVisible(false);
    };

    useEffect(() => {
        const allGroups = options.filter((i) => !!i.displayGroup).map((o) => o.displayGroup || "");
        setGroups(Array.from(new Set(allGroups)));
    }, [options]);

    const menuItems = (items: Option[]) => {
        return items.map((item) => (
            <Menu.Item key={item.id}>
                <Checkbox checked={selectedOptions.has(item)} onClick={() => onClick(item)}>
                    {item.displayName}
                    {item.internalName && (
                        <>
                            {" "}
                            <em> ({item.internalName})</em>
                        </>
                    )}
                </Checkbox>
            </Menu.Item>
        ));
    };

    const menu = () => (
        <>
            <Menu>
                {groups && !!groups.length
                    ? groups.map((value) => (
                          <Menu.ItemGroup key={value} title={value}>
                              {menuItems(options.filter((i) => i.displayGroup === value))}
                          </Menu.ItemGroup>
                      ))
                    : menuItems(options)}
            </Menu>

            <div className="filter-commands">
                <Button onClick={onClearFilter}>Clear filter</Button>
            </div>
        </>
    );

    return (
        <div className="filter-wrapper">
            {title && <span>{title}</span>}
            <Dropdown
                className="truncate"
                overlay={menu}
                trigger={["click"]}
                visible={menuVisible}
                onVisibleChange={setMenuVisible}
                overlayClassName="filter-dropdown-wrapper"
            >
                <Input type="text" value={displayString} placeholder={placeholder} />
            </Dropdown>
        </div>
    );
};
