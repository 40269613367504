import styles from "./FeatureBadge.module.scss";

import { PropsWithChildren } from "react";
import classNames from "classnames";

export type SpanProps = React.HTMLProps<HTMLSpanElement>;

interface Props extends SpanProps {
    className?: string;
}

export const FeatureBadge = ({ children, className, ...rest }: PropsWithChildren<Props>) => {
    return (
        <span className={classNames(styles.label, className)} {...rest}>
            {children}
        </span>
    );
};
