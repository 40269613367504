import { AppState } from "features";
import { createSelector } from "reselect";
import { meanduColor, replaceImageUrlWithOrig } from "../containers/EditBrandingPageContainer";
import { Branding } from "../types";

const getExisting = ({ locations: { edit } }: AppState) => {
    return edit.status === "loaded" ? edit.data : null;
};

export const getSaveBrandingTrackingEventData = createSelector(
    getExisting,
    (_: AppState, update: Branding) => update,
    (existing, update) => {
        const changes: any = {};

        if (existing && existing.themeColor !== update.themeColor) {
            const isCustomVenueColor = update.themeColor !== meanduColor;
            changes.custom_venue_color = isCustomVenueColor;

            if (isCustomVenueColor) {
                changes.theme_color = update.themeColor;
            }
        }

        if (existing && isImageUrlChanged(existing.logoUrl, update.logoUrl)) {
            changes.logo = getImageUpdateStatus(existing.logoUrl, update.logoUrl);
        }

        if (existing && isImageUrlChanged(existing.menuBackgroundImage, update.menuBackgroundImage)) {
            changes.menu_background_image = getImageUpdateStatus(
                existing.menuBackgroundImage,
                update.menuBackgroundImage
            );
        }

        if (existing && existing.heroImage !== update.heroImage) {
            changes.hero_image = getImageUpdateStatus(existing.heroImage, update.heroImage);
        }

        if (existing && isImageUrlChanged(existing.backgroundImage, update.backgroundImage)) {
            changes.background_image = getImageUpdateStatus(existing.backgroundImage, update.backgroundImage);
        }

        if (existing && existing.defaultCatalogueImage !== update.defaultCatalogueImage) {
            changes.default_image = getImageUpdateStatus(existing.defaultCatalogueImage, update.defaultCatalogueImage);
        }

        return {
            event: {
                name: "BRANDING/SETTINGS_SAVED",
            },
            customProperties: changes,
        };
    }
);

const isImageUrlChanged = (existingUrl?: string, updatedUrl?: string | File | null) => {
    if (updatedUrl !== null && existingUrl !== updatedUrl) {
        let existingImageUrl: string | undefined | null = existingUrl;
        if (existingUrl?.includes("-full")) {
            existingImageUrl = replaceImageUrlWithOrig(existingUrl);
        }

        let updateImageUrl: string | File | undefined | null = updatedUrl;
        if (typeof updatedUrl === "string" && updatedUrl.includes("-full")) {
            updateImageUrl = replaceImageUrlWithOrig(updatedUrl);
        }

        return existingImageUrl !== updateImageUrl;
    }
    return false;
};
const getImageUpdateStatus = (existingUrl?: string, updatedUrl?: string | File | null) => {
    return !existingUrl && updatedUrl ? "added" : existingUrl && !updatedUrl ? "removed" : "updated";
};
