import { PosUISettings } from ".";

export const mpowerconnectUISettings: PosUISettings = {
    servicePosId: {
        visible: false,
    },
    surchargeSku: {
        visible: false,
    },
    priceListPosId: {
        visible: true,
        title: "Price List POS ID",
        subTitle: "",
    },
    taxPosId: {
        visible: false,
    },
};
