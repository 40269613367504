import styles from "./Analytics.module.scss";
import IframeResizer from "iframe-resizer-react";
import { useSelector } from "react-redux";
import { getAnalyticsDashboarUrl } from "../selectors/getAnalyticsDashboardUrl";
import { useLoadStatus, usePrevious } from "common/hooks";
import { getState } from "common/selectors/getState";
import { LoadStatus } from "common/loader";
import { PageLoading } from "core/components/pageLoading";
import { PageError } from "core/components/pageError";
import { Button } from "core/components/button";
import { useEffect, useState } from "react";
import classNames from "classnames";

interface ContainerProps {
    onRetry: () => void;
}

interface Props extends ContainerProps {
    dashboardEmbedUrl: string;
    status: LoadStatus;
}

export const AnalyticsTabContainer = ({ onRetry }: ContainerProps) => {
    const state = useSelector(getState);
    const analytics = useSelector(getAnalyticsDashboarUrl);
    const status = useLoadStatus([state.analytics.dashboardUrl.status]);

    if (!analytics) {
        return null;
    }

    return <AnalyticsTabComponent dashboardEmbedUrl={analytics.dashboardEmbedUrl} onRetry={onRetry} status={status} />;
};

const AnalyticsTabComponent = ({ dashboardEmbedUrl, onRetry, status }: Props) => {
    const [iframeLoaded, setIframeLoaded] = useState(false);

    const prevDashboardEmbedUrl = usePrevious(dashboardEmbedUrl);

    useEffect(() => {
        if (prevDashboardEmbedUrl !== dashboardEmbedUrl) {
            setIframeLoaded(false);
        }
    }, [dashboardEmbedUrl, prevDashboardEmbedUrl]);

    if (status === "loading") {
        return (
            <div className={styles.loading}>
                <PageLoading message="Loading Analytics..." />
            </div>
        );
    }

    if (status === "failed") {
        return (
            <PageError
                heading="Oh no! There was a problem loading Analytics"
                message="There was a technical problem that prevented me&u from loading this page. Try reloading this page or try again later."
                actions={
                    <>
                        <Button onClick={onRetry}>Retry</Button>
                    </>
                }
            />
        );
    }

    return (
        <>
            {!iframeLoaded && (
                <div className={styles.loading}>
                    <PageLoading message="Loading Analytics..." />
                </div>
            )}
            <IframeResizer
                onLoad={() => setIframeLoaded(true)}
                log={false}
                autoResize={true}
                src={dashboardEmbedUrl}
                className={classNames(styles.metabaseIframe, iframeLoaded && styles.fadeIn)}
            />
        </>
    );
};

export const AnalyticsTab = AnalyticsTabContainer;
