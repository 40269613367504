import { createAction } from "../reducers/list";
import { sortByDisplayName } from "foundation/dataConventions/sortByDisplayName";
import { MembershipLevelSummary } from "..";
import { scaffoldListAction } from "../../../common/scaffolding/actions/scaffoldListAction";
import { listMembershipLevels } from "../api/listMembershipLevels";

export const list = (location: string) =>
    scaffoldListAction<MembershipLevelSummary>(
        (state) => state.membershipLevels.list,
        createAction,
        async () => {
            const membershipLevels = await listMembershipLevels(location);

            return membershipLevels.sort(sortByDisplayName);
        }
    );
