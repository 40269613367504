import styles from "./Tabs.module.scss";

import { PropsWithChildren } from "react";

// export with children for typings in Tabs which clones element
interface TabProps extends PropsWithChildren<any> {
    active: boolean;
    id: string;
}

export const Tab = ({ active, children, id, onClick }: TabProps) => {
    return (
        <button
            aria-controls={`${id}-tabpanel`}
            aria-selected={active}
            className={active ? styles.tabActive : styles.tab}
            id={id}
            onClick={onClick}
            role="tab"
        >
            {children}
        </button>
    );
};
