import { opsApi } from "API";
import { QuickSellSummary } from "../types";

export const fetchQuickSellData = async (locationId: string): Promise<QuickSellSummary> => {
    const res = await opsApi.graphQLQuery<QuickSellSummary>(query, { locationId });
    return res.data.quickSell;
};

const query = `
    query($locationId: ID!) {
        quickSell(locationId: $locationId) {
            locationId,
            conversion,
            totalSales,
            totalValue,
            services {
                serviceId
                conversion
                totalSales
                totalValue
                items {
                    itemId
                    displayName
                    internalName
                    conversion
                    sales
                    sku
                }
            },
            dateUpdated
        }
    }
`;
