import { actions as catalogueActions } from "features/catalogue";
import { actions as modifierActions } from "features/modifier";
import { AppState } from "features";
import { BulkUploadType } from "../api/bulkUpload";
import { CatalogueRouteParams } from "../types/CatalogueRouteParams";
import { CsvUploadContext } from "../context/CsvUploadContext";
import { FEATURE_CONFIG } from "common/feature-variables";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { getIsEnergyContentSupported } from "features/location/selectors/getEnergyContentSupported";
import {
    getModifierExportTemplate,
    productTemplateData,
    productTemplateDataWithEnergyContent,
} from "../csv/csvTemplates";
import { isLoaded } from "common/loader/isLoaded";
import { ModalUpload as ModalUploadComponent } from "../components/ModalUpload";
import { State as ImportState } from "../reducers/import";
import { State as UploadState } from "../reducers/upload";
import { useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { usePermissions } from "features/location/hooks";

const ModalUploadContainer = () => {
    const bulkImport = useSelector<AppState, ImportState>((state) => state.catalogue.bulkImport);
    const dispatch = useDispatch();
    const energyContentSupported = useSelector(getIsEnergyContentSupported) || false;
    const history = useHistory();
    const importResult = isLoaded(bulkImport) ? bulkImport.data : undefined;
    const importStatus = bulkImport.status;
    const permissions = usePermissions().catalogue;
    const restaurantLocation = useSelector(getActiveLocation);
    const upload = useSelector<AppState, UploadState>((state) => state.catalogue.upload);
    const uploadResult = isLoaded(upload) ? upload.data : undefined;
    const uploadStatus = upload.status;
    const { showUploadModal, setShowUploadModal } = useContext(CsvUploadContext);
    const { tab } = useParams<CatalogueRouteParams>();

    const upsellTypesSupported = FEATURE_CONFIG.enableUpsellTypes === "1";

    const templateData =
        tab === "products"
            ? energyContentSupported
                ? productTemplateDataWithEnergyContent
                : productTemplateData
            : getModifierExportTemplate(energyContentSupported, upsellTypesSupported);

    const handleUpload = useCallback(
        (file: File, type: BulkUploadType) => {
            restaurantLocation && dispatch(catalogueActions.uploadImportFile(restaurantLocation.id, type, file));
        },
        [dispatch, restaurantLocation]
    );

    const handleImport = useCallback(
        (fileName: string, preview: boolean) => {
            const endpoint = tab === "products" ? catalogueActions.importProducts : catalogueActions.importModifiers;
            restaurantLocation && dispatch(endpoint(fileName, restaurantLocation.id, preview));
        },
        [dispatch, restaurantLocation, tab]
    );

    const handleLoadNew = useCallback(() => {
        dispatch(catalogueActions.resetBulkUpload());
        restaurantLocation &&
            dispatch(
                tab === "products"
                    ? catalogueActions.listProducts(restaurantLocation.id, true)
                    : modifierActions.list(restaurantLocation.id, true)
            );
        history.replace(`${window.location.pathname}?s=${encodeURIComponent("Missing content")}`);
        setShowUploadModal(false);
    }, [dispatch, history, restaurantLocation, setShowUploadModal, tab]);

    const handleResetModal = useCallback(() => {
        setShowUploadModal(false);
        dispatch(catalogueActions.resetBulkUpload());
    }, [dispatch, setShowUploadModal]);

    if (!tab) {
        return null;
    }

    return (
        <ModalUploadComponent
            childRecordText={tab === "products" ? "variant" : "option"}
            importResult={importResult}
            importStatus={importStatus}
            onImport={handleImport}
            onLoadNew={handleLoadNew}
            onResetForm={handleResetModal}
            onUpload={handleUpload}
            parentRecordText={tab === "products" ? "product" : "modifier"}
            permissions={permissions}
            templateData={templateData}
            uploadResult={uploadResult}
            uploadStatus={uploadStatus}
            visible={showUploadModal}
        />
    );
};

export const ModalUpload = ModalUploadContainer;
