import { FeatureConfiguration } from "features";
import { reducer } from "./reducers";
import * as actions from "./actions";
import { withPermissions } from "features/location/components/withPermission";
import { Route } from "react-router";
import { Col } from "antd";
import { PosConfigurationPageContainer } from "./containers/PosConfigurationPageContainer";
import { requirePosOperationSupport } from "./components/requirePosOperationSupport";

export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.posConfiguration = reducer.posConfiguration;
    config.reducers.posLookups = reducer.posLookups;

    config.addRoutes(config.routes.locationRoutes, [
        <Route
            path={`/:region/:location/pos/configuration`}
            key="posconfiguration"
            exact
            render={() => (
                <Col span={24} md={24} className="content__col content__col--white">
                    <div className="content__col-edit">
                        <Route
                            component={requirePosOperationSupport("SetConfiguration")(
                                withPermissions(PosConfigurationPageContainer, "location:pos:configuration:read")
                            )}
                        />
                    </div>
                </Col>
            )}
        />,
    ]);
}
