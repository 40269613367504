import { FeatureConfiguration } from "..";
import { reducer } from "./reducers";
import * as actions from "./actions";
import { Route } from "react-router";
import { Col } from "antd";
import { withPermissions } from "../location/components/withPermission";
import { EditLocationSettingsPageContainer } from "./containers/EditLocationSettingsPageContainer";

export * from "./types/EditableLocationSettings";

export { actions };

export default function (config: FeatureConfiguration) {
    config.reducers.LocationSettings = reducer;

    config.addRoutes(config.routes.locationRoutes, [
        <Route
            path={"/:region/:location/settings"}
            key="location-settings"
            render={() => (
                <Col span={24} md={24} className="content__col">
                    <div className="content__col-edit">
                        <Route component={withPermissions(EditLocationSettingsPageContainer, "settings:read")} />
                    </div>
                </Col>
            )}
        />,
    ]);
}
