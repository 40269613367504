import { FormikProps, getIn } from "formik";
import { ReactElement, PropsWithChildren, useState, useEffect } from "react";
import { Form } from "antd";
import { useTimer } from "common/hooks/useTimer";

export interface FieldErrorsProps {
    form: FormikProps<any>;
    name: string;
    className?: string;
    render?: (error: string | undefined) => ReactElement | null;
}

export const FieldErrors: React.FC<FieldErrorsProps> = (props: PropsWithChildren<FieldErrorsProps>) => {
    const {
        children,
        render,
        form: { errors, touched },
        name,
        className,
    } = props;

    const error = getIn(errors, name);
    const touch = getIn(touched, name);

    const [errorEnabled, setErrorEnabled] = useState<boolean>(false);

    const { setTimer } = useTimer();

    // block initial error display for a fraction of a second
    // prevents flash of error messages on field when values are valid.
    useEffect(() => {
        if (render) {
            return;
        }

        if (error && touch && !errorEnabled) {
            setTimer(() => {
                setErrorEnabled(true);
            }, 25);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, touched, render]);

    if (render) {
        return render(error);
    }

    return touch && error && typeof error === "string" && errorEnabled ? (
        <Form.Item className={className} validateStatus="error" help={error}>
            {children}
        </Form.Item>
    ) : (
        <Form.Item className={className}>{children}</Form.Item>
    );
};
