import { TrackEventData } from "common/appInsights/types/TrackEventData";
import { Tab } from "../types/Tab";

export const getDateChangedData = (date: string, tab: Tab): TrackEventData => ({
    event: { name: "SERVICES/DATE_CHANGED" },
    customProperties: { current_view: tab, date },
});

export const getStartDateChangedData = (date: string, tab: Tab): TrackEventData => ({
    event: { name: "SERVICES/DATE_CHANGED" },
    customProperties: { current_view: tab, week_start_date: date },
});

export const getSectionChangedData = (sectionId: string, tab: Tab): TrackEventData => ({
    event: { name: "SERVICES/SECTION_CHANGED" },
    customProperties: { current_view: Tab.WEEK, section_id: sectionId },
});
