import { FeatureConfiguration } from "features";
import { withPermissions } from "features/location/components/withPermission";
import * as actions from "./actions";
import { Route } from "react-router";
import { reducer } from "./reducers";
import { PlainWrapper } from "common/scaffolding/components/PlainWrapper";
import { AnalyticsPage } from "./components/AnalyticsPage";

export { actions };

const Component = withPermissions(AnalyticsPage, "metabase:read");

export default function (config: FeatureConfiguration) {
    config.reducers.analytics = reducer;
    config.addRoutes(config.routes.locationRoutes, [
        <Route
            path={"/:region/:location/analytics/:folderSlug/:pageSlug"}
            key="analytics"
            render={(props) => (
                <PlainWrapper name="analytics">
                    <Component {...props} key={`${props.match.params.pageSlug}`} />
                </PlainWrapper>
            )}
        />,
    ]);
}
