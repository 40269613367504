import { Button } from "core/components/button";
import { Card, Row, Title } from "core/components/card";
import { EditableProduct, EditableVariant } from "features/catalogue/types";
import { EditableModifier } from "features/modifier";
import { FieldArray, useFormikContext } from "formik";
import { getTempPriceId } from "features/catalogue/components/product/edit/productHelpers";
import { ItemValidationStatus } from "features/catalogue/types";
import { LocationLocale } from "features/location/types/LocationLocale";
import { Variants } from "./Variants";

interface Props {
    disableFields: boolean;
    disableLinkedFields: boolean;
    locationLocale: LocationLocale;
    posSyncEnabled: boolean;
    syncStatus: ItemValidationStatus | undefined;
}

export const ProductPrices = ({
    disableFields,
    disableLinkedFields,
    locationLocale,
    posSyncEnabled,
    syncStatus,
}: Props) => {
    const { values } = useFormikContext<EditableProduct>();

    return (
        <Card>
            <Row collapse="down">
                <Title title="Price" />
                <FieldArray
                    name="prices"
                    render={(props) => (
                        <Button
                            disabled={disableFields || posSyncEnabled || disableLinkedFields}
                            colorScheme="interactive"
                            type="button"
                            onClick={() => {
                                props.push(newVariant(values.modifiers));
                            }}
                        >
                            Add variant
                        </Button>
                    )}
                />
            </Row>

            <Row>
                <FieldArray
                    name="prices"
                    render={(props) => (
                        <Variants
                            {...props}
                            disableFields={disableFields}
                            disableLinkedFields={disableLinkedFields}
                            locationLocale={locationLocale}
                            posSyncEnabled={posSyncEnabled}
                            syncStatus={syncStatus}
                        />
                    )}
                />
            </Row>
        </Card>
    );
};

function newVariant(modifiers: EditableModifier[]): EditableVariant {
    return {
        displayName: "",
        energyContent: "",
        id: `${getTempPriceId()}`,
        isNew: true,
        modifiers: modifiers.map((x) => x.id!),
        price: "",
        sku: "",
        taxRate: null,
        isLinked: false,
    };
}
