import { FieldProps } from "formik";
import { Input, InputProps } from "antd";
import { useMemo } from "react";

export type InputTestConfig = {
    numDigits?: number | undefined;
    numDecimals?: number;
    allowNegative?: boolean;
};

type InputTest = InputTestConfig | RegExp | string;

export interface Props {
    inputTest: InputTest;
    parseValue?: (value: string) => any;
}

// default input regex accepts postive decimal number of any length
const defaultInputRegex = new RegExp("^[0-9]*(\\.([0-9]*)?)?$");

const defaultParseValue = (value: string) => {
    const n = parseFloat(value);
    if (isNaN(n)) {
        return "";
    }
    return n;
};

export const parseValueToFixed =
    (dp: number) =>
    (value: string): string => {
        const n = parseFloat(value);
        if (isNaN(n)) {
            return "";
        }
        return n.toFixed(dp);
    };

const isValidInputChange = (e: React.ChangeEvent<HTMLInputElement>, regex: RegExp) => {
    const rawValue = e.target.value;

    // allows user to clear field
    if (rawValue === "") {
        return true;
    }

    return regex.test(rawValue);
};

export const InputNumber = ({
    form: { setFieldValue },
    field,
    inputTest,
    parseValue = defaultParseValue,
    meta,
    ...rest
}: Props & FieldProps & InputProps) => {
    const { name, value, onChange, onBlur } = field || {};

    const regex = useMemo(() => {
        try {
            return configureInputTest(inputTest);
        } catch (err) {
            console.error(
                "Could not parse input test. Valid values are a regex, string representation or a config object",
                err
            );
            return defaultInputRegex;
        }
    }, [inputTest]);

    return (
        <Input
            data-testid={`input-${name}`}
            name={name}
            value={value}
            onChange={(e) => {
                if (isValidInputChange(e, regex)) {
                    setFieldValue(name, value);
                    onChange(e);
                } else {
                    e.preventDefault();
                }
            }}
            onBlur={(e) => {
                setFieldValue(name, parseValue(value));
                onBlur(e);
            }}
            {...rest}
        />
    );
};

export function buildInputRegex(config: InputTestConfig = {}): RegExp {
    const numDigits = config?.numDigits ?? undefined;
    const numDecimals = config?.numDecimals || 0;
    const allowNegative = config?.allowNegative || false;

    const parts: string[] = ["^"];

    if (allowNegative) {
        parts.push("-?");
    }

    parts.push(numDigits === undefined ? `[0-9]*` : `[0-9]{0,${numDigits}}`);

    if (numDecimals > 0) {
        parts.push(`(\\.([0-9]{0,${numDecimals}})?)?`);
    }

    parts.push("$");

    return new RegExp(parts.join(""));
}

function configureInputTest(inputTest: InputTest) {
    if (!inputTest) {
        return defaultInputRegex;
    }

    if (inputTest instanceof RegExp) {
        return inputTest;
    }

    if (typeof inputTest === "string") {
        return new RegExp(inputTest);
    }

    return buildInputRegex(inputTest);
}
