import { connect } from "react-redux";
import * as actions from "../actions";
import { RouteComponentProps, withRouter } from "react-router";
import { FunctionProps, DataProps } from "types/utils";
import { PageLoadingProps, withLoader } from "common/loader/withLoader";
import { Props, DailySalesDetailsModal } from "../components/DailySalesDetailsModal";
import { LocationRouteParams } from "common/types";
import { AppState } from "../..";
import { isLoaded } from "../../../common/loader/isLoaded";
import { getIsGroupTabGratuitySupported } from "../../location/selectors/getGroupTabsGratuitySupported";

interface OwnProps {
    diningDate: Date;
}

const mapStateToProps = (state: AppState): DataProps<PageLoadingProps<Props>> => {
    const {
        dailySalesReport: { details },
        locations: { active: activeLocation },
    } = state;

    if (details.status === "loaded" && isLoaded(activeLocation)) {
        return {
            loadStatus: "loaded",
            details: details.data,
            groupTabGratuitySupported: getIsGroupTabGratuitySupported(activeLocation.data),
        };
    }

    return {
        loadStatus: details.status,
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { match: { params }, diningDate }: OwnProps & RouteComponentProps<LocationRouteParams>
): FunctionProps<Props> => ({
    fetch: () => dispatch(actions.fetchDetails(params.location, diningDate)),
});

export const DailySalesDetailsModalContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withLoader(DailySalesDetailsModal))
);
