import { PropsWithChildren } from "react";
import classNames from "classnames";
import { WarningSolid } from "common/icons";
import { IconWithText } from ".";

interface Props {
    className?: string;
}

export const Warning = ({ className, children }: PropsWithChildren<Props>) => (
    <IconWithText className={classNames("warning", className)} icon={WarningSolid}>
        {children}
    </IconWithText>
);
