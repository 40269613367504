import { useCallback } from "react";

// TODO for non-english locales, this should really use
// the locale's formatting tools (not toFixed(2))
// But this should be undertaken carefully as it would affect
// any underlying assumptions that rely on numeric conversion
// e.g. Number("1.23") vs Number("1,23")
export function useCurrencyParser() {
    // format number to 2dp if we have a value, but allow empty
    return useCallback((value: string | number) => {
        if (value === "" || value === undefined) {
            return value;
        }

        if (isNaN(Number(value))) {
            return (0).toFixed(2);
        }

        return Number(value).toFixed(2);
    }, []);
}
