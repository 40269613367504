import { EditableCourse } from "features/course/types";
import { createAction } from "../reducers/edit";
import { CourseInputModel, saveCourse } from "../api/saveCourse";
import { scaffoldSaveAction } from "../../../common/scaffolding/actions/scaffoldSaveAction";

export const save = (location: string, editableCourse: EditableCourse, clone: boolean = false) =>
    scaffoldSaveAction(
        (state) => state.courses.edit,
        createAction,
        async (courseId: string | undefined) => {
            const courseInputModel = await mapInputModel(editableCourse);
            return await saveCourse(location, courseId, courseInputModel);
        },
        undefined,
        clone
    );

const mapInputModel = ({ internalName, displayName, sku, categories }: EditableCourse): CourseInputModel => ({
    internalName,
    displayName,
    sku,
    categories,
});
