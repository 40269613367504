import { AppState } from "features";
import { createSelector } from "reselect";


const posLookupState = (state: AppState) => state.posLookups;

const UNLOADED_STATE = { status: "unloaded" };

export const getPosLookup = createSelector(
    posLookupState,
    (state) => (key: string, scope?: string) => {
        const compositeKey = `${key}!!${scope}`;

        return state[compositeKey] ?? UNLOADED_STATE;
    }
)