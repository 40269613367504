import { EditableModifier } from "features/modifier/types/EditableModifier";
import { scaffoldEdit } from "../../../common/scaffolding/reducers/scaffoldEdit";
import { ModifierSummary } from "..";

export enum TypeKeys {
    FETCH_BEGIN = "MODIFIER/FETCH_EDITABLE_BEGIN",
    FETCH_SUCCESS = "MODIFIER/FETCH_EDITABLE_SUCCESS",
    FETCH_FAILURE = "MODIFIER/FETCH_EDITABLE_FAILURE",

    SAVE_BEGIN = "MODIFIER/SAVE_EDITABLE_BEGIN",
    SAVE_SUCCESS = "MODIFIER/SAVE_EDITABLE_SUCCESS",
    SAVE_FAILURE = "MODIFIER/SAVE_EDITABLE_FAILURE",
}

const edit = scaffoldEdit<EditableModifier, ModifierSummary, typeof TypeKeys>(TypeKeys);
const { createAction, reducer } = edit;

export type State = ReturnType<typeof edit.reducer>;
export { createAction, reducer };
