import { opsApiFetch } from "API/fetch";
import { downloadBlob } from "common/utility/fileUtils";
import moment, { Moment } from "moment";

export async function fetchCsvExport(locationId: string, startDate: Moment, endDate: Moment) {
    return opsApiFetch(
        `/reports/sales/${locationId}?start=${startDate.format("YYYY-MM-DD")}&end=${endDate.format("YYYY-MM-DD")}`
    )
        .then((res) => res.blob())
        .then((blob) =>
            downloadBlob(
                blob,
                `meu-export-${startDate.format("YYYYMMDD")}-${endDate.format("YYYYMMDD")}-snapshot-${moment().format(
                    "YYYYMMDD-HHmm"
                )}.csv`
            )
        );
}
