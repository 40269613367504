import classNames from "classnames";
import "./Indicator.scss";

interface Props {
    label: string;
    className?: string;
}

export const Indicator = ({ label, className }: Props) => {
    return <span className={classNames("indicator indicator__red", className)}>{label}</span>;
};
