import { AnimateSharedLayout, motion } from "framer-motion";
import { Snackbar } from "./Snackbar";
import { useSnackbarContext } from "./SnackbarContext";
import styles from "./SnackbarList.module.scss";

interface Props {}

export const SnackbarList = ({}: Props) => {
    const { messages } = useSnackbarContext();

    return (
        <div className={styles.root}>
            <AnimateSharedLayout>
                <motion.ul className={styles.list} layout>
                    {messages.map((message) => (
                        <Snackbar message={message} key={message.id} />
                    ))}
                </motion.ul>
            </AnimateSharedLayout>
        </div>
    );
};
