import { AppState } from "features/state";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import { LocationRouteParams } from "common/types";
import { getIsPosMenuSupported } from "features/location/selectors/getPosMenuSupported";
import { Props, CmsHeader } from "./CmsHeader";
function mapStateFromProps(state: AppState, props: RouteComponentProps<LocationRouteParams>): Props {
    return {
        posMenuSupported: getIsPosMenuSupported(state),
    };
}

export const CmsHeaderContainer = withRouter(connect(mapStateFromProps)(CmsHeader));
