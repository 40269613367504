import { createAction } from "../reducers/edit";
import { scaffoldSaveAction } from "../../../common/scaffolding/actions/scaffoldSaveAction";
import { deleteLocation } from "../api/deleteLocation";

export const archive = (
    location: string,
    callback?: () => void
) =>
    scaffoldSaveAction(
        (state) => state.locations.edit,
        createAction,
        async (locationId: string | undefined) => {
            return await deleteLocation(locationId);
        },
        undefined,
        false,
        callback,
        undefined,
        () => ({
            event: { name: "LOCATION/DELETED" },
            customProperties: { locationId: location }
        })
    );
