import classNames from "classnames";
import { PropsWithChildren } from "react";

export interface Props extends PropsWithChildren<Record<string, any>> {
    as?: React.ElementType;
    className?: string;
    defaultStyle: string;
}

/*
 * Abstract container implementation.
 * For examples of extension, see PageContainer, CardsContainer
 * Intended for applying container styling - not to be confused with a data container
 * Use `as` prop to render alternate element or Custom component
 */

export const Container = ({ as: Component = "div", children, className, defaultStyle, ...rest }: Props) => {
    return (
        <Component className={classNames(defaultStyle, className)} {...rest}>
            {children}
        </Component>
    );
};
