import * as Yup from "yup";
import { EditableDateRange, EditableService } from "features/service/types";
import { CourseSummary } from "features/course";

export const optionalPriceSchema = Yup.number().nullable(true).min(0, "Please enter a positive price");
export const requiredPriceSchema = Yup.number()
    .required("Please enter a price")
    .min(0, "Please enter a positive price");

export const editableDateRangeSchema = Yup.object<EditableDateRange>().shape({
    startDate: Yup.string().notRequired(),
    endDate: Yup.string().notRequired(),
});

export const editableServiceSchema = Yup.object<EditableService>().shape({
    internalName: Yup.string().nullable(true),
    displayName: Yup.string().required("Please enter a name"),
    times: Yup.object({
        startTime: Yup.number().required("Please enter the start time"),
        endTime: Yup.number().required("Please enter the end time"),
    }),
    dates: Yup.array().of(editableDateRangeSchema),
    categories: Yup.array().of(Yup.string()),
    menus: Yup.array().of(Yup.string()),
    courses: Yup.array()
        .of(Yup.string())
        .when("$courses", (courses: CourseSummary[], schema: Yup.ArraySchema<string>) =>
            courses.length > 0 ? schema.min(1, "Please select a course") : schema
        ),
    defaultCourse: Yup.string().when("$courses", (courses: CourseSummary[], schema: Yup.ArraySchema<string>) =>
        courses.length > 0 ? schema.required("Please select a default course") : schema.notRequired()
    ),
    setMenus: Yup.array().of(Yup.string()),
    dropDownListOptions: Yup.array(),
    daysOfWeek: Yup.array().of(Yup.number()),
    surcharge: Yup.number().nullable(true),
    surchargeSku: Yup.string().nullable(true),
    available: Yup.boolean(),
    posId: Yup.string().nullable(true),
    sections: Yup.array().of(Yup.string()),
    menuPriority: Yup.string(),
    featured: Yup.object({
        item: Yup.string().nullable(true),
        title: Yup.string().trim().max(20, "Title must be less than 20 characters.").nullable(true),
    }),
    priceList: Yup.string().nullable(true),
    membershipPriceLists: Yup.object(),
    applyAlcoholicDrinksRestriction: Yup.boolean().nullable(true),
});
