import { createAction } from "features/service/reducers/edit";
import { EditableService, DaysOfWeek, MembershipPriceListMap } from "features/service/types";
import { isNew } from "common/utility/modelUtils";
import { editService, GraphQLService } from "../api/editService";
import { scaffoldEditAction } from "common/scaffolding/actions/scaffoldEditAction";

export const edit = (location: string, serviceId: string) =>
    scaffoldEditAction(
        serviceId,
        (state) => state.services.edit,
        createAction,
        async () => {
            if (isNew(serviceId)) {
                return newEditableService();
            }

            const service = await editService(location, serviceId);
            return mapEditableService(service);
        }
    );

const mapEditableService = (input: GraphQLService): EditableService => ({
    id: input.id,
    internalName: input.internalName,
    displayName: input.displayName,
    times: {
        startTime: input.startTime,
        endTime: input.endTime,
    },
    dates: input.dates || [],
    categories: input.categories.map((m) => m.id),
    menus: input.menus.map((m) => m.id),
    courses: input.courses.map((m) => m.id),
    setMenus: input.setMenus.map((sm) => sm.id),
    defaultCourse: input.defaultCourse ? input.defaultCourse.id : "",
    dropDownListOptions: input.dropDownListOptions,
    daysOfWeek: getEditableDaysOfWeek(input.daysOfWeek),
    surcharge: input.surcharge ? input.surcharge * 100 : input.surcharge,
    surchargeSku: input.surchargeSku,
    available: input.available === null ? true : input.available,
    posId: input.posId,
    sections: input.sections.map((s) => s.id),
    menuPriority: input.menuPriority || "food",
    featured: {
        title: input.featured.title || undefined,
        item: input.featured.items[0],
    },
    priceList: input.priceList ? input.priceList.id : null,
    membershipPriceLists: input.membershipPriceLists.reduce((map, mpl) => {
        map[mpl.membershipLevel.id] = mpl.priceList.id;
        return map;
    }, {} as MembershipPriceListMap),
    applyAlcoholicDrinksRestriction: input.applyAlcoholicDrinksRestriction,
});

const allDaysOfWeek = [
    DaysOfWeek.MONDAY,
    DaysOfWeek.TUESDAY,
    DaysOfWeek.WEDNESDAY,
    DaysOfWeek.THURSDAY,
    DaysOfWeek.FRIDAY,
    DaysOfWeek.SATURDAY,
    DaysOfWeek.SUNDAY,
];

const getEditableDaysOfWeek = (value: DaysOfWeek): DaysOfWeek[] => allDaysOfWeek.filter((d) => (value & d) !== 0);

const newEditableService = (): EditableService => ({
    internalName: "",
    displayName: "",
    dates: [],
    times: {
        startTime: 930,
        endTime: 1730,
    },
    categories: [],
    menus: [],
    courses: [],
    setMenus: [],
    defaultCourse: "",
    dropDownListOptions: [],
    daysOfWeek: [],
    surcharge: 0,
    surchargeSku: "",
    available: true,
    posId: null,
    sections: [],
    menuPriority: "food",
    featured: {},
    priceList: null,
    membershipPriceLists: {},
    applyAlcoholicDrinksRestriction: false,
});
