import { PropsWithChildren } from "react";
import styles from "./EmptyMessage.module.scss";
import { EmptySvg } from "./EmptySvg";

export type Size = "standard" | "large";
interface Props {
    heading?: string;
    message?: string;
    size?: Size;
}

export const EmptyMessage = ({
    children,
    message = "Oops, there’s nothing here!",
    heading = "",
    size = "large",
}: PropsWithChildren<Props>) => {
    return (
        <div className={styles.container}>
            <div className={styles[`icon--${size}`]}>
                <EmptySvg />
            </div>
            {heading && <h2 className={styles.heading}>{heading}</h2>}
            {message && <p className={styles.message}>{message}</p>}
            {children}
        </div>
    );
};
