import { ReactElement, useContext, useEffect, useMemo } from "react";
import { useFormikContext } from "formik";
import { EditableLocationSettings } from "..";
import { Select } from "antd";
import { LocationLocaleContext } from "features/location";
const { Option } = Select;

interface Props {
    disabled: boolean;
}

const ageOptions = [16, 17, 18, 19, 20, 21].map((age) => {
    return { displayName: `${age}`, value: age };
});

const defaultAgeRestrictions = {
    "en-au": 18,
    "en-nz": 18,
    "en-gb": 18,
    "en-us": 21,
};

export const SelectAgeRestriction = ({ disabled }: Props): ReactElement => {
    const { locale } = useContext(LocationLocaleContext);

    const defaultAgeRestriction = useMemo(() => defaultAgeRestrictions[locale.toLowerCase()] || 18, [locale]);

    const { values, setFieldValue } = useFormikContext<EditableLocationSettings>();

    useEffect(() => {
        // TODO rename confirmEighteenPlus to be generic e.g. confirmAge
        if (values.confirmEighteenPlus && !values.ageRestriction) {
            setFieldValue("ageRestriction", defaultAgeRestriction);
        }
    }, [defaultAgeRestriction, setFieldValue, values.confirmEighteenPlus, values.ageRestriction]);

    return (
        <Select
            value={values.ageRestriction}
            disabled={disabled}
            onChange={(value) => setFieldValue("ageRestriction", value)}
        >
            {ageOptions.map(({ displayName, value }) => (
                <Option key={value} value={value}>
                    {displayName}
                </Option>
            ))}
        </Select>
    );
};
