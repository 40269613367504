import { createPromotionCodesFileEndpoint } from "../api/importPromotion";

export const importPromotionFile = async (file: File, locationId: string) => {
    const endpoint = await createPromotionCodesFileEndpoint(file, locationId);

    const { uploadUrl, uploadMethod } = endpoint;

    const uploadResult = await fetch(uploadUrl, {
        method: uploadMethod,
        body: file,
        headers: {
            // https://feedback.azure.com/forums/217298-storage/suggestions/11387868-add-x-ms-request-headers-in-shared-access-signatu
            "x-ms-blob-type": "BlockBlob",
        },
    });
    if (!uploadResult?.ok) {
        return;
    }
    return file.name;
};
