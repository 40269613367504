import { AppState } from "features";
import { createSelector } from "reselect";

export const getLocationsListState = ({ locations: { list } }: AppState) => {
    return list;
};

export const getLocationsList = createSelector(getLocationsListState, (list) => {
    return list.status === "loaded" ? list.data : [];
});
