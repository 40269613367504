import { Diamonds } from "common/icons";
import { Icon } from "core/components/icon/Icon";
import styles from "./Nested.module.scss";

export const Nested = () => (
    <Icon size="extraLarge" className={styles.iconNested}>
        <Diamonds />
    </Icon>
);

export const Unnested = () => (
    <Icon size="extraLarge" className={styles.icon}>
        <Diamonds />
    </Icon>
);
