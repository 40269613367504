import { withSvg } from "core/components/svg/Svg";

export const UploadSvg = withSvg(
    16,
    16
)(() => (
    <>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.88986 6.97088C6.04404 7.19998 5.98331 7.51068 5.75422 7.66486C5.04323 8.14335 4.50472 8.83754 4.21802 9.64517C3.93132 10.4528 3.91159 11.3311 4.16172 12.1508C4.41186 12.9705 4.91865 13.6882 5.60742 14.1981C6.2962 14.7081 7.13055 14.9833 7.98755 14.9833C8.84455 14.9833 9.6789 14.7081 10.3677 14.1981C11.0564 13.6882 11.5632 12.9705 11.8134 12.1508C12.0635 11.3311 12.0438 10.4528 11.7571 9.64517C11.4704 8.83754 10.9319 8.14335 10.2209 7.66486C9.99179 7.51068 9.93106 7.19998 10.0852 6.97088C10.2394 6.74179 10.5501 6.68106 10.7792 6.83524C11.6679 7.43336 12.3411 8.3011 12.6995 9.31063C13.0578 10.3202 13.0825 11.4181 12.7698 12.4427C12.4572 13.4673 11.8237 14.3644 10.9627 15.0018C10.1017 15.6393 9.0588 15.9833 7.98755 15.9833C6.9163 15.9833 5.87336 15.6393 5.01239 15.0018C4.15142 14.3644 3.51794 13.4673 3.20527 12.4427C2.8926 11.4181 2.91726 10.3202 3.27564 9.31063C3.63402 8.3011 4.30715 7.43336 5.19588 6.83524C5.42497 6.68106 5.73568 6.74179 5.88986 6.97088Z"
            fill="#4D5358"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.50001 2.11497V10C7.50001 10.2761 7.72387 10.5 8.00001 10.5C8.27615 10.5 8.50001 10.2761 8.50001 10V2.11497L10.3683 4.32297C10.5467 4.53377 10.8622 4.56007 11.073 4.38169C11.2838 4.20332 11.3101 3.88783 11.1317 3.67703L8.38397 0.42971C8.37712 0.421508 8.37002 0.413528 8.36266 0.405783C8.35454 0.397226 8.34614 0.388985 8.33749 0.381071C8.24855 0.299667 8.13008 0.25 8.00001 0.25C7.86994 0.25 7.75147 0.299667 7.66253 0.381071C7.65388 0.388985 7.64548 0.397226 7.63736 0.405783C7.63001 0.413528 7.6229 0.421508 7.61605 0.42971L4.86832 3.67703C4.68995 3.88783 4.71624 4.20332 4.92704 4.38169C5.13784 4.56007 5.45333 4.53377 5.6317 4.32297L7.50001 2.11497Z"
            fill="#4D5358"
        />
    </>
));
