import { ActionMeta, PropsValue } from "react-select";
import { AppState } from "features";
import { CardWidth, Confirm, Modal } from "core/components/modal";
import { findOptions, Option, Select } from "core/components/form/select";
import { getLinkableLocationOptions } from "../selectors/getLinkableLocationOptions";
import { getLocationsList } from "features/location/selectors/getLocationsList";
import { LocationRelations } from "../types";
import { Row } from "core/components/card";
import { sortSelectedIdsByDisplayName } from "../selectors/getSortedSelections";
import { useCallback, useState } from "react";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { useMedia } from "common/hooks";

interface Props {
    onClose: () => void;
    name: string;
    visible: boolean;
}

export const LinkChildModal = ({ name, onClose, visible }: Props) => {
    const {
        values: { childLocationIds },
        setFieldValue,
    } = useFormikContext<LocationRelations>();

    const locations = useSelector(getLocationsList);

    const locationOptions = useSelector<AppState>((state) =>
        getLinkableLocationOptions(state, childLocationIds || [])
    ) as Option[];

    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    const selectedValue = findOptions(selectedValues, locationOptions);

    const [selectMenuOpen, setSelectMenuOpen] = useState(false);

    const isMedium = useMedia("(min-width: 672px)");

    const handleChange = useCallback(
        (value: PropsValue<Option>, meta: ActionMeta<Option>) => {
            if (Array.isArray(value)) {
                const selectedIds = value.map(({ value }) => value);

                setSelectedValues(sortSelectedIdsByDisplayName(selectedIds, locations));
            }
        },
        [locations]
    );

    const handleConfirm = useCallback(() => {
        const newValues = [...childLocationIds, ...selectedValues];

        setFieldValue(name, sortSelectedIdsByDisplayName(newValues, locations));
    }, [childLocationIds, locations, name, selectedValues, setFieldValue]);

    return (
        <Modal
            footer={<Confirm confirmLabel="Ok" onConfirm={handleConfirm} />}
            onClose={onClose}
            onOpen={() => setSelectedValues([])}
            title="Link venues"
            visible={visible}
            width={CardWidth.NARROW}
            initialFocus="#childLocationsSelect input"
            escapeCloses={false}
        >
            <Row>
                <Select
                    blurInputOnSelect={false}
                    closeMenuOnSelect={false}
                    isMulti
                    label="Search venues"
                    menuPlacement="auto"
                    menuPosition={isMedium ? "fixed" : "absolute"}
                    onChange={handleChange}
                    onMenuOpen={() => setSelectMenuOpen(true)}
                    onMenuClose={() => setSelectMenuOpen(false)}
                    options={locationOptions}
                    placeholder="Search venues"
                    search
                    value={selectedValue}
                    id="childLocationsSelect"
                    maxMenuHeight={isMedium ? undefined : 200}
                />
            </Row>
            {selectMenuOpen && <div className="hide-on-medium" style={{ height: 208 }} />}
        </Modal>
    );
};
