import { scaffoldList } from "../../../common/scaffolding/reducers/scaffoldList";
import { Group } from "..";

enum TypeKeys {
    LOADING = "GROUP/LIST_BEGIN",
    LOADED = "GROUP/LIST_SUCCESS",
    FAILED = "GROUP/LIST_FAILURE",
    RELOAD = "GROUP/LIST_RELOAD",
}

enum EditTypeKeys {
    FETCH_BEGIN = "GROUP/FETCH_EDITABLE_BEGIN",
    FETCH_SUCCESS = "GROUP/FETCH_EDITABLE_SUCCESS",
    FETCH_FAILURE = "GROUP/FETCH_EDITABLE_FAILURE",

    SAVE_BEGIN = "GROUP/SAVE_EDITABLE_BEGIN",
    SAVE_SUCCESS = "GROUP/SAVE__EDITABLE_SUCCESS",
    SAVE_FAILURE = "GROUP/SAVE_EDITABLE_FAILURE",
}

const list = scaffoldList<Group, typeof TypeKeys, typeof EditTypeKeys>(TypeKeys, EditTypeKeys);
const { createAction, reducer } = list;

export type State = ReturnType<typeof list.reducer>;
export { createAction, reducer };
