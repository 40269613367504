import styles from "./FileUpload.module.scss";
import classNames from "classnames";
import { Accept, FileRejection, useDropzone } from "react-dropzone";
import { ArrowUp, FileSvg, WarningSolid } from "common/icons";
import { Icon } from "../icon";
import { Button } from "../button";
import { Spinner } from "../spinner";
import { FieldInputProps, FormikProps } from "formik";
import { useState, MouseEvent } from "react";
import { Row, Title } from "../card";

export interface FileUploadStateInfo {
    maxFiles: number;
    maxFileSizeMegabytes: number;
    acceptedFileTypes: Accept;
    isDragActive: boolean;
    loading: boolean | undefined;
}
interface Props {
    acceptedFileTypes: Accept;
    loading?: boolean;
    field?: FieldInputProps<any>;
    form?: FormikProps<any>;
    filesAcceptedHandler: (files: File[]) => void;
    filesRejectedHandler?: (files: FileRejection[]) => void;
    getDragMessage?: (info: FileUploadStateInfo) => string;
    maxFileSizeMegabytes: number;
    maxFiles?: number;
    failedMessage?: string;
    showFile?: boolean;
    resetFileInfo?: boolean;
    onResetFile?: () => void;
    downloadFile?: () => void;
}

export const FileUpload = ({
    acceptedFileTypes,
    loading,
    field,
    form,
    filesAcceptedHandler,
    filesRejectedHandler,
    maxFileSizeMegabytes: maxFileSizeMegabytes,
    maxFiles = 1,
    failedMessage,
    showFile,
    resetFileInfo,
    onResetFile,
    downloadFile,
    getDragMessage = defaultGetDragMessage,
}: Props) => {
    const [files, setFiles] = useState<File[]>([]);
    const [internalError, setInternalError] = useState("");
    const { open, getRootProps, getInputProps, isDragActive } = useDropzone({
        maxFiles,
        multiple: !!maxFiles && maxFiles > 1,
        onDrop: async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
            if (field && form) {
                const { name } = field;
                const { setFieldValue, setFieldTouched, values, validateField } = form;
                setFieldTouched(name, true);
                setFieldValue(
                    name,
                    values[name].concat(acceptedFiles).slice(acceptedFiles.length - (maxFiles ?? acceptedFiles.length))
                );
                await validateField(name);
            }
            setFiles(acceptedFiles);
            filesAcceptedHandler(acceptedFiles);
            filesRejectedHandler?.(rejectedFiles);
            const firstErrorMessage = rejectedFiles?.find((r) => {
                return r.errors.find((e) => e.message);
            })?.errors[0];

            switch (firstErrorMessage?.code) {
                case "file-too-large":
                    setInternalError(`File size must be less than ${maxFileSizeMegabytes}MB`);
                    break;
                case "file-invalid-type":
                    setInternalError(
                        `File must be in ${Object.keys(acceptedFileTypes)
                            .map((fileType) => {
                                const visibleType = fileType.split("/")[1];
                                return visibleType ? `.${visibleType}` : fileType;
                            })
                            .join(", ")} format${Object.keys(acceptedFileTypes).length > 1 ? "s" : ""}`
                    );
                    break;
                default:
                    setInternalError(firstErrorMessage?.message ?? "");
            }
        },
        accept: acceptedFileTypes,
        maxSize: 1024 * 1024 * maxFileSizeMegabytes,
    });

    const containerClasses = classNames(styles.container, {
        [styles["dragActive"]]: isDragActive,
    });

    const textClasses = classNames({
        [styles.textActive]: isDragActive,
        [styles.textLoading]: !!loading,
        [styles.text]: true,
    });

    return (
        <div {...getRootProps({ className: "dropzone" })} className={containerClasses}>
            <input {...getInputProps()} />
            {loading ? (
                <div className={styles["spinner"]}>
                    <Spinner size="medium" />
                </div>
            ) : failedMessage || internalError ? (
                <Row className={styles.failedRow}>
                    <div className={styles.failedMessageContainer}>
                        <Icon verticalAlign="middle" size="extraLarge">
                            <WarningSolid />
                        </Icon>
                        <div className={styles.failedMessage}>
                            <Title title="Import failed" className={styles.failedMessageTitle} />
                            {failedMessage || internalError}
                        </div>
                    </div>
                    <Button
                        role="secondary"
                        onClick={(e: MouseEvent<HTMLElement>) => {
                            setFiles([]);
                            onResetFile?.();
                            setInternalError("");
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                    >
                        Try again
                    </Button>
                </Row>
            ) : !!files.length && showFile ? (
                <>
                    {files.map((file, index) => (
                        <Row className={styles.fileRow} key={file.name}>
                            <FileSvg />
                            <div className={styles.fileNameText}>{file.name}</div>
                            {resetFileInfo && (
                                <Button
                                    role="secondary"
                                    onClick={(e: MouseEvent<HTMLElement>) => {
                                        const newFiles = [...files];
                                        newFiles.splice(index, 1);
                                        setFiles(newFiles);
                                        onResetFile?.();
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                >
                                    Change file
                                </Button>
                            )}
                            {downloadFile && (
                                <Button
                                    role="secondary"
                                    className={styles.extraButton}
                                    onClick={(e: MouseEvent<HTMLElement>) => {
                                        downloadFile();
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                >
                                    Download
                                </Button>
                            )}
                        </Row>
                    ))}
                </>
            ) : (
                <>
                    <Icon size="largest" className={styles["icon"]}>
                        <ArrowUp />
                    </Icon>
                    <Button
                        noBackground
                        role="secondary"
                        onClick={open}
                        className={styles["button"]}
                        disabled={isDragActive}
                        type="button"
                    >
                        Upload file
                    </Button>
                    <p className={textClasses}>
                        {getDragMessage({ isDragActive, loading, maxFiles, maxFileSizeMegabytes, acceptedFileTypes })}
                    </p>
                </>
            )}
        </div>
    );
};

function defaultGetDragMessage(info: FileUploadStateInfo) {
    const { isDragActive, loading, maxFiles } = info;

    if (isDragActive) {
        return "Drop file";
    }

    if (loading) {
        return "Loading file...";
    }

    return `or drag ${maxFiles > 1 ? `up to ${maxFiles} files` : ""} to upload`;
}
