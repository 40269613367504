import { trackEvent } from "common/appInsights";
import { getCollectionNameSingular } from "../selectors/getSelectionInfo";
import { CatalogueFilters, CollectionName } from "../types";

export const trackEventFilterCatalogue = (collectionName: CollectionName, filters: CatalogueFilters) => {
    trackEvent(
        { name: `CATALOGUE/${getCollectionNameSingular(collectionName).toUpperCase()}/FILTER_SEARCHED` },
        formatFilterEventData(filters)
    );
};

const formatFilterEventData = (filters: CatalogueFilters) => {
    return {
        ...(filters.categories?.length && { category: filters.categories.map((c) => c.label.toLowerCase()) }),
        ...(filters.sku?.value !== undefined && { has_sku: filters.sku.value }),
        ...(filters.status?.length && { status: filters.status.map((s) => s.label.toLowerCase().replace(/ /g, "_")) }),
        ...(filters.syncStatus?.length && {
            sync_status: filters.syncStatus.map((s) => s.label.toLowerCase().replace(/ /g, "_")),
        }),
    };
};

export const trackEventFilterText = (collectionName: CollectionName, filterText: string, available: boolean) => {
    trackEvent(
        { name: `CATALOGUE/${getCollectionNameSingular(collectionName).toUpperCase()}/KEYWORD_SEARCHED` },
        { keyword: filterText, availability: available }
    );
};
