import * as Yup from "yup";
import { EditableLocationSettings, TipOptions } from "../types";
import { duplicates } from "common/utility/arrayUtils";

export const locationTipsSchema = Yup.object<TipOptions>().shape({
    defaultIndex: Yup.number().test("notMax", "The preselected tip cannot be the highest value.", function (value) {
        const formValues = this.options.context as EditableLocationSettings;
        if (!formValues.allowTips || value === -1) {
            return true;
        }
        const defaults = formValues.defaultTipLevels;
        const custom = formValues.tipOptions.levels || [];
        const combined = defaults.map((level, index) => Number(custom[index] || level));
        const max = Math.max(...combined);
        const myValue = combined[value];
        return Number(myValue) < max;
    }),
    levels: Yup.array(
        Yup.number()
            .nullable(true)
            .transform((n) => (isNaN(Number(n)) ? null : n)) // handle empty string
            .min(0.5, "Value cannot be 0%")
            .max(50, "Value cannot exceed 50%")
            .test("validDecimal", "Decimal amounts can only be .0 or .5", function (value) {
                const formValues = this.options.context as EditableLocationSettings;
                if (!formValues.allowTips || !value) {
                    return true;
                }
                const stringValue = String(value);
                return /^[0-9]*(\.[05]?)?$/g.test(stringValue);
            })
            .test("unique", "Tip amounts must be unique", function (value) {
                const formValues = this.options.context as EditableLocationSettings;
                if (!formValues.allowTips) {
                    return true;
                }
                const defaults = formValues.defaultTipLevels;
                const custom = this.parent;
                const combined = defaults.map((level, index) => Number(custom[index] || level));
                const dupes: number[] = duplicates(combined);
                const isDuplicate = dupes.length && dupes.includes(Number(value));
                return !isDuplicate;
            })
    ),
    showNoTip: Yup.boolean(),
});
