import { FeatureConfiguration } from "features";
import { reducer } from "features/posCatalogue/reducers";
import * as actions from "features/posCatalogue/actions";
import { withPermissions } from "features/location/components/withPermission";
import { PosCataloguePageContainer } from "./containers/PosCataloguePageContainer";
import { ModalCrudRouteContainer, ListComponent } from "common/scaffolding/components/ModalCrudRoute";

export { actions };
export * from "features/posCatalogue/types";

const catalogueList: ListComponent = {
    route: ":tab(products|modifiers)?",
    component: withPermissions(PosCataloguePageContainer, "poscatalogue:read"),
};

export default function (config: FeatureConfiguration) {
    config.reducers.posCatalogue = reducer;

    config.addRoutes(config.routes.locationRoutes, [
        <ModalCrudRouteContainer
            name="catalogue"
            key="pos-catalogue"
            section="pos"
            listComponent={catalogueList}
            listWidth={22}
        />,
    ]);
}
