import { isNew } from "common/utility/modelUtils";
import { createAction } from "../reducers/edit";
import { EditablePackage } from "../types";
import { scaffoldEditAction } from "common/scaffolding/actions/scaffoldEditAction";
import { editPackage } from "../api/editPackage";

export const edit = (locationId: string, packageId: string) =>
    scaffoldEditAction(
        packageId,
        (state) => state.groupTabs.edit,
        createAction,
        async () => {
            if (isNew(packageId)) {
                return newEditablePackage();
            }

            return await editPackage(locationId, packageId);
        }
    );

function newEditablePackage(): EditablePackage {
    return {
        id: "new",
        displayName: "",
        description: "",
        enabled: true,
        itemCount: 0,
        usedCount: 0,
        categories: [],
    };
}
