import { useCurrencyFormatter, useLocalStorage } from "common/hooks";
import { LocationLocaleContext } from "features/location/context";
import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from "react";

interface CatalogueState {
    bulkDeleteOpen: boolean;
    formatCurrency: (x: number) => string;
    modifiersPageIndex: number;
    modifiersPageSize: number;
    productsPageIndex: number;
    productsPageSize: number;
    setBulkDeleteOpen: Dispatch<SetStateAction<boolean>>;
    setModifiersPageIndex: Dispatch<SetStateAction<number>>;
    setProductsPageIndex: Dispatch<SetStateAction<number>>;
    setStoredModifiersPageSize: Dispatch<SetStateAction<number>>;
    setStoredProductsPageSize: Dispatch<SetStateAction<number>>;
}

export const CatalogueContext = createContext<CatalogueState>({
    bulkDeleteOpen: false,
    formatCurrency: () => "",
    modifiersPageIndex: 1,
    productsPageIndex: 1,
    productsPageSize: 25,
    modifiersPageSize: 25,
    setBulkDeleteOpen: () => {},
    setModifiersPageIndex: () => {},
    setProductsPageIndex: () => {},
    setStoredModifiersPageSize: () => {},
    setStoredProductsPageSize: () => {},
});

export const CatalogueProvider = ({ children }: PropsWithChildren<{}>) => {
    const [bulkDeleteOpen, setBulkDeleteOpen] = useState(false);
    const [productsPageIndex, setProductsPageIndex] = useState(1);
    const [modifiersPageIndex, setModifiersPageIndex] = useState(1);
    const [productsPageSize, setStoredProductsPageSize] = useLocalStorage<number>("catalogueProductsPageSize", 25);
    const [modifiersPageSize, setStoredModifiersPageSize] = useLocalStorage<number>("catalogueModifiersPageSize", 25);
    const locationLocale = useContext(LocationLocaleContext);
    const formatCurrency = useCurrencyFormatter(locationLocale).format;

    return (
        <CatalogueContext.Provider
            value={{
                bulkDeleteOpen,
                formatCurrency,
                modifiersPageSize,
                modifiersPageIndex,
                productsPageSize,
                productsPageIndex,
                setBulkDeleteOpen,
                setModifiersPageIndex,
                setProductsPageIndex,
                setStoredModifiersPageSize,
                setStoredProductsPageSize,
            }}
        >
            {children}
        </CatalogueContext.Provider>
    );
};
