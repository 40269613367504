import { createAction } from "../reducers/accessLevelList";
import { scaffoldListAction } from "../../../common/scaffolding/actions/scaffoldListAction";
import { listStaffAccessLevels } from "../api/listStaffAccessLevels";
import { AccessLevelSummary } from "../types/AccessLevelSummary";

export const listAccessLevels = (location: string) =>
    scaffoldListAction<AccessLevelSummary>(
        (state) => state.waiters.accessList,
        createAction,
        async () => {
            return await listStaffAccessLevels(location);
        }
    );
