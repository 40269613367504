import moment from "moment-timezone";
import * as Yup from "yup";
import { EditableSurcharge } from "../types";

const editableSurchargeSchema = {
    id: Yup.string(),
    startTime: Yup.number().required("Start time is required. "),
    endTime: Yup.number().when("startTime", (startTime: number, schema: Yup.NumberSchema) =>
        schema
            .nullable(true)
            .typeError("End time is required. ")
            .required("End time is required. ")
            .test({
                name: "endTime",
                exclusive: true,
                message: "End time cannot exceed 5:00AM the next day. ",
                test: (value) => !(value > 500 && value <= startTime),
            })
    ),
    percentage: Yup.number()
        .nullable(true)
        .typeError("Percentage must be greater than 0%. ")
        .required("Percentage must be greater than 0%. ")
        .moreThan(0, "Percentage must be greater than 0%. ")
        .lessThan(101, "Percentage cannot be higher than 100%. "),
    surchargeId: Yup.string()
        .nullable(true)
        .when(
            ["$surchargeIdName", "$validateSurchargeId"],
            (surchargeIdName: string, validateSurchargeId: boolean, schema: Yup.BooleanSchema) =>
                validateSurchargeId
                    ? schema.required(`${surchargeIdName} is required. `)
                    : schema.notRequired().nullable(true)
        ),
    applyToFood: Yup.boolean(),
    applyToDrink: Yup.boolean().when("applyToFood", {
        is: false,
        then: Yup.boolean().oneOf([true], "Apply surcharge to Food or Drinks. "),
        otherwise: Yup.boolean().notRequired(),
    }),
    markedForDelete: Yup.boolean(),
    rowKey: Yup.string(),
    newRow: Yup.boolean(),
    surchargeType: Yup.number(),
};

export const recurringSurchargeSchema = Yup.object<EditableSurcharge>().shape({
    day: Yup.number(),
    dayLabel: Yup.string(),
    date: Yup.date().nullable(true),
    ...editableSurchargeSchema,
});

export const oneTimeSurchargeSchema = Yup.object<EditableSurcharge>().shape({
    date: Yup.mixed().when(
        ["$locationDateInt", "$locationTime", "startTime", "isLive"],
        (locationDateInt: number, locationTime: number, startTime: number, isLive: boolean, schema: Yup.DateSchema) =>
            schema.required("Date required. ").test({
                name: "date",
                exclusive: true,
                message: "Date must be in future. ",
                test: (value: Date) => {
                    if (!value || isLive) {
                        return true;
                    }

                    const valueDay = parseInt(moment(value).format("YYYYMMDD"));

                    return valueDay > locationDateInt || (valueDay === locationDateInt && startTime > locationTime);
                },
            })
    ),
    day: Yup.number().nullable(true),
    dayLabel: Yup.string().nullable(true),
    ...editableSurchargeSchema,
});
