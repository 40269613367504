import { message } from "antd";
import { opsApi } from "API";
import { GraphQLItemId } from "../../../common/scaffolding/types";

export const saveHyperwalletUser = async (
    locationId: string,
    hyperwalletUser: HyperwalletUserUpdateModel,
    isNew: boolean
) => {
    try {
        if (isNew) {
            const res = await opsApi.graphQLQuery<{ hyperwalletUser: GraphQLItemId }>(createQuery, {
                locationId,
                hyperwalletUser,
            });
            message.success("Hyperwallet user created successfully");
            return res.data.createHyperwalletUser;
        }

        const res = await opsApi.graphQLQuery<{ hyperwalletUser: GraphQLItemId }>(updateQuery, {
            locationId,
            hyperwalletUser,
        });
        message.success("Hyperwallet user updated successfully");
        return res.data.updateHyperwalletUser;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export interface HyperwalletUserUpdateModel {
    businessName: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string;
}

const createQuery = `
    mutation($locationId: ID!, $hyperwalletUser: HyperwalletUserUpdateInput!) {
        createHyperwalletUser(locationId: $locationId, hyperwalletUser: $hyperwalletUser) {
            businessName,
            firstName,
            lastName,
            email,
            phoneNumber
        }
    }
`;

const updateQuery = `
    mutation($locationId: ID!, $hyperwalletUser: HyperwalletUserUpdateInput!) {
        updateHyperwalletUser(locationId: $locationId, hyperwalletUser: $hyperwalletUser) {
            businessName,
            firstName,
            lastName,
            email,
            phoneNumber
        }
    }
`;
