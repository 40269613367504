import { ActionsPopup } from "core/components/actionsPopup/ActionsPopup";
import { Button } from "core/components/button";
import { Card } from "core/components/card";
import { CatalogueRouteParams } from "../types/CatalogueRouteParams";
import { CsvUploadContext } from "../context/CsvUploadContext";
import { getActiveLocation } from "features/location/selectors/getLocationPermissions";
import { getIsPosImportSupported } from "features/location/selectors/getPosMenuSupported";
import { getPosSyncEnabled } from "../selectors/getPosSyncEnabled";
import { Link } from "react-router-dom";
import { syncFromPos as syncFromPosAction } from "../actions";
import { useCallback, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { useMedia } from "common/hooks";
import { usePermissions } from "features/location/hooks";
import { getCatalogueEmpty } from "../selectors/getCatalogueEmpty";
import { exportProductsCSV } from "../actions/exportProductsCSV";
import { exportModifiersCSV } from "../actions/exportModifiersCSV";
import { useFormikContext } from "formik";
import { CatalogueSelections } from "../types";
import { getProductCatalogueItems } from "../selectors/getProductCatalogueItems";
import { getModifierCatalogueItems } from "../selectors/getModifierCatalogueItems";
import { getPosSync } from "../selectors/getPosSync";

interface Props {
    canCreate: boolean;
    canImportCsv: boolean;
    canImportPos: boolean;
    createLink: string;
    posImportLink?: string;
    posSyncEnabled: boolean;
    posImportSupported: boolean;
    onImportCsv?: () => void;
    tab?: string;
    posSyncStatus: string;
    syncFromPos: () => void;
    exportAll: () => void;
    exportSelected: () => void;
    showExportSelected: boolean;
    showExportAll: boolean;
}

const HeaderActionsContainer = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const posImportSupported = useSelector(getIsPosImportSupported) || false;
    const posSync = useSelector(getPosSync);
    const posSyncEnabled = useSelector(getPosSyncEnabled) || false;
    const restaurantLocation = useSelector(getActiveLocation);
    const { setShowUploadModal } = useContext(CsvUploadContext);
    const { region, location: slug, tab } = useParams<CatalogueRouteParams>();
    const createLink = `${location.pathname}/new`;
    const posImportLink = posImportSupported ? `/${region}/${slug}/pos/import/${tab}?from=${tab}` : undefined;
    const { values: selectedItems } = useFormikContext<CatalogueSelections>();
    const products = useSelector(getProductCatalogueItems);
    const modifiers = useSelector(getModifierCatalogueItems);

    const {
        product: { canCreate },
        catalogue: { canImportCsv, canImportPos },
    } = usePermissions();

    const syncFromPos = useCallback(() => {
        restaurantLocation && dispatch(syncFromPosAction(restaurantLocation.id));
    }, [dispatch, restaurantLocation]);

    const handleUploadCsv = useCallback(() => {
        setShowUploadModal(true);
    }, [setShowUploadModal]);

    const showExportSelected = useMemo(() => {
        return tab === "products"
            ? !!Object.keys(selectedItems?.products || {}).length
            : !!Object.keys(selectedItems?.modifiers || {}).length;
    }, [selectedItems, tab]);

    const showExportAll = useMemo(() => {
        return tab === "products" ? !!products.length : !!modifiers.length;
    }, [products, modifiers, tab]);

    const exportSelected = useCallback(() => {
        if (tab === "products") {
            dispatch(exportProductsCSV(selectedItems.products));
        } else {
            dispatch(exportModifiersCSV(selectedItems.modifiers));
        }
    }, [dispatch, tab, selectedItems]);

    const exportAll = useCallback(() => {
        if (tab === "products") {
            dispatch(exportProductsCSV());
        } else {
            dispatch(exportModifiersCSV());
        }
    }, [dispatch, tab]);
    return (
        <HeaderActionsComponent
            canCreate={canCreate && !(posSyncEnabled && tab === "products")}
            createLink={createLink}
            canImportCsv={!!tab && canImportCsv}
            canImportPos={canImportPos && posImportSupported}
            posImportLink={posImportLink}
            posSyncEnabled={posSyncEnabled}
            posImportSupported={true}
            tab={tab}
            onImportCsv={handleUploadCsv}
            posSyncStatus={posSync.status}
            syncFromPos={syncFromPos}
            exportAll={exportAll}
            exportSelected={exportSelected}
            showExportSelected={showExportSelected}
            showExportAll={showExportAll}
        />
    );
};

// @private
export const HeaderActionsComponent = ({
    canCreate,
    createLink,
    canImportCsv,
    canImportPos,
    onImportCsv,
    posImportLink,
    posSyncEnabled,
    posSyncStatus,
    syncFromPos,
    tab,
    exportSelected,
    exportAll,
    showExportSelected,
    showExportAll,
}: Props) => {
    const isLarge = useMedia("(min-width: 896px)");
    const importCsvLabel = `Import ${tab === "products" ? "Products" : "Modifiers"} CSV`;
    const exportAllLabel = `Export all ${tab === "products" ? "Products" : "Modifiers"}`;
    const exportSelectedLabel = `Export selected ${tab === "products" ? "Products" : "Modifiers"}`;
    const createLabel = `Create ${tab === "products" ? "Product" : "Modifier"}`;
    const emptyCollection = useSelector(getCatalogueEmpty);

    if (isLarge) {
        const needsPopup =
            canImportPos || canImportCsv || (canCreate && posSyncEnabled) || showExportAll || showExportSelected;
        return (
            <>
                {needsPopup && (
                    <ActionsPopup>
                        <Card cardStyle="popup">
                            {canImportPos && (
                                <Button as={Link} to={posImportLink} shape="listItem">
                                    Import from POS
                                </Button>
                            )}
                            {canImportCsv && (
                                <Button shape="listItem" type="button" onClick={onImportCsv}>
                                    {importCsvLabel}
                                </Button>
                            )}
                            {canCreate && posSyncEnabled && (
                                <Button as={Link} to={createLink} shape="listItem">
                                    {createLabel}
                                </Button>
                            )}
                            {showExportAll && (
                                <Button shape="listItem" type="button" onClick={exportAll}>
                                    {exportAllLabel}
                                </Button>
                            )}
                            {showExportSelected && (
                                <Button shape="listItem" type="button" onClick={exportSelected}>
                                    {exportSelectedLabel}
                                </Button>
                            )}
                        </Card>
                    </ActionsPopup>
                )}
                {canCreate && !posSyncEnabled && (
                    <Button as={Link} to={createLink} nowrap role={posSyncEnabled ? "secondary" : "primary"}>
                        {createLabel}
                    </Button>
                )}
                {posSyncEnabled && (
                    <Button
                        nowrap
                        role="primary"
                        onClick={syncFromPos}
                        disabled={emptyCollection}
                        loading={posSyncStatus === "loading"}
                    >
                        Sync from POS
                    </Button>
                )}
            </>
        );
    }

    return (
        <ActionsPopup>
            <Card cardStyle="popup">
                {posSyncEnabled && (
                    <Button
                        nowrap
                        role="primary"
                        onClick={syncFromPos}
                        loading={posSyncStatus === "loading"}
                        shape="listItem"
                        type="button"
                        disabled={emptyCollection}
                    >
                        Sync from POS
                    </Button>
                )}
                {canCreate && (
                    <Button
                        as={Link}
                        to={createLink}
                        shape="listItem"
                        type="button"
                        style={posSyncEnabled ? { order: 1 } : null}
                    >
                        {createLabel}
                    </Button>
                )}
                {canImportPos && (
                    <Button as={Link} to={posImportLink} shape="listItem" type="button">
                        Import from POS
                    </Button>
                )}
                {canImportCsv && (
                    <Button shape="listItem" type="button" onClick={onImportCsv}>
                        {importCsvLabel}
                    </Button>
                )}
                {showExportAll && (
                    <Button shape="listItem" type="button" onClick={exportAll} style={{ order: 2 }}>
                        {exportAllLabel}
                    </Button>
                )}
                {showExportSelected && (
                    <Button shape="listItem" type="button" onClick={exportSelected} style={{ order: 2 }}>
                        {exportSelectedLabel}
                    </Button>
                )}
            </Card>
        </ActionsPopup>
    );
};

export const HeaderActions = HeaderActionsContainer;
