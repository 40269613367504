import { createSelector } from "reselect";
import { CatalogueItemSelections, PageSelectionInfo, PageSelectionState, CatalogueItem } from "../types";

const getPageItems = (state: PageSelectionState) => {
    return state.pageItems;
};

const getSelections = (state: PageSelectionState) => {
    return state.selections;
};

const getItemCount = (item: CatalogueItem) => {
    if (item.children?.length) {
        return item.children.length;
    }

    return 1;
};

const getSelectionCount = (pageItem: CatalogueItem, selections: CatalogueItemSelections) => {
    if (!pageItem.id) {
        console.warn("getSelectionCount: pageItem.id is undefined");
        return 0;
    }
    const value = selections[pageItem.id];

    if (value === true) {
        return 1;
    }

    if (Array.isArray(value)) {
        return pageItem.children?.filter((child) => value?.includes(child.id!)).length || 0;
    }

    return 0;
};

export const getPageSelectionInfo = createSelector(
    getPageItems,
    getSelections,
    (pageItems, selections): PageSelectionInfo => {
        const numSelected = pageItems.reduce<number>((prev, curr) => {
            return prev + getSelectionCount(curr, selections);
        }, 0);

        const numItems: number = pageItems.reduce<number>((prev, curr) => {
            return prev + getItemCount(curr);
        }, 0);

        return {
            numItems,
            numSelected,
        };
    }
);

export const getAllPageItemsSelected = createSelector(getPageItems, (pageItems) => {
    return pageItems.reduce<CatalogueItemSelections>((prev, curr) => {
        if (!curr.id) {
            return prev;
        }
        prev[curr.id] = curr.children?.length ? curr.children.map(({ id }) => id!) : true;

        return prev;
    }, {});
});

export const getSelectionsWithoutPageItems = createSelector(getPageItems, getSelections, (pageItems, selections) => {
    return pageItems.reduce<CatalogueItemSelections>(
        (prev, curr) => {
            if (curr.id) {
                delete prev[curr.id];
            }

            return prev;
        },
        { ...selections }
    );
});
