import reactDayPickerClasses from "./ReactDayPicker.module.scss";
import styles from "./DatePickerInputRange.module.scss";
import { DayPickerProps, ModifiersUtils } from "react-day-picker";

import { Field, useField } from "formik";
import { useMemo, useRef } from "react";
import moment from "moment";
import { DatePickerInput, SelectionSource, Props as InputRangeProps } from "./DatePickerInput";

type InputProps = Omit<InputRangeProps, "locale" | "dayPickerProps">;
interface Props {
    fromFieldName?: string;
    toFieldName?: string;
    storageFormat?: string;
    locale: string;
    today?: Date;
    fromDayPickerProps?: DayPickerProps;
    toDayPickerProps?: DayPickerProps;
    fromInputProps?: InputProps;
    toInputProps?: InputProps;
}

const getParser = (storageFormat: string) => (value: string) => {
    return value ? moment(value, storageFormat).toDate() : undefined;
};

export const DatePickerInputRange = ({
    fromFieldName = "from",
    toFieldName = "to",
    storageFormat = "YYYY-MM-DD",
    locale,
    fromDayPickerProps,
    today,
    toDayPickerProps,
    fromInputProps,
    toInputProps,
}: Props) => {
    const fromInputRef = useRef<HTMLInputElement | null>(null);
    const toInputRef = useRef<HTMLInputElement | null>(null);

    const [, fromMeta] = useField(fromFieldName);
    const { value: fromValue } = fromMeta;

    const [, toMeta] = useField(toFieldName);
    const { value: toValue } = toMeta;

    const parse = useMemo(() => getParser(storageFormat), [storageFormat]);
    const from: Date | undefined = useMemo(() => parse(fromValue), [fromValue, parse]);
    const to: Date | undefined = useMemo(() => parse(toValue), [toValue, parse]);

    const modifiers = useMemo(() => {
        const mods = {};
        if (from) {
            mods[reactDayPickerClasses.start] = from;
            if (to) {
                mods[reactDayPickerClasses.end] = to;
                mods[reactDayPickerClasses.rangeSelected] = (day: Date) =>
                    day.getTime() >= from.getTime() && day.getTime() <= to.getTime();
            }
        }

        return mods;
    }, [from, to]);

    return (
        <div className={styles.container}>
            <Field
                dayPickerProps={{
                    ...fromDayPickerProps,
                    modifiers: {
                        ...fromDayPickerProps?.modifiers,
                        ...modifiers,
                    },
                }}
                name={fromFieldName}
                component={DatePickerInput}
                onSelect={(day: Date, source: SelectionSource) => {
                    const invalidDay = day && ModifiersUtils.dayMatchesModifier(day, fromDayPickerProps?.disabledDays);

                    if (day && !invalidDay && !to && source === "input") {
                        // jump to the `to` field (focus trap prevents us doing this synchronously)
                        setTimeout(() => {
                            toInputRef.current?.focus();
                        }, 0);
                    }
                }}
                innerRef={fromInputRef}
                locale={locale}
                today={today}
                {...fromInputProps}
            />
            <Field
                dayPickerProps={{
                    ...toDayPickerProps,
                    modifiers: {
                        ...toDayPickerProps?.modifiers,
                        ...modifiers,
                    },
                }}
                name={toFieldName}
                component={DatePickerInput}
                innerRef={toInputRef}
                locale={locale}
                today={today}
                pickerPlacement="bottom-end"
                initialMonth={to || from || undefined}
                {...toInputProps}
            />
        </div>
    );
};
