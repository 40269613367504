import { findPosFieldSchema, findPosField, getPosFieldSchema } from "components/forms/posField/getPosFieldSchema";
import { ActiveLocation } from "features/location";
import * as Yup from "yup";
import { EditableSection, SectionDemoQr } from "../types";

export const editableTableSectionSchema = Yup.object<EditableSection>().shape({
    displayName: Yup.string().required("Please enter a name"),
    services: Yup.array().of(Yup.string()),
    posId: findPosFieldSchema("OrderCreate", "sectionPosId"),
    posMenuId: findPosFieldSchema("PosMenu", "posMenuId"),
    sectionType: Yup.mixed().oneOf(["outdoor", "indoor", null]).required("Section type required"),
    enableTips: Yup.boolean(),
    locationTipsEnabled: Yup.boolean(),
    applyTrayCharge: Yup.boolean(),
    applySurcharges: Yup.boolean(),
    // We can't use findPosFieldSchema because you can't nest Yup.when calls and we need to check isEnabled
    demoTableNumber: Yup.string().when(
        ["demoQr.isEnabled", "$restaurantLocation"],
        (isEnabled: boolean, restaurantLocation: ActiveLocation, schema: Yup.StringSchema) => {
            if (!isEnabled) {
                return schema.nullable(true);
            }

            const posField = findPosField(restaurantLocation, "OrderCreate", "tableNumber");

            if (!posField) {
                return schema.required("Table number is required");
            }

            return getPosFieldSchema(posField);
        }
    ),
    demoQr: Yup.object<SectionDemoQr>().shape({
        isEnabled: Yup.boolean(),
        url: Yup.string(),
        dateUpdated: Yup.number(),
        timeToLiveSeconds: Yup.number().nullable(true),
        selectedTimeToLive: Yup.number().when(["isEnabled", "timeToLiveSeconds", "dateUpdated"], {
            is: (isEnabled: boolean, timeToLiveSeconds?: number, dateUpdated?: number) =>
                isEnabled && !timeToLiveSeconds && !dateUpdated,
            then: Yup.number().nullable(true).required("Expiry is required"),
            otherwise: Yup.number().nullable(true),
        }),
    }),
});
