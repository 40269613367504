import { opsApi } from "API";

export async function archiveSection(locationId: string, sectionId: string): Promise<void> {
    await opsApi.graphQLQuery(query, { locationId, sectionId });
}

const query = `
    mutation($locationId: ID!, $sectionId: ID!) {
        deleteSection(locationId: $locationId, id: $sectionId) {
            id
        }
    }
`;
