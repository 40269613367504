import {
    fromPercentageWithPrecision,
    numberOrUndefined,
    numberOrZero,
    toPercentageWithPrecision,
} from "common/utility/numberUtils";
import { GraphQLPromotion } from ".";
import { EditablePromotion } from "./EditablePromotion";
import { Promotion, PromotionDiscountType } from ".";
import { PromotionDiscount, PromotionType } from "./Promotion";
import { ALL_DAYS, DaysOfWeek, DAYS_OF_WEEK } from "common/utility/dateUtils";
import { ImportedPromotion } from "./ImportedPromotion";
import { isNotPastDate } from "../schema/EditablePromotionSchema";
import { Moment } from "moment";

export const mapGraphQLPromotionToPromotion = ({
    code,
    generatedCodeCount,
    promotionType,
    discount,
    priceListId,
    displayName,
    id,
    internalName,
    posId,
    restrictions: { minSpend, maxMemberUsage, maxCodeUsage, requiredItems, schedule },
    usageCount,
    dateUpdated,
    status,
}: GraphQLPromotion): Promotion => ({
    code,
    generatedCodeCount,
    promotionType,
    displayName,
    id,
    internalName: internalName ?? "",
    posId: posId ?? "",
    usageCount,
    status: status,
    priceListId: priceListId ?? "",
    dateUpdated,
    discount: discount
        ? {
              type: discount.type,
              value: parseDiscountValue(discount),
          }
        : {
              type: PromotionDiscountType.PRICELIST,
          },
    restrictions: {
        minSpend: minSpend ? minSpend.toFixed(2) : "",
        maxMemberUsage: maxMemberUsage ? maxMemberUsage.toFixed(0) : 0,
        maxCodeUsage: maxCodeUsage ? maxCodeUsage.toFixed(0) : 0,
        requiredItems: requiredItems || null,
        schedule: {
            startDate: schedule?.startDate || null,
            endDate: schedule?.endDate || null,
        },
    },
});

export const mapGraphQLPromotionToEditablePromotion = (graphQLPromotion: GraphQLPromotion): EditablePromotion => {
    const { generatedCodeCount, ...editablePromotion } = mapGraphQLPromotionToPromotion(graphQLPromotion);

    const schedule = graphQLPromotion.restrictions.schedule || {};

    const { startDate, endDate, activeDaysOfWeek, dailyStartTime, dailyEndTime, specificInactiveDates } =
        schedule || {};

    return {
        ...editablePromotion,
        generatedCodeCount: generatedCodeCount?.toString(),
        memberUsage: editablePromotion.restrictions.maxMemberUsage > 0 ? "limited" : "unlimited",
        codeUsage: editablePromotion.restrictions.maxCodeUsage > 0 ? "limited" : "unlimited",
        applyToAll: !editablePromotion.restrictions.requiredItems,
        hasUniqueCode: true,
        restrictions: {
            ...editablePromotion.restrictions,
            schedule: {
                startDate: startDate || "",
                endDate: endDate || null,
                activeDaysOfWeek: DAYS_OF_WEEK.map((value) => (activeDaysOfWeek || ALL_DAYS) & value),
                dailyStartTime: dailyStartTime ?? 0,
                dailyEndTime: dailyEndTime && dailyEndTime !== 2400 ? dailyEndTime : 0,
                specificInactiveDates: specificInactiveDates || [],
            },
        },
    };
};

export const mapEditablePromotionToGraphQLPromotion = ({
    applyToAll, // UI only
    code,
    generatedCodeCount,
    promotionType,
    promotionFileName,
    discount,
    priceListId,
    displayName,
    id,
    internalName,
    posId,
    restrictions: { minSpend, maxMemberUsage, maxCodeUsage, requiredItems, schedule },
    memberUsage, // UI only
    codeUsage, // UI only
    usageCount,
}: EditablePromotion): GraphQLPromotion => ({
    code,
    generatedCodeCount: numberOrUndefined(generatedCodeCount?.replace(/,/g, "")),
    promotionType,
    promotionFileName,
    displayName,
    id,
    internalName,
    posId,
    usageCount,
    priceListId: priceListId ? priceListId : undefined,
    discount:
        discount.type !== PromotionDiscountType.PRICELIST
            ? {
                  type: discount.type,
                  value:
                      discount.type === PromotionDiscountType.PERCENTAGE
                          ? fromPercentageWithPrecision(discount.value, 2)
                          : numberOrZero(discount.value),
              }
            : undefined,
    restrictions: {
        minSpend: numberOrZero(minSpend),
        maxMemberUsage: memberUsage === "limited" ? Number(maxMemberUsage) || null : null,
        maxCodeUsage: codeUsage === "limited" ? Number(maxCodeUsage) || null : null,
        requiredItems: applyToAll ? null : requiredItems!,
        schedule: {
            ...schedule,
            endDate: schedule.endDate || null,
            activeDaysOfWeek: schedule.activeDaysOfWeek.reduce((total, n) => total | n, 0),
            dailyEndTime: schedule.dailyEndTime === 0 ? 2400 : schedule.dailyEndTime,
        },
    },
});

export function mapImportedPromotionToEditablePromotion(
    imported: ImportedPromotion,
    venueTime: Moment,
    venueTimeZone: string,
    isMultiCodes: boolean
): EditablePromotion {
    const promotionType = imported.promotionType
        ? PromotionType[imported.promotionType.toUpperCase()]
        : isMultiCodes
        ? PromotionType.MULTIPLECODE
        : PromotionType.SINGLECODE;
    const minSpend = parseFloat(imported.minSpend ?? "0");
    const maxCodeUsage = imported.maxCodeUsage
        ? parseInt(imported.maxCodeUsage)
        : promotionType === PromotionType.MULTIPLECODE
        ? 1
        : null;
    const maxMemberUsage = imported.maxMemberUsage
        ? parseInt(imported.maxMemberUsage)
        : promotionType === PromotionType.MULTIPLECODE
        ? null
        : 1;

    const startDate =
        imported.startDate && isNotPastDate(imported.startDate, venueTime, venueTimeZone)
            ? imported.startDate
            : new Date(venueTime.format("YYYY/MM/DD")).toUTCString();

    let activeDaysOfWeek = DAYS_OF_WEEK;
    if (imported.activeDaysOfWeek && Array.isArray(imported.activeDaysOfWeek)) {
        activeDaysOfWeek = imported.activeDaysOfWeek.map((day) => DaysOfWeek[day.toUpperCase()]);
    } else if (imported.activeDaysOfWeek && imported.activeDaysOfWeek !== "All") {
        activeDaysOfWeek = [DaysOfWeek[imported.activeDaysOfWeek.toUpperCase()]];
    }

    return {
        id: "new",
        code: imported.code,
        generatedCodeCount: imported.generatedCodeCount,
        promotionType,
        displayName: imported.name ?? "",
        internalName: imported.internalname ?? "",
        posId: imported.posId ?? "",
        discount: {
            type: imported.priceListId
                ? PromotionDiscountType.PRICELIST
                : imported.type
                ? PromotionDiscountType[imported.type.toUpperCase()]
                : PromotionDiscountType.PERCENTAGE,
            value:
                imported.discount && imported.type
                    ? parseDiscountValue({
                          value: parseFloat(imported.discount),
                          type: PromotionDiscountType[imported.type.toUpperCase()],
                      })
                    : "",
        },
        priceListId: "",
        applyToAll: imported.applyToAll !== "N",
        hasUniqueCode: false,
        restrictions: {
            minSpend: minSpend ? minSpend.toFixed(2) : "",
            maxMemberUsage: maxMemberUsage ?? "1",
            maxCodeUsage: maxCodeUsage ?? "1",
            requiredItems: null,
            schedule: {
                startDate: startDate,
                endDate: imported.endDate || null,
                activeDaysOfWeek,
                dailyStartTime: parseInt(imported.dailyStartTime ?? "0"),
                dailyEndTime: parseInt(imported.dailyEndTime ?? "2400"),
                specificInactiveDates: [],
            },
        },
        usageCount: 0,
        memberUsage: maxMemberUsage
            ? "limited"
            : promotionType === PromotionType.MULTIPLECODE
            ? "unlimited"
            : "limited",
        codeUsage: maxCodeUsage ? "limited" : promotionType === PromotionType.MULTIPLECODE ? "limited" : "unlimited",
    };
}

const parseDiscountValue = ({ type, value }: PromotionDiscount) => {
    if (value === "" || undefined) {
        return "";
    }

    if (type === PromotionDiscountType.PERCENTAGE) {
        return String(toPercentageWithPrecision(value, 2));
    }

    return numberOrZero(value).toFixed(2);
};
