import "./PosConfigurationPage.scss";
import * as Yup from "yup";
import { PosFieldDefinition, PosMetadata } from "features/location";
import { CheckCircleFilled } from "@ant-design/icons";
import { useState } from "react";
import { Formik, FastField, FormikHelpers as FormikActions } from "formik";
import { FieldErrors } from "components/forms";
import { Form, Divider, Modal, message } from "antd";
import { PageTitle } from "common/scaffolding/components/DataListingPage/PageTitle";
import { SaveStatus } from "common/loader";
import { EditRow } from "common/scaffolding/components/EditRow";
import { ValidationError } from "common/forms/ValidationError";
import { PosField } from "components/forms/posField/legacy/PosField";
import { CrudPermissions } from "features/location/types/createCrudPermissions";
import { NotificationMessage } from "common/notificationMessage";
import { ActionFooter } from "core/components/actionFooter";
import { Button } from "core/components/button";
import { WarningSolid } from "common/icons";

export interface Props {
    schema: Yup.Schema<{}>;
    fields: PosFieldDefinition[];
    initialValues: {};
    updateTimestamp: number;
    onSubmit: (values: {}) => void;
    onClear: () => void;
    saveStatus: SaveStatus;
    permissions: CrudPermissions;
    supportsClear: boolean;
    supportsOrderCreate: boolean;
    posMetadata: PosMetadata;
}

export const PosConfigurationPage = ({
    initialValues,
    updateTimestamp,
    schema,
    fields,
    onSubmit,
    onClear,
    saveStatus,
    supportsClear,
    supportsOrderCreate,
    permissions,
    posMetadata,
}: Props) => {
    const [submittingMode, setSubmittingMode] = useState("loaded");

    const disableFields = !permissions.canUpdate;

    async function handleSubmit(values: any, actions: FormikActions<{}>) {
        if (submittingMode === "clear") {
            onClear();
            return;
        }

        setSubmittingMode("save");
        try {
            await onSubmit(values);
        } catch (e) {
            if (e instanceof ValidationError) {
                actions.setErrors(e.errors);
                return;
            }

            message.error(NotificationMessage.ERROR);
        }
    }

    function handleClear() {
        setSubmittingMode("clear");

        Modal.confirm({
            title: "Are you sure?",
            content: "Changing POS type will remove all existing POS settings, and will affect any open orders.",
            onOk: () => onClear(),
        });
    }

    return (
        <div className="edit-pos-config">
            <Formik
                validationSchema={schema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                key={updateTimestamp}
            >
                {(form) => (
                    <Form onFinish={form.handleSubmit} className="padding-bottom-60">
                        <PageTitle
                            title="POS Integration Settings"
                            description={formatDisplayName(posMetadata)}
                            additionalInfo={renderConfigurationState(supportsOrderCreate)}
                        />
                        <Divider />
                        {fields.map((field) => (
                            <EditRow title={field.title} subTitle={field.subtitle} key={field.name}>
                                <FieldErrors name={field.name} form={form}>
                                    <FastField
                                        name={field.name}
                                        component={PosField}
                                        definition={field}
                                        disabled={disableFields}
                                    />
                                </FieldErrors>
                            </EditRow>
                        ))}
                        <ActionFooter align="left" forceDirty={false}>
                            <Button
                                loading={submittingMode === "save" && saveStatus === "saving"}
                                type="submit"
                                disabled={!permissions.canUpdate}
                            >
                                {supportsOrderCreate ? "Save" : "Next"}
                            </Button>
                            {supportsClear && (
                                <Button
                                    colorScheme="critical"
                                    role="secondary"
                                    type="button"
                                    loading={submittingMode === "clear" && saveStatus === "saving"}
                                    onClick={handleClear}
                                    disabled={!permissions.canDelete}
                                >
                                    {!(submittingMode === "clear" && saveStatus === "saving") && <WarningSolid />}
                                    Change POS Type
                                </Button>
                            )}
                        </ActionFooter>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

function renderConfigurationState(acceptingOrders: boolean) {
    if (acceptingOrders) {
        return (
            <>
                Accepting orders
                <CheckCircleFilled />
            </>
        );
    }

    return <>Not accepting orders</>;
}

function formatDisplayName(metadata: PosMetadata) {
    let posDisplayName = "Configured POS: ";

    if (!metadata?.posDisplayName) {
        posDisplayName += "none";
        return posDisplayName;
    }

    posDisplayName += metadata.posDisplayName;

    if (metadata.venuePosDisplayName) {
        posDisplayName += ` (${metadata.venuePosDisplayName})`;
    }

    return posDisplayName;
}
