import { alcoholicDrinksRestrictionSchema } from "features/locationSettings/schema/AlcoholicDrinksRestrictionSchema";
import { ValidationOptions } from "features/locationSettings/schema/ValidationOptions";
import * as Yup from "yup";
import { EditableLocationSettings } from "../types";
import { locationTipsSchema } from "./LocationTipsSchema";
import { marketingOptionsSchema } from "./MarketingOptionsSchema";
import { getTrayChargeSchema } from "./TrayChargeSchema";
import { getVenueServiceFeeSchema } from "./VenueServiceFeeSchema";

export const getEditableLocationSettingsSchema = (options: ValidationOptions) =>
    Yup.object<EditableLocationSettings>().shape({
        ageRestriction: Yup.number().nullable(true),
        allowNotesToKitchen: Yup.boolean(),
        id: Yup.string(),
        slug: Yup.string(),
        confirmEighteenPlus: Yup.boolean(),
        isBuzzer: Yup.boolean(),
        orderBatchTimeSeconds: Yup.number()
            .typeError("Valid number required")
            .min(0, "Value cannot be negative")
            .nullable(true),
        drinkOrderBatchTimeSeconds: Yup.number()
            .transform((value) => (isNaN(value) ? null : value))
            .typeError("Valid number required")
            .min(0, "Value cannot be negative")
            .nullable(true),
        foodOrderBatchTimeSeconds: Yup.number()
            .transform((value) => (isNaN(value) ? null : value))
            .typeError("Valid number required")
            .min(0, "Value cannot be negative")
            .nullable(true),
        marketingOptions: marketingOptionsSchema,
        unusedPartyTimeoutMinutes: Yup.number()
            .typeError("Valid number required")
            .min(1, "Value must be greater than 0")
            .max(60, "Value must be less than 60 minutes"),
        allowTips: Yup.boolean(),
        defaultTipLevels: Yup.array(Yup.number()).nullable(true),
        mustAcceptAllergenNotice: Yup.boolean(),
        fromTheVenue: Yup.string()
            .nullable(true)
            .when("enableDietaryNotifier", {
                is: true,
                then: Yup.string()
                    .required("Must enter message when Dietary Notifier enabled")
                    .max(400, "Must be less than 400 characters"),
            }),
        sectionsWithDocketMerging: Yup.array(Yup.string()),
        enableDietaryNotifier: Yup.boolean(),
        idleTimeoutMinutes: Yup.number()
            .typeError("Valid number required")
            .min(1, "Value must be greater than 0")
            .max(45, "Value must be less than 45 minutes"),
        isLiveOrdersEnabled: Yup.boolean(),
        fromTheVenueTitle: Yup.string().max(28, "Must be less than 28 characters").nullable(true),
        enableAnotherRound: Yup.boolean(),
        enableQuickSell: Yup.boolean(),
        quickSellDrinksOnly: Yup.boolean(),
        facebookPixelId: Yup.string().matches(/^\d+$/, "Facebook Pixel ID must be numbers only").nullable(true),
        enableSurcharges: Yup.boolean(),
        enableSurchargesFeature: Yup.boolean(),
        enableGroupTabs: Yup.boolean(),
        enableNoTip: Yup.boolean(),
        tipOptions: locationTipsSchema,
        groupTabGratuityFactor: Yup.number()
            .min(1, "Gratuity must be between 1% and 20%")
            .max(20, "Gratuity must be between 1% and 20%")
            .nullable(true)
            .integer("Value cannot contain decimals"),
        trayCharge: getTrayChargeSchema(options),
        venueServiceFee: getVenueServiceFeeSchema(),
        alcoholicDrinksRestriction: alcoholicDrinksRestrictionSchema,
        enableProductVideos: Yup.boolean(),
        enableAbsorbMerchantFee: Yup.boolean(),
        orderFlow: Yup.mixed().required("Order Flow is required"),
        splitPaymentOptions: Yup.array(),
    });
