export type CsvWriter<T> = (row: T) => void;

export function generateCsvBlob<TOut extends Array<string | null>>(generator: (writer: CsvWriter<TOut>) => void) {
    const tokens: string[] = [];

    const CELL_DELIMITER = ",";
    const ROW_DELIMITER = "\r\n";

    generator((row: TOut) => {
        for (let i = 0; i < row.length; i++) {
            const cell = formatCell(row[i]);

            if (cell) {
                tokens.push(cell);
            }

            if (i === row.length - 1) {
                tokens.push(ROW_DELIMITER);
            } else {
                tokens.push(CELL_DELIMITER);
            }
        }
    });

    return new Blob(tokens);
}

const NEEDS_QUOTING = /^\s|\s$|,|"|\n/;

function formatCell(value: string | null): string | null {
    if (value === null) {
        return null;
    }

    if (NEEDS_QUOTING.test(value)) {
        value = value.replace(/"/g, '""');
        value = `"${value}"`;
    }

    return value.trim();
}
