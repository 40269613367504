import { Col, Divider, Form, Row } from "antd";
import { TreeItem } from "common/types";
import { ConfirmModal } from "common/scaffolding/components/ConfirmModal/ConfirmModal";
import { CrudPermissions } from "features/location/types/createCrudPermissions";
import { EditRow } from "common/scaffolding/components/EditRow";
import { FastField, Field, FieldProps, Formik, validateYupSchema, yupToFormErrors } from "formik";
import { FieldDisplaySettings } from "components/forms/posUI/posSettings";
import { FieldErrors, TextInput } from "components/forms";
import { InputNumber, InputTestConfig, parseValueToFixed } from "core/components/form/InputNumber";
import { RouteComponentProps } from "react-router-dom";
import { ProductTree } from "components/forms/ProductTree/ProductTree";
import { SaveStatus } from "common/loader";
import { SelectItem, SearchInput } from "components/forms/SearchInput";
import { SwitchInput } from "components/forms/SwitchInput";
import { useCallback, useEffect, useMemo, useState } from "react";
import { visit, initIdsByTypeDictionary } from "common/data/treeUtils";
import { isNew } from "common/utility/modelUtils";

import { EditableTaxClass, TaxClassAssignment, TaxType, TaxTypeLabel } from "../types";
import { EditableTaxClassValidationContext, editableTaxSchema } from "../schema/EditableTaxSchema";
import { SelectionsAdapter } from "./SelectionsAdapter";

import "./EditTaxPage.scss";
import { equalTaxClasses } from "../taxesHelpers";
import { DisabledCheckedIcon } from "./DisabledCheckboxIcon";
import { CrudActionFooter, SubmitMode } from "core/components/actionFooter/CrudActionFooter";

const options: SelectItem[] = Object.keys(TaxType).map((key) => {
    return { displayName: TaxTypeLabel[key], id: key };
});

export interface Props extends RouteComponentProps<RouteParams> {
    id: string;
    onArchive: () => void;
    onClose: () => void;
    onSubmit: (data: EditableTaxClass) => void;
    permissions: CrudPermissions;
    productTree: TreeItem[];
    saveStatus: SaveStatus;
    tax: EditableTaxClass;
    taxPosIdFieldSettings: FieldDisplaySettings | null | undefined;
}

interface RouteParams {
    id: string;
    location: string;
    region: string;
}

const validate = (values: EditableTaxClass) => {
    const context: EditableTaxClassValidationContext = {
        ...values,
        validatePos: document.getElementsByName("posTaxId").length > 0,
    };

    return validateYupSchema(values, editableTaxSchema, undefined, context).then(
        () => ({}),
        (err: any) => yupToFormErrors(err)
    );
};

const validTaxTargets = ["category", "product", "variant", "modifier", "option"];

const taxRateConfig: InputTestConfig = {
    numDigits: 3,
    numDecimals: 2,
    allowNegative: false,
};

const parseTaxRate = parseValueToFixed(2);

export const EditTaxPage = ({
    match: {
        params: { id },
    },
    onArchive,
    onClose,
    onSubmit,
    permissions,
    productTree,
    saveStatus,
    tax,
    taxPosIdFieldSettings,
}: Props) => {
    const [submitMode, setSubmitMode] = useState<SubmitMode>("save");
    const [selectedItems, setSelectedItems] = useState<TreeItem[]>([]);
    const initialSelections = useMemo(() => {
        const idsByType = initIdsByTypeDictionary(validTaxTargets);
        const selections: TreeItem[] = [];

        if (!tax?.taxAssignments) {
            return [];
        }

        tax.taxAssignments.forEach(({ targetType, targetId }) => {
            if (idsByType.hasOwnProperty(targetType)) {
                idsByType[targetType][targetId] = true;
            } else {
                console.warn("unrecognised targetType", targetType);
            }
        });

        visit(productTree, (node) => {
            if (idsByType?.[node.type]?.[node.id]) {
                selections.push(node);
            }
        });

        return selections;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tax]);

    const { canUpdate, canDelete } = permissions || {};

    const anyKeySelected = useCallback(
        (keys: string[] = []) => {
            for (let i = 0; i < keys.length; i++) {
                if (selectedItems.find((n) => n.key === keys[i])) {
                    return true;
                }
            }
            return false;
        },
        [selectedItems]
    );

    // give the appearance that all children are checked and disabled when a parent is checked
    // This doesn't actually change the 'checkedness' of children, just their appearance
    useEffect(() => {
        if (!productTree) {
            return;
        }
        visit(productTree, (node, parentKeys) => {
            if (anyKeySelected(parentKeys)) {
                node.checkable = false;
                node.icon = <DisabledCheckedIcon />;
            } else {
                if (node.type !== "root") {
                    node.checkable = true;
                    node.icon = null;
                }
            }
        });
    }, [productTree, selectedItems, anyKeySelected]);

    const defaultExpandedKeys = useMemo(() => {
        let expandedKeys: string[] = [];
        if (!productTree) {
            return expandedKeys;
        }
        visit(
            productTree,
            (node) => {
                if (node.type === "root") {
                    expandedKeys.push(node.key);
                }
            },
            false
        );
        return expandedKeys;
    }, [productTree]);

    const handleSubmit = (data: EditableTaxClass) => {
        setSubmitMode("save");
        onSubmit(data);
    };

    const handleDelete = () => {
        setSubmitMode("archive");
        onArchive();
    };

    if (!tax) {
        return null;
    }

    const isCreate = isNew(id);
    return (
        <div>
            <Formik
                validate={validate}
                validateOnMount={true}
                initialValues={tax}
                onSubmit={handleSubmit}
                validateOnBlur={true}
            >
                {(form) => {
                    const isDirty = !equalTaxClasses(form.initialValues, form.values);

                    return (
                        <>
                            <Form onFinish={form.handleSubmit} autoComplete="off">
                                <EditRow
                                    labelCol={12}
                                    col={10}
                                    title="Name"
                                    subTitle="This will show to the user at checkout and receipts."
                                >
                                    <FieldErrors name="displayName" form={form}>
                                        <FastField
                                            name="displayName"
                                            component={TextInput}
                                            placeholder="Eg: Sales tax"
                                            disabled={!canUpdate}
                                        />
                                    </FieldErrors>
                                </EditRow>
                                <EditRow
                                    labelCol={12}
                                    col={10}
                                    title="Internal name"
                                    subTitle="Optional field. This will only appear in this portal."
                                >
                                    <FieldErrors name="description" form={form}>
                                        <FastField
                                            name="internalName"
                                            component={TextInput}
                                            placeholder="Eg: Drinks sales tax"
                                            disabled={!canUpdate}
                                        />
                                    </FieldErrors>
                                </EditRow>
                                {taxPosIdFieldSettings?.visible && (
                                    <EditRow
                                        labelCol={12}
                                        col={10}
                                        title={taxPosIdFieldSettings?.title || "Tax ID (from POS)"}
                                    >
                                        <FieldErrors name="posTaxId" form={form}>
                                            <FastField
                                                name="posTaxId"
                                                component={TextInput}
                                                placeholder="Eg: 12345"
                                                disabled={!canUpdate}
                                            />
                                        </FieldErrors>
                                    </EditRow>
                                )}
                                <Divider />
                                <EditRow labelCol={12} col={10} title="Type" subTitle="Apply a percentage to an item.">
                                    <FieldErrors name="taxType" form={form}>
                                        <Field
                                            name="taxType"
                                            component={SearchInput}
                                            placeholder={"Select one option"}
                                            options={options}
                                            value="percentage"
                                            disabled={!canUpdate}
                                        />
                                    </FieldErrors>
                                </EditRow>
                                {form.values.taxType === options[0].id && (
                                    <Row justify="start" gutter={16} align="top">
                                        <Col span={24}>
                                            <p>Percentage will be applied to all selected items at checkout.</p>
                                        </Col>
                                        <Col span={24}>
                                            <FieldErrors name="taxRate" form={form}>
                                                <div className="edit-tax-amount__container">
                                                    <p className="edit-tax-amount__label">Rate</p>
                                                    <div className="edit-tax-amount__select-container">
                                                        <Field
                                                            name="taxRate"
                                                            component={InputNumber}
                                                            placeholder="eg. 10%"
                                                            addonAfter="%"
                                                            inputMode="decimal"
                                                            disabled={!canUpdate}
                                                            inputTest={taxRateConfig}
                                                            parseValue={parseTaxRate}
                                                        />
                                                    </div>
                                                </div>
                                            </FieldErrors>
                                        </Col>
                                    </Row>
                                )}
                                <Divider />
                                <EditRow labelCol={12} col={10} title="Apply to all items">
                                    <FieldErrors name="applyToAll" form={form}>
                                        <Field
                                            name="applyToAll"
                                            form={form}
                                            component={SwitchInput}
                                            disabled={!canUpdate}
                                            validateOnChange={true}
                                        />
                                    </FieldErrors>
                                </EditRow>
                                {!form.values.applyToAll && (
                                    <>
                                        <FieldErrors name="taxAssignments" form={form}>
                                            <EditRow
                                                labelCol={24}
                                                col={24}
                                                title="Select items"
                                                subTitle="Taxes will be applied to the selected items. Taxes do not apply to tips and are not a substitute for tips."
                                            >
                                                {null}
                                            </EditRow>
                                            <Field name="taxAssignments">
                                                {({ field, form }: FieldProps<TaxClassAssignment>) => {
                                                    return productTree ? (
                                                        <>
                                                            <SelectionsAdapter
                                                                field={field}
                                                                form={form}
                                                                selectedItems={selectedItems}
                                                                validTaxTargets={validTaxTargets}
                                                            />
                                                            <ProductTree
                                                                initialSelectedItems={initialSelections}
                                                                initialSelectionsExpansion={["parents"]}
                                                                datasource={productTree}
                                                                defaultExpandedKeys={defaultExpandedKeys}
                                                                disabled={!canUpdate}
                                                                checkStrictly={true}
                                                                selectedItems={selectedItems}
                                                                setSelectedItems={setSelectedItems}
                                                                searchPlaceholder="Search by products, variants and modifiers"
                                                                showIcon={true}
                                                                onCheck={() =>
                                                                    form.setFieldTouched("taxAssignments", true)
                                                                }
                                                            />
                                                        </>
                                                    ) : null;
                                                }}
                                            </Field>
                                        </FieldErrors>
                                    </>
                                )}

                                <CrudActionFooter
                                    forceDirty={isDirty}
                                    saving={saveStatus === "saving"}
                                    submitMode={submitMode}
                                    showDuplicate={false}
                                    showDelete={!isCreate && canDelete}
                                    showSave={canUpdate}
                                    saveProps={{
                                        disabled: !isDirty && !isCreate,
                                        children: "Save & close",
                                    }}
                                    confirmDelete={true}
                                    deleteProps={{
                                        onClick: handleDelete,
                                    }}
                                />
                            </Form>
                            <ConfirmModal
                                title={"You have unsaved changes."}
                                subTitle="Are you sure you want to leave this page without saving?"
                                isPrompt={!form.isSubmitting && !equalTaxClasses(form.initialValues, form.values)}
                                cancelText="Discard changes"
                                confirmText="Keep editing"
                            />
                        </>
                    );
                }}
            </Formik>
        </div>
    );
};
