import styles from "./HeaderActions.module.scss";

import { ActionsPopup } from "core/components/actionsPopup/ActionsPopup";
import { Button, ButtonGroup } from "core/components/button";
import { Card } from "core/components/card";
import { CrudPermissions } from "features/location/types/createCrudPermissions";
import { Link } from "react-router-dom";
import { ServiceRouteParams } from "features/service/types/ServiceRouteParams";
import { Tab } from "features/service/types/Tab";
import { useMedia } from "common/hooks";
import { useParams } from "react-router";

interface Props {
    newItemPath: string;
    permissions: CrudPermissions | undefined;
}

const TAB_BUTTONS = [
    { label: "List", tab: Tab.LIST },
    { label: "Week", tab: Tab.WEEK },
    { label: "Day", tab: Tab.DAY },
];

export const HeaderActions = ({ newItemPath, permissions }: Props) => {
    const isLarge = useMedia("(min-width: 896px)");

    const { tab: selectedTab } = useParams<ServiceRouteParams>();

    return (
        <div className={styles.container}>
            {isLarge ? (
                <>
                    <ButtonGroup
                        buttonStyle="outline"
                        selectedIndex={TAB_BUTTONS.findIndex(({ tab }) => tab === selectedTab)}
                    >
                        {renderButtons({ shape: "button" })}
                    </ButtonGroup>
                    {permissions?.canCreate && <CreateButton createUrl={newItemPath} nowrap shape="button" />}
                </>
            ) : (
                <ActionsPopup closeOnMenuClick={true}>
                    <Card cardStyle="popup">
                        {renderButtons({ shape: "listItem" })}
                        {permissions?.canCreate && <CreateButton createUrl={newItemPath} shape="listItem" />}
                    </Card>
                </ActionsPopup>
            )}
        </div>
    );
};

interface ButtonProps {
    shape: "button" | "listItem";
    nowrap?: boolean;
}

// implemented as a function because ButtonGroup expects children to be Buttons, not another component
function renderButtons({ shape }: ButtonProps) {
    return TAB_BUTTONS.map(({ label, tab }) => (
        <Button as={Link} to={tab} key={tab} shape={shape}>
            {label}
        </Button>
    ));
}

interface CreateButtonProps extends ButtonProps {
    createUrl: string;
}

const CreateButton = ({ shape, createUrl }: CreateButtonProps) => (
    <Button as={Link} to={createUrl} shape={shape}>
        New service
    </Button>
);
