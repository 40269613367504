import styles from "./PromotionType.module.scss";
import commonStyles from "../EditPromotion.module.scss";

import { buildInputRegex } from "core/components/form/InputNumber";
import { Card, Row, Title } from "core/components/card";
import { EditablePromotion, PromotionDiscountType } from "../../types";
import { Field, FieldProps, useFormikContext } from "formik";
import { FieldErrors } from "core/components/form/fieldErrors";
import { formatLeadingDecimal } from "common/utility/numberUtils";
import { Input } from "core/components/form/input";
import { LocationLocaleContext } from "features/location";
import { Select } from "core/components/form/select";
import { useCurrencyParser } from "common/hooks";
import { CommonEditProps } from "../EditPromotionPage";
import { useSelector } from "react-redux";
import { getPriceListsOptions } from "features/priceList/selectors";
import { useEffect } from "react";

interface Props extends CommonEditProps {}

const CurrencySymbol = () => (
    <LocationLocaleContext.Consumer>{(locale) => locale.currencySymbol}</LocationLocaleContext.Consumer>
);

const discountValueInputProps = {
    [PromotionDiscountType.PERCENTAGE]: {
        inputTest: buildInputRegex({ numDigits: 2 }),
        after: "%",
        pattern: "\\d*",
    },
    [PromotionDiscountType.FIXEDAMOUNT]: {
        inputTest: buildInputRegex({ numDecimals: 2 }),
        before: CurrencySymbol,
        inputMode: "decimal",
    },
};

export const PromotionType = ({ disableFields }: Props) => {
    const form = useFormikContext<EditablePromotion>();
    const currencyParser = useCurrencyParser();
    const priceListsOptions = useSelector(getPriceListsOptions);

    useEffect(() => {
        if (
            form.values.id &&
            form.values.priceListId &&
            priceListsOptions?.every((plo) => plo.value !== form.values.priceListId)
        ) {
            // reset the saved priceListId incase it no longer available
            form.setFieldValue("priceListId", null, true);

            // force trigger field validation
            const field = document.querySelector("[name='priceListId']") as HTMLInputElement;
            if (field) {
                field.focus();
                field.blur();
            }
        }
    }, [form, priceListsOptions]);

    return (
        <Card>
            <Row collapse="down">
                <Title title="Type" />
            </Row>
            <Row>
                <FieldErrors fieldNames={["discount.type", "discount.value", "priceListId"]}>
                    <div className={styles.container}>
                        <div className={commonStyles.equalColumn}>
                            <Field name="discount.type">
                                {({
                                    field,
                                    form: { setFieldValue, setFieldTouched },
                                    meta,
                                }: FieldProps<PromotionDiscountType>) => {
                                    return (
                                        <Select
                                            field={field}
                                            form={form as any}
                                            meta={meta}
                                            label="Promotion type"
                                            disabled={disableFields}
                                            markRequired
                                            onChange={(option: any) => {
                                                if (option.value === PromotionDiscountType.PRICELIST) {
                                                    setFieldValue("applyToAll", true);
                                                    setFieldValue("discount.value", "");
                                                    return;
                                                }
                                                if (option.value === PromotionDiscountType.PERCENTAGE) {
                                                    setFieldValue("applyToAll", true);
                                                }

                                                setFieldValue("priceListId", undefined);
                                                setFieldTouched("priceListId", false);

                                                setFieldValue("discount.value", "");
                                                setFieldTouched("discount.value", false);
                                                (
                                                    document.querySelector(
                                                        "[name='discount.value']"
                                                    ) as HTMLInputElement
                                                )?.focus();
                                            }}
                                            options={[
                                                {
                                                    value: PromotionDiscountType.PERCENTAGE,
                                                    label: "Percentage",
                                                },
                                                {
                                                    value: PromotionDiscountType.FIXEDAMOUNT,
                                                    label: "Amount",
                                                },
                                                {
                                                    value: PromotionDiscountType.PRICELIST,
                                                    label: "Price List",
                                                },
                                            ]}
                                        />
                                    );
                                }}
                            </Field>
                        </div>
                        <div className={commonStyles.equalColumn}>
                            {form.values.discount.type === PromotionDiscountType.PRICELIST ? (
                                <Field
                                    name="priceListId"
                                    component={Select}
                                    label="Price List"
                                    placeholder="Select a Price List"
                                    disabled={disableFields}
                                    blurInputOnSelect={false}
                                    markRequired
                                    options={priceListsOptions}
                                />
                            ) : (
                                <Field
                                    name="discount.value"
                                    component={Input}
                                    label="Discount"
                                    disabled={disableFields}
                                    markRequired
                                    {...discountValueInputProps[form.values.discount.type]}
                                    parseValue={
                                        form.values.discount.type === PromotionDiscountType.FIXEDAMOUNT
                                            ? currencyParser
                                            : undefined
                                    }
                                    formatOnChange={formatLeadingDecimal}
                                    maxLength={6}
                                />
                            )}
                        </div>
                    </div>
                </FieldErrors>
            </Row>
        </Card>
    );
};
