import { TimePicker } from "antd";
import { useState } from "react";
import moment from "moment";

export interface Props {
    id: string;
    value?: number;
    defaultOpenValue?: number;
    onChange: (time?: number) => void;
    disabled?: boolean;
    className?: string;
    placeholder?: string;
}

export const TimeSelector = ({
    id,
    value,
    onChange,
    defaultOpenValue,
    className,
    disabled = false,
    placeholder = "Select",
}: Props) => {
    const momentValue = typeof value === "number" ? moment(String(value).padStart(3, "0"), "Hmm") : undefined;
    const [open, setOpen] = useState(false);
    const idAttr = { id: id };

    const handleOpenChange = (open: any) => {
        setOpen(open);
    };

    const handleChange = (time: moment.Moment | null) => {
        onChange(time ? parseInt(time.format("HHmm")) : undefined);
    };

    return (
        <TimePicker
            {...idAttr}
            format="HH:mm"
            open={open}
            onOpenChange={handleOpenChange}
            value={momentValue}
            onSelect={handleChange}
            onChange={handleChange}
            disabled={disabled}
            placeholder={placeholder}
            minuteStep={15}
            className={className}
            showNow={false}
            allowClear={true}
            defaultValue={
                defaultOpenValue === undefined
                    ? undefined
                    : typeof defaultOpenValue === "number"
                    ? moment(String(defaultOpenValue).padStart(3, "0"), "Hmm")
                    : moment("00:00", "HH:mm")
            }
        />
    );
};
