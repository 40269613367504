import styles from "./LocationList.module.scss";

import { ActiveLocation, LocationSummary } from "..";
import { Button } from "core/components/button";
import { EditNewLocationModal } from "./EditNewLocationModal";
import { FocusEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getActiveLocation } from "../selectors/getLocationPermissions";
import { getChangeLocationUrl } from "../selectors/getChangeLocationUrl";
import { getIsParentLocation } from "../selectors/getIsParentLocation";
import { getLocationsListContainsParents } from "../selectors/getLocationsListContainParents";
import { getLocationsListState } from "../selectors/getLocationsList";
import { getLocationsMenu, isGroup, isLocation, isParentLocation } from "../selectors/getLocationsMenu";
import { Group } from "features/group";
import { HighlightSearchTerm } from "core/components/highlightSearchTerm";
import { Input } from "core/components/form/input/Input";
import { isParent } from "core/components/menu/types/Menu";
import { LocationsMenuNode } from "../types/LocationsMenu";
import { Menu, MenuItemParentProps, MenuItemProps } from "core/components/menu";
import { normaliseText } from "common/utility/StringUtils";
import { scrollIntoViewIfNeeded } from "common/utility/scrollIntoView";
import { Search } from "components/icons";
import { useLocationListContext } from "../context/LocationListContext";
import { usePopupContext } from "core/components/popup/PopupContext";
import { useSelector } from "react-redux";
import classNames from "classnames";
export interface LocationListProps {
    activeLocation: ActiveLocation | undefined;
    canCreate: boolean;
    locations: LocationSummary[] | undefined;
    loading: boolean;
    fetchLocations(): void;
    groups: Group[] | undefined;
}

// TODO add nodesById
export interface InteractiveNodesInfo {
    nodes: LocationsMenuNode[];
    indexes: Record<string, number>;
    ids: string[];
    nodesById: Record<string, LocationsMenuNode>;
}

// only used if scrollIntoViewIfNeeded is NOT supported
const scrollIntoViewOptions: ScrollIntoViewOptions = {
    behavior: "auto",
    block: "end",
    inline: "nearest",
};

export const LocationList = ({ fetchLocations, groups, canCreate, loading }: LocationListProps) => {
    const [newLocationModalOpen, setLocationModalOpen] = useState(false);

    const { expanded } = usePopupContext();

    const { filterText, setFilterText, selectedNodeId, setSelectedNodeId } = useLocationListContext();

    const activeLocation = useSelector(getActiveLocation);

    const listContainsParents = useSelector(getLocationsListContainsParents);

    const locationsState = useSelector(getLocationsListState);

    const isParentLocation = useSelector(getIsParentLocation) || false;

    const menu = useSelector(getLocationsMenu);

    const [expandedIds, setExpandedIds] = useState<string[]>([]);

    const [selectedItemIsParent, setSelectedItemIsParent] = useState(false);

    const canSetInitialExpandedIds = useRef(true);

    const canScrollToActiveLocation = useRef(true);

    const input = useRef<HTMLInputElement>(null);

    const listRef = useRef<HTMLUListElement>(null);

    const filteredLocations = useMemo(() => {
        if (!menu || !groups) {
            return [];
        }

        if (!filterText.trim()) return menu;

        return filterMenu(menu, filterText, groups);
    }, [filterText, groups, menu]);

    const allExpandableParentIds = useMemo(
        () => findParentNodes(filteredLocations, false).map((node) => node.id),
        [filteredLocations]
    );

    // flattened list of interactive nodes. Doesn't include groups!
    const interactiveNodesInfo = useMemo(
        () => getInteractiveMenuNodesInfo(filteredLocations, false),
        [filteredLocations]
    );

    useEffect(() => {
        if (expanded) {
            input.current?.focus();
        }
    }, [expanded]);

    useEffect(() => {
        if (expanded && locationsState.status === "unloaded") {
            fetchLocations();
        }
    }, [expanded, fetchLocations, locationsState.status]);

    // make the active location the selected node initially
    useEffect(() => {
        if (!activeLocation) {
            return;
        }

        if (!selectedNodeId) {
            setSelectedNodeId(activeLocation.id);
        }
    }, [activeLocation, selectedNodeId, setSelectedNodeId]);

    // set initially expanded items - only once on mount
    useEffect(() => {
        if (!activeLocation || !canSetInitialExpandedIds.current) {
            return;
        }

        canSetInitialExpandedIds.current = false;

        setExpandedIds(
            [
                activeLocation.parentLocationId,
                activeLocation.group?.id,
                isParentLocation ? activeLocation.id : undefined,
            ].filter(Boolean) as string[]
        );
    }, [activeLocation, isParentLocation, selectedNodeId, setSelectedNodeId]);

    // scroll to location, will retry if list not ready yet
    const scrollToLocation = useCallback((id: string) => {
        const element = listRef.current?.querySelector(getItemIdSelector(id)) as HTMLElement;

        if (element) {
            scrollIntoViewIfNeeded(element, true, scrollIntoViewOptions);
        } else {
            // element not ready, keep trying
            requestAnimationFrame(() => scrollToLocation(id));
        }
    }, []);

    // trigger scroll to active location after locations have loaded
    useEffect(() => {
        if (!activeLocation || locationsState.status !== "loaded" || !canScrollToActiveLocation.current) {
            return;
        }

        canScrollToActiveLocation.current = false;

        scrollToLocation(activeLocation.id);
    }, [activeLocation, locationsState.status, scrollToLocation, selectedNodeId, setSelectedNodeId]);

    const selectItemAt = useCallback(
        (index: number) => {
            const node = interactiveNodesInfo.nodes[index];

            if (!node) {
                return;
            }

            setSelectedItemIsParent(isParent(node));

            const parentIds = getNodeParentIds(filteredLocations, node.id);

            if (parentIds) {
                setExpandedIds(Array.from(new Set([...expandedIds, ...parentIds])));
            }

            setSelectedNodeId(node.id);

            const element = listRef.current?.querySelector(getItemIdSelector(node.id)) as HTMLElement;

            if (element) {
                scrollIntoViewIfNeeded(element, false, scrollIntoViewOptions);
            }
        },
        [expandedIds, filteredLocations, interactiveNodesInfo.nodes, setSelectedNodeId]
    );

    const handleFilter = useCallback(
        (value) => {
            setFilterText(value);
            selectItemAt(0);
        },
        [selectItemAt, setFilterText]
    );

    const onExpandedChanged = useCallback((expanded: boolean, node: LocationsMenuNode) => {
        if (expanded) {
            setExpandedIds((ids) => [...ids, node.id]);
        } else {
            setExpandedIds((ids) => ids.filter((id) => id !== node.id));
        }
    }, []);

    const handleInputKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLElement>) => {
            if (e.key === "ArrowDown") {
                e.preventDefault();

                const selectedIndex = interactiveNodesInfo.indexes[selectedNodeId];

                const selectedItem = interactiveNodesInfo.nodes[selectedIndex];

                const parentNode = isParent(selectedItem) ? selectedItem : null;

                if (parentNode && !expandedIds.includes(parentNode.id)) {
                    // skip elements that are not expanded
                    const newIndex = selectedIndex + parentNode.children.length + 1;

                    if (newIndex < interactiveNodesInfo.nodes.length - 1) {
                        selectItemAt(newIndex);
                    } else {
                        selectItemAt(0);
                    }
                } else {
                    const newIndex = selectedIndex < interactiveNodesInfo.nodes.length - 1 ? selectedIndex + 1 : 0;
                    selectItemAt(newIndex);
                }
            }

            if (e.key == "ArrowUp") {
                e.preventDefault();

                const selectedIndex = interactiveNodesInfo.indexes[selectedNodeId];

                const newIndex = selectedIndex > 0 ? selectedIndex - 1 : interactiveNodesInfo.nodes.length - 1;

                selectItemAt(newIndex);
            }

            if (!filterText && selectedItemIsParent && e.key === "ArrowLeft") {
                e.preventDefault();

                const selectedIndex = interactiveNodesInfo.indexes[selectedNodeId];

                const selectedItem = interactiveNodesInfo.nodes[selectedIndex];

                const parentNode = isParent(selectedItem) ? selectedItem : null;

                if (parentNode && expandedIds.includes(parentNode.id)) {
                    setExpandedIds((ids) => ids.filter((id) => id !== parentNode.id));
                }
            }

            if (!filterText && selectedItemIsParent && e.key === "ArrowRight") {
                e.preventDefault();

                const selectedIndex = interactiveNodesInfo.indexes[selectedNodeId];

                const selectedItem = interactiveNodesInfo.nodes[selectedIndex];

                const parentNode = isParent(selectedItem) ? selectedItem : null;

                if (parentNode && !expandedIds.includes(parentNode.id)) {
                    setExpandedIds((ids) => [...ids, parentNode.id]);
                    selectItemAt(selectedIndex + 1);
                }
            }

            if (e.key !== "Enter") {
                return;
            }

            const selectedIndex = interactiveNodesInfo.indexes[selectedNodeId];

            const selectedItem = interactiveNodesInfo.nodes[selectedIndex];

            if (selectedItem) {
                const location = isLocation(selectedItem.data) ? selectedItem.data : null;
                if (location) {
                    window.location.href = getChangeLocationUrl(location.slug);
                }
            }
        },
        [
            expandedIds,
            filterText,
            interactiveNodesInfo.indexes,
            interactiveNodesInfo.nodes,
            selectItemAt,
            selectedItemIsParent,
            selectedNodeId,
        ]
    );

    const handleItemFocus = useCallback(
        (e: FocusEvent<HTMLAnchorElement>) => {
            const nodeId = e.currentTarget.dataset.nodeid;

            if (!nodeId) {
                return;
            }

            const node = interactiveNodesInfo.nodesById[nodeId];

            if (!node) {
                return;
            }

            selectItemAt(interactiveNodesInfo.indexes[nodeId]);
        },
        [interactiveNodesInfo.indexes, interactiveNodesInfo.nodesById, selectItemAt]
    );

    return (
        <>
            <div className={styles.container}>
                <div className={styles.filterCreate}>
                    <Input
                        autoComplete="off"
                        data-initialfocus
                        name="search"
                        ref={input}
                        className={styles.search}
                        placeholder={`Filter ${listContainsParents ? "brands and " : ""}venues`}
                        before={<Search />}
                        value={filterText}
                        onChange={handleFilter}
                        onKeyDown={handleInputKeyDown}
                    ></Input>
                    {canCreate && (
                        <Button className={styles.create} onClick={() => setLocationModalOpen(true)}>
                            Create
                        </Button>
                    )}
                </div>
                <ul className={styles.list} ref={listRef}>
                    {loading && <li className={styles.listItemMessage}>Loading...</li>}
                    {!loading && !filteredLocations.length && (
                        <li className={styles.listItemMessage}>No matching items</li>
                    )}
                    {!loading && !!filteredLocations.length && (
                        <Menu
                            menu={filteredLocations}
                            parentComponent={MenuParentItem}
                            itemComponent={MenuItem}
                            onFocus={handleItemFocus}
                            expandedKeys={filterText ? allExpandableParentIds : expandedIds}
                            onExpandedChanged={onExpandedChanged}
                            expandEnabled={!filterText}
                        />
                    )}
                </ul>
            </div>

            <EditNewLocationModal onClose={() => setLocationModalOpen(false)} visible={newLocationModalOpen} />
        </>
    );
};

const MenuParentItem = ({ node, level, onFocus }: MenuItemParentProps<Group | LocationSummary>) => {
    const group = isGroup(node.data) ? node.data : null;

    const parentLocation = isParentLocation(node.data) ? node.data : null;

    if (group) {
        return (
            <span className={styles.groupLabel}>
                <span className={styles.groupTitle}>{group.displayName}</span>
            </span>
        );
    } else if (parentLocation) {
        return (
            <span className={styles.parentLabel} data-level={`level${level}`}>
                <LinkItem location={parentLocation} onFocus={onFocus} />
            </span>
        );
    }

    return null;
};

const MenuItem = ({ node, level, onFocus }: MenuItemProps<Group | LocationSummary>) => {
    if (!isLocation(node.data)) {
        return null;
    }

    return (
        <span className={styles.itemLabel} data-level={`level${level}`}>
            <LinkItem location={node.data} onFocus={onFocus} />
        </span>
    );
};

interface LinkItemProps {
    location: LocationSummary;
    onFocus?(e: FocusEvent<HTMLElement>): void;
}

const LinkItem = ({ location, onFocus }: LinkItemProps) => {
    const activeLocation = useSelector(getActiveLocation);

    const { filterText, selectedNodeId } = useLocationListContext();

    const isActive = location.id === activeLocation?.id;

    const isSelected = location.id === selectedNodeId;

    if (!location) {
        return null;
    }

    const linkClasses = classNames(styles.link, {
        [styles.linkActiveLocation]: isActive,
        [styles.linkSelected]: isSelected,
    });

    return (
        <a
            className={linkClasses}
            href={getChangeLocationUrl(location.slug)}
            onFocus={onFocus}
            data-nodeid={location.id}
        >
            <HighlightSearchTerm source={location.displayName} searchTerm={filterText} />
        </a>
    );
};

type NodeFilter = (node: LocationsMenuNode, search: string, groups: Group[]) => boolean;

// custom filter used here (as opposed to filterSearchable) to support multiple levels of nesting and not flatten result
const recursiveFilter = (f: NodeFilter, search: string, groups: Group[]) => (nodes: LocationsMenuNode[]) => {
    return nodes.reduce<LocationsMenuNode[]>((acc, node) => {
        if (isParent(node)) {
            // call recursively to filter children
            const children: LocationsMenuNode[] = recursiveFilter(f, search, groups)(node.children);

            return children.length || f(node, search, groups) ? [...acc, { ...node, children }] : acc;
        }

        return f(node, search, groups) ? [...acc, node] : acc;
    }, []);
};

// get a flattened list of all parent menu nodes
export function findParentNodes(
    nodes: LocationsMenuNode[],
    includeParentsWithNoChildren = true,
    results: LocationsMenuNode[] = []
): LocationsMenuNode[] {
    nodes.forEach((node) => {
        if (isParent(node)) {
            const hasChildren = node.children.length > 0;

            if (hasChildren || includeParentsWithNoChildren) {
                results.push(node);
            }

            if (hasChildren) {
                findParentNodes(node.children, includeParentsWithNoChildren, results);
            }
        }
    });

    return results;
}

function filterMenu(menu: LocationsMenuNode[], search: string, groups: Group[]): LocationsMenuNode[] {
    if (!menu) {
        return [];
    }

    if (!search) {
        return menu;
    }

    return recursiveFilter(searchMatchesNode, normaliseText(search), groups)(menu);
}

function searchMatchesNode(node: LocationsMenuNode, search: string, groups: Group[]) {
    // targeting groups themselves doesn't provide value to search, just their children
    if (isGroup(node.data)) {
        return false;
    }

    const locationNode = isLocation(node.data) ? node.data : null;

    if (!locationNode) {
        return false;
    }

    const group = groups.find((g) => g.id === locationNode.groupId);
    return (
        normaliseText(locationNode.displayName).includes(search) ||
        normaliseText(locationNode.slug).includes(search) ||
        locationNode.id.toLowerCase() === search ||
        ((group && normaliseText(group.displayName).includes(search)) ?? false)
    );
}

export function findNode(nodes: LocationsMenuNode[], id: string): LocationsMenuNode | null {
    for (const node of nodes) {
        if (node.id === id) {
            return node;
        }

        if (isParent(node)) {
            const found = findNode(node.children, id);
            if (found) {
                return found;
            }
        }
    }
    return null;
}

// get a flattened ordered list of all interactive menu nodes with indexes for fast lookups
export function getInteractiveMenuNodesInfo(
    nodes: LocationsMenuNode[],
    includeGroups: boolean = false,
    results: LocationsMenuNode[] = []
): InteractiveNodesInfo {
    const info: InteractiveNodesInfo = {
        nodes: [],
        ids: [],
        indexes: {},
        nodesById: {},
    };

    const addNode = (node: LocationsMenuNode) => {
        info.nodes.push(node);
        info.ids.push(node.id);
        info.indexes[node.id] = info.nodes.length - 1;
        info.nodesById[node.id] = node;
    };

    const addInteractiveNodes = (
        nodes: LocationsMenuNode[],
        includeGroups: boolean = false,
        results: LocationsMenuNode[] = []
    ) => {
        nodes.forEach((node) => {
            if (!isGroup(node.data) || includeGroups) {
                addNode(node);
            }

            if (isParent(node) && node.children.length > 0) {
                addInteractiveNodes(node.children, includeGroups, results);
            }
        });
    };

    addInteractiveNodes(nodes, includeGroups, results);

    return info;
}

// recursive search to check if a node or its children match any of the passed in nodeIds
export function containsNodeId(node: LocationsMenuNode, nodeIds: string[]): boolean {
    if (nodeIds.includes(node.id)) {
        return true;
    }

    if (isParent(node)) {
        for (const child of node.children) {
            if (containsNodeId(child, nodeIds)) {
                return true;
            }
        }
    }

    return false;
}

function getItemIdSelector(nodeId: string) {
    return `[data-nodeid="${nodeId}"]`;
}

export function getNodeParentIds(nodes: LocationsMenuNode[], id: string, parentIds: string[] = []): string[] {
    for (const node of nodes) {
        if (node.id === id) {
            // special case: if the node is a parent include it as well
            if (isParent(node)) {
                return [...parentIds, node.id];
            }

            return parentIds;
        }

        if (isParent(node) && node.children.length > 0) {
            parentIds.push(node.id);

            const result = getNodeParentIds(node.children, id, parentIds);

            if (result.length) {
                return result;
            }

            parentIds.pop();
        }
    }

    return [];
}

// debug helper
//
// function debugNodeById(id: string, nodes: LocationsMenuNode[]) {
//     const node = findNode(nodes, id);
//     const parent = isParent(node) ? node : null;
//     const group = parent && isGroup(parent.data) ? parent.data : null;
//     const location = node && isLocation(node.data) ? node.data : null;

//     const type = group ? "group" : parent ? "parent" : location ? "location" : "unknown";
//     return node ? `${node.data.displayName} (${type}) id:${id}` : "not found id:${id}";
// }
