import { CsvWriter } from "common/data/generateCsvBlob";
import { CatalogueItemSelections } from "../types";
import { CatalogueExportItem } from "../types/CatalogueExportItem";

export function getModifierCSVHeader(energyContent: boolean, upsellTypes: boolean): Array<string | null> {
    return [
        "Modifier List Name",
        "Internal Name",
        "Modifier",
        "Price",
        "SKU",
        ...(energyContent ? ["Energy Content"] : []),
        "Min Selection",
        "Max Selection",
        "Selection Per Item",
        ...(upsellTypes ? ["Print As Product"] : []),
    ];
}

export function generateModifierRow(
    catalogueItem: CatalogueExportItem,
    writer: CsvWriter<Array<string | null>>,
    exportEnergyContent: boolean,
    exportUpsellTypes: boolean,
    selections: CatalogueItemSelections | null
): void {
    writer(getModifierRow(catalogueItem, exportEnergyContent, exportUpsellTypes));

    if (!selections) {
        catalogueItem.children?.forEach((option) => {
            writer(getModifierRow(option, exportEnergyContent, exportUpsellTypes));
        });
    } else {
        const childSelections: string[] | boolean = selections[catalogueItem.id] || [];

        if (!catalogueItem.children || childSelections === true) {
            return;
        }

        catalogueItem.children
            .filter((child) => childSelections.includes(child.id))
            .forEach((child) => {
                writer(getModifierRow(child, exportEnergyContent, exportUpsellTypes));
            });
    }
}

function getModifierRow(
    item: CatalogueExportItem,
    exportEnergyContent: boolean,
    exportUpsellTypes: boolean
): Array<string | null> {
    return [
        item.type === "modifier" ? item.displayName : null,
        item.type === "modifier" ? item.internalName ?? item.displayName : null,
        item.type === "option" ? item.displayName : null,
        item.price ? String(item.price) : null,
        item.sku || null,
        ...(exportEnergyContent ? [item.energyContent ? String(item.energyContent) : null] : []),
        item.type === "modifier" ? "0" : null,
        item.type === "modifier" ? "1" : null,
        item.type === "modifier" ? "1" : null,
        ...(exportUpsellTypes ? [null] : []),
    ];
}
