import styles from "./PosFieldSyncStatus.module.scss";

import { SyncStatus, getStatusFromValidationStatus } from "core/components/syncStatus";
import { useSelector } from "react-redux";
import { getPosSyncEnabled } from "features/catalogue/selectors/getPosSyncEnabled";
import { ItemValidationStatus } from "features/catalogue/types";

interface Props {
    syncStatus: ItemValidationStatus;
}

export const PosFieldSyncStatus = ({ syncStatus }: Props) => {
    const posSyncEnabled = useSelector(getPosSyncEnabled);
    const status = getStatusFromValidationStatus(syncStatus);

    if (!status || !posSyncEnabled) {
        return null;
    }

    return (
        <span className={styles.syncContainer}>
            <SyncStatus status={status} />
        </span>
    );
};
