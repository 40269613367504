import { opsApi } from "API";
import { ItemValidationStatus } from "features/catalogue";
import { GraphQLItem, GraphQLItemId } from "common/scaffolding/types";
import { GraphQLModifier } from "features/modifier/api/editModifier";

export const editProduct = async (locationId: string, menuItemId: string): Promise<GraphQLMenuItem> => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId, menuItemId });
    return res.data.item;
};

type GraphQLPayload = { item: GraphQLMenuItem };

export interface GraphQLMenuItem extends GraphQLItem {
    description: string | null;
    categories: Array<GraphQLItemId>;
    image: string;
    video: string;
    price: number;
    taxRate: number | null;
    sku: string;
    relatedItems: Array<GraphQLItemId>;
    template: GraphQLItemId;
    tags: Array<GraphQLItemId>;
    modifiers: GraphQLModifier[];
    variants: GraphQLVariant[];
    special: boolean;
    popular: boolean;
    recommended: boolean;
    descriptors: GraphQLDescriptor[];
    priceLists: Array<string>;
    status: number;
    energyContent?: number | null;
    validationStatus?: ItemValidationStatus;
    isLinked: boolean;
}

export interface GraphQLDescriptor {
    key: string;
    value: string;
}

export interface GraphQLVariant {
    id: string;
    displayName: string;
    price: number;
    taxRate: number | null;
    sku: string;
    modifiers: string[];
    recommended?: boolean;
    energyContent?: number | null;
    validationStatus?: ItemValidationStatus;
    isLinked: boolean;
}

const query = `
    query($locationId: ID!, $menuItemId: ID!) {
        item(locationId: $locationId, id:$menuItemId) {
            id,
            internalName,
            displayName,
            description,
            descriptors {
                key,
                value
            },
            categories {
                id
            },
            image,
            video,
            price,
            taxRate,
            sku,
            energyContent,
            variants {
                id,
                price,
                taxRate,
                displayName,
                sku,
                modifiers,
                recommended,
                energyContent,
                validationStatus,
                isLinked
            },
            relatedItems {
                id
            },
            template {
                id
            },
            tags {
                id
            },
            modifiers {
                id,
                global,
                internalName,
                displayName,
                minSelection,
                maxSelection,
                required,
                sku,
                options {
                    displayName,
                    price,
                    taxRate,
                    sku,
                    isLinked
                },
                upsell,
                type,
                isLinked
            },
            recommended,
            popular,
            special,
            priceLists,
            status,
            validationStatus
            isLinked
        }
    }
`;
