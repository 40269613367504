import { Saved, KeyedLoaded } from "common/loader";

export interface EditTypeKeys {
    FETCH_BEGIN: string;
    FETCH_SUCCESS: string;
    FETCH_FAILURE: string;

    SAVE_BEGIN: string;
    SAVE_SUCCESS: string;
    SAVE_FAILURE: string;
}

export function scaffoldEdit<TData, TList, TTypeKeys extends EditTypeKeys, TKey = string>(typeKeys: TTypeKeys) {
    type FetchingAction = { type: TTypeKeys["FETCH_BEGIN"]; key: TKey | undefined };
    type FetchedAction = { type: TTypeKeys["FETCH_SUCCESS"]; key: TKey | undefined; data: TData };
    type FetchFailedAction = { type: TTypeKeys["FETCH_FAILURE"]; key: TKey | undefined; error: any };
    type SavingAction = { type: TTypeKeys["SAVE_BEGIN"]; key: TKey | undefined };
    type SavedAction = { type: TTypeKeys["SAVE_SUCCESS"]; key: TKey | undefined; data: any };
    type SaveFailedAction = { type: TTypeKeys["SAVE_FAILURE"]; error: any };

    type AllActions =
        | FetchingAction
        | FetchedAction
        | FetchFailedAction
        | SavingAction
        | SavedAction
        | SaveFailedAction;

    const createAction = {
        loading: (key: TKey | undefined): FetchingAction => ({ type: typeKeys.FETCH_BEGIN, key }),
        loaded: (key: TKey | undefined, data: TData): FetchedAction => ({ type: typeKeys.FETCH_SUCCESS, key, data }),
        loadFailed: (key: TKey | undefined, error: any): FetchFailedAction => ({
            type: typeKeys.FETCH_FAILURE,
            key,
            error,
        }),
        saving: (key: TKey | undefined): SavingAction => ({
            type: typeKeys.SAVE_BEGIN,
            key,
        }),
        saved: (key: TKey | undefined, data?: TList | null): SavedAction => ({
            type: typeKeys.SAVE_SUCCESS,
            key,
            data,
        }),
        saveFailed: (key: TKey | undefined, error: any) => ({ type: typeKeys.SAVE_FAILURE, key, error }),
    };

    type State = KeyedLoaded<TData, TKey | undefined> & Saved<TData>;

    const initialState: State = {
        status: "unloaded",
    };

    function isAction<TAction extends AllActions>(action: AllActions, type: string): action is TAction {
        return action.type === type;
    }

    function reducer(state: State = initialState, action: AllActions): State {
        if (isAction<FetchingAction>(action, typeKeys.FETCH_BEGIN)) {
            return {
                status: "loading",
                key: action.key,
            };
        }

        if (isAction<FetchedAction>(action, typeKeys.FETCH_SUCCESS) && acceptLoadCompletion(action.key, state)) {
            const { data } = action;

            return {
                status: "loaded",
                data,
                key: state.key,
            };
        }

        if (isAction<FetchFailedAction>(action, typeKeys.FETCH_FAILURE) && acceptLoadCompletion(action.key, state)) {
            const { error } = action;

            return {
                status: "failed",
                error,
                key: state.key,
            };
        }

        if (isAction<SavingAction>(action, typeKeys.SAVE_BEGIN)) {
            return {
                ...state,
                saveStatus: "saving",
            };
        }

        if (isAction<SavedAction>(action, typeKeys.SAVE_SUCCESS)) {
            return {
                ...state,
                saveStatus: "saved",
            };
        }

        if (isAction<SaveFailedAction>(action, typeKeys.SAVE_FAILURE)) {
            return {
                ...state,
                saveStatus: "savefailed",
            };
        }

        return state;
    }

    function acceptLoadCompletion(
        id: TKey | undefined,
        state: KeyedLoaded<TData, TKey | undefined>
    ): state is { status: "loading"; key: TKey } | { status: "loaded"; key: TKey; data: TData } {
        if (state.status !== "loading" && state.status !== "loaded") {
            return false;
        }

        return !!state.key && state.key === id;
    }

    return {
        createAction,
        reducer,
    };
}
