import { opsApi } from "API";
import { trayChargeQueryFields } from "features/trayCharge/api";
import { venueServiceFeeQueryFields } from "features/venueServiceFee/api";
import { ActiveLocation } from "..";

export const fetchLocation = async (slug: string) => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId: slug });
    return res.data.location;
};

type GraphQLPayload = { location: ActiveLocation };

export const alcoholicDrinksRestrictionQueryFields = `
    enabled,
    applyAcrossAllServices,
    maxDrinksPerOrder
`;

const analyticsLayoutConfigQueryFields = `
    folders {
        id,
        displayName,
        slug,
        pages {
            id,
            displayName,
            slug
            tabs {
                id,
                displayName
            }
        }
    }
`;

export const activeLocationFields = `
    id,
    displayName,
    billingSystemId,
    slug,
    logoUrl,
    lastPublished,
    lastPublishedUrl,
    lastPublishedUpdate,
    isDemo,
    isSalesMode,
    authInfo {
        permissions
        userRoles
    },
    companyDetails {
        registeredName,
        abn,
        address,
        privacyPolicyUrl
    },
    takeawayOptions {
        enabled,
        minReadyTimeMins,
        sectionId,
        contactPhoneNumber, 
        collectionInstructions,
        scheduleOptions {
            enabled,
            scheduleWindowMinutes
        }
    },  
    venueAddress {
        addressLine1,
        addressLine2,
        city,
        postcode,
        region,
        country,
        googleBusinessName
    },
    postype,    
    backgroundImage,
    menuBackgroundImage,
    heroImage,
    defaultCatalogueImage,
    themeColor,
    timeZone,
    locale,
    currency,
    allowTips,
    enableAbsorbMerchantFee,
    enableEnhancedProductEdit,
    enableEnhancedModifierEdit,
    enablePosSync,
    enablePromotions,
    enableProductVideos,
    enableStripeOnboarding,
    enableSurcharges,
    enableSurchargesFeature,
    lastCatalogueSyncTime,
    lastCatalogueSyncDuration,
    posMetadata {
        posDisplayName
        venuePosDisplayName
        operations {
            name
            supported
            fields {
                name
                title
                subtitle
                validation
                input
                placeholder
                visible
                lookup
                lookupScope
                lookupValue
                multiValueDelimiter
            },
            behaviors {
                name
                supported
            }
        }
    }
    group {
        id
        displayName
        billingSystemId
    },
    membershipPrograms {
        programId,
        membershipLevels {
            membershipLevelId,
            priceListId
        }
    },
    trayCharge {
        ${trayChargeQueryFields}
    },
    venueServiceFee {
        ${venueServiceFeeQueryFields}
    },
    alcoholicDrinksRestriction {
        ${alcoholicDrinksRestrictionQueryFields}
    },
    analyticsLayoutConfig {
        ${analyticsLayoutConfigQueryFields}
    },
    parentLocationId,
    childLocationIds,
    locationType,
    lastItemUpdatedUtc,
    parentLocationUpdateStatus {
        lastMenuUpdateUtc,
        lastParentImportUtc,
        hasBeenSynced,
        updatesAvailable,
    },
    enableServiceSchedule,
    enableEnhancedServiceEdit,
    orderFlow,
    splitPaymentOptions
`;

const query = `
    query ($locationId: ID!) {
        location(locationId: $locationId) {
            ${activeLocationFields}
        }
    }
`;
