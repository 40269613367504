import { opsApi } from "API";
import { GraphQLItem } from "../../../common/scaffolding/types";

export const editWaiter = async (locationId: string, staffId: string): Promise<GraphQLStaff> => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { locationId, staffId });
    return res.data.staffMember;
};

type GraphQLPayload = { staffMember: GraphQLStaff };

export interface GraphQLStaff extends GraphQLItem {
    pin: string;
    accessLevel?: string;
}

const query = `
    query ($locationId: ID!, $staffId: ID!) {
        staffMember(locationId: $locationId, id: $staffId) {
            id,
            displayName,
            pin,
            accessLevel
        }
    }
`;
