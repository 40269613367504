import { PriceListPermissions } from "features/priceList/types/PriceListPermissions";
import { createSelector } from "reselect";
import { createCrudPermissions } from "../types/createCrudPermissions";
import { ExtendedPermissions } from "../types/ExtendedPermissions";
import { getLocationPermissions } from "./getLocationPermissions";
import { StaffPermissions } from "features/waiter/types/StaffPermissions";
import { getIsParentLocation } from "./getIsParentLocation";

export const getCrudPermissions = createSelector(
    getLocationPermissions,
    getIsParentLocation,
    (permissions, isParentLocation) => ({
        menudata: createCrudPermissions("menudata", permissions),
        demoQR: createCrudPermissions("demoqr", permissions),
        nfctag: createCrudPermissions("nfctag", permissions),
        section: createCrudPermissions("section", permissions),
        staff: createCrudPermissions<StaffPermissions>("staff", permissions, () => {
            return { canSetAccessLevel: permissions.has(`staff:accesslevel:update`) };
        }),
        pricelist: createCrudPermissions<PriceListPermissions>("pricelist", permissions, () => {
            return { canCreateBulkRule: permissions.has(`pricelist:global:create`) };
        }),
        product: {
            ...createCrudPermissions("product", permissions),
            ...(isParentLocation && !permissions.has("location:parent:menudata:edit")
                ? {
                      canCreate: false,
                      canUpdate: false,
                      canDelete: false,
                      canBulkDelete: false,
                  }
                : {}),
        },
        catalogue: createCrudPermissions<ExtendedPermissions>("catalogue", permissions, () => {
            const blockImport = isParentLocation && !permissions.has("location:parent:menudata:csv:import");
            return {
                canImportCsv: !blockImport && permissions.has(`menudata:csv:import`),
                canImportPos: !blockImport && permissions.has(`menudata:pos:import`),
            };
        }),
        quickSell: createCrudPermissions("quicksell", permissions),
        surcharges: createCrudPermissions("surcharges", permissions),
        packages: createCrudPermissions("packages", permissions),
        posConfiguration: createCrudPermissions("location:pos:configuration", permissions),
        taxes: createCrudPermissions("taxes", permissions),
        promotions: createCrudPermissions("promotions", permissions),
        stripe: createCrudPermissions("stripe", permissions),
        memberships: createCrudPermissions("membership", permissions),
    })
);
