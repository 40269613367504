import { createAction } from "../reducers/edit";
import { scaffoldArchiveAction } from "../../../common/scaffolding/actions/scaffoldArchiveAction";
import { archiveWaiter } from "../api/archiveWaiter";

export const archive = (location: string) =>
    scaffoldArchiveAction(
        (state) => state.waiters.edit,
        createAction,
        (id) => archiveWaiter(location, id)
    );
