import { AppDispatch } from "features";
import { createAction } from "../reducers/upload";
import { createAction as importActions } from "../reducers/import";

export function resetBulkUpload() {
    return async (dispatch: AppDispatch) => {
        dispatch(createAction.reset());
        dispatch(importActions.resetImport());
    };
}
