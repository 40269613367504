import { isNew } from "common/utility/modelUtils";
import { createAction } from "../reducers/edit";
import { EditableCategory } from "../types";
import { editCategory, GraphQLCategory } from "../api/editCategory";
import { scaffoldEditAction } from "common/scaffolding/actions/scaffoldEditAction";

export const edit = (locationId: string, categoryId: string) =>
    scaffoldEditAction(
        categoryId,
        (state) => state.categories.edit,
        createAction,
        async () => {
            if (isNew(categoryId)) {
                return newEditableCategory();
            }

            const category = await editCategory(locationId, categoryId);
            return mapEditableCategory(category);
        }
    );

const mapEditableCategory = (input: GraphQLCategory): EditableCategory => ({
    id: input.id,
    internalName: input.internalName,
    displayName: input.displayName,
    type: input.type as "food" | "drink",
    special: input.special || false,
    image: input.image,
    showFromPrice: input.showFromPrice || false,
    services: input.services.map((s) => s.id),
    menus: (input.menus || []).map((m) => m.id),
    menuItems: (input.items || []).map((m) => m.id),
});

const newEditableCategory = (): EditableCategory => ({
    internalName: "",
    displayName: "",
    type: "food",
    menuItems: [],
    services: [],
    menus: [],
    image: null,
    special: false,
    showFromPrice: true,
});
