import { opsApi } from "API";
import { PriceListSummary } from "..";

export const listPriceLists = async (location: string) => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { location });
    return res.data.priceLists;
};

type GraphQLPayload = { priceLists: PriceListSummary[] };

const query = `
    query($location: ID!) {
        priceLists(locationId: $location) {
            id,
            displayName,
            posId,
            itemCount
        }
    }
`;
