import styles from "./ChildLocationsTable.module.scss";

import { AppState } from "features";
import { CardFooter, Row } from "core/components/card";
import { CardWidth, CloseEvent, CloseMeta, Confirm, Modal, ModalRenderer } from "core/components/modal";
import { ChildLocationsEmpty } from "./ChildLocationsEmpty";
import { FieldArray, useFormikContext } from "formik";
import { getActiveFilters, getTextFilter } from "../selectors/getFilters";
import { getFilteredLocations } from "../selectors/getFilteredLocations";
import { ListHeaderRow } from "./ListHeaderRow";
import { ListItem } from "./ListItem";
import { LocationRelations } from "../types/LocationRelations";
import { LocationSummary } from "features/location/types/LocationSummary";
import { Pagination } from "core/components/pagination";
import { scrollIntoView } from "common/utility/scrollIntoView";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocalStorage } from "common/hooks";
import { useSelector } from "react-redux";

interface Props {
    disableFields: boolean;
}

export const ChildLocationsTable = ({ disableFields }: Props) => {
    const {
        dirty,
        values: { childLocationIds },
    } = useFormikContext<LocationRelations>();

    const [blockTransitionVisible, setBlockTransitionVisible] = useState(false);

    const activeFilters = useSelector(getActiveFilters);

    const textFilter = useSelector(getTextFilter);

    const clickedChildLinkUrl = useRef<string | undefined>();

    const [pageIndex, setPageIndex] = useState(1);

    const [pageSize, setStoredPageSize] = useLocalStorage<number>("childLocationsPageSize", 25);

    const resetOnFilterChange = useRef(false);

    const start = (pageIndex - 1) * pageSize;

    const end = pageIndex * pageSize;

    const locations = useSelector<AppState>((state) =>
        getFilteredLocations(state, childLocationIds)
    ) as LocationSummary[];

    const childLocations = useMemo(() => {
        return locations.filter((location) => childLocationIds.includes(location.id));
    }, [childLocationIds, locations]);

    const noResults = childLocationIds.length === 0;

    const pageItems = useMemo(() => childLocations.slice(start, end), [childLocations, start, end]);

    const handlePageSizeChange = useCallback(
        (size: number) => {
            if (size !== pageSize) {
                setStoredPageSize(size);
                setPageIndex(1);
                scrollIntoView("#child-locations-list");
            }
        },
        [pageSize, setStoredPageSize, setPageIndex]
    );

    const handleChildLinkClick = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            if (dirty) {
                e.preventDefault();
                setBlockTransitionVisible(true);
                clickedChildLinkUrl.current = e.currentTarget.href;
            } else {
                clickedChildLinkUrl.current = undefined;
            }
        },
        [dirty]
    );

    const handleBlockTransitionClose = (event: CloseEvent, meta: CloseMeta) => {
        if (meta.source !== "confirm") {
            setBlockTransitionVisible(false);
        }
    };

    const handleTransitionConfirm = () => {
        if (clickedChildLinkUrl.current) {
            window.location.href = clickedChildLinkUrl.current;
        }
    };

    useEffect(() => {
        if (resetOnFilterChange.current) {
            setPageIndex(1);
        } else {
            resetOnFilterChange.current = true;
        }
    }, [activeFilters, setPageIndex, textFilter]);

    useEffect(() => {
        if (pageItems.length === 0 && pageIndex > 1) {
            setPageIndex(pageIndex - 1);
        }
    }, [pageItems, pageIndex, setPageIndex]);

    return (
        <>
            <div role="table" className={styles.table}>
                {!noResults && <ListHeaderRow pageIndex={pageIndex} pageItems={pageItems} pageSize={pageSize} />}
                <div role="rowgroup">
                    <FieldArray
                        name="childLocationIds"
                        render={(props) =>
                            pageItems.map((item, index) => {
                                return (
                                    <ListItem
                                        key={item.id}
                                        index={index}
                                        item={item}
                                        disableRemove={disableFields}
                                        onChildLinkClick={handleChildLinkClick}
                                        {...props}
                                    />
                                );
                            })
                        }
                    />
                </div>
            </div>
            {pageItems.length === 0 && (
                <div className={styles.emptyMessageContainer}>
                    <ChildLocationsEmpty />
                </div>
            )}

            {!noResults && (
                <CardFooter className={styles.cardFooter}>
                    <Pagination
                        allowUserPageSize
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        buffer={2}
                        count={childLocations.length}
                        onChange={(index) =>
                            setPageIndex((prevIndex) => {
                                if (prevIndex !== index) {
                                    scrollIntoView("#child-locations-list");
                                }
                                return index;
                            })
                        }
                        onPageSizeChange={(size) => handlePageSizeChange(size)}
                    />
                </CardFooter>
            )}
            {/* Special navigation warning for child links, since they cannot be handled through react-router*/}
            <ModalRenderer target="#modal">
                <Modal
                    footer={
                        <Confirm
                            cancelLabel="Keep editing"
                            confirmLabel="Discard changes"
                            onConfirm={handleTransitionConfirm}
                        />
                    }
                    onClose={handleBlockTransitionClose}
                    title="Unsaved changes"
                    visible={blockTransitionVisible}
                    width={CardWidth.NARROW}
                >
                    <Row>Changes have not been saved. Are you sure you want to leave this page?</Row>
                </Modal>
            </ModalRenderer>
        </>
    );
};
