import styles from "./PromotionSummary.module.scss";
import { Row } from "core/components/card";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import {
    Promotion,
    PromotionDiscount,
    PromotionDiscountType,
    PromotionScheduleState,
    PromotionScheduleStateKeys,
    PromotionType,
} from "../types";
import { Col } from "core/components/grid/Grid";
import { LocationLocaleContext } from "features/location";
import { numberOrZero } from "common/utility/numberUtils";
import classNames from "classnames";
import { useContext } from "react";
import { PromoCode } from "common/icons";
import { Icon } from "core/components/icon/Icon";
import { Badge } from "core/components/badge";
import { Status, ColorScheme } from "core/components/status";
import { toTitleCase } from "common/utility/StringUtils";
import { MultiplePromoCodes } from "common/icons/MultiplePromoCodes";

interface ListItemProps {
    canUpdate: boolean;
    promotion: Promotion;
    index: number;
}

const getBadgeColor = (status?: PromotionScheduleStateKeys | null): ColorScheme => {
    if (!status) {
        return "active";
    }

    switch (PromotionScheduleState[status]) {
        case PromotionScheduleState.STAGED:
            return "warning";
        case PromotionScheduleState.SCHEDULED:
            return "ongoing";
        case PromotionScheduleState.EXPIRED:
            return "disabled";
        default:
            return "active";
    }
};

const getPublishStatusLabel = (status?: PromotionScheduleStateKeys | null) => {
    if (!status) {
        return "Active";
    }

    switch (PromotionScheduleState[status]) {
        case PromotionScheduleState.STAGED:
            return "Staged changes";
        default:
            return toTitleCase(status || "Active");
    }
};

export const PromotionSummary = ({ canUpdate, promotion, index }: ListItemProps) => {
    const location = useLocation();
    const link = canUpdate ? `${location.pathname}/${promotion.id}` : undefined;
    const border = index > 0 ? "top" : undefined;
    const locale = useContext(LocationLocaleContext);
    const multiCodes =
        promotion.promotionType === PromotionType.MULTIPLECODE && (promotion.generatedCodeCount ?? 0) > 1;

    return (
        <li className={styles.container}>
            <Row
                as={link ? Link : "div"}
                className={link ? styles.link : styles.innerContainer}
                to={link}
                border={border}
            >
                <Col cols={{ xs: 12, md: 6 }} className={styles.detailsContainer}>
                    <span className={styles.heading}>{promotion.displayName}</span>
                    <span className={styles.subtle}>{promotion.internalName}</span>
                    <ul className={classNames(styles.details, styles.subtle)}>
                        <li className={styles.detailItem}>
                            {promotion.discount.type !== PromotionDiscountType.PRICELIST ? (
                                <>
                                    <Discount discount={promotion.discount} /> off order
                                </>
                            ) : (
                                <>Price List</>
                            )}
                        </li>
                        <li className={styles.detailItem}>
                            {locale.formatCurrency(numberOrZero(promotion.restrictions.minSpend))} min spend
                        </li>
                        <li className={styles.detailItem}>
                            {promotion.restrictions.maxMemberUsage || promotion.restrictions.maxCodeUsage
                                ? "Restrictions applied"
                                : "No restrictions"}
                        </li>
                    </ul>
                </Col>
                <Col cols={{ xs: 12, md: 4 }} className={styles.propertiesContainer}>
                    <div className={styles.propertyCol}>
                        <Badge textColorScheme="mid" backgroundColorScheme="light">
                            <Icon className={styles.badgeIcon}>
                                {promotion.promotionType === PromotionType.MULTIPLECODE ? (
                                    <MultiplePromoCodes />
                                ) : (
                                    <PromoCode />
                                )}
                            </Icon>
                            {promotion.code}
                        </Badge>
                    </div>
                    <div className={styles.propertyCol}>
                        {promotion.usageCount}
                        {multiCodes && `/${locale.formatNumber(promotion.generatedCodeCount!)}`} used
                    </div>
                </Col>
                <Col cols={{ xs: 12, md: 2 }} className={styles.statusContainer}>
                    <Status colorScheme={getBadgeColor(promotion.status)}>
                        {getPublishStatusLabel(promotion.status)}
                    </Status>
                </Col>
            </Row>
        </li>
    );
};

interface DiscountProps {
    discount: PromotionDiscount;
}

export const Discount = ({ discount }: DiscountProps) => {
    if (discount.type === PromotionDiscountType.PERCENTAGE) {
        return <>{`${discount.value}%`}</>;
    }

    return (
        <>
            <CurrencySymbol />
            {discount.value}
        </>
    );
};

const CurrencySymbol = () => (
    <LocationLocaleContext.Consumer>{(locale) => locale.currencySymbol}</LocationLocaleContext.Consumer>
);
