import { AppState } from "features";
import { OrderFlowType } from "features/locationSettings/types/OrderFlow";
import { createSelector } from "reselect";
import { getActiveLocation } from "./getLocationPermissions";

export const getActiveLocationSelector = (state: AppState) => getActiveLocation(state);

export const getShowSplitPaymentOptions = createSelector(getActiveLocation, (location) =>
    location?.orderFlow ? location.orderFlow !== OrderFlowType.PAYANDORDER : false
);
