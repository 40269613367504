import { opsApi } from "API";
import { sortByDisplayName } from "foundation/dataConventions/sortByDisplayName";
import { Group } from "..";

export const listGroups = async () => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query);
    return res.data.groups.sort(sortByDisplayName);
};

type GraphQLPayload = { groups: Group[] };

const query = `
    query {
        groups {
            id,
            displayName,
            authInfo {
                permissions
            }
        }
    }
`;
