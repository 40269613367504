import styles from "./FieldErrors.module.scss";

import { PropsWithChildren, ReactElement, useMemo } from "react";
import { useFormikContext, FormikProps, getIn } from "formik";
import { Warning } from "core/components/iconWithText";

interface Props {
    fieldNames: string[];
}

export const FieldErrors = ({ children, fieldNames }: PropsWithChildren<Props>): ReactElement => {
    const form: FormikProps<any> = useFormikContext();
    const { errors, touched } = form || {};

    const displayErrors = useMemo(
        () =>
            fieldNames
                .map((name) => {
                    const error = getIn(errors, name);
                    const isTouched = getIn(touched, name);
                    return isTouched && error ? { name, error } : null;
                })
                .filter(Boolean),
        [errors, touched, fieldNames]
    );

    return (
        <div className={displayErrors.length ? styles.containerError : styles.container}>
            {children}
            {displayErrors.map((errorInfo) => (
                <Warning key={`input-error-${errorInfo!.name}`}>{errorInfo!.error}</Warning>
            ))}
        </div>
    );
};
