export const productTemplateData = `Type,Master Product Name,Internal Name,Description,Variant Name,Price,SKU
Food,Burger,Burger - Plain,"Our signature burger, available in chicken or beef",Beef,9.00,1121213
,,,Chicken,9.50,2232344
Food,Duck Ragu,,Slow cooked duck and handmade pasta,,14.50,1233342
Beer,Victoria Bitter,VB,,Schooner,5.50,3375478
,,,Pint,7.20,2966323
,,,Jug,15.00,1233342
Other,Mojito,Cocktail - Basic Mojito,"Mint, rum, sugar and crushed ice",,16.00,3859735`;

export const productTemplateDataWithEnergyContent = `Type,Master Product Name,Internal Name,Description,Variant Name,Price,SKU,Energy Content
Food,Burger,Burger - Plain,"Our signature burger, available in chicken or beef",Beef,9.00,1121213,1024
,,,Chicken,9.50,2232344
Food,Duck Ragu,,Slow cooked duck and handmade pasta,,14.50,1233342,660
Beer,Victoria Bitter,VB,,Schooner,5.50,3375478,150
,,,Pint,7.20,2966323,227
,,,Jug,15.00,1233342,456
Other,Mojito,Cocktail - Basic Mojito,"Mint, rum, sugar and crushed ice",,16.00,3859735,0`;

export const getModifierExportTemplate = (energyContent: boolean, upsellTypes: boolean) => {
    return `Modifier List Name,Internal Name,Modifier,Price,SKU${
        energyContent ? ",Energy Content" : ""
    },Min Selection,Max Selection,Selection Per Item${upsellTypes ? ",Print As Product" : ""}
What milk would you like?,Milk Type,Cow's Milk,0.00,1121213${energyContent ? ",42" : ""},0,5,1${
        upsellTypes ? ",Drink" : ""
    }
,,Soy Milk,1.50,3375478
,,Almond Milk,1.80,2966323
,,Oat Milk,2.00,1233342
Want to add an extra?,,Extra Patty,6.00,2840583${energyContent ? "," : ""},0,0,5${upsellTypes ? ",Food" : ""}
,,Bacon,3.00,2849573
,,Avocado,2.00,2947362
,,Onion Rings,2.75,9836194
Which drink would you like?,,Carlton Draught,12.00,1234567${energyContent ? "," : ""},0,4,1${
        upsellTypes ? ",Alcohol" : ""
    }
,,Mud House Sauvignon Blanc,15.00,3215678
,,Riddoch merlot,14.00,2136789`;
};
