import { WaiterSummary } from "../types";

export function validateWaiterPin(allWaiters: WaiterSummary[], waiterId: string | undefined, pin: String) {
    if (!pin) {
        return true;
    }

    const existingWaiter = allWaiters.find((x) => x.pin === pin);

    return existingWaiter === undefined || (waiterId && waiterId === existingWaiter.id) || false;
}
