import { opsApi } from "API";
import { AppDispatch } from "features/state";
import { createAction, TypeKeys } from "../reducers/edit";
import { getQuickSellData } from "./getQuickSellData";

export const generateDemoQuickSellData = (locationId: string) => {
    return async (dispatch: AppDispatch) => {
        dispatch(createAction.saving(TypeKeys.SAVE_BEGIN));
        try {
            const response = await opsApi.fetch(`/location/${locationId}/adUnitDemo`, {
                method: "POST",
            });

            console.log("quickSellData", await response.json());

            window.setTimeout(() => {
                dispatch(getQuickSellData(locationId));
                dispatch(createAction.saved(TypeKeys.SAVE_SUCCESS));
            }, 4000);
        } catch {
            dispatch(createAction.saved(TypeKeys.SAVE_FAILURE));
        }
    };
};
